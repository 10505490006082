import { Pie } from '@nivo/pie';

import {
  FormattedSwitchTabs,
  FormattedSwitchTabsItems,
} from '../../../../../components/translation/FormattedSwitchTabs';

import { DocStatsData, PercentStat } from './get_stats';

export default function Pies({ stats }: { stats: DocStatsData }) {
  const tabs: FormattedSwitchTabsItems[] = [
    {
      title: 'doc.field.type',
      content: <PieTab data={stats.docTypes} />,
    },
    {
      title: 'doc.field.fraud_type',
      content: <PieTab data={stats.fraudTypes} />,
    },
  ];

  return (
    <div className="[&_svg]:overflow-visible">
      <FormattedSwitchTabs tabs={tabs} align="center" />
    </div>
  );
}

function PieTab(props: { data: PercentStat[] }) {
  return (
    <div className="mt-6">
      <Pie
        margin={{ top: 20 }}
        width={400}
        height={300}
        data={props.data}
        arcLabel={({ data }) => `${data.percent}%`}
        arcLabelsSkipAngle={8}
        arcLinkLabelsSkipAngle={5}
      />
    </div>
  );
}
