import { DocumentPlusIcon } from '@heroicons/react/24/outline';
import { useTranslate } from '@tolgee/react';
import { $getSelection } from 'lexical';
import * as React from 'react';
import { createContext, useContext, useMemo } from 'react';

import {
  MeasuresSentencesIndex,
  safeTranslationKeyMeasuresPartialSentences,
  safeTranslationKeyMeasuresSentences,
  TRANSLATION_KEY_PREDEFINED_MEASURES_SENTENCES,
} from '../../../translationsMapping/measures';
import FormattedRichTextField from '../../translation/FormattedRichTextField';

import { Language } from '#gql';
import {
  DropdownActionOption,
  DropdownOption,
  RichTextToolbarOptions,
  ToolbarCustomPluginProps,
  Tooltip,
  useRichTextContext,
} from '#tailwind_ui';

interface AlertDraftEditMeasuresFieldProps {
  toolbarOptions: RichTextToolbarOptions;
  readOnly?: boolean;
  language?: Language;
}

const languageContext = createContext<Language | undefined>(undefined);

export function AlertDraftEditMeasuresField(
  props: AlertDraftEditMeasuresFieldProps,
) {
  const { language, ...otherProps } = props;

  return (
    <languageContext.Provider value={language}>
      <FormattedRichTextField
        customPlugins={[{ ToolbarComponent: MeasuresSentencesPlugin }]}
        name="measures"
        label="series.alert.field.measures"
        labelOptions={{ ns: 'alert' }}
        {...otherProps}
      />
    </languageContext.Provider>
  );
}

function MeasuresSentencesPlugin(props: ToolbarCustomPluginProps<string>) {
  const { Dropdown } = props;
  const { t } = useTranslate('alert');
  const [state] = useRichTextContext();
  const languageCtx = useContext(languageContext);

  const sentences: MeasuresSentencesIndex[] = Object.keys(
    TRANSLATION_KEY_PREDEFINED_MEASURES_SENTENCES,
  ) as MeasuresSentencesIndex[];

  const options: Array<Array<DropdownOption<string>>> = useMemo(() => {
    return [
      sentences.map((key) => ({
        type: 'action',
        label: t(safeTranslationKeyMeasuresPartialSentences(key), {
          language: languageCtx,
        }),
        data: t(safeTranslationKeyMeasuresSentences(key), {
          language: languageCtx,
        }),
      })),
    ];
  }, [languageCtx, sentences, t]);

  function onSelect(element: DropdownActionOption<string>) {
    state.activeEditor?.update(() => {
      const selection = $getSelection();
      selection?.insertText(element.data || '');
    });
  }

  return (
    <Tooltip content={t('series.alert.field.measures.predefined.dropdown')}>
      <div className="flex items-center">
        <Dropdown onSelect={onSelect} options={options}>
          <div>
            <DocumentPlusIcon className="h-5 w-5" />
          </div>
        </Dropdown>
      </div>
    </Tooltip>
  );
}
