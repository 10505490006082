import { createContext, useContext } from 'react';

import { assert } from '#utils/assert';

export const scanIdContext = createContext<string | null>(null);

export function useScanIdContext() {
  const ctx = useContext(scanIdContext);

  assert(ctx);

  return ctx;
}
