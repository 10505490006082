import { CogIcon } from '@heroicons/react/20/solid';
import { BoltIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { match } from 'ts-pattern';

import FormattedConfirmDialog from '../translation/FormattedConfirmDialog';
import FormattedErrorAlert from '../translation/FormattedErrorAlert';

import { PadifSelectReference, ReferenceActionButtons } from './PadifShowInit';
import { useChangePadifReference } from './hooks/usePadifReference';

import {
  GqlPadifAnalysisQuery,
  usePadifChangeStatusMutation,
  useRemovePadifAnalysisMutation,
} from '#gql';
import { FormattedAlert } from '#intl';
import {
  Color,
  DialogBody,
  DialogFooter,
  DialogRoot,
  DialogTitle,
  Dropdown,
  DropdownActionOption,
  DropdownOptions,
  useNotificationCenter,
  useOnOff,
} from '#tailwind_ui';
import { assert } from '#utils/assert';

type Action = 'CHANGE_ACTIVE' | 'CHANGE_REF' | 'DELETE';

export interface PadifSettingsProps {
  padif: GqlPadifAnalysisQuery['padifAnalysis'];
}

export default function PadifSettings({ padif }: PadifSettingsProps) {
  const [refPickerOpen, openRefPicker, closeRefPicker] = useOnOff();
  const [deletePadifModalOpen, openDeletePadifModal, closeDeletePadifModal] =
    useOnOff(false);

  const { addToastNotification, addNotification } = useNotificationCenter();

  const [padifChangeStatus] = usePadifChangeStatusMutation({
    variables: {
      input: {
        id: padif.id,
        active: !padif.active,
      },
    },
  });

  const options = useMemo(() => buildOptions(padif.active), [padif.active]);

  function onPadifChangeStatus() {
    padifChangeStatus()
      .then(({ data }) => {
        assert(data);
        const {
          changePadifAnalysisStatus: { active },
        } = data;
        addToastNotification({
          label: (
            <T
              keyName="padif.view.notifications.change_status.success"
              params={{ active }}
            />
          ),
        });
      })
      .catch((error) =>
        addNotification({
          type: 'danger',
          title: (
            <T
              keyName="padif.view.notifications.change_status.error"
              params={{ active: padif.active }}
            />
          ),
          content: error.message,
        }),
      );
  }

  function onSelect({ data }: DropdownActionOption<Action>) {
    assert(data);

    match(data)
      .with('CHANGE_REF', openRefPicker)
      .with('CHANGE_ACTIVE', onPadifChangeStatus)
      .with('DELETE', openDeletePadifModal)
      .exhaustive();
  }

  return (
    <>
      <Dropdown<Action> options={options} onSelect={onSelect}>
        <CogIcon className="h-5 w-5 text-neutral-900" />
      </Dropdown>

      <DeletePadifModal
        padifId={padif.id}
        isOpen={deletePadifModalOpen}
        onClose={closeDeletePadifModal}
      />

      <DialogRoot
        open={refPickerOpen}
        icon={<PencilIcon />}
        onOpenChange={closeRefPicker}
      >
        <ModalContent padif={padif} closeRefPicker={closeRefPicker} />
      </DialogRoot>
    </>
  );
}

function buildOptions(isActive: boolean): DropdownOptions<Action> {
  return [
    [
      {
        label: (
          <T
            keyName="page.padif_list.toggle_active"
            params={{ active: isActive }}
          />
        ),
        data: 'CHANGE_ACTIVE',
        type: 'action',
        icon: <BoltIcon className="text-yellow-500" />,
      },
      {
        label: <T keyName="page.padif.change_reference" />,
        data: 'CHANGE_REF',
        type: 'action',
        icon: <PencilIcon className="text-primary-700" />,
      },
      {
        label: <T keyName="padif.view.delete" />,
        type: 'action',
        data: 'DELETE',
        icon: <TrashIcon className="text-danger-700" />,
      },
    ],
  ];
}

interface ModalContentProps {
  padif: GqlPadifAnalysisQuery['padifAnalysis'];
  closeRefPicker: () => void;
}

function ModalContent({ padif, closeRefPicker }: ModalContentProps) {
  const {
    setSelectedReference,
    setReferenceMutation,
    selectedReference,
    removeReferenceMutation,
    handlePageChange,
    page,
    rowsPerPage,
    query,
    mutationError,
  } = useChangePadifReference(padif.id, 2);

  const { data, error } = query;
  if (error) {
    return <FormattedErrorAlert error={error} />;
  }
  if (!data) {
    return null;
  }
  return (
    <>
      <DialogTitle>
        <T keyName="page.padif.select_ref" />
      </DialogTitle>
      <DialogBody className="gap-2">
        <PadifSelectReference
          data={data}
          handlePageChange={handlePageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          selectedReference={selectedReference}
          setSelectedReference={setSelectedReference}
        />
        <FormattedAlert
          type="warning"
          messageId="page.padif.change_reference.warning"
        />
        <FormattedErrorAlert error={mutationError} />
      </DialogBody>
      <DialogFooter>
        <ReferenceActionButtons
          onSuccess={closeRefPicker}
          onCancel={closeRefPicker}
          padif={padif}
          removeReferenceMutation={removeReferenceMutation}
          selectedReference={selectedReference}
          setReferenceMutation={setReferenceMutation}
        />
      </DialogFooter>
    </>
  );
}

interface DeletePadifModalProps {
  isOpen: boolean;
  onClose: () => void;
  padifId: string;
}

function DeletePadifModal(props: DeletePadifModalProps) {
  const { isOpen, onClose, padifId } = props;

  const { addToastNotification, addNotification } = useNotificationCenter();
  const navigate = useNavigate();

  const [removePadifAnalysis] = useRemovePadifAnalysisMutation({
    variables: {
      id: padifId,
    },
  });

  function onRemovePadifAnalysis() {
    removePadifAnalysis()
      .then(() => {
        addToastNotification({
          label: (
            <T
              keyName="padif.view.notifications.delete.success"
              params={{ id: padifId }}
            />
          ),
        });
        navigate('/padif');
      })
      .catch((error) =>
        addNotification({
          type: 'danger',
          title: <T keyName="padif.view.notifications.delete.error" />,
          content: error.message,
        }),
      );
  }

  return (
    <FormattedConfirmDialog
      open={isOpen}
      title="padif.view.delete"
      icon={<TrashIcon />}
      color={Color.danger}
      confirmText="global.delete"
      onConfirm={onRemovePadifAnalysis}
      onCancel={onClose}
    />
  );
}
