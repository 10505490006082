import { TableSearchBaseFilters } from '../../components/providers/TableSearchContextProvider';
import { SyncSearchParamsStoreSupportedTypes } from '../SyncSearchParamsStore';
import { SyncSearchParamsWithTermStore } from '../SyncSearchParamsWithTermStore';

export interface UserFilters extends TableSearchBaseFilters {
  service: string | null;
  isDisabled: boolean | null;
  isConfirmed: boolean | null;
}

export class UserFiltersStore extends SyncSearchParamsWithTermStore<UserFilters> {
  public get shapeDef(): Record<
    keyof UserFilters,
    SyncSearchParamsStoreSupportedTypes
  > {
    return {
      searchTerm: 'string',
      service: 'string',
      isDisabled: 'boolean',
      isConfirmed: 'boolean',
    };
  }

  public override get initialData(): UserFilters {
    return {
      searchTerm: '',
      service: null,
      isDisabled: null,
      isConfirmed: null,
    };
  }
}
