import { UserMinusIcon } from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { match } from 'ts-pattern';

import IfHasPermission from '../../permissions/IfHasPermission';
import FormattedButton from '../../translation/FormattedButton';
import FormattedEmptyState from '../../translation/FormattedEmptyState';

import AdminUsersTable from './AdminUsersTable';
import ChangeUserProtectedFieldsModal from './ChangeUserProtectedFieldsModal';
import ChangeUserRolesModal from './ChangeUserRolesModal';
import DeleteUserConfirm from './DeleteUserConfirm';
import ResetUserPasswordConfirm from './ResetUserPasswordConfirm';

import { GqlUsersQuery } from '#gql';
import { PageLayout } from '#ui/page_layout';

export interface UserListModalState {
  type: 'CHANGE_USER' | 'CHANGE_ROLES' | 'RESET_PASSWORD' | 'DELETE_USER';
  user: GqlUsersQuery['users']['nodes'][number];
}

export type UserListState = UserListModalState | null;
export default function AdminUsersIndexPage() {
  const [modal, setModal] = useState<UserListState>(null);

  const closeModal = useCallback(() => setModal(null), []);

  return (
    <IfHasPermission
      userPermission="user_view_admin_inactive"
      otherwise={
        <FormattedEmptyState
          icon={<UserMinusIcon className="h-10" />}
          title="admin.user.list.unauthorized.title"
        />
      }
    >
      <PageLayout
        title={<T keyName="admin.users.title" />}
        actions={
          <FormattedButton
            as={Link}
            to="/admin/users/create"
            messageId="admin.create_user.title"
          />
        }
      >
        <AdminUsersTable openModal={setModal}>
          {match(modal)
            .with({ type: 'DELETE_USER' }, ({ user }) => (
              <DeleteUserConfirm user={user} onCancel={closeModal} />
            ))
            .with({ type: 'RESET_PASSWORD' }, ({ user }) => (
              <ResetUserPasswordConfirm user={user} onCancel={closeModal} />
            ))
            .with({ type: 'CHANGE_ROLES' }, ({ user }) => (
              <ChangeUserRolesModal user={user} onCancel={closeModal} />
            ))
            .with({ type: 'CHANGE_USER' }, ({ user }) => (
              <ChangeUserProtectedFieldsModal
                user={user}
                onCancel={closeModal}
              />
            ))
            .otherwise(() => null)}
        </AdminUsersTable>
      </PageLayout>
    </IfHasPermission>
  );
}
