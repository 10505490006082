import { useTranslate } from '@tolgee/react';
import { ForwardedRef, forwardRef } from 'react';

// eslint-disable-next-line no-restricted-imports
import { Radio, RadioProps } from '#tailwind_ui';

interface FormattedRadioProps extends Omit<RadioProps, 'label' | 'help'> {
  label: TranslationKey;
  help?: TranslationKey;
}

export default forwardRef<HTMLInputElement, FormattedRadioProps>(
  function FormattedRadio(
    props: FormattedRadioProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) {
    const { t } = useTranslate();
    const { label, help, ...otherProps } = props;

    return (
      <Radio
        ref={ref}
        label={t(label)}
        help={help && t(help)}
        {...otherProps}
      />
    );
  },
);
