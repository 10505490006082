import { CheckIcon } from '@heroicons/react/24/outline';
import { T, useTranslate } from '@tolgee/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { safeTranslationKeyDocMrzIsValid } from '../../translationsMapping/documents';
import FormattedButton from '../translation/FormattedButton';
import FormattedCheckboxField from '../translation/FormattedCheckboxField';
import FormattedSubmitButton from '../translation/FormattedSubmitButton';

import {
  GqlDocument,
  GqlImportDataDocumentFields,
  useFinalizeScanImportMutation,
} from '#gql';
import { FormattedAlert } from '#intl';
import { Color, Dialog, FormRHF } from '#tailwind_ui';
import { HighlightedLink } from '#ui/link';

interface FormValues {
  updateDocumentMRZ: boolean;
  updateDocumentNumber: boolean;
}

export default function FinalizeButton(props: {
  scanId: string;
  scanName: string;
  documentFields?: GqlImportDataDocumentFields;
  document: Pick<GqlDocument, 'seqId' | 'docIsMrzOk' | 'docSerialNumber'>;
  disabled?: boolean;
}) {
  const { scanId, scanName, documentFields, document, disabled } = props;
  const { t } = useTranslate();
  const [isOpen, setOpen] = useState(false);
  const [finalizeImport] = useFinalizeScanImportMutation();
  const navigate = useNavigate();

  const mrzValidityHasChanged =
    document.docIsMrzOk !== documentFields?.docIsMrzOk;
  const mrzValidityIsDefined = documentFields?.docIsMrzOk != null;
  const documentNumberHasChanged =
    document.docSerialNumber !== documentFields?.docSerialNumber;
  const documentNumberIsDefined = documentFields?.docSerialNumber != null;

  const showMrzIsValidField = mrzValidityHasChanged && mrzValidityIsDefined;
  const showDocumentNumberField =
    documentNumberHasChanged && documentNumberIsDefined;

  const hasFields = showMrzIsValidField || showDocumentNumberField;

  return (
    <div className="flex flex-col gap-2">
      <Dialog.Root
        as={FormRHF<FormValues>}
        asProps={{
          defaultValues: {
            updateDocumentMRZ: showMrzIsValidField,
            updateDocumentNumber: showDocumentNumberField,
          },
          onSubmit: async (values: FormValues) => {
            return finalizeImport({
              variables: { input: { id: scanId, options: values } },
            }).then(() => navigate('/scans'));
          },
        }}
        trigger={
          <FormattedButton
            messageId="scan.finalize_import.title"
            disabled={disabled}
          />
        }
        open={isOpen}
        onOpenChange={setOpen}
        icon={<CheckIcon />}
        iconColor={Color.primary}
      >
        <Dialog.Title>
          <T
            keyName="scan.finalize_import.confirm_dialog.title"
            params={{ name: scanName }}
          />
        </Dialog.Title>
        <Dialog.Body className="max-w-2xl space-y-4">
          <FormattedAlert
            type="warning"
            messageId="scan.finalize_import.confirm_dialog.warning"
          />
          <p>
            {t({
              key: 'scan.finalize_import.confirm_dialog.description',
              params: {
                seqId: (
                  <HighlightedLink
                    className="text-nowrap"
                    key="link-to-doc"
                    to={`/documents/${document.seqId}`}
                  >
                    {document.seqId}
                  </HighlightedLink>
                ),
              },
            })}
          </p>
          <div className="flex flex-col gap-1.5">
            {hasFields && (
              <T keyName="scan.finalize_import.confirm_dialog.updated_doc_fields" />
            )}
            {showMrzIsValidField && (
              <FormattedCheckboxField
                name="updateDocumentMRZ"
                label="scan.import.finalize.update_document_mrz_validity"
                labelParams={{
                  from: t(safeTranslationKeyDocMrzIsValid(document.docIsMrzOk)),
                  to: t(
                    safeTranslationKeyDocMrzIsValid(documentFields?.docIsMrzOk),
                  ),
                }}
              />
            )}
            {showDocumentNumberField && (
              <FormattedCheckboxField
                name="updateDocumentNumber"
                label="scan.import.finalize.update_document_number"
                labelParams={{
                  from: document.docSerialNumber || t('global.unspecified'),
                  to:
                    documentFields?.docSerialNumber || t('global.unspecified'),
                }}
              />
            )}
          </div>
        </Dialog.Body>
        <Dialog.Footer>
          <FormattedButton
            color="neutral"
            messageId="global.cancel"
            onClick={() => setOpen(false)}
          />
          <FormattedSubmitButton
            messageId="scan.finalize_import.title"
            disabled={disabled}
          />
        </Dialog.Footer>
      </Dialog.Root>
    </div>
  );
}
