import { ViewfinderCircleIcon } from '@heroicons/react/24/solid';
import { T } from '@tolgee/react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { match } from 'ts-pattern';

import { useSeqIdParam } from '../../hooks/useParams';
import { safeTranslationKeyDocumentView } from '../../translationsMapping/documents';
import ImgLink from '../ImgLink';
import IfHasPermission from '../permissions/IfHasPermission';
import { FormattedBadge } from '../translation/FormattedBadge';
import FormattedButton from '../translation/FormattedButton';

import ImageDelete from './ImageDelete';
import ImageRotate from './ImageRotate';

import { GqlDetailedDocumentFieldsFragment } from '#gql';
import { Button } from '#tailwind_ui';
import { CardLayout } from '#ui/card_layout';
import { CompleteOverlay, PartialOverlay } from '#ui/overlay';

export interface DocumentStandardizedImagesProps {
  docId: string;
  images: GqlDetailedDocumentFieldsFragment['images'];
  readonly?: boolean;
  classNames?: string;
}

export function DocumentStandardizedImagesEdition(
  props: DocumentStandardizedImagesProps,
) {
  const { images, docId, readonly, classNames } = props;

  if (images.length === 0) {
    return (
      <span className="italic text-neutral-500">
        <T keyName="global.no_images" />
      </span>
    );
  }

  return (
    <div className={clsx('gap-2', classNames)}>
      {images.map((image) => (
        <DocumentStandardizedImageEdition
          key={image.id}
          image={image}
          docId={docId}
          readonly={readonly}
        />
      ))}
    </div>
  );
}

type DocumentStandardizedImagesViewProps = DocumentStandardizedImagesProps & {
  scans: GqlDetailedDocumentFieldsFragment['unfinishedScans'];
};

export function DocumentStandardizedImagesView(
  props: DocumentStandardizedImagesViewProps,
) {
  const { images, docId, readonly, classNames } = props;

  const scansToReview = props.scans
    .map((scan) =>
      match(scan.importData)
        .with({ __typename: 'ProcessReview' }, (importData) => ({
          id: scan.id,
          filename: scan.filename,
          importData,
        }))
        .otherwise(() => false),
    )
    .filter(Boolean);
  const typedScansToReview = scansToReview as Array<
    Exclude<(typeof scansToReview)[number], boolean>
  >;

  if (images.length === 0 && typedScansToReview.length === 0) {
    return (
      <span className="italic text-neutral-500">
        <T keyName="global.no_images" />
      </span>
    );
  }

  return (
    <div className={clsx('gap-2', classNames)}>
      {images.map((image) => (
        <DocumentStandardizedImageView
          key={image.id}
          image={image}
          docId={docId}
          readonly={readonly}
        />
      ))}
      {typedScansToReview.map((scan) => (
        <Link
          key={scan.id}
          to={`/scans/${scan.id}/import`}
          className="group m-2 flex min-w-0 flex-col items-center justify-between rounded-lg border border-warning-200 bg-warning-200 px-1 py-4 opacity-70 hover:border-neutral-400 hover:opacity-100"
        >
          <div className="flex min-w-0 max-w-full flex-col items-center gap-2">
            <FormattedBadge
              color="neutral"
              label={safeTranslationKeyDocumentView(
                scan.importData.extractedDocument.metadata.view,
              )}
              variant="COLORED_BACKGROUND"
              size="large"
            />
            <div className="max-w-full break-all px-1 text-neutral-900">
              {scan.filename}
            </div>
          </div>
          <FormattedButton
            variant="primary"
            color="warning"
            className="border-warning-200 group-hover:bg-warning-700"
            messageId="document.view.scan.review"
          />
        </Link>
      ))}
    </div>
  );
}

export interface DocumentStandardizedImageProps {
  docId: string;
  image: GqlDetailedDocumentFieldsFragment['images'][0];
  readonly?: boolean;
}

function DocumentStandardizedImageView(props: DocumentStandardizedImageProps) {
  const { image, docId, readonly } = props;

  const actions = !readonly && (
    <div className="flex items-center gap-2 self-end">
      <ImageFull image={image} />

      <IfHasPermission userPermission="document_update">
        <ImageRotate docId={docId} image={image} />
        <ImageDelete docId={docId} imageId={image.id} />
      </IfHasPermission>
    </div>
  );

  const Overlay = readonly ? PartialOverlay : CompleteOverlay;

  return (
    <div className="group static">
      <div className="relative min-h-[200px] break-inside-avoid">
        <ImgLink
          src={image.document.url}
          thumbSrc={image.documentThumb.url}
          imgClassName="p-2"
        />

        <Overlay>
          <T keyName={safeTranslationKeyDocumentView(image.view)} />

          <div className="flex-1" />

          {actions}

          {readonly && (
            <div className="absolute bottom-0 right-0 p-4">
              <ImageFull image={image} />
            </div>
          )}
        </Overlay>
      </div>
    </div>
  );
}

function DocumentStandardizedImageEdition(
  props: DocumentStandardizedImageProps,
) {
  const { image, docId, readonly } = props;

  return (
    <CardLayout
      cardClassName="break-inside-avoid"
      bodyClassName="!px-0 !py-0"
      footerClassName="!px-2 !py-2"
      title={safeTranslationKeyDocumentView(image.view)}
      footer={
        <div className="flex flex-1 flex-wrap justify-end gap-2">
          <ImageFull image={image} />

          {!readonly && (
            <IfHasPermission userPermission="document_update">
              <ImageRotate docId={docId} image={image} />
              <ImageDelete docId={docId} imageId={image.id} />
            </IfHasPermission>
          )}
        </div>
      }
    >
      <ImgLink src={image.document.url} thumbSrc={image.documentThumb.url} />
    </CardLayout>
  );
}

interface ImageFullProps {
  image: GqlDetailedDocumentFieldsFragment['images'][0];
}

function ImageFull({ image }: ImageFullProps) {
  const seqId = useSeqIdParam();

  return (
    <Link
      to={`/documents/${seqId}/standardized-images/${image.id}`}
      className="pointer-events-auto"
    >
      <Button variant="white" className="pointer-events-auto">
        <div className="flex items-center gap-2">
          <ViewfinderCircleIcon className="h-5 w-5" />
          <T keyName="page.doc.view.image.details" />
        </div>
      </Button>
    </Link>
  );
}
