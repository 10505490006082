import { ArchiveBoxIcon, BoltIcon } from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';
import { match } from 'ts-pattern';

import { safeTranslationKeyDocumentSetType } from '../../translationsMapping/documentSets';
import { TRANSLATIONS_KEY_DOCUMENT_TYPE } from '../../translationsMapping/documents';
import CountryName from '../CountryName';
import IndexedFormattedMessage from '../translation/IndexedFormattedMessage';

import { DocumentSetType as GqlDocumentSetType, GqlDocumentSet } from '#gql';

export default function DocumentSetType({
  value,
}: {
  value: Pick<GqlDocumentSet, 'docType' | 'docCountry' | 'type'>;
}) {
  return (
    <>
      <IndexedFormattedMessage
        translationMap={TRANSLATIONS_KEY_DOCUMENT_TYPE}
        index={value.docType}
      />
      {' | '}
      <CountryName country={value.docCountry} />
      {' | '}
      <span className="inline-flex items-baseline gap-2">
        {match(value.type)
          .with(GqlDocumentSetType.DYNAMIC, () => (
            <BoltIcon className="h-5 self-center" />
          ))
          .with(GqlDocumentSetType.MANUAL, () => (
            <ArchiveBoxIcon className="h-5 self-center" />
          ))
          .exhaustive()}
        <T keyName={safeTranslationKeyDocumentSetType(value.type)} />
      </span>
    </>
  );
}
