import { createContext, useContext } from 'react';

import { TableSearchContext } from '../components/providers/TableSearchContextProvider';
import {
  PadifFilters,
  PadifFiltersStore,
} from '../stores/searches/PadifFiltersStore';

import { PadifAnalysisSortField } from '#gql';

export const padifContext = createContext<TableSearchContext<
  PadifAnalysisSortField,
  PadifFilters,
  PadifFiltersStore
> | null>(null);

export function usePadifContext() {
  const context = useContext(padifContext);

  if (context === null) {
    throw new Error(
      'usePadifContext must be used within a TableSearchContextProvider',
    );
  }

  return context;
}
