import { T } from '@tolgee/react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { useSeqIdParam } from '../../hooks/useParams';
import FilesUpload from '../FilesUpload';
import FormattedButton from '../translation/FormattedButton';
import FormattedErrorAlert from '../translation/FormattedErrorAlert';

import SerieAttachments from './SerieAttachments';
import { SerieHeadingBreadcrumb } from './SerieHeading';
import SerieImages from './SerieImages';

import { useSerieFilesQuery, useUploadSerieAttachmentsMutation } from '#gql';
import { CardLayout } from '#ui/card_layout';
import { PageLayout, PageLayoutNavigation } from '#ui/page_layout';

/**
 * This should be restricted to a safe list of extensions to mitigate the risk of viruses or XSS vulnerabilities.
 * Keep in sync with backend.
 */
const mimetypeAllowList = {
  'image/png': ['.png'],
  'image/jpeg': ['.jpeg', '.jpg'],
  'image/bmp': ['.bmp'],
  'application/pdf': ['.pdf'],
  'text/plain': ['.txt'],
};

function UploadSerieAttachments() {
  const seqId = useSeqIdParam();

  const [uploadSerieImages] = useUploadSerieAttachmentsMutation();
  const [hasError, setHasError] = useState<Error | null>(null);

  const { loading, error, data, refetch } = useSerieFilesQuery({
    variables: { seqId },
  });

  if (error) {
    return <FormattedErrorAlert error={error} />;
  } else if (loading && !data) {
    return <T keyName="global.loading" />;
  }

  if (!data) {
    return null;
  }

  const { serie } = data;

  async function handleSend(files: File[]) {
    try {
      await uploadSerieImages({
        variables: { input: { id: serie.id, attachments: files } },
      });

      void refetch();
    } catch (error) {
      setHasError(error);
    }
  }

  return (
    <PageLayout
      title={<T keyName="global.attachments" />}
      navigation={
        <>
          <PageLayoutNavigation to="/series">
            <T keyName="nav.series.list" />
          </PageLayoutNavigation>
          <PageLayoutNavigation to={`/series/${serie.seqId}`}>
            <SerieHeadingBreadcrumb serie={serie} />
          </PageLayoutNavigation>
        </>
      }
      actions={
        <FormattedButton
          messageId="serie.upload.go_back"
          variant="white"
          as={Link}
          to=".."
        />
      }
      bodyClassName="grid grid-cols-2 items-stretch justify-items-stretch gap-x-4 gap-y-2"
      noDefaultBodyClassName
    >
      <CardLayout
        title="serie.upload.add_files"
        bodyClassName="flex flex-col gap-5"
      >
        <FormattedErrorAlert error={hasError} />
        <FilesUpload accept={mimetypeAllowList} onSend={handleSend} />
      </CardLayout>

      <CardLayout title="global.attachments">
        <SerieAttachments serieId={serie.id} attachments={serie.attachments} />
      </CardLayout>

      <div className="col-span-2">
        <CardLayout title="global.images">
          <SerieImages
            serieId={serie.id}
            images={serie.images}
            className="!columns-2 md:columns-3 lg:!columns-4"
          />
        </CardLayout>
      </div>
    </PageLayout>
  );
}

export default UploadSerieAttachments;
