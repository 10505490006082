import { match } from 'ts-pattern';

import { ScanProcessStatus } from '#gql';
import { Color } from '#tailwind_ui';

export function getScanStatusBadgeColor(status: ScanProcessStatus): Color {
  return match(status)
    .with('PENDING', () => Color.neutral)
    .with('PROCESSING', 'FINALIZING', 'IMPORTING', () => Color.primary)
    .with('IMPORTED', () => Color.success)
    .with('TO_BE_REVIEWED', () => Color.warning)
    .with('ERROR', () => Color.danger)
    .exhaustive();
}
