import { RoiContainer, RoiList, TargetImage } from 'react-roi';

import { RoiData } from '../PadifNewRoiPage';

interface PadifRoisDisplayerProps {
  url?: string;
}

export function PadifRoisDisplayer(props: PadifRoisDisplayerProps) {
  const { url } = props;

  return (
    <RoiContainer
      zoomWithoutModifierKey
      className="h-[50vh] flex-1 border border-solid border-neutral-200"
      target={<TargetImage src={url || ''} />}
      noUnselection
    >
      <RoiList<RoiData>
        getReadOnly={(roi) => roi.data?.alreadyInDatabase || false}
        getStyle={(roi) => ({
          rectAttributes: {
            fill: roi.data?.backgroundColor,
            opacity: roi.data?.alreadyInDatabase ? 0.3 : 0.6,
            ...(roi.data?.alreadyInDatabase && {
              stroke: 'black',
              strokeWidth: 3,
            }),
          },
        })}
      />
    </RoiContainer>
  );
}
