import { useTranslate } from '@tolgee/react';
import { ReactNode, useCallback, useMemo } from 'react';
import { match } from 'ts-pattern';

import { UseTableSelect } from '../hooks/useTableSelection';

import FormattedCheckbox from './translation/FormattedCheckbox';
import { FormattedTable } from './translation/FormattedTable';

import { TableProps, Td, Th } from '#tailwind_ui';

export interface TableSelectionRenderHeaderProps<Item, Id> {
  data: Item[];
  selection?: UseTableSelect<Id>;
  checkboxTh: ReactNode;
}

export interface TableSelectionRenderTrProps<Item, Id> {
  value: Item;
  index: number;
  checkboxTd: ReactNode;
  data: Item[];
  selection?: UseTableSelect<Id>;
  isSelected?: boolean;
  selectedClassname?: string;
}

export interface TableSelectionProps<Item, Id>
  extends Omit<TableProps<Item>, 'renderHeader' | 'renderTr'> {
  selection?: UseTableSelect<Id>;
  trSelectedClassname?: string;
  renderHeader: (props: TableSelectionRenderHeaderProps<Item, Id>) => ReactNode;
  renderTr: (props: TableSelectionRenderTrProps<Item, Id>) => ReactNode;
}

interface CheckboxHeader {
  state: 'checked' | 'unchecked' | 'indeterminate';
  onClick: () => void;
}

const noop = () => void 0;

export function TableSelection<
  Item extends { id: Id },
  Id extends string | number = string,
>(props: TableSelectionProps<Item, Id>) {
  const {
    selection,
    trSelectedClassname,
    data,
    renderHeader: propsRenderHeader,
    renderTr: propsRenderTr,
  } = props;
  const { value: selected, methods } = selection ?? {};

  const { t } = useTranslate();

  const checkbox = useMemo(() => {
    if (!selected) return;
    if (!methods) return;

    let countIntersection = 0;
    const ids: Id[] = [];
    for (const { id } of data) {
      if (selected.has(id)) countIntersection++;
      ids.push(id);
    }

    return match(countIntersection)
      .returnType<CheckboxHeader>()
      .with(0, () => ({
        state: 'unchecked',
        onClick: () => methods.addAll(ids),
      }))
      .with(ids.length, () => ({
        state: 'checked',
        onClick: () => methods.deleteAll(ids),
      }))
      .otherwise(() => ({
        state: 'indeterminate',
        onClick: () => methods.addAll(ids),
      }));
  }, [selected, methods, data]);

  const renderHeader = useCallback(
    () =>
      propsRenderHeader({
        data,
        selection,
        checkboxTh: checkbox && (
          <Th
            onClick={checkbox.onClick}
            title={t(
              match(checkbox.state)
                .returnType<TranslationKey>()
                .with('checked', () => 'global.deselect_page_items')
                .with('unchecked', () => 'global.select_page_items')
                .with('indeterminate', () => 'global.select_page_items')
                .exhaustive(),
            )}
          >
            <FormattedCheckbox
              name="select_page"
              checked={checkbox.state === 'checked'}
              indeterminate={checkbox.state === 'indeterminate'}
              onChange={noop}
            />
          </Th>
        ),
      }),
    [checkbox, data, propsRenderHeader, selection, t],
  );

  const renderTr = useCallback(
    (value: Item, index: number) =>
      propsRenderTr({
        value,
        index,
        data,
        selection,
        selectedClassname: trSelectedClassname,
        isSelected: selection?.value.has(value.id),
        checkboxTd: selection && (
          <Td
            onClick={() =>
              selection?.methods.onSelect(
                value.id,
                !selection?.value.has(value.id),
              )
            }
            className="cursor-pointer"
          >
            <input
              type="checkbox"
              checked={selection?.value.has(value.id)}
              onChange={noop}
            />
          </Td>
        ),
      }),
    [data, propsRenderTr, selection, trSelectedClassname],
  );

  return (
    <FormattedTable<Item>
      {...props}
      renderHeader={renderHeader}
      renderTr={renderTr}
    />
  );
}
