import { createContext, useContext } from 'react';

import { TableSearchContext } from '../components/providers/TableSearchContextProvider';
import {
  ScanFilters,
  ScanFiltersStore,
} from '../stores/searches/ScanFiltersStore';

import { ScanSortField } from '#gql';

export const scanContext = createContext<TableSearchContext<
  ScanSortField,
  ScanFilters,
  ScanFiltersStore
> | null>(null);

export function useScanContext() {
  const context = useContext(scanContext);

  if (context === null) {
    throw new Error(
      'useScanContext must be used within a TableSearchContextProvider',
    );
  }

  return context;
}
