import { TableSearchContextProvider } from '../components/providers/TableSearchContextProvider';
import { documentDocumentSetContext } from '../contexts/documentDocumentSet';
import { MandatoryChildren } from '../global';
import { UsePaginationTableInitial } from '../hooks/usePaginationTable';
import { SortTable } from '../hooks/useSortTable';
import {
  DocumentDocumentSetsFilters,
  DocumentDocumentSetsFiltersStore,
} from '../stores/searches/DocumentDocumentSetsFiltersStore';

import { DocumentSetsSortFields } from '#gql';

const initialSort = {
  field: 'name',
  direction: 'DESC',
} as const satisfies SortTable<DocumentSetsSortFields>;

const initialPagination: UsePaginationTableInitial = {
  page: 0,
  itemsPerPage: 6,
  label: 'page.doc.list.filter_info',
};

export function DocumentDocumentSetTableSearchProvider(
  props: MandatoryChildren,
) {
  return (
    <TableSearchContextProvider<
      DocumentSetsSortFields,
      DocumentDocumentSetsFilters,
      typeof DocumentDocumentSetsFiltersStore,
      DocumentDocumentSetsFiltersStore
    >
      context={documentDocumentSetContext}
      Store={DocumentDocumentSetsFiltersStore}
      initialSort={initialSort}
      initialPagination={initialPagination}
    >
      {props.children}
    </TableSearchContextProvider>
  );
}
