import { useTranslate } from '@tolgee/react';

import { GenericTableSearchContext } from '../providers/TableSearchContextProvider';

import { Input } from '#tailwind_ui';

interface SearchInputProps<C extends GenericTableSearchContext> {
  context: C;
}

export default function TableSearchInput<C extends GenericTableSearchContext>(
  props: SearchInputProps<C>,
) {
  const { t } = useTranslate();

  const {
    filters: { searchTerm },
    store,
  } = props.context;

  return (
    <Input
      name="Search"
      label="Search"
      hiddenLabel
      type="text"
      value={searchTerm ?? ''}
      onChange={(event) => store.setSearchTerm(event.target.value)}
      inputClassName="!placeholder-neutral-500"
      placeholder={t('global.search')}
    />
  );
}
