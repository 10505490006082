import { XCircleIcon } from '@heroicons/react/20/solid';
import { PencilIcon } from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';
import { FormattedDate, FormattedNumber } from 'react-intl';
import { Link } from 'react-router-dom';

import CountryFilterSelectList from '../../../components/TableSearch/CountryFilterSelectList';
import FacetGraphqlEnumFilterSelectList from '../../../components/TableSearch/FacetGraphqlEnumFilterSelectList';
import { IndexFilterError } from '../../../components/TableSearch/IndexFilterError';
import { TableSearchLayout } from '../../../components/TableSearch/TableSearchLayout';
import IfHasPermission from '../../../components/permissions/IfHasPermission';
import FormattedButton from '../../../components/translation/FormattedButton';
import FormattedEmptyState from '../../../components/translation/FormattedEmptyState';
import { FormattedTable } from '../../../components/translation/FormattedTable';
import { useLabelsContext } from '../../../contexts/labels';
import {
  documentTypeValues,
  fraudTypeValues,
} from '../../../graphql/enumValues';
import { useSyncTableSearchParams } from '../../../hooks/useSyncTableSearchParams';
import { LabelFilters } from '../../../stores/searches/LabelFiltersStore';
import {
  TRANSLATIONS_KEY_DOCUMENT_FRAUD_TYPE,
  TRANSLATIONS_KEY_DOCUMENT_TYPE,
} from '../../../translationsMapping/documents';
import { LabelScope } from '../components/badge/scope';

import { DeleteDialog } from './index/delete_dialog';

import { FraudType, DocumentType, GqlLabelsQuery, useLabelsQuery } from '#gql';
import { LinkLabelBadge } from '#label';
import {
  Color,
  IconButton,
  SimpleListContainer,
  SimpleListContainerItem,
  Td,
  Th,
} from '#tailwind_ui';
import { HighlightedLink } from '#ui/link';
import { PageLayout } from '#ui/page_layout';

export default function LabelIndexPage() {
  return (
    <PageLayout
      title={<T keyName="nav.labels.list" />}
      actions={
        <IfHasPermission userPermission="label_create">
          <FormattedButton
            as={Link}
            to="new"
            messageId="nav.labels.new"
            color={Color.primary}
          />
        </IfHasPermission>
      }
    >
      <LabelIndexTableSearch />
    </PageLayout>
  );
}

function LabelIndexTableSearch() {
  const context = useLabelsContext();

  const queryResult = useLabelsQuery({
    variables: {
      ...context.pagination,
      filterBy: context.filters,
      sortBy: context.sort,
    },
  });
  const data = queryResult.data || queryResult.previousData;

  useSyncTableSearchParams(context);

  if (queryResult.error) {
    return <IndexFilterError error={queryResult.error} context={context} />;
  }
  if (!data?.labels) return null;

  // map facet
  const correctedFacets = context.store.getCorrectedFacets(data.labels.facets);
  const totalCount = data.labels.totalCount;

  return (
    <TableSearchLayout
      context={context}
      advancedFilter={
        <SimpleListContainer>
          <SimpleListContainerItem className="space-y-2">
            <FacetGraphqlEnumFilterSelectList<
              DocumentType,
              LabelFilters,
              'docType'
            >
              facets={correctedFacets}
              prop="docType"
              name="doc.field.type"
              enumValues={documentTypeValues}
              translationMap={TRANSLATIONS_KEY_DOCUMENT_TYPE}
              onAdd={context.store.addFilter}
              onRemove={context.store.removeFilter}
              value={context.filters.docType}
            />
            <FacetGraphqlEnumFilterSelectList<
              FraudType,
              LabelFilters,
              'docFraudType'
            >
              facets={correctedFacets}
              prop="docFraudType"
              name="doc.field.fraud_type"
              enumValues={fraudTypeValues}
              translationMap={TRANSLATIONS_KEY_DOCUMENT_FRAUD_TYPE}
              onAdd={context.store.addFilter}
              onRemove={context.store.removeFilter}
              value={context.filters.docFraudType}
            />
            <CountryFilterSelectList<LabelFilters, 'docCountry'>
              facets={correctedFacets}
              prop="docCountry"
              name="doc.field.country"
              onAdd={context.store.addFilter}
              onRemove={context.store.removeFilter}
              value={context.filters.docCountry}
            />
          </SimpleListContainerItem>
        </SimpleListContainer>
      }
    >
      <FormattedTable
        data={data.labels.nodes}
        renderHeader={renderHeader}
        renderTr={(value) => renderTr(value, queryResult.refetch)}
        renderEmpty={renderEmptyState}
        pagination={{
          ...context.tablePagination,
          totalCount,
        }}
        sort={context.tableSort}
      />
    </TableSearchLayout>
  );
}

function renderHeader() {
  return (
    <tr>
      <Th sortField="name">
        <T keyName="label.field.name" />
      </Th>
      <Th>
        <T keyName="label.field.description" />
      </Th>
      <Th>
        <T keyName="label.field.subset" />
      </Th>
      <Th>
        <T keyName="labels.table.doc_count" />
      </Th>
      <Th sortField="createdAt">
        <T keyName="labels.table.created_at" />
      </Th>
      <Th>
        <T keyName="global.actions" />
      </Th>
    </tr>
  );
}

function renderTr(
  label: GqlLabelsQuery['labels']['nodes'][number],
  refetch: () => Promise<unknown>,
) {
  return (
    <tr>
      <Td>
        <LinkLabelBadge label={label} to="/documents" />
      </Td>
      <Td>{label.description}</Td>
      <Td>
        <LabelScope label={label} />
      </Td>
      <Td>
        <FormattedNumber value={label.docCount} />
      </Td>
      <Td>
        <FormattedDate value={label.createdAt} />
      </Td>
      <Td className="flex items-center gap-2">
        <IfHasPermission userPermission="label_update">
          <Link to={label.id}>
            <IconButton
              size="5"
              icon={<PencilIcon className="h-5" />}
              tooltip={<T keyName="global.edit" />}
            />
          </Link>
        </IfHasPermission>
        <DeleteDialog label={label} onDelete={() => void refetch()} />
      </Td>
    </tr>
  );
}

function renderEmptyState() {
  return (
    <FormattedEmptyState title="labels.search.noresults" icon={<XCircleIcon />}>
      <IfHasPermission userPermission="label_create">
        <HighlightedLink to="new">
          <T keyName="nav.labels.new" />
        </HighlightedLink>
      </IfHasPermission>
    </FormattedEmptyState>
  );
}
