import { TableSearchContextProvider } from '../components/providers/TableSearchContextProvider';
import { labelsContext, LabelSupportedSortField } from '../contexts/labels';
import { MandatoryChildren } from '../global';
import { UsePaginationTableInitial } from '../hooks/usePaginationTable';
import { UseSortTableInitial } from '../hooks/useSortTable';
import {
  LabelFilters,
  LabelFiltersStore,
} from '../stores/searches/LabelFiltersStore';

const initialSort = {
  field: 'name',
  direction: 'ASC',
} as const satisfies UseSortTableInitial<LabelSupportedSortField>;
const initialPagination = {
  label: 'page.labels.filter_info',
} as const satisfies Partial<UsePaginationTableInitial>;

export function LabelTableSearchProvider({ children }: MandatoryChildren) {
  return (
    <TableSearchContextProvider<
      LabelSupportedSortField,
      LabelFilters,
      typeof LabelFiltersStore,
      LabelFiltersStore
    >
      context={labelsContext}
      Store={LabelFiltersStore}
      initialSort={initialSort}
      initialPagination={initialPagination}
    >
      {children}
    </TableSearchContextProvider>
  );
}
