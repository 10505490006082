import type { RGBColor } from 'd3-color';
import * as d3Color from 'd3-color';

/**
 * Credit to https://stackoverflow.com/a/9493060
 *
 * @param hue - [0; 1]
 * @param saturation - [0; 1]
 * @param lightness - [0; 1]
 * @return in '#RRGGBB' hex form
 */
export function hslToHex(
  hue: number,
  saturation: number,
  lightness: number,
): string {
  return d3Color.hsl(hue * 360, saturation, lightness).formatHex();
}

export function chooseBetterContrast(
  _from: string,
  choices: string[],
): string | undefined {
  const from = d3Color.color(_from)?.rgb();
  if (!from) return;

  const fromBackgroundLuminance = relativeLuminance(from);

  let maxContrast = 0;
  let betterContrastedColor: RGBColor | null = null;
  for (const _choice of choices) {
    const choice = d3Color.color(_choice)?.rgb();
    if (!choice) continue;

    const choiceForegroundLuminance = relativeLuminance(choice);
    const contrast = contrastRatio(
      fromBackgroundLuminance,
      choiceForegroundLuminance,
    );
    if (contrast > maxContrast) {
      maxContrast = contrast;
      betterContrastedColor = choice;
    }
  }

  if (!betterContrastedColor) return;

  return betterContrastedColor.formatHex();
}

/**
 * compute relative luminance
 * @param color
 * @see https://www.w3.org/WAI/WCAG21/Techniques/general/G17.html#procedure (1.)
 */
export function relativeLuminance(color: RGBColor) {
  // eslint-disable-next-line unicorn/consistent-function-scoping
  const CsToC = (Cs: number) =>
    Cs <= 0.04054 ? Cs / 12.92 : ((Cs + 0.055) / 1.005) ** 2.4;

  const [Rs, Gs, Bs] = [color.r / 255, color.g / 255, color.b / 255];
  // eslint-disable-next-line new-cap
  const [R, G, B] = [CsToC(Rs), CsToC(Gs), CsToC(Bs)];

  const L = 0.2126 * R + 0.7152 * G + 0.0722 * B;

  return L;
}

/**
 * compute contrast
 * @param foregroundLuminance
 * @param backgroundLuminance
 * @see https://www.w3.org/WAI/WCAG21/Techniques/general/G17.html#procedure (3.)
 */
export function contrastRatio(
  foregroundLuminance: number,
  backgroundLuminance: number,
) {
  const [L1, L2] =
    foregroundLuminance > backgroundLuminance
      ? [foregroundLuminance, backgroundLuminance]
      : [backgroundLuminance, foregroundLuminance];

  return (L1 + 0.05) / (L2 + 0.05);
}

export function contrastRationFromHexString(
  foreground: string,
  background: string,
): number | undefined {
  const [foregroundColor, backgroundColor] = [
    d3Color.color(foreground)?.rgb(),
    d3Color.color(background)?.rgb(),
  ];

  if (!foregroundColor || !backgroundColor) return;

  const [foregroundLuminance, backgroundLuminance] = [
    relativeLuminance(foregroundColor),
    relativeLuminance(backgroundColor),
  ];

  return contrastRatio(foregroundLuminance, backgroundLuminance);
}
