import { TRANSLATIONS_KEY_DOCUMENT_TYPE } from '../../translationsMapping/documents';
import CountryName from '../CountryName';
import FormattedSearchSelectField from '../translation/FormattedSearchSelectField';
import IndexedFormattedMessage from '../translation/IndexedFormattedMessage';

import { GqlDocumentSetsSelectQuery, useDocumentSetsSelectQuery } from '#gql';
import { useSearchSelect } from '#tailwind_ui';

export interface DocumentSetSelectOption {
  label: string;
  value: string;
  set: GqlDocumentSetsSelectQuery['documentSets']['nodes'][number];
}

export default function DocumentSetSelectField(props: { name: string }) {
  const result = useDocumentSetsSelectQuery();

  const options = (result.data?.documentSets.nodes || []).map(
    (documentSet) => ({
      label: documentSet.name,
      value: documentSet.id,
      set: documentSet,
    }),
  );

  const selectField = useSearchSelect({ options });

  return (
    <FormattedSearchSelectField
      name={props.name}
      loading={result.loading}
      label="global.title.doc_set"
      renderOption={renderDocumentSetOption}
      renderSelectedOption={renderSelectedDocumentSetOption}
      {...selectField}
    />
  );
}

function renderDocumentSetOption(option: DocumentSetSelectOption) {
  const { set } = option;
  const images =
    set.documents.nodes.length > 0
      ? set.documents.nodes[0].images.filter((img) => img.view !== 'OTHER')
      : [];
  return (
    <div>
      <DocumentSetIdentity set={set} />
      <div>
        {images.map((img) => (
          <img
            key={img.documentThumb.url}
            alt="document set thumbnail"
            src={img.documentThumb.url}
            className="mx-1 max-h-[100px]"
          />
        ))}
      </div>
    </div>
  );
}

function renderSelectedDocumentSetOption(option: DocumentSetSelectOption) {
  return <DocumentSetIdentity set={option.set} />;
}

function DocumentSetIdentity({ set }: { set: DocumentSetSelectOption['set'] }) {
  return (
    <div>
      {`${set.name} | `}
      <IndexedFormattedMessage
        translationMap={TRANSLATIONS_KEY_DOCUMENT_TYPE}
        index={set.docType}
      />
      {' | '}
      <CountryName country={set.docCountry} />
    </div>
  );
}
