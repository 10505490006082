import { ZoomImage } from '#tailwind_ui';

export default function ImgPreview({
  src,
  thumbSrc,
  imgClassName,
}: {
  src: string;
  thumbSrc: string;
  imgClassName?: string;
}) {
  return (
    <ZoomImage
      className={imgClassName}
      thumbSrc={thumbSrc}
      src={src}
      alt="document preview"
      fullscreenAlt="document face"
    />
  );
}
