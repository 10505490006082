import { useTranslate } from '@tolgee/react';

import { requiredObject, requiredString } from '../../../forms/validation';
import { safeTranslationKeyDocumentSetType } from '../../../translationsMapping/documentSets';
import SelectCountry from '../../form/SelectCountry';
import { SelectDocumentType } from '../../form/SelectFields';
import FormattedErrorAlert from '../../translation/FormattedErrorAlert';
import FormattedInputField from '../../translation/FormattedInputField';
import FormattedSubmitButton from '../../translation/FormattedSubmitButton';

import { FirstStepFormValues } from './common';

import { DocumentSetType } from '#gql';
import { FormRHF, FormRHFProps, GroupOptionFieldRHF } from '#tailwind_ui';

const firstStepValidationSchema = requiredObject({
  docType: requiredString,
  docCountry: requiredString,
  name: requiredString,
  type: requiredString,
});

export interface DocumentSetNewStep1Props {
  defaultValues: FirstStepFormValues;
  onSubmit: FormRHFProps<FirstStepFormValues>['onSubmit'];
  formError?: Error;
}

export default function DocumentSetNewStep1(props: DocumentSetNewStep1Props) {
  const { defaultValues, onSubmit, formError } = props;
  const { t } = useTranslate();

  return (
    <div className="m-auto max-w-lg">
      <FormRHF<FirstStepFormValues>
        defaultValues={defaultValues}
        validationSchema={firstStepValidationSchema}
        onSubmit={onSubmit}
      >
        <GroupOptionFieldRHF
          name="type"
          label={t('doc_set.field.type')}
          required
        >
          <GroupOptionFieldRHF.Option
            label={t(safeTranslationKeyDocumentSetType(DocumentSetType.MANUAL))}
            description={t('doc_set.field.type.MANUAL.description')}
            value={DocumentSetType.MANUAL}
          />
          <GroupOptionFieldRHF.Option
            label={t(
              safeTranslationKeyDocumentSetType(DocumentSetType.DYNAMIC),
            )}
            description={t('doc_set.field.type.DYNAMIC.description')}
            value={DocumentSetType.DYNAMIC}
          />
        </GroupOptionFieldRHF>

        <SelectDocumentType name="docType" />
        <SelectCountry name="docCountry" label="doc.field.country" />

        <FormattedInputField name="name" label="global.name" />

        <FormattedErrorAlert error={formError} />

        <FormattedSubmitButton color="primary" messageId="global.next_step" />
      </FormRHF>
    </div>
  );
}
