import { AdjustmentsVerticalIcon } from '@heroicons/react/20/solid';

import { GenericTableSearchContext } from '../providers/TableSearchContextProvider';

import { Button, Color, Roundness } from '#tailwind_ui';

export interface TableSearchButtonToggleProps<
  Context extends GenericTableSearchContext,
> {
  context: Context;
}

export default function TableSearchButtonToggle<
  Context extends GenericTableSearchContext = GenericTableSearchContext,
>({ context }: TableSearchButtonToggleProps<Context>) {
  const { toggleAdvancedSearch, isFiltered } = context;

  return (
    <Button
      onClick={toggleAdvancedSearch}
      color={isFiltered ? Color.primary : Color.neutral}
      roundness={Roundness.circular}
    >
      <AdjustmentsVerticalIcon className="h-5 w-5" />
    </Button>
  );
}
