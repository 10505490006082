import { T } from '@tolgee/react';
import clsx from 'clsx';
import { JSX, ReactNode } from 'react';

interface OverridableClassname {
  className?: string;
  overrideClassName?: boolean;
}

export type DlProps = JSX.IntrinsicElements['dl'];

/**
 * Use to build a definition list.
 *
 * @param props
 *
 * @example
 * ```
 * <Dl>
 *   <DlItemInline>
 *     <Dt>label-1</Dt>
 *     <Dd>value-1</Dd>
 *   </DlInline>
 *
 *   <DlItemInline>
 *     <Dt>label-2</Dt>
 *     <Dd>value-2</Dd>
 *   </DlInline>
 * </Dl>
 * ```
 */
export default function Dl(props: DlProps) {
  return <dl {...props} />;
}
export { Dl };

export type DlItemInlineProps = JSX.IntrinsicElements['div'] &
  OverridableClassname;

/**
 * simple wrapper to have horizontal title - value definition
 *
 * @param props
 * @param props.className default `'flex flex-row gap-1'`
 */
export function DlItemInline(props: DlItemInlineProps) {
  const className =
    props.overrideClassName && props.className
      ? props.className
      : clsx('flex flex-row gap-1', props.className);
  return <div {...props} className={className} />;
}

export type DtProps = JSX.IntrinsicElements['dt'] & OverridableClassname;

/**
 * className props is append to the default, but is overridable with `overrideClassName` props.
 * This component apply bold text and add `:` to your children by css
 *
 * @param props
 * @param props.className @default "font-bold after:content-[':']"
 */
export function Dt(props: DtProps) {
  const className =
    props.overrideClassName && props.className
      ? props.className
      : clsx("font-bold after:content-[':']", props.className);

  return <dt {...props} className={className} />;
}

export type DdProps = JSX.IntrinsicElements['dd'];

/**
 * simple `<dd />` with conditional new line feed append to the flex flow
 * @param props
 */
export function Dd(props: DdProps) {
  return <dd {...props} />;
}

export interface FormattedDefinitionListProps {
  /**
   * data keys will be rendered with FormattedMessage
   */
  data: Partial<Record<TranslationKey, ReactNode>>;
  dlProps?: DlProps;
  dlItemInlineProps?: DlItemInlineProps;
  dtProps?: DtProps;
  ddProps?: DdProps;
}

/**
 * render a dl with dt-dd for each entry of data; each key is rendered by a FormattedMessage bound on id props
 *
 * @param props
 *
 * @example
 * ```tsx
 * <FormattedDefinitionList
 *   data={{
 *     'global.status': scan.status,
 *     'global.createdAt': scan.createdAt.toString(),
 *     'global.updatedAt': scan.updatedAt.toString(),
 *     'global.created_by': scan.createdBy.name,
 *     'global.error': scan.error,
 *   }}
 * />
 * ```
 *
 * @see Dl
 * @see DlItemInline
 * @see Dt
 * @see Dd
 */
export function FormattedDefinitionList(props: FormattedDefinitionListProps) {
  const datalist = Object.entries(props.data) as Array<
    [TranslationKey, ReactNode]
  >;

  return (
    <Dl {...props.dlProps}>
      {datalist.map(([dt, dd]) => (
        <DlItemInline key={dt} {...props.dlItemInlineProps}>
          <Dt {...props.dtProps}>
            <T keyName={dt} />
          </Dt>
          <Dd {...props.ddProps}>{dd}</Dd>
        </DlItemInline>
      ))}
    </Dl>
  );
}

export interface DefinitionListProps {
  /**
   * data keys will be rendered with FormattedMessage
   */
  data: Partial<Record<string, ReactNode>>;
  dlProps?: DlProps;
  dlItemInlineProps?: DlItemInlineProps;
  dtProps?: DtProps;
  ddProps?: DdProps;

  // true if you want to ignore key, false else
  ignoreKeys?: (key: string) => boolean;
}
export function DefinitionList(props: DefinitionListProps) {
  const datalist = Object.entries(props.data);

  const ignoreKeys = props.ignoreKeys ?? defaultIgnoreKeys;

  return (
    <Dl {...props.dlProps}>
      {datalist.map(
        ([dt, dd]) =>
          !ignoreKeys(dt) && (
            <DlItemInline key={dt} {...props.dlItemInlineProps}>
              <Dt {...props.dtProps}>{dt}</Dt>
              <Dd {...props.ddProps}>{dd}</Dd>
            </DlItemInline>
          ),
      )}
    </Dl>
  );
}

function defaultIgnoreKeys(key: string) {
  return key.endsWith('__typename');
}
