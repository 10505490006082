import { ChevronDownIcon, UserGroupIcon } from '@heroicons/react/20/solid';
import { T } from '@tolgee/react';
import { FormattedDate } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import HalfFullCircle from '../../icons/HalfFullCircleIcon';
import {
  DraftOrPublicationAlert,
  getTranslation,
  isAlertDraft,
} from '../utils/draftOrPublication';
import {
  getAlertDraftViewUrl,
  getAlertPublicationViewUrl,
} from '../utils/navigation';

import { GqlAlertDraft, Language, useAlertVersionsQuery } from '#gql';
import { Dropdown, DropdownActionOption } from '#tailwind_ui';
import {
  PageLayoutNavigation,
  PageLayoutBreadcrumbItem,
} from '#ui/page_layout';
import { assert } from '#utils/assert';

interface AlertOption {
  isDraft: boolean;
  id: string;
}

export interface AlertBreadcrumbsProps {
  alert: DraftOrPublicationAlert;
  language?: Language;
}

export default function AlertBreadcrumbs(props: AlertBreadcrumbsProps) {
  const { alert } = props;
  const navigate = useNavigate();
  const language = props.language ?? getTranslation(alert).language;
  const alertAllVersions = useAlertVersionsQuery({
    variables: {
      id: alert.id,
      language,
    },
  });

  const isDraft = isAlertDraft(alert);
  const draftOrPublicationId = isAlertDraft(alert)
    ? alert.draft?.id
    : alert.publication?.id;

  const options: Array<Array<DropdownActionOption<AlertOption>>> = [];
  if (alertAllVersions.data) {
    if (alertAllVersions.data.alert.draft) {
      const isViewing =
        isDraft &&
        draftOrPublicationId === alertAllVersions.data.alert.draft.id;
      options.push([
        {
          type: 'action',
          data: {
            isDraft: true,
            id: alertAllVersions.data.alert.draft.id,
          },
          label: (
            <div className={isViewing ? 'underline' : ''}>
              <HalfFullCircle className="mr-2 inline h-4 w-4 text-neutral-600" />
              <span>
                <T keyName="alert.breadcrumb.status.draft" />{' '}
              </span>
              <FormattedDate
                value={alertAllVersions.data.alert.draft.updatedAt}
              />
            </div>
          ),
        },
      ]);
    }

    options.push(
      alertAllVersions.data.alert.publications.map((publication) => {
        const isViewing = !isDraft && draftOrPublicationId === publication.id;
        return {
          type: 'action',
          data: {
            isDraft: false,
            id: publication.id,
          },
          label: (
            <div className={isViewing ? 'underline' : ''}>
              <span>
                <UserGroupIcon className="mr-2 inline h-4 w-4 text-neutral-600" />
              </span>
              <span>
                <T keyName="alert.breadcrumb.status.published" />{' '}
              </span>
              <FormattedDate value={publication.publishedAt} />
            </div>
          ),
        };
      }),
    );
  }

  return (
    <>
      <PageLayoutNavigation to="/series">
        <T keyName="alert.breadcrumb.series" />
      </PageLayoutNavigation>
      <PageLayoutNavigation to={`/series/${alert.serie.seqId}`}>
        {alert.serie.name}
      </PageLayoutNavigation>
      <PageLayoutBreadcrumbItem>
        <Dropdown
          options={options}
          onSelect={(option) => {
            assert(option.data);
            if (option.data.isDraft) {
              navigate(getAlertDraftViewUrl(alert.id, language));
            } else {
              navigate(
                getAlertPublicationViewUrl(alert.id, option.data.id, language),
              );
            }
          }}
        >
          <div className="flex cursor-pointer items-end text-neutral-600 hover:text-neutral-700">
            <div>
              <T keyName="alert.breadcrumb.dropdown.title" />
            </div>
            <div>
              <ChevronDownIcon className="h-4 w-4" />
            </div>
          </div>
        </Dropdown>
      </PageLayoutBreadcrumbItem>
    </>
  );
}

export interface AlertBreadcrumbsTitleProps {
  alert: DraftOrPublicationAlert;
  translation?: GqlAlertDraft['translations'][number];
}

export function AlertBreadcrumbsTitle(props: AlertBreadcrumbsTitleProps) {
  const { alert } = props;
  const translation = props.translation ?? getTranslation(alert);
  const isDraft = isAlertDraft(alert);

  return (
    <div className="flex items-center gap-2 text-lg text-neutral-600">
      <div>{translation.fields.title}</div>
      {isDraft && <HalfFullCircle className="h-5 w-5" />}
      {!isDraft && alert.status !== 'INITIATED' && (
        <UserGroupIcon className="h-5 w-5" />
      )}
    </div>
  );
}
