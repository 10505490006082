import { T } from '@tolgee/react';
import { useMemo, useState } from 'react';

import { useAuditLogContext } from '../../../contexts/auditLog';
import { useSyncTableSearchParams } from '../../../hooks/useSyncTableSearchParams';
import DateRangePicker from '../../DateRangePicker';
import { IndexFilterError } from '../../TableSearch/IndexFilterError';
import { TableSearchLayout } from '../../TableSearch/TableSearchLayout';
import { ExperimentalAlert } from '../../experimental-features/ExperimentalWarning';

import AuditLogsList from './AuditLogsList';

import { AuditLogAction, useAuditLogsQuery, useUsersPickerQuery } from '#gql';
import {
  MultiSearchSelect,
  SearchSelect,
  SimpleListContainer,
  SimpleListContainerItem,
  SimpleStringSelectOption,
} from '#tailwind_ui';
import { PageLayout } from '#ui/page_layout';

export default function AdminAuditLogsIndexPage() {
  return (
    <PageLayout title={<T keyName="admin.audit_logs.title" />}>
      <ExperimentalAlert />

      <AdminAuditLogsTableSearch />
    </PageLayout>
  );
}

function AdminAuditLogsTableSearch() {
  const context = useAuditLogContext();
  useSyncTableSearchParams(context);

  const { data, previousData, error } = useAuditLogsQuery({
    variables: {
      ...context.pagination,
      filterBy: {
        searchTerm: context.filters.searchTerm,
        actions: context.filters.actions,
        user: context.filters.userId,
        startDate: context.filters.startDate,
        endDate: context.filters.endDate,
      },
      sortBy: context.sort,
    },
  });

  const userSelected = useMemo(() => {
    return {
      value: context.filters.userId ?? '',
      label: context.filters.userName ?? '',
    };
  }, [context.filters.userId, context.filters.userName]);

  if (error) return <IndexFilterError error={error} context={context} />;

  return (
    <TableSearchLayout
      context={context}
      advancedFilter={
        <SimpleListContainer>
          <SimpleListContainerItem className="space-y-2">
            <ActionPicker
              selected={context.filters.actions}
              onChange={context.store.setActions}
            />
            <UserPicker
              selected={userSelected}
              onSelect={context.store.setUser}
            />
            <DateRangePicker
              start={context.filters.startDate ?? undefined}
              end={context.filters.endDate ?? undefined}
              startPlaceholderId="global.daterangepicker.start.label"
              endPlaceholderId="global.daterangepicker.end.label"
              onStartChange={context.store.setStartDate}
              onEndChange={context.store.setEndDate}
            />
          </SimpleListContainerItem>
        </SimpleListContainer>
      }
    >
      <AuditLogsList auditLogs={(data || previousData)?.auditLogs} />
    </TableSearchLayout>
  );
}

interface UserPickerProps {
  selected: SimpleStringSelectOption | null;
  onSelect: (option: SimpleStringSelectOption | undefined) => void;
}

function UserPicker(props: UserPickerProps) {
  const [search, setSearch] = useState('');

  const results = useUsersPickerQuery({
    variables: {
      term: search,
    },
  });

  if (results.error) throw results.error;
  const users =
    results.data?.users?.nodes || results.previousData?.users?.nodes;

  const options = useMemo<SimpleStringSelectOption[]>(() => {
    if (!users) return [];
    return users.map((u) => ({ value: u.id, label: `${u.email} - ${u.name}` }));
  }, [users]);

  return (
    <SearchSelect<SimpleStringSelectOption>
      selected={props.selected ?? undefined}
      options={options}
      clearSearchOnSelect
      clearable
      placeholder="User"
      label="User"
      hiddenLabel
      searchValue={search}
      onSearchChange={setSearch}
      onChange={props.onSelect}
      renderSelectedOption={renderSelectedOption}
    />
  );
}

function renderSelectedOption(option: SimpleStringSelectOption) {
  return option?.label ?? '';
}

interface ActionPickerProps {
  selected: AuditLogAction[];
  onChange: (values: AuditLogAction[]) => void;
}

const actionPickerOptions = Object.keys(AuditLogAction);
function ActionPicker(props: ActionPickerProps) {
  const [search, setSearch] = useState('');

  return (
    <MultiSearchSelect<string>
      selected={props.selected}
      options={actionPickerOptions}
      onChange={props.onChange as (v: string[]) => void}
      searchValue={search}
      onSearchChange={setSearch}
      name="actions"
      placeholder="Actions"
      label="Actions"
      hiddenLabel
      clearable
      clearSearchOnSelect
    />
  );
}
