import { Language } from '#gql';

export interface FormState {
  language?: Language;
  title: string;
}

export const defaultFormState: FormState = {
  language: undefined,
  title: '',
};
