import { useParams, useSearchParams } from 'react-router-dom';

import useLanguageParams from '../../../hooks/useLanguageParams';

import { Language } from '#gql';
import { assert } from '#utils/assert';

export function useAlertDraftParams(): { id: string; language: Language } {
  const { id } = useParams<{
    id: string;
  }>();
  const language = useLanguageParams();

  assert(id);
  return { id, language };
}

export function useAlertPublicationParams() {
  const { id, publicationId } = useParams<{
    id: string;
    publicationId: string;
  }>();
  const [searchParams] = useSearchParams();

  assert(id);
  assert(publicationId);
  const language = searchParams.get('language') as Language | undefined;

  return { id, publicationId, language };
}
