import { ReactNode } from 'react';

import { useAppMeta } from '../../providers/AppMetaProvider.context';

import { assertNotNull } from '#utils/assert';
import { useGetActivateMailto } from '#utils/index';

interface ActivateMailtoLinkProps {
  email: string;
  token: string | null;
  children: ReactNode;
}

export default function ActivateMailtoLink({
  email,
  token,
  children,
}: ActivateMailtoLinkProps) {
  const appMeta = useAppMeta();
  const getActivateMailto = useGetActivateMailto();

  assertNotNull(token);

  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={getActivateMailto(email, token, appMeta.title)}
    >
      {children}
    </a>
  );
}
