import { T } from '@tolgee/react';

import { PersonalDataOption } from './utils/personal_data';
import { backgroundPatternSvgUrl } from './utils/stripe_background_pattern';

interface ScanRoiAnonymizationGroupOptionsProps {
  options: PersonalDataOption[];
  checked: PersonalDataOption;
  onChange: (option: PersonalDataOption) => void;
}

export function ScanRoiAnonymizationGroupOptions(
  props: ScanRoiAnonymizationGroupOptionsProps,
) {
  const { options, checked, onChange } = props;

  return (
    <fieldset>
      <div className="-space-y-px rounded-md bg-white">
        {options.map((element) => (
          <Option
            key={element.type}
            {...element}
            checked={checked === element}
            onChange={() => onChange(element)}
          />
        ))}
      </div>
    </fieldset>
  );
}

interface OptionProps extends Omit<PersonalDataOption, 'type'> {
  checked: boolean;
  onChange: () => void;
}

function Option(props: OptionProps) {
  const { label, checked, onChange, color, kbdLabel } = props;
  const { r, g, b } = color;

  return (
    <label
      style={{
        backgroundColor: `rgba(${r}, ${g}, ${b}, ${checked ? '0.3' : '0.1'})`,
        backgroundImage: backgroundPatternSvgUrl,
      }}
      className="relative flex cursor-pointer border border-neutral-200 p-4 first:rounded-tl-md first:rounded-tr-md last:rounded-bl-md last:rounded-br-md focus:outline-none"
    >
      <input
        type="radio"
        checked={checked}
        className="mt-0.5 h-4 w-4 shrink-0 cursor-pointer border-neutral-300 text-primary-600"
        onChange={onChange}
      />

      <span className="ml-3 flex flex-row items-center gap-2 text-sm font-medium">
        <kbd>{kbdLabel}</kbd>
        <T keyName={label} />
      </span>
    </label>
  );
}
