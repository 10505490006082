import { useTranslate } from '@tolgee/react';

// eslint-disable-next-line no-restricted-imports
import { Table, TableProps } from '#tailwind_ui';

export function FormattedTable<T>(props: TableProps<T>) {
  const { pagination, ...others } = props;
  const { t } = useTranslate();

  return (
    // @ts-expect-error TS doesn't understand the prop type
    <Table<T>
      {...others}
      pagination={
        pagination
          ? {
              ...pagination,
              nextText: t('global.next_page'),
              previousText: t('global.previous_page'),
            }
          : undefined
      }
    />
  );
}
