import { ScaleOrdinal } from 'd3-scale';

import SeriesLink from '../../serie/SeriesLink';
import { PadifAnalysisResultDocumentSeries } from '../padifGqlTypes';

import { CardLayout } from '#ui/card_layout';

interface PadifClusteringLegendProps {
  series: PadifAnalysisResultDocumentSeries[];
  seriesColorScale: ScaleOrdinal<string, string>;
  seriesLabelScale: ScaleOrdinal<string, string>;
}

export default function PadifClusteringLegend(
  props: PadifClusteringLegendProps,
) {
  const { series, seriesColorScale, seriesLabelScale } = props;

  if (series.length === 0) {
    return null;
  }

  return (
    <div className="sticky top-3">
      <CardLayout
        title="nav.series"
        headerClassName="text-center"
        bodyClassName="space-y-3"
      >
        {series.map((serie) => (
          <div key={serie.seqId} className="flex flex-row items-center gap-1">
            <div
              className="mr-1.5 h-2 w-5"
              style={{
                backgroundColor: seriesColorScale(serie.seqId),
              }}
            />
            <SeriesLink
              series={serie}
              multiline
              getLinkLabel={(serie) =>
                `${serie.seqId}${seriesLabelScale(serie.seqId)}`
              }
            />
          </div>
        ))}
      </CardLayout>
    </div>
  );
}
