import { LanguageIcon } from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';
import { useNavigate } from 'react-router-dom';

import FormattedButton from '../../translation/FormattedButton';
import FormattedFormError from '../../translation/FormattedFormError';
import FormattedSelectField from '../../translation/FormattedSelectField';
import FormattedSubmitButton from '../../translation/FormattedSubmitButton';
import { AlertTitleInput } from '../AlertTitleInput';
import { getLanguageKey, languageKeys } from '../utils/languages';
import { getAlertDraftEditUrl } from '../utils/navigation';

import { defaultFormState, FormState } from './shared';
import { createAlertValidationSchema } from './validation';

import {
  GqlAlertDraftQuery,
  Language,
  useCreateAlertDraftTranslationMutation,
} from '#gql';
import { FormRHFModal } from '#tailwind_ui';
import { assert } from '#utils/assert';

interface CreateAlertDraftTranslationModalProps {
  isOpen: boolean;
  alert: GqlAlertDraftQuery['alert'];
  onClose: () => void;
}

export default function CreateAlertDraftTranslationModal(
  props: CreateAlertDraftTranslationModalProps,
) {
  const navigate = useNavigate();
  const { alert, onClose, isOpen } = props;
  const draft = alert.draft;
  assert(draft);
  const remainingLanguages = languageKeys.filter((lang) => {
    return !draft.languages.includes(lang);
  });

  const [createTranslation] = useCreateAlertDraftTranslationMutation();

  return (
    <FormRHFModal<FormState>
      isOpen={isOpen}
      defaultValues={defaultFormState}
      onRequestClose={onClose}
      validationSchema={createAlertValidationSchema}
      onSubmit={async (values) => {
        if (!values.language) return;

        await createTranslation({
          variables: {
            input: {
              id: alert.id,
              draftId: draft.id,
              language: values.language,
              title: values.title,
            },
          },
        });

        navigate(getAlertDraftEditUrl(alert.id, values.language));
      }}
      icon={<LanguageIcon />}
    >
      <FormRHFModal.Header>
        <T keyName="series.alert.add_language.title" />
      </FormRHFModal.Header>
      <FormRHFModal.Body className="w-96">
        <FormattedSelectField<string>
          options={remainingLanguages}
          getValue={(val) => val}
          label="global.language"
          name="language"
          required
          renderOption={(option) => (
            <T keyName={getLanguageKey(option as Language)} />
          )}
        />
        <AlertTitleInput />

        <FormattedFormError />
      </FormRHFModal.Body>
      <FormRHFModal.Footer>
        <FormattedButton
          variant="white"
          messageId="global.cancel"
          onClick={onClose}
        />
        <FormattedSubmitButton
          color="primary"
          messageId="series.alert.create_translation.submit"
        />
      </FormRHFModal.Footer>
    </FormRHFModal>
  );
}
