import { RectangleGroupIcon } from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';
import { useWatch } from 'react-hook-form';

import FormattedDropzoneField from '../../translation/FormattedDropzoneField';
import FormattedFormError from '../../translation/FormattedFormError';
import FormattedSubmitButton from '../../translation/FormattedSubmitButton';

import { useUpdateAlertRapidDetectionImageMutation } from '#gql';
import { FormRHFModal } from '#tailwind_ui';

/**
 * This should be restricted to binary image formats supported by Chromium renderer.
 * Keep in sync with backend.
 */
const mimetypeAllowList = {
  'image/png': ['.png'],
  'image/jpeg': ['.jpeg', '.jpg'],
};

interface RapidDetectionImageFields {
  image: File[];
}

interface EditRapidDetectionImageModalProps {
  id: string;
  draftId: string;
  isOpen: boolean;
  onClose: () => void;
}

export default function EditRapidDetectionImageModal(
  props: EditRapidDetectionImageModalProps,
) {
  const { id, draftId, isOpen, onClose } = props;
  const [updateImage] = useUpdateAlertRapidDetectionImageMutation();

  return (
    <FormRHFModal<RapidDetectionImageFields>
      onSubmit={async (values) => {
        const rapidIdentificationImage = values.image?.[0];
        if (!rapidIdentificationImage) return;

        await updateImage({
          variables: {
            input: {
              id,
              draftId,
              rapidIdentificationImage,
            },
          },
        });
        onClose();
      }}
      isOpen={isOpen}
      icon={<RectangleGroupIcon />}
      onRequestClose={onClose}
    >
      <FormRHFModal.Header>
        <T keyName="series.alert.edit_rapid_identification_image.title" />
      </FormRHFModal.Header>
      <FormRHFModal.Body>
        <div className="w-[500px]">
          <FormattedDropzoneField
            name="image"
            label="series.alert.field.rapid_identification_image"
            ns="alert"
            hiddenLabel
            accept={mimetypeAllowList}
            replace
            showList
            showPreview
            multiple={false}
          />
        </div>
        <FormattedFormError />
      </FormRHFModal.Body>
      <FormRHFModal.Footer>
        <SubmitButton />
      </FormRHFModal.Footer>
    </FormRHFModal>
  );
}

function SubmitButton() {
  const image = useWatch<RapidDetectionImageFields>({ name: 'image' });
  const disabled = Array.isArray(image) ? !image[0] : !image;

  return (
    <FormattedSubmitButton
      color="primary"
      messageId="global.send"
      disabled={disabled}
    />
  );
}
