import { T } from '@tolgee/react';

import { useDocumentContext } from '../providers/DocumentProvider.context';

import {
  GqlDetailedDocumentFieldsFragment,
  GqlDocumentImage,
  DocumentView,
} from '#gql';

function getExtraImageFromView(
  document: GqlDetailedDocumentFieldsFragment,
  view: DocumentView,
) {
  switch (view) {
    case 'RECTO':
      return document.imageRecto;
    case 'VERSO':
      return document.imageVerso;
    case 'OTHER':
      return document.imageRecto;
    default:
      throw new Error('unreachable');
  }
}

export default function DocumentImage({
  view,
}: Pick<GqlDocumentImage, 'view'>) {
  const { document } = useDocumentContext();

  const imageToRender = getExtraImageFromView(document, view);
  if (!imageToRender) {
    return (
      <div className="text-neutral-500">
        <T keyName="page.doc.view.noimage" />
      </div>
    );
  }

  return <RenderImage url={imageToRender.document.url} view={view} />;
}

interface RenderImageProps {
  url: string;
  view: string;
}

function RenderImage(props: RenderImageProps) {
  const { url, view } = props;

  return (
    <div>
      <a href={url} target="_blank" rel="noopener noreferrer">
        <img className="w-full" src={url} alt={view} />
      </a>
    </div>
  );
}
