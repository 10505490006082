import { T } from '@tolgee/react';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { FormattedDate } from 'react-intl';
import { match, P } from 'ts-pattern';

import { TableSearchPagination } from '../../hooks/usePaginationTable';
import { UseTableSelect } from '../../hooks/useTableSelection';
import {
  safeTranslationKeyDocumentFraud,
  safeTranslationKeyDocumentView,
} from '../../translationsMapping/documents';
import {
  TableSelection,
  TableSelectionRenderHeaderProps,
  TableSelectionRenderTrProps,
} from '../TableSelection';
import { DocumentImagePreview } from '../document/DocumentImagePreview';
import DocumentLink from '../document/DocumentLink';
import SeriesLink from '../serie/SeriesLink';

import { GqlDocumentSetDocumentsQuery } from '#gql';
import { LinkLabelBadge } from '#label';
import { PaginationProps, TableSortConfig, Td, Th } from '#tailwind_ui';

type DocumentSet = GqlDocumentSetDocumentsQuery['documentSet'];
type Document = DocumentSet['documents']['nodes'][number];

export interface DocumentSetTableDocumentViewProps {
  documents: Document[];
  totalCount: number;
  tableSort: TableSortConfig;
  tablePagination: TableSearchPagination;
  renderTextPagination: PaginationProps['renderText'];
  renderEmpty: () => ReactNode;
  selection?: UseTableSelect<string>;
  selectedClassname?: string;
}

export function DocumentSetTableDocumentView({
  documents,
  tableSort,
  tablePagination,
  totalCount,
  renderTextPagination,
  renderEmpty,
  selection,
  selectedClassname,
}: DocumentSetTableDocumentViewProps) {
  return (
    <TableSelection<Document, Document['id']>
      data={documents}
      renderHeader={renderHeader}
      renderTr={renderTr}
      renderEmpty={renderEmpty}
      sort={tableSort}
      pagination={{
        ...tablePagination,
        totalCount,
        renderText: renderTextPagination,
      }}
      selection={selection}
      trSelectedClassname={selectedClassname}
    />
  );
}

function renderHeader(
  props: TableSelectionRenderHeaderProps<Document, Document['id']>,
) {
  return (
    <tr>
      {props.checkboxTh}
      <Th className="min-w-[20ch]">
        <T keyName={safeTranslationKeyDocumentView('RECTO')} />
      </Th>
      <Th className="min-w-[20ch]">
        <T keyName={safeTranslationKeyDocumentView('VERSO')} />
      </Th>
      <Th>
        <T keyName="doc.list.table.header.exhibit_and_labels" />
      </Th>
      <Th>
        <T keyName="doc.table.service" />
      </Th>
      <Th sortField="docFraudType">
        <T keyName="doc.field.fraud_type" />
      </Th>
      <Th>
        <T keyName="global.series" />
      </Th>
      <Th sortField="caseSeizureDate">
        <T keyName="doc.field.seizure_date" />
      </Th>
    </tr>
  );
}

function renderTr(
  props: TableSelectionRenderTrProps<Document, Document['id']>,
) {
  const { value: document, isSelected, selectedClassname, checkboxTd } = props;

  return (
    <tr className={clsx(isSelected && selectedClassname)}>
      {checkboxTd}
      <Td>
        <DocumentImagePreview image={document.imageRecto} />
      </Td>
      <Td>
        <DocumentImagePreview image={document.imageVerso} />
      </Td>
      <Td>
        <DocumentLink document={document} multiline />
        <div className="flex flex-row flex-wrap items-center gap-2">
          {document.labels.map((label) => (
            <LinkLabelBadge key={label.id} label={label} to="/documents" />
          ))}
        </div>
      </Td>
      <Td className="align-middle" title={document.caseService.name}>
        {document.caseService.identifier}
      </Td>
      <Td>
        <T keyName={safeTranslationKeyDocumentFraud(document.docFraudType)} />
      </Td>
      <Td className="align-middle">
        <SeriesLink series={document.series} multiline />
      </Td>
      <Td className="align-middle">
        {match(document.caseSeizureDate)
          .with(P.not(P.nullish), (value) => <FormattedDate value={value} />)
          .otherwise(() => '-')}
      </Td>
    </tr>
  );
}
