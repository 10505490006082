import { TableSearchContextProvider } from '../components/providers/TableSearchContextProvider';
import { auditLogContext } from '../contexts/auditLog';
import { MandatoryChildren } from '../global';
import { SortTable } from '../hooks/useSortTable';
import {
  AuditLogFilters,
  AuditLogFiltersStore,
} from '../stores/searches/AuditLogFiltersStore';

import { AuditLogSortField } from '#gql';

const initialSort = {
  field: 'date',
  direction: 'DESC',
} as const satisfies SortTable<AuditLogSortField>;

export function AuditLogTableSearchProvider({ children }: MandatoryChildren) {
  return (
    <TableSearchContextProvider<
      AuditLogSortField,
      AuditLogFilters,
      typeof AuditLogFiltersStore,
      AuditLogFiltersStore
    >
      context={auditLogContext}
      Store={AuditLogFiltersStore}
      initialSort={initialSort}
    >
      {children}
    </TableSearchContextProvider>
  );
}
