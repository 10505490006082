import { apiUrl } from '../../../constants';

import { Language } from '#gql';

export interface DownloadPdfOptions {
  alertId: string;
  draftOrPublicationId: string;
  language?: Language;
}

export function getDownloadPdfUrl(options: DownloadPdfOptions) {
  const { alertId, draftOrPublicationId, language } = options;
  const url = new URL(
    [apiUrl, 'files/alerts', alertId, draftOrPublicationId, language, 'pdf']
      .filter(Boolean)
      .join('/'),
  );
  url.searchParams.set('download', 'true');

  return url.href;
}

export const ALERT_DOWNLOAD_PDF_NOTIFICATION_GROUP = 'download-pdf';
