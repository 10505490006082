import { ArchiveBoxXMarkIcon } from '@heroicons/react/24/outline';

import { AsyncFormattedConfirmModal } from '../../AsyncFormattedConfirmModal';

import { useUnarchiveAlertMutation } from '#gql';

interface ArchiveAlertModalProps {
  isOpen: boolean;
  id: string;
  onClose: (success?: boolean) => void;
}

export default function UnarchiveAlertModal(props: ArchiveAlertModalProps) {
  const { isOpen, id, onClose } = props;
  const [unarchiveAlert] = useUnarchiveAlertMutation();

  return (
    <AsyncFormattedConfirmModal
      open={isOpen}
      icon={<ArchiveBoxXMarkIcon />}
      confirmText="series.alert.unarchive.confirm.button"
      cancelText="global.cancel"
      title="series.alert.unarchive.confirm.title"
      body="series.alert.unarchive.confirm.body"
      onClose={onClose}
      color="primary"
      onConfirm={() =>
        unarchiveAlert({
          variables: {
            id,
          },
        })
      }
    />
  );
}
