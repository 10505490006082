import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';
import { scanImportApplyImageAnonymization } from '@zakodium/profid-shared';
import { readImg, writeCanvas } from 'image-js';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useKbsGlobal } from 'react-kbs';
import {
  CommittedRoi,
  RoiContainer,
  RoiList,
  RoiProvider,
  TargetImage,
  useActions,
  useCommittedRois,
  useRoiState,
} from 'react-roi';
import { useBoolean } from 'usehooks-ts';

import { useNavigateAfterSave } from '../../../../hooks/useNavigateAfterSave';
import { usePreventNavigation } from '../../../../hooks/usePreventNavigation';

import { LeftToolbar } from './left_toolbar';
import { RightToolbar } from './right_toolbar';
import { TopToolbar } from './top_toolbar';
import { getScanRoiStyle } from './utils/get_scan_roi_style';
import {
  PersonalDataOption,
  PersonalDataReactRoiData,
  personalDataTypeOptions,
} from './utils/personal_data';

import { GqlProcessReviewDataFragment, PersonalDataType } from '#gql';
import {
  zoomImageInOverlayStyling,
  ZoomOverlay,
  ZoomOverlayRef,
} from '#tailwind_ui';
import { assert } from '#utils/assert';

interface DocumentAnonymizationProps {
  importData: GqlProcessReviewDataFragment;
  url: string;
  scanId: string;
}

interface RoiData {
  fill: string;
  type: PersonalDataType;
}

export default function DocumentAnonymization(
  props: DocumentAnonymizationProps,
) {
  const { importData, url, scanId } = props;

  const [selectedOption, setSelectedOption] = useState<PersonalDataOption>(
    personalDataTypeOptions[0],
  );

  const getNewRoiData = useMemo<() => PersonalDataReactRoiData>(() => {
    return () => {
      const { r, g, b } = selectedOption.color;

      return {
        fill: `rgba(${r}, ${g}, ${b}, 1)`,
        type: selectedOption.type,
      };
    };
  }, [selectedOption.color, selectedOption.type]);

  return (
    <RoiProvider<RoiData>
      initialConfig={{
        mode: 'hybrid',
        zoom: {
          min: 0.95,
          spaceAroundTarget: 0.05,
        },
        commitRoiBoxStrategy: 'round',
        rois: importData.anonymization.rois.map((roi) => {
          const option = personalDataTypeOptions.find(
            (opt) => opt.type === roi.type,
          );

          assert(option);
          const { r, g, b } = option.color;

          return {
            ...roi,
            angle: 0,
            data: {
              fill: `rgba(${r}, ${g}, ${b}, 1)`,
              type: roi.type,
            },
          };
        }),
      }}
    >
      <InnerDocumentAnonymization
        scanId={scanId}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        getNewRoiData={getNewRoiData}
        url={url}
      />
    </RoiProvider>
  );
}

function InnerDocumentAnonymization(props: {
  scanId: string;
  selectedOption: PersonalDataOption;
  setSelectedOption: Dispatch<SetStateAction<PersonalDataOption>>;
  getNewRoiData: () => PersonalDataReactRoiData;
  url: string;
}) {
  const { scanId, selectedOption, setSelectedOption, getNewRoiData, url } =
    props;

  const { value: isSaved, setTrue: setSavedTrue } = useBoolean(false);

  const rois = useCommittedRois<RoiData>();
  const originalRoisStableRef = useRef<string>();

  const roisDataHaveChanged = useMemo(() => {
    if (!originalRoisStableRef.current) {
      originalRoisStableRef.current = rois.map(stableHashRoi).join('\n');
    }
    const stableRois = rois.map(stableHashRoi).join('\n');

    return originalRoisStableRef.current !== stableRois;
  }, [rois]);

  usePreventNavigation(roisDataHaveChanged && !isSaved);
  useNavigateAfterSave(isSaved, `./..`);

  const { selectedRoi } = useRoiState();
  const { removeRoi, cancelAction } = useActions();

  const onDelete = useCallback(() => {
    if (selectedRoi) {
      removeRoi(selectedRoi);
    }
  }, [selectedRoi, removeRoi]);

  const onSelectedOption = useCallback(
    (option: PersonalDataOption) => {
      setSelectedOption(option);
    },
    [setSelectedOption],
  );

  function buildKbsOptionHandler(type: PersonalDataType) {
    return () => {
      const option = personalDataTypeOptions.find((o) => o.type === type);
      assert(option);

      onSelectedOption(option);
    };
  }

  useKbsGlobal([
    {
      shortcut: 'Escape',
      handler: (event) => cancelAction(event, { noUnselection: false }),
    },
    {
      shortcut: ['Delete', 'Backspace'],
      handler: () => onDelete(),
    },
    ...personalDataTypeOptions.map((o) => ({
      shortcut: o.kbd.map((code) => ({ code })),
      handler: buildKbsOptionHandler(o.type),
    })),
  ]);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const zoomRef = useRef<ZoomOverlayRef>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const onPreview = useCallback(() => {
    const img: HTMLImageElement | null =
      containerRef.current?.querySelector('img[data-target-image]') ?? null;
    const canvas = canvasRef.current;

    if (!img) return;
    if (!canvas) return;

    const image = readImg(img);

    const scanRois = rois.map((roi) => {
      const type = roi.data?.type;
      assert(type);

      return {
        type,
        width: roi.width,
        height: roi.height,
        x: roi.x,
        y: roi.y,
      };
    });
    scanImportApplyImageAnonymization(image, scanRois);

    writeCanvas(image, canvas);
    zoomRef.current?.open();
  }, [rois]);
  return (
    <div className="flex flex-1 items-center justify-center" ref={containerRef}>
      <div className="flex min-w-0 max-w-6xl flex-1 flex-col gap-5">
        <TopToolbar
          onDelete={onDelete}
          scanId={scanId}
          onPreview={onPreview}
          onSaveSuccess={setSavedTrue}
        />

        <div className="flex flex-row gap-5">
          <LeftToolbar
            selectedOption={selectedOption}
            setSelectedOption={onSelectedOption}
          />

          <div className="flex min-w-0 flex-1 flex-col gap-5">
            <div className="flex flex-row gap-5">
              <RoiContainer
                zoomWithoutModifierKey
                getNewRoiData={getNewRoiData}
                className="max-h-[50vh] flex-1 border border-neutral-600"
                target={
                  <TargetImage
                    src={url}
                    data-target-image
                    crossOrigin="use-credentials"
                  />
                }
              >
                <RoiList getStyle={getScanRoiStyle} />
              </RoiContainer>

              <RightToolbar />
            </div>

            <div className="border-l-4 border-neutral-400 bg-neutral-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <InformationCircleIcon className="h-6 w-6 text-neutral-400" />
                </div>
                <div className="ml-3 flex items-center">
                  <p className="text-sm text-neutral-700">
                    <T keyName="document.anonymization.tips.MOVE" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ZoomOverlay ref={zoomRef}>
        <canvas className={zoomImageInOverlayStyling()} ref={canvasRef} />
      </ZoomOverlay>
    </div>
  );
}

function stableHashRoi(roi: CommittedRoi<RoiData>) {
  return `${roi.id},${roi.x},${roi.y},${roi.width},${roi.height},${roi.angle},${roi.data?.type ?? ''},${roi.data?.fill ?? ''}`;
}
