import { T } from '@tolgee/react';

import SeriesLink from '../../../../../components/serie/SeriesLink';
import FormattedRelativeDate from '../../../../../components/translation/FormattedRelativeDate';

import { GqlStatsQuery } from '#gql';

interface LastSeriesProps {
  series: GqlStatsQuery['series']['nodes'];
}

export default function LastSeries(props: LastSeriesProps) {
  const { series } = props;

  return (
    <div className="space-y-2">
      <div className="text-center font-bold">
        <T keyName="home.stats.last_series.title" />
      </div>

      <table>
        <tbody>
          {series.map((serie) => (
            <tr key={serie.id}>
              <td className="px-2">
                <SeriesLink series={serie} />
                {serie.documents.totalCount > 0
                  ? ` (${serie.documents.totalCount})`
                  : null}
              </td>
              <td className="px-2 text-end text-neutral-500">
                <FormattedRelativeDate value={serie.createdAt} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
