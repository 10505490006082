import { T, TranslationKey } from '@tolgee/react';
import { ReactNode, useCallback } from 'react';
import { useWatch } from 'react-hook-form';

import { safeMappedTranslationKey } from '../../translationsMapping/common';
import {
  TRANSLATIONS_KEY_DOCUMENT_FRAUD_TYPE,
  TRANSLATIONS_KEY_DOCUMENT_TYPE,
} from '../../translationsMapping/documents';
import CountryName from '../CountryName';
import NewSeriesModal from '../serie/NewSeriesModal';
import FormattedHelp from '../translation/FormattedHelp';
import FormattedLabel from '../translation/FormattedLabel';
import FormattedSearchSelectField from '../translation/FormattedSearchSelectField';

import {
  FraudType,
  DocumentType,
  GqlNewSerieInput,
  GqlSerieNamesQuery,
  GqlUpdateDocumentInput,
  useSerieNamesQuery,
} from '#gql';
import {
  useCheckedFormRHFContext,
  useOnOff,
  useSearchSelect,
} from '#tailwind_ui';
import { LinkLike } from '#ui/link';
import { assert } from '#utils/assert';

export default function SeriesFields() {
  const { error, data } = useSerieNamesQuery();

  if (error) throw error;
  if (!data) return null;

  return <SeriesFieldsRender data={data} />;
}

interface SeriesFieldsRenderProps {
  data: GqlSerieNamesQuery;
}

function SeriesFieldsRender(props: SeriesFieldsRenderProps) {
  const { data } = props;
  const {
    setValue,
    formState: { isSubmitted },
  } = useCheckedFormRHFContext<GqlUpdateDocumentInput>();
  const [isNewSerieModalOpen, openNewSerieModal, closeNewSerieModal] =
    useOnOff();

  const options = data.series.nodes.map((node) => node.id);

  const filterOptions = useCallback(
    (query: string, options: string[]) => {
      const filteredNodes = data.series.nodes.filter((node) =>
        options.includes(node.id),
      );
      const normalizedQuery = normalize(query);
      return filteredNodes
        .filter(
          (obj) =>
            obj.seqId.toLowerCase().includes(normalizedQuery) ||
            normalize(obj.name).includes(normalizedQuery) ||
            obj.aliases?.some((alias) =>
              normalize(alias).includes(normalizedQuery),
            ),
        )
        .map((obj) => obj.id);
    },
    [data.series.nodes],
  );

  const selectSeriesProps = useSearchSelect({
    options,
    filterOptions,
  });

  const [docType, docFraudType, docCountry, newSeries] =
    useWatch<GqlUpdateDocumentInput>({
      name: ['docType', 'docFraudType', 'docCountry', 'newSeries'],
    }) as [DocumentType, FraudType, string, GqlNewSerieInput | null];

  return (
    <div>
      <NewSeriesModal
        isOpen={isNewSerieModalOpen}
        title="doc.field.new_series.title"
        close={closeNewSerieModal}
        onSubmit={(newSerie) => {
          setValue('newSeries', newSerie, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: isSubmitted,
          });
          closeNewSerieModal();
        }}
        defaultValues={{
          docCountry,
          docType,
          docFraudType,
        }}
        excludedNames={data.series.nodes.flatMap((node) => [
          ...(node.aliases || []),
          node.name,
        ])}
      />

      <div className="flex flex-col gap-3">
        {newSeries ? (
          <div>
            <div className="flex items-center justify-between">
              <div>
                <FormattedLabel text="doc.field.new_series" />
              </div>
              <LinkLike
                className="text-sm"
                onClick={() => {
                  setValue('newSeries', null, {
                    shouldDirty: true,
                    shouldTouch: true,
                    shouldValidate: isSubmitted,
                  });
                }}
              >
                <T keyName="global.remove" />
              </LinkLike>
            </div>
            <SerieAttribute name="global.name" value={newSeries.name} />
            <SerieAttribute
              name="doc.field.country"
              value={<CountryName country={newSeries.docCountry} />}
            />
            <FormattedSerieAttribute
              name="doc.field.fraud_type"
              value={newSeries.docFraudType}
              translationMap={TRANSLATIONS_KEY_DOCUMENT_FRAUD_TYPE}
            />
            <FormattedSerieAttribute
              name="doc.field.type"
              value={newSeries.docType}
              translationMap={TRANSLATIONS_KEY_DOCUMENT_TYPE}
            />
            <FormattedHelp help="doc.field.new_series.help" />
          </div>
        ) : (
          <FormattedSearchSelectField
            name="series"
            label="doc.field.series"
            renderSelectedOption={(option) => {
              const serie = data.series.nodes.find(
                (node) => node.id === option,
              );
              assert(serie, `Serie ${option} not found`);
              return `${serie.seqId} | ${serie.name}`;
            }}
            renderOption={(option) => {
              const serie = data.series.nodes.find(
                (node) => node.id === option,
              );
              assert(serie, `Serie ${option} not found`);
              return (
                <div className="leading-tight">
                  {serie.seqId}
                  <br />
                  <span className="text-sm">{serie.name}</span>
                </div>
              );
            }}
            {...selectSeriesProps}
            corner={
              <LinkLike onClick={openNewSerieModal}>
                <T keyName="doc.field.new_series.set" />
              </LinkLike>
            }
            clearable
          />
        )}
      </div>
    </div>
  );
}

function normalize(text: string) {
  return text
    .toLowerCase()
    .normalize('NFD')
    .replaceAll(/[\u0300-\u036F]/g, '');
}

function FormattedSerieAttribute<T extends string>(props: {
  name: TranslationKey;
  value: T;
  translationMap: Record<T, TranslationKey>;
}) {
  const { name, value, translationMap } = props;
  return (
    <div className="flex gap-2 text-sm text-neutral-800">
      <div>
        <T keyName={name} />:
      </div>
      <T keyName={safeMappedTranslationKey(translationMap, value)} />
    </div>
  );
}

function SerieAttribute(props: { name: TranslationKey; value: ReactNode }) {
  const { name, value } = props;
  return (
    <div className="flex gap-2 text-sm text-neutral-800">
      <div>
        <T keyName={name} />:
      </div>
      {value}
    </div>
  );
}
