import { ReactNode } from 'react';

import LocalePicker from './LocalePicker';
import { useAppMeta } from './providers/AppMetaProvider.context';

import { H1 } from '#ui/heading';

export default function NarrowContainer({ children }: { children: ReactNode }) {
  const appMeta = useAppMeta();
  return (
    <div className="mx-auto min-w-[400px] max-w-lg">
      <div className="flex justify-between">
        <H1>{appMeta.title}</H1>
        <div className="ml-3">
          <LocalePicker />
        </div>
      </div>
      <div className="mt-5">{children}</div>
    </div>
  );
}
