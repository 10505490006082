import { useTolgeeCacheLoad } from '../../../hooks/useTolgeeCacheLoad';
import AlertDocument from '../AlertDocument';
import AlertDocumentInfo from '../AlertDocumentInfo';
import AlertBreadcrumbs, {
  AlertBreadcrumbsTitle,
} from '../header/AlertBreadcrumbs';
import { AlertLanguageSelect } from '../header/AlertLanguageSelect';
import AlertPublicationActions from '../header/publication/AlertPublicationActions';
import AlertPublicationWarning from '../header/publication/AlertPublicationWarning';
import { useAlertPublicationParams } from '../hooks/useAlertParams';

import { useAlertPublicationQuery } from '#gql';
import { PageLayout } from '#ui/page_layout';

export default function ViewAlertPublication() {
  const { id, publicationId, language } = useAlertPublicationParams();

  const query = useAlertPublicationQuery({
    variables: {
      id,
      publicationId,
      language,
    },
  });

  useTolgeeCacheLoad(
    query.data?.alert.publication.translation.language ?? language,
    'alert',
  );

  if (query.error) {
    throw new Error(query.error.message);
  }

  if (!query.data) {
    return null;
  }

  const alert = query.data.alert;

  return (
    <PageLayout
      title={<AlertBreadcrumbsTitle alert={alert} />}
      navigation={<AlertBreadcrumbs alert={alert} />}
      actions={
        <>
          <AlertPublicationActions alert={alert} />
          <AlertLanguageSelect alert={alert} />
        </>
      }
    >
      <AlertPublicationWarning alert={alert} />
      <AlertDocumentInfo alert={alert} />

      <AlertDocument alert={alert} />
    </PageLayout>
  );
}
