import * as OperationTypes from './graphql_operations';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export const DraftAlertFieldsFragmentDoc = gql`
  fragment draftAlertFields on AlertDraft {
    id
    rapidIdentificationImage {
      id
      url
      filename
      size
    }
    languages
  }
`;
export const PublicationAlertFieldsFragmentDoc = gql`
  fragment publicationAlertFields on AlertPublication {
    id
    publishedAt
    publishedBy {
      id
      name
    }
    rapidIdentificationImage {
      id
      url
      filename
      size
    }
    languages
    isCurrent
  }
`;
export const AlertTranslationFieldsFragmentDoc = gql`
  fragment alertTranslationFields on AlertTranslation {
    id
    language
    fields {
      id
      title
      general
      measures
      rapidIdentificationDescription
    }
    rev
  }
`;
export const AlertTranslationTitleFragmentDoc = gql`
  fragment alertTranslationTitle on AlertTranslation {
    id
    fields {
      id
      title
    }
  }
`;
export const ServiceFieldsFragmentDoc = gql`
  fragment serviceFields on Service {
    id
    identifier
    name
  }
`;
export const DocumentIdentifierFieldsFragmentDoc = gql`
  fragment documentIdentifierFields on Document {
    id
    seqId
    caseService {
      ...serviceFields
    }
    caseTitle
    caseNumber
    caseExhibitNumber
  }
  ${ServiceFieldsFragmentDoc}
`;
export const MailableUserFragmentDoc = gql`
  fragment mailableUser on User {
    id
    name
    email
    service {
      id
      name
    }
  }
`;
export const DocumentViewImageIdentifiersFragmentDoc = gql`
  fragment documentViewImageIdentifiers on DocumentViewImage {
    id
    isCorrected
  }
`;
export const ViewImagesAllowExtraFragmentDoc = gql`
  fragment viewImagesAllowExtra on Document {
    imageRecto(corrected: false, allowExtra: true) {
      ...documentViewImageIdentifiers
      view
      isExtra
      document {
        id
        url
      }
      documentThumb {
        id
        url
      }
    }
    imageVerso(corrected: false, allowExtra: true) {
      ...documentViewImageIdentifiers
      view
      isExtra
      document {
        id
        url
      }
      documentThumb {
        id
        url
      }
    }
  }
  ${DocumentViewImageIdentifiersFragmentDoc}
`;
export const DetailedExtraImageFieldsFragmentDoc = gql`
  fragment detailedExtraImageFields on DocumentExtraImage {
    id
    light
    view
    comment
    document {
      id
      url
      filename
    }
  }
`;
export const SeizureAuthorityFieldsFragmentDoc = gql`
  fragment seizureAuthorityFields on SeizureAuthority {
    id
    identifier
    name
  }
`;
export const SerieIdentifierFieldsFragmentDoc = gql`
  fragment serieIdentifierFields on Serie {
    id
    seqId
    name
  }
`;
export const DocumentImageIdentifiersFragmentDoc = gql`
  fragment documentImageIdentifiers on DocumentImage {
    id
    isCorrected
  }
`;
export const ImagesWithUrlFragmentDoc = gql`
  fragment imagesWithUrl on DocumentImage {
    ...documentImageIdentifiers
    view
    full {
      id
      url
    }
    document {
      id
      url
    }
    documentThumb {
      id
      url
    }
  }
  ${DocumentImageIdentifiersFragmentDoc}
`;
export const LabelBadgeFieldsFragmentDoc = gql`
  fragment labelBadgeFields on Label {
    id
    name
    description
    color {
      background
      text
    }
    docType
    docFraudType
    docCountry
  }
`;
export const UpdateableDocumentFieldsFragmentDoc = gql`
  fragment updateableDocumentFields on Document {
    docType
    docCountry
    docFraudType
    caseOperation
    caseSeizureDate
    caseSeizurePlace
    caseSeizureAuthority {
      ...seizureAuthorityFields
    }
    caseSeizureContext
    caseRelatedOffense
    casePresumedNationality
    caseInformation
    caseIsDocKept
    caseIsProfiling
    docSerialNumber
    docEmissionLocation
    docIsMrzOk
    docIsReported
    docMedium
    docPrintBackground
    docPrintNumber
    docPrintData
    docPrintPhoto
    docIsBackgroundFluo
    docIsBitmapCode
    docIsBitmapCodeAnalyzed
    docBitmapCodeAnalysis
    docIsPurchasedOnInternet
    docInternetPurchaseURL
    docOther
    series {
      ...serieIdentifierFields
    }
    images(corrected: false) {
      ...imagesWithUrl
      originalScan {
        id
        status
      }
    }
    labels {
      ...labelBadgeFields
    }
  }
  ${SeizureAuthorityFieldsFragmentDoc}
  ${SerieIdentifierFieldsFragmentDoc}
  ${ImagesWithUrlFragmentDoc}
  ${LabelBadgeFieldsFragmentDoc}
`;
export const DetailedDocumentFieldsFragmentDoc = gql`
  fragment detailedDocumentFields on Document {
    ...documentIdentifierFields
    createdAt
    createdBy {
      ...mailableUser
    }
    updatedAt
    updatedBy {
      ...mailableUser
    }
    similarSerialNumbers {
      totalCount
    }
    padifAnalyses {
      id
      documentSet {
        id
        name
      }
    }
    ...viewImagesAllowExtra
    extraImages {
      ...detailedExtraImageFields
    }
    unfinishedScans {
      id
      filename
      importData {
        __typename
        ... on ProcessReview {
          extractedDocument {
            metadata {
              view
            }
          }
        }
      }
    }
    ...updateableDocumentFields
    labels {
      ...labelBadgeFields
    }
  }
  ${DocumentIdentifierFieldsFragmentDoc}
  ${MailableUserFragmentDoc}
  ${ViewImagesAllowExtraFragmentDoc}
  ${DetailedExtraImageFieldsFragmentDoc}
  ${UpdateableDocumentFieldsFragmentDoc}
  ${LabelBadgeFieldsFragmentDoc}
`;
export const DetailedStandardizedImageFieldsFragmentDoc = gql`
  fragment detailedStandardizedImageFields on DocumentImage {
    ...imagesWithUrl
    originalScan {
      id
      status
    }
  }
  ${ImagesWithUrlFragmentDoc}
`;
export const DocumentFieldsForDocumentSetFragmentDoc = gql`
  fragment documentFieldsForDocumentSet on Document {
    id
    ...documentIdentifierFields
    docSerialNumber
    docFraudType
    series {
      ...serieIdentifierFields
    }
    caseSeizureDate
    labels {
      ...labelBadgeFields
    }
    imageRecto(corrected: false) {
      ...documentViewImageIdentifiers
      view
      document {
        id
        url
      }
      documentThumb {
        id
        url
      }
    }
    imageVerso(corrected: false) {
      ...documentViewImageIdentifiers
      view
      document {
        id
        url
      }
      documentThumb {
        id
        url
      }
    }
  }
  ${DocumentIdentifierFieldsFragmentDoc}
  ${SerieIdentifierFieldsFragmentDoc}
  ${LabelBadgeFieldsFragmentDoc}
  ${DocumentViewImageIdentifiersFragmentDoc}
`;
export const DocumentSetCommonFieldsFragmentDoc = gql`
  fragment documentSetCommonFields on DocumentSet {
    id
    name
    type
    docType
    docCountry
  }
`;
export const DocumentSetDynamicFiltersFragmentDoc = gql`
  fragment documentSetDynamicFilters on DocumentSetDocumentsFilters {
    caseService
    casePresumedNationality
    docFraudType
    docIsMrzOk
    docMedium
    docPrintBackground
    docPrintNumber
    docPrintData
    docPrintPhoto
    docIsBackgroundFluo
    docIsBitmapCode
    series
    searchTerm
    seizureStart
    seizureEnd
    scanView
    extraImageView
    labels
  }
`;
export const PadifAnalysisShowMainReferenceFragmentDoc = gql`
  fragment padifAnalysisShowMainReference on PadifAnalysis {
    id
    rois {
      id
      view
      x
      y
      width
      height
      color
    }
    referenceDocument {
      id
      imageRecto(corrected: false) {
        ...documentViewImageIdentifiers
        view
        document {
          id
          url
        }
      }
      imageVerso(corrected: false) {
        ...documentViewImageIdentifiers
        view
        document {
          id
          url
        }
      }
    }
  }
  ${DocumentViewImageIdentifiersFragmentDoc}
`;
export const MrzAndImagesFragmentDoc = gql`
  fragment mrzAndImages on MRZData {
    mrzLines {
      id
      url
    }
    mustBeReviewed
  }
`;
export const MrzFieldsFragmentDoc = gql`
  fragment mrzFields on ScanImportData {
    ... on ProcessFindRegions {
      mrz {
        ...mrzAndImages
      }
    }
    ... on ProcessReview {
      mrz {
        ...mrzAndImages
      }
    }
    ... on ProcessFinalize {
      mrz {
        ...mrzAndImages
      }
    }
  }
  ${MrzAndImagesFragmentDoc}
`;
export const UserWithServiceFragmentDoc = gql`
  fragment userWithService on User {
    id
    name
    service {
      ...serviceFields
    }
  }
  ${ServiceFieldsFragmentDoc}
`;
export const UpdateableSerieFieldsFragmentDoc = gql`
  fragment updateableSerieFields on Serie {
    docType
    docCountry
    docFraudType
    managers {
      ...userWithService
    }
    contextualProfile
    materialProfile
    aliases
  }
  ${UserWithServiceFragmentDoc}
`;
export const ImportParameterExtractFieldsFragmentDoc = gql`
  fragment importParameterExtractFields on ImportParameters {
    extract {
      x
      y
      width
      height
      angle
    }
  }
`;
export const ImportDataFieldsFragmentDoc = gql`
  fragment importDataFields on ImportDataFields {
    parameters {
      ...importParameterExtractFields
      mrz
      rotate
    }
  }
  ${ImportParameterExtractFieldsFragmentDoc}
`;
export const AnalyzedDocumentFragmentDoc = gql`
  fragment analyzedDocument on AnalyzedScanFields {
    scanJpg {
      id
      filename
      url
    }
  }
`;
export const ExtractedDocumentFragmentDoc = gql`
  fragment extractedDocument on ExtractedDocument {
    image {
      id
      url
    }
    ...analyzedDocument
  }
  ${AnalyzedDocumentFragmentDoc}
`;
export const RotatedDocumentFragmentDoc = gql`
  fragment rotatedDocument on RotatedDocument {
    image {
      id
      filename
      url
    }
  }
`;
export const DocumentMrzFieldsFragmentDoc = gql`
  fragment documentMRZFields on DocumentMRZFields {
    docIsMrzOk
    docSerialNumber
  }
`;
export const ProcessReviewDataFragmentDoc = gql`
  fragment processReviewData on ProcessReview {
    extractedDocument {
      ...extractedDocument
    }
    rotatedDocument {
      ...rotatedDocument
    }
    documentFields {
      ...documentMRZFields
    }
    anonymization {
      rois {
        id
        type
        height
        width
        x
        y
      }
    }
  }
  ${ExtractedDocumentFragmentDoc}
  ${RotatedDocumentFragmentDoc}
  ${DocumentMrzFieldsFragmentDoc}
`;
export const AddDocumentsToSetDocument = gql`
  mutation addDocumentsToSet($input: AddDocumentsToSetInput!) {
    addDocumentsToSet(input: $input) {
      id
      documents {
        nodes {
          ...documentFieldsForDocumentSet
        }
      }
    }
  }
  ${DocumentFieldsForDocumentSetFragmentDoc}
`;

/**
 * __useAddDocumentsToSetMutation__
 *
 * To run a mutation, you first call `useAddDocumentsToSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDocumentsToSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDocumentsToSetMutation, { data, loading, error }] = useAddDocumentsToSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddDocumentsToSetMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlAddDocumentsToSetMutation,
    OperationTypes.GqlAddDocumentsToSetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlAddDocumentsToSetMutation,
    OperationTypes.GqlAddDocumentsToSetMutationVariables
  >(AddDocumentsToSetDocument, options);
}
export type AddDocumentsToSetMutationHookResult = ReturnType<
  typeof useAddDocumentsToSetMutation
>;
export type AddDocumentsToSetMutationResult =
  Apollo.MutationResult<OperationTypes.GqlAddDocumentsToSetMutation>;
export type AddDocumentsToSetMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlAddDocumentsToSetMutation,
  OperationTypes.GqlAddDocumentsToSetMutationVariables
>;
export const ArchiveAlertDocument = gql`
  mutation archiveAlert($id: MongoID!) {
    archiveAlert(input: { id: $id }) {
      id
      status
    }
  }
`;

/**
 * __useArchiveAlertMutation__
 *
 * To run a mutation, you first call `useArchiveAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveAlertMutation, { data, loading, error }] = useArchiveAlertMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveAlertMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlArchiveAlertMutation,
    OperationTypes.GqlArchiveAlertMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlArchiveAlertMutation,
    OperationTypes.GqlArchiveAlertMutationVariables
  >(ArchiveAlertDocument, options);
}
export type ArchiveAlertMutationHookResult = ReturnType<
  typeof useArchiveAlertMutation
>;
export type ArchiveAlertMutationResult =
  Apollo.MutationResult<OperationTypes.GqlArchiveAlertMutation>;
export type ArchiveAlertMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlArchiveAlertMutation,
  OperationTypes.GqlArchiveAlertMutationVariables
>;
export const UnarchiveAlertDocument = gql`
  mutation unarchiveAlert($id: MongoID!) {
    unarchiveAlert(input: { id: $id }) {
      id
      status
    }
  }
`;

/**
 * __useUnarchiveAlertMutation__
 *
 * To run a mutation, you first call `useUnarchiveAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchiveAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchiveAlertMutation, { data, loading, error }] = useUnarchiveAlertMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnarchiveAlertMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlUnarchiveAlertMutation,
    OperationTypes.GqlUnarchiveAlertMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlUnarchiveAlertMutation,
    OperationTypes.GqlUnarchiveAlertMutationVariables
  >(UnarchiveAlertDocument, options);
}
export type UnarchiveAlertMutationHookResult = ReturnType<
  typeof useUnarchiveAlertMutation
>;
export type UnarchiveAlertMutationResult =
  Apollo.MutationResult<OperationTypes.GqlUnarchiveAlertMutation>;
export type UnarchiveAlertMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlUnarchiveAlertMutation,
  OperationTypes.GqlUnarchiveAlertMutationVariables
>;
export const BatchDocumentsOperationDocument = gql`
  mutation batchDocumentsOperation($input: BatchDocumentsOperationInput!) {
    batchDocumentsOperation(input: $input) {
      id
      labels {
        ...labelBadgeFields
      }
    }
  }
  ${LabelBadgeFieldsFragmentDoc}
`;

/**
 * __useBatchDocumentsOperationMutation__
 *
 * To run a mutation, you first call `useBatchDocumentsOperationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBatchDocumentsOperationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [batchDocumentsOperationMutation, { data, loading, error }] = useBatchDocumentsOperationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBatchDocumentsOperationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlBatchDocumentsOperationMutation,
    OperationTypes.GqlBatchDocumentsOperationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlBatchDocumentsOperationMutation,
    OperationTypes.GqlBatchDocumentsOperationMutationVariables
  >(BatchDocumentsOperationDocument, options);
}
export type BatchDocumentsOperationMutationHookResult = ReturnType<
  typeof useBatchDocumentsOperationMutation
>;
export type BatchDocumentsOperationMutationResult =
  Apollo.MutationResult<OperationTypes.GqlBatchDocumentsOperationMutation>;
export type BatchDocumentsOperationMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlBatchDocumentsOperationMutation,
  OperationTypes.GqlBatchDocumentsOperationMutationVariables
>;
export const ChangeUserPasswordDocument = gql`
  mutation changeUserPassword($input: ChangeUserPasswordInput!) {
    changeUserPassword(input: $input) {
      id
    }
  }
`;

/**
 * __useChangeUserPasswordMutation__
 *
 * To run a mutation, you first call `useChangeUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserPasswordMutation, { data, loading, error }] = useChangeUserPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeUserPasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlChangeUserPasswordMutation,
    OperationTypes.GqlChangeUserPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlChangeUserPasswordMutation,
    OperationTypes.GqlChangeUserPasswordMutationVariables
  >(ChangeUserPasswordDocument, options);
}
export type ChangeUserPasswordMutationHookResult = ReturnType<
  typeof useChangeUserPasswordMutation
>;
export type ChangeUserPasswordMutationResult =
  Apollo.MutationResult<OperationTypes.GqlChangeUserPasswordMutation>;
export type ChangeUserPasswordMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlChangeUserPasswordMutation,
  OperationTypes.GqlChangeUserPasswordMutationVariables
>;
export const ChangeUserProtectedFieldsDocument = gql`
  mutation changeUserProtectedFields($input: ChangeUserProtectedFieldsInput!) {
    changeUserProtectedFields(input: $input) {
      id
      isDisabled
      service {
        ...serviceFields
      }
    }
  }
  ${ServiceFieldsFragmentDoc}
`;

/**
 * __useChangeUserProtectedFieldsMutation__
 *
 * To run a mutation, you first call `useChangeUserProtectedFieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserProtectedFieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserProtectedFieldsMutation, { data, loading, error }] = useChangeUserProtectedFieldsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeUserProtectedFieldsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlChangeUserProtectedFieldsMutation,
    OperationTypes.GqlChangeUserProtectedFieldsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlChangeUserProtectedFieldsMutation,
    OperationTypes.GqlChangeUserProtectedFieldsMutationVariables
  >(ChangeUserProtectedFieldsDocument, options);
}
export type ChangeUserProtectedFieldsMutationHookResult = ReturnType<
  typeof useChangeUserProtectedFieldsMutation
>;
export type ChangeUserProtectedFieldsMutationResult =
  Apollo.MutationResult<OperationTypes.GqlChangeUserProtectedFieldsMutation>;
export type ChangeUserProtectedFieldsMutationOptions =
  Apollo.BaseMutationOptions<
    OperationTypes.GqlChangeUserProtectedFieldsMutation,
    OperationTypes.GqlChangeUserProtectedFieldsMutationVariables
  >;
export const ChangeUserRolesDocument = gql`
  mutation changeUserRoles($input: ChangeUserRolesInput!) {
    changeUserRoles(input: $input) {
      id
      roles
    }
  }
`;

/**
 * __useChangeUserRolesMutation__
 *
 * To run a mutation, you first call `useChangeUserRolesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserRolesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserRolesMutation, { data, loading, error }] = useChangeUserRolesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeUserRolesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlChangeUserRolesMutation,
    OperationTypes.GqlChangeUserRolesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlChangeUserRolesMutation,
    OperationTypes.GqlChangeUserRolesMutationVariables
  >(ChangeUserRolesDocument, options);
}
export type ChangeUserRolesMutationHookResult = ReturnType<
  typeof useChangeUserRolesMutation
>;
export type ChangeUserRolesMutationResult =
  Apollo.MutationResult<OperationTypes.GqlChangeUserRolesMutation>;
export type ChangeUserRolesMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlChangeUserRolesMutation,
  OperationTypes.GqlChangeUserRolesMutationVariables
>;
export const ConfirmMrzAreaDocument = gql`
  mutation confirmMRZArea($input: ConfirmScanImportMRZInput!) {
    confirmMRZArea(input: $input) {
      id
      importData {
        __typename
        ...mrzFields
      }
    }
  }
  ${MrzFieldsFragmentDoc}
`;

/**
 * __useConfirmMrzAreaMutation__
 *
 * To run a mutation, you first call `useConfirmMrzAreaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmMrzAreaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmMrzAreaMutation, { data, loading, error }] = useConfirmMrzAreaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmMrzAreaMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlConfirmMrzAreaMutation,
    OperationTypes.GqlConfirmMrzAreaMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlConfirmMrzAreaMutation,
    OperationTypes.GqlConfirmMrzAreaMutationVariables
  >(ConfirmMrzAreaDocument, options);
}
export type ConfirmMrzAreaMutationHookResult = ReturnType<
  typeof useConfirmMrzAreaMutation
>;
export type ConfirmMrzAreaMutationResult =
  Apollo.MutationResult<OperationTypes.GqlConfirmMrzAreaMutation>;
export type ConfirmMrzAreaMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlConfirmMrzAreaMutation,
  OperationTypes.GqlConfirmMrzAreaMutationVariables
>;
export const CorrectScanImportDocumentExtractionDocument = gql`
  mutation correctScanImportDocumentExtraction(
    $input: CorrectScanImportDocumentExtractionInput!
  ) {
    correctScanImportDocumentExtraction(input: $input) {
      id
    }
  }
`;

/**
 * __useCorrectScanImportDocumentExtractionMutation__
 *
 * To run a mutation, you first call `useCorrectScanImportDocumentExtractionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCorrectScanImportDocumentExtractionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [correctScanImportDocumentExtractionMutation, { data, loading, error }] = useCorrectScanImportDocumentExtractionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCorrectScanImportDocumentExtractionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlCorrectScanImportDocumentExtractionMutation,
    OperationTypes.GqlCorrectScanImportDocumentExtractionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlCorrectScanImportDocumentExtractionMutation,
    OperationTypes.GqlCorrectScanImportDocumentExtractionMutationVariables
  >(CorrectScanImportDocumentExtractionDocument, options);
}
export type CorrectScanImportDocumentExtractionMutationHookResult = ReturnType<
  typeof useCorrectScanImportDocumentExtractionMutation
>;
export type CorrectScanImportDocumentExtractionMutationResult =
  Apollo.MutationResult<OperationTypes.GqlCorrectScanImportDocumentExtractionMutation>;
export type CorrectScanImportDocumentExtractionMutationOptions =
  Apollo.BaseMutationOptions<
    OperationTypes.GqlCorrectScanImportDocumentExtractionMutation,
    OperationTypes.GqlCorrectScanImportDocumentExtractionMutationVariables
  >;
export const CreateAlertDocument = gql`
  mutation createAlert($input: CreateAlertInput!) {
    createAlert(input: $input) {
      id
      draft {
        id
      }
    }
  }
`;

/**
 * __useCreateAlertMutation__
 *
 * To run a mutation, you first call `useCreateAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAlertMutation, { data, loading, error }] = useCreateAlertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAlertMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlCreateAlertMutation,
    OperationTypes.GqlCreateAlertMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlCreateAlertMutation,
    OperationTypes.GqlCreateAlertMutationVariables
  >(CreateAlertDocument, options);
}
export type CreateAlertMutationHookResult = ReturnType<
  typeof useCreateAlertMutation
>;
export type CreateAlertMutationResult =
  Apollo.MutationResult<OperationTypes.GqlCreateAlertMutation>;
export type CreateAlertMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlCreateAlertMutation,
  OperationTypes.GqlCreateAlertMutationVariables
>;
export const CreateAlertDraftDocument = gql`
  mutation createAlertDraft($id: MongoID!) {
    createAlertDraft(input: { id: $id }) {
      id
      draft {
        id
      }
    }
  }
`;

/**
 * __useCreateAlertDraftMutation__
 *
 * To run a mutation, you first call `useCreateAlertDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAlertDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAlertDraftMutation, { data, loading, error }] = useCreateAlertDraftMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCreateAlertDraftMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlCreateAlertDraftMutation,
    OperationTypes.GqlCreateAlertDraftMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlCreateAlertDraftMutation,
    OperationTypes.GqlCreateAlertDraftMutationVariables
  >(CreateAlertDraftDocument, options);
}
export type CreateAlertDraftMutationHookResult = ReturnType<
  typeof useCreateAlertDraftMutation
>;
export type CreateAlertDraftMutationResult =
  Apollo.MutationResult<OperationTypes.GqlCreateAlertDraftMutation>;
export type CreateAlertDraftMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlCreateAlertDraftMutation,
  OperationTypes.GqlCreateAlertDraftMutationVariables
>;
export const CreateAlertDraftTranslationDocument = gql`
  mutation createAlertDraftTranslation(
    $input: CreateAlertDraftTranslationInput!
  ) {
    createAlertDraftTranslation(input: $input) {
      id
      draft {
        id
      }
    }
  }
`;

/**
 * __useCreateAlertDraftTranslationMutation__
 *
 * To run a mutation, you first call `useCreateAlertDraftTranslationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAlertDraftTranslationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAlertDraftTranslationMutation, { data, loading, error }] = useCreateAlertDraftTranslationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAlertDraftTranslationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlCreateAlertDraftTranslationMutation,
    OperationTypes.GqlCreateAlertDraftTranslationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlCreateAlertDraftTranslationMutation,
    OperationTypes.GqlCreateAlertDraftTranslationMutationVariables
  >(CreateAlertDraftTranslationDocument, options);
}
export type CreateAlertDraftTranslationMutationHookResult = ReturnType<
  typeof useCreateAlertDraftTranslationMutation
>;
export type CreateAlertDraftTranslationMutationResult =
  Apollo.MutationResult<OperationTypes.GqlCreateAlertDraftTranslationMutation>;
export type CreateAlertDraftTranslationMutationOptions =
  Apollo.BaseMutationOptions<
    OperationTypes.GqlCreateAlertDraftTranslationMutation,
    OperationTypes.GqlCreateAlertDraftTranslationMutationVariables
  >;
export const CreateDocumentDocument = gql`
  mutation createDocument($newDocument: NewDocumentInput!) {
    createDocument(input: $newDocument) {
      ...documentIdentifierFields
      createdAt
    }
  }
  ${DocumentIdentifierFieldsFragmentDoc}
`;

/**
 * __useCreateDocumentMutation__
 *
 * To run a mutation, you first call `useCreateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentMutation, { data, loading, error }] = useCreateDocumentMutation({
 *   variables: {
 *      newDocument: // value for 'newDocument'
 *   },
 * });
 */
export function useCreateDocumentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlCreateDocumentMutation,
    OperationTypes.GqlCreateDocumentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlCreateDocumentMutation,
    OperationTypes.GqlCreateDocumentMutationVariables
  >(CreateDocumentDocument, options);
}
export type CreateDocumentMutationHookResult = ReturnType<
  typeof useCreateDocumentMutation
>;
export type CreateDocumentMutationResult =
  Apollo.MutationResult<OperationTypes.GqlCreateDocumentMutation>;
export type CreateDocumentMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlCreateDocumentMutation,
  OperationTypes.GqlCreateDocumentMutationVariables
>;
export const CreateDocumentSetDocument = gql`
  mutation createDocumentSet($input: CreateDocumentSetInput!) {
    createDocumentSet(input: $input) {
      id
      documents {
        totalCount
        nodes {
          ...documentFieldsForDocumentSet
        }
      }
    }
  }
  ${DocumentFieldsForDocumentSetFragmentDoc}
`;

/**
 * __useCreateDocumentSetMutation__
 *
 * To run a mutation, you first call `useCreateDocumentSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentSetMutation, { data, loading, error }] = useCreateDocumentSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDocumentSetMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlCreateDocumentSetMutation,
    OperationTypes.GqlCreateDocumentSetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlCreateDocumentSetMutation,
    OperationTypes.GqlCreateDocumentSetMutationVariables
  >(CreateDocumentSetDocument, options);
}
export type CreateDocumentSetMutationHookResult = ReturnType<
  typeof useCreateDocumentSetMutation
>;
export type CreateDocumentSetMutationResult =
  Apollo.MutationResult<OperationTypes.GqlCreateDocumentSetMutation>;
export type CreateDocumentSetMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlCreateDocumentSetMutation,
  OperationTypes.GqlCreateDocumentSetMutationVariables
>;
export const CreateLabelDocument = gql`
  mutation createLabel($newLabel: NewLabelInput!) {
    createLabel(input: $newLabel) {
      ...labelBadgeFields
    }
  }
  ${LabelBadgeFieldsFragmentDoc}
`;

/**
 * __useCreateLabelMutation__
 *
 * To run a mutation, you first call `useCreateLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLabelMutation, { data, loading, error }] = useCreateLabelMutation({
 *   variables: {
 *      newLabel: // value for 'newLabel'
 *   },
 * });
 */
export function useCreateLabelMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlCreateLabelMutation,
    OperationTypes.GqlCreateLabelMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlCreateLabelMutation,
    OperationTypes.GqlCreateLabelMutationVariables
  >(CreateLabelDocument, options);
}
export type CreateLabelMutationHookResult = ReturnType<
  typeof useCreateLabelMutation
>;
export type CreateLabelMutationResult =
  Apollo.MutationResult<OperationTypes.GqlCreateLabelMutation>;
export type CreateLabelMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlCreateLabelMutation,
  OperationTypes.GqlCreateLabelMutationVariables
>;
export const CreatePadifAnalysisDocument = gql`
  mutation createPadifAnalysis($input: CreatePadifAnalysisInput!) {
    createPadifAnalysis(input: $input) {
      id
    }
  }
`;

/**
 * __useCreatePadifAnalysisMutation__
 *
 * To run a mutation, you first call `useCreatePadifAnalysisMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePadifAnalysisMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPadifAnalysisMutation, { data, loading, error }] = useCreatePadifAnalysisMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePadifAnalysisMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlCreatePadifAnalysisMutation,
    OperationTypes.GqlCreatePadifAnalysisMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlCreatePadifAnalysisMutation,
    OperationTypes.GqlCreatePadifAnalysisMutationVariables
  >(CreatePadifAnalysisDocument, options);
}
export type CreatePadifAnalysisMutationHookResult = ReturnType<
  typeof useCreatePadifAnalysisMutation
>;
export type CreatePadifAnalysisMutationResult =
  Apollo.MutationResult<OperationTypes.GqlCreatePadifAnalysisMutation>;
export type CreatePadifAnalysisMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlCreatePadifAnalysisMutation,
  OperationTypes.GqlCreatePadifAnalysisMutationVariables
>;
export const CreateSeizureAuthorityDocument = gql`
  mutation createSeizureAuthority($input: CreateSeizureAuthorityInput!) {
    createSeizureAuthority(input: $input) {
      ...seizureAuthorityFields
    }
  }
  ${SeizureAuthorityFieldsFragmentDoc}
`;

/**
 * __useCreateSeizureAuthorityMutation__
 *
 * To run a mutation, you first call `useCreateSeizureAuthorityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSeizureAuthorityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSeizureAuthorityMutation, { data, loading, error }] = useCreateSeizureAuthorityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSeizureAuthorityMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlCreateSeizureAuthorityMutation,
    OperationTypes.GqlCreateSeizureAuthorityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlCreateSeizureAuthorityMutation,
    OperationTypes.GqlCreateSeizureAuthorityMutationVariables
  >(CreateSeizureAuthorityDocument, options);
}
export type CreateSeizureAuthorityMutationHookResult = ReturnType<
  typeof useCreateSeizureAuthorityMutation
>;
export type CreateSeizureAuthorityMutationResult =
  Apollo.MutationResult<OperationTypes.GqlCreateSeizureAuthorityMutation>;
export type CreateSeizureAuthorityMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlCreateSeizureAuthorityMutation,
  OperationTypes.GqlCreateSeizureAuthorityMutationVariables
>;
export const CreateSerieDocument = gql`
  mutation createSerie($newSerie: NewSerieInput!) {
    createSerie(input: $newSerie) {
      ...serieIdentifierFields
      docType
      docCountry
      docFraudType
    }
  }
  ${SerieIdentifierFieldsFragmentDoc}
`;

/**
 * __useCreateSerieMutation__
 *
 * To run a mutation, you first call `useCreateSerieMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSerieMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSerieMutation, { data, loading, error }] = useCreateSerieMutation({
 *   variables: {
 *      newSerie: // value for 'newSerie'
 *   },
 * });
 */
export function useCreateSerieMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlCreateSerieMutation,
    OperationTypes.GqlCreateSerieMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlCreateSerieMutation,
    OperationTypes.GqlCreateSerieMutationVariables
  >(CreateSerieDocument, options);
}
export type CreateSerieMutationHookResult = ReturnType<
  typeof useCreateSerieMutation
>;
export type CreateSerieMutationResult =
  Apollo.MutationResult<OperationTypes.GqlCreateSerieMutation>;
export type CreateSerieMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlCreateSerieMutation,
  OperationTypes.GqlCreateSerieMutationVariables
>;
export const CreateServiceDocument = gql`
  mutation createService($input: CreateServiceInput!) {
    createService(input: $input) {
      ...serviceFields
    }
  }
  ${ServiceFieldsFragmentDoc}
`;

/**
 * __useCreateServiceMutation__
 *
 * To run a mutation, you first call `useCreateServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServiceMutation, { data, loading, error }] = useCreateServiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateServiceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlCreateServiceMutation,
    OperationTypes.GqlCreateServiceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlCreateServiceMutation,
    OperationTypes.GqlCreateServiceMutationVariables
  >(CreateServiceDocument, options);
}
export type CreateServiceMutationHookResult = ReturnType<
  typeof useCreateServiceMutation
>;
export type CreateServiceMutationResult =
  Apollo.MutationResult<OperationTypes.GqlCreateServiceMutation>;
export type CreateServiceMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlCreateServiceMutation,
  OperationTypes.GqlCreateServiceMutationVariables
>;
export const DeleteAlertDocument = gql`
  mutation deleteAlert($id: MongoID!) {
    deleteAlert(input: { id: $id })
  }
`;

/**
 * __useDeleteAlertMutation__
 *
 * To run a mutation, you first call `useDeleteAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAlertMutation, { data, loading, error }] = useDeleteAlertMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAlertMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlDeleteAlertMutation,
    OperationTypes.GqlDeleteAlertMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlDeleteAlertMutation,
    OperationTypes.GqlDeleteAlertMutationVariables
  >(DeleteAlertDocument, options);
}
export type DeleteAlertMutationHookResult = ReturnType<
  typeof useDeleteAlertMutation
>;
export type DeleteAlertMutationResult =
  Apollo.MutationResult<OperationTypes.GqlDeleteAlertMutation>;
export type DeleteAlertMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlDeleteAlertMutation,
  OperationTypes.GqlDeleteAlertMutationVariables
>;
export const DeleteAlertDraftDocument = gql`
  mutation deleteAlertDraft($id: MongoID!, $draftId: ID!) {
    deleteAlertDraft(input: { id: $id, draftId: $draftId }) {
      id
      draft {
        id
      }
    }
  }
`;

/**
 * __useDeleteAlertDraftMutation__
 *
 * To run a mutation, you first call `useDeleteAlertDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAlertDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAlertDraftMutation, { data, loading, error }] = useDeleteAlertDraftMutation({
 *   variables: {
 *      id: // value for 'id'
 *      draftId: // value for 'draftId'
 *   },
 * });
 */
export function useDeleteAlertDraftMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlDeleteAlertDraftMutation,
    OperationTypes.GqlDeleteAlertDraftMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlDeleteAlertDraftMutation,
    OperationTypes.GqlDeleteAlertDraftMutationVariables
  >(DeleteAlertDraftDocument, options);
}
export type DeleteAlertDraftMutationHookResult = ReturnType<
  typeof useDeleteAlertDraftMutation
>;
export type DeleteAlertDraftMutationResult =
  Apollo.MutationResult<OperationTypes.GqlDeleteAlertDraftMutation>;
export type DeleteAlertDraftMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlDeleteAlertDraftMutation,
  OperationTypes.GqlDeleteAlertDraftMutationVariables
>;
export const DeleteAlertDraftTranslationDocument = gql`
  mutation deleteAlertDraftTranslation(
    $input: DeleteAlertDraftTranslationInput!
  ) {
    deleteAlertDraftTranslation(input: $input) {
      id
      translation {
        id
      }
    }
  }
`;

/**
 * __useDeleteAlertDraftTranslationMutation__
 *
 * To run a mutation, you first call `useDeleteAlertDraftTranslationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAlertDraftTranslationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAlertDraftTranslationMutation, { data, loading, error }] = useDeleteAlertDraftTranslationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAlertDraftTranslationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlDeleteAlertDraftTranslationMutation,
    OperationTypes.GqlDeleteAlertDraftTranslationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlDeleteAlertDraftTranslationMutation,
    OperationTypes.GqlDeleteAlertDraftTranslationMutationVariables
  >(DeleteAlertDraftTranslationDocument, options);
}
export type DeleteAlertDraftTranslationMutationHookResult = ReturnType<
  typeof useDeleteAlertDraftTranslationMutation
>;
export type DeleteAlertDraftTranslationMutationResult =
  Apollo.MutationResult<OperationTypes.GqlDeleteAlertDraftTranslationMutation>;
export type DeleteAlertDraftTranslationMutationOptions =
  Apollo.BaseMutationOptions<
    OperationTypes.GqlDeleteAlertDraftTranslationMutation,
    OperationTypes.GqlDeleteAlertDraftTranslationMutationVariables
  >;
export const DeleteDocumentDocument = gql`
  mutation deleteDocument($input: DeleteDocumentInput!) {
    deleteDocument(input: $input)
  }
`;

/**
 * __useDeleteDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentMutation, { data, loading, error }] = useDeleteDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDocumentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlDeleteDocumentMutation,
    OperationTypes.GqlDeleteDocumentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlDeleteDocumentMutation,
    OperationTypes.GqlDeleteDocumentMutationVariables
  >(DeleteDocumentDocument, options);
}
export type DeleteDocumentMutationHookResult = ReturnType<
  typeof useDeleteDocumentMutation
>;
export type DeleteDocumentMutationResult =
  Apollo.MutationResult<OperationTypes.GqlDeleteDocumentMutation>;
export type DeleteDocumentMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlDeleteDocumentMutation,
  OperationTypes.GqlDeleteDocumentMutationVariables
>;
export const DeleteDocumentImageDocument = gql`
  mutation deleteDocumentImage($input: DeleteDocumentImageInput!) {
    deleteDocumentImage(input: $input) {
      id
      images(corrected: false) {
        ...documentImageIdentifiers
      }
    }
  }
  ${DocumentImageIdentifiersFragmentDoc}
`;

/**
 * __useDeleteDocumentImageMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentImageMutation, { data, loading, error }] = useDeleteDocumentImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDocumentImageMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlDeleteDocumentImageMutation,
    OperationTypes.GqlDeleteDocumentImageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlDeleteDocumentImageMutation,
    OperationTypes.GqlDeleteDocumentImageMutationVariables
  >(DeleteDocumentImageDocument, options);
}
export type DeleteDocumentImageMutationHookResult = ReturnType<
  typeof useDeleteDocumentImageMutation
>;
export type DeleteDocumentImageMutationResult =
  Apollo.MutationResult<OperationTypes.GqlDeleteDocumentImageMutation>;
export type DeleteDocumentImageMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlDeleteDocumentImageMutation,
  OperationTypes.GqlDeleteDocumentImageMutationVariables
>;
export const DeleteDocumentSetDocument = gql`
  mutation deleteDocumentSet($id: NumberID!) {
    deleteDocumentSet(id: $id) {
      id
    }
  }
`;

/**
 * __useDeleteDocumentSetMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentSetMutation, { data, loading, error }] = useDeleteDocumentSetMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDocumentSetMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlDeleteDocumentSetMutation,
    OperationTypes.GqlDeleteDocumentSetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlDeleteDocumentSetMutation,
    OperationTypes.GqlDeleteDocumentSetMutationVariables
  >(DeleteDocumentSetDocument, options);
}
export type DeleteDocumentSetMutationHookResult = ReturnType<
  typeof useDeleteDocumentSetMutation
>;
export type DeleteDocumentSetMutationResult =
  Apollo.MutationResult<OperationTypes.GqlDeleteDocumentSetMutation>;
export type DeleteDocumentSetMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlDeleteDocumentSetMutation,
  OperationTypes.GqlDeleteDocumentSetMutationVariables
>;
export const DeleteLabelDocument = gql`
  mutation deleteLabel($input: DeleteLabelInput!) {
    deleteLabel(input: $input) {
      id
    }
  }
`;

/**
 * __useDeleteLabelMutation__
 *
 * To run a mutation, you first call `useDeleteLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLabelMutation, { data, loading, error }] = useDeleteLabelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteLabelMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlDeleteLabelMutation,
    OperationTypes.GqlDeleteLabelMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlDeleteLabelMutation,
    OperationTypes.GqlDeleteLabelMutationVariables
  >(DeleteLabelDocument, options);
}
export type DeleteLabelMutationHookResult = ReturnType<
  typeof useDeleteLabelMutation
>;
export type DeleteLabelMutationResult =
  Apollo.MutationResult<OperationTypes.GqlDeleteLabelMutation>;
export type DeleteLabelMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlDeleteLabelMutation,
  OperationTypes.GqlDeleteLabelMutationVariables
>;
export const DeleteScanDocument = gql`
  mutation deleteScan($input: DeleteScanInput!) {
    deleteScan(input: $input)
  }
`;

/**
 * __useDeleteScanMutation__
 *
 * To run a mutation, you first call `useDeleteScanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScanMutation, { data, loading, error }] = useDeleteScanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteScanMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlDeleteScanMutation,
    OperationTypes.GqlDeleteScanMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlDeleteScanMutation,
    OperationTypes.GqlDeleteScanMutationVariables
  >(DeleteScanDocument, options);
}
export type DeleteScanMutationHookResult = ReturnType<
  typeof useDeleteScanMutation
>;
export type DeleteScanMutationResult =
  Apollo.MutationResult<OperationTypes.GqlDeleteScanMutation>;
export type DeleteScanMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlDeleteScanMutation,
  OperationTypes.GqlDeleteScanMutationVariables
>;
export const DeleteSerieDocument = gql`
  mutation deleteSerie($input: DeleteSerieInput!) {
    deleteSerie(input: $input)
  }
`;

/**
 * __useDeleteSerieMutation__
 *
 * To run a mutation, you first call `useDeleteSerieMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSerieMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSerieMutation, { data, loading, error }] = useDeleteSerieMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSerieMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlDeleteSerieMutation,
    OperationTypes.GqlDeleteSerieMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlDeleteSerieMutation,
    OperationTypes.GqlDeleteSerieMutationVariables
  >(DeleteSerieDocument, options);
}
export type DeleteSerieMutationHookResult = ReturnType<
  typeof useDeleteSerieMutation
>;
export type DeleteSerieMutationResult =
  Apollo.MutationResult<OperationTypes.GqlDeleteSerieMutation>;
export type DeleteSerieMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlDeleteSerieMutation,
  OperationTypes.GqlDeleteSerieMutationVariables
>;
export const DeleteSerieAttachmentDocument = gql`
  mutation deleteSerieAttachment($input: DeleteSerieAttachmentInput!) {
    deleteSerieAttachment(input: $input) {
      id
      images {
        id
      }
      attachments {
        id
      }
    }
  }
`;

/**
 * __useDeleteSerieAttachmentMutation__
 *
 * To run a mutation, you first call `useDeleteSerieAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSerieAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSerieAttachmentMutation, { data, loading, error }] = useDeleteSerieAttachmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSerieAttachmentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlDeleteSerieAttachmentMutation,
    OperationTypes.GqlDeleteSerieAttachmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlDeleteSerieAttachmentMutation,
    OperationTypes.GqlDeleteSerieAttachmentMutationVariables
  >(DeleteSerieAttachmentDocument, options);
}
export type DeleteSerieAttachmentMutationHookResult = ReturnType<
  typeof useDeleteSerieAttachmentMutation
>;
export type DeleteSerieAttachmentMutationResult =
  Apollo.MutationResult<OperationTypes.GqlDeleteSerieAttachmentMutation>;
export type DeleteSerieAttachmentMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlDeleteSerieAttachmentMutation,
  OperationTypes.GqlDeleteSerieAttachmentMutationVariables
>;
export const DeleteUserDocument = gql`
  mutation deleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input)
  }
`;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlDeleteUserMutation,
    OperationTypes.GqlDeleteUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlDeleteUserMutation,
    OperationTypes.GqlDeleteUserMutationVariables
  >(DeleteUserDocument, options);
}
export type DeleteUserMutationHookResult = ReturnType<
  typeof useDeleteUserMutation
>;
export type DeleteUserMutationResult =
  Apollo.MutationResult<OperationTypes.GqlDeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlDeleteUserMutation,
  OperationTypes.GqlDeleteUserMutationVariables
>;
export const UploadDocumentExtraImageDocument = gql`
  mutation uploadDocumentExtraImage($input: UploadDocumentExtraImageInput!) {
    uploadDocumentExtraImage(input: $input) {
      id
      extraImages {
        ...detailedExtraImageFields
      }
      ...viewImagesAllowExtra
    }
  }
  ${DetailedExtraImageFieldsFragmentDoc}
  ${ViewImagesAllowExtraFragmentDoc}
`;

/**
 * __useUploadDocumentExtraImageMutation__
 *
 * To run a mutation, you first call `useUploadDocumentExtraImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadDocumentExtraImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadDocumentExtraImageMutation, { data, loading, error }] = useUploadDocumentExtraImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadDocumentExtraImageMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlUploadDocumentExtraImageMutation,
    OperationTypes.GqlUploadDocumentExtraImageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlUploadDocumentExtraImageMutation,
    OperationTypes.GqlUploadDocumentExtraImageMutationVariables
  >(UploadDocumentExtraImageDocument, options);
}
export type UploadDocumentExtraImageMutationHookResult = ReturnType<
  typeof useUploadDocumentExtraImageMutation
>;
export type UploadDocumentExtraImageMutationResult =
  Apollo.MutationResult<OperationTypes.GqlUploadDocumentExtraImageMutation>;
export type UploadDocumentExtraImageMutationOptions =
  Apollo.BaseMutationOptions<
    OperationTypes.GqlUploadDocumentExtraImageMutation,
    OperationTypes.GqlUploadDocumentExtraImageMutationVariables
  >;
export const DeleteDocumentExtraImageDocument = gql`
  mutation deleteDocumentExtraImage($input: DeleteDocumentExtraImageInput!) {
    deleteDocumentExtraImage(input: $input) {
      id
      extraImages {
        ...detailedExtraImageFields
      }
      ...viewImagesAllowExtra
    }
  }
  ${DetailedExtraImageFieldsFragmentDoc}
  ${ViewImagesAllowExtraFragmentDoc}
`;

/**
 * __useDeleteDocumentExtraImageMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentExtraImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentExtraImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentExtraImageMutation, { data, loading, error }] = useDeleteDocumentExtraImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDocumentExtraImageMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlDeleteDocumentExtraImageMutation,
    OperationTypes.GqlDeleteDocumentExtraImageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlDeleteDocumentExtraImageMutation,
    OperationTypes.GqlDeleteDocumentExtraImageMutationVariables
  >(DeleteDocumentExtraImageDocument, options);
}
export type DeleteDocumentExtraImageMutationHookResult = ReturnType<
  typeof useDeleteDocumentExtraImageMutation
>;
export type DeleteDocumentExtraImageMutationResult =
  Apollo.MutationResult<OperationTypes.GqlDeleteDocumentExtraImageMutation>;
export type DeleteDocumentExtraImageMutationOptions =
  Apollo.BaseMutationOptions<
    OperationTypes.GqlDeleteDocumentExtraImageMutation,
    OperationTypes.GqlDeleteDocumentExtraImageMutationVariables
  >;
export const UpdateDocumentExtraImageMetadataDocument = gql`
  mutation updateDocumentExtraImageMetadata(
    $input: UpdateDocumentExtraImageMetadataInput!
  ) {
    updateDocumentExtraImageMetadata(input: $input) {
      id
      extraImages {
        ...detailedExtraImageFields
      }
      ...viewImagesAllowExtra
    }
  }
  ${DetailedExtraImageFieldsFragmentDoc}
  ${ViewImagesAllowExtraFragmentDoc}
`;

/**
 * __useUpdateDocumentExtraImageMetadataMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentExtraImageMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentExtraImageMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentExtraImageMetadataMutation, { data, loading, error }] = useUpdateDocumentExtraImageMetadataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDocumentExtraImageMetadataMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlUpdateDocumentExtraImageMetadataMutation,
    OperationTypes.GqlUpdateDocumentExtraImageMetadataMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlUpdateDocumentExtraImageMetadataMutation,
    OperationTypes.GqlUpdateDocumentExtraImageMetadataMutationVariables
  >(UpdateDocumentExtraImageMetadataDocument, options);
}
export type UpdateDocumentExtraImageMetadataMutationHookResult = ReturnType<
  typeof useUpdateDocumentExtraImageMetadataMutation
>;
export type UpdateDocumentExtraImageMetadataMutationResult =
  Apollo.MutationResult<OperationTypes.GqlUpdateDocumentExtraImageMetadataMutation>;
export type UpdateDocumentExtraImageMetadataMutationOptions =
  Apollo.BaseMutationOptions<
    OperationTypes.GqlUpdateDocumentExtraImageMetadataMutation,
    OperationTypes.GqlUpdateDocumentExtraImageMetadataMutationVariables
  >;
export const EditDynamicFiltersDocumentSetDocument = gql`
  mutation editDynamicFiltersDocumentSet(
    $input: EditDynamicFiltersDocumentSetInput!
  ) {
    editDynamicFiltersDocumentSet(input: $input) {
      id
    }
  }
`;

/**
 * __useEditDynamicFiltersDocumentSetMutation__
 *
 * To run a mutation, you first call `useEditDynamicFiltersDocumentSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDynamicFiltersDocumentSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDynamicFiltersDocumentSetMutation, { data, loading, error }] = useEditDynamicFiltersDocumentSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditDynamicFiltersDocumentSetMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlEditDynamicFiltersDocumentSetMutation,
    OperationTypes.GqlEditDynamicFiltersDocumentSetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlEditDynamicFiltersDocumentSetMutation,
    OperationTypes.GqlEditDynamicFiltersDocumentSetMutationVariables
  >(EditDynamicFiltersDocumentSetDocument, options);
}
export type EditDynamicFiltersDocumentSetMutationHookResult = ReturnType<
  typeof useEditDynamicFiltersDocumentSetMutation
>;
export type EditDynamicFiltersDocumentSetMutationResult =
  Apollo.MutationResult<OperationTypes.GqlEditDynamicFiltersDocumentSetMutation>;
export type EditDynamicFiltersDocumentSetMutationOptions =
  Apollo.BaseMutationOptions<
    OperationTypes.GqlEditDynamicFiltersDocumentSetMutation,
    OperationTypes.GqlEditDynamicFiltersDocumentSetMutationVariables
  >;
export const ExcludeCandidateDocumentsFromSetDocument = gql`
  mutation excludeCandidateDocumentsFromSet(
    $input: ExcludeCandidateDocumentsFromSetInput!
  ) {
    excludeCandidateDocumentsFromSet(input: $input) {
      id
    }
  }
`;

/**
 * __useExcludeCandidateDocumentsFromSetMutation__
 *
 * To run a mutation, you first call `useExcludeCandidateDocumentsFromSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExcludeCandidateDocumentsFromSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [excludeCandidateDocumentsFromSetMutation, { data, loading, error }] = useExcludeCandidateDocumentsFromSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExcludeCandidateDocumentsFromSetMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlExcludeCandidateDocumentsFromSetMutation,
    OperationTypes.GqlExcludeCandidateDocumentsFromSetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlExcludeCandidateDocumentsFromSetMutation,
    OperationTypes.GqlExcludeCandidateDocumentsFromSetMutationVariables
  >(ExcludeCandidateDocumentsFromSetDocument, options);
}
export type ExcludeCandidateDocumentsFromSetMutationHookResult = ReturnType<
  typeof useExcludeCandidateDocumentsFromSetMutation
>;
export type ExcludeCandidateDocumentsFromSetMutationResult =
  Apollo.MutationResult<OperationTypes.GqlExcludeCandidateDocumentsFromSetMutation>;
export type ExcludeCandidateDocumentsFromSetMutationOptions =
  Apollo.BaseMutationOptions<
    OperationTypes.GqlExcludeCandidateDocumentsFromSetMutation,
    OperationTypes.GqlExcludeCandidateDocumentsFromSetMutationVariables
  >;
export const FinalizeScanImportDocument = gql`
  mutation finalizeScanImport($input: FinalizeScanInput!) {
    finalizeScanImport(input: $input) {
      id
      importData {
        __typename
      }
    }
  }
`;

/**
 * __useFinalizeScanImportMutation__
 *
 * To run a mutation, you first call `useFinalizeScanImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalizeScanImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalizeScanImportMutation, { data, loading, error }] = useFinalizeScanImportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFinalizeScanImportMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlFinalizeScanImportMutation,
    OperationTypes.GqlFinalizeScanImportMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlFinalizeScanImportMutation,
    OperationTypes.GqlFinalizeScanImportMutationVariables
  >(FinalizeScanImportDocument, options);
}
export type FinalizeScanImportMutationHookResult = ReturnType<
  typeof useFinalizeScanImportMutation
>;
export type FinalizeScanImportMutationResult =
  Apollo.MutationResult<OperationTypes.GqlFinalizeScanImportMutation>;
export type FinalizeScanImportMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlFinalizeScanImportMutation,
  OperationTypes.GqlFinalizeScanImportMutationVariables
>;
export const InitUserDocument = gql`
  mutation initUser($input: InitUserInput!) {
    initUser(input: $input) {
      id
      email
      activationToken
    }
  }
`;

/**
 * __useInitUserMutation__
 *
 * To run a mutation, you first call `useInitUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initUserMutation, { data, loading, error }] = useInitUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInitUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlInitUserMutation,
    OperationTypes.GqlInitUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlInitUserMutation,
    OperationTypes.GqlInitUserMutationVariables
  >(InitUserDocument, options);
}
export type InitUserMutationHookResult = ReturnType<typeof useInitUserMutation>;
export type InitUserMutationResult =
  Apollo.MutationResult<OperationTypes.GqlInitUserMutation>;
export type InitUserMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlInitUserMutation,
  OperationTypes.GqlInitUserMutationVariables
>;
export const MergeSeriesDocument = gql`
  mutation mergeSeries($input: MergeSeriesInput!) {
    mergeSeries(input: $input) {
      id
      updatedAt
      ...updateableSerieFields
    }
  }
  ${UpdateableSerieFieldsFragmentDoc}
`;

/**
 * __useMergeSeriesMutation__
 *
 * To run a mutation, you first call `useMergeSeriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergeSeriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergeSeriesMutation, { data, loading, error }] = useMergeSeriesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMergeSeriesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlMergeSeriesMutation,
    OperationTypes.GqlMergeSeriesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlMergeSeriesMutation,
    OperationTypes.GqlMergeSeriesMutationVariables
  >(MergeSeriesDocument, options);
}
export type MergeSeriesMutationHookResult = ReturnType<
  typeof useMergeSeriesMutation
>;
export type MergeSeriesMutationResult =
  Apollo.MutationResult<OperationTypes.GqlMergeSeriesMutation>;
export type MergeSeriesMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlMergeSeriesMutation,
  OperationTypes.GqlMergeSeriesMutationVariables
>;
export const PublishAlertDraftDocument = gql`
  mutation publishAlertDraft($id: MongoID!, $draftId: ID!) {
    publishAlertDraft(input: { id: $id, draftId: $draftId }) {
      id
      draft {
        id
      }
    }
  }
`;

/**
 * __usePublishAlertDraftMutation__
 *
 * To run a mutation, you first call `usePublishAlertDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishAlertDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishAlertDraftMutation, { data, loading, error }] = usePublishAlertDraftMutation({
 *   variables: {
 *      id: // value for 'id'
 *      draftId: // value for 'draftId'
 *   },
 * });
 */
export function usePublishAlertDraftMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlPublishAlertDraftMutation,
    OperationTypes.GqlPublishAlertDraftMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlPublishAlertDraftMutation,
    OperationTypes.GqlPublishAlertDraftMutationVariables
  >(PublishAlertDraftDocument, options);
}
export type PublishAlertDraftMutationHookResult = ReturnType<
  typeof usePublishAlertDraftMutation
>;
export type PublishAlertDraftMutationResult =
  Apollo.MutationResult<OperationTypes.GqlPublishAlertDraftMutation>;
export type PublishAlertDraftMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlPublishAlertDraftMutation,
  OperationTypes.GqlPublishAlertDraftMutationVariables
>;
export const RemoveDocumentsFromSetDocument = gql`
  mutation removeDocumentsFromSet($input: RemoveDocumentsFromSetInput!) {
    removeDocumentsFromSet(input: $input) {
      id
      documents {
        nodes {
          ...documentFieldsForDocumentSet
        }
      }
    }
  }
  ${DocumentFieldsForDocumentSetFragmentDoc}
`;

/**
 * __useRemoveDocumentsFromSetMutation__
 *
 * To run a mutation, you first call `useRemoveDocumentsFromSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDocumentsFromSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDocumentsFromSetMutation, { data, loading, error }] = useRemoveDocumentsFromSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveDocumentsFromSetMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlRemoveDocumentsFromSetMutation,
    OperationTypes.GqlRemoveDocumentsFromSetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlRemoveDocumentsFromSetMutation,
    OperationTypes.GqlRemoveDocumentsFromSetMutationVariables
  >(RemoveDocumentsFromSetDocument, options);
}
export type RemoveDocumentsFromSetMutationHookResult = ReturnType<
  typeof useRemoveDocumentsFromSetMutation
>;
export type RemoveDocumentsFromSetMutationResult =
  Apollo.MutationResult<OperationTypes.GqlRemoveDocumentsFromSetMutation>;
export type RemoveDocumentsFromSetMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlRemoveDocumentsFromSetMutation,
  OperationTypes.GqlRemoveDocumentsFromSetMutationVariables
>;
export const RemoveExcludedDocumentsFromSetDocument = gql`
  mutation removeExcludedDocumentsFromSet(
    $input: RemoveDocumentsFromSetInput!
  ) {
    removeExcludedDocumentsFromSet(input: $input) {
      id
    }
  }
`;

/**
 * __useRemoveExcludedDocumentsFromSetMutation__
 *
 * To run a mutation, you first call `useRemoveExcludedDocumentsFromSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveExcludedDocumentsFromSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeExcludedDocumentsFromSetMutation, { data, loading, error }] = useRemoveExcludedDocumentsFromSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveExcludedDocumentsFromSetMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlRemoveExcludedDocumentsFromSetMutation,
    OperationTypes.GqlRemoveExcludedDocumentsFromSetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlRemoveExcludedDocumentsFromSetMutation,
    OperationTypes.GqlRemoveExcludedDocumentsFromSetMutationVariables
  >(RemoveExcludedDocumentsFromSetDocument, options);
}
export type RemoveExcludedDocumentsFromSetMutationHookResult = ReturnType<
  typeof useRemoveExcludedDocumentsFromSetMutation
>;
export type RemoveExcludedDocumentsFromSetMutationResult =
  Apollo.MutationResult<OperationTypes.GqlRemoveExcludedDocumentsFromSetMutation>;
export type RemoveExcludedDocumentsFromSetMutationOptions =
  Apollo.BaseMutationOptions<
    OperationTypes.GqlRemoveExcludedDocumentsFromSetMutation,
    OperationTypes.GqlRemoveExcludedDocumentsFromSetMutationVariables
  >;
export const RemovePadifAnalysisDocument = gql`
  mutation removePadifAnalysis($id: NumberID!) {
    removePadifAnalysis(id: $id)
  }
`;

/**
 * __useRemovePadifAnalysisMutation__
 *
 * To run a mutation, you first call `useRemovePadifAnalysisMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePadifAnalysisMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePadifAnalysisMutation, { data, loading, error }] = useRemovePadifAnalysisMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemovePadifAnalysisMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlRemovePadifAnalysisMutation,
    OperationTypes.GqlRemovePadifAnalysisMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlRemovePadifAnalysisMutation,
    OperationTypes.GqlRemovePadifAnalysisMutationVariables
  >(RemovePadifAnalysisDocument, options);
}
export type RemovePadifAnalysisMutationHookResult = ReturnType<
  typeof useRemovePadifAnalysisMutation
>;
export type RemovePadifAnalysisMutationResult =
  Apollo.MutationResult<OperationTypes.GqlRemovePadifAnalysisMutation>;
export type RemovePadifAnalysisMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlRemovePadifAnalysisMutation,
  OperationTypes.GqlRemovePadifAnalysisMutationVariables
>;
export const RenameDocumentSetDocument = gql`
  mutation renameDocumentSet($input: RenameDocumentSetInput!) {
    renameDocumentSet(input: $input) {
      id
      name
    }
  }
`;

/**
 * __useRenameDocumentSetMutation__
 *
 * To run a mutation, you first call `useRenameDocumentSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameDocumentSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameDocumentSetMutation, { data, loading, error }] = useRenameDocumentSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRenameDocumentSetMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlRenameDocumentSetMutation,
    OperationTypes.GqlRenameDocumentSetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlRenameDocumentSetMutation,
    OperationTypes.GqlRenameDocumentSetMutationVariables
  >(RenameDocumentSetDocument, options);
}
export type RenameDocumentSetMutationHookResult = ReturnType<
  typeof useRenameDocumentSetMutation
>;
export type RenameDocumentSetMutationResult =
  Apollo.MutationResult<OperationTypes.GqlRenameDocumentSetMutation>;
export type RenameDocumentSetMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlRenameDocumentSetMutation,
  OperationTypes.GqlRenameDocumentSetMutationVariables
>;
export const ResetUserPasswordDocument = gql`
  mutation resetUserPassword($input: ResetUserPasswordInput!) {
    resetUserPassword(input: $input) {
      id
      isConfirmed
      activationToken
    }
  }
`;

/**
 * __useResetUserPasswordMutation__
 *
 * To run a mutation, you first call `useResetUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetUserPasswordMutation, { data, loading, error }] = useResetUserPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetUserPasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlResetUserPasswordMutation,
    OperationTypes.GqlResetUserPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlResetUserPasswordMutation,
    OperationTypes.GqlResetUserPasswordMutationVariables
  >(ResetUserPasswordDocument, options);
}
export type ResetUserPasswordMutationHookResult = ReturnType<
  typeof useResetUserPasswordMutation
>;
export type ResetUserPasswordMutationResult =
  Apollo.MutationResult<OperationTypes.GqlResetUserPasswordMutation>;
export type ResetUserPasswordMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlResetUserPasswordMutation,
  OperationTypes.GqlResetUserPasswordMutationVariables
>;
export const RotateDocumentImageDocument = gql`
  mutation rotateDocumentImage($input: RotateDocumentImageInput!) {
    rotateDocumentImage(input: $input) {
      id
      images(corrected: false) {
        ...imagesWithUrl
      }
    }
  }
  ${ImagesWithUrlFragmentDoc}
`;

/**
 * __useRotateDocumentImageMutation__
 *
 * To run a mutation, you first call `useRotateDocumentImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRotateDocumentImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rotateDocumentImageMutation, { data, loading, error }] = useRotateDocumentImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRotateDocumentImageMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlRotateDocumentImageMutation,
    OperationTypes.GqlRotateDocumentImageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlRotateDocumentImageMutation,
    OperationTypes.GqlRotateDocumentImageMutationVariables
  >(RotateDocumentImageDocument, options);
}
export type RotateDocumentImageMutationHookResult = ReturnType<
  typeof useRotateDocumentImageMutation
>;
export type RotateDocumentImageMutationResult =
  Apollo.MutationResult<OperationTypes.GqlRotateDocumentImageMutation>;
export type RotateDocumentImageMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlRotateDocumentImageMutation,
  OperationTypes.GqlRotateDocumentImageMutationVariables
>;
export const RotateScanImportDocument = gql`
  mutation rotateScanImport($id: MongoID!, $rotate: Int!) {
    rotateScanImport(id: $id, rotate: $rotate) {
      id
    }
  }
`;

/**
 * __useRotateScanImportMutation__
 *
 * To run a mutation, you first call `useRotateScanImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRotateScanImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rotateScanImportMutation, { data, loading, error }] = useRotateScanImportMutation({
 *   variables: {
 *      id: // value for 'id'
 *      rotate: // value for 'rotate'
 *   },
 * });
 */
export function useRotateScanImportMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlRotateScanImportMutation,
    OperationTypes.GqlRotateScanImportMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlRotateScanImportMutation,
    OperationTypes.GqlRotateScanImportMutationVariables
  >(RotateScanImportDocument, options);
}
export type RotateScanImportMutationHookResult = ReturnType<
  typeof useRotateScanImportMutation
>;
export type RotateScanImportMutationResult =
  Apollo.MutationResult<OperationTypes.GqlRotateScanImportMutation>;
export type RotateScanImportMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlRotateScanImportMutation,
  OperationTypes.GqlRotateScanImportMutationVariables
>;
export const SetScanImportDataPersonalDataRegionsDocument = gql`
  mutation setScanImportDataPersonalDataRegions(
    $scanId: MongoID!
    $rois: [RoiInput!]!
  ) {
    setScanImportDataPersonalDataRegions(
      input: { scanId: $scanId, rois: $rois }
    ) {
      id
    }
  }
`;

/**
 * __useSetScanImportDataPersonalDataRegionsMutation__
 *
 * To run a mutation, you first call `useSetScanImportDataPersonalDataRegionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetScanImportDataPersonalDataRegionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setScanImportDataPersonalDataRegionsMutation, { data, loading, error }] = useSetScanImportDataPersonalDataRegionsMutation({
 *   variables: {
 *      scanId: // value for 'scanId'
 *      rois: // value for 'rois'
 *   },
 * });
 */
export function useSetScanImportDataPersonalDataRegionsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlSetScanImportDataPersonalDataRegionsMutation,
    OperationTypes.GqlSetScanImportDataPersonalDataRegionsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlSetScanImportDataPersonalDataRegionsMutation,
    OperationTypes.GqlSetScanImportDataPersonalDataRegionsMutationVariables
  >(SetScanImportDataPersonalDataRegionsDocument, options);
}
export type SetScanImportDataPersonalDataRegionsMutationHookResult = ReturnType<
  typeof useSetScanImportDataPersonalDataRegionsMutation
>;
export type SetScanImportDataPersonalDataRegionsMutationResult =
  Apollo.MutationResult<OperationTypes.GqlSetScanImportDataPersonalDataRegionsMutation>;
export type SetScanImportDataPersonalDataRegionsMutationOptions =
  Apollo.BaseMutationOptions<
    OperationTypes.GqlSetScanImportDataPersonalDataRegionsMutation,
    OperationTypes.GqlSetScanImportDataPersonalDataRegionsMutationVariables
  >;
export const SetScanImportMrzDocument = gql`
  mutation setScanImportMRZ($id: MongoID!, $mrz: [String!]!) {
    setScanImportMRZ(id: $id, mrz: $mrz) {
      id
    }
  }
`;

/**
 * __useSetScanImportMrzMutation__
 *
 * To run a mutation, you first call `useSetScanImportMrzMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetScanImportMrzMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setScanImportMrzMutation, { data, loading, error }] = useSetScanImportMrzMutation({
 *   variables: {
 *      id: // value for 'id'
 *      mrz: // value for 'mrz'
 *   },
 * });
 */
export function useSetScanImportMrzMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlSetScanImportMrzMutation,
    OperationTypes.GqlSetScanImportMrzMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlSetScanImportMrzMutation,
    OperationTypes.GqlSetScanImportMrzMutationVariables
  >(SetScanImportMrzDocument, options);
}
export type SetScanImportMrzMutationHookResult = ReturnType<
  typeof useSetScanImportMrzMutation
>;
export type SetScanImportMrzMutationResult =
  Apollo.MutationResult<OperationTypes.GqlSetScanImportMrzMutation>;
export type SetScanImportMrzMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlSetScanImportMrzMutation,
  OperationTypes.GqlSetScanImportMrzMutationVariables
>;
export const UpdateAlertDraftTranslationDocument = gql`
  mutation updateAlertDraftTranslation(
    $input: UpdateAlertDraftTranslationInput!
  ) {
    updateAlertDraftTranslation(input: $input) {
      id
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useUpdateAlertDraftTranslationMutation__
 *
 * To run a mutation, you first call `useUpdateAlertDraftTranslationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAlertDraftTranslationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAlertDraftTranslationMutation, { data, loading, error }] = useUpdateAlertDraftTranslationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAlertDraftTranslationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlUpdateAlertDraftTranslationMutation,
    OperationTypes.GqlUpdateAlertDraftTranslationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlUpdateAlertDraftTranslationMutation,
    OperationTypes.GqlUpdateAlertDraftTranslationMutationVariables
  >(UpdateAlertDraftTranslationDocument, options);
}
export type UpdateAlertDraftTranslationMutationHookResult = ReturnType<
  typeof useUpdateAlertDraftTranslationMutation
>;
export type UpdateAlertDraftTranslationMutationResult =
  Apollo.MutationResult<OperationTypes.GqlUpdateAlertDraftTranslationMutation>;
export type UpdateAlertDraftTranslationMutationOptions =
  Apollo.BaseMutationOptions<
    OperationTypes.GqlUpdateAlertDraftTranslationMutation,
    OperationTypes.GqlUpdateAlertDraftTranslationMutationVariables
  >;
export const UpdateAlertRapidDetectionImageDocument = gql`
  mutation updateAlertRapidDetectionImage($input: RapidDetectionImageInput!) {
    updateAlertRapidDetectionImage(input: $input) {
      id
      rapidIdentificationImage {
        id
        url
        filename
        size
      }
    }
  }
`;

/**
 * __useUpdateAlertRapidDetectionImageMutation__
 *
 * To run a mutation, you first call `useUpdateAlertRapidDetectionImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAlertRapidDetectionImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAlertRapidDetectionImageMutation, { data, loading, error }] = useUpdateAlertRapidDetectionImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAlertRapidDetectionImageMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlUpdateAlertRapidDetectionImageMutation,
    OperationTypes.GqlUpdateAlertRapidDetectionImageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlUpdateAlertRapidDetectionImageMutation,
    OperationTypes.GqlUpdateAlertRapidDetectionImageMutationVariables
  >(UpdateAlertRapidDetectionImageDocument, options);
}
export type UpdateAlertRapidDetectionImageMutationHookResult = ReturnType<
  typeof useUpdateAlertRapidDetectionImageMutation
>;
export type UpdateAlertRapidDetectionImageMutationResult =
  Apollo.MutationResult<OperationTypes.GqlUpdateAlertRapidDetectionImageMutation>;
export type UpdateAlertRapidDetectionImageMutationOptions =
  Apollo.BaseMutationOptions<
    OperationTypes.GqlUpdateAlertRapidDetectionImageMutation,
    OperationTypes.GqlUpdateAlertRapidDetectionImageMutationVariables
  >;
export const UpdateDocumentDocument = gql`
  mutation updateDocument($input: UpdateDocumentInput!) {
    updateDocument(input: $input) {
      id
    }
  }
`;

/**
 * __useUpdateDocumentMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentMutation, { data, loading, error }] = useUpdateDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDocumentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlUpdateDocumentMutation,
    OperationTypes.GqlUpdateDocumentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlUpdateDocumentMutation,
    OperationTypes.GqlUpdateDocumentMutationVariables
  >(UpdateDocumentDocument, options);
}
export type UpdateDocumentMutationHookResult = ReturnType<
  typeof useUpdateDocumentMutation
>;
export type UpdateDocumentMutationResult =
  Apollo.MutationResult<OperationTypes.GqlUpdateDocumentMutation>;
export type UpdateDocumentMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlUpdateDocumentMutation,
  OperationTypes.GqlUpdateDocumentMutationVariables
>;
export const UpdateLabelDocument = gql`
  mutation updateLabel($input: UpdateLabelInput!) {
    updateLabel(input: $input) {
      ...labelBadgeFields
    }
  }
  ${LabelBadgeFieldsFragmentDoc}
`;

/**
 * __useUpdateLabelMutation__
 *
 * To run a mutation, you first call `useUpdateLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLabelMutation, { data, loading, error }] = useUpdateLabelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLabelMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlUpdateLabelMutation,
    OperationTypes.GqlUpdateLabelMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlUpdateLabelMutation,
    OperationTypes.GqlUpdateLabelMutationVariables
  >(UpdateLabelDocument, options);
}
export type UpdateLabelMutationHookResult = ReturnType<
  typeof useUpdateLabelMutation
>;
export type UpdateLabelMutationResult =
  Apollo.MutationResult<OperationTypes.GqlUpdateLabelMutation>;
export type UpdateLabelMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlUpdateLabelMutation,
  OperationTypes.GqlUpdateLabelMutationVariables
>;
export const UpdateSeizureAuthorityDocument = gql`
  mutation updateSeizureAuthority($input: UpdateSeizureAuthorityInput!) {
    updateSeizureAuthority(input: $input) {
      id
      identifier
      nameEn: name(locale: en)
      hasTranslationEn: hasTranslation(locale: en)
      nameFr: name(locale: fr)
      hasTranslationFr: hasTranslation(locale: fr)
      nameDe: name(locale: de)
      hasTranslationDe: hasTranslation(locale: de)
      nameNl: name(locale: nl)
      hasTranslationNl: hasTranslation(locale: nl)
    }
  }
`;

/**
 * __useUpdateSeizureAuthorityMutation__
 *
 * To run a mutation, you first call `useUpdateSeizureAuthorityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSeizureAuthorityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSeizureAuthorityMutation, { data, loading, error }] = useUpdateSeizureAuthorityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSeizureAuthorityMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlUpdateSeizureAuthorityMutation,
    OperationTypes.GqlUpdateSeizureAuthorityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlUpdateSeizureAuthorityMutation,
    OperationTypes.GqlUpdateSeizureAuthorityMutationVariables
  >(UpdateSeizureAuthorityDocument, options);
}
export type UpdateSeizureAuthorityMutationHookResult = ReturnType<
  typeof useUpdateSeizureAuthorityMutation
>;
export type UpdateSeizureAuthorityMutationResult =
  Apollo.MutationResult<OperationTypes.GqlUpdateSeizureAuthorityMutation>;
export type UpdateSeizureAuthorityMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlUpdateSeizureAuthorityMutation,
  OperationTypes.GqlUpdateSeizureAuthorityMutationVariables
>;
export const UpdateSerieDocument = gql`
  mutation updateSerie($input: UpdateSerieInput!) {
    updateSerie(input: $input) {
      id
      updatedAt
      ...updateableSerieFields
    }
  }
  ${UpdateableSerieFieldsFragmentDoc}
`;

/**
 * __useUpdateSerieMutation__
 *
 * To run a mutation, you first call `useUpdateSerieMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSerieMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSerieMutation, { data, loading, error }] = useUpdateSerieMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSerieMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlUpdateSerieMutation,
    OperationTypes.GqlUpdateSerieMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlUpdateSerieMutation,
    OperationTypes.GqlUpdateSerieMutationVariables
  >(UpdateSerieDocument, options);
}
export type UpdateSerieMutationHookResult = ReturnType<
  typeof useUpdateSerieMutation
>;
export type UpdateSerieMutationResult =
  Apollo.MutationResult<OperationTypes.GqlUpdateSerieMutation>;
export type UpdateSerieMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlUpdateSerieMutation,
  OperationTypes.GqlUpdateSerieMutationVariables
>;
export const UpdateSerieNameDocument = gql`
  mutation updateSerieName($input: UpdateSerieNameInput!) {
    updateSerieName(input: $input) {
      ...serieIdentifierFields
      updatedAt
      aliases
    }
  }
  ${SerieIdentifierFieldsFragmentDoc}
`;

/**
 * __useUpdateSerieNameMutation__
 *
 * To run a mutation, you first call `useUpdateSerieNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSerieNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSerieNameMutation, { data, loading, error }] = useUpdateSerieNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSerieNameMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlUpdateSerieNameMutation,
    OperationTypes.GqlUpdateSerieNameMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlUpdateSerieNameMutation,
    OperationTypes.GqlUpdateSerieNameMutationVariables
  >(UpdateSerieNameDocument, options);
}
export type UpdateSerieNameMutationHookResult = ReturnType<
  typeof useUpdateSerieNameMutation
>;
export type UpdateSerieNameMutationResult =
  Apollo.MutationResult<OperationTypes.GqlUpdateSerieNameMutation>;
export type UpdateSerieNameMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlUpdateSerieNameMutation,
  OperationTypes.GqlUpdateSerieNameMutationVariables
>;
export const UpdateServiceDocument = gql`
  mutation updateService($input: UpdateServiceInput!) {
    updateService(input: $input) {
      id
      identifier
      nameEn: name(locale: en)
      hasTranslationEn: hasTranslation(locale: en)
      nameFr: name(locale: fr)
      hasTranslationFr: hasTranslation(locale: fr)
      nameDe: name(locale: de)
      hasTranslationDe: hasTranslation(locale: de)
      nameNl: name(locale: nl)
      hasTranslationNl: hasTranslation(locale: nl)
    }
  }
`;

/**
 * __useUpdateServiceMutation__
 *
 * To run a mutation, you first call `useUpdateServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceMutation, { data, loading, error }] = useUpdateServiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateServiceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlUpdateServiceMutation,
    OperationTypes.GqlUpdateServiceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlUpdateServiceMutation,
    OperationTypes.GqlUpdateServiceMutationVariables
  >(UpdateServiceDocument, options);
}
export type UpdateServiceMutationHookResult = ReturnType<
  typeof useUpdateServiceMutation
>;
export type UpdateServiceMutationResult =
  Apollo.MutationResult<OperationTypes.GqlUpdateServiceMutation>;
export type UpdateServiceMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlUpdateServiceMutation,
  OperationTypes.GqlUpdateServiceMutationVariables
>;
export const UploadScansDocument = gql`
  mutation uploadScans($scans: [Upload!]!) {
    uploadScans(input: { scans: $scans }) {
      id
    }
  }
`;

/**
 * __useUploadScansMutation__
 *
 * To run a mutation, you first call `useUploadScansMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadScansMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadScansMutation, { data, loading, error }] = useUploadScansMutation({
 *   variables: {
 *      scans: // value for 'scans'
 *   },
 * });
 */
export function useUploadScansMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlUploadScansMutation,
    OperationTypes.GqlUploadScansMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlUploadScansMutation,
    OperationTypes.GqlUploadScansMutationVariables
  >(UploadScansDocument, options);
}
export type UploadScansMutationHookResult = ReturnType<
  typeof useUploadScansMutation
>;
export type UploadScansMutationResult =
  Apollo.MutationResult<OperationTypes.GqlUploadScansMutation>;
export type UploadScansMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlUploadScansMutation,
  OperationTypes.GqlUploadScansMutationVariables
>;
export const UploadSerieAttachmentsDocument = gql`
  mutation uploadSerieAttachments($input: UploadSerieAttachmentsInput!) {
    uploadSerieAttachments(input: $input) {
      id
    }
  }
`;

/**
 * __useUploadSerieAttachmentsMutation__
 *
 * To run a mutation, you first call `useUploadSerieAttachmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadSerieAttachmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadSerieAttachmentsMutation, { data, loading, error }] = useUploadSerieAttachmentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadSerieAttachmentsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlUploadSerieAttachmentsMutation,
    OperationTypes.GqlUploadSerieAttachmentsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlUploadSerieAttachmentsMutation,
    OperationTypes.GqlUploadSerieAttachmentsMutationVariables
  >(UploadSerieAttachmentsDocument, options);
}
export type UploadSerieAttachmentsMutationHookResult = ReturnType<
  typeof useUploadSerieAttachmentsMutation
>;
export type UploadSerieAttachmentsMutationResult =
  Apollo.MutationResult<OperationTypes.GqlUploadSerieAttachmentsMutation>;
export type UploadSerieAttachmentsMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlUploadSerieAttachmentsMutation,
  OperationTypes.GqlUploadSerieAttachmentsMutationVariables
>;
export const AlertDraftDocument = gql`
  query AlertDraft($id: MongoID!, $language: Language) {
    alert(id: $id) {
      id
      status
      createdAt
      updatedAt
      serie {
        id
        seqId
        name
        docFraudType
        docCountry
        docType
      }
      currentPublication {
        id
      }
      draft {
        ...draftAlertFields
        createdAt
        updatedAt
        createdBy {
          id
          name
        }
        updatedBy {
          id
          name
        }
        translation(language: $language) {
          ...alertTranslationFields
        }
      }
    }
  }
  ${DraftAlertFieldsFragmentDoc}
  ${AlertTranslationFieldsFragmentDoc}
`;

/**
 * __useAlertDraftQuery__
 *
 * To run a query within a React component, call `useAlertDraftQuery` and pass it any options that fit your needs.
 * When your component renders, `useAlertDraftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAlertDraftQuery({
 *   variables: {
 *      id: // value for 'id'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useAlertDraftQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlAlertDraftQuery,
    OperationTypes.GqlAlertDraftQueryVariables
  > &
    (
      | {
          variables: OperationTypes.GqlAlertDraftQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlAlertDraftQuery,
    OperationTypes.GqlAlertDraftQueryVariables
  >(AlertDraftDocument, options);
}
export function useAlertDraftLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlAlertDraftQuery,
    OperationTypes.GqlAlertDraftQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlAlertDraftQuery,
    OperationTypes.GqlAlertDraftQueryVariables
  >(AlertDraftDocument, options);
}
export function useAlertDraftSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlAlertDraftQuery,
    OperationTypes.GqlAlertDraftQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlAlertDraftQuery,
    OperationTypes.GqlAlertDraftQueryVariables
  >(AlertDraftDocument, options);
}
export type AlertDraftQueryHookResult = ReturnType<typeof useAlertDraftQuery>;
export type AlertDraftLazyQueryHookResult = ReturnType<
  typeof useAlertDraftLazyQuery
>;
export type AlertDraftSuspenseQueryHookResult = ReturnType<
  typeof useAlertDraftSuspenseQuery
>;
export type AlertDraftQueryResult = Apollo.QueryResult<
  OperationTypes.GqlAlertDraftQuery,
  OperationTypes.GqlAlertDraftQueryVariables
>;
export const AlertDraftAllLanguagesDocument = gql`
  query AlertDraftAllLanguages($id: MongoID!) {
    alert(id: $id) {
      id
      status
      createdAt
      updatedAt
      serie {
        id
        seqId
        name
        docFraudType
        docCountry
        docType
      }
      currentPublication {
        id
      }
      draft {
        ...draftAlertFields
        createdAt
        updatedAt
        createdBy {
          id
          name
        }
        updatedBy {
          id
          name
        }
        translations {
          ...alertTranslationFields
        }
      }
    }
  }
  ${DraftAlertFieldsFragmentDoc}
  ${AlertTranslationFieldsFragmentDoc}
`;

/**
 * __useAlertDraftAllLanguagesQuery__
 *
 * To run a query within a React component, call `useAlertDraftAllLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAlertDraftAllLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAlertDraftAllLanguagesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAlertDraftAllLanguagesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlAlertDraftAllLanguagesQuery,
    OperationTypes.GqlAlertDraftAllLanguagesQueryVariables
  > &
    (
      | {
          variables: OperationTypes.GqlAlertDraftAllLanguagesQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlAlertDraftAllLanguagesQuery,
    OperationTypes.GqlAlertDraftAllLanguagesQueryVariables
  >(AlertDraftAllLanguagesDocument, options);
}
export function useAlertDraftAllLanguagesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlAlertDraftAllLanguagesQuery,
    OperationTypes.GqlAlertDraftAllLanguagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlAlertDraftAllLanguagesQuery,
    OperationTypes.GqlAlertDraftAllLanguagesQueryVariables
  >(AlertDraftAllLanguagesDocument, options);
}
export function useAlertDraftAllLanguagesSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlAlertDraftAllLanguagesQuery,
    OperationTypes.GqlAlertDraftAllLanguagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlAlertDraftAllLanguagesQuery,
    OperationTypes.GqlAlertDraftAllLanguagesQueryVariables
  >(AlertDraftAllLanguagesDocument, options);
}
export type AlertDraftAllLanguagesQueryHookResult = ReturnType<
  typeof useAlertDraftAllLanguagesQuery
>;
export type AlertDraftAllLanguagesLazyQueryHookResult = ReturnType<
  typeof useAlertDraftAllLanguagesLazyQuery
>;
export type AlertDraftAllLanguagesSuspenseQueryHookResult = ReturnType<
  typeof useAlertDraftAllLanguagesSuspenseQuery
>;
export type AlertDraftAllLanguagesQueryResult = Apollo.QueryResult<
  OperationTypes.GqlAlertDraftAllLanguagesQuery,
  OperationTypes.GqlAlertDraftAllLanguagesQueryVariables
>;
export const AlertPublicationDocument = gql`
  query AlertPublication(
    $id: MongoID!
    $publicationId: ID!
    $language: Language
  ) {
    alert(id: $id) {
      id
      status
      createdAt
      updatedAt
      serie {
        id
        seqId
        name
        docFraudType
        docCountry
        docType
      }
      currentPublication {
        id
      }
      draft {
        id
        updatedAt
      }
      publication(id: $publicationId) {
        ...publicationAlertFields
        translation(language: $language) {
          ...alertTranslationFields
        }
      }
    }
  }
  ${PublicationAlertFieldsFragmentDoc}
  ${AlertTranslationFieldsFragmentDoc}
`;

/**
 * __useAlertPublicationQuery__
 *
 * To run a query within a React component, call `useAlertPublicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAlertPublicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAlertPublicationQuery({
 *   variables: {
 *      id: // value for 'id'
 *      publicationId: // value for 'publicationId'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useAlertPublicationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlAlertPublicationQuery,
    OperationTypes.GqlAlertPublicationQueryVariables
  > &
    (
      | {
          variables: OperationTypes.GqlAlertPublicationQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlAlertPublicationQuery,
    OperationTypes.GqlAlertPublicationQueryVariables
  >(AlertPublicationDocument, options);
}
export function useAlertPublicationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlAlertPublicationQuery,
    OperationTypes.GqlAlertPublicationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlAlertPublicationQuery,
    OperationTypes.GqlAlertPublicationQueryVariables
  >(AlertPublicationDocument, options);
}
export function useAlertPublicationSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlAlertPublicationQuery,
    OperationTypes.GqlAlertPublicationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlAlertPublicationQuery,
    OperationTypes.GqlAlertPublicationQueryVariables
  >(AlertPublicationDocument, options);
}
export type AlertPublicationQueryHookResult = ReturnType<
  typeof useAlertPublicationQuery
>;
export type AlertPublicationLazyQueryHookResult = ReturnType<
  typeof useAlertPublicationLazyQuery
>;
export type AlertPublicationSuspenseQueryHookResult = ReturnType<
  typeof useAlertPublicationSuspenseQuery
>;
export type AlertPublicationQueryResult = Apollo.QueryResult<
  OperationTypes.GqlAlertPublicationQuery,
  OperationTypes.GqlAlertPublicationQueryVariables
>;
export const AlertVersionsDocument = gql`
  query alertVersions($id: MongoID!, $language: Language) {
    alert(id: $id) {
      id
      publications {
        id
        publishedAt
        translation(language: $language) {
          ...alertTranslationTitle
        }
      }
      draft {
        id
        updatedAt
        translation(language: $language) {
          ...alertTranslationTitle
        }
      }
    }
  }
  ${AlertTranslationTitleFragmentDoc}
`;

/**
 * __useAlertVersionsQuery__
 *
 * To run a query within a React component, call `useAlertVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAlertVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAlertVersionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useAlertVersionsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlAlertVersionsQuery,
    OperationTypes.GqlAlertVersionsQueryVariables
  > &
    (
      | {
          variables: OperationTypes.GqlAlertVersionsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlAlertVersionsQuery,
    OperationTypes.GqlAlertVersionsQueryVariables
  >(AlertVersionsDocument, options);
}
export function useAlertVersionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlAlertVersionsQuery,
    OperationTypes.GqlAlertVersionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlAlertVersionsQuery,
    OperationTypes.GqlAlertVersionsQueryVariables
  >(AlertVersionsDocument, options);
}
export function useAlertVersionsSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlAlertVersionsQuery,
    OperationTypes.GqlAlertVersionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlAlertVersionsQuery,
    OperationTypes.GqlAlertVersionsQueryVariables
  >(AlertVersionsDocument, options);
}
export type AlertVersionsQueryHookResult = ReturnType<
  typeof useAlertVersionsQuery
>;
export type AlertVersionsLazyQueryHookResult = ReturnType<
  typeof useAlertVersionsLazyQuery
>;
export type AlertVersionsSuspenseQueryHookResult = ReturnType<
  typeof useAlertVersionsSuspenseQuery
>;
export type AlertVersionsQueryResult = Apollo.QueryResult<
  OperationTypes.GqlAlertVersionsQuery,
  OperationTypes.GqlAlertVersionsQueryVariables
>;
export const AuditLogsDocument = gql`
  query auditLogs(
    $filterBy: AuditLogFiltersInput
    $sortBy: AuditLogSortInput
    $limit: Int
    $skip: Int
  ) {
    auditLogs(
      filterBy: $filterBy
      sortBy: $sortBy
      limit: $limit
      skip: $skip
    ) {
      nodes {
        id
        action
        date
        user {
          id
          name
          email
        }
        identifiers {
          ... on AuditLogIdentifiersWithMongoId {
            stringId: id
            collection
            idForHumans
          }
          ... on AuditLogIdentifiersWithNumberId {
            numberId: id
            collection
            idForHumans
          }
        }
        data {
          ... on AuditLogDataLabelDelete {
            id
            name
            description
            color {
              text
              background
            }
            docCountry
            docFraudType
            docType
          }
          ... on AuditLogDataDocumentDelete {
            documentId
            service
            caseNumber
            caseExhibitNumber
            reason
          }
          ... on AuditLogDataDocumentUpdate {
            documentId
            document {
              ...documentIdentifierFields
            }
            fields
          }
          ... on AuditLogDataDocumentUpdateSeries {
            documentId
            document {
              ...documentIdentifierFields
            }
            series {
              ...serieIdentifierFields
            }
            type
          }
          ... on AuditLogDataScanDelete {
            scanId
            filename
          }
          ... on AuditLogDataSeriesDelete {
            seriesId
            name
            reason
          }
          ... on AuditLogDataSeriesUpdate {
            seriesId
            series {
              ...serieIdentifierFields
            }
            fields
          }
          ... on AuditLogDataSeriesUpdateAttachments {
            seriesId
            series {
              ...serieIdentifierFields
            }
            type
          }
          ... on AuditLogDataUserDelete {
            userId
            email
          }
        }
      }
      totalCount
    }
  }
  ${DocumentIdentifierFieldsFragmentDoc}
  ${SerieIdentifierFieldsFragmentDoc}
`;

/**
 * __useAuditLogsQuery__
 *
 * To run a query within a React component, call `useAuditLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuditLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuditLogsQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      sortBy: // value for 'sortBy'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useAuditLogsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlAuditLogsQuery,
    OperationTypes.GqlAuditLogsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlAuditLogsQuery,
    OperationTypes.GqlAuditLogsQueryVariables
  >(AuditLogsDocument, options);
}
export function useAuditLogsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlAuditLogsQuery,
    OperationTypes.GqlAuditLogsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlAuditLogsQuery,
    OperationTypes.GqlAuditLogsQueryVariables
  >(AuditLogsDocument, options);
}
export function useAuditLogsSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlAuditLogsQuery,
    OperationTypes.GqlAuditLogsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlAuditLogsQuery,
    OperationTypes.GqlAuditLogsQueryVariables
  >(AuditLogsDocument, options);
}
export type AuditLogsQueryHookResult = ReturnType<typeof useAuditLogsQuery>;
export type AuditLogsLazyQueryHookResult = ReturnType<
  typeof useAuditLogsLazyQuery
>;
export type AuditLogsSuspenseQueryHookResult = ReturnType<
  typeof useAuditLogsSuspenseQuery
>;
export type AuditLogsQueryResult = Apollo.QueryResult<
  OperationTypes.GqlAuditLogsQuery,
  OperationTypes.GqlAuditLogsQueryVariables
>;
export const DocumentBySeqIdDocument = gql`
  query documentBySeqId($seqId: String!) {
    documentBySeqId(seqId: $seqId) {
      ...detailedDocumentFields
    }
  }
  ${DetailedDocumentFieldsFragmentDoc}
`;

/**
 * __useDocumentBySeqIdQuery__
 *
 * To run a query within a React component, call `useDocumentBySeqIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentBySeqIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentBySeqIdQuery({
 *   variables: {
 *      seqId: // value for 'seqId'
 *   },
 * });
 */
export function useDocumentBySeqIdQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlDocumentBySeqIdQuery,
    OperationTypes.GqlDocumentBySeqIdQueryVariables
  > &
    (
      | {
          variables: OperationTypes.GqlDocumentBySeqIdQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlDocumentBySeqIdQuery,
    OperationTypes.GqlDocumentBySeqIdQueryVariables
  >(DocumentBySeqIdDocument, options);
}
export function useDocumentBySeqIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlDocumentBySeqIdQuery,
    OperationTypes.GqlDocumentBySeqIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlDocumentBySeqIdQuery,
    OperationTypes.GqlDocumentBySeqIdQueryVariables
  >(DocumentBySeqIdDocument, options);
}
export function useDocumentBySeqIdSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlDocumentBySeqIdQuery,
    OperationTypes.GqlDocumentBySeqIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlDocumentBySeqIdQuery,
    OperationTypes.GqlDocumentBySeqIdQueryVariables
  >(DocumentBySeqIdDocument, options);
}
export type DocumentBySeqIdQueryHookResult = ReturnType<
  typeof useDocumentBySeqIdQuery
>;
export type DocumentBySeqIdLazyQueryHookResult = ReturnType<
  typeof useDocumentBySeqIdLazyQuery
>;
export type DocumentBySeqIdSuspenseQueryHookResult = ReturnType<
  typeof useDocumentBySeqIdSuspenseQuery
>;
export type DocumentBySeqIdQueryResult = Apollo.QueryResult<
  OperationTypes.GqlDocumentBySeqIdQuery,
  OperationTypes.GqlDocumentBySeqIdQueryVariables
>;
export const DocumentDocumentSetCandidatesDocument = gql`
  query documentDocumentSetCandidates(
    $id: MongoID!
    $skip: Int
    $limit: Int
    $sort: DocumentSetsSort
    $filters: DocumentDocumentSetsFiltersInput
  ) {
    document(id: $id) {
      id
      docSetCandidates(
        skip: $skip
        limit: $limit
        sort: $sort
        filters: $filters
      ) {
        totalCount
        nodes {
          id
          name
          createdAt
          documents(limit: 1) {
            totalCount
            nodes {
              id
              images {
                id
                isCorrected
                document {
                  id
                  url
                }
                documentThumb {
                  id
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useDocumentDocumentSetCandidatesQuery__
 *
 * To run a query within a React component, call `useDocumentDocumentSetCandidatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentDocumentSetCandidatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentDocumentSetCandidatesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useDocumentDocumentSetCandidatesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlDocumentDocumentSetCandidatesQuery,
    OperationTypes.GqlDocumentDocumentSetCandidatesQueryVariables
  > &
    (
      | {
          variables: OperationTypes.GqlDocumentDocumentSetCandidatesQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlDocumentDocumentSetCandidatesQuery,
    OperationTypes.GqlDocumentDocumentSetCandidatesQueryVariables
  >(DocumentDocumentSetCandidatesDocument, options);
}
export function useDocumentDocumentSetCandidatesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlDocumentDocumentSetCandidatesQuery,
    OperationTypes.GqlDocumentDocumentSetCandidatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlDocumentDocumentSetCandidatesQuery,
    OperationTypes.GqlDocumentDocumentSetCandidatesQueryVariables
  >(DocumentDocumentSetCandidatesDocument, options);
}
export function useDocumentDocumentSetCandidatesSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlDocumentDocumentSetCandidatesQuery,
    OperationTypes.GqlDocumentDocumentSetCandidatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlDocumentDocumentSetCandidatesQuery,
    OperationTypes.GqlDocumentDocumentSetCandidatesQueryVariables
  >(DocumentDocumentSetCandidatesDocument, options);
}
export type DocumentDocumentSetCandidatesQueryHookResult = ReturnType<
  typeof useDocumentDocumentSetCandidatesQuery
>;
export type DocumentDocumentSetCandidatesLazyQueryHookResult = ReturnType<
  typeof useDocumentDocumentSetCandidatesLazyQuery
>;
export type DocumentDocumentSetCandidatesSuspenseQueryHookResult = ReturnType<
  typeof useDocumentDocumentSetCandidatesSuspenseQuery
>;
export type DocumentDocumentSetCandidatesQueryResult = Apollo.QueryResult<
  OperationTypes.GqlDocumentDocumentSetCandidatesQuery,
  OperationTypes.GqlDocumentDocumentSetCandidatesQueryVariables
>;
export const DocumentEditableDocument = gql`
  query documentEditable($seqId: String!) {
    document: documentBySeqId(seqId: $seqId) {
      ...documentIdentifierFields
      ...updateableDocumentFields
    }
  }
  ${DocumentIdentifierFieldsFragmentDoc}
  ${UpdateableDocumentFieldsFragmentDoc}
`;

/**
 * __useDocumentEditableQuery__
 *
 * To run a query within a React component, call `useDocumentEditableQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentEditableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentEditableQuery({
 *   variables: {
 *      seqId: // value for 'seqId'
 *   },
 * });
 */
export function useDocumentEditableQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlDocumentEditableQuery,
    OperationTypes.GqlDocumentEditableQueryVariables
  > &
    (
      | {
          variables: OperationTypes.GqlDocumentEditableQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlDocumentEditableQuery,
    OperationTypes.GqlDocumentEditableQueryVariables
  >(DocumentEditableDocument, options);
}
export function useDocumentEditableLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlDocumentEditableQuery,
    OperationTypes.GqlDocumentEditableQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlDocumentEditableQuery,
    OperationTypes.GqlDocumentEditableQueryVariables
  >(DocumentEditableDocument, options);
}
export function useDocumentEditableSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlDocumentEditableQuery,
    OperationTypes.GqlDocumentEditableQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlDocumentEditableQuery,
    OperationTypes.GqlDocumentEditableQueryVariables
  >(DocumentEditableDocument, options);
}
export type DocumentEditableQueryHookResult = ReturnType<
  typeof useDocumentEditableQuery
>;
export type DocumentEditableLazyQueryHookResult = ReturnType<
  typeof useDocumentEditableLazyQuery
>;
export type DocumentEditableSuspenseQueryHookResult = ReturnType<
  typeof useDocumentEditableSuspenseQuery
>;
export type DocumentEditableQueryResult = Apollo.QueryResult<
  OperationTypes.GqlDocumentEditableQuery,
  OperationTypes.GqlDocumentEditableQueryVariables
>;
export const DocumentExtraImagesDocument = gql`
  query documentExtraImages($seqId: String!) {
    document: documentBySeqId(seqId: $seqId) {
      ...documentIdentifierFields
      extraImages {
        ...detailedExtraImageFields
      }
    }
  }
  ${DocumentIdentifierFieldsFragmentDoc}
  ${DetailedExtraImageFieldsFragmentDoc}
`;

/**
 * __useDocumentExtraImagesQuery__
 *
 * To run a query within a React component, call `useDocumentExtraImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentExtraImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentExtraImagesQuery({
 *   variables: {
 *      seqId: // value for 'seqId'
 *   },
 * });
 */
export function useDocumentExtraImagesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlDocumentExtraImagesQuery,
    OperationTypes.GqlDocumentExtraImagesQueryVariables
  > &
    (
      | {
          variables: OperationTypes.GqlDocumentExtraImagesQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlDocumentExtraImagesQuery,
    OperationTypes.GqlDocumentExtraImagesQueryVariables
  >(DocumentExtraImagesDocument, options);
}
export function useDocumentExtraImagesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlDocumentExtraImagesQuery,
    OperationTypes.GqlDocumentExtraImagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlDocumentExtraImagesQuery,
    OperationTypes.GqlDocumentExtraImagesQueryVariables
  >(DocumentExtraImagesDocument, options);
}
export function useDocumentExtraImagesSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlDocumentExtraImagesQuery,
    OperationTypes.GqlDocumentExtraImagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlDocumentExtraImagesQuery,
    OperationTypes.GqlDocumentExtraImagesQueryVariables
  >(DocumentExtraImagesDocument, options);
}
export type DocumentExtraImagesQueryHookResult = ReturnType<
  typeof useDocumentExtraImagesQuery
>;
export type DocumentExtraImagesLazyQueryHookResult = ReturnType<
  typeof useDocumentExtraImagesLazyQuery
>;
export type DocumentExtraImagesSuspenseQueryHookResult = ReturnType<
  typeof useDocumentExtraImagesSuspenseQuery
>;
export type DocumentExtraImagesQueryResult = Apollo.QueryResult<
  OperationTypes.GqlDocumentExtraImagesQuery,
  OperationTypes.GqlDocumentExtraImagesQueryVariables
>;
export const DocumentIdDocument = gql`
  query documentId($seqId: String!) {
    document: documentBySeqId(seqId: $seqId) {
      ...documentIdentifierFields
    }
  }
  ${DocumentIdentifierFieldsFragmentDoc}
`;

/**
 * __useDocumentIdQuery__
 *
 * To run a query within a React component, call `useDocumentIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentIdQuery({
 *   variables: {
 *      seqId: // value for 'seqId'
 *   },
 * });
 */
export function useDocumentIdQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlDocumentIdQuery,
    OperationTypes.GqlDocumentIdQueryVariables
  > &
    (
      | {
          variables: OperationTypes.GqlDocumentIdQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlDocumentIdQuery,
    OperationTypes.GqlDocumentIdQueryVariables
  >(DocumentIdDocument, options);
}
export function useDocumentIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlDocumentIdQuery,
    OperationTypes.GqlDocumentIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlDocumentIdQuery,
    OperationTypes.GqlDocumentIdQueryVariables
  >(DocumentIdDocument, options);
}
export function useDocumentIdSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlDocumentIdQuery,
    OperationTypes.GqlDocumentIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlDocumentIdQuery,
    OperationTypes.GqlDocumentIdQueryVariables
  >(DocumentIdDocument, options);
}
export type DocumentIdQueryHookResult = ReturnType<typeof useDocumentIdQuery>;
export type DocumentIdLazyQueryHookResult = ReturnType<
  typeof useDocumentIdLazyQuery
>;
export type DocumentIdSuspenseQueryHookResult = ReturnType<
  typeof useDocumentIdSuspenseQuery
>;
export type DocumentIdQueryResult = Apollo.QueryResult<
  OperationTypes.GqlDocumentIdQuery,
  OperationTypes.GqlDocumentIdQueryVariables
>;
export const DocumentImageBySeqIdDocument = gql`
  query documentImageBySeqId($seqId: String!, $imageId: ID!) {
    document: documentBySeqId(seqId: $seqId) {
      ...documentIdentifierFields
      image(id: $imageId, corrected: false) {
        ...documentImageIdentifiers
        date
        importDate
        resolution
        view
        document {
          id
          url
          size
          filename
        }
        scanner {
          brand
          description
          model
          serialNumber
          service
        }
        originalScan {
          id
          image {
            id
            url
            filename
            size
          }
          status
          createdAt
          updatedAt
          createdBy {
            id
            name
          }
          error
        }
        full {
          id
          filename
          url
          size
        }
        colorData {
          type
          colors
        }
      }
    }
  }
  ${DocumentIdentifierFieldsFragmentDoc}
  ${DocumentImageIdentifiersFragmentDoc}
`;

/**
 * __useDocumentImageBySeqIdQuery__
 *
 * To run a query within a React component, call `useDocumentImageBySeqIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentImageBySeqIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentImageBySeqIdQuery({
 *   variables: {
 *      seqId: // value for 'seqId'
 *      imageId: // value for 'imageId'
 *   },
 * });
 */
export function useDocumentImageBySeqIdQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlDocumentImageBySeqIdQuery,
    OperationTypes.GqlDocumentImageBySeqIdQueryVariables
  > &
    (
      | {
          variables: OperationTypes.GqlDocumentImageBySeqIdQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlDocumentImageBySeqIdQuery,
    OperationTypes.GqlDocumentImageBySeqIdQueryVariables
  >(DocumentImageBySeqIdDocument, options);
}
export function useDocumentImageBySeqIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlDocumentImageBySeqIdQuery,
    OperationTypes.GqlDocumentImageBySeqIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlDocumentImageBySeqIdQuery,
    OperationTypes.GqlDocumentImageBySeqIdQueryVariables
  >(DocumentImageBySeqIdDocument, options);
}
export function useDocumentImageBySeqIdSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlDocumentImageBySeqIdQuery,
    OperationTypes.GqlDocumentImageBySeqIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlDocumentImageBySeqIdQuery,
    OperationTypes.GqlDocumentImageBySeqIdQueryVariables
  >(DocumentImageBySeqIdDocument, options);
}
export type DocumentImageBySeqIdQueryHookResult = ReturnType<
  typeof useDocumentImageBySeqIdQuery
>;
export type DocumentImageBySeqIdLazyQueryHookResult = ReturnType<
  typeof useDocumentImageBySeqIdLazyQuery
>;
export type DocumentImageBySeqIdSuspenseQueryHookResult = ReturnType<
  typeof useDocumentImageBySeqIdSuspenseQuery
>;
export type DocumentImageBySeqIdQueryResult = Apollo.QueryResult<
  OperationTypes.GqlDocumentImageBySeqIdQuery,
  OperationTypes.GqlDocumentImageBySeqIdQueryVariables
>;
export const DocumentPadifAnalysisRoisDocument = gql`
  query documentPadifAnalysisRois($id: MongoID!) {
    document(id: $id) {
      id
      padifAnalyses {
        documentSet {
          id
          name
        }
        ...padifAnalysisShowMainReference
      }
    }
  }
  ${PadifAnalysisShowMainReferenceFragmentDoc}
`;

/**
 * __useDocumentPadifAnalysisRoisQuery__
 *
 * To run a query within a React component, call `useDocumentPadifAnalysisRoisQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentPadifAnalysisRoisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentPadifAnalysisRoisQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDocumentPadifAnalysisRoisQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlDocumentPadifAnalysisRoisQuery,
    OperationTypes.GqlDocumentPadifAnalysisRoisQueryVariables
  > &
    (
      | {
          variables: OperationTypes.GqlDocumentPadifAnalysisRoisQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlDocumentPadifAnalysisRoisQuery,
    OperationTypes.GqlDocumentPadifAnalysisRoisQueryVariables
  >(DocumentPadifAnalysisRoisDocument, options);
}
export function useDocumentPadifAnalysisRoisLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlDocumentPadifAnalysisRoisQuery,
    OperationTypes.GqlDocumentPadifAnalysisRoisQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlDocumentPadifAnalysisRoisQuery,
    OperationTypes.GqlDocumentPadifAnalysisRoisQueryVariables
  >(DocumentPadifAnalysisRoisDocument, options);
}
export function useDocumentPadifAnalysisRoisSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlDocumentPadifAnalysisRoisQuery,
    OperationTypes.GqlDocumentPadifAnalysisRoisQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlDocumentPadifAnalysisRoisQuery,
    OperationTypes.GqlDocumentPadifAnalysisRoisQueryVariables
  >(DocumentPadifAnalysisRoisDocument, options);
}
export type DocumentPadifAnalysisRoisQueryHookResult = ReturnType<
  typeof useDocumentPadifAnalysisRoisQuery
>;
export type DocumentPadifAnalysisRoisLazyQueryHookResult = ReturnType<
  typeof useDocumentPadifAnalysisRoisLazyQuery
>;
export type DocumentPadifAnalysisRoisSuspenseQueryHookResult = ReturnType<
  typeof useDocumentPadifAnalysisRoisSuspenseQuery
>;
export type DocumentPadifAnalysisRoisQueryResult = Apollo.QueryResult<
  OperationTypes.GqlDocumentPadifAnalysisRoisQuery,
  OperationTypes.GqlDocumentPadifAnalysisRoisQueryVariables
>;
export const DocumentPadifAnalysisDistancesDocument = gql`
  query documentPadifAnalysisDistances(
    $id: MongoID!
    $padifAnalysis: NumberID!
    $skip: Int!
    $limit: Int!
  ) {
    document(id: $id) {
      id
      padifAnalysisDocumentDistance(
        padifAnalysis: $padifAnalysis
        skip: $skip
        limit: $limit
      ) {
        totalCount
        nodes {
          document {
            ...documentIdentifierFields
            series {
              ...serieIdentifierFields
            }
            images(corrected: false) {
              ...documentImageIdentifiers
              document {
                id
                url
              }
              documentThumb {
                id
                url
              }
            }
          }
          distance
        }
      }
    }
  }
  ${DocumentIdentifierFieldsFragmentDoc}
  ${SerieIdentifierFieldsFragmentDoc}
  ${DocumentImageIdentifiersFragmentDoc}
`;

/**
 * __useDocumentPadifAnalysisDistancesQuery__
 *
 * To run a query within a React component, call `useDocumentPadifAnalysisDistancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentPadifAnalysisDistancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentPadifAnalysisDistancesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      padifAnalysis: // value for 'padifAnalysis'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useDocumentPadifAnalysisDistancesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlDocumentPadifAnalysisDistancesQuery,
    OperationTypes.GqlDocumentPadifAnalysisDistancesQueryVariables
  > &
    (
      | {
          variables: OperationTypes.GqlDocumentPadifAnalysisDistancesQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlDocumentPadifAnalysisDistancesQuery,
    OperationTypes.GqlDocumentPadifAnalysisDistancesQueryVariables
  >(DocumentPadifAnalysisDistancesDocument, options);
}
export function useDocumentPadifAnalysisDistancesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlDocumentPadifAnalysisDistancesQuery,
    OperationTypes.GqlDocumentPadifAnalysisDistancesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlDocumentPadifAnalysisDistancesQuery,
    OperationTypes.GqlDocumentPadifAnalysisDistancesQueryVariables
  >(DocumentPadifAnalysisDistancesDocument, options);
}
export function useDocumentPadifAnalysisDistancesSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlDocumentPadifAnalysisDistancesQuery,
    OperationTypes.GqlDocumentPadifAnalysisDistancesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlDocumentPadifAnalysisDistancesQuery,
    OperationTypes.GqlDocumentPadifAnalysisDistancesQueryVariables
  >(DocumentPadifAnalysisDistancesDocument, options);
}
export type DocumentPadifAnalysisDistancesQueryHookResult = ReturnType<
  typeof useDocumentPadifAnalysisDistancesQuery
>;
export type DocumentPadifAnalysisDistancesLazyQueryHookResult = ReturnType<
  typeof useDocumentPadifAnalysisDistancesLazyQuery
>;
export type DocumentPadifAnalysisDistancesSuspenseQueryHookResult = ReturnType<
  typeof useDocumentPadifAnalysisDistancesSuspenseQuery
>;
export type DocumentPadifAnalysisDistancesQueryResult = Apollo.QueryResult<
  OperationTypes.GqlDocumentPadifAnalysisDistancesQuery,
  OperationTypes.GqlDocumentPadifAnalysisDistancesQueryVariables
>;
export const DocumentSetDocument = gql`
  query documentSet($id: NumberID!) {
    documentSet(id: $id) {
      ...documentSetCommonFields
    }
  }
  ${DocumentSetCommonFieldsFragmentDoc}
`;

/**
 * __useDocumentSetQuery__
 *
 * To run a query within a React component, call `useDocumentSetQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentSetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentSetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDocumentSetQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlDocumentSetQuery,
    OperationTypes.GqlDocumentSetQueryVariables
  > &
    (
      | {
          variables: OperationTypes.GqlDocumentSetQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlDocumentSetQuery,
    OperationTypes.GqlDocumentSetQueryVariables
  >(DocumentSetDocument, options);
}
export function useDocumentSetLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlDocumentSetQuery,
    OperationTypes.GqlDocumentSetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlDocumentSetQuery,
    OperationTypes.GqlDocumentSetQueryVariables
  >(DocumentSetDocument, options);
}
export function useDocumentSetSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlDocumentSetQuery,
    OperationTypes.GqlDocumentSetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlDocumentSetQuery,
    OperationTypes.GqlDocumentSetQueryVariables
  >(DocumentSetDocument, options);
}
export type DocumentSetQueryHookResult = ReturnType<typeof useDocumentSetQuery>;
export type DocumentSetLazyQueryHookResult = ReturnType<
  typeof useDocumentSetLazyQuery
>;
export type DocumentSetSuspenseQueryHookResult = ReturnType<
  typeof useDocumentSetSuspenseQuery
>;
export type DocumentSetQueryResult = Apollo.QueryResult<
  OperationTypes.GqlDocumentSetQuery,
  OperationTypes.GqlDocumentSetQueryVariables
>;
export const DocumentSetWithFiltersDocument = gql`
  query documentSetWithFilters($id: NumberID!) {
    documentSet(id: $id) {
      ...documentSetCommonFields
      dynamicFilters {
        ...documentSetDynamicFilters
      }
    }
  }
  ${DocumentSetCommonFieldsFragmentDoc}
  ${DocumentSetDynamicFiltersFragmentDoc}
`;

/**
 * __useDocumentSetWithFiltersQuery__
 *
 * To run a query within a React component, call `useDocumentSetWithFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentSetWithFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentSetWithFiltersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDocumentSetWithFiltersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlDocumentSetWithFiltersQuery,
    OperationTypes.GqlDocumentSetWithFiltersQueryVariables
  > &
    (
      | {
          variables: OperationTypes.GqlDocumentSetWithFiltersQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlDocumentSetWithFiltersQuery,
    OperationTypes.GqlDocumentSetWithFiltersQueryVariables
  >(DocumentSetWithFiltersDocument, options);
}
export function useDocumentSetWithFiltersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlDocumentSetWithFiltersQuery,
    OperationTypes.GqlDocumentSetWithFiltersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlDocumentSetWithFiltersQuery,
    OperationTypes.GqlDocumentSetWithFiltersQueryVariables
  >(DocumentSetWithFiltersDocument, options);
}
export function useDocumentSetWithFiltersSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlDocumentSetWithFiltersQuery,
    OperationTypes.GqlDocumentSetWithFiltersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlDocumentSetWithFiltersQuery,
    OperationTypes.GqlDocumentSetWithFiltersQueryVariables
  >(DocumentSetWithFiltersDocument, options);
}
export type DocumentSetWithFiltersQueryHookResult = ReturnType<
  typeof useDocumentSetWithFiltersQuery
>;
export type DocumentSetWithFiltersLazyQueryHookResult = ReturnType<
  typeof useDocumentSetWithFiltersLazyQuery
>;
export type DocumentSetWithFiltersSuspenseQueryHookResult = ReturnType<
  typeof useDocumentSetWithFiltersSuspenseQuery
>;
export type DocumentSetWithFiltersQueryResult = Apollo.QueryResult<
  OperationTypes.GqlDocumentSetWithFiltersQuery,
  OperationTypes.GqlDocumentSetWithFiltersQueryVariables
>;
export const DocumentSetDocumentsDocument = gql`
  query documentSetDocuments(
    $id: NumberID!
    $limit: Int
    $skip: Int
    $sort: DocumentSetDocumentsSort
    $candidatesFilterBy: DocumentsForSetFiltersInput
  ) {
    documentSet(id: $id) {
      ...documentSetCommonFields
      createdAt
      createdBy {
        ...mailableUser
      }
      updatedAt
      updatedBy {
        ...mailableUser
      }
      dynamicFilters {
        ...documentSetDynamicFilters
      }
      documents(
        limit: $limit
        skip: $skip
        sort: $sort
        facets: [
          caseService
          casePresumedNationality
          docType
          docCountry
          docFraudType
          docIsMrzOk
          docMedium
          docPrintBackground
          docPrintNumber
          docPrintData
          docPrintPhoto
          docIsBackgroundFluo
          docIsBitmapCode
          series
          labels
        ]
      ) {
        totalCount
        nodes {
          ...documentFieldsForDocumentSet
        }
        facets {
          name
          values {
            filter
            count
          }
        }
      }
      allCandidatesIds(filterBy: $candidatesFilterBy)
      allDocumentsIds
      allExcludedDocumentsIds
      padifAnalysis {
        totalCount
      }
    }
  }
  ${DocumentSetCommonFieldsFragmentDoc}
  ${MailableUserFragmentDoc}
  ${DocumentSetDynamicFiltersFragmentDoc}
  ${DocumentFieldsForDocumentSetFragmentDoc}
`;

/**
 * __useDocumentSetDocumentsQuery__
 *
 * To run a query within a React component, call `useDocumentSetDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentSetDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentSetDocumentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      sort: // value for 'sort'
 *      candidatesFilterBy: // value for 'candidatesFilterBy'
 *   },
 * });
 */
export function useDocumentSetDocumentsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlDocumentSetDocumentsQuery,
    OperationTypes.GqlDocumentSetDocumentsQueryVariables
  > &
    (
      | {
          variables: OperationTypes.GqlDocumentSetDocumentsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlDocumentSetDocumentsQuery,
    OperationTypes.GqlDocumentSetDocumentsQueryVariables
  >(DocumentSetDocumentsDocument, options);
}
export function useDocumentSetDocumentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlDocumentSetDocumentsQuery,
    OperationTypes.GqlDocumentSetDocumentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlDocumentSetDocumentsQuery,
    OperationTypes.GqlDocumentSetDocumentsQueryVariables
  >(DocumentSetDocumentsDocument, options);
}
export function useDocumentSetDocumentsSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlDocumentSetDocumentsQuery,
    OperationTypes.GqlDocumentSetDocumentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlDocumentSetDocumentsQuery,
    OperationTypes.GqlDocumentSetDocumentsQueryVariables
  >(DocumentSetDocumentsDocument, options);
}
export type DocumentSetDocumentsQueryHookResult = ReturnType<
  typeof useDocumentSetDocumentsQuery
>;
export type DocumentSetDocumentsLazyQueryHookResult = ReturnType<
  typeof useDocumentSetDocumentsLazyQuery
>;
export type DocumentSetDocumentsSuspenseQueryHookResult = ReturnType<
  typeof useDocumentSetDocumentsSuspenseQuery
>;
export type DocumentSetDocumentsQueryResult = Apollo.QueryResult<
  OperationTypes.GqlDocumentSetDocumentsQuery,
  OperationTypes.GqlDocumentSetDocumentsQueryVariables
>;
export const SimulateDocumentsWithFiltersDocument = gql`
  query simulateDocumentsWithFilters(
    $limit: Int
    $skip: Int
    $filterBy: DocumentFiltersInput
    $sortBy: DocumentSortInput
  ) {
    documents(
      limit: $limit
      skip: $skip
      sortBy: $sortBy
      filterBy: $filterBy
      facets: [
        caseService
        docType
        docCountry
        docFraudType
        docIsMrzOk
        docMedium
        docPrintBackground
        docPrintNumber
        docPrintData
        docPrintPhoto
        docIsBackgroundFluo
        docIsBitmapCode
        series
        casePresumedNationality
        labels
      ]
    ) {
      totalCount
      nodes {
        ...documentFieldsForDocumentSet
      }
      facets {
        name
        values {
          filter
          count
        }
      }
    }
  }
  ${DocumentFieldsForDocumentSetFragmentDoc}
`;

/**
 * __useSimulateDocumentsWithFiltersQuery__
 *
 * To run a query within a React component, call `useSimulateDocumentsWithFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimulateDocumentsWithFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimulateDocumentsWithFiltersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      filterBy: // value for 'filterBy'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useSimulateDocumentsWithFiltersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlSimulateDocumentsWithFiltersQuery,
    OperationTypes.GqlSimulateDocumentsWithFiltersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlSimulateDocumentsWithFiltersQuery,
    OperationTypes.GqlSimulateDocumentsWithFiltersQueryVariables
  >(SimulateDocumentsWithFiltersDocument, options);
}
export function useSimulateDocumentsWithFiltersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlSimulateDocumentsWithFiltersQuery,
    OperationTypes.GqlSimulateDocumentsWithFiltersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlSimulateDocumentsWithFiltersQuery,
    OperationTypes.GqlSimulateDocumentsWithFiltersQueryVariables
  >(SimulateDocumentsWithFiltersDocument, options);
}
export function useSimulateDocumentsWithFiltersSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlSimulateDocumentsWithFiltersQuery,
    OperationTypes.GqlSimulateDocumentsWithFiltersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlSimulateDocumentsWithFiltersQuery,
    OperationTypes.GqlSimulateDocumentsWithFiltersQueryVariables
  >(SimulateDocumentsWithFiltersDocument, options);
}
export type SimulateDocumentsWithFiltersQueryHookResult = ReturnType<
  typeof useSimulateDocumentsWithFiltersQuery
>;
export type SimulateDocumentsWithFiltersLazyQueryHookResult = ReturnType<
  typeof useSimulateDocumentsWithFiltersLazyQuery
>;
export type SimulateDocumentsWithFiltersSuspenseQueryHookResult = ReturnType<
  typeof useSimulateDocumentsWithFiltersSuspenseQuery
>;
export type SimulateDocumentsWithFiltersQueryResult = Apollo.QueryResult<
  OperationTypes.GqlSimulateDocumentsWithFiltersQuery,
  OperationTypes.GqlSimulateDocumentsWithFiltersQueryVariables
>;
export const DocumentSetExcludedDocumentsDocument = gql`
  query documentSetExcludedDocuments(
    $id: NumberID!
    $limit: Int
    $skip: Int
    $sort: DocumentSetDocumentsSort
  ) {
    documentSet(id: $id) {
      id
      name
      type
      docType
      docCountry
      excludedDocuments(limit: $limit, skip: $skip, sort: $sort) {
        nodes {
          ...documentFieldsForDocumentSet
        }
        totalCount
        facets {
          values {
            count
            filter
          }
          name
        }
      }
      allExcludedDocumentsIds
    }
  }
  ${DocumentFieldsForDocumentSetFragmentDoc}
`;

/**
 * __useDocumentSetExcludedDocumentsQuery__
 *
 * To run a query within a React component, call `useDocumentSetExcludedDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentSetExcludedDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentSetExcludedDocumentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useDocumentSetExcludedDocumentsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlDocumentSetExcludedDocumentsQuery,
    OperationTypes.GqlDocumentSetExcludedDocumentsQueryVariables
  > &
    (
      | {
          variables: OperationTypes.GqlDocumentSetExcludedDocumentsQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlDocumentSetExcludedDocumentsQuery,
    OperationTypes.GqlDocumentSetExcludedDocumentsQueryVariables
  >(DocumentSetExcludedDocumentsDocument, options);
}
export function useDocumentSetExcludedDocumentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlDocumentSetExcludedDocumentsQuery,
    OperationTypes.GqlDocumentSetExcludedDocumentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlDocumentSetExcludedDocumentsQuery,
    OperationTypes.GqlDocumentSetExcludedDocumentsQueryVariables
  >(DocumentSetExcludedDocumentsDocument, options);
}
export function useDocumentSetExcludedDocumentsSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlDocumentSetExcludedDocumentsQuery,
    OperationTypes.GqlDocumentSetExcludedDocumentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlDocumentSetExcludedDocumentsQuery,
    OperationTypes.GqlDocumentSetExcludedDocumentsQueryVariables
  >(DocumentSetExcludedDocumentsDocument, options);
}
export type DocumentSetExcludedDocumentsQueryHookResult = ReturnType<
  typeof useDocumentSetExcludedDocumentsQuery
>;
export type DocumentSetExcludedDocumentsLazyQueryHookResult = ReturnType<
  typeof useDocumentSetExcludedDocumentsLazyQuery
>;
export type DocumentSetExcludedDocumentsSuspenseQueryHookResult = ReturnType<
  typeof useDocumentSetExcludedDocumentsSuspenseQuery
>;
export type DocumentSetExcludedDocumentsQueryResult = Apollo.QueryResult<
  OperationTypes.GqlDocumentSetExcludedDocumentsQuery,
  OperationTypes.GqlDocumentSetExcludedDocumentsQueryVariables
>;
export const DocumentSetsDocument = gql`
  query documentSets(
    $limit: Int
    $skip: Int
    $sort: DocumentSetsSort
    $filters: DocumentSetFiltersInput
  ) {
    documentSets(limit: $limit, skip: $skip, sort: $sort, filters: $filters) {
      totalCount
      facets {
        name
        values {
          count
          filter
        }
      }
      nodes {
        id
        docCountry
        docType
        name
        type
        createdAt
        documents(limit: 1) {
          totalCount
          nodes {
            id
            images(corrected: false) {
              ...documentImageIdentifiers
              view
              documentThumb {
                id
                url
              }
              document {
                id
                url
              }
            }
          }
        }
      }
    }
  }
  ${DocumentImageIdentifiersFragmentDoc}
`;

/**
 * __useDocumentSetsQuery__
 *
 * To run a query within a React component, call `useDocumentSetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentSetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentSetsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      sort: // value for 'sort'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useDocumentSetsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlDocumentSetsQuery,
    OperationTypes.GqlDocumentSetsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlDocumentSetsQuery,
    OperationTypes.GqlDocumentSetsQueryVariables
  >(DocumentSetsDocument, options);
}
export function useDocumentSetsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlDocumentSetsQuery,
    OperationTypes.GqlDocumentSetsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlDocumentSetsQuery,
    OperationTypes.GqlDocumentSetsQueryVariables
  >(DocumentSetsDocument, options);
}
export function useDocumentSetsSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlDocumentSetsQuery,
    OperationTypes.GqlDocumentSetsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlDocumentSetsQuery,
    OperationTypes.GqlDocumentSetsQueryVariables
  >(DocumentSetsDocument, options);
}
export type DocumentSetsQueryHookResult = ReturnType<
  typeof useDocumentSetsQuery
>;
export type DocumentSetsLazyQueryHookResult = ReturnType<
  typeof useDocumentSetsLazyQuery
>;
export type DocumentSetsSuspenseQueryHookResult = ReturnType<
  typeof useDocumentSetsSuspenseQuery
>;
export type DocumentSetsQueryResult = Apollo.QueryResult<
  OperationTypes.GqlDocumentSetsQuery,
  OperationTypes.GqlDocumentSetsQueryVariables
>;
export const DocumentSetSearchSelectDocument = gql`
  query documentSetSearchSelect($search: String) {
    documentSets(filters: { searchTerm: $search }) {
      nodes {
        id
        name
        type
        docType
        docCountry
      }
    }
  }
`;

/**
 * __useDocumentSetSearchSelectQuery__
 *
 * To run a query within a React component, call `useDocumentSetSearchSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentSetSearchSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentSetSearchSelectQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useDocumentSetSearchSelectQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlDocumentSetSearchSelectQuery,
    OperationTypes.GqlDocumentSetSearchSelectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlDocumentSetSearchSelectQuery,
    OperationTypes.GqlDocumentSetSearchSelectQueryVariables
  >(DocumentSetSearchSelectDocument, options);
}
export function useDocumentSetSearchSelectLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlDocumentSetSearchSelectQuery,
    OperationTypes.GqlDocumentSetSearchSelectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlDocumentSetSearchSelectQuery,
    OperationTypes.GqlDocumentSetSearchSelectQueryVariables
  >(DocumentSetSearchSelectDocument, options);
}
export function useDocumentSetSearchSelectSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlDocumentSetSearchSelectQuery,
    OperationTypes.GqlDocumentSetSearchSelectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlDocumentSetSearchSelectQuery,
    OperationTypes.GqlDocumentSetSearchSelectQueryVariables
  >(DocumentSetSearchSelectDocument, options);
}
export type DocumentSetSearchSelectQueryHookResult = ReturnType<
  typeof useDocumentSetSearchSelectQuery
>;
export type DocumentSetSearchSelectLazyQueryHookResult = ReturnType<
  typeof useDocumentSetSearchSelectLazyQuery
>;
export type DocumentSetSearchSelectSuspenseQueryHookResult = ReturnType<
  typeof useDocumentSetSearchSelectSuspenseQuery
>;
export type DocumentSetSearchSelectQueryResult = Apollo.QueryResult<
  OperationTypes.GqlDocumentSetSearchSelectQuery,
  OperationTypes.GqlDocumentSetSearchSelectQueryVariables
>;
export const DocumentSetsSelectDocument = gql`
  query documentSetsSelect {
    documentSets {
      totalCount
      nodes {
        id
        docCountry
        docType
        name
        documents(limit: 1) {
          nodes {
            id
            images(corrected: false) {
              ...documentImageIdentifiers
              view
              documentThumb {
                id
                url
              }
            }
          }
        }
      }
    }
  }
  ${DocumentImageIdentifiersFragmentDoc}
`;

/**
 * __useDocumentSetsSelectQuery__
 *
 * To run a query within a React component, call `useDocumentSetsSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentSetsSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentSetsSelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useDocumentSetsSelectQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlDocumentSetsSelectQuery,
    OperationTypes.GqlDocumentSetsSelectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlDocumentSetsSelectQuery,
    OperationTypes.GqlDocumentSetsSelectQueryVariables
  >(DocumentSetsSelectDocument, options);
}
export function useDocumentSetsSelectLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlDocumentSetsSelectQuery,
    OperationTypes.GqlDocumentSetsSelectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlDocumentSetsSelectQuery,
    OperationTypes.GqlDocumentSetsSelectQueryVariables
  >(DocumentSetsSelectDocument, options);
}
export function useDocumentSetsSelectSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlDocumentSetsSelectQuery,
    OperationTypes.GqlDocumentSetsSelectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlDocumentSetsSelectQuery,
    OperationTypes.GqlDocumentSetsSelectQueryVariables
  >(DocumentSetsSelectDocument, options);
}
export type DocumentSetsSelectQueryHookResult = ReturnType<
  typeof useDocumentSetsSelectQuery
>;
export type DocumentSetsSelectLazyQueryHookResult = ReturnType<
  typeof useDocumentSetsSelectLazyQuery
>;
export type DocumentSetsSelectSuspenseQueryHookResult = ReturnType<
  typeof useDocumentSetsSelectSuspenseQuery
>;
export type DocumentSetsSelectQueryResult = Apollo.QueryResult<
  OperationTypes.GqlDocumentSetsSelectQuery,
  OperationTypes.GqlDocumentSetsSelectQueryVariables
>;
export const DocumentSimilarSerialNumbersDocument = gql`
  query documentSimilarSerialNumbers($id: MongoID!) {
    document(id: $id) {
      id
      similarSerialNumbers {
        nodes {
          ...documentIdentifierFields
          series {
            ...serieIdentifierFields
          }
          docSerialNumber
        }
        totalCount
      }
    }
  }
  ${DocumentIdentifierFieldsFragmentDoc}
  ${SerieIdentifierFieldsFragmentDoc}
`;

/**
 * __useDocumentSimilarSerialNumbersQuery__
 *
 * To run a query within a React component, call `useDocumentSimilarSerialNumbersQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentSimilarSerialNumbersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentSimilarSerialNumbersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDocumentSimilarSerialNumbersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlDocumentSimilarSerialNumbersQuery,
    OperationTypes.GqlDocumentSimilarSerialNumbersQueryVariables
  > &
    (
      | {
          variables: OperationTypes.GqlDocumentSimilarSerialNumbersQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlDocumentSimilarSerialNumbersQuery,
    OperationTypes.GqlDocumentSimilarSerialNumbersQueryVariables
  >(DocumentSimilarSerialNumbersDocument, options);
}
export function useDocumentSimilarSerialNumbersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlDocumentSimilarSerialNumbersQuery,
    OperationTypes.GqlDocumentSimilarSerialNumbersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlDocumentSimilarSerialNumbersQuery,
    OperationTypes.GqlDocumentSimilarSerialNumbersQueryVariables
  >(DocumentSimilarSerialNumbersDocument, options);
}
export function useDocumentSimilarSerialNumbersSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlDocumentSimilarSerialNumbersQuery,
    OperationTypes.GqlDocumentSimilarSerialNumbersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlDocumentSimilarSerialNumbersQuery,
    OperationTypes.GqlDocumentSimilarSerialNumbersQueryVariables
  >(DocumentSimilarSerialNumbersDocument, options);
}
export type DocumentSimilarSerialNumbersQueryHookResult = ReturnType<
  typeof useDocumentSimilarSerialNumbersQuery
>;
export type DocumentSimilarSerialNumbersLazyQueryHookResult = ReturnType<
  typeof useDocumentSimilarSerialNumbersLazyQuery
>;
export type DocumentSimilarSerialNumbersSuspenseQueryHookResult = ReturnType<
  typeof useDocumentSimilarSerialNumbersSuspenseQuery
>;
export type DocumentSimilarSerialNumbersQueryResult = Apollo.QueryResult<
  OperationTypes.GqlDocumentSimilarSerialNumbersQuery,
  OperationTypes.GqlDocumentSimilarSerialNumbersQueryVariables
>;
export const DocumentStandardizedImagesDocument = gql`
  query documentStandardizedImages($seqId: String!) {
    document: documentBySeqId(seqId: $seqId) {
      ...documentIdentifierFields
      images(corrected: false) {
        ...detailedStandardizedImageFields
      }
    }
  }
  ${DocumentIdentifierFieldsFragmentDoc}
  ${DetailedStandardizedImageFieldsFragmentDoc}
`;

/**
 * __useDocumentStandardizedImagesQuery__
 *
 * To run a query within a React component, call `useDocumentStandardizedImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentStandardizedImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentStandardizedImagesQuery({
 *   variables: {
 *      seqId: // value for 'seqId'
 *   },
 * });
 */
export function useDocumentStandardizedImagesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlDocumentStandardizedImagesQuery,
    OperationTypes.GqlDocumentStandardizedImagesQueryVariables
  > &
    (
      | {
          variables: OperationTypes.GqlDocumentStandardizedImagesQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlDocumentStandardizedImagesQuery,
    OperationTypes.GqlDocumentStandardizedImagesQueryVariables
  >(DocumentStandardizedImagesDocument, options);
}
export function useDocumentStandardizedImagesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlDocumentStandardizedImagesQuery,
    OperationTypes.GqlDocumentStandardizedImagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlDocumentStandardizedImagesQuery,
    OperationTypes.GqlDocumentStandardizedImagesQueryVariables
  >(DocumentStandardizedImagesDocument, options);
}
export function useDocumentStandardizedImagesSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlDocumentStandardizedImagesQuery,
    OperationTypes.GqlDocumentStandardizedImagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlDocumentStandardizedImagesQuery,
    OperationTypes.GqlDocumentStandardizedImagesQueryVariables
  >(DocumentStandardizedImagesDocument, options);
}
export type DocumentStandardizedImagesQueryHookResult = ReturnType<
  typeof useDocumentStandardizedImagesQuery
>;
export type DocumentStandardizedImagesLazyQueryHookResult = ReturnType<
  typeof useDocumentStandardizedImagesLazyQuery
>;
export type DocumentStandardizedImagesSuspenseQueryHookResult = ReturnType<
  typeof useDocumentStandardizedImagesSuspenseQuery
>;
export type DocumentStandardizedImagesQueryResult = Apollo.QueryResult<
  OperationTypes.GqlDocumentStandardizedImagesQuery,
  OperationTypes.GqlDocumentStandardizedImagesQueryVariables
>;
export const DocumentsDocument = gql`
  query documents(
    $limit: Int
    $skip: Int
    $filterBy: DocumentFiltersInput
    $sortBy: DocumentSortInput
  ) {
    documents(
      limit: $limit
      skip: $skip
      filterBy: $filterBy
      sortBy: $sortBy
      facets: [
        caseService
        docType
        docCountry
        docFraudType
        docIsMrzOk
        docMedium
        docPrintBackground
        docPrintNumber
        docPrintData
        docPrintPhoto
        docIsBackgroundFluo
        docIsBitmapCode
        series
        casePresumedNationality
        labels
      ]
    ) {
      totalCount
      facets {
        name
        values {
          filter
          count
        }
      }
      nodes {
        ...documentIdentifierFields
        caseOperation
        casePresumedNationality
        caseSeizureDate
        docCountry
        docSerialNumber
        docFraudType
        docType
        series {
          ...serieIdentifierFields
        }
        createdAt
        ...viewImagesAllowExtra
        labels {
          ...labelBadgeFields
        }
      }
    }
  }
  ${DocumentIdentifierFieldsFragmentDoc}
  ${SerieIdentifierFieldsFragmentDoc}
  ${ViewImagesAllowExtraFragmentDoc}
  ${LabelBadgeFieldsFragmentDoc}
`;

/**
 * __useDocumentsQuery__
 *
 * To run a query within a React component, call `useDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      filterBy: // value for 'filterBy'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useDocumentsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlDocumentsQuery,
    OperationTypes.GqlDocumentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlDocumentsQuery,
    OperationTypes.GqlDocumentsQueryVariables
  >(DocumentsDocument, options);
}
export function useDocumentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlDocumentsQuery,
    OperationTypes.GqlDocumentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlDocumentsQuery,
    OperationTypes.GqlDocumentsQueryVariables
  >(DocumentsDocument, options);
}
export function useDocumentsSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlDocumentsQuery,
    OperationTypes.GqlDocumentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlDocumentsQuery,
    OperationTypes.GqlDocumentsQueryVariables
  >(DocumentsDocument, options);
}
export type DocumentsQueryHookResult = ReturnType<typeof useDocumentsQuery>;
export type DocumentsLazyQueryHookResult = ReturnType<
  typeof useDocumentsLazyQuery
>;
export type DocumentsSuspenseQueryHookResult = ReturnType<
  typeof useDocumentsSuspenseQuery
>;
export type DocumentsQueryResult = Apollo.QueryResult<
  OperationTypes.GqlDocumentsQuery,
  OperationTypes.GqlDocumentsQueryVariables
>;
export const DocumentsSearchSelectDocument = gql`
  query documentsSearchSelect($search: String) {
    documents(filterBy: { searchTerm: $search }, limit: 100) {
      nodes {
        ...documentIdentifierFields
      }
    }
  }
  ${DocumentIdentifierFieldsFragmentDoc}
`;

/**
 * __useDocumentsSearchSelectQuery__
 *
 * To run a query within a React component, call `useDocumentsSearchSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentsSearchSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentsSearchSelectQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useDocumentsSearchSelectQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlDocumentsSearchSelectQuery,
    OperationTypes.GqlDocumentsSearchSelectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlDocumentsSearchSelectQuery,
    OperationTypes.GqlDocumentsSearchSelectQueryVariables
  >(DocumentsSearchSelectDocument, options);
}
export function useDocumentsSearchSelectLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlDocumentsSearchSelectQuery,
    OperationTypes.GqlDocumentsSearchSelectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlDocumentsSearchSelectQuery,
    OperationTypes.GqlDocumentsSearchSelectQueryVariables
  >(DocumentsSearchSelectDocument, options);
}
export function useDocumentsSearchSelectSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlDocumentsSearchSelectQuery,
    OperationTypes.GqlDocumentsSearchSelectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlDocumentsSearchSelectQuery,
    OperationTypes.GqlDocumentsSearchSelectQueryVariables
  >(DocumentsSearchSelectDocument, options);
}
export type DocumentsSearchSelectQueryHookResult = ReturnType<
  typeof useDocumentsSearchSelectQuery
>;
export type DocumentsSearchSelectLazyQueryHookResult = ReturnType<
  typeof useDocumentsSearchSelectLazyQuery
>;
export type DocumentsSearchSelectSuspenseQueryHookResult = ReturnType<
  typeof useDocumentsSearchSelectSuspenseQuery
>;
export type DocumentsSearchSelectQueryResult = Apollo.QueryResult<
  OperationTypes.GqlDocumentsSearchSelectQuery,
  OperationTypes.GqlDocumentsSearchSelectQueryVariables
>;
export const DocumentsCandidatesForSetDocument = gql`
  query documentsCandidatesForSet(
    $documentSetId: NumberID!
    $filterBy: DocumentsForSetFiltersInput
    $skip: Int!
    $limit: Int!
    $sortBy: DocumentSortInput
  ) {
    documentsCandidatesForSet(
      facets: [
        caseService
        docFraudType
        docIsMrzOk
        docMedium
        docPrintBackground
        docPrintNumber
        docPrintData
        docPrintPhoto
        docIsBackgroundFluo
        docIsBitmapCode
        series
        casePresumedNationality
        labels
      ]
      documentSetId: $documentSetId
      filterBy: $filterBy
      skip: $skip
      limit: $limit
      sortBy: $sortBy
    ) {
      totalCount
      nodes {
        ...documentFieldsForDocumentSet
      }
      facets {
        name
        values {
          filter
          count
        }
      }
    }
  }
  ${DocumentFieldsForDocumentSetFragmentDoc}
`;

/**
 * __useDocumentsCandidatesForSetQuery__
 *
 * To run a query within a React component, call `useDocumentsCandidatesForSetQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentsCandidatesForSetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentsCandidatesForSetQuery({
 *   variables: {
 *      documentSetId: // value for 'documentSetId'
 *      filterBy: // value for 'filterBy'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useDocumentsCandidatesForSetQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlDocumentsCandidatesForSetQuery,
    OperationTypes.GqlDocumentsCandidatesForSetQueryVariables
  > &
    (
      | {
          variables: OperationTypes.GqlDocumentsCandidatesForSetQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlDocumentsCandidatesForSetQuery,
    OperationTypes.GqlDocumentsCandidatesForSetQueryVariables
  >(DocumentsCandidatesForSetDocument, options);
}
export function useDocumentsCandidatesForSetLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlDocumentsCandidatesForSetQuery,
    OperationTypes.GqlDocumentsCandidatesForSetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlDocumentsCandidatesForSetQuery,
    OperationTypes.GqlDocumentsCandidatesForSetQueryVariables
  >(DocumentsCandidatesForSetDocument, options);
}
export function useDocumentsCandidatesForSetSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlDocumentsCandidatesForSetQuery,
    OperationTypes.GqlDocumentsCandidatesForSetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlDocumentsCandidatesForSetQuery,
    OperationTypes.GqlDocumentsCandidatesForSetQueryVariables
  >(DocumentsCandidatesForSetDocument, options);
}
export type DocumentsCandidatesForSetQueryHookResult = ReturnType<
  typeof useDocumentsCandidatesForSetQuery
>;
export type DocumentsCandidatesForSetLazyQueryHookResult = ReturnType<
  typeof useDocumentsCandidatesForSetLazyQuery
>;
export type DocumentsCandidatesForSetSuspenseQueryHookResult = ReturnType<
  typeof useDocumentsCandidatesForSetSuspenseQuery
>;
export type DocumentsCandidatesForSetQueryResult = Apollo.QueryResult<
  OperationTypes.GqlDocumentsCandidatesForSetQuery,
  OperationTypes.GqlDocumentsCandidatesForSetQueryVariables
>;
export const LabelDocument = gql`
  query label($id: MongoID!) {
    label(id: $id) {
      ...labelBadgeFields
      createdAt
      updatedAt
      createdBy {
        ...mailableUser
      }
      updatedBy {
        ...mailableUser
      }
    }
  }
  ${LabelBadgeFieldsFragmentDoc}
  ${MailableUserFragmentDoc}
`;

/**
 * __useLabelQuery__
 *
 * To run a query within a React component, call `useLabelQuery` and pass it any options that fit your needs.
 * When your component renders, `useLabelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLabelQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLabelQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlLabelQuery,
    OperationTypes.GqlLabelQueryVariables
  > &
    (
      | { variables: OperationTypes.GqlLabelQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlLabelQuery,
    OperationTypes.GqlLabelQueryVariables
  >(LabelDocument, options);
}
export function useLabelLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlLabelQuery,
    OperationTypes.GqlLabelQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlLabelQuery,
    OperationTypes.GqlLabelQueryVariables
  >(LabelDocument, options);
}
export function useLabelSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlLabelQuery,
    OperationTypes.GqlLabelQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlLabelQuery,
    OperationTypes.GqlLabelQueryVariables
  >(LabelDocument, options);
}
export type LabelQueryHookResult = ReturnType<typeof useLabelQuery>;
export type LabelLazyQueryHookResult = ReturnType<typeof useLabelLazyQuery>;
export type LabelSuspenseQueryHookResult = ReturnType<
  typeof useLabelSuspenseQuery
>;
export type LabelQueryResult = Apollo.QueryResult<
  OperationTypes.GqlLabelQuery,
  OperationTypes.GqlLabelQueryVariables
>;
export const LabelsDocument = gql`
  query labels(
    $limit: Int
    $skip: Int
    $filterBy: LabelFiltersInput
    $sortBy: LabelSortInput
  ) {
    labels(
      limit: $limit
      skip: $skip
      filterBy: $filterBy
      sortBy: $sortBy
      facets: [docType, docFraudType, docCountry]
    ) {
      totalCount
      nodes {
        ...labelBadgeFields
        docCount
        createdAt
      }
      facets {
        name
        values {
          filter
          count
        }
      }
    }
  }
  ${LabelBadgeFieldsFragmentDoc}
`;

/**
 * __useLabelsQuery__
 *
 * To run a query within a React component, call `useLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLabelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLabelsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      filterBy: // value for 'filterBy'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useLabelsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlLabelsQuery,
    OperationTypes.GqlLabelsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlLabelsQuery,
    OperationTypes.GqlLabelsQueryVariables
  >(LabelsDocument, options);
}
export function useLabelsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlLabelsQuery,
    OperationTypes.GqlLabelsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlLabelsQuery,
    OperationTypes.GqlLabelsQueryVariables
  >(LabelsDocument, options);
}
export function useLabelsSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlLabelsQuery,
    OperationTypes.GqlLabelsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlLabelsQuery,
    OperationTypes.GqlLabelsQueryVariables
  >(LabelsDocument, options);
}
export type LabelsQueryHookResult = ReturnType<typeof useLabelsQuery>;
export type LabelsLazyQueryHookResult = ReturnType<typeof useLabelsLazyQuery>;
export type LabelsSuspenseQueryHookResult = ReturnType<
  typeof useLabelsSuspenseQuery
>;
export type LabelsQueryResult = Apollo.QueryResult<
  OperationTypes.GqlLabelsQuery,
  OperationTypes.GqlLabelsQueryVariables
>;
export const PadifAnalysisDocument = gql`
  query padifAnalysis($id: NumberID!) {
    padifAnalysis(id: $id) {
      id
      active
      createdAt
      updatedAt
      createdBy {
        ...mailableUser
      }
      updatedBy {
        ...mailableUser
      }
      documentSet {
        id
        name
        type
        docType
        docCountry
      }
      ...padifAnalysisShowMainReference
    }
  }
  ${MailableUserFragmentDoc}
  ${PadifAnalysisShowMainReferenceFragmentDoc}
`;

/**
 * __usePadifAnalysisQuery__
 *
 * To run a query within a React component, call `usePadifAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `usePadifAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePadifAnalysisQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePadifAnalysisQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlPadifAnalysisQuery,
    OperationTypes.GqlPadifAnalysisQueryVariables
  > &
    (
      | {
          variables: OperationTypes.GqlPadifAnalysisQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlPadifAnalysisQuery,
    OperationTypes.GqlPadifAnalysisQueryVariables
  >(PadifAnalysisDocument, options);
}
export function usePadifAnalysisLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlPadifAnalysisQuery,
    OperationTypes.GqlPadifAnalysisQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlPadifAnalysisQuery,
    OperationTypes.GqlPadifAnalysisQueryVariables
  >(PadifAnalysisDocument, options);
}
export function usePadifAnalysisSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlPadifAnalysisQuery,
    OperationTypes.GqlPadifAnalysisQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlPadifAnalysisQuery,
    OperationTypes.GqlPadifAnalysisQueryVariables
  >(PadifAnalysisDocument, options);
}
export type PadifAnalysisQueryHookResult = ReturnType<
  typeof usePadifAnalysisQuery
>;
export type PadifAnalysisLazyQueryHookResult = ReturnType<
  typeof usePadifAnalysisLazyQuery
>;
export type PadifAnalysisSuspenseQueryHookResult = ReturnType<
  typeof usePadifAnalysisSuspenseQuery
>;
export type PadifAnalysisQueryResult = Apollo.QueryResult<
  OperationTypes.GqlPadifAnalysisQuery,
  OperationTypes.GqlPadifAnalysisQueryVariables
>;
export const PadifAnalysesDocument = gql`
  query padifAnalyses(
    $limit: Int
    $skip: Int
    $filterBy: PadifAnalysisFiltersInput
    $sortBy: PadifAnalysisSortInput
  ) {
    padifAnalyses(
      limit: $limit
      skip: $skip
      filterBy: $filterBy
      sortBy: $sortBy
    ) {
      totalCount
      nodes {
        id
        active
        updatedAt
        documentSet {
          id
          name
          type
          docType
          docCountry
          documents {
            totalCount
          }
        }
        referenceDocument {
          ...documentIdentifierFields
          imageRecto(corrected: false) {
            ...documentViewImageIdentifiers
            view
            document {
              id
              url
            }
            documentThumb {
              id
              url
            }
          }
          imageVerso(corrected: false) {
            ...documentViewImageIdentifiers
            view
            document {
              id
              url
            }
            documentThumb {
              id
              url
            }
          }
        }
      }
    }
  }
  ${DocumentIdentifierFieldsFragmentDoc}
  ${DocumentViewImageIdentifiersFragmentDoc}
`;

/**
 * __usePadifAnalysesQuery__
 *
 * To run a query within a React component, call `usePadifAnalysesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePadifAnalysesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePadifAnalysesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      filterBy: // value for 'filterBy'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function usePadifAnalysesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlPadifAnalysesQuery,
    OperationTypes.GqlPadifAnalysesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlPadifAnalysesQuery,
    OperationTypes.GqlPadifAnalysesQueryVariables
  >(PadifAnalysesDocument, options);
}
export function usePadifAnalysesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlPadifAnalysesQuery,
    OperationTypes.GqlPadifAnalysesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlPadifAnalysesQuery,
    OperationTypes.GqlPadifAnalysesQueryVariables
  >(PadifAnalysesDocument, options);
}
export function usePadifAnalysesSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlPadifAnalysesQuery,
    OperationTypes.GqlPadifAnalysesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlPadifAnalysesQuery,
    OperationTypes.GqlPadifAnalysesQueryVariables
  >(PadifAnalysesDocument, options);
}
export type PadifAnalysesQueryHookResult = ReturnType<
  typeof usePadifAnalysesQuery
>;
export type PadifAnalysesLazyQueryHookResult = ReturnType<
  typeof usePadifAnalysesLazyQuery
>;
export type PadifAnalysesSuspenseQueryHookResult = ReturnType<
  typeof usePadifAnalysesSuspenseQuery
>;
export type PadifAnalysesQueryResult = Apollo.QueryResult<
  OperationTypes.GqlPadifAnalysesQuery,
  OperationTypes.GqlPadifAnalysesQueryVariables
>;
export const PadifDocumentSetDocument = gql`
  query padifDocumentSet($id: NumberID!, $skip: Int!, $limit: Int!) {
    padifAnalysis(id: $id) {
      id
      documentSet {
        id
        name
        docType
        docCountry
        documents(limit: $limit, skip: $skip) {
          totalCount
          nodes {
            id
            ...documentIdentifierFields
            imageRecto(corrected: false) {
              ...documentViewImageIdentifiers
              view
              documentThumb {
                id
                url
              }
            }
            imageVerso(corrected: false) {
              ...documentViewImageIdentifiers
              view
              documentThumb {
                id
                url
              }
            }
          }
        }
      }
    }
  }
  ${DocumentIdentifierFieldsFragmentDoc}
  ${DocumentViewImageIdentifiersFragmentDoc}
`;

/**
 * __usePadifDocumentSetQuery__
 *
 * To run a query within a React component, call `usePadifDocumentSetQuery` and pass it any options that fit your needs.
 * When your component renders, `usePadifDocumentSetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePadifDocumentSetQuery({
 *   variables: {
 *      id: // value for 'id'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function usePadifDocumentSetQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlPadifDocumentSetQuery,
    OperationTypes.GqlPadifDocumentSetQueryVariables
  > &
    (
      | {
          variables: OperationTypes.GqlPadifDocumentSetQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlPadifDocumentSetQuery,
    OperationTypes.GqlPadifDocumentSetQueryVariables
  >(PadifDocumentSetDocument, options);
}
export function usePadifDocumentSetLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlPadifDocumentSetQuery,
    OperationTypes.GqlPadifDocumentSetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlPadifDocumentSetQuery,
    OperationTypes.GqlPadifDocumentSetQueryVariables
  >(PadifDocumentSetDocument, options);
}
export function usePadifDocumentSetSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlPadifDocumentSetQuery,
    OperationTypes.GqlPadifDocumentSetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlPadifDocumentSetQuery,
    OperationTypes.GqlPadifDocumentSetQueryVariables
  >(PadifDocumentSetDocument, options);
}
export type PadifDocumentSetQueryHookResult = ReturnType<
  typeof usePadifDocumentSetQuery
>;
export type PadifDocumentSetLazyQueryHookResult = ReturnType<
  typeof usePadifDocumentSetLazyQuery
>;
export type PadifDocumentSetSuspenseQueryHookResult = ReturnType<
  typeof usePadifDocumentSetSuspenseQuery
>;
export type PadifDocumentSetQueryResult = Apollo.QueryResult<
  OperationTypes.GqlPadifDocumentSetQuery,
  OperationTypes.GqlPadifDocumentSetQueryVariables
>;
export const PadifAddRoiDocument = gql`
  mutation padifAddRoi($input: AddPadifRoiInput!) {
    addPadifRoi(input: $input) {
      id
      rois {
        id
        view
        x
        y
        width
        height
        color
      }
    }
  }
`;

/**
 * __usePadifAddRoiMutation__
 *
 * To run a mutation, you first call `usePadifAddRoiMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePadifAddRoiMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [padifAddRoiMutation, { data, loading, error }] = usePadifAddRoiMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePadifAddRoiMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlPadifAddRoiMutation,
    OperationTypes.GqlPadifAddRoiMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlPadifAddRoiMutation,
    OperationTypes.GqlPadifAddRoiMutationVariables
  >(PadifAddRoiDocument, options);
}
export type PadifAddRoiMutationHookResult = ReturnType<
  typeof usePadifAddRoiMutation
>;
export type PadifAddRoiMutationResult =
  Apollo.MutationResult<OperationTypes.GqlPadifAddRoiMutation>;
export type PadifAddRoiMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlPadifAddRoiMutation,
  OperationTypes.GqlPadifAddRoiMutationVariables
>;
export const PadifRemoveRoiDocument = gql`
  mutation padifRemoveRoi($input: RemovePadifRoiInput!) {
    removePadifRoi(input: $input) {
      id
      rois {
        id
        view
        x
        y
        width
        height
        color
      }
    }
  }
`;

/**
 * __usePadifRemoveRoiMutation__
 *
 * To run a mutation, you first call `usePadifRemoveRoiMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePadifRemoveRoiMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [padifRemoveRoiMutation, { data, loading, error }] = usePadifRemoveRoiMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePadifRemoveRoiMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlPadifRemoveRoiMutation,
    OperationTypes.GqlPadifRemoveRoiMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlPadifRemoveRoiMutation,
    OperationTypes.GqlPadifRemoveRoiMutationVariables
  >(PadifRemoveRoiDocument, options);
}
export type PadifRemoveRoiMutationHookResult = ReturnType<
  typeof usePadifRemoveRoiMutation
>;
export type PadifRemoveRoiMutationResult =
  Apollo.MutationResult<OperationTypes.GqlPadifRemoveRoiMutation>;
export type PadifRemoveRoiMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlPadifRemoveRoiMutation,
  OperationTypes.GqlPadifRemoveRoiMutationVariables
>;
export const PadifSetReferenceDocument = gql`
  mutation padifSetReference($input: SetPadifReferenceDocumentInput!) {
    setPadifReferenceDocument(input: $input) {
      id
      rois {
        id
        view
        x
        y
        width
        height
        color
      }
      referenceDocument {
        id
        imageRecto(corrected: false) {
          ...documentViewImageIdentifiers
          view
          document {
            id
            url
          }
        }
        imageVerso(corrected: false) {
          ...documentViewImageIdentifiers
          view
          document {
            id
            url
          }
        }
      }
    }
  }
  ${DocumentViewImageIdentifiersFragmentDoc}
`;

/**
 * __usePadifSetReferenceMutation__
 *
 * To run a mutation, you first call `usePadifSetReferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePadifSetReferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [padifSetReferenceMutation, { data, loading, error }] = usePadifSetReferenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePadifSetReferenceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlPadifSetReferenceMutation,
    OperationTypes.GqlPadifSetReferenceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlPadifSetReferenceMutation,
    OperationTypes.GqlPadifSetReferenceMutationVariables
  >(PadifSetReferenceDocument, options);
}
export type PadifSetReferenceMutationHookResult = ReturnType<
  typeof usePadifSetReferenceMutation
>;
export type PadifSetReferenceMutationResult =
  Apollo.MutationResult<OperationTypes.GqlPadifSetReferenceMutation>;
export type PadifSetReferenceMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlPadifSetReferenceMutation,
  OperationTypes.GqlPadifSetReferenceMutationVariables
>;
export const PadifChangeStatusDocument = gql`
  mutation padifChangeStatus($input: ChangePadifAnalysisStatusInput!) {
    changePadifAnalysisStatus(input: $input) {
      id
      active
    }
  }
`;

/**
 * __usePadifChangeStatusMutation__
 *
 * To run a mutation, you first call `usePadifChangeStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePadifChangeStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [padifChangeStatusMutation, { data, loading, error }] = usePadifChangeStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePadifChangeStatusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OperationTypes.GqlPadifChangeStatusMutation,
    OperationTypes.GqlPadifChangeStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OperationTypes.GqlPadifChangeStatusMutation,
    OperationTypes.GqlPadifChangeStatusMutationVariables
  >(PadifChangeStatusDocument, options);
}
export type PadifChangeStatusMutationHookResult = ReturnType<
  typeof usePadifChangeStatusMutation
>;
export type PadifChangeStatusMutationResult =
  Apollo.MutationResult<OperationTypes.GqlPadifChangeStatusMutation>;
export type PadifChangeStatusMutationOptions = Apollo.BaseMutationOptions<
  OperationTypes.GqlPadifChangeStatusMutation,
  OperationTypes.GqlPadifChangeStatusMutationVariables
>;
export const PadifAnalysesFromSetDocument = gql`
  query padifAnalysesFromSet($filterBy: PadifAnalysisFiltersInput) {
    padifAnalyses(filterBy: $filterBy) {
      totalCount
    }
  }
`;

/**
 * __usePadifAnalysesFromSetQuery__
 *
 * To run a query within a React component, call `usePadifAnalysesFromSetQuery` and pass it any options that fit your needs.
 * When your component renders, `usePadifAnalysesFromSetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePadifAnalysesFromSetQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function usePadifAnalysesFromSetQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlPadifAnalysesFromSetQuery,
    OperationTypes.GqlPadifAnalysesFromSetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlPadifAnalysesFromSetQuery,
    OperationTypes.GqlPadifAnalysesFromSetQueryVariables
  >(PadifAnalysesFromSetDocument, options);
}
export function usePadifAnalysesFromSetLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlPadifAnalysesFromSetQuery,
    OperationTypes.GqlPadifAnalysesFromSetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlPadifAnalysesFromSetQuery,
    OperationTypes.GqlPadifAnalysesFromSetQueryVariables
  >(PadifAnalysesFromSetDocument, options);
}
export function usePadifAnalysesFromSetSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlPadifAnalysesFromSetQuery,
    OperationTypes.GqlPadifAnalysesFromSetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlPadifAnalysesFromSetQuery,
    OperationTypes.GqlPadifAnalysesFromSetQueryVariables
  >(PadifAnalysesFromSetDocument, options);
}
export type PadifAnalysesFromSetQueryHookResult = ReturnType<
  typeof usePadifAnalysesFromSetQuery
>;
export type PadifAnalysesFromSetLazyQueryHookResult = ReturnType<
  typeof usePadifAnalysesFromSetLazyQuery
>;
export type PadifAnalysesFromSetSuspenseQueryHookResult = ReturnType<
  typeof usePadifAnalysesFromSetSuspenseQuery
>;
export type PadifAnalysesFromSetQueryResult = Apollo.QueryResult<
  OperationTypes.GqlPadifAnalysesFromSetQuery,
  OperationTypes.GqlPadifAnalysesFromSetQueryVariables
>;
export const PadifAnalysisResultDocument = gql`
  query padifAnalysisResult($id: NumberID!) {
    padifAnalysis(id: $id) {
      id
      referenceDocument {
        id
        imageRecto {
          ...documentViewImageIdentifiers
          view
          document {
            id
            path
          }
        }
        imageVerso {
          ...documentViewImageIdentifiers
          view
          document {
            id
            path
          }
        }
      }
      rois {
        id
        color
        view
        height
        width
        x
        y
      }
      result {
        finishedAt
        distanceMetric
        distanceCombinationMethod
        documents {
          ...documentIdentifierFields
          series {
            ...serieIdentifierFields
          }
        }
        distanceMatrix
        errors
      }
      resultJs {
        finishedAt
        documents {
          ...documentIdentifierFields
          series {
            ...serieIdentifierFields
          }
        }
        distances {
          roi
          value
        }
        errors
      }
    }
  }
  ${DocumentViewImageIdentifiersFragmentDoc}
  ${DocumentIdentifierFieldsFragmentDoc}
  ${SerieIdentifierFieldsFragmentDoc}
`;

/**
 * __usePadifAnalysisResultQuery__
 *
 * To run a query within a React component, call `usePadifAnalysisResultQuery` and pass it any options that fit your needs.
 * When your component renders, `usePadifAnalysisResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePadifAnalysisResultQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePadifAnalysisResultQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlPadifAnalysisResultQuery,
    OperationTypes.GqlPadifAnalysisResultQueryVariables
  > &
    (
      | {
          variables: OperationTypes.GqlPadifAnalysisResultQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlPadifAnalysisResultQuery,
    OperationTypes.GqlPadifAnalysisResultQueryVariables
  >(PadifAnalysisResultDocument, options);
}
export function usePadifAnalysisResultLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlPadifAnalysisResultQuery,
    OperationTypes.GqlPadifAnalysisResultQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlPadifAnalysisResultQuery,
    OperationTypes.GqlPadifAnalysisResultQueryVariables
  >(PadifAnalysisResultDocument, options);
}
export function usePadifAnalysisResultSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlPadifAnalysisResultQuery,
    OperationTypes.GqlPadifAnalysisResultQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlPadifAnalysisResultQuery,
    OperationTypes.GqlPadifAnalysisResultQueryVariables
  >(PadifAnalysisResultDocument, options);
}
export type PadifAnalysisResultQueryHookResult = ReturnType<
  typeof usePadifAnalysisResultQuery
>;
export type PadifAnalysisResultLazyQueryHookResult = ReturnType<
  typeof usePadifAnalysisResultLazyQuery
>;
export type PadifAnalysisResultSuspenseQueryHookResult = ReturnType<
  typeof usePadifAnalysisResultSuspenseQuery
>;
export type PadifAnalysisResultQueryResult = Apollo.QueryResult<
  OperationTypes.GqlPadifAnalysisResultQuery,
  OperationTypes.GqlPadifAnalysisResultQueryVariables
>;
export const ScanDocument = gql`
  query scan($id: MongoID!) {
    scan(id: $id) {
      id
      error
      status
      updatedAt
      createdAt
      createdBy {
        ...mailableUser
      }
      updatedBy {
        ...mailableUser
      }
      image {
        id
        filename
        size
        url
      }
      filename
      document {
        ...documentIdentifierFields
        images {
          ...documentImageIdentifiers
          originalScan {
            id
          }
        }
        ...documentMRZFields
      }
      importData {
        __typename
        ...importDataFields
        ...mrzFields
        ... on ProcessExtract {
          extractedDocument {
            ...analyzedDocument
          }
        }
        ... on ProcessRotate {
          extractedDocument {
            ...extractedDocument
          }
        }
        ... on ProcessMRZ {
          extractedDocument {
            ...extractedDocument
          }
          rotatedDocument {
            ...rotatedDocument
          }
        }
        ... on ProcessFindRegions {
          extractedDocument {
            ...extractedDocument
          }
          rotatedDocument {
            ...rotatedDocument
          }
        }
        ... on ProcessReview {
          ...processReviewData
        }
        ... on ProcessFinalize {
          extractedDocument {
            ...extractedDocument
          }
          rotatedDocument {
            ...rotatedDocument
          }
          anonymization {
            rois {
              id
              type
              height
              width
              x
              y
            }
          }
        }
      }
    }
  }
  ${MailableUserFragmentDoc}
  ${DocumentIdentifierFieldsFragmentDoc}
  ${DocumentImageIdentifiersFragmentDoc}
  ${DocumentMrzFieldsFragmentDoc}
  ${ImportDataFieldsFragmentDoc}
  ${MrzFieldsFragmentDoc}
  ${AnalyzedDocumentFragmentDoc}
  ${ExtractedDocumentFragmentDoc}
  ${RotatedDocumentFragmentDoc}
  ${ProcessReviewDataFragmentDoc}
`;

/**
 * __useScanQuery__
 *
 * To run a query within a React component, call `useScanQuery` and pass it any options that fit your needs.
 * When your component renders, `useScanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScanQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useScanQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlScanQuery,
    OperationTypes.GqlScanQueryVariables
  > &
    (
      | { variables: OperationTypes.GqlScanQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlScanQuery,
    OperationTypes.GqlScanQueryVariables
  >(ScanDocument, options);
}
export function useScanLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlScanQuery,
    OperationTypes.GqlScanQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlScanQuery,
    OperationTypes.GqlScanQueryVariables
  >(ScanDocument, options);
}
export function useScanSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlScanQuery,
    OperationTypes.GqlScanQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlScanQuery,
    OperationTypes.GqlScanQueryVariables
  >(ScanDocument, options);
}
export type ScanQueryHookResult = ReturnType<typeof useScanQuery>;
export type ScanLazyQueryHookResult = ReturnType<typeof useScanLazyQuery>;
export type ScanSuspenseQueryHookResult = ReturnType<
  typeof useScanSuspenseQuery
>;
export type ScanQueryResult = Apollo.QueryResult<
  OperationTypes.GqlScanQuery,
  OperationTypes.GqlScanQueryVariables
>;
export const ScansDocument = gql`
  query scans(
    $filterBy: ScanFiltersInput
    $sortBy: ScanSortInput
    $limit: Int
    $skip: Int
  ) {
    scans(filterBy: $filterBy, sortBy: $sortBy, limit: $limit, skip: $skip) {
      nodes {
        id
        createdAt
        createdBy {
          id
          email
          name
        }
        status
        image {
          id
          url
        }
        filename
        document {
          ...documentIdentifierFields
        }
        error
      }
      totalCount
    }
  }
  ${DocumentIdentifierFieldsFragmentDoc}
`;

/**
 * __useScansQuery__
 *
 * To run a query within a React component, call `useScansQuery` and pass it any options that fit your needs.
 * When your component renders, `useScansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScansQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      sortBy: // value for 'sortBy'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useScansQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlScansQuery,
    OperationTypes.GqlScansQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlScansQuery,
    OperationTypes.GqlScansQueryVariables
  >(ScansDocument, options);
}
export function useScansLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlScansQuery,
    OperationTypes.GqlScansQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlScansQuery,
    OperationTypes.GqlScansQueryVariables
  >(ScansDocument, options);
}
export function useScansSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlScansQuery,
    OperationTypes.GqlScansQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlScansQuery,
    OperationTypes.GqlScansQueryVariables
  >(ScansDocument, options);
}
export type ScansQueryHookResult = ReturnType<typeof useScansQuery>;
export type ScansLazyQueryHookResult = ReturnType<typeof useScansLazyQuery>;
export type ScansSuspenseQueryHookResult = ReturnType<
  typeof useScansSuspenseQuery
>;
export type ScansQueryResult = Apollo.QueryResult<
  OperationTypes.GqlScansQuery,
  OperationTypes.GqlScansQueryVariables
>;
export const SeizureAuthoritiesDocument = gql`
  query seizureAuthorities {
    seizureAuthorities {
      ...seizureAuthorityFields
    }
  }
  ${SeizureAuthorityFieldsFragmentDoc}
`;

/**
 * __useSeizureAuthoritiesQuery__
 *
 * To run a query within a React component, call `useSeizureAuthoritiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeizureAuthoritiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeizureAuthoritiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSeizureAuthoritiesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlSeizureAuthoritiesQuery,
    OperationTypes.GqlSeizureAuthoritiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlSeizureAuthoritiesQuery,
    OperationTypes.GqlSeizureAuthoritiesQueryVariables
  >(SeizureAuthoritiesDocument, options);
}
export function useSeizureAuthoritiesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlSeizureAuthoritiesQuery,
    OperationTypes.GqlSeizureAuthoritiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlSeizureAuthoritiesQuery,
    OperationTypes.GqlSeizureAuthoritiesQueryVariables
  >(SeizureAuthoritiesDocument, options);
}
export function useSeizureAuthoritiesSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlSeizureAuthoritiesQuery,
    OperationTypes.GqlSeizureAuthoritiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlSeizureAuthoritiesQuery,
    OperationTypes.GqlSeizureAuthoritiesQueryVariables
  >(SeizureAuthoritiesDocument, options);
}
export type SeizureAuthoritiesQueryHookResult = ReturnType<
  typeof useSeizureAuthoritiesQuery
>;
export type SeizureAuthoritiesLazyQueryHookResult = ReturnType<
  typeof useSeizureAuthoritiesLazyQuery
>;
export type SeizureAuthoritiesSuspenseQueryHookResult = ReturnType<
  typeof useSeizureAuthoritiesSuspenseQuery
>;
export type SeizureAuthoritiesQueryResult = Apollo.QueryResult<
  OperationTypes.GqlSeizureAuthoritiesQuery,
  OperationTypes.GqlSeizureAuthoritiesQueryVariables
>;
export const SeizureAuthoritiesListDocument = gql`
  query seizureAuthoritiesList {
    seizureAuthorities {
      id
      identifier
      nameEn: name(locale: en)
      hasTranslationEn: hasTranslation(locale: en)
      nameFr: name(locale: fr)
      hasTranslationFr: hasTranslation(locale: fr)
      nameDe: name(locale: de)
      hasTranslationDe: hasTranslation(locale: de)
      nameNl: name(locale: nl)
      hasTranslationNl: hasTranslation(locale: nl)
    }
  }
`;

/**
 * __useSeizureAuthoritiesListQuery__
 *
 * To run a query within a React component, call `useSeizureAuthoritiesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeizureAuthoritiesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeizureAuthoritiesListQuery({
 *   variables: {
 *   },
 * });
 */
export function useSeizureAuthoritiesListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlSeizureAuthoritiesListQuery,
    OperationTypes.GqlSeizureAuthoritiesListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlSeizureAuthoritiesListQuery,
    OperationTypes.GqlSeizureAuthoritiesListQueryVariables
  >(SeizureAuthoritiesListDocument, options);
}
export function useSeizureAuthoritiesListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlSeizureAuthoritiesListQuery,
    OperationTypes.GqlSeizureAuthoritiesListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlSeizureAuthoritiesListQuery,
    OperationTypes.GqlSeizureAuthoritiesListQueryVariables
  >(SeizureAuthoritiesListDocument, options);
}
export function useSeizureAuthoritiesListSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlSeizureAuthoritiesListQuery,
    OperationTypes.GqlSeizureAuthoritiesListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlSeizureAuthoritiesListQuery,
    OperationTypes.GqlSeizureAuthoritiesListQueryVariables
  >(SeizureAuthoritiesListDocument, options);
}
export type SeizureAuthoritiesListQueryHookResult = ReturnType<
  typeof useSeizureAuthoritiesListQuery
>;
export type SeizureAuthoritiesListLazyQueryHookResult = ReturnType<
  typeof useSeizureAuthoritiesListLazyQuery
>;
export type SeizureAuthoritiesListSuspenseQueryHookResult = ReturnType<
  typeof useSeizureAuthoritiesListSuspenseQuery
>;
export type SeizureAuthoritiesListQueryResult = Apollo.QueryResult<
  OperationTypes.GqlSeizureAuthoritiesListQuery,
  OperationTypes.GqlSeizureAuthoritiesListQueryVariables
>;
export const SerieDocument = gql`
  query Serie($seqId: String!) {
    serie: serieBySeqId(seqId: $seqId) {
      ...serieIdentifierFields
      aliases
      createdAt
      createdBy {
        ...mailableUser
      }
      updatedAt
      updatedBy {
        ...mailableUser
      }
      firstSeizureDate
      lastSeizureDate
      seizureSpan
      documents(limit: 10) {
        nodes {
          ...documentIdentifierFields
          seriesUpdatedAt
        }
        totalCount
      }
      images {
        id
        url
        filename
      }
      attachments {
        id
        filename
        size
        url
      }
      currentAlert {
        id
        status
        draft {
          id
          languages
          translation {
            ...alertTranslationTitle
          }
        }
        currentPublication {
          id
          publishedAt
          languages
          translation {
            ...alertTranslationTitle
          }
        }
      }
      ...updateableSerieFields
    }
  }
  ${SerieIdentifierFieldsFragmentDoc}
  ${MailableUserFragmentDoc}
  ${DocumentIdentifierFieldsFragmentDoc}
  ${AlertTranslationTitleFragmentDoc}
  ${UpdateableSerieFieldsFragmentDoc}
`;

/**
 * __useSerieQuery__
 *
 * To run a query within a React component, call `useSerieQuery` and pass it any options that fit your needs.
 * When your component renders, `useSerieQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSerieQuery({
 *   variables: {
 *      seqId: // value for 'seqId'
 *   },
 * });
 */
export function useSerieQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlSerieQuery,
    OperationTypes.GqlSerieQueryVariables
  > &
    (
      | { variables: OperationTypes.GqlSerieQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlSerieQuery,
    OperationTypes.GqlSerieQueryVariables
  >(SerieDocument, options);
}
export function useSerieLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlSerieQuery,
    OperationTypes.GqlSerieQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlSerieQuery,
    OperationTypes.GqlSerieQueryVariables
  >(SerieDocument, options);
}
export function useSerieSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlSerieQuery,
    OperationTypes.GqlSerieQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlSerieQuery,
    OperationTypes.GqlSerieQueryVariables
  >(SerieDocument, options);
}
export type SerieQueryHookResult = ReturnType<typeof useSerieQuery>;
export type SerieLazyQueryHookResult = ReturnType<typeof useSerieLazyQuery>;
export type SerieSuspenseQueryHookResult = ReturnType<
  typeof useSerieSuspenseQuery
>;
export type SerieQueryResult = Apollo.QueryResult<
  OperationTypes.GqlSerieQuery,
  OperationTypes.GqlSerieQueryVariables
>;
export const SerieEditableDocument = gql`
  query serieEditable($seqId: String!) {
    serie: serieBySeqId(seqId: $seqId) {
      ...serieIdentifierFields
      ...updateableSerieFields
    }
  }
  ${SerieIdentifierFieldsFragmentDoc}
  ${UpdateableSerieFieldsFragmentDoc}
`;

/**
 * __useSerieEditableQuery__
 *
 * To run a query within a React component, call `useSerieEditableQuery` and pass it any options that fit your needs.
 * When your component renders, `useSerieEditableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSerieEditableQuery({
 *   variables: {
 *      seqId: // value for 'seqId'
 *   },
 * });
 */
export function useSerieEditableQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlSerieEditableQuery,
    OperationTypes.GqlSerieEditableQueryVariables
  > &
    (
      | {
          variables: OperationTypes.GqlSerieEditableQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlSerieEditableQuery,
    OperationTypes.GqlSerieEditableQueryVariables
  >(SerieEditableDocument, options);
}
export function useSerieEditableLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlSerieEditableQuery,
    OperationTypes.GqlSerieEditableQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlSerieEditableQuery,
    OperationTypes.GqlSerieEditableQueryVariables
  >(SerieEditableDocument, options);
}
export function useSerieEditableSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlSerieEditableQuery,
    OperationTypes.GqlSerieEditableQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlSerieEditableQuery,
    OperationTypes.GqlSerieEditableQueryVariables
  >(SerieEditableDocument, options);
}
export type SerieEditableQueryHookResult = ReturnType<
  typeof useSerieEditableQuery
>;
export type SerieEditableLazyQueryHookResult = ReturnType<
  typeof useSerieEditableLazyQuery
>;
export type SerieEditableSuspenseQueryHookResult = ReturnType<
  typeof useSerieEditableSuspenseQuery
>;
export type SerieEditableQueryResult = Apollo.QueryResult<
  OperationTypes.GqlSerieEditableQuery,
  OperationTypes.GqlSerieEditableQueryVariables
>;
export const SerieFilesDocument = gql`
  query serieFiles($seqId: String!) {
    serie: serieBySeqId(seqId: $seqId) {
      ...serieIdentifierFields
      images {
        id
        url
        filename
      }
      attachments {
        id
        filename
        size
        url
      }
    }
  }
  ${SerieIdentifierFieldsFragmentDoc}
`;

/**
 * __useSerieFilesQuery__
 *
 * To run a query within a React component, call `useSerieFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSerieFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSerieFilesQuery({
 *   variables: {
 *      seqId: // value for 'seqId'
 *   },
 * });
 */
export function useSerieFilesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlSerieFilesQuery,
    OperationTypes.GqlSerieFilesQueryVariables
  > &
    (
      | {
          variables: OperationTypes.GqlSerieFilesQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlSerieFilesQuery,
    OperationTypes.GqlSerieFilesQueryVariables
  >(SerieFilesDocument, options);
}
export function useSerieFilesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlSerieFilesQuery,
    OperationTypes.GqlSerieFilesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlSerieFilesQuery,
    OperationTypes.GqlSerieFilesQueryVariables
  >(SerieFilesDocument, options);
}
export function useSerieFilesSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlSerieFilesQuery,
    OperationTypes.GqlSerieFilesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlSerieFilesQuery,
    OperationTypes.GqlSerieFilesQueryVariables
  >(SerieFilesDocument, options);
}
export type SerieFilesQueryHookResult = ReturnType<typeof useSerieFilesQuery>;
export type SerieFilesLazyQueryHookResult = ReturnType<
  typeof useSerieFilesLazyQuery
>;
export type SerieFilesSuspenseQueryHookResult = ReturnType<
  typeof useSerieFilesSuspenseQuery
>;
export type SerieFilesQueryResult = Apollo.QueryResult<
  OperationTypes.GqlSerieFilesQuery,
  OperationTypes.GqlSerieFilesQueryVariables
>;
export const SerieIdDocument = gql`
  query serieId($seqId: String!) {
    serie: serieBySeqId(seqId: $seqId) {
      ...serieIdentifierFields
    }
  }
  ${SerieIdentifierFieldsFragmentDoc}
`;

/**
 * __useSerieIdQuery__
 *
 * To run a query within a React component, call `useSerieIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSerieIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSerieIdQuery({
 *   variables: {
 *      seqId: // value for 'seqId'
 *   },
 * });
 */
export function useSerieIdQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlSerieIdQuery,
    OperationTypes.GqlSerieIdQueryVariables
  > &
    (
      | { variables: OperationTypes.GqlSerieIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlSerieIdQuery,
    OperationTypes.GqlSerieIdQueryVariables
  >(SerieIdDocument, options);
}
export function useSerieIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlSerieIdQuery,
    OperationTypes.GqlSerieIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlSerieIdQuery,
    OperationTypes.GqlSerieIdQueryVariables
  >(SerieIdDocument, options);
}
export function useSerieIdSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlSerieIdQuery,
    OperationTypes.GqlSerieIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlSerieIdQuery,
    OperationTypes.GqlSerieIdQueryVariables
  >(SerieIdDocument, options);
}
export type SerieIdQueryHookResult = ReturnType<typeof useSerieIdQuery>;
export type SerieIdLazyQueryHookResult = ReturnType<typeof useSerieIdLazyQuery>;
export type SerieIdSuspenseQueryHookResult = ReturnType<
  typeof useSerieIdSuspenseQuery
>;
export type SerieIdQueryResult = Apollo.QueryResult<
  OperationTypes.GqlSerieIdQuery,
  OperationTypes.GqlSerieIdQueryVariables
>;
export const SerieNamesDocument = gql`
  query serieNames {
    series {
      totalCount
      nodes {
        ...serieIdentifierFields
        aliases
      }
    }
  }
  ${SerieIdentifierFieldsFragmentDoc}
`;

/**
 * __useSerieNamesQuery__
 *
 * To run a query within a React component, call `useSerieNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSerieNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSerieNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSerieNamesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlSerieNamesQuery,
    OperationTypes.GqlSerieNamesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlSerieNamesQuery,
    OperationTypes.GqlSerieNamesQueryVariables
  >(SerieNamesDocument, options);
}
export function useSerieNamesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlSerieNamesQuery,
    OperationTypes.GqlSerieNamesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlSerieNamesQuery,
    OperationTypes.GqlSerieNamesQueryVariables
  >(SerieNamesDocument, options);
}
export function useSerieNamesSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlSerieNamesQuery,
    OperationTypes.GqlSerieNamesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlSerieNamesQuery,
    OperationTypes.GqlSerieNamesQueryVariables
  >(SerieNamesDocument, options);
}
export type SerieNamesQueryHookResult = ReturnType<typeof useSerieNamesQuery>;
export type SerieNamesLazyQueryHookResult = ReturnType<
  typeof useSerieNamesLazyQuery
>;
export type SerieNamesSuspenseQueryHookResult = ReturnType<
  typeof useSerieNamesSuspenseQuery
>;
export type SerieNamesQueryResult = Apollo.QueryResult<
  OperationTypes.GqlSerieNamesQuery,
  OperationTypes.GqlSerieNamesQueryVariables
>;
export const SeriesDocument = gql`
  query Series(
    $skip: Int
    $limit: Int
    $filterBy: SerieFiltersInput
    $sortBy: SerieSortInput
  ) {
    series(
      skip: $skip
      limit: $limit
      filterBy: $filterBy
      sortBy: $sortBy
      facets: [docType, docFraudType, docCountry]
    ) {
      nodes {
        ...serieIdentifierFields
        aliases
        createdAt
        importantFieldsUpdatedAt
        docType
        docCountry
        docFraudType
        documents {
          totalCount
        }
        currentAlert {
          id
          status
          draft {
            id
            languages
            translation {
              ...alertTranslationTitle
            }
          }
          currentPublication {
            id
            publishedAt
            languages
            translation {
              ...alertTranslationTitle
            }
          }
        }
      }
      totalCount
      facets {
        name
        values {
          filter
          count
        }
      }
    }
  }
  ${SerieIdentifierFieldsFragmentDoc}
  ${AlertTranslationTitleFragmentDoc}
`;

/**
 * __useSeriesQuery__
 *
 * To run a query within a React component, call `useSeriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeriesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      filterBy: // value for 'filterBy'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useSeriesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlSeriesQuery,
    OperationTypes.GqlSeriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlSeriesQuery,
    OperationTypes.GqlSeriesQueryVariables
  >(SeriesDocument, options);
}
export function useSeriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlSeriesQuery,
    OperationTypes.GqlSeriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlSeriesQuery,
    OperationTypes.GqlSeriesQueryVariables
  >(SeriesDocument, options);
}
export function useSeriesSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlSeriesQuery,
    OperationTypes.GqlSeriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlSeriesQuery,
    OperationTypes.GqlSeriesQueryVariables
  >(SeriesDocument, options);
}
export type SeriesQueryHookResult = ReturnType<typeof useSeriesQuery>;
export type SeriesLazyQueryHookResult = ReturnType<typeof useSeriesLazyQuery>;
export type SeriesSuspenseQueryHookResult = ReturnType<
  typeof useSeriesSuspenseQuery
>;
export type SeriesQueryResult = Apollo.QueryResult<
  OperationTypes.GqlSeriesQuery,
  OperationTypes.GqlSeriesQueryVariables
>;
export const SeriesSelectDocument = gql`
  query SeriesSelect($limit: Int, $searchTerm: String) {
    series(limit: $limit, filterBy: { searchTerm: $searchTerm }) {
      nodes {
        ...serieIdentifierFields
      }
      totalCount
    }
  }
  ${SerieIdentifierFieldsFragmentDoc}
`;

/**
 * __useSeriesSelectQuery__
 *
 * To run a query within a React component, call `useSeriesSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeriesSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeriesSelectQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useSeriesSelectQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlSeriesSelectQuery,
    OperationTypes.GqlSeriesSelectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlSeriesSelectQuery,
    OperationTypes.GqlSeriesSelectQueryVariables
  >(SeriesSelectDocument, options);
}
export function useSeriesSelectLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlSeriesSelectQuery,
    OperationTypes.GqlSeriesSelectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlSeriesSelectQuery,
    OperationTypes.GqlSeriesSelectQueryVariables
  >(SeriesSelectDocument, options);
}
export function useSeriesSelectSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlSeriesSelectQuery,
    OperationTypes.GqlSeriesSelectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlSeriesSelectQuery,
    OperationTypes.GqlSeriesSelectQueryVariables
  >(SeriesSelectDocument, options);
}
export type SeriesSelectQueryHookResult = ReturnType<
  typeof useSeriesSelectQuery
>;
export type SeriesSelectLazyQueryHookResult = ReturnType<
  typeof useSeriesSelectLazyQuery
>;
export type SeriesSelectSuspenseQueryHookResult = ReturnType<
  typeof useSeriesSelectSuspenseQuery
>;
export type SeriesSelectQueryResult = Apollo.QueryResult<
  OperationTypes.GqlSeriesSelectQuery,
  OperationTypes.GqlSeriesSelectQueryVariables
>;
export const ServicesDocument = gql`
  query Services($skip: Int, $limit: Int, $sortBy: ServicesSortBy) {
    services(skip: $skip, limit: $limit, sortBy: $sortBy) {
      totalCount
      nodes {
        ...serviceFields
      }
    }
  }
  ${ServiceFieldsFragmentDoc}
`;

/**
 * __useServicesQuery__
 *
 * To run a query within a React component, call `useServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServicesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useServicesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlServicesQuery,
    OperationTypes.GqlServicesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlServicesQuery,
    OperationTypes.GqlServicesQueryVariables
  >(ServicesDocument, options);
}
export function useServicesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlServicesQuery,
    OperationTypes.GqlServicesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlServicesQuery,
    OperationTypes.GqlServicesQueryVariables
  >(ServicesDocument, options);
}
export function useServicesSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlServicesQuery,
    OperationTypes.GqlServicesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlServicesQuery,
    OperationTypes.GqlServicesQueryVariables
  >(ServicesDocument, options);
}
export type ServicesQueryHookResult = ReturnType<typeof useServicesQuery>;
export type ServicesLazyQueryHookResult = ReturnType<
  typeof useServicesLazyQuery
>;
export type ServicesSuspenseQueryHookResult = ReturnType<
  typeof useServicesSuspenseQuery
>;
export type ServicesQueryResult = Apollo.QueryResult<
  OperationTypes.GqlServicesQuery,
  OperationTypes.GqlServicesQueryVariables
>;
export const ServicesListDocument = gql`
  query servicesList($skip: Int, $limit: Int, $sortBy: ServicesSortBy) {
    services(skip: $skip, limit: $limit, sortBy: $sortBy) {
      totalCount
      nodes {
        id
        identifier
        nameEn: name(locale: en)
        hasTranslationEn: hasTranslation(locale: en)
        nameFr: name(locale: fr)
        hasTranslationFr: hasTranslation(locale: fr)
        nameDe: name(locale: de)
        hasTranslationDe: hasTranslation(locale: de)
        nameNl: name(locale: nl)
        hasTranslationNl: hasTranslation(locale: nl)
      }
    }
  }
`;

/**
 * __useServicesListQuery__
 *
 * To run a query within a React component, call `useServicesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useServicesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServicesListQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useServicesListQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlServicesListQuery,
    OperationTypes.GqlServicesListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlServicesListQuery,
    OperationTypes.GqlServicesListQueryVariables
  >(ServicesListDocument, options);
}
export function useServicesListLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlServicesListQuery,
    OperationTypes.GqlServicesListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlServicesListQuery,
    OperationTypes.GqlServicesListQueryVariables
  >(ServicesListDocument, options);
}
export function useServicesListSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlServicesListQuery,
    OperationTypes.GqlServicesListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlServicesListQuery,
    OperationTypes.GqlServicesListQueryVariables
  >(ServicesListDocument, options);
}
export type ServicesListQueryHookResult = ReturnType<
  typeof useServicesListQuery
>;
export type ServicesListLazyQueryHookResult = ReturnType<
  typeof useServicesListLazyQuery
>;
export type ServicesListSuspenseQueryHookResult = ReturnType<
  typeof useServicesListSuspenseQuery
>;
export type ServicesListQueryResult = Apollo.QueryResult<
  OperationTypes.GqlServicesListQuery,
  OperationTypes.GqlServicesListQueryVariables
>;
export const SessionDocument = gql`
  query Session {
    me {
      id
      email
      name
      service {
        id
        identifier
      }
      roles
      permissions
    }
  }
`;

/**
 * __useSessionQuery__
 *
 * To run a query within a React component, call `useSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionQuery({
 *   variables: {
 *   },
 * });
 */
export function useSessionQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlSessionQuery,
    OperationTypes.GqlSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlSessionQuery,
    OperationTypes.GqlSessionQueryVariables
  >(SessionDocument, options);
}
export function useSessionLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlSessionQuery,
    OperationTypes.GqlSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlSessionQuery,
    OperationTypes.GqlSessionQueryVariables
  >(SessionDocument, options);
}
export function useSessionSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlSessionQuery,
    OperationTypes.GqlSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlSessionQuery,
    OperationTypes.GqlSessionQueryVariables
  >(SessionDocument, options);
}
export type SessionQueryHookResult = ReturnType<typeof useSessionQuery>;
export type SessionLazyQueryHookResult = ReturnType<typeof useSessionLazyQuery>;
export type SessionSuspenseQueryHookResult = ReturnType<
  typeof useSessionSuspenseQuery
>;
export type SessionQueryResult = Apollo.QueryResult<
  OperationTypes.GqlSessionQuery,
  OperationTypes.GqlSessionQueryVariables
>;
export const SingleDocumentDocument = gql`
  query singleDocument(
    $skip: Int!
    $filterBy: DocumentFiltersInput!
    $sortBy: DocumentSortInput!
  ) {
    documents(skip: $skip, limit: 3, filterBy: $filterBy, sortBy: $sortBy) {
      nodes {
        ...detailedDocumentFields
      }
      totalCount
    }
  }
  ${DetailedDocumentFieldsFragmentDoc}
`;

/**
 * __useSingleDocumentQuery__
 *
 * To run a query within a React component, call `useSingleDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useSingleDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSingleDocumentQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      filterBy: // value for 'filterBy'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useSingleDocumentQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlSingleDocumentQuery,
    OperationTypes.GqlSingleDocumentQueryVariables
  > &
    (
      | {
          variables: OperationTypes.GqlSingleDocumentQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlSingleDocumentQuery,
    OperationTypes.GqlSingleDocumentQueryVariables
  >(SingleDocumentDocument, options);
}
export function useSingleDocumentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlSingleDocumentQuery,
    OperationTypes.GqlSingleDocumentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlSingleDocumentQuery,
    OperationTypes.GqlSingleDocumentQueryVariables
  >(SingleDocumentDocument, options);
}
export function useSingleDocumentSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlSingleDocumentQuery,
    OperationTypes.GqlSingleDocumentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlSingleDocumentQuery,
    OperationTypes.GqlSingleDocumentQueryVariables
  >(SingleDocumentDocument, options);
}
export type SingleDocumentQueryHookResult = ReturnType<
  typeof useSingleDocumentQuery
>;
export type SingleDocumentLazyQueryHookResult = ReturnType<
  typeof useSingleDocumentLazyQuery
>;
export type SingleDocumentSuspenseQueryHookResult = ReturnType<
  typeof useSingleDocumentSuspenseQuery
>;
export type SingleDocumentQueryResult = Apollo.QueryResult<
  OperationTypes.GqlSingleDocumentQuery,
  OperationTypes.GqlSingleDocumentQueryVariables
>;
export const StatsDocument = gql`
  query stats {
    documents(
      facets: [
        caseService
        docCountry
        casePresumedNationality
        docType
        docFraudType
        series
      ]
    ) {
      totalCount
      facets {
        name
        values {
          filter
          count
        }
      }
    }
    series {
      totalCount
      nodes {
        ...serieIdentifierFields
        createdAt
        docType
        docCountry
        docFraudType
        documents {
          totalCount
        }
        lastDocument {
          ...documentIdentifierFields
          seriesUpdatedAt
        }
      }
    }
  }
  ${SerieIdentifierFieldsFragmentDoc}
  ${DocumentIdentifierFieldsFragmentDoc}
`;

/**
 * __useStatsQuery__
 *
 * To run a query within a React component, call `useStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStatsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlStatsQuery,
    OperationTypes.GqlStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlStatsQuery,
    OperationTypes.GqlStatsQueryVariables
  >(StatsDocument, options);
}
export function useStatsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlStatsQuery,
    OperationTypes.GqlStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlStatsQuery,
    OperationTypes.GqlStatsQueryVariables
  >(StatsDocument, options);
}
export function useStatsSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlStatsQuery,
    OperationTypes.GqlStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlStatsQuery,
    OperationTypes.GqlStatsQueryVariables
  >(StatsDocument, options);
}
export type StatsQueryHookResult = ReturnType<typeof useStatsQuery>;
export type StatsLazyQueryHookResult = ReturnType<typeof useStatsLazyQuery>;
export type StatsSuspenseQueryHookResult = ReturnType<
  typeof useStatsSuspenseQuery
>;
export type StatsQueryResult = Apollo.QueryResult<
  OperationTypes.GqlStatsQuery,
  OperationTypes.GqlStatsQueryVariables
>;
export const UserEmailsDocument = gql`
  query userEmails {
    users(filterBy: { isDisabled: false, isConfirmed: true }) {
      nodes {
        id
        email
      }
    }
  }
`;

/**
 * __useUserEmailsQuery__
 *
 * To run a query within a React component, call `useUserEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserEmailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserEmailsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlUserEmailsQuery,
    OperationTypes.GqlUserEmailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlUserEmailsQuery,
    OperationTypes.GqlUserEmailsQueryVariables
  >(UserEmailsDocument, options);
}
export function useUserEmailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlUserEmailsQuery,
    OperationTypes.GqlUserEmailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlUserEmailsQuery,
    OperationTypes.GqlUserEmailsQueryVariables
  >(UserEmailsDocument, options);
}
export function useUserEmailsSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlUserEmailsQuery,
    OperationTypes.GqlUserEmailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlUserEmailsQuery,
    OperationTypes.GqlUserEmailsQueryVariables
  >(UserEmailsDocument, options);
}
export type UserEmailsQueryHookResult = ReturnType<typeof useUserEmailsQuery>;
export type UserEmailsLazyQueryHookResult = ReturnType<
  typeof useUserEmailsLazyQuery
>;
export type UserEmailsSuspenseQueryHookResult = ReturnType<
  typeof useUserEmailsSuspenseQuery
>;
export type UserEmailsQueryResult = Apollo.QueryResult<
  OperationTypes.GqlUserEmailsQuery,
  OperationTypes.GqlUserEmailsQueryVariables
>;
export const UserSelectDocument = gql`
  query userSelect {
    users(filterBy: { isDisabled: false, isConfirmed: true }) {
      nodes {
        ...userWithService
      }
    }
  }
  ${UserWithServiceFragmentDoc}
`;

/**
 * __useUserSelectQuery__
 *
 * To run a query within a React component, call `useUserSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserSelectQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlUserSelectQuery,
    OperationTypes.GqlUserSelectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlUserSelectQuery,
    OperationTypes.GqlUserSelectQueryVariables
  >(UserSelectDocument, options);
}
export function useUserSelectLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlUserSelectQuery,
    OperationTypes.GqlUserSelectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlUserSelectQuery,
    OperationTypes.GqlUserSelectQueryVariables
  >(UserSelectDocument, options);
}
export function useUserSelectSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlUserSelectQuery,
    OperationTypes.GqlUserSelectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlUserSelectQuery,
    OperationTypes.GqlUserSelectQueryVariables
  >(UserSelectDocument, options);
}
export type UserSelectQueryHookResult = ReturnType<typeof useUserSelectQuery>;
export type UserSelectLazyQueryHookResult = ReturnType<
  typeof useUserSelectLazyQuery
>;
export type UserSelectSuspenseQueryHookResult = ReturnType<
  typeof useUserSelectSuspenseQuery
>;
export type UserSelectQueryResult = Apollo.QueryResult<
  OperationTypes.GqlUserSelectQuery,
  OperationTypes.GqlUserSelectQueryVariables
>;
export const UsersDocument = gql`
  query users(
    $skip: Int
    $limit: Int
    $filterBy: UserFiltersInput
    $sortBy: UserSortInput
  ) {
    users(skip: $skip, limit: $limit, filterBy: $filterBy, sortBy: $sortBy) {
      totalCount
      nodes {
        id
        lastLogin
        activationToken
        isDisabled
        isConfirmed
        email
        name
        service {
          ...serviceFields
        }
        roles
      }
    }
  }
  ${ServiceFieldsFragmentDoc}
`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      filterBy: // value for 'filterBy'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useUsersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlUsersQuery,
    OperationTypes.GqlUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlUsersQuery,
    OperationTypes.GqlUsersQueryVariables
  >(UsersDocument, options);
}
export function useUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlUsersQuery,
    OperationTypes.GqlUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlUsersQuery,
    OperationTypes.GqlUsersQueryVariables
  >(UsersDocument, options);
}
export function useUsersSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlUsersQuery,
    OperationTypes.GqlUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlUsersQuery,
    OperationTypes.GqlUsersQueryVariables
  >(UsersDocument, options);
}
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersSuspenseQueryHookResult = ReturnType<
  typeof useUsersSuspenseQuery
>;
export type UsersQueryResult = Apollo.QueryResult<
  OperationTypes.GqlUsersQuery,
  OperationTypes.GqlUsersQueryVariables
>;
export const UsersPickerDocument = gql`
  query usersPicker($term: String) {
    users(
      sortBy: { direction: ASC, field: email }
      filterBy: { searchTerm: $term }
    ) {
      nodes {
        id
        email
        name
      }
    }
  }
`;

/**
 * __useUsersPickerQuery__
 *
 * To run a query within a React component, call `useUsersPickerQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersPickerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersPickerQuery({
 *   variables: {
 *      term: // value for 'term'
 *   },
 * });
 */
export function useUsersPickerQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    OperationTypes.GqlUsersPickerQuery,
    OperationTypes.GqlUsersPickerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OperationTypes.GqlUsersPickerQuery,
    OperationTypes.GqlUsersPickerQueryVariables
  >(UsersPickerDocument, options);
}
export function useUsersPickerLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OperationTypes.GqlUsersPickerQuery,
    OperationTypes.GqlUsersPickerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OperationTypes.GqlUsersPickerQuery,
    OperationTypes.GqlUsersPickerQueryVariables
  >(UsersPickerDocument, options);
}
export function useUsersPickerSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    OperationTypes.GqlUsersPickerQuery,
    OperationTypes.GqlUsersPickerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    OperationTypes.GqlUsersPickerQuery,
    OperationTypes.GqlUsersPickerQueryVariables
  >(UsersPickerDocument, options);
}
export type UsersPickerQueryHookResult = ReturnType<typeof useUsersPickerQuery>;
export type UsersPickerLazyQueryHookResult = ReturnType<
  typeof useUsersPickerLazyQuery
>;
export type UsersPickerSuspenseQueryHookResult = ReturnType<
  typeof useUsersPickerSuspenseQuery
>;
export type UsersPickerQueryResult = Apollo.QueryResult<
  OperationTypes.GqlUsersPickerQuery,
  OperationTypes.GqlUsersPickerQueryVariables
>;
