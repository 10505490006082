import { T } from '@tolgee/react';
import { Link } from 'react-router-dom';

import { FormattedAlert } from '#intl';
import { AlertButton } from '#tailwind_ui';

interface StateNotCompatibleFormattedAlertProps {
  id: string;
}

export function StateNotCompatibleFormattedAlert(
  props: StateNotCompatibleFormattedAlertProps,
) {
  const { id } = props;

  return (
    <FormattedAlert
      type="warning"
      title="scan.step.correct.state_not_compatible.title"
    >
      <div>
        <T keyName="scan.step.correct.wrong_step_alert.generic_description" />
      </div>
      <Link to={`/scans/${id}`}>
        <AlertButton>
          <T keyName="scan.step.correct.wrong_step_alert.back_button" />
        </AlertButton>
      </Link>
    </FormattedAlert>
  );
}
