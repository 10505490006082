import { T } from '@tolgee/react';
import { useNavigate } from 'react-router-dom';

import { useCheckedLoginState } from '../../../../components/providers/LoginProvider.context';
import FormattedFormError from '../../../../components/translation/FormattedFormError';
import FormattedInputField from '../../../../components/translation/FormattedInputField';
import FormattedSubmitButton from '../../../../components/translation/FormattedSubmitButton';
import { requiredObject, requiredString } from '../../../../forms/validation';

import {
  GqlChangeUserPasswordInput,
  useChangeUserPasswordMutation,
} from '#gql';
import { Color, FormRHF, useNotificationCenter } from '#tailwind_ui';
import { PageLayout } from '#ui/page_layout';

type ChangePasswordValues = Omit<GqlChangeUserPasswordInput, 'id'>;

const validationSchema = requiredObject({
  currentPassword: requiredString,
  newPassword: requiredString,
  confirmNewPassword: requiredString,
});

export default function ChangePassword() {
  const navigate = useNavigate();
  const { addNotification } = useNotificationCenter();
  const { id } = useCheckedLoginState();
  const [changeUserPassword] = useChangeUserPasswordMutation();

  const submitButton = (
    <FormattedSubmitButton color="primary" messageId="global.change" />
  );

  return (
    <FormRHF<ChangePasswordValues>
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        await changeUserPassword({
          variables: { input: { id, ...values } },
        });
        addNotification({
          type: Color.success,
          title: <T keyName="page.change_password.success" />,
        });
        navigate(-1);
      }}
      noDefaultStyle
    >
      <PageLayout
        title={<T keyName="nav.change_password" />}
        actions={submitButton}
        footer={submitButton}
        bodyClassName="max-w-xl mx-auto"
        footerClassName="max-w-xl mx-auto"
      >
        <FormattedInputField
          type="password"
          name="currentPassword"
          label="page.change_password.current_password"
          autoComplete="current-password"
          autoFocus
        />
        <FormattedInputField
          type="password"
          name="newPassword"
          label="page.change_password.new_password"
          autoComplete="new-password"
        />
        <FormattedInputField
          type="password"
          name="confirmNewPassword"
          label="user.activate.field.repeat_password"
          autoComplete="new-password"
        />
        <FormattedFormError />
      </PageLayout>
    </FormRHF>
  );
}
