import { ScaleOrdinal } from 'd3-scale';
import { useLayoutEffect, useRef, useState } from 'react';

import { PadifAnalysisResult } from '../padifGqlTypes';

import {
  labelHeight,
  seriesColorOpacity,
  labelPadding,
  labelTextDiff,
} from './reportUtils';

import { HighlightedLink } from '#ui/link';

interface PadifClusteringDocumentProps {
  seriesColorScale: ScaleOrdinal<string, string>;
  seriesLabelScale: ScaleOrdinal<string, string>;
  referenceId: string;
  document: PadifAnalysisResult['documents'][number];
  index: number;
}

export default function PadifClusteringDocument(
  props: PadifClusteringDocumentProps,
) {
  const { seriesColorScale, seriesLabelScale, referenceId, document, index } =
    props;
  const textRef = useRef<SVGTextElement>(null);
  const [textDimensions, setTextDimensions] = useState({ width: 0, height: 0 });
  const y = labelHeight * index + labelHeight / 2;
  const documentLabel = `${document.seqId} | ${
    document.caseService.identifier
  }${document.id === referenceId ? ' *' : ''}`;
  const series = document.series?.seqId;
  useLayoutEffect(() => {
    if (textRef.current) {
      const bbox = textRef.current.getBBox();
      setTextDimensions({ width: bbox.width, height: bbox.height });
    }
  }, [documentLabel]);
  const rectHeight = textDimensions.height + labelPadding * 2;
  return (
    <g transform={`translate(0,${y})`}>
      <rect
        x={-labelPadding}
        y={-rectHeight / 2 - labelTextDiff}
        width={textDimensions.width + labelPadding * 2}
        height={rectHeight}
        fillOpacity={series ? seriesColorOpacity : 0}
        fill={series ? seriesColorScale(series) : ''}
      />
      <HighlightedLink to={`/documents/${document.seqId}`}>
        <text ref={textRef} dominantBaseline="middle">
          {`${documentLabel}${series ? seriesLabelScale(series) : ''}`}
        </text>
      </HighlightedLink>
    </g>
  );
}
