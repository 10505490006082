import { T } from '@tolgee/react';
import { ReactNode } from 'react';

import { useAppMeta } from '../../components/providers/AppMetaProvider.context';
import { useLoginState } from '../../components/providers/LoginProvider.context';

import logoEuc from '#assets/img/logo_euc.png';
import logoIsf from '#assets/img/logo_isf.png';
import logoProfid from '#assets/img/logo_profid.png';
import logoRbt from '#assets/img/logo_rbt.png';
import LogoUnil from '#assets/img/logo_unil.svg?react';
import LogoZakodium from '#assets/img/logo_zakodium.svg?react';
import { Tooltip } from '#tailwind_ui';

export function Footer() {
  const appMeta = useAppMeta();
  const variant = appMeta.variant;
  const loginState = useLoginState();

  if (variant === 'demo' || loginState.status !== 'authenticated') {
    return <div className="mt-8" />;
  }

  return (
    <div className="mb-2 mt-8 flex items-center justify-center gap-16 border-t border-neutral-200 pt-2 text-center print:hidden">
      <img className="inline h-[60px]" src={logoProfid} alt="Logo RBT" />
      <Logo
        text="Zakodium Sàrl"
        image={<LogoZakodium className="inline" height="55px" />}
      />
      <Logo
        text="UNIL - Ecole des sciences criminelles"
        image={<LogoUnil className="inline" height="60px" />}
      />
      {variant === 'ch' && (
        <Logo
          text="Groupe RBT"
          image={
            <img className="inline h-[60px]" src={logoRbt} alt="Logo RBT" />
          }
        />
      )}
      {variant === 'eu' && (
        <>
          <Logo
            text="Project ISFP-2020-AGPOLCOP n°101036247 funded by the European Commission"
            image={
              <img className="inline h-[70px]" src={logoIsf} alt="Logo ISF" />
            }
          />
          <Logo
            text={<T keyName="global.european_commission" />}
            image={
              <img
                className="inline h-[60px]"
                src={logoEuc}
                alt="Logo European Commission"
              />
            }
          />
        </>
      )}
    </div>
  );
}

function Logo(props: { text: ReactNode; image: ReactNode }) {
  return (
    <Tooltip content={props.text}>
      <div className="flex">{props.image}</div>
    </Tooltip>
  );
}
