import { T, useTranslate } from '@tolgee/react';
import { useMemo, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { match } from 'ts-pattern';

import { useDocumentSetDynamicDocumentContext } from '../../../contexts/documentSeDynamicDocument';
import { useTableSelection } from '../../../hooks/useTableSelection';
import { DocumentSetCandidateTableSearchProvider } from '../../../providers/DocumentSetCandidateTableSearchProvider';
import { DocumentSetDynamicDocumentTableSearchProvider } from '../../../providers/DocumentSetDynamicDocumentTableSearchProvider';

import DocumentSetNewStep1 from './DocumentSetNewStep1';
import DocumentSetNewStep2Dynamic from './DocumentSetNewStep2Dynamic';
import DocumentSetNewStep2Manual from './DocumentSetNewStep2Manual';
import { firstStepDefaultValues } from './common';

import { useCreateDocumentSetMutation } from '#gql';
import { Stepper } from '#tailwind_ui';
import { PageLayout, PageLayoutNavigation } from '#ui/page_layout';
import { assert, assertNotNull } from '#utils/assert';

type StepId = '1-identifiers' | '2-select-documents';
interface Step {
  id: StepId;
  label: string;
}

function DocumentSetNew() {
  const navigate = useNavigate();
  const { t } = useTranslate();

  const [step, dispatch] = useReducer(stepReducer, 0);
  const steps = useMemo<Step[]>(
    () => [
      {
        id: '1-identifiers',
        label: t('doc_set.form.step_identifiers'),
      },
      {
        id: '2-select-documents',
        label: t('doc_set.form.step_select_documents'),
      },
    ],
    [t],
  );
  const [firstStepValues, setFirstStepValues] = useState(
    firstStepDefaultValues,
  );

  const candidateSelection = useTableSelection<string>();
  const excludedSelection = useTableSelection<string>();

  const [formError, setFormError] = useState<Error>();
  const [createDocumentSet] = useCreateDocumentSetMutation();

  const { filters: dynamicFilters } = useDocumentSetDynamicDocumentContext();

  function onSave() {
    const { name, docType, docCountry, type } = firstStepValues;
    assertNotNull(docType);
    assertNotNull(docCountry);

    createDocumentSet({
      variables: {
        input: {
          name,
          docType,
          docCountry,
          type,
          documents:
            type === 'manual' ? Array.from(candidateSelection.value) : null,
          dynamicFilters: type === 'dynamic' ? dynamicFilters : null,
          excludedDocuments:
            type === 'dynamic' ? Array.from(excludedSelection.value) : null,
        },
      },
    })
      .then(({ data }) => {
        assert(data);
        navigate(`/document-sets/${data.createDocumentSet.id}`);
      })
      .catch((error) => {
        setFormError(error);
        dispatch({ type: 'set', payload: 0 });
      });
  }

  return (
    <PageLayout
      title={<T keyName="page.doc_set.new.title" />}
      navigation={
        <PageLayoutNavigation to="/document-sets">
          <T keyName="nav.doc_sets" />
        </PageLayoutNavigation>
      }
    >
      <Stepper
        steps={steps}
        current={step}
        onSelectStep={(index) => dispatch({ type: 'set', payload: index })}
      />

      {match(steps[step].id)
        .with('1-identifiers', () => (
          <DocumentSetNewStep1
            defaultValues={firstStepValues}
            onSubmit={(values) => {
              setFirstStepValues(values);
              dispatch({ type: 'next' });
            }}
            formError={formError}
          />
        ))
        .with('2-select-documents', () => {
          return match(firstStepValues.type)
            .with('manual', () => (
              <DocumentSetNewStep2Manual
                selection={candidateSelection}
                identifiers={firstStepValues}
                onSubmit={onSave}
              />
            ))
            .with('dynamic', () => (
              <DocumentSetNewStep2Dynamic
                selection={excludedSelection}
                identifiers={firstStepValues}
                onSubmit={onSave}
              />
            ))
            .exhaustive();
        })
        .exhaustive()}
    </PageLayout>
  );
}

interface PreviousAction {
  type: 'previous';
}

interface NextAction {
  type: 'next';
}

interface SetAction {
  type: 'set';
  payload: number;
}

type Action = PreviousAction | NextAction | SetAction;

function stepReducer(state: number, action: Action): number {
  return match(action)
    .returnType<number>()
    .with({ type: 'set' }, (action) => action.payload)
    .with({ type: 'previous' }, () => {
      state--;
      if (state < 0) state = 2;
      return state;
    })
    .with({ type: 'next' }, () => {
      state++;
      if (state > 2) state = 0;
      return state;
    })
    .exhaustive();
}

export default function DocumentSetNewPage() {
  return (
    <DocumentSetCandidateTableSearchProvider>
      <DocumentSetDynamicDocumentTableSearchProvider>
        <DocumentSetNew />
      </DocumentSetDynamicDocumentTableSearchProvider>
    </DocumentSetCandidateTableSearchProvider>
  );
}
