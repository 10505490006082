import clsx from 'clsx';
import { forwardRef } from 'react';

import { PropsOf } from '#tailwind_ui';

export const H1 = forwardRef<HTMLHeadingElement, PropsOf<'h1'>>(
  (props, ref) => {
    return (
      <h1
        {...props}
        ref={ref}
        className={clsx('mb-2 text-4xl font-semibold', props.className)}
      />
    );
  },
);

export const H2 = forwardRef<HTMLHeadingElement, PropsOf<'h2'>>(
  (props, ref) => {
    return (
      <h2
        {...props}
        ref={ref}
        className={clsx('mb-2 text-3xl font-semibold', props.className)}
      />
    );
  },
);

export const H3 = forwardRef<HTMLHeadingElement, PropsOf<'h3'>>(
  (props, ref) => {
    return (
      <h3
        {...props}
        ref={ref}
        className={clsx('mb-2 text-xl font-semibold', props.className)}
      />
    );
  },
);

export const H4 = forwardRef<HTMLHeadingElement, PropsOf<'h4'>>(
  (props, ref) => {
    return (
      <h4
        {...props}
        ref={ref}
        className={clsx('mb-2 text-lg font-semibold', props.className)}
      />
    );
  },
);
