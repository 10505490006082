import { T, useTranslate } from '@tolgee/react';
import { memo } from 'react';

import { useLocalizedCountryMap } from '../../../../hooks/localizedCountries';
import {
  safeTranslationKeyDocumentFraud,
  safeTranslationKeyDocumentType,
} from '../../../../translationsMapping/documents';

import { GqlLabelBadgeFieldsFragment } from '#gql';
import { FormattedDefinitionList } from '#ui/definition_list';

interface LabelScopeProps {
  label: GqlLabelBadgeFieldsFragment;
}

export const LabelScope = memo(function LabelScope({ label }: LabelScopeProps) {
  const { t } = useTranslate();
  const countries = useLocalizedCountryMap();

  const scopeEntries = [
    [
      'doc.field.type',
      label.docType && t(safeTranslationKeyDocumentType(label.docType)),
    ],
    [
      'doc.field.fraud_type',
      label.docFraudType &&
        t(safeTranslationKeyDocumentFraud(label.docFraudType)),
    ],
    [
      'doc.field.country',
      countries?.[label.docCountry ?? '']
        ? `${String(label.docCountry)} - ${countries?.[label.docCountry ?? '']}`
        : label.docCountry ?? '',
    ],
  ].filter(filterEmptyEntries);

  return scopeEntries.length > 0 ? (
    <FormattedDefinitionList data={Object.fromEntries(scopeEntries)} />
  ) : (
    <T keyName="label.field.subset.empty" />
  );
});

function filterEmptyEntries([, value]: readonly unknown[]): boolean {
  return Boolean(value);
}
