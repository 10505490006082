import SelectCountry from '../form/SelectCountry';
import { SelectDocumentType, SelectFraudType } from '../form/SelectFields';
import FormattedFormError from '../translation/FormattedFormError';
import FormattedInputField from '../translation/FormattedInputField';

export default function NewSeriesFields() {
  return (
    <>
      <FormattedInputField name="name" label="series.field.name" autoFocus />

      <SelectDocumentType name="docType" />
      <SelectCountry name="docCountry" label="doc.field.country" />
      <SelectFraudType name="docFraudType" />
      <FormattedFormError />
    </>
  );
}
