import { TrashIcon } from '@heroicons/react/24/outline';

import { AsyncFormattedConfirmModal } from '../../AsyncFormattedConfirmModal';

import { useDeleteAlertMutation } from '#gql';

interface DeleteAlertModalProps {
  isOpen: boolean;
  id: string;
  onClose: (success?: boolean) => void;
}
export default function DeleteAlertModal(props: DeleteAlertModalProps) {
  const { isOpen, id, onClose } = props;
  const [deleteAlert] = useDeleteAlertMutation();

  return (
    <AsyncFormattedConfirmModal
      open={isOpen}
      icon={<TrashIcon />}
      color="danger"
      confirmText="global.delete"
      cancelText="global.cancel"
      title="series.alert.delete.confirm.title"
      body="series.alert.delete.confirm.body"
      onClose={onClose}
      onConfirm={() =>
        deleteAlert({
          variables: {
            id,
          },
        })
      }
    />
  );
}
