import { T, useTolgee } from '@tolgee/react';
import { useMemo } from 'react';

import { useRectoVerso } from '../../../../hooks/useRectoVerso';
import { PADIF_COLORS, useNewRoiContext } from '../utils';

import { DocumentView } from '#gql';
import { Badge, Select } from '#tailwind_ui';
import { assertNotNull } from '#utils/assert';

export interface SelectionImage {
  label: string;
  value: DocumentView;
}

export function PadifDocumentation() {
  const { t } = useTolgee();
  const [{ analysis, view }, { setView }] = useNewRoiContext();

  assertNotNull(analysis.referenceDocument);
  const { recto, verso } = useRectoVerso(analysis.referenceDocument);

  const opts = useMemo(() => {
    const options: SelectionImage[] = [];

    if (recto) {
      options.push({
        label: t('doc.document_view.RECTO'),
        value: 'RECTO',
      });
    }

    if (verso) {
      options.push({ label: t('doc.document_view.VERSO'), value: 'VERSO' });
    }

    return options;
  }, [recto, t, verso]);

  if (!recto && !verso) {
    return <div>TODO: print message when no image is available</div>;
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col gap-5">
        <div className="flex flex-col">
          <T keyName="doc.document_view" />
          <Select
            className="max-w-sm"
            label="Select a view"
            hiddenLabel
            selected={
              opts.length === 1 ? opts[0] : opts.find((el) => el.value === view)
            }
            options={opts}
            onChange={(element) => setView(element?.value || 'RECTO')}
          />
        </div>

        <div className="flex flex-col gap-2">
          <span className="text-xl font-bold underline">
            <T keyName="page.padif.new.doc.general.title" />
          </span>

          <p>
            <T keyName="page.padif.new.doc.general.description" />
          </p>

          <div className="flex flex-1 flex-row gap-5">
            <Badge
              variant="COLORED_DOT"
              label={t(
                'page.padif.new.doc.general.description.badges.readonly',
              )}
              dot
              customColor={PADIF_COLORS.readonly}
            />

            <Badge
              variant="COLORED_DOT"
              label={t(
                'page.padif.new.doc.general.description.badges.editable',
              )}
              dot
              customColor={PADIF_COLORS.editable}
            />
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <span className="text-xl font-bold underline">
            <T keyName="page.padif.new.doc.move.title" />
          </span>

          <p>
            <T keyName="page.padif.new.doc.move.description" />
          </p>
        </div>

        <div className="flex flex-col gap-2">
          <span className="text-xl font-bold underline">
            <T keyName="page.padif.new.doc.resize.title" />
          </span>

          <p>
            <T keyName="page.padif.new.doc.resize.description" />
          </p>
        </div>

        <div className="flex flex-col gap-2">
          <span className="text-xl font-bold underline">
            <T keyName="page.padif.new.doc.zoom.title" />
          </span>

          <p>
            <T keyName="page.padif.new.doc.zoom.description" />
          </p>
        </div>
      </div>
    </div>
  );
}
