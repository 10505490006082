import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { LabelScope } from './scope';

import { GqlLabelBadgeFieldsFragment } from '#gql';
import { Badge, Tooltip } from '#tailwind_ui';

export interface BaseLabelBadgeProps {
  name: string;
  color: string;
  backgroundColor: string;
  className?: string;
  icon?: ReactNode;
  tooltip?: ReactNode;
}

export function BaseLabelBadge(props: BaseLabelBadgeProps) {
  const { name, color, backgroundColor, className, icon, tooltip } = props;

  const target = (
    <Badge
      variant="CUSTOM_COLOR"
      backgroundColor={backgroundColor}
      textColor={color}
      label={
        <span className="flex flex-row flex-nowrap items-center">
          {icon}
          {name}
        </span>
      }
      className={className}
    />
  );

  if (tooltip) {
    return (
      <Tooltip content={<div className="z-20">{tooltip}</div>}>
        <div className="inline-flex">{target}</div>
      </Tooltip>
    );
  }

  return target;
}

export interface LabelBadgeProps {
  label: GqlLabelBadgeFieldsFragment;
  icon?: ReactNode;
}

export function LabelBadge({ label, icon }: LabelBadgeProps) {
  return (
    <BaseLabelBadge
      name={label.name}
      color={label.color.text}
      backgroundColor={label.color.background}
      tooltip={
        <>
          {label.description && <p>{label.description}</p>}
          <LabelScope label={label} />
        </>
      }
      icon={icon}
    />
  );
}

export interface LinkLabelBadgeProps {
  to: string;
  label: GqlLabelBadgeFieldsFragment;
  replace?: boolean;
}
export function LinkLabelBadge(props: LinkLabelBadgeProps) {
  const { to, label, replace } = props;
  const search = new URLSearchParams({ labels: label.id });

  return (
    <Link
      to={{ pathname: to, search: search.toString() }}
      replace={replace}
      className="inline-flex"
    >
      <LabelBadge label={label} />
    </Link>
  );
}
