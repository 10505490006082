import { OverlayProps } from './types';

export function PartialOverlay({ children }: OverlayProps) {
  return (
    <div className="pointer-events-none absolute left-0 top-0 h-full w-full text-white opacity-0 transition-opacity group-hover:opacity-100">
      <div className="rounded-t-lg bg-gradient-to-b from-black/70 from-75% p-4 pb-8">
        {children}
      </div>
    </div>
  );
}
