import { T } from '@tolgee/react';

export interface FormattedUserNameProps {
  id?: TranslationKey;
  values: { name?: string | null; email: string };
}

export default function FormattedUserName(props: FormattedUserNameProps) {
  const { id, values } = props;
  const newValues = { name: values.name || values.email };

  if (id) {
    return <T keyName={id} params={newValues} />;
  } else {
    return <>{newValues.name}</>;
  }
}
