import { useOutletContext } from 'react-router-dom';
import { match, P } from 'ts-pattern';

import FormattedEmptyState from '../translation/FormattedEmptyState';

import { PadifReportNew } from './report-js/PadifReportNew';

import { GqlPadifAnalysisQuery, usePadifAnalysisResultQuery } from '#gql';

export default function PadifShowMainNewReportPage() {
  const padif = useOutletContext<GqlPadifAnalysisQuery['padifAnalysis']>();
  const { data, error } = usePadifAnalysisResultQuery({
    variables: { id: padif.id },
  });

  if (error) throw error;
  if (!data) return null;

  const { referenceDocument, resultJs, rois } = data.padifAnalysis;

  if (!referenceDocument) {
    return <FormattedEmptyState title="padif.report.no_reference_document" />;
  }

  if (!resultJs) {
    return <FormattedEmptyState title="page.padif.report.no_new_report" />;
  }

  return match(resultJs)
    .with(
      {
        finishedAt: P.not(P.nullish),
        distances: [P._, ...P.array(P._)],
        documents: [P._, ...P.array(P._)],
      },
      (result) => (
        <PadifReportNew
          referenceDocument={referenceDocument}
          rois={rois}
          result={result}
        />
      ),
    )
    .otherwise(() => (
      <FormattedEmptyState title="page.padif.report.no_new_report" />
    ));
}
