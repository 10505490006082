import { ReactNode } from 'react';

import { useHasPermission } from '../../hooks/permissions';

import { UserPermission } from '#gql';

interface IfHasPermissionProps {
  userPermission: UserPermission;
  children: ReactNode;
  otherwise?: ReactNode;
}

export default function IfHasPermission({
  userPermission,
  children,
  otherwise = null,
}: IfHasPermissionProps) {
  const hasPermission = useHasPermission(userPermission);
  const node = hasPermission ? children : otherwise;
  return <>{node}</>;
}
