import { ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import { match } from 'ts-pattern';

import { ScanImportSwitchTab } from '../../../../../translationsMapping/scanSwitchTab';

import { switchTabContext } from './switch_tab_context';

import { GqlScanQuery } from '#gql';
import { scanImportGetTab, scanImportSetTab } from '#scan_import';

export type ScanImportDataStep = Exclude<
  GqlScanQuery['scan']['importData'],
  null
>['__typename'];

interface SwitchTabContextProviderProps {
  children: ReactNode;
  step: ScanImportDataStep;
  hasMrz: boolean;
  scanId: string;
}

export function SwitchTabContextProvider(props: SwitchTabContextProviderProps) {
  const { step, children, hasMrz, scanId } = props;

  const [tab, setTabPrimitive] = useState(() =>
    defaultTab(step, hasMrz, scanId),
  );

  const scanIdRef = useRef(scanId);
  scanIdRef.current = scanId;
  const setTab = useCallback<typeof setTabPrimitive>((value) => {
    if (typeof value === 'function') {
      return setTabPrimitive((v) => {
        const newValue = value(v);
        scanImportSetTab(scanIdRef.current, newValue);
        return newValue;
      });
    }

    scanImportSetTab(scanIdRef.current, value);
    return setTabPrimitive(value);
  }, []);

  const value = useMemo(() => {
    return {
      tab,
      setTab,
      hasMrz,
    };
  }, [tab, setTab, hasMrz]);

  return (
    <switchTabContext.Provider value={value}>
      {children}
    </switchTabContext.Provider>
  );
}

function defaultTab(
  step: ScanImportDataStep,
  hasMrz: boolean,
  scanId: string,
): ScanImportSwitchTab {
  const item = scanImportGetTab(scanId);
  if (item) return item;

  return match<ScanImportDataStep, ScanImportSwitchTab>(step)
    .with('ProcessAnalyze', () => 'raw')
    .with('ProcessExtract', () => 'raw')
    .with('ProcessRotate', () => 'extraction')
    .with('ProcessMRZ', () => 'rotation')
    .with('ProcessFindRegions', () => (hasMrz ? 'mrz' : 'rotation'))
    .with('ProcessReview', 'ProcessFinalize', () => 'anonymization')
    .exhaustive();
}
