import { T } from '@tolgee/react';
import React from 'react';

import { StateNotCompatibleFormattedAlert } from '../../../components/scans/importData/utils/StateNotCompatibleFormattedAlert';
import FormattedErrorAlert from '../../../components/translation/FormattedErrorAlert';
import { useScanIdParam } from '../../../hooks/useParams';

import DocumentAnonymization from './scan_roi_anonymization_page/document_anonymization';

import { useScanQuery } from '#gql';
import { PageLayout, PageLayoutNavigation } from '#ui/page_layout';

export function ScanRoiAnonymizationPage() {
  const scanId = useScanIdParam();

  const { data, error } = useScanQuery({
    variables: { id: scanId },
  });

  if (error) {
    return <FormattedErrorAlert error={error} />;
  }
  if (!data?.scan) return null;

  const {
    scan: { importData, filename },
  } = data;

  if (importData?.__typename !== 'ProcessReview') {
    return <StateNotCompatibleFormattedAlert id={data.scan.id} />;
  }

  return (
    <PageLayout
      title={<T keyName="nav.scans.list.import.anonymize" />}
      navigation={
        <>
          <PageLayoutNavigation to="/scans">
            <T keyName="nav.scans.list" />
          </PageLayoutNavigation>
          <PageLayoutNavigation to="./../">{filename}</PageLayoutNavigation>
        </>
      }
    >
      <DocumentAnonymization
        importData={importData}
        url={importData.rotatedDocument.image.url}
        scanId={scanId}
      />
    </PageLayout>
  );
}
