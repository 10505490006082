import { scanImportApplyImageAnonymization } from '@zakodium/profid-shared';
import { readCanvas, writeCanvas } from 'image-js';
import { useCallback, useEffect, useRef } from 'react';
import { match } from 'ts-pattern';

import {
  FinalizeScanQueryImportData,
  ReviewScanQueryImportData,
  RoiAnonymizationScanQueryImportData,
} from '../../../../components/scans/importData/types';
import FormattedErrorAlert from '../../../../components/translation/FormattedErrorAlert';
import { usePromise } from '../../../../hooks/usePromise';

import { ScanImportTabContentProps } from './scan_import_switch_tabs';

import {
  zoomImageInOverlayStyling,
  ZoomOverlay,
  ZoomOverlayRef,
} from '#tailwind_ui';

export function AnonymizationTabContent(props: ScanImportTabContentProps) {
  return match(props.importData)
    .with(
      { __typename: 'ProcessReview' },
      { __typename: 'ProcessFinalize' },
      (importData) => (
        <DocumentWithPersonalDataRegions importData={importData} />
      ),
    )
    .otherwise(() => null);
}

export function DocumentWithPersonalDataRegions(props: {
  importData: ReviewScanQueryImportData | FinalizeScanQueryImportData;
}) {
  const { importData } = props;

  return (
    <Preview
      rois={importData.anonymization.rois}
      imageUrl={importData.rotatedDocument.image.url}
    />
  );
}

interface PreviewProps {
  rois: RoiAnonymizationScanQueryImportData[];
  imageUrl: string;
}
function Preview(props: PreviewProps) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const zoomRef = useRef<ZoomOverlayRef>(null);

  const result = usePromise(
    useCallback(async () => {
      const $image: HTMLImageElement | null =
        containerRef.current?.querySelector('img[data-thumbnail-image]') ??
        null;
      const $canvas: HTMLCanvasElement | null =
        containerRef.current?.querySelector('canvas[data-thumbnail-canvas]') ??
        null;

      if (!$image) return;
      if (!$canvas) return;

      await new Promise((resolve, reject) => {
        // eslint-disable-next-line no-promise-executor-return
        if ($image.complete) return resolve(null);

        $image.addEventListener('load', resolve);
        $image.addEventListener('error', (errorEvent) => {
          reportError(errorEvent);
          reject(errorEvent.error as Error);
        });
      });

      // prepare canvas with naturalHeight and naturalWidth of img
      $canvas.height = $image.naturalHeight;
      $canvas.width = $image.naturalWidth;
      $canvas.getContext('2d')?.drawImage($image, 0, 0);

      // even with 600dpi image process seems fast and not blocking interface
      const image = readCanvas($canvas);
      scanImportApplyImageAnonymization(image, props.rois);
      writeCanvas(image, $canvas);

      // show the original canvas
      $canvas.classList.add('opacity-100');
      $canvas.classList.remove('opacity-0');

      // write on fullscreen canvas
      const $canvasOverlay: HTMLCanvasElement | null =
        containerRef.current?.querySelector('canvas[data-overlay-canvas]') ??
        null;
      if (!$canvasOverlay) return;

      writeCanvas(image, $canvasOverlay, { resizeCanvas: true });
    }, [props.rois]),
  );

  useEffect(() => {
    if (!result.error) return;

    reportError(result.error);
  }, [result.error]);

  return (
    <div
      className="relative flex h-screen max-h-[50vh] w-full flex-col items-center justify-center gap-5 self-center"
      ref={containerRef}
    >
      {result.error && <FormattedErrorAlert error={result.error} />}

      <img
        alt="orignal"
        src={props.imageUrl}
        className="absolute -z-10 max-h-[50vh] max-w-full object-contain"
        crossOrigin="use-credentials"
        data-thumbnail-image=""
      />

      <div className="contents">
        <canvas
          onClick={() => zoomRef.current?.open()}
          height={0}
          className="mt-5 max-h-[50vh] max-w-full cursor-zoom-in object-contain opacity-0 transition-opacity duration-300"
          data-thumbnail-canvas=""
        />
        <ZoomOverlay ref={zoomRef}>
          <canvas
            className={zoomImageInOverlayStyling()}
            height={0}
            data-overlay-canvas=""
          />
        </ZoomOverlay>
      </div>
    </div>
  );
}
