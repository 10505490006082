import { PencilIcon } from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';

import {
  SelectDocumentView,
  SelectExtraImageLight,
} from '../form/SelectFields';
import FormattedButton from '../translation/FormattedButton';
import FormattedFormError from '../translation/FormattedFormError';
import FormattedSubmitButton from '../translation/FormattedSubmitButton';
import FormattedTextAreaField from '../translation/FormattedTextAreaField';

import {
  DocumentExtraImageLight,
  DocumentView,
  GqlDetailedExtraImageFieldsFragment,
  useUpdateDocumentExtraImageMetadataMutation,
} from '#gql';
import { FormRHFModal } from '#tailwind_ui';

interface DocumentUpdateExtraImageProps {
  docId: string;
  image: GqlDetailedExtraImageFieldsFragment;
  onCancel: () => void;
}

interface FormValues {
  light: DocumentExtraImageLight;
  view: DocumentView;
  comment: string | null;
}

export default function DocumentUpdateExtraImageModal(
  props: DocumentUpdateExtraImageProps,
) {
  const { docId, onCancel, image } = props;

  const [updateExtraImage] = useUpdateDocumentExtraImageMetadataMutation();
  return (
    <FormRHFModal<FormValues>
      icon={<PencilIcon />}
      fluid
      isOpen
      defaultValues={image}
      onRequestClose={onCancel}
      onSubmit={async (values) => {
        await updateExtraImage({
          variables: {
            input: {
              id: docId,
              imageId: image.id,
              light: values.light,
              view: values.view,
              comment: values.comment,
            },
          },
        });
        onCancel();
      }}
    >
      <FormRHFModal.Header>
        <T keyName="doc.extra_image.update.title" />
      </FormRHFModal.Header>
      <FormRHFModal.Body className="flex w-96 flex-col gap-5">
        <SelectExtraImageLight name="light" required />
        <SelectDocumentView name="view" label="doc.document_view" required />
        <FormattedTextAreaField name="comment" label="global.comment" />
        <FormattedFormError />
      </FormRHFModal.Body>
      <FormRHFModal.Footer>
        <FormattedButton
          variant="white"
          messageId="global.cancel"
          onClick={onCancel}
        />
        <FormattedSubmitButton messageId="global.save" color="primary" />
      </FormRHFModal.Footer>
    </FormRHFModal>
  );
}
