import { T } from '@tolgee/react';
import { useState } from 'react';

import { requiredObject, requiredString } from '../../forms/validation';
import SelectService from '../form/SelectService';
import FormattedInputField from '../translation/FormattedInputField';
import FormattedSubmitButton from '../translation/FormattedSubmitButton';

import ScannerQrCode from './ScannerQrCode';

import { FormRHF } from '#tailwind_ui';
import { PageLayout, PageLayoutNavigation } from '#ui/page_layout';

export interface ScannerData {
  service: string;
  brand: string;
  model: string;
  serial?: string;
  description?: string;
}

const validationSchema = requiredObject({
  service: requiredString,
  brand: requiredString,
  model: requiredString,
});

export default function Scanner() {
  const [scanner, setScanner] = useState<ScannerData | null>(null);

  return (
    <FormRHF<ScannerData>
      onSubmit={setScanner}
      validationSchema={validationSchema}
      noDefaultStyle
    >
      <PageLayout
        title={<T keyName="scanner.title" />}
        navigation={
          <PageLayoutNavigation to="/scans">
            <T keyName="nav.scans.list" />
          </PageLayoutNavigation>
        }
        headerClassName="print:hidden"
        bodyClassName="grid grid-cols-3 gap-y-2 gap-x-3 print:block md:grid-cols-6 max-w-4xl"
        noDefaultBodyClassName
      >
        <div className="col-span-3 space-y-3 lg:col-span-3 print:hidden">
          <SelectService required name="service" />
          <FormattedInputField
            required
            type="text"
            name="brand"
            label="scanner.form.brand"
            placeholder="scanner.form.brand_placeholder"
          />
          <FormattedInputField
            required
            type="text"
            name="model"
            label="scanner.form.model"
          />
          <FormattedInputField
            type="text"
            name="serial"
            label="scanner.form.serial_number"
          />
          <FormattedInputField
            type="text"
            name="description"
            label="global.description"
            placeholder="scanner.form.description_placeholder"
          />
          <FormattedSubmitButton
            className="mt-3"
            color="primary"
            messageId="scanner.button.create_qr_code"
          />
        </div>

        <div className="col-span-3">
          {scanner ? <ScannerQrCode data={scanner} /> : null}
        </div>
      </PageLayout>
    </FormRHF>
  );
}
