import { createContext, useContext } from 'react';

import { TableSearchContext } from '../components/providers/TableSearchContextProvider';
import {
  SeriesFilters,
  SeriesFiltersStore,
} from '../stores/searches/SeriesFiltersStore';

export const seriesSupportedSortFields = [
  'name',
  'createdAt',
  'importantFieldsUpdatedAt',
] as const;
export type SeriesSupportedSortField =
  (typeof seriesSupportedSortFields)[number];

export const seriesContext = createContext<TableSearchContext<
  SeriesSupportedSortField,
  SeriesFilters,
  SeriesFiltersStore
> | null>(null);

export function useSeriesContext() {
  const context = useContext(seriesContext);

  if (context === null) {
    throw new Error(
      'useSeriesContext must be used within a TableSearchContextProvider',
    );
  }

  return context;
}
