import { useTranslate } from '@tolgee/react';

// eslint-disable-next-line no-restricted-imports
import { EmptyState, EmptyStateProps } from '#tailwind_ui';

interface FormattedEmptyStateProps
  extends Omit<EmptyStateProps, 'title' | 'subtitle'> {
  title: TranslationKey;
  subtitle?: TranslationKey;
}

export default function FormattedEmptyState(props: FormattedEmptyStateProps) {
  const { title, subtitle, ...otherProps } = props;
  const { t } = useTranslate();

  return (
    <EmptyState
      title={t(title)}
      subtitle={subtitle && t(subtitle)}
      {...otherProps}
    />
  );
}
