import { TrashIcon } from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';

import { AsyncFormattedConfirmModal } from '../../AsyncFormattedConfirmModal';
import FormattedUserName from '../../translation/FormattedUserName';

import { GqlUsersQuery, useDeleteUserMutation } from '#gql';
import { Color, useNotificationCenter } from '#tailwind_ui';

interface DeleteUserConfirmProps {
  onCancel: () => void;
  user: GqlUsersQuery['users']['nodes'][0];
}

export default function DeleteUserConfirm(props: DeleteUserConfirmProps) {
  const { onCancel, user } = props;

  const { addNotification } = useNotificationCenter();
  const [deleteUser] = useDeleteUserMutation();

  return (
    <AsyncFormattedConfirmModal
      open
      onClose={onCancel}
      icon={<TrashIcon />}
      color={Color.danger}
      confirmText="global.delete"
      title="page.users.action.delete_user"
      body={
        <div>
          <T keyName="global.user" />
          {`: `}
          <FormattedUserName values={user} />
        </div>
      }
      onConfirm={() =>
        deleteUser({
          variables: {
            input: { id: user.id },
          },
          refetchQueries: ['users'],
        }).then(() => {
          addNotification({
            type: 'success',
            title: (
              <T
                keyName="user.admin.actions.delete.notification.success"
                params={{ name: user.name }}
              />
            ),
          });
        })
      }
    />
  );
}
