import { BoolStr, safeMappedTranslationKey } from './common';

export const TRANSLATIONS_KEY_GLOBAL_COMPLYING = {
  true: 'global.complying',
  false: 'global.noncomplying',
} as const satisfies Record<BoolStr, TranslationKey>;

export function safeTranslationKeyGlobalComplying(
  index: boolean | null | undefined,
) {
  return safeMappedTranslationKey(TRANSLATIONS_KEY_GLOBAL_COMPLYING, index);
}

export const TRANSLATIONS_KEY_GLOBAL_FLUO = {
  true: 'global.fluorescent',
  false: 'global.nonfluorescent',
} as const satisfies Record<BoolStr, TranslationKey>;

export function safeTranslationKeyGlobalFluo(
  index: boolean | null | undefined,
) {
  return safeMappedTranslationKey(TRANSLATIONS_KEY_GLOBAL_FLUO, index);
}

export const TRANSLATIONS_KEY_GLOBAL_PRESENCE = {
  true: 'global.present',
  false: 'global.absent',
} as const satisfies Record<BoolStr, TranslationKey>;

export function safeTranslationKeyGlobalPresence(
  index: boolean | null | undefined,
) {
  return safeMappedTranslationKey(TRANSLATIONS_KEY_GLOBAL_PRESENCE, index);
}
