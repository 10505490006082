import { useTranslate } from '@tolgee/react';
import { useState } from 'react';

import FormattedConfirmDialog, {
  FormattedConfirmDialogProps,
} from './translation/FormattedConfirmDialog';
import FormattedErrorAlert from './translation/FormattedErrorAlert';

import { useOnOff } from '#tailwind_ui';

interface ConfirmModalErroredProps
  extends Omit<
    FormattedConfirmDialogProps,
    'onConfirm' | 'onCancel' | 'onRequestClose'
  > {
  onConfirm: () => Promise<unknown>;
  onClose: (success?: boolean) => void;
}

export function AsyncFormattedConfirmModal(props: ConfirmModalErroredProps) {
  const { onConfirm, ...otherProps } = props;
  const { onClose, body } = otherProps;
  const [error, setError] = useState<Error | null>(null);
  const { t } = useTranslate();

  const [submitDisabled, disable, enable] = useOnOff(false);

  async function handleConfirm() {
    disable();
    setError(null);

    try {
      await onConfirm();
      onClose(true);
    } catch (error) {
      reportError(error);
      setError(error);
    } finally {
      enable();
    }
  }

  function handleClose() {
    setError(null);
    onClose();
  }

  return (
    <FormattedConfirmDialog
      {...otherProps}
      onConfirm={() => void handleConfirm()}
      onCancel={handleClose}
      noCloseConfirm
      confirmProps={{
        disabled: submitDisabled,
        ...otherProps.confirmProps,
      }}
      body={
        <div className="flex flex-col gap-5">
          {typeof body === 'string' ? <p>{t(body as TranslationKey)}</p> : body}
          <FormattedErrorAlert error={error} />
        </div>
      }
    />
  );
}
