import { T } from '@tolgee/react';
import { parse } from 'mrz';

import { MRZ, MRZProps } from '#tailwind_ui';

interface MRZWithCannotParseFallbackProps extends MRZProps {
  displayMRZWithFallback: boolean;
}

export function MRZWithCannotParseFallback(
  props: MRZWithCannotParseFallbackProps,
): JSX.Element {
  const { mrz, displayMRZWithFallback, ...otherProps } = props;

  try {
    parse(mrz);

    return <MRZ mrz={mrz} {...otherProps} />;
  } catch {
    return <MRZCannotParse mrz={displayMRZWithFallback ? mrz : null} />;
  }
}

const cannotParseTranslationParams = {
  summary: (content: string) => <p className="my-2">{content}</p>,
  ul: (content: string) => <ul className="pl-3.5">{content}</ul>,
  li: (content: string) => <li className="list-disc">{content}</li>,
};

function MRZCannotParse(props: { mrz: string[] | string | null }) {
  const mrz = typeof props.mrz === 'string' ? props.mrz.split('\n') : props.mrz;
  return (
    <div className="flex flex-col gap-2">
      {mrz && (
        <div className="inline-block rounded-md border px-7 py-2 font-ocrb">
          {mrz.map((item, i) => (
            <div key={i}>{item}</div>
          ))}
        </div>
      )}
      <T
        keyName="scans.import_data.mrz.cannot_be_parsed"
        params={cannotParseTranslationParams}
      />
    </div>
  );
}
