import { ScaleLinear } from 'd3-scale';

export default function PadifClusteringXAxis({
  scale,
  width,
}: {
  scale: ScaleLinear<number, number>;
  width: number;
}) {
  const ticks = scale.ticks(10);
  return (
    <g dominantBaseline="hanging" textAnchor="middle">
      <line x1={0} x2={width} y1={0} y2={0} stroke="black" />
      <g>
        {ticks.map((tick) => {
          const x = scale(tick);
          return (
            <g key={tick}>
              <line x1={x} x2={x} y1={0} y2={5} stroke="black" />
              <text x={x} y={7}>
                {tick}
              </text>
            </g>
          );
        })}
      </g>
      <text x={width / 2} y={30}>
        Distance
      </text>
    </g>
  );
}
