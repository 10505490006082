import { useTranslate } from '@tolgee/react';

import { useFormattedSerializeError } from '../../hooks/useFormattedSerializeError';

import {
  RHFValidationProps,
  SearchSelectFieldProps,
  // eslint-disable-next-line no-restricted-imports
  SearchSelectFieldRHF,
  SimpleSearchSelectFieldProps,
  SimpleSelectOption,
} from '#tailwind_ui';

type Excluded = 'serializeError' | 'label' | 'help' | 'placeholder';

type FormattedSearchSelectFieldProps<OptionType> = RHFValidationProps &
  (OptionType extends SimpleSelectOption
    ? Omit<SimpleSearchSelectFieldProps<OptionType>, Excluded>
    : Omit<SearchSelectFieldProps<OptionType>, Excluded>) & {
    label: TranslationKey;
    help?: TranslationKey;
    placeholder?: TranslationKey;
  };

export default function FormattedSearchSelectField<OptionType>(
  props: FormattedSearchSelectFieldProps<OptionType>,
) {
  const { t } = useTranslate();
  const { label, help, placeholder, ...otherProps } = props;
  const serialize = useFormattedSerializeError();

  return (
    // @ts-expect-error ts unable to know if SimpleOption or not here
    <SearchSelectFieldRHF
      label={t(label)}
      help={help && t(help)}
      placeholder={placeholder && t(placeholder)}
      serializeError={serialize}
      {...otherProps}
    />
  );
}
