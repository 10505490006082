import { TableSearchBaseFilters } from '../components/providers/TableSearchContextProvider';

import { SyncSearchParamsStore } from './SyncSearchParamsStore';

/**
 * base class defining a base shape data with searchTerm data attribute
 */
export abstract class SyncSearchParamsWithTermStore<
  Data extends TableSearchBaseFilters,
> extends SyncSearchParamsStore<Data> {
  /**
   * helper for set searchTerm to data
   * @param searchTerm
   */
  public setSearchTerm = (searchTerm: string | null) => {
    searchTerm ||= null;

    this.newSnapshot();
    this.data.searchTerm = searchTerm;
    this.emitChanges();
  };
}
