import { useSearchParams } from 'react-router-dom';

import { useLocale } from '../components/providers/LocaleProvider.context';

import { Language } from '#gql';

export default function useLanguageParams(): Language {
  const [searchParams] = useSearchParams();
  const [locale] = useLocale();

  const language = searchParams.get('language') as Language | undefined;

  return language || locale;
}
