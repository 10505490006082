import FormattedSearchSelectField from '../translation/FormattedSearchSelectField';

import { useSeizureAuthoritiesQuery } from '#gql';
import { customOptionsFilter, useSearchSelect } from '#tailwind_ui';
import { assert } from '#utils/assert';

interface SelectSeizureAuthorityProps {
  name: string;
}

export default function SelectSeizureAuthority(
  props: SelectSeizureAuthorityProps,
) {
  const { name } = props;

  const { data, error } = useSeizureAuthoritiesQuery({
    fetchPolicy: 'cache-first',
  });

  const filterOptions = customOptionsFilter<string>((option) => {
    const seizureAuthority = data?.seizureAuthorities.find(
      (element) => element.identifier === option,
    );
    assert(seizureAuthority);
    return seizureAuthority.name;
  });
  const hook = useSearchSelect({
    options:
      data?.seizureAuthorities.map(
        (seizureAuthority) => seizureAuthority.identifier,
      ) || [],
    filterOptions,
  });

  if (error) throw error;
  if (!data) return null;

  return (
    <FormattedSearchSelectField<string>
      name={name}
      clearable
      label="global.seizure_authority"
      renderOption={(value) =>
        data?.seizureAuthorities.find((element) => element.identifier === value)
          ?.name
      }
      {...hook}
    />
  );
}
