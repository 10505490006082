import { T } from '@tolgee/react';
import { Link } from 'react-router-dom';

import { requiredObject, requiredString } from '../../forms/validation';
import { getUserNameWithService } from '../../helpers/user';
import { useNavigateAfterSave } from '../../hooks/useNavigateAfterSave';
import { FormPreventNavigation } from '../form/FormPreventNavigation';
import RemoveInList from '../form/RemoveInList';
import SelectCountry from '../form/SelectCountry';
import { SelectDocumentType, SelectFraudType } from '../form/SelectFields';
import SelectUsers from '../form/SelectUsers';
import FormattedButton from '../translation/FormattedButton';
import FormattedFormError from '../translation/FormattedFormError';
import FormattedRichTextField from '../translation/FormattedRichTextField';
import FormattedSubmitButton from '../translation/FormattedSubmitButton';

import EditableSerieContainer from './EditableSerieContainer';
import { SerieHeadingBreadcrumb } from './SerieHeading';

import {
  GqlUpdateSerieInput,
  GqlSerieEditableQuery,
  useUpdateSerieMutation,
} from '#gql';
import { SimpleStringSelectOption, useOnOff } from '#tailwind_ui';
import { CardLayout } from '#ui/card_layout';
import { PageLayout, PageLayoutNavigation } from '#ui/page_layout';

const validationSchema = requiredObject({
  docType: requiredString,
  docCountry: requiredString,
  docFraudType: requiredString,
});

interface EditSerieInnerProps {
  serie: GqlSerieEditableQuery['serie'];
}

type EditSerieValues = Omit<GqlUpdateSerieInput, 'managers'> & {
  managers: SimpleStringSelectOption[];
};

const FORM_ACTIONS = (
  <>
    <FormattedButton
      as={Link}
      to="./.."
      variant="white"
      messageId="global.cancel"
    />
    <FormattedSubmitButton messageId="global.save" color="primary" />
  </>
);

function EditSerieInner(props: EditSerieInnerProps) {
  const { serie } = props;
  const {
    id,
    seqId,
    docCountry,
    contextualProfile,
    docFraudType,
    docType,
    materialProfile,
    managers,
    aliases,
  } = serie;

  const defaultValues: EditSerieValues = {
    id,
    managers: managers.map((user) => ({
      value: user.id,
      label: getUserNameWithService(user),
    })),
    contextualProfile,
    docCountry,
    docFraudType,
    docType,
    materialProfile,
    aliases,
  };

  const [saved, setSaved] = useOnOff();
  useNavigateAfterSave(saved, `/series/${seqId}`);

  const [updateSerie] = useUpdateSerieMutation();

  async function onSubmit(values: EditSerieValues) {
    await updateSerie({
      variables: {
        input: {
          id,
          contextualProfile: values.contextualProfile,
          docCountry: values.docCountry,
          docFraudType: values.docFraudType,
          docType: values.docType,
          managers: values.managers?.map((el) => el.value),
          materialProfile: values.materialProfile,
          aliases: values.aliases,
        },
      },
    });

    setSaved();
  }

  return (
    <FormPreventNavigation
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      noDefaultStyle
    >
      <PageLayout
        title={<T keyName="global.edit" />}
        navigation={
          <>
            <PageLayoutNavigation to="/series">
              <T keyName="nav.series.list" />
            </PageLayoutNavigation>
            <PageLayoutNavigation to={`/series/${seqId}`}>
              <SerieHeadingBreadcrumb serie={serie} />
            </PageLayoutNavigation>
          </>
        }
        actions={FORM_ACTIONS}
        footer={FORM_ACTIONS}
        footerClassName="space-x-2"
      >
        <CardLayout
          title="page.series.view.general_information"
          bodyClassName="flex flex-col gap-5"
        >
          <RemoveInList name="aliases" label="series.field.aliases" />
          <SelectDocumentType name="docType" required />
          <SelectFraudType name="docFraudType" required />
          <SelectCountry name="docCountry" label="doc.field.country" required />
          <SelectUsers name="managers" label="series.field.managers" />
        </CardLayout>

        <CardLayout title="series.field.contextual_profile">
          <FormattedRichTextField
            name="contextualProfile"
            label="series.field.contextual_profile"
            hiddenLabel
          />
        </CardLayout>

        <CardLayout title="series.field.material_profile">
          <FormattedRichTextField
            name="materialProfile"
            label="series.field.material_profile"
            hiddenLabel
          />
        </CardLayout>

        <FormattedFormError />
      </PageLayout>
    </FormPreventNavigation>
  );
}

export default function EditSerie() {
  return (
    <EditableSerieContainer>
      {(serie) => <EditSerieInner serie={serie} />}
    </EditableSerieContainer>
  );
}
