import { LinkProps } from 'react-router-dom';

import { GqlDocumentIdentifierFieldsFragment } from '#gql';
import { HighlightedLink } from '#ui/link';
import { getDocumentTitle } from '#utils/index';

interface DocumentLinkInternalProps extends DocumentLinkProps {
  to: string;
}
function DocumentLinkInternal(props: DocumentLinkInternalProps) {
  const {
    document,
    multiline = false,
    includeService = false,
    to,
    state,
  } = props;

  const linkLabel = document.seqId;

  if (multiline) {
    return (
      <div className="leading-tight">
        <HighlightedLink to={to} state={state}>
          {linkLabel}
        </HighlightedLink>
        <br />
        <span className="text-xs text-neutral-500">
          {getDocumentTitle(document, includeService)}
        </span>
      </div>
    );
  }

  return (
    <HighlightedLink to={to} state={state}>
      {linkLabel}
    </HighlightedLink>
  );
}

interface DocumentLinkProps {
  document: GqlDocumentIdentifierFieldsFragment;
  multiline?: boolean;
  includeService?: boolean;
  state?: LinkProps['state'];
}

export default function DocumentLink(props: DocumentLinkProps) {
  return (
    <DocumentLinkInternal
      {...props}
      to={`/documents/${props.document.seqId}`}
    />
  );
}

export interface DocumentImageLinkProps extends DocumentLinkProps {
  imageId: string;
}

export function DocumentImageLink(props: DocumentImageLinkProps) {
  return (
    <DocumentLinkInternal
      {...props}
      to={`/documents/${props.document.seqId}/standardized-images/${props.imageId}`}
    />
  );
}
