import { T, useTranslate } from '@tolgee/react';

// eslint-disable-next-line no-restricted-imports
import { Pagination } from '#tailwind_ui';

interface FormattedPaginationProps {
  page: number;
  rowsPerPage: number;
  onChange: (page: number) => void;
  totalCount: number;
}

export default function FormattedPagination({
  page,
  rowsPerPage,
  totalCount,
  onChange,
}: FormattedPaginationProps) {
  const { t } = useTranslate();
  const totalPages = Math.ceil(totalCount / rowsPerPage);

  let currentPage = page;
  if (currentPage > totalPages) {
    currentPage = totalPages - 1;
  }

  if (totalPages <= 1) return null;

  return (
    <Pagination
      className="mt-5"
      itemsPerPage={rowsPerPage}
      page={currentPage}
      totalCount={totalCount}
      nextText={t('table.next')}
      previousText={t('table.previous')}
      onPageChange={onChange}
    />
  );
}

export interface FormattedPaginationTextProps {
  messageId: TranslationKey;
  page: number;
  rowsPerPage: number;
  total: number;
}

export function FormattedPaginationText(props: FormattedPaginationTextProps) {
  const first = (props.page - 1) * props.rowsPerPage + 1;
  const last = Math.min(props.page * props.rowsPerPage, props.total);
  const total = props.total;
  return (
    <span className="text-sm text-neutral-600">
      <T keyName={props.messageId} params={{ first, last, total }} />
    </span>
  );
}
