import { FetchResult } from '@apollo/client';
import { CSSProperties, useMemo, useState } from 'react';
import {
  RoiProvider,
  RoiProviderInitialConfig,
  useCommittedRois,
} from 'react-roi';
import { Link, useNavigate } from 'react-router-dom';

import { useIdParam } from '../../../hooks/useParams';
import { useRectoVerso } from '../../../hooks/useRectoVerso';
import FormattedButton from '../../translation/FormattedButton';
import FormattedErrorAlert from '../../translation/FormattedErrorAlert';

import { PadifDocumentation } from './components/PadifDocumentation';
import { PadifImage } from './components/PadifImage';
import { PadifInformation } from './components/PadifInformation';
import {
  ImageSize,
  NewRoiActions,
  NewRoiContext,
  newRoiContext,
  PADIF_COLORS,
  useNewRoiContext,
} from './utils';

import {
  GqlAddPadifRoiInput,
  DocumentView,
  GqlPadifAddRoiMutation,
  GqlPadifAnalysisQuery,
  usePadifAddRoiMutation,
  usePadifAnalysisQuery,
} from '#gql';
import { assertNotNull } from '#utils/assert';

export interface RoiData {
  alreadyInDatabase: boolean;
  backgroundColor: CSSProperties['backgroundColor'];
}

export function PadifNewRoiPage() {
  const id = useIdParam();

  const { data: padifData, error: padifError } = usePadifAnalysisQuery({
    variables: { id },
  });

  if (padifError) throw padifError;
  if (!padifData) return null;

  return <NewRoiPage id={id} padif={padifData} />;
}

interface NewRoiPageProps {
  padif: GqlPadifAnalysisQuery;
  id: string;
}

function NewRoiPage(props: NewRoiPageProps) {
  const { padif, id } = props;

  assertNotNull(padif.padifAnalysis.referenceDocument);
  const { recto, verso } = useRectoVerso(padif.padifAnalysis.referenceDocument);
  const [padifAddRoiMutation, { error, loading }] = usePadifAddRoiMutation();

  const [view, setView] = useState<DocumentView>(() => {
    return recto && verso ? 'RECTO' : ((recto || verso)?.view as DocumentView);
  });

  const [imageSize, setImageSize] = useState<ImageSize | null>(null);

  const contextValue = useMemo<[NewRoiContext, NewRoiActions]>(() => {
    return [
      {
        analysis: padif.padifAnalysis,
        padifId: id,
        view,
        imageSize,
      },
      {
        setView,
        setImageSize,
      },
    ];
  }, [padif, id, view, imageSize]);

  const initialRoiProviderConfig = useMemo<
    RoiProviderInitialConfig<RoiData>
  >(() => {
    if (!imageSize) return { rois: [] };

    const rois = padif.padifAnalysis.rois.filter((roi) => roi.view === view);
    const newRoiId = crypto.randomUUID();

    return {
      mode: 'select',
      selectedRoiId: newRoiId,
      rois: [
        ...rois.map((roi, index) => ({
          id: roi.id,
          height: roi.height,
          width: roi.width,
          x: roi.x,
          y: roi.y,
          label: `ROI ${index + 1}`,
          angle: 0,
          data: {
            alreadyInDatabase: true,
            backgroundColor: PADIF_COLORS.readonly,
          },
        })),
        {
          id: newRoiId,
          x: imageSize.width * 0.4,
          y: imageSize.height * 0.4,
          width: imageSize.width * 0.2,
          height: imageSize.height * 0.2,
          angle: 0,
          data: {
            alreadyInDatabase: false,
            backgroundColor: PADIF_COLORS.editable,
          },
        },
      ],
    };
  }, [imageSize, padif, view]);

  return (
    <newRoiContext.Provider value={contextValue}>
      <RoiProvider
        key={`${imageSize?.width}-${imageSize?.height}-${view}`}
        initialConfig={initialRoiProviderConfig}
      >
        <div className="flex flex-col gap-5">
          <div className="flex flex-1 flex-row items-baseline justify-between">
            <PadifInformation />
            <Actions
              isLoading={loading}
              onSave={(input) => padifAddRoiMutation({ variables: { input } })}
            />
          </div>

          <FormattedErrorAlert error={error} />

          <div className="grid grid-cols-5 grid-rows-1 gap-5">
            <div className="col-span-2">
              <PadifDocumentation />
            </div>
            <div className="col-span-3">
              <PadifImage />
            </div>
          </div>
        </div>
      </RoiProvider>
    </newRoiContext.Provider>
  );
}

interface ActionsProps {
  isLoading: boolean;
  onSave: (
    inputs: GqlAddPadifRoiInput,
  ) => Promise<FetchResult<GqlPadifAddRoiMutation>>;
}

function Actions(props: ActionsProps) {
  const { onSave, isLoading } = props;
  const [{ padifId, view, imageSize }] = useNewRoiContext();
  const committedRois = useCommittedRois<RoiData>();
  const navigate = useNavigate();

  const roi = committedRois.find((e) => e.data?.alreadyInDatabase === false);

  return (
    <div className="flex flex-1 justify-end gap-3">
      <FormattedButton
        as={Link}
        to={`/padif/${padifId}`}
        messageId="global.cancel"
        variant="white"
      />

      <FormattedButton
        disabled={isLoading}
        onClick={() => {
          if (!roi || !imageSize) {
            return;
          }

          void onSave({
            id: padifId,
            view,
            x: Math.floor(roi.x),
            height: Math.floor(roi.height),
            width: Math.floor(roi.width),
            y: Math.floor(roi.y),
          }).then(() => navigate(`/padif/${padifId}`));
        }}
        messageId="global.save"
      />
    </div>
  );
}
