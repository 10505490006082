import { useTranslate } from '@tolgee/react';

import { FieldLabel, FieldLabelProps } from '#tailwind_ui';

interface FormattedLabelProps extends Omit<FieldLabelProps, 'text'> {
  text: TranslationKey;
}

export default function FormattedLabel(props: FormattedLabelProps) {
  const { text, ...otherProps } = props;
  const { t } = useTranslate();

  return <FieldLabel text={t(text)} {...otherProps} />;
}
