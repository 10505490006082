import { FormatIcu } from '@tolgee/format-icu';
import {
  DevTools,
  LanguageDetector,
  LanguageStorage,
  Tolgee,
  TolgeeOptions,
  TolgeeProvider,
  useTolgee,
} from '@tolgee/react';
// @ts-expect-error Type confusion with react-datepicker.
import type { Locale as DateFnsLocale } from 'date-fns/types';
import { ReactNode, useEffect } from 'react';
import { registerLocale, setDefaultLocale } from 'react-datepicker';

import { Locale } from '#gql';

export default function TolgeeLocaleProvider({
  children,
}: {
  children: ReactNode;
}) {
  const commonTolgeeOptions: TolgeeOptions = {
    // https://tolgee.io/js-sdk/api/core_package/options#fallbacklanguage
    fallbackLanguage: 'en',
    defaultLanguage: 'en',
    availableLanguages: Object.values(Locale),
    ns: ['', 'alert'],
  };
  const tolgeeOptions: TolgeeOptions = import.meta.env.DEV
    ? {
        ...commonTolgeeOptions,
        apiUrl: import.meta.env.VITE_TOLGEE_API_URL,
        apiKey: import.meta.env.VITE_TOLGEE_API_KEY,
        projectId: import.meta.env.VITE_TOLGEE_PROJECT_ID,
      }
    : {
        ...commonTolgeeOptions,
        staticData: {
          en: () => import('../translations/en.json'),
          fr: () => import('../translations/fr.json'),
          de: () => import('../translations/de.json'),
          nl: () => import('../translations/nl.json'),
          it: () => import('../translations/it.json'),
          'en:alert': () => import('../translations/alert/en.json'),
          'fr:alert': () => import('../translations/alert/fr.json'),
          'de:alert': () => import('../translations/alert/de.json'),
          'nl:alert': () => import('../translations/alert/nl.json'),
          'it:alert': () => import('../translations/alert/it.json'),
        },
      };

  /* eslint-disable new-cap */
  const tolgee = Tolgee()
    .use(LanguageStorage())
    .use(LanguageDetector())
    .use(DevTools())
    .use(FormatIcu())
    .init(tolgeeOptions);
  /* eslint-enable new-cap */

  return (
    <TolgeeProvider tolgee={tolgee} fallback={'Loading tolgee translations…'}>
      <TolgeeGlueIntl />
      {children}
    </TolgeeProvider>
  );
}

const DATE_FNS_LOCALES: Record<Locale, () => Promise<DateFnsLocale>> = {
  en: () => import('date-fns/locale/en-GB').then((l) => l.enGB),
  fr: () => import('date-fns/locale/fr').then((l) => l.fr),
  de: () => import('date-fns/locale/de').then((l) => l.de),
  nl: () => import('date-fns/locale/nl').then((l) => l.nl),
};

/**
 * registerLocale and setDefaultLocale for react-datepicker
 */
function TolgeeGlueIntl() {
  const tolgee = useTolgee(['language']);
  const currentLang = tolgee.getLanguage() as Locale | undefined;

  useEffect(() => {
    if (!currentLang) return;

    let canceled = false;
    function cancel() {
      canceled = true;
    }

    DATE_FNS_LOCALES[currentLang]()
      .then((dateLocale) => {
        if (canceled) return;

        registerLocale(currentLang, dateLocale);
        setDefaultLocale(currentLang);
      })
      .catch(reportError);

    return cancel;
  }, [currentLang]);

  return null;
}
