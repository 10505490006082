import { T } from '@tolgee/react';
import { useState } from 'react';

import { useTolgeeCacheLoad } from '../../../hooks/useTolgeeCacheLoad';
import { useLocale } from '../../providers/LocaleProvider.context';
import AlertDraftEditFields from '../draft/AlertDraftEditFields';
import AlertBreadcrumbs, {
  AlertBreadcrumbsTitle,
} from '../header/AlertBreadcrumbs';
import { LanguageSelect } from '../header/AlertLanguageSelect';
import { useAlertDraftParams } from '../hooks/useAlertParams';
import { getLanguageKey } from '../utils/languages';

import {
  GqlAlertDraftAllLanguagesQuery,
  Language,
  useAlertDraftAllLanguagesQuery,
} from '#gql';
import { FormattedAlert } from '#intl';
import { Select } from '#tailwind_ui';
import { H1, H3 } from '#ui/heading';
import { PageLayout, PageLayoutNavigation } from '#ui/page_layout';
import { assert } from '#utils/assert';

export default function EditAlertDraft() {
  const { id, language } = useAlertDraftParams();

  const { data, error } = useAlertDraftAllLanguagesQuery({
    variables: {
      id,
    },
  });

  useTolgeeCacheLoad(language, 'alert');

  if (error) throw error;

  if (!data) return null;

  if (!data.alert.draft) {
    return <FormattedAlert type="error" title="series.alert.draft.notfound" />;
  }

  return (
    <EditAlertDraftContent
      key={language}
      alert={data.alert}
      preferredLanguage={language}
    />
  );
}

function EditAlertDraftContent({
  alert,
  preferredLanguage,
}: {
  alert: GqlAlertDraftAllLanguagesQuery['alert'];
  preferredLanguage: Language;
}) {
  const draft = alert.draft;
  assert(draft);

  const [locale] = useLocale();

  const language = chooseLanguage(draft.languages, preferredLanguage);

  const compareLanguageOptions = draft.languages.filter(
    (lang) => lang !== language,
  );

  const [compareLanguage, setCompareLanguage] = useState<Language | undefined>(
    () => chooseLanguage(compareLanguageOptions, locale),
  );

  useTolgeeCacheLoad(compareLanguage, 'alert');
  const translation = draft.translations.find(
    (translation) => translation.language === language,
  );

  assert(translation);

  if (draft.languages.length < 2) {
    return (
      <PageLayout
        title={<T keyName="global.edit" />}
        navigation={
          <>
            <AlertBreadcrumbs alert={alert} language={translation.language} />
            <PageLayoutNavigation
              to={`/alerts/${alert.id}/draft?language=${translation.language}`}
            >
              <AlertBreadcrumbsTitle alert={alert} translation={translation} />
            </PageLayoutNavigation>
          </>
        }
      >
        <div className="flex items-center justify-between">
          <H1>
            <T keyName="series.alert.actions.edit_draft_fields.title" />{' '}
            {translation.language}
          </H1>
        </div>

        <AlertDraftEditFields
          alertId={alert.id}
          draftId={draft.id}
          translation={translation}
        />
      </PageLayout>
    );
  }

  const compareTranslation = draft.translations.find(
    (translation) => translation.language === compareLanguage,
  );
  assert(compareTranslation, 'no compare language');

  return (
    <PageLayout
      title={<T keyName="global.edit" />}
      navigation={
        <>
          <AlertBreadcrumbs alert={alert} language={translation.language} />
          <PageLayoutNavigation
            to={`/alerts/${alert.id}/draft?language=${translation.language}`}
          >
            <AlertBreadcrumbsTitle alert={alert} translation={translation} />
          </PageLayoutNavigation>
        </>
      }
      bodyClassName="grid grid-flow-dense grid-cols-2 gap-x-10 gap-y-2"
      noDefaultBodyClassName
    >
      <div className="flex items-center justify-between">
        <H3>
          <T keyName="series.alert.actions.edit_draft_fields.title" />
        </H3>
        <LanguageSelect
          languages={draft.languages}
          selectedLanguage={translation.language}
        />
      </div>

      <div className="flex items-center justify-between">
        <H3>
          <T keyName="series.alert.actions.compare_with" />
        </H3>

        <Select<string>
          label="Language"
          hiddenLabel
          options={compareLanguageOptions}
          renderOption={(option) => (
            <T keyName={getLanguageKey(option as Language)} />
          )}
          onChange={(language) => {
            if (!language) return;
            setCompareLanguage(language as Language);
          }}
          selected={compareLanguage}
        />
      </div>

      <AlertDraftEditFields
        alertId={alert.id}
        draftId={draft.id}
        translation={translation}
        fieldClassName="col-start-1"
      />

      <AlertDraftEditFields
        alertId={alert.id}
        draftId={draft.id}
        translation={compareTranslation}
        fieldClassName="col-start-2"
        readOnly
      />
    </PageLayout>
  );
}

function chooseLanguage(languageList: Language[], preferredLanguage: Language) {
  return languageList.includes(preferredLanguage)
    ? preferredLanguage
    : languageList.includes('en')
      ? 'en'
      : languageList[0];
}
