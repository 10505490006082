import { TableSearchBaseFilters } from '../../components/providers/TableSearchContextProvider';
import { SyncSearchParamsStoreSupportedTypes } from '../SyncSearchParamsStore';
import { SyncSearchParamsWithTermStore } from '../SyncSearchParamsWithTermStore';

export interface PadifFilters extends TableSearchBaseFilters {
  documentSets: string[];
  referenceDocuments: Array<string | null>;
  active: boolean | null;
}

export class PadifFiltersStore extends SyncSearchParamsWithTermStore<PadifFilters> {
  public get shapeDef(): Record<
    keyof PadifFilters,
    SyncSearchParamsStoreSupportedTypes
  > {
    return {
      searchTerm: 'string',
      active: 'boolean',
      referenceDocuments: 'string[]',
      documentSets: 'string[]',
    };
  }

  public override get initialData(): PadifFilters {
    return {
      searchTerm: '',
      active: null,
      documentSets: [],
      referenceDocuments: [],
    };
  }

  public setDocumentSets = (documentSets: string[]) => {
    documentSets ??= [];

    this.mergeData({ documentSets });
  };

  public setDocuments = (referenceDocuments: string[]) => {
    this.mergeData({
      referenceDocuments: referenceDocuments?.map((d) => d || null) ?? [],
    });
  };
}
