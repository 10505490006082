import { BitDepth, ImageColorModel } from 'image-js';
import { CommittedRoi } from 'react-roi';

import { assert } from '#utils/assert';

export interface WorkerSuccessResponse {
  type: 'success';
  data: WorkerImageData;
}

export interface WorkerErrorResponse {
  type: 'error';
  error: string;
}

export type WorkerResponse = WorkerSuccessResponse | WorkerErrorResponse;

interface WorkerImageData {
  buffer: ArrayBufferLike;
  byteLength: number;
  width: number;
  height: number;
  bitDepth: BitDepth;
  colorModel: ImageColorModel;
}

export interface ImageJobData {
  image: WorkerImageData;
}

export interface CropJobData extends ImageJobData {
  region: CommittedRoi;
}

export function getImageData(
  img: Extract<HTMLImageElement, Record<'width' | 'height', number>>,
): WorkerImageData {
  const canvas = document.createElement('canvas');
  canvas.width = img.width;
  canvas.height = img.height;
  const ctx = canvas.getContext('2d');
  assert(ctx);
  ctx.drawImage(img, 0, 0);
  const imageData = ctx.getImageData(0, 0, img.width, img.height);
  return {
    buffer: imageData.data.buffer,
    byteLength: imageData.data.byteLength,
    bitDepth: 8,
    height: img.height,
    width: img.width,
    colorModel: 'RGBA',
  };
}
