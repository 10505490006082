import { T } from '@tolgee/react';
import { useBoolean } from 'usehooks-ts';

import MRZLinesCompare, { MRZLinesCompareProps } from '../../MRZLinesCompare';

import MRZIsValid from './MRZIsValid';
import { MRZUserConfirmation } from './MRZUserConfirmation';
import { MRZWithCannotParseFallback } from './MRZWithCannotParseFallback';

import { LinkLike } from '#ui/link';

export interface MRZDisplayResultProps extends MRZLinesCompareProps {
  mustBeReviewed?: boolean;
}

export default function MRZDisplayResult(props: MRZDisplayResultProps) {
  const { value: showMore, toggle: toggleShowMore } = useBoolean(false);
  const hasMrzImage = !!props.mrzLines;
  return (
    <div className="flex flex-col items-start gap-4">
      {props.mustBeReviewed && (
        <div className="flex-1">
          <MRZUserConfirmation />
        </div>
      )}
      {hasMrzImage && (
        <MRZLinesCompare mrzLines={props.mrzLines} mrz={props.mrz} />
      )}
      <div className="flex items-center gap-2">
        <MRZIsValid mrz={props.mrz} />
        <LinkLike onClick={toggleShowMore}>
          {showMore ? (
            <T keyName="scans.import_data.mrz.is_valid_show_less" />
          ) : (
            <T keyName="scans.import_data.mrz.is_valid_show_more" />
          )}
        </LinkLike>
      </div>

      {showMore && (
        <MRZWithCannotParseFallback
          mrz={props.mrz}
          displayMRZWithFallback={!hasMrzImage}
        />
      )}
    </div>
  );
}
