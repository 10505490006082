import {
  ChevronDoubleRightIcon,
  MinusIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';
import { Link } from 'react-router-dom';
import { match } from 'ts-pattern';

import {
  FixableImportDataStep,
  ImportDataStep,
} from '../../../../components/scans/importData/utils/types';
import FormattedButton from '../../../../components/translation/FormattedButton';
import {
  safeTranslationKeyScanFeedStep,
  safeTranslationKeyScanFeedStepButton,
} from '../../../../translationsMapping/scanFeedStep';
import { ScanImportSwitchTab } from '../../../../translationsMapping/scanSwitchTab';

import { useScanIdContext } from './contexts/scan_id_context';
import {
  getSwitchStep,
  useSwitchTabContext,
} from './contexts/switch_tab_context';
import { ScanImportDataStep } from './contexts/switch_tab_context_provider';

import { ScanProcessStatus } from '#gql';
import { scanImportSetTab } from '#scan_import';
import { Color, Feed, FeedItem } from '#tailwind_ui';

interface ReviewFeedProps {
  step: ImportDataStep;
  status: ScanProcessStatus;
}

const steps: FixableImportDataStep[] = [
  'ProcessExtract',
  'ProcessRotate',
  'ProcessMRZ',
  'ProcessFindRegions',
];

const links: Record<ImportDataStep, string | null> = {
  ProcessAnalyze: null,
  ProcessExtract: './extract-document',
  ProcessRotate: './rotate',
  ProcessMRZ: './mrz',
  ProcessFindRegions: './anonymization',
  ProcessReview: null,
  ProcessFinalize: null,
};

function getSteps(
  step: ImportDataStep,
): [FixableImportDataStep[], FixableImportDataStep[]] {
  if (step === 'ProcessAnalyze') {
    return [[], steps];
  }
  if (step === 'ProcessReview' || step === 'ProcessFinalize') {
    return [steps, []];
  }

  const index = steps.indexOf(step);
  const finishedSteps = steps.slice(0, index);
  const remainingSteps = steps.slice(index + 1, steps.length);

  return [finishedSteps, remainingSteps];
}

export function ReviewFeed(props: ReviewFeedProps) {
  const { step, status } = props;

  const scanImportContext = useSwitchTabContext();
  const [finishedSteps, remainingSteps] = getSteps(step);

  const result = finishedSteps.map((fixableStep, index) => {
    return (
      <FeedItem
        key={fixableStep}
        iconBackgroundColor={Color.warning}
        icon={<QuestionMarkCircleIcon />}
        title={
          <div
            className="hover:cursor-pointer hover:text-primary-500"
            onClick={() => {
              scanImportContext.setTab(
                getSwitchStep(fixableStep, scanImportContext.hasMrz),
              );
            }}
          >
            <div className="flex gap-1">
              <span>{index + 1}.</span>
              <T
                keyName={safeTranslationKeyScanFeedStep(fixableStep, 'DONE')}
              />
            </div>
          </div>
        }
        description={
          <FixableStepButtonLink currentStep={step} fixableStep={fixableStep} />
        }
      />
    );
  });

  if (step !== 'ProcessReview' && step !== 'ProcessFinalize') {
    result.push(
      <FeedItem
        key={step}
        iconBackgroundColor={
          status === 'PENDING' ? Color.neutral : Color.primary
        }
        title={
          <T
            keyName={safeTranslationKeyScanFeedStep(
              step,
              status === 'PENDING' ? 'IDLE' : 'PERFORMING',
            )}
          />
        }
        icon={status === 'PENDING' ? <MinusIcon /> : <ChevronDoubleRightIcon />}
      />,
    );
  }

  const feedSteps = [
    ...result,
    ...remainingSteps.map((value) => (
      <Feed.Item
        key={value}
        iconBackgroundColor={Color.neutral}
        icon={<MinusIcon />}
        title={<T keyName={safeTranslationKeyScanFeedStep(value, 'IDLE')} />}
      />
    )),
  ];

  return (
    <div className="flex flex-col gap-5">
      <p className="italic text-neutral-800">
        <T keyName="scan.step.review.feed.description" />
      </p>
      <Feed>{feedSteps}</Feed>
    </div>
  );
}

function FixableStepButtonLink(props: {
  currentStep: ImportDataStep;
  fixableStep: FixableImportDataStep;
}) {
  const switchTab = useSwitchTabContext();
  const scanId = useScanIdContext();

  const link = links[props.fixableStep];
  const messageId = safeTranslationKeyScanFeedStepButton(
    `${props.fixableStep}Button`,
  );

  if (props.currentStep === 'ProcessFinalize') {
    return <FormattedButton disabled variant="white" messageId={messageId} />;
  } else if (link) {
    return (
      <FormattedButton
        className="inline-block"
        as={Link}
        variant="white"
        messageId={messageId}
        to={link}
        onClick={() => {
          const tab = match<ScanImportDataStep, ScanImportSwitchTab | null>(
            props.fixableStep,
          )
            .with('ProcessExtract', () => 'extraction')
            .with('ProcessRotate', () => 'rotation')
            .with('ProcessMRZ', () => (switchTab.hasMrz ? 'mrz' : null))
            .with('ProcessFindRegions', () => 'anonymization')
            .otherwise(() => null);
          if (!tab) return;

          scanImportSetTab(scanId, tab);
        }}
      />
    );
  }

  return null;
}
