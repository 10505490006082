import { T } from '@tolgee/react';

import { useDocumentsContext } from '../../contexts/documents';
import { useSyncTableSearchParams } from '../../hooks/useSyncTableSearchParams';
import { useTableSelection } from '../../hooks/useTableSelection';
import { IndexFilterError } from '../TableSearch/IndexFilterError';
import { TableSearchLayout } from '../TableSearch/TableSearchLayout';
import FormattedButton from '../translation/FormattedButton';

import DocumentListTable from './DocumentListTable';
import DocumentSearchFilter from './DocumentSearchFilter';
import { DocumentListQueryVariables } from './documentListLocationState';

import { useBatchDocumentsOperationMutation, useDocumentsQuery } from '#gql';
import { ModalLabelsPicker } from '#label';
import { PageLayout } from '#ui/page_layout';

export default function DocumentsIndexPage() {
  return (
    <PageLayout title={<T keyName="nav.documents.list" />}>
      <DocumentTableSearch />
    </PageLayout>
  );
}

function DocumentTableSearch() {
  const ctx = useDocumentsContext();
  const { filters, store, pagination, sort } = ctx;

  const documentsQueryVariables: DocumentListQueryVariables = {
    filterBy: filters,
    ...pagination,
    sortBy: sort,
  };

  const queryResult = useDocumentsQuery({
    variables: documentsQueryVariables,
  });

  const data = queryResult.data || queryResult.previousData;
  const [batchDocumentsOperation] = useBatchDocumentsOperationMutation();

  useSyncTableSearchParams(ctx);

  const documentSelection = useTableSelection<string>();

  if (queryResult.error) {
    return <IndexFilterError error={queryResult.error} context={ctx} />;
  }
  if (!data) return null;

  async function batchAddLabels(labels: Set<string>) {
    await batchDocumentsOperation({
      variables: {
        input: {
          documents: Array.from(documentSelection.value),
          labels: Array.from(labels),
          operation: 'add',
        },
      },
    });
    await queryResult.refetch();
  }

  async function batchRemoveLabels(labels: Set<string>) {
    await batchDocumentsOperation({
      variables: {
        input: {
          documents: Array.from(documentSelection.value),
          labels: Array.from(labels),
          operation: 'remove',
        },
      },
    });
    await queryResult.refetch();
  }

  const { nodes, totalCount, facets } = data.documents;

  return (
    <TableSearchLayout
      context={ctx}
      advancedFilter={
        <DocumentSearchFilter facets={store.getCorrectedFacets(facets)} />
      }
      searchBarExtra={
        <>
          <FormattedButton
            messageId="global.deselect_all"
            variant="secondary"
            onClick={documentSelection.methods.clear}
            disabled={documentSelection.value.size === 0}
          />
          <div className="flex-1" />
          <ModalLabelsPicker
            label="document.list.action.apply_labels"
            disabled={documentSelection.value.size === 0}
            badgeValue={documentSelection.value.size}
            modalHeader="document.list.modal_label_batch.title"
            onBatchAdd={batchAddLabels}
            onBatchRemove={batchRemoveLabels}
          />
        </>
      }
    >
      <DocumentListTable
        data={nodes}
        totalCount={totalCount}
        selection={documentSelection}
        documentsQueryVariables={documentsQueryVariables}
      />
    </TableSearchLayout>
  );
}
