import { T } from '@tolgee/react';
import { useMemo } from 'react';

// eslint-disable-next-line no-restricted-imports
import { SwitchTabs, SwitchTabsItems, SwitchTabsProps } from '#tailwind_ui';

export interface FormattedSwitchTabsItems
  extends Omit<SwitchTabsItems, 'title'> {
  title: TranslationKey;
}

interface FormattedSwitchTabsProps extends Omit<SwitchTabsProps, 'tabs'> {
  tabs: FormattedSwitchTabsItems[];
}

export function FormattedSwitchTabs(props: FormattedSwitchTabsProps) {
  const { tabs, ...otherProps } = props;

  const formattedTabs = useMemo<SwitchTabsItems[]>(() => {
    return tabs.map((tab) => ({ ...tab, title: <T keyName={tab.title} /> }));
  }, [tabs]);

  return <SwitchTabs tabs={formattedTabs} {...otherProps} />;
}
