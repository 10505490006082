import { T, useTranslate } from '@tolgee/react';
import { useWatch } from 'react-hook-form';

import { Badge, FieldLabel, useCheckedFormRHFContext } from '#tailwind_ui';

export default function RemoveInList(props: {
  name: string;
  label: TranslationKey;
  required?: boolean;
}) {
  const { t } = useTranslate();
  const { name, label, required } = props;
  const {
    setValue,
    formState: { isSubmitted },
  } = useCheckedFormRHFContext();
  const values = useWatch({ name }) as string[] | undefined;
  const translatedLabel = t(label);
  const valuesArr = values || [];

  return (
    <div>
      <FieldLabel text={translatedLabel} required={required} />
      <div>
        {valuesArr.map((value) => (
          <Badge
            key={value}
            className="mr-2 inline-block"
            color="primary"
            label={value}
            variant="COLORED_BACKGROUND"
            onDismiss={() => {
              setValue(
                name,
                valuesArr.filter((v) => v !== value),
                {
                  shouldTouch: true,
                  shouldValidate: isSubmitted,
                  shouldDirty: true,
                },
              );
            }}
          />
        ))}
        {valuesArr.length === 0 && (
          <div className="text-sm italic text-neutral-600">
            <T keyName="page.series.edit.noaliases" />
          </div>
        )}
      </div>
    </div>
  );
}
