import { T } from '@tolgee/react';

import FormattedButton from '../translation/FormattedButton';

import { GqlFile, useDeleteSerieAttachmentMutation } from '#gql';
import { Color, DialogBody, DialogFooter, DialogTitle } from '#tailwind_ui';

type ImageFile = Pick<GqlFile, 'id' | 'filename' | 'url'>;
interface DeleteSerieImageProps {
  serieId: string;
  image: ImageFile;
  onCancel: () => void;
}

export default function DeleteSerieImage(props: DeleteSerieImageProps) {
  const { serieId, image, onCancel } = props;

  const [deleteSerieAttachment] = useDeleteSerieAttachmentMutation();
  const deleteAttachment = () =>
    deleteSerieAttachment({
      variables: { input: { id: serieId, attachmentId: image.id } },
    }).then(onCancel);

  return (
    <>
      <DialogTitle>
        <T keyName="page.series.delete_image.title" />
      </DialogTitle>
      <DialogBody className="flex max-w-lg flex-col gap-5">
        <T keyName="page.series.delete_image.question" params={image} />
        <img src={image.url} alt="Serie" className="max-w-lg" />
      </DialogBody>
      <DialogFooter>
        <FormattedButton
          messageId="global.cancel"
          variant="white"
          onClick={onCancel}
        />
        <FormattedButton
          messageId="global.delete"
          color={Color.danger}
          onClick={() => {
            void deleteAttachment();
          }}
        />
      </DialogFooter>
    </>
  );
}
