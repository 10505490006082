// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="../tolgee.d.ts" />

import bytesize from 'byte-size';
import { CheckCSS } from 'checkcss';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import '../../tailwind.css';
import App from './app';

bytesize.defaultOptions({
  units: 'metric_octet',
});

if (import.meta.env.DEV) {
  if (window.location.hostname === 'localhost') {
    // Redirect to 127.0.0.1. Session won't work on localhost.
    window.location.hostname = 'profid.localhost';
  }

  const checkCSS = new CheckCSS();
  checkCSS.onClassnameDetected = (className) => {
    return !/^(?:cls-|css-|react-datepicker|cropper-|point-|html2canvas-|_tolgee-highlighter)/.test(
      className,
    );
  };
  checkCSS.scan().watch();
}

if (typeof reportError === 'undefined') {
  // eslint-disable-next-line no-console
  globalThis.reportError = console.error;
}

const rootElement = document.querySelector('#root');
if (!rootElement) {
  throw new Error('Root element not found');
}

const root = createRoot(rootElement);
const router = createBrowserRouter([{ path: '*', element: <App /> }]);
root.render(<RouterProvider router={router} />);
