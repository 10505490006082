import { TranslateProps, useTranslate } from '@tolgee/react';
import { ReactNode } from 'react';

import { ConfirmDialog, ConfirmDialogProps } from '#tailwind_ui';

type TranslationValues = TranslateProps['params'];

export interface FormattedConfirmDialogProps
  extends Omit<
    ConfirmDialogProps,
    'title' | 'confirmText' | 'cancelText' | 'body' | 'children'
  > {
  title: TranslationKey;
  titleValues?: TranslationValues;

  cancelText?: TranslationKey;
  cancelTextValues?: TranslationValues;

  confirmText: TranslationKey;
  confirmTextValues?: TranslationValues;

  body?: TranslationKey | Exclude<ReactNode, string>;
  bodyValues?: TranslationValues;
}

export default function FormattedConfirmDialog(
  props: FormattedConfirmDialogProps,
) {
  const {
    body,
    bodyValues,
    title,
    titleValues,
    cancelText,
    cancelTextValues,
    confirmText,
    confirmTextValues,
  } = props;

  const { t } = useTranslate();

  return (
    <ConfirmDialog
      {...props}
      title={t(title, titleValues)}
      cancelText={cancelText && t(cancelText, cancelTextValues)}
      confirmText={confirmText && t(confirmText, confirmTextValues)}
    >
      {typeof body === 'string' ? t(body as TranslationKey, bodyValues) : body}
    </ConfirmDialog>
  );
}
