import { useWatch } from 'react-hook-form';

import FormattedCheckboxField from '../translation/FormattedCheckboxField';
import FormattedTextAreaField from '../translation/FormattedTextAreaField';

import TrueFalseUndefinedField from './TrueFalseUndefinedField';

export default function BitmapFields() {
  const hasBitmap = useWatch({
    name: 'docIsBitmapCode',
  });

  const isAnalyzed = useWatch({
    name: 'docIsBitmapCodeAnalyzed',
  });

  return (
    <div className="flex flex-col gap-3">
      <TrueFalseUndefinedField
        name="docIsBitmapCode"
        label="global.bitmap_code"
        trueLabel="global.present"
        falseLabel="global.absent"
      />

      {hasBitmap && (
        <FormattedCheckboxField
          name="docIsBitmapCodeAnalyzed"
          label="doc.field.doc_is_bitmap_code_analyzed"
        />
      )}
      {hasBitmap && isAnalyzed && (
        <FormattedTextAreaField
          name="docBitmapCodeAnalysis"
          label="doc.field.doc_bitmap_code_analysis"
          rows={3}
        />
      )}
    </div>
  );
}
