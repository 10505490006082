import { T } from '@tolgee/react';
import byteSize from 'byte-size';

import FormattedButton from '../translation/FormattedButton';

import { GqlFile, Maybe } from '#gql';
import { useOnOff } from '#tailwind_ui';
import { DownloadFileButton } from '#ui/button';
import { CardLayout } from '#ui/card_layout';
import {
  FormattedDefinitionList,
  FormattedDefinitionListProps,
} from '#ui/definition_list';
import { ObjectLinkFallback } from '#ui/link';

type FileProps = Pick<GqlFile, 'url' | 'filename' | 'size'>;

interface DocumentImageCardProps {
  title: TranslationKey;
  image: Maybe<FileProps>;
}
export function FullScanImageCard(props: DocumentImageCardProps) {
  const { title, image } = props;

  return (
    <CardLayout title={title}>
      {image ? (
        <FullScanImageCardContent image={image} />
      ) : (
        <span className="italic text-neutral-800">
          <T keyName="scan.view.original_scan.not_available" />
        </span>
      )}
    </CardLayout>
  );
}

function FullScanImageCardContent(props: { image: FileProps }) {
  const [isImageShown, showImage] = useOnOff(false);

  const {
    image: { filename, url, size },
  } = props;

  const formattedSize = byteSize(size).toString();
  const data: FormattedDefinitionListProps['data'] = {
    'global.file.name': filename,
    'global.file.size': formattedSize,
  };

  return (
    <div className="flex flex-col gap-6">
      {isImageShown && (
        <ObjectLinkFallback src={url} alt={`${filename} | ${formattedSize}`} />
      )}
      <FormattedDefinitionList data={data} />
      <div className="flex gap-2">
        {!isImageShown && (
          <FormattedButton
            messageId="scan.full_image.button.show"
            variant="white"
            onClick={showImage}
          />
        )}

        <DownloadFileButton href={url} />
      </div>
    </div>
  );
}
