import { T } from '@tolgee/react';
import { useEffect } from 'react';

import { getFormattedError } from '../error.helper';

import { useNotificationCenter } from '#tailwind_ui';

export default function useFormattedErrorNotificationEffect(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any,
) {
  const { addNotification } = useNotificationCenter();

  useEffect(() => {
    if (!error) {
      return;
    }
    const { title, titleId, details, detailsId, detailsValues } =
      getFormattedError(error);

    const notificationTitle = titleId ? <T keyName={titleId} /> : title;
    const notificationDetails = detailsId ? (
      <T keyName={detailsId} params={detailsValues} />
    ) : (
      details
    );

    addNotification({
      type: 'danger',
      title: notificationTitle,
      content: notificationDetails,
    });
  }, [error, addNotification]);
}
