import { TableSearchContextProvider } from '../components/providers/TableSearchContextProvider';
import { userContext } from '../contexts/user';
import { MandatoryChildren } from '../global';
import { UsePaginationTableInitial } from '../hooks/usePaginationTable';
import { SortTable } from '../hooks/useSortTable';
import {
  UserFilters,
  UserFiltersStore,
} from '../stores/searches/UserFiltersStore';

import { UserSortField } from '#gql';

const initialSort = {
  field: 'lastName',
  direction: 'ASC',
} as const satisfies SortTable<UserSortField>;

const initialPagination = {
  label: 'user.list.pagination',
} as const satisfies Partial<UsePaginationTableInitial>;

export function UserTableSearchProvider({ children }: MandatoryChildren) {
  return (
    <TableSearchContextProvider<
      UserSortField,
      UserFilters,
      typeof UserFiltersStore,
      UserFiltersStore
    >
      context={userContext}
      Store={UserFiltersStore}
      initialSort={initialSort}
      initialPagination={initialPagination}
    >
      {children}
    </TableSearchContextProvider>
  );
}
