import { ScanRoiAnonymizationGroupOptions } from './group_options';
import {
  PersonalDataOption,
  personalDataTypeOptions,
} from './utils/personal_data';

interface LeftToolbarProps {
  selectedOption: PersonalDataOption;
  setSelectedOption: (option: PersonalDataOption) => void;
}

export function LeftToolbar(props: LeftToolbarProps) {
  const { selectedOption, setSelectedOption } = props;

  return (
    <div className="flex min-w-[100px] flex-col gap-5">
      <ScanRoiAnonymizationGroupOptions
        options={personalDataTypeOptions}
        checked={selectedOption}
        onChange={setSelectedOption}
      />
    </div>
  );
}
