import { ReactNode } from 'react';

import { Feature } from '../constants';

import { useAppMeta } from './providers/AppMetaProvider.context';

export default function IfHasFeature({
  feature,
  children,
}: {
  feature: Feature;
  children: ReactNode;
}) {
  const { features } = useAppMeta();
  return features.includes(feature) ? <>{children}</> : null;
}
