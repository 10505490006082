import { T } from '@tolgee/react';

import DocumentLink from '../../../../../components/document/DocumentLink';
import SeriesLink from '../../../../../components/serie/SeriesLink';
import FormattedRelativeDate from '../../../../../components/translation/FormattedRelativeDate';

import { GqlStatsQuery } from '#gql';
import { assert } from '#utils/assert';

export default function LastSeriesDocuments({
  series,
}: {
  series: GqlStatsQuery['series']['nodes'];
}) {
  return (
    <div className="space-y-2">
      <div className="text-center font-bold">
        <T keyName="home.stats.last_series_documents.title" />
      </div>

      <table>
        <tbody>
          {series.map((serie) => {
            assert(serie.lastDocument?.seriesUpdatedAt);
            return (
              <tr key={serie.id}>
                <td className="px-2">
                  <div>
                    <SeriesLink series={serie} />
                    {serie.documents.totalCount > 0
                      ? ` (${serie.documents.totalCount})`
                      : null}
                  </div>
                </td>
                <td className="px-2">
                  <div>
                    <DocumentLink document={serie.lastDocument} />
                  </div>
                </td>
                <td className="px-2 text-end text-neutral-500">
                  <FormattedRelativeDate
                    value={serie.lastDocument.seriesUpdatedAt}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
