export default function HalfFullCircle(props: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      className={props.className}
    >
      <g>
        <path
          d="M8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2ZM8 4C10.2081 4.00248 11.9975 5.79189 12 8H4C4.00248 5.79189 5.79189 4.00248 8 4Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
