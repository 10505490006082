import { T } from '@tolgee/react';

import { GqlDetailedDocumentFieldsFragment } from '#gql';
import { HighlightedLink } from '#ui/link';

export default function PadifLink({
  padifAnalysis,
}: {
  padifAnalysis: GqlDetailedDocumentFieldsFragment['padifAnalyses'][number];
}) {
  return (
    <div>
      <HighlightedLink to={`/padif/${padifAnalysis.id}/report`}>
        <T keyName="global.analysis" />
        {` ${padifAnalysis.id} `}
      </HighlightedLink>
      <T keyName="padif.from_set" />
      {' "'}
      <HighlightedLink to={`/document-sets/${padifAnalysis.documentSet.id}`}>
        {padifAnalysis.documentSet.name}
      </HighlightedLink>
      {'"'}
    </div>
  );
}
