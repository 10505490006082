import { TranslationKey } from '@tolgee/react';

import { BoolStr, NullStr, safeMappedTranslationKey } from './common';

import {
  DocumentExtraImageLight,
  DocumentMedium,
  DocumentType,
  DocumentView,
  FraudType,
  Offense,
  PrintProcess,
  SeizureContext,
} from '#gql';

export const TRANSLATIONS_KEY_DOCUMENT_FRAUD_TYPE = {
  CONTENT_FORGERY: 'doc.field.fraud_type.CONTENT_FORGERY',
  COUNTERFEIT: 'doc.field.fraud_type.COUNTERFEIT',
  FANTASY: 'doc.field.fraud_type.FANTASY',
  GENUINE: 'doc.field.fraud_type.GENUINE',
  STOLEN_BLANK: 'doc.field.fraud_type.STOLEN_BLANK',
  UNKNOWN: 'doc.field.fraud_type.UNKNOWN',
} as const satisfies Record<FraudType, TranslationKey>;

export function safeTranslationKeyDocumentFraud(
  index: FraudType | null | undefined,
) {
  return safeMappedTranslationKey(TRANSLATIONS_KEY_DOCUMENT_FRAUD_TYPE, index);
}

export const TRANSLATIONS_KEY_DOCUMENT_TYPE = {
  DRIVING_LICENSE: 'doc.field.type.DRIVING_LICENSE',
  IDENTITY_DOCUMENT: 'doc.field.type.IDENTITY_DOCUMENT',
  OTHER: 'doc.field.type.OTHER',
  PASSPORT: 'doc.field.type.PASSPORT',
  RESIDENCE_DOCUMENT: 'doc.field.type.RESIDENCE_DOCUMENT',
  STAMP: 'doc.field.type.STAMP',
  TRAVEL_DOCUMENT_NON_NATIONALS: 'doc.field.type.TRAVEL_DOCUMENT_NON_NATIONALS',
  VEHICLE_REGISTRATION: 'doc.field.type.VEHICLE_REGISTRATION',
  VISA: 'doc.field.type.VISA',
} as const satisfies Record<DocumentType, TranslationKey>;

export function safeTranslationKeyDocumentType(
  index: DocumentType | null | undefined,
) {
  return safeMappedTranslationKey(TRANSLATIONS_KEY_DOCUMENT_TYPE, index);
}

export const TRANSLATIONS_KEY_DOCUMENT_VIEW_TYPE = {
  OTHER: 'doc.document_view.OTHER',
  RECTO: 'doc.document_view.RECTO',
  VERSO: 'doc.document_view.VERSO',
  null: 'doc.document_view.null',
} as const satisfies Record<DocumentView | NullStr, TranslationKey>;

export function safeTranslationKeyDocumentView(
  index: DocumentView | null | undefined,
) {
  return safeMappedTranslationKey(TRANSLATIONS_KEY_DOCUMENT_VIEW_TYPE, index);
}

export const TRANSLATIONS_KEY_DOCUMENT_VIEW_IS_BITMAP = {
  true: 'page.doc.view.is_bitmap_code.true',
  false: 'page.doc.view.is_bitmap_code.false',
} as const satisfies Record<BoolStr, TranslationKey>;

export function safeTranslationKeyDocumentViewBitmap(
  index: boolean | null | undefined,
) {
  return safeMappedTranslationKey(
    TRANSLATIONS_KEY_DOCUMENT_VIEW_IS_BITMAP,
    index,
  );
}

export const TRANSLATIONS_KEY_DOCUMENT_IS_FLUO = {
  true: 'doc.field.is_background_fluo.true',
  false: 'doc.field.is_background_fluo.false',
} as const satisfies Record<BoolStr, TranslationKey>;

export function safeTranslationKeyDocumentFluo(
  index: boolean | null | undefined,
) {
  return safeMappedTranslationKey(TRANSLATIONS_KEY_DOCUMENT_IS_FLUO, index);
}

export const TRANSLATIONS_KEY_DOCUMENT_IS_MRZ = {
  true: 'doc.field.is_mrz_ok.true',
  false: 'doc.field.is_mrz_ok.false',
} as const satisfies Record<BoolStr, TranslationKey>;

export function safeTranslationKeyDocumentMRZ(
  index: boolean | null | undefined,
) {
  return safeMappedTranslationKey(TRANSLATIONS_KEY_DOCUMENT_IS_MRZ, index);
}

export const TRANSLATIONS_KEY_DOCUMENT_MEDIUM = {
  CARDBOARD: 'doc.field.medium.CARDBOARD',
  FABRIC: 'doc.field.medium.FABRIC',
  NEOBON: 'doc.field.medium.NEOBON',
  PAPER: 'doc.field.medium.PAPER',
  PLASTIC: 'doc.field.medium.PLASTIC',
  POLYCARBONATE: 'doc.field.medium.POLYCARBONATE',
} as const satisfies Record<DocumentMedium, TranslationKey>;

export function safeTranslationKeyDocumentMedium(
  index: DocumentMedium | null | undefined,
) {
  return safeMappedTranslationKey(TRANSLATIONS_KEY_DOCUMENT_MEDIUM, index);
}

export const TRANSLATIONS_KEY_DOCUMENT_PRINT_PROCESS = {
  HANDWRITTEN: 'doc.print_process.HANDWRITTEN',
  INKJET_PRINTING: 'doc.print_process.INKJET_PRINTING',
  INTAGLIO_PRINTING: 'doc.print_process.INTAGLIO_PRINTING',
  LASER_ENGRAVING: 'doc.print_process.LASER_ENGRAVING',
  LASER_PRINTING: 'doc.print_process.LASER_PRINTING',
  LETTERPRESS_PRINTING: 'doc.print_process.LETTERPRESS_PRINTING',
  OFFSET_PRINTING: 'doc.print_process.OFFSET_PRINTING',
  OTHER: 'doc.print_process.OTHER',
  PHOTO_PROCESS: 'doc.print_process.PHOTO_PROCESS',
  SCREEN_TYPING: 'doc.print_process.SCREEN_TYPING',
  THERMAL_DYE_SUBLIMATION: 'doc.print_process.THERMAL_DYE_SUBLIMATION',
  THERMAL_TRANSFER_PRINTING: 'doc.print_process.THERMAL_TRANSFER_PRINTING',
  TYPEWRITER: 'doc.print_process.TYPEWRITER',
} as const satisfies Record<PrintProcess, TranslationKey>;

export function safeTranslationKeyDocumentPrintProcess(
  index: PrintProcess | null | undefined,
) {
  return safeMappedTranslationKey(
    TRANSLATIONS_KEY_DOCUMENT_PRINT_PROCESS,
    index,
  );
}

export const TRANSLATIONS_KEY_DOCUMENT_RELATED_OFFENSE = {
  COUNTERFEITING_MEANS_OF_PAYMENT:
    'doc.field.related_offense.COUNTERFEITING_MEANS_OF_PAYMENT',
  COUNTERFEIT_ACTIVITY: 'doc.field.related_offense.COUNTERFEIT_ACTIVITY',
  DRUGS: 'doc.field.related_offense.DRUGS',
  IDENTITY_THEFT: 'doc.field.related_offense.IDENTITY_THEFT',
  IMMIGRATION: 'doc.field.related_offense.IMMIGRATION',
  LAUNDERING: 'doc.field.related_offense.LAUNDERING',
  MONEY_COUNTERFEITING: 'doc.field.related_offense.MONEY_COUNTERFEITING',
  ORGANIZED_CRIME: 'doc.field.related_offense.ORGANIZED_CRIME',
  OTHER: 'doc.field.related_offense.OTHER',
  PROSTITUTION: 'doc.field.related_offense.PROSTITUTION',
  ROAD_TRAFFIC: 'doc.field.related_offense.ROAD_TRAFFIC',
  SKIMMING: 'doc.field.related_offense.SKIMMING',
  SMUGGLING_ACTIVITY: 'doc.field.related_offense.SMUGGLING_ACTIVITY',
  TERRORISM: 'doc.field.related_offense.TERRORISM',
  THEFT_AND_ROBBERY: 'doc.field.related_offense.THEFT_AND_ROBBERY',
  VEHICLE_TRAFFICKING: 'doc.field.related_offense.VEHICLE_TRAFFICKING',
  WELFARE_FRAUD: 'doc.field.related_offense.WELFARE_FRAUD',
} as const satisfies Record<Offense, TranslationKey>;

export function safeTranslationKeyDocumentRelatedOffense(
  index: Offense | null | undefined,
) {
  return safeMappedTranslationKey(
    TRANSLATIONS_KEY_DOCUMENT_RELATED_OFFENSE,
    index,
  );
}

export const TRANSLATIONS_KEY_DOCUMENT_SEIZURE_CONTEXT = {
  ADMINISTRATION: 'doc.field.seizure_context.ADMINISTRATION',
  CONTEXT: 'doc.field.seizure_context.CONTEXT',
  FINANCIAL_OR_MAILING_INST:
    'doc.field.seizure_context.FINANCIAL_OR_MAILING_INST',
  HIGHWAY: 'doc.field.seizure_context.HIGHWAY',
  INVESTIGATION: 'doc.field.seizure_context.INVESTIGATION',
  LAND_BORDER: 'doc.field.seizure_context.LAND_BORDER',
  MAIL_INTERCEPTION: 'doc.field.seizure_context.MAIL_INTERCEPTION',
  OTHER: 'doc.field.seizure_context.OTHER',
  PORT_OR_VESSEL: 'doc.field.seizure_context.PORT_OR_VESSEL',
  RETAIL_OR_AGENCY: 'doc.field.seizure_context.RETAIL_OR_AGENCY',
  ROAD_TRAFFIC_CONTROL: 'doc.field.seizure_context.ROAD_TRAFFIC_CONTROL',
  TRAIN: 'doc.field.seizure_context.TRAIN',
  WORKPLACE: 'doc.field.seizure_context.WORKPLACE',
} as const satisfies Record<SeizureContext, TranslationKey>;

export function safeTranslationKeyDocumentSeizureContext(
  index: SeizureContext | null | undefined,
) {
  return safeMappedTranslationKey(
    TRANSLATIONS_KEY_DOCUMENT_SEIZURE_CONTEXT,
    index,
  );
}

export const TRANSLATIONS_KEY_DOCUMENT_EXTRA_IMAGE_LIGHT = {
  COAXIAL: 'doc.extra_image.light.COAXIAL',
  IR: 'doc.extra_image.light.IR',
  NORMAL: 'doc.extra_image.light.NORMAL',
  OBLIQUE: 'doc.extra_image.light.OBLIQUE',
  TRANSMITTED: 'doc.extra_image.light.TRANSMITTED',
  UV: 'doc.extra_image.light.UV',
} as const satisfies Record<DocumentExtraImageLight, TranslationKey>;

export function safeTranslationKeyDocumentExtraImageLight(
  index: DocumentExtraImageLight | null | undefined,
) {
  return safeMappedTranslationKey(
    TRANSLATIONS_KEY_DOCUMENT_EXTRA_IMAGE_LIGHT,
    index,
  );
}

export const TRANSLATIONS_KEY_DOCUMENT_MRZ_IS_VALID = {
  true: 'global.complying',
  false: 'global.noncomplying',
} as const satisfies Record<'true' | 'false', TranslationKey>;

export function safeTranslationKeyDocMrzIsValid(
  index: boolean | null | undefined,
) {
  return safeMappedTranslationKey(
    TRANSLATIONS_KEY_DOCUMENT_MRZ_IS_VALID,
    index,
  );
}
