import { GqlSerieIdentifierFieldsFragment } from '#gql';
import { HighlightedLink } from '#ui/link';

interface SeriesLinkProps {
  series?: GqlSerieIdentifierFieldsFragment | null;
  multiline?: boolean;
  getLinkLabel?: (series: GqlSerieIdentifierFieldsFragment) => string;
}

function defaultGetLinkLabel(
  series: GqlSerieIdentifierFieldsFragment,
  multiline: boolean,
) {
  if (multiline) {
    return series.seqId;
  } else {
    return `${series.seqId} | ${series.name}`;
  }
}

export default function SeriesLink(props: SeriesLinkProps) {
  const {
    series,
    multiline = false,
    getLinkLabel = defaultGetLinkLabel,
  } = props;

  if (!series) {
    return <>{'-'}</>;
  }

  const linkLabel = getLinkLabel(series, multiline);

  if (multiline) {
    return (
      <div className="leading-tight">
        <HighlightedLink to={`/series/${series.seqId}`}>
          {linkLabel}
        </HighlightedLink>
        <br />
        <span className="text-xs text-neutral-500">{series.name}</span>
      </div>
    );
  }

  return (
    <HighlightedLink to={`/series/${series.seqId}`}>
      {linkLabel}
    </HighlightedLink>
  );
}
