import { UserRole } from '#gql';

/**
 * Complete URL of API in string, no end trailing slash
 */
export const apiUrl = (
  import.meta.env.VITE_API_URI.startsWith('/')
    ? new URL(import.meta.env.VITE_API_URI, window.location.href)
    : new URL(import.meta.env.VITE_API_URI)
).href.replace(/\/+$/, '');

export const baseUrl = window.location.origin + import.meta.env.BASE_URL;

export const userRoles = Object.values(UserRole);

export const Feature = {
  documents: 'documents',
  series: 'series',
  analysis: 'analysis',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Feature = keyof typeof Feature;

export const AppEnv = {
  production: 'production',
  test: 'test',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type AppEnv = keyof typeof AppEnv;

export type AppVariant = 'ch' | 'eu' | 'demo';
