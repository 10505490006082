import { useTranslate } from '@tolgee/react';
import { useRef } from 'react';
import { Link } from 'react-router-dom';

import IfHasFeature from '../../../components/IfHasFeature';
import IfHasPermission from '../../../components/permissions/IfHasPermission';
import { useAppMeta } from '../../../components/providers/AppMetaProvider.context';
import FormattedButton from '../../../components/translation/FormattedButton';
import { Feature } from '../../../constants';
import { useSyncDocumentTitle } from '../../../hooks/useSyncDocumentTitle';

import CheckHasSeizureAuthorities from './home_page/check_has_seizure_authorities';
import CheckHasServices from './home_page/check_has_services';
import HomeStats from './home_page/stats';
import { getDocStats, getSeriesStats } from './home_page/stats/get_stats';

import { useStatsQuery } from '#gql';
import { Variant } from '#tailwind_ui';
import { H2 } from '#ui/heading';

export default function HomePage() {
  const { t } = useTranslate();
  const { data, error } = useStatsQuery();
  const appMeta = useAppMeta();

  const refDocumentTitle = useRef<HTMLHeadingElement>(null);
  useSyncDocumentTitle(refDocumentTitle);

  if (error) throw error;
  if (!data) return null;

  const documentStats = getDocStats(data.documents, t);
  const seriesStats = getSeriesStats(data.series);
  return (
    <div className="space-y-2">
      <div className="mb-12">
        <H2 className="text-center" ref={refDocumentTitle}>
          {appMeta.titleLong}
        </H2>
      </div>
      <IfHasPermission userPermission="service_create">
        <CheckHasServices />
      </IfHasPermission>
      <IfHasFeature feature={Feature.documents}>
        <IfHasPermission userPermission="seizureAuthority_create">
          <CheckHasSeizureAuthorities />
        </IfHasPermission>
      </IfHasFeature>
      <div className="flex flex-row justify-center gap-5">
        <IfHasFeature feature={Feature.documents}>
          <FormattedButton
            as={Link}
            to="/documents"
            messageId="home.nav.documents"
            variant={Variant.white}
          />
          <IfHasPermission userPermission="document_create">
            <FormattedButton
              as={Link}
              to="/documents/new"
              messageId="home.nav.add_document"
              variant={Variant.white}
            />
          </IfHasPermission>
        </IfHasFeature>
        <IfHasFeature feature={Feature.series}>
          <FormattedButton
            as={Link}
            to="/series"
            messageId="home.nav.serie"
            variant={Variant.white}
          />
        </IfHasFeature>
      </div>
      <IfHasFeature feature={Feature.documents}>
        <IfHasFeature feature={Feature.series}>
          <HomeStats documentStats={documentStats} seriesStats={seriesStats} />
        </IfHasFeature>
      </IfHasFeature>
    </div>
  );
}
