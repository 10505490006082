import { useTranslate } from '@tolgee/react';

import { useFormattedSerializeError } from '../../hooks/useFormattedSerializeError';
import { useDateFormat } from '../providers/LocaleProvider.context';

// eslint-disable-next-line no-restricted-imports
import { DatePickerFieldProps, DatePickerFieldRHF } from '#tailwind_ui';

export interface FormattedDatePickerFieldProps
  extends Omit<DatePickerFieldProps, 'serializeError' | 'label' | 'help'> {
  label: TranslationKey;
  help?: TranslationKey;
}

export default function FormattedDatePickerField(
  props: FormattedDatePickerFieldProps,
) {
  const { t } = useTranslate();
  const serialize = useFormattedSerializeError();
  const dateFormat = useDateFormat();

  const { label, help, ...otherProps } = props;

  return (
    <DatePickerFieldRHF
      valueType="string"
      label={t(label)}
      help={help && t(help)}
      dateFormat={dateFormat}
      serializeError={serialize}
      {...otherProps}
    />
  );
}
