import { T } from '@tolgee/react';
import React, { ReactNode, useReducer } from 'react';
import { useBlocker } from 'react-router-dom';

import FormattedConfirmDialog from '../../components/translation/FormattedConfirmDialog';
import { usePreventNavigation } from '../../hooks/usePreventNavigation';
import { usePreventUnload } from '../../hooks/usePreventUnload';

import {
  PreventNavigationBlockerContext,
  usePreventNavigationBlockerContext,
} from './PreventNavigationBlockerContext';
import { PreventNavigationContext } from './PreventNavigationContext';
import {
  PreventNavigationShouldBlockContext,
  usePreventNavigationShouldBlockContext,
} from './PreventNavigationShouldBlockContext';
import { preventNavigationReducer, PreventNavigationState } from './reducer';

import { DialogBody, DialogRoot, LightBanner } from '#tailwind_ui';

const emptyList: PreventNavigationState = new Set();

/**
 * prevent beforeunload and react-router navigation with useBlocker
 *
 * Provide PreventNavigationContext to dispatch the PreventNavigation
 * Provide PreventNavigationBlockerContext to know the blocker state
 * Provide PreventNavigationShouldBlockContext a simple view of preventNavigationState
 *
 * mount PreventNavigationModal, display a nice ConfirmModal let the user choose if they accept losing pending data
 *
 * https://github.com/remix-run/react-router/blob/main/decisions/0001-use-blocker.md
 *
 * @param props
 */
export function PreventNavigationProvider(props: { children: ReactNode }) {
  const [preventNavigationState, dispatch] = useReducer(
    preventNavigationReducer,
    emptyList,
  );
  const shouldBlock = preventNavigationState.size > 0;
  const blocker = useBlocker(shouldBlock);

  usePreventUnload(shouldBlock);

  return (
    <PreventNavigationContext.Provider value={dispatch}>
      <PreventNavigationBlockerContext.Provider value={blocker}>
        <PreventNavigationShouldBlockContext.Provider value={shouldBlock}>
          <PreventNavigationModal>{props.children}</PreventNavigationModal>
        </PreventNavigationShouldBlockContext.Provider>
      </PreventNavigationBlockerContext.Provider>
    </PreventNavigationContext.Provider>
  );
}

function PreventNavigationModal({ children }: { children: ReactNode }) {
  const blocker = usePreventNavigationBlockerContext();

  return (
    <>
      {children}

      <FormattedConfirmDialog
        open={blocker.state === 'blocked'}
        title="global.prompt.title"
        confirmText="global.yes"
        cancelText="global.no"
        body="global.prompt.body"
        onConfirm={blocker.proceed ?? (() => void 0)}
        onCancel={blocker.reset ?? (() => void 0)}
      />

      <DialogRoot
        open={blocker.state === 'proceeding'}
        onOpenChange={() => void 0}
      >
        <DialogBody>
          <p>
            <T keyName="global.prompt.navigating" />
          </p>
        </DialogBody>
      </DialogRoot>
    </>
  );
}

/**
 * if when is set to true : signal to PreventNavigationProvider the need to lock navigation, false unlock the navigation
 * if prompt mount a LightBanner with prompt as description.
 * LightBanner is visible or invisible from PreventNavigationShouldBlockContext. visible/invisible switch don't produce layout resizing
 *
 * Typical use is to put it in a form, when props bound from isDirty form state
 * You can use the HOC front/src/components/form/FormPreventNavigation which do that for you
 * @param props
 */
export function PreventNavigation(props: { when: boolean; prompt?: string }) {
  const { when } = props;
  const shouldBlock = usePreventNavigationShouldBlockContext();

  usePreventNavigation(when);

  if (!props.prompt) return null;

  return (
    <div className={shouldBlock ? 'visible' : 'invisible'}>
      <LightBanner description={props.prompt} renderButton={() => null} />
    </div>
  );
}
