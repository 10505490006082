import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';

import { AsyncFormattedConfirmModal } from '../../AsyncFormattedConfirmModal';
import FormattedUserName from '../../translation/FormattedUserName';

import { GqlUsersQuery, useResetUserPasswordMutation } from '#gql';
import { Color, useNotificationCenter } from '#tailwind_ui';

function ResetUserPasswordConfirm({
  user,
  onCancel,
}: {
  user: GqlUsersQuery['users']['nodes'][number];
  onCancel: () => void;
}) {
  const { addNotification } = useNotificationCenter();
  const [resetUserPassword] = useResetUserPasswordMutation();

  return (
    <AsyncFormattedConfirmModal
      open
      onClose={onCancel}
      icon={<ArrowPathIcon />}
      color={Color.danger}
      confirmText="global.reset"
      cancelText="global.cancel"
      title="page.users.action.reset_password"
      body={
        <div>
          <FormattedUserName
            id="page.users.reset_password.dialog.1"
            values={user}
          />
          <br />
          <T keyName="page.users.reset_password.dialog.2" />
        </div>
      }
      onConfirm={() =>
        resetUserPassword({ variables: { input: { id: user.id } } }).then(
          () => {
            addNotification(
              {
                type: 'success',
                title: (
                  <T keyName="user.admin.actions.reset_password.notification.success.title" />
                ),
                content: (
                  <T
                    keyName="user.admin.actions.reset_password.notification.success.content"
                    params={{ name: user.name }}
                  />
                ),
              },
              0,
            );
            onCancel();
          },
        )
      }
    />
  );
}

export default ResetUserPasswordConfirm;
