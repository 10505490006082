import { T } from '@tolgee/react';
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { match } from 'ts-pattern';

import { useScanIdParam } from '../../../hooks/useParams';
import FormattedButton from '../../translation/FormattedButton';
import FormattedErrorAlert from '../../translation/FormattedErrorAlert';
import { FormattedSwitchTabs } from '../../translation/FormattedSwitchTabs';
import MRZLinesCompare from '../MRZLinesCompare';

import { MRZInput } from './mrz/MRZInput';
import { MRZWithCannotParseFallback } from './mrz/MRZWithCannotParseFallback';
import { MrzLineImage } from './types';
import { StateNotCompatibleFormattedAlert } from './utils/StateNotCompatibleFormattedAlert';

import { useScanQuery, useSetScanImportMrzMutation } from '#gql';
import { scanImportSetTab } from '#scan_import';
import { PageLayout, PageLayoutNavigation } from '#ui/page_layout';

type MRZActionTabsType = 'edit' | 'inspect' | 'compare';
interface MRZActionTab {
  type: MRZActionTabsType;
  title: TranslationKey;
}

const mrzActionTabs: MRZActionTab[] = [
  {
    type: 'edit',
    title: 'scans.import_data.mrz.tabs.edit',
  },
  {
    type: 'inspect',
    title: 'scans.import_data.mrz.tabs.inspect',
  },
  {
    type: 'compare',
    title: 'scans.import_data.mrz.tabs.compare',
  },
];

export function CorrectMRZPage() {
  const scanId = useScanIdParam();

  const { data, error } = useScanQuery({
    variables: { id: scanId },
  });

  if (error) {
    return <FormattedErrorAlert error={error} />;
  }
  if (!data?.scan) return null;

  const {
    scan: { importData, filename },
  } = data;

  if (
    !(
      importData?.__typename === 'ProcessFindRegions' ||
      importData?.__typename === 'ProcessReview'
    )
  ) {
    return <StateNotCompatibleFormattedAlert id={data.scan.id} />;
  }

  const lines = importData.mrz?.mrzLines || [];
  const mrz = importData.parameters.mrz || [];
  return (
    <PageLayout
      title={<T keyName="nav.scans.list.import.mrz" />}
      navigation={
        <>
          <PageLayoutNavigation to="/scans">
            <T keyName="nav.scans.list" />
          </PageLayoutNavigation>
          <PageLayoutNavigation to="./../">{filename}</PageLayoutNavigation>
        </>
      }
    >
      <DisplayMrzAndDocument
        mrz={mrz}
        lines={lines}
        imageUrl={importData.rotatedDocument.image.url}
        filename={importData.rotatedDocument.image.filename}
        scanId={scanId}
      />
    </PageLayout>
  );
}

interface DisplayMrzAndDocumentProps {
  scanId: string;
  lines: MrzLineImage[];
  mrz: string[];
  imageUrl: string;
  filename: string;
}

function DisplayMrzAndDocument(props: DisplayMrzAndDocumentProps) {
  const { imageUrl, lines, mrz, scanId, filename } = props;
  const navigate = useNavigate();
  const [setMrzScanImport] = useSetScanImportMrzMutation();
  const [isSaving, setIsSaving] = useState(false);
  const [editedMrz, setMrz] = useState(mrz);

  const onSave = useCallback(() => {
    setIsSaving(true);

    void setMrzScanImport({ variables: { id: scanId, mrz: editedMrz } })
      .then(() => {
        navigate('./../');
        if (!editedMrz.join('').trim()) return;

        scanImportSetTab(scanId, 'mrz');
      })
      .catch(() => {
        setIsSaving(false);
      });
  }, [editedMrz, navigate, scanId, setMrzScanImport]);

  return (
    <div className="flex flex-1 gap-5">
      <div
        // Basis is computed so that compare tab gets enough space to display without layout shift
        style={{
          flexBasis: Math.max(500, ...mrz.map((str) => str.length * 15)),
        }}
        className="flex flex-col gap-5"
      >
        <FormattedSwitchTabs
          tabs={mrzActionTabs.map((tab) => ({
            title: tab.title,
            content: (
              <div className="mt-5">
                <TabContent
                  type={tab.type}
                  mrz={editedMrz}
                  mrzLines={lines}
                  setMrz={setMrz}
                />
              </div>
            ),
            disabled:
              tab.type === 'compare' &&
              (lines.length === 0 ||
                (lines.length > 0 && editedMrz.length === 0)),
          }))}
        />

        <div className="flex flex-row gap-3">
          <FormattedButton
            as={Link}
            to="./../"
            messageId="global.back"
            color="neutral"
          />
          <FormattedButton
            disabled={isSaving}
            messageId="global.save"
            onClick={onSave}
          />
        </div>
      </div>

      <div className="hidden min-w-0 flex-grow basis-[320px] md:block">
        <img
          alt={filename}
          src={imageUrl}
          className="mx-auto max-h-[50vh] object-contain"
        />
      </div>
    </div>
  );
}

interface TabContentProps {
  type: MRZActionTabsType;
  mrz: string[];
  mrzLines: MrzLineImage[];
  setMrz: Dispatch<SetStateAction<string[]>>;
}
function TabContent(props: TabContentProps) {
  return match(props.type)
    .with('edit', () => (
      <MRZInput
        initialMrz={props.mrz}
        onChange={(value) => props.setMrz(value)}
        help="scans.import_data.mrz.input_help"
      />
    ))
    .with('inspect', () => (
      <MRZWithCannotParseFallback mrz={props.mrz} displayMRZWithFallback />
    ))
    .with('compare', () => (
      <MRZLinesCompare mrz={props.mrz} mrzLines={props.mrzLines} />
    ))
    .exhaustive();
}
