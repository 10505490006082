import { useMemo } from 'react';

import {
  CountriesList,
  useLocalizedCountryList,
} from '../../hooks/localizedCountries';
import FormattedSearchSelectField from '../translation/FormattedSearchSelectField';

import { customOptionsFilter, useSearchSelect } from '#tailwind_ui';
import { assert } from '#utils/assert';

export interface SelectCountryProps {
  name: string;
  label: TranslationKey;
  clearable?: boolean;
  required?: boolean;
  enumValues?: string[];
}

export default function SelectCountry(props: SelectCountryProps) {
  const { name, label, clearable, required, enumValues } = props;

  const countries = useLocalizedCountryList();

  const countryOptions = useMemo(
    () => mapCountries(countries, enumValues),
    [countries, enumValues],
  );

  const filterOptions = customOptionsFilter<string>((option) =>
    getCountryLabel(countryOptions, option),
  );
  const hook = useSearchSelect({
    options: countryOptions.map((element) => element.value),
    filterOptions,
  });

  return (
    <FormattedSearchSelectField
      {...hook}
      name={name}
      clearable={clearable}
      required={required}
      label={label}
      renderOption={(option: string) => getCountryLabel(countryOptions, option)}
    />
  );
}

function getCountryLabel(
  countries: Array<{ value: string; label: string }>,
  value: string,
) {
  if (countries.length === 0) return '';
  const country = countries.find((country) => country.value === value);
  assert(country);
  return `${country.value} - ${country.label}`;
}

function mapCountries(
  countries: CountriesList | null,
  authorizedCountries?: string[],
) {
  if (!countries) return [];

  if (authorizedCountries) {
    const set = new Set(authorizedCountries);
    const result: Array<{ value: string; label: string }> = [];

    for (const [code, name] of countries) {
      if (!set.has(code)) continue;
      result.push({ value: code, label: name });
    }

    return result;
  }

  return countries.map(([code, name]) => {
    return {
      value: code,
      label: name,
    };
  });
}
