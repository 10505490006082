import { useState } from 'react';

import { useSeriesSelectQuery } from '#gql';
import { SearchSelect } from '#tailwind_ui';

export interface SelectSerieOption {
  id: string;
  seqId: string;
  name: string;
}

export interface SelectSerieProps {
  selected?: SelectSerieOption;
  onSelect: (option?: SelectSerieOption) => void;
  excludeSeqId?: string;
  clearable?: boolean;
}

export default function SelectSerie(props: SelectSerieProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const { data, loading, error } = useSeriesSelectQuery({
    variables: { limit: 10, searchTerm },
  });
  if (error) {
    throw error;
  }
  const series =
    data?.series.nodes.filter((series) =>
      props.excludeSeqId ? series.seqId !== props.excludeSeqId : true,
    ) || [];
  return (
    <SearchSelect<SelectSerieOption>
      label="Select a series"
      hiddenLabel
      options={series}
      selected={props.selected}
      onChange={props.onSelect}
      clearable={props.clearable}
      onSearchChange={setSearchTerm}
      searchValue={searchTerm}
      loading={loading}
      getValue={(option) => option.id}
      renderSelectedOption={(option) => `${option.seqId} - ${option.name}`}
      renderOption={(option) => (
        <div className="leading-tight">
          {option.seqId}
          <br />
          <span className="text-sm">{option.name}</span>
        </div>
      )}
    />
  );
}
