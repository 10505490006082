import { useTranslate } from '@tolgee/react';

import { useFormattedSerializeError } from '../../hooks/useFormattedSerializeError';

// eslint-disable-next-line no-restricted-imports
import { TextAreaFieldRHF, TextAreaFieldRHFProps } from '#tailwind_ui';

export type FormattedTextAreaFieldProps = Omit<
  TextAreaFieldRHFProps,
  'serializeError' | 'label' | 'help' | 'placeholder'
> & {
  label: TranslationKey;
  help?: TranslationKey;
  placeholder?: TranslationKey;
};

export default function FormattedTextAreaField(
  props: FormattedTextAreaFieldProps,
) {
  const { t } = useTranslate();
  const serialize = useFormattedSerializeError();
  const { label, help, placeholder, ...otherProps } = props;

  return (
    <TextAreaFieldRHF
      label={t(label)}
      help={help && t(help)}
      placeholder={placeholder && t(placeholder)}
      serializeError={serialize}
      {...otherProps}
    />
  );
}
