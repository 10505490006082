import { TranslateParams, useTranslate } from '@tolgee/react';

import { useFormattedSerializeError } from '../../hooks/useFormattedSerializeError';

// eslint-disable-next-line no-restricted-imports
import { CheckboxFieldRHF, CheckboxFieldRHFProps } from '#tailwind_ui';

interface FormattedCheckboxFieldProps
  extends Omit<CheckboxFieldRHFProps, 'serializeError' | 'label' | 'help'> {
  label: TranslationKey;
  labelParams?: TranslateParams;
  help?: TranslationKey;
}

export default function FormattedCheckboxField(
  props: FormattedCheckboxFieldProps,
) {
  const { t } = useTranslate();
  const serialize = useFormattedSerializeError();
  const { label, labelParams, help, ...otherProps } = props;

  return (
    <CheckboxFieldRHF
      label={t({ key: label, params: labelParams })}
      help={help && t(help)}
      serializeError={serialize}
      {...otherProps}
    />
  );
}
