import { ApolloError, isApolloError } from '@apollo/client';
import clsx from 'clsx';
import { useMemo } from 'react';

// Alert title is already formatted.
// eslint-disable-next-line no-restricted-imports
import { Alert } from '#tailwind_ui';
import { H3, H4 } from '#ui/heading';

export default function DevErrorAlert({
  error,
  onDismiss,
  className,
  title,
  details,
}: {
  error: Error;
  onDismiss?: () => void;
  className?: string;
  title: string;
  details?: string;
}) {
  return (
    <Alert
      className={clsx(className, 'space-y-2')}
      type="error"
      title={title}
      onDismiss={onDismiss}
    >
      <div className="space-y-2">
        <div>{details}</div>
        {isApolloError(error) && (
          <details>
            <H3>GraphQL Errors:</H3>
            <ApolloGraphqlErrors error={error} />
          </details>
        )}
      </div>
    </Alert>
  );
}

function ApolloGraphqlErrors({ error }: { error: ApolloError }) {
  const networkError = useMemo(() => {
    if (!error.networkError) return;

    return JSON.stringify(error.networkError);
  }, [error.networkError]);

  return (
    <>
      {error.graphQLErrors.map((graphQLError, index) => {
        const code = graphQLError.extensions?.profid?.code as string | null;
        const path = graphQLError.path;
        const stacktrace = graphQLError.extensions?.stacktrace as
          | string[]
          | null;
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index}>
            <H4>{error.message}</H4>
            {code && <div>Code: {code}</div>}
            {path && <div>Path: {path.join(',')}</div>}
            {stacktrace && (
              <code className="whitespace-pre">{stacktrace.join('\n')}</code>
            )}
          </div>
        );
      })}
      {networkError && (
        <>
          <p>Network error</p>
          <pre className="whitespace-pre">
            <code className="font-mono">{networkError}</code>
          </pre>
        </>
      )}
    </>
  );
}
