import { scaleOrdinal } from 'd3-scale';
import { schemeTableau10, schemeSet3 } from 'd3-scale-chromatic';
import { AgglomerationMethod } from 'ml-hclust';
import { memo } from 'react';
import useResizeObserver from 'use-resize-observer';

import {
  PadifAnalysisResult,
  PadifAnalysisResultDocumentSeries,
} from '../padifGqlTypes';

import PadifClusteringDendrogram from './PadifClusteringDendrogram';
import PadifClusteringLegend from './PadifClusteringLegend';

const seriesColors = schemeTableau10.concat(schemeSet3);
const seriesBaseColorScale = scaleOrdinal(seriesColors);

function getLabelScale(series: readonly string[]) {
  const result: string[] = [];
  if (series.length > seriesColors.length) {
    for (let i = 0; i < series.length; i++) {
      result.push(` (${Number(i + 1)})`);
    }
  } else {
    result.push('');
  }
  return scaleOrdinal(result).domain(series);
}

export interface PadifClusteringProps {
  referenceId: string;
  documents: PadifAnalysisResult['documents'];
  distanceMatrix: PadifAnalysisResult['distanceMatrix'];
  clusteringMethod: AgglomerationMethod;
}

function PadifClusteringBase(props: PadifClusteringProps) {
  const { referenceId, documents, distanceMatrix, clusteringMethod } = props;
  const { ref: resizeRef, width } = useResizeObserver();
  const series = getUniqueSeries(documents);
  const seriesIds = series.map((s) => s.seqId);
  const seriesColorScale = seriesBaseColorScale.copy().domain(seriesIds);
  const seriesLabelScale = getLabelScale(seriesIds);

  return (
    <div className="mt-4 flex flex-row justify-center">
      <div className="mr-4 min-w-0 max-w-4xl flex-auto" ref={resizeRef}>
        {width && (
          <PadifClusteringDendrogram
            documents={documents}
            distanceMatrix={distanceMatrix}
            clusteringMethod={clusteringMethod}
            referenceId={referenceId}
            width={width}
            seriesColorScale={seriesColorScale}
            seriesLabelScale={seriesLabelScale}
          />
        )}
      </div>
      <div>
        <PadifClusteringLegend
          series={series}
          seriesColorScale={seriesColorScale}
          seriesLabelScale={seriesLabelScale}
        />
      </div>
    </div>
  );
}

const PadifClustering = memo(PadifClusteringBase);
export default PadifClustering;

function getUniqueSeries(documents: PadifAnalysisResult['documents']) {
  const series = new Set<string>();
  const seriesData: PadifAnalysisResultDocumentSeries[] = [];
  for (const document of documents) {
    if (document.series && !series.has(document.series.seqId)) {
      series.add(document.series.seqId);
      seriesData.push(document.series);
    }
  }
  return seriesData.sort((s1, s2) => s2.seqId.localeCompare(s1.seqId));
}
