import { DocumentView, GqlDocumentIdentifierFieldsFragment } from '#gql';

type ViewTypeQr = 1 | 2 | 3;
const VIEW_TYPE: Record<DocumentView, ViewTypeQr> = {
  [DocumentView.RECTO]: 1,
  [DocumentView.VERSO]: 2,
  [DocumentView.OTHER]: 3,
};
function fallbackError(view: DocumentView) {
  throw new Error(`Unexpected type: ${view}`);
}
function getType(view: DocumentView): ViewTypeQr {
  return VIEW_TYPE[view] ?? fallbackError(view);
}

export function getQrDataExhibit(
  document: GqlDocumentIdentifierFieldsFragment,
  view: DocumentView,
): string {
  return JSON.stringify({
    _: 'P2',
    c: document.caseService.identifier,
    n: document.caseNumber,
    p: document.caseExhibitNumber,
    v: getType(view), // scan type
    e: Date.now(), // epoch
  });
}
