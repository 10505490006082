import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { CheckBadgeIcon, XCircleIcon } from '@heroicons/react/24/solid';
import { T } from '@tolgee/react';
import { parse, ParseResult } from 'mrz';

import { Tooltip } from '#tailwind_ui';

export interface MRZIsValidProps {
  mrz: string[];
}

export default function MRZIsValid(props: MRZIsValidProps) {
  let parseResult: ParseResult | null = null;
  try {
    parseResult = parse(props.mrz);
  } catch {
    // fail silently
  }

  return (
    <div className="flex items-center gap-1">
      {parseResult?.valid ? (
        <>
          <CheckBadgeIcon className="h-6 w-6 text-success-500" />
          <T keyName="scan.import.mrz.is_valid" />
        </>
      ) : (
        <>
          <XCircleIcon className="h-6 w-6 text-danger-600" />
          <T keyName="scan.import.mrz.is_invalid" />
          <MRZValidityInfo />
        </>
      )}
    </div>
  );
}

function MRZValidityInfo() {
  return (
    <Tooltip
      delay={100}
      content={
        <div className="max-w-sm text-sm">
          <T keyName="scan.import.mrz.validity_info" />
        </div>
      }
    >
      <InformationCircleIcon className="h-6 w-6 text-neutral-900" />
    </Tooltip>
  );
}
