import { T } from '@tolgee/react';
import { useNavigate } from 'react-router-dom';

import { requiredObject, requiredString } from '../../../forms/validation';
import FormattedFormError from '../../translation/FormattedFormError';
import FormattedInputField from '../../translation/FormattedInputField';
import FormattedSubmitButton from '../../translation/FormattedSubmitButton';

import { useCreateSeizureAuthorityMutation } from '#gql';
import { FormattedAlert } from '#intl';
import { FormRHF } from '#tailwind_ui';
import { PageLayout, PageLayoutNavigation } from '#ui/page_layout';

const createSeizureAuthorityValidation = requiredObject({
  identifier: requiredString.matches(/^[A-Z]+(?:_[A-Z]+)*$/),
  name: requiredString,
});

const defaultValues = { identifier: '', name: '' };

const SUBMIT_BUTTON = (
  <FormattedSubmitButton color="primary" messageId="global.create" />
);

export default function AdminSeizureAuthorityCreate() {
  const [createSeizureAuthority] = useCreateSeizureAuthorityMutation();

  const navigate = useNavigate();

  async function onSubmit(values: typeof defaultValues) {
    await createSeizureAuthority({
      variables: { input: { ...values } },
    });

    navigate('/admin/seizure-authorities');
  }

  return (
    <FormRHF<typeof defaultValues>
      defaultValues={defaultValues}
      validationSchema={createSeizureAuthorityValidation}
      onSubmit={onSubmit}
      noDefaultStyle
    >
      <PageLayout
        title={<T keyName="admin.create_seizure_authority.title" />}
        navigation={
          <PageLayoutNavigation to="/admin/seizure-authorities">
            <T keyName="admin.seizure_authorities.title" />
          </PageLayoutNavigation>
        }
        actions={SUBMIT_BUTTON}
        footer={SUBMIT_BUTTON}
        bodyClassName="max-w-xl mx-auto"
        footerClassName="max-w-xl mx-auto"
      >
        <FormattedAlert
          type="warning"
          messageId="admin.create_seizure_authority.alert"
        />
        <FormattedInputField
          name="identifier"
          size={20}
          maxLength={20}
          label="admin.create_seizure_authority.field.identifier.label"
          help="admin.create_seizure_authority.field.identifier.help"
          placeholder="admin.create_seizure_authority.field.identifier.placeholder"
        />
        <FormattedInputField
          name="name"
          label="admin.create_seizure_authority.field.name.label"
          help="admin.create_seizure_authority.field.name.help"
          placeholder="admin.create_seizure_authority.field.name.placeholder"
        />
        <FormattedFormError />
      </PageLayout>
    </FormRHF>
  );
}
