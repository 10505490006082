import { TranslateOptions, useTranslate } from '@tolgee/react';

import { useFormattedSerializeError } from '../../hooks/useFormattedSerializeError';

import {
  // eslint-disable-next-line no-restricted-imports
  RichTextFieldRHF,
  RichTextFieldRHFProps,
  RichTextToolbarOptions,
} from '#tailwind_ui';

export interface FormattedRichTextFieldProps
  extends Omit<RichTextFieldRHFProps, 'serializeError' | 'label' | 'help'> {
  label: TranslationKey;
  labelOptions?: TranslateOptions;
  help?: TranslationKey;
}

const toolbarOptions: RichTextToolbarOptions = {
  insertImage: false,
  insertTable: false,
};

export default function FormattedRichTextField(
  props: FormattedRichTextFieldProps,
) {
  const { t } = useTranslate();
  const serialize = useFormattedSerializeError();
  const { label, help, labelOptions, ...otherProps } = props;

  return (
    <RichTextFieldRHF
      label={t(label, labelOptions)}
      help={help && t(help)}
      serializeError={serialize}
      toolbarOptions={toolbarOptions}
      {...otherProps}
    />
  );
}
