import { T } from '@tolgee/react';

import ScanTable from './ScanTable';

import { PageLayout } from '#ui/page_layout';

export default function ScansIndexPage() {
  return (
    <PageLayout title={<T keyName="scans.title" />}>
      <ScanTable />
    </PageLayout>
  );
}
