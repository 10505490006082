import { T } from '@tolgee/react';

import { MandatoryChildren } from '../../global';

import { H3 } from '#ui/heading';

export interface TableSearchFilterContainerProps extends MandatoryChildren {
  title?: TranslationKey;
}

export default function TableSearchFilterContainer(
  props: TableSearchFilterContainerProps,
) {
  const { title = 'doc.search.filter.title', children } = props;

  return (
    <div style={{ flex: '0 0 260px' }} className="mr-2 mt-2">
      <H3>
        <T keyName={title} />
      </H3>

      {children}
    </div>
  );
}
