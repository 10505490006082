import { ReactNode, useCallback, useMemo, useState } from 'react';

import { UseTableSelect } from '../../../../hooks/useTableSelection';
import { LabelBadge } from '../badge/badge';

import { GqlLabelsQuery, useLabelsQuery } from '#gql';
import { CheckboxMultiSearchSelect } from '#tailwind_ui';

type Label = GqlLabelsQuery['labels']['nodes'][number];

export interface LabelsPickerProps {
  selection: UseTableSelect<string>;
  label: ReactNode;
  id?: string;
}

export function LabelsPicker(props: LabelsPickerProps) {
  const queryResult = useLabelsQuery();
  const error = queryResult.error;
  const data = queryResult.data || queryResult.previousData;

  const [search, setSearch] = useState('');

  const { selection, id, label } = props;
  const selected = useMemo(
    () => Array.from(selection.value),
    [selection.value],
  );

  const [options, labelsMap] = useMemo(() => {
    const labelsMap = new Map<string, Label>();
    const normalizedSearch = search.toLowerCase().normalize();

    for (const label of data?.labels.nodes ?? []) {
      if (label.name.toLowerCase().normalize().includes(normalizedSearch)) {
        labelsMap.set(label.id, label);
      }
    }

    return [Array.from(labelsMap.keys()), labelsMap] as const;
  }, [data, search]);

  const renderOption = useCallback(
    (option: string) => {
      const label = labelsMap.get(option);
      if (!label) return null;

      return <LabelBadge label={label} />;
    },
    [labelsMap],
  );

  if (error) throw error;
  if (!data) return null;

  return (
    <CheckboxMultiSearchSelect
      id={id}
      name="labels"
      label={label}
      selected={selected}
      onChange={selection.methods.set}
      getValue={getValue}
      renderOption={renderOption}
      options={options}
      searchValue={search}
      onSearchChange={setSearch}
    />
  );
}

function getValue(option: string) {
  return option;
}
