import { T } from '@tolgee/react';
import { Link } from 'react-router-dom';

import FormattedButton from '../../translation/FormattedButton';

import AdminServicesTable from './AdminServicesTable';

import { FormattedAlert } from '#intl';
import { PageLayout } from '#ui/page_layout';

export default function AdminServicesIndexPage() {
  return (
    <PageLayout
      title={<T keyName="admin.services.title" />}
      actions={
        <FormattedButton
          as={Link}
          to="/admin/services/create"
          messageId="admin.create_service.title"
        />
      }
    >
      <FormattedAlert
        className="my-3"
        type="info"
        messageId="admin.services.instructions"
      />

      <AdminServicesTable />
    </PageLayout>
  );
}
