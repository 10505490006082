import { useIdParam } from '../../hooks/useParams';

import PadifShowInit from './PadifShowInit';
import PadifShowMain from './PadifShowMain';

import { usePadifAnalysisQuery } from '#gql';

export default function PadifShow() {
  const id = useIdParam();
  const { data, error } = usePadifAnalysisQuery({
    variables: { id },
  });

  if (error) throw error;
  if (!data) return null;
  const { padifAnalysis } = data;
  const hasRef = !!padifAnalysis.referenceDocument;

  return (
    <div>
      {hasRef ? (
        <PadifShowMain padif={padifAnalysis} />
      ) : (
        <PadifShowInit padif={padifAnalysis} />
      )}
    </div>
  );
}
