import { safeMappedTranslationKey } from './common';

import { ScanProcessStatus } from '#gql';

export const TRANSLATIONS_KEY_SCAN_STATUS = {
  ERROR: 'scan.status.ERROR',
  FINALIZING: 'scan.status.FINALIZING',
  IMPORTED: 'scan.status.IMPORTED',
  IMPORTING: 'scan.status.IMPORTING',
  PENDING: 'scan.status.PENDING',
  PROCESSING: 'scan.status.PROCESSING',
  TO_BE_REVIEWED: 'scan.status.TO_BE_REVIEWED',
} as const satisfies Record<ScanProcessStatus, TranslationKey>;

export function safeTranslationKeyScanStatus(
  index: ScanProcessStatus | null | undefined,
) {
  return safeMappedTranslationKey(TRANSLATIONS_KEY_SCAN_STATUS, index);
}
