import { TrashIcon } from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';

import MinimalPagination from '../../../../components/MinimalPagination';
import DocumentLink from '../../../../components/document/DocumentLink';
import IfHasPermission from '../../../../components/permissions/IfHasPermission';
import FormattedConfirmDialog from '../../../../components/translation/FormattedConfirmDialog';
import FormattedErrorAlert from '../../../../components/translation/FormattedErrorAlert';
import { usePaginationTable } from '../../../../hooks/usePaginationTable';

import {
  GqlLabelsQuery,
  useDeleteLabelMutation,
  useDocumentsQuery,
} from '#gql';
import { FormattedAlert } from '#intl';
import { IconButton } from '#tailwind_ui';

export interface DeleteDialogProps {
  label: GqlLabelsQuery['labels']['nodes'][number];
  onDelete: () => void;
}

export function DeleteDialog(props: DeleteDialogProps) {
  const { label, onDelete } = props;

  const [deleteMutation, deleteResult] = useDeleteLabelMutation({
    variables: {
      input: {
        id: label.id,
      },
    },
    onCompleted: onDelete,
  });

  function onConfirm() {
    void deleteMutation();
  }

  return (
    <IfHasPermission userPermission="label_delete">
      <FormattedConfirmDialog
        title="label.delete_modal.title"
        trigger={
          <IconButton
            size="5"
            icon={<TrashIcon className="h-5" />}
            tooltip={<T keyName="global.delete" />}
          />
        }
        size="medium"
        noCloseConfirm
        confirmText="global.delete"
        confirmProps={{ color: 'danger' }}
        onConfirm={onConfirm}
        body={
          <>
            {deleteResult.error && (
              <FormattedErrorAlert error={deleteResult.error} />
            )}
            <FormattedAlert
              type="warning"
              title="label.delete_modal.description"
            />
            <DocumentList label={label} />
          </>
        }
      />
    </IfHasPermission>
  );
}

function DocumentList(props: Pick<DeleteDialogProps, 'label'>) {
  const pagination = usePaginationTable({ page: 0, itemsPerPage: 10 });

  const query = useDocumentsQuery({
    variables: {
      ...pagination.pagination,
      filterBy: {
        labels: [props.label.id],
      },
    },
  });

  if (query.error) return <FormattedErrorAlert error={query.error} />;
  const data = query.data ?? query.previousData;
  const documents = data?.documents.nodes ?? [];
  const totalCount = data?.documents.totalCount ?? 0;

  return (
    <div className="mt-2 space-y-2">
      <div className="flex items-center gap-2">
        <p>
          <T
            keyName="label.delete_modal.documents_count"
            params={{ count: totalCount }}
          />
        </p>

        <MinimalPagination
          page={pagination.tablePagination.page}
          rowsPerPage={pagination.tablePagination.itemsPerPage}
          totalCount={totalCount}
          onChange={pagination.onPageChange}
        />
      </div>
      <ul className="space-y-1">
        {documents.map((document) => (
          <li key={document.id} className="[&>*]:flex [&>*]:gap-1">
            <DocumentLink document={document} includeService multiline />
          </li>
        ))}
      </ul>
    </div>
  );
}
