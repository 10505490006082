import { Dispatch } from 'react';

import { ActionType, assertUnreachable } from '#tailwind_ui';

export type PreventNavigationState = Set<string>;
export type PreventNavigationAction =
  | ActionType<'ADD', string>
  | ActionType<'REMOVE', string>;
export type DispatchPreventNavigationContext =
  Dispatch<PreventNavigationAction>;

export function preventNavigationReducer(
  state: PreventNavigationState,
  action: PreventNavigationAction,
): PreventNavigationState {
  switch (action.type) {
    case 'ADD': {
      const newState = new Set(state);
      newState.add(action.payload);
      return newState;
    }
    case 'REMOVE': {
      const newState = new Set(state);
      newState.delete(action.payload);
      return newState;
    }
    default: {
      assertUnreachable(action);
    }
  }
}
