import { TableSearchContextProvider } from '../components/providers/TableSearchContextProvider';
import { documentSetCandidateContext } from '../contexts/documentSetCandidate';
import { MandatoryChildren } from '../global';
import { UsePaginationTableInitial } from '../hooks/usePaginationTable';
import { SortTable } from '../hooks/useSortTable';
import {
  DocumentSetDocumentFilters,
  DocumentSetDocumentFiltersStore,
} from '../stores/searches/DocumentSetDocumentFiltersStore';

import { DocumentSortField } from '#gql';

const initialSort = {
  field: 'createdAt',
  direction: 'DESC',
} as const satisfies SortTable<DocumentSortField>;

const initialPagination: UsePaginationTableInitial = {
  page: 0,
  itemsPerPage: 6,
  label: 'doc_set.list.candidates.filter_info',
};

export function DocumentSetCandidateTableSearchProvider({
  children,
}: MandatoryChildren) {
  return (
    <TableSearchContextProvider<
      DocumentSortField,
      DocumentSetDocumentFilters,
      typeof DocumentSetDocumentFiltersStore,
      DocumentSetDocumentFiltersStore
    >
      context={documentSetCandidateContext}
      Store={DocumentSetDocumentFiltersStore}
      initialSort={initialSort}
      initialPagination={initialPagination}
    >
      {children}
    </TableSearchContextProvider>
  );
}
