import { endOfDay, startOfDay } from 'date-fns';

import { TableSearchBaseFilters } from '../../components/providers/TableSearchContextProvider';
import { SyncSearchParamsStoreSupportedTypes } from '../SyncSearchParamsStore';
import { SyncSearchParamsWithTermStore } from '../SyncSearchParamsWithTermStore';

export interface DocumentDocumentSetsFilters extends TableSearchBaseFilters {
  startDate: Date | null;
  endDate: Date | null;
}

export class DocumentDocumentSetsFiltersStore extends SyncSearchParamsWithTermStore<DocumentDocumentSetsFilters> {
  public get shapeDef() {
    return {
      searchTerm: 'string',
      startDate: 'date',
      endDate: 'date',
    } as const satisfies Record<
      keyof DocumentDocumentSetsFilters,
      SyncSearchParamsStoreSupportedTypes
    >;
  }

  public get initialData(): DocumentDocumentSetsFilters {
    return {
      searchTerm: null,
      startDate: null,
      endDate: null,
    };
  }

  public setStartDate = (startDate: Date | null) => {
    startDate ??= null;

    if (startDate) {
      startDate = startOfDay(startDate);
    }

    this.mergeData({ startDate });
  };

  public setEndDate = (endDate: Date | null) => {
    endDate ??= null;

    if (endDate) {
      endDate = endOfDay(endDate);
    }

    this.mergeData({ endDate });
  };

  // disable search param sync
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected override snapshotToSearchParams() {}

  public override syncUrlSearchParams = () => void 0;
}
