import { useTranslate } from '@tolgee/react';
import { createElement, useCallback, useMemo, useState } from 'react';

import { FormattedPaginationText } from '../components/translation/FormattedPagination';

import { PaginationProps } from '#tailwind_ui';

export interface TableSearchPagination
  extends Required<
    Pick<
      PaginationProps,
      'page' | 'onPageChange' | 'itemsPerPage' | 'renderText'
    >
  > {
  // t('table.next')
  nextText: string;
  // t('table.previous')
  previousText: string;
  withText: true;
}

export interface TableSearchGqlPagination {
  skip: number;
  limit: number;
}

export interface UsePaginationTableInitial {
  page: number;
  itemsPerPage: number;
  label: TranslationKey;
}
const defaultPagination: UsePaginationTableInitial = {
  page: 0,
  itemsPerPage: 10,
  label: 'global.pagination.render_text',
};

export interface UsePaginationTable {
  /** to spread into standard graphql variables */
  pagination: TableSearchGqlPagination;
  /** to spread into PaginationProps (Table component from tailwind-ui) */
  tablePagination: TableSearchPagination;
  /** stable callback (shortcut of tablePagination.onPageChange) */
  onPageChange: (page: number) => void;
}

/**
 * @param initialPagination
 */
export function usePaginationTable(
  initialPagination?: Partial<UsePaginationTableInitial>,
): UsePaginationTable {
  const { t } = useTranslate();

  const [basePagination, setBasePagination] =
    useState<UsePaginationTableInitial>(() => ({
      ...defaultPagination,
      ...initialPagination,
    }));

  const onPageChange = useCallback((page: number) => {
    setBasePagination((pagination) => {
      if (page === pagination.page) return pagination;
      return { ...pagination, page };
    });
  }, []);

  const pagination = useMemo<TableSearchGqlPagination>(
    () => ({
      skip: basePagination.page * basePagination.itemsPerPage,
      limit: basePagination.itemsPerPage,
    }),
    [basePagination.page, basePagination.itemsPerPage],
  );

  const tablePagination = useMemo<TableSearchPagination>(
    () => ({
      ...basePagination,
      onPageChange,
      nextText: t('table.next'),
      previousText: t('table.previous'),
      withText: true,
      renderText: (actualPage, totalPage, totalCount) =>
        createElement(FormattedPaginationText, {
          messageId: basePagination.label,
          page: actualPage,
          rowsPerPage: basePagination.itemsPerPage,
          total: totalCount,
        }),
    }),
    [basePagination, onPageChange, t],
  );

  return { pagination, tablePagination, onPageChange };
}
