import { ReactNode, useEffect, useState } from 'react';

import { apiClient } from '../../api';

import { AppMeta, appMetaContext } from './AppMetaProvider.context';

import { Button, ErrorPage } from '#tailwind_ui';

export function AppMetaProvider(props: { children: ReactNode }) {
  const [appMeta, setAppMeta] = useState<AppMeta | null>(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    apiClient
      .get('meta')
      .json<AppMeta>()
      .then((meta) => {
        document.title = meta.title;
        setAppMeta(meta);
      })
      .catch((error) => {
        reportError(error);
        setError(error);
      });
  }, []);

  if (error) {
    return (
      <ErrorPage
        title="Internal Server Error"
        subtitle="An error occurred. Please reload the page or retry accessing the application later. If it persists, contact an administrator."
        code={500}
      >
        <Button variant="primary" onClick={() => window.location.reload()}>
          Refresh
        </Button>
      </ErrorPage>
    );
  }

  if (!appMeta) return null;

  return (
    <appMetaContext.Provider value={appMeta}>
      {props.children}
    </appMetaContext.Provider>
  );
}
