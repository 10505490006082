import { T } from '@tolgee/react';
import { match } from 'ts-pattern';

import MRZDisplayResult from '../../../../components/scans/importData/mrz/MRZDisplayResult';
import {
  safeTranslationKeyScanSwitchTab,
  ScanImportSwitchTab,
} from '../../../../translationsMapping/scanSwitchTab';

import { AnonymizationTabContent } from './anonymization_tab_content';
import { useSwitchTabContext } from './contexts/switch_tab_context';
import { ScanImportDataStep } from './contexts/switch_tab_context_provider';

import { GqlScanQuery } from '#gql';
// complex usage with internal styling; does not fit well with Formatted variant
// eslint-disable-next-line no-restricted-imports
import { SwitchTabs, ZoomImage } from '#tailwind_ui';
import { assert } from '#utils/assert';

type Scan = GqlScanQuery['scan'];

function getSwitchTab(step: ScanImportDataStep) {
  return match<ScanImportDataStep, ScanImportSwitchTab | null>(step)
    .with('ProcessAnalyze', () => 'extraction')
    .with('ProcessExtract', () => 'extraction')
    .with('ProcessRotate', () => 'rotation')
    .with('ProcessMRZ', () => 'mrz')
    .with('ProcessFindRegions', () => 'anonymization')
    .with('ProcessReview', 'ProcessFinalize', () => null)
    .exhaustive();
}

const switchTabOrder: Record<ScanImportSwitchTab, number> = {
  raw: 0,
  extraction: 1,
  rotation: 2,
  mrz: 3,
  anonymization: 4,
};

const switchTabs = Object.keys(switchTabOrder) as Array<
  keyof typeof switchTabOrder
>;

interface ScanImportSwitchTabsProps {
  scan: Scan;
}

export function ScanImportSwitchTabs(props: ScanImportSwitchTabsProps) {
  const { scan } = props;
  assert(scan.importData);

  const scanImportContext = useSwitchTabContext();

  const step = scan.importData.__typename;

  function isSwitchDisabled(
    currentStep: ScanImportSwitchTab | null,
    tab: ScanImportSwitchTab,
  ) {
    if (scan.status === 'TO_BE_REVIEWED' || currentStep === null) {
      if (tab === 'mrz') {
        return !scanImportContext.hasMrz;
      }

      return false;
    }

    if (switchTabOrder[currentStep] > switchTabOrder.mrz && tab === 'mrz') {
      return !scanImportContext.hasMrz;
    }

    return switchTabOrder[currentStep] < switchTabOrder[tab] + 1;
  }

  const importSwitchTab = getSwitchTab(step);
  const tabs = switchTabs.map((tab, index) => ({
    title: (
      <div className="flex items-center gap-2">
        {index !== 0 && <span>{index}.</span>}
        <T keyName={safeTranslationKeyScanSwitchTab(tab)} />
      </div>
    ),
    id: tab,
    disabled: isSwitchDisabled(importSwitchTab, tab),
    content: <SwitchTabContent scan={scan} tab={tab} />,
  }));

  return (
    <SwitchTabs
      tabs={tabs}
      value={scanImportContext.tab}
      onValueChange={(value) =>
        scanImportContext.setTab(value as ScanImportSwitchTab)
      }
    />
  );
}

interface SwitchTabContentProps {
  scan: Scan;
  tab: ScanImportSwitchTab;
}

function SwitchTabContent(props: SwitchTabContentProps) {
  const { scan, tab } = props;
  const importData = scan.importData;
  assert(importData);

  return (
    <div className="flex h-full items-center justify-center py-5 [&>img]:max-h-[50vh] [&>img]:max-w-full">
      {match(tab)
        .with('raw', () => {
          if (importData.__typename === 'ProcessAnalyze') {
            return null;
          }
          return (
            <ZoomImage
              alt={importData.extractedDocument.scanJpg.filename}
              src={importData.extractedDocument.scanJpg.url}
            />
          );
        })
        .with('extraction', () => (
          <ExtractionTabContent importData={importData} />
        ))
        .with('rotation', () => <RotationTabContent importData={importData} />)
        .with('mrz', () => <MrzCompareTabContent importData={importData} />)
        .with('anonymization', () => (
          <AnonymizationTabContent importData={importData} />
        ))
        .exhaustive()}
    </div>
  );
}

function MrzCompareTabContent(props: ScanImportTabContentProps) {
  return match(props.importData)
    .with(
      { __typename: 'ProcessFindRegions' },
      { __typename: 'ProcessReview' },
      { __typename: 'ProcessFinalize' },
      (importData) => {
        return (
          <div className="flex-1 self-start">
            <MRZDisplayResult
              mrzLines={importData.mrz?.mrzLines}
              mrz={importData.parameters.mrz || []}
              mustBeReviewed={importData.mrz?.mustBeReviewed}
            />
          </div>
        );
      },
    )
    .otherwise(() => null);
}

export interface ScanImportTabContentProps {
  importData: GqlScanQuery['scan']['importData'];
}

function ExtractionTabContent(props: ScanImportTabContentProps) {
  return match(props.importData)
    .with(
      { __typename: 'ProcessRotate' },
      { __typename: 'ProcessMRZ' },
      { __typename: 'ProcessFindRegions' },
      { __typename: 'ProcessReview' },
      { __typename: 'ProcessFinalize' },
      (importData) => (
        <ZoomImage
          alt={importData.extractedDocument.image.id}
          src={importData.extractedDocument.image.url}
        />
      ),
    )
    .otherwise(() => null);
}

function RotationTabContent(props: ScanImportTabContentProps) {
  return match(props.importData)
    .with(
      { __typename: 'ProcessMRZ' },
      { __typename: 'ProcessFindRegions' },
      { __typename: 'ProcessReview' },
      { __typename: 'ProcessFinalize' },
      (importData) => (
        <ZoomImage
          alt={importData.rotatedDocument.image.id}
          src={importData.rotatedDocument.image.url}
        />
      ),
    )
    .otherwise(() => null);
}
