import { T } from '@tolgee/react';
import { useNavigate } from 'react-router-dom';

import {
  requiredCaseNumber,
  requiredObject,
  requiredString,
} from '../../forms/validation';
import SelectCountry from '../form/SelectCountry';
import { SelectDocumentType, SelectFraudType } from '../form/SelectFields';
import SelectService from '../form/SelectService';
import { useCheckedLoginState } from '../providers/LoginProvider.context';
import FormattedFormError from '../translation/FormattedFormError';
import FormattedInputField from '../translation/FormattedInputField';
import FormattedSubmitButton from '../translation/FormattedSubmitButton';

import { GqlNewDocumentInput, useCreateDocumentMutation } from '#gql';
import { FormRHF } from '#tailwind_ui';
import { PageLayout, PageLayoutNavigation } from '#ui/page_layout';
import { assert } from '#utils/assert';

const validationSchema = requiredObject({
  caseService: requiredString,
  caseNumber: requiredCaseNumber,
  caseExhibitNumber: requiredCaseNumber,
  docType: requiredString,
  docCountry: requiredString,
  docFraudType: requiredString,
});

function DocumentNew() {
  const { service } = useCheckedLoginState();
  const initialService = service?.identifier || '';
  const navigate = useNavigate();
  const [createDocument] = useCreateDocumentMutation();
  async function createNewDocument(values: GqlNewDocumentInput) {
    const { data } = await createDocument({
      variables: {
        newDocument: values,
      },
    });
    // We expect the previous call to throw if there is an error. Otherwise data is always defined.
    assert(data);
    navigate(`/documents/${data.createDocument.seqId}/qr`);
  }

  const submitButton = (
    <FormattedSubmitButton color="primary" messageId="global.add" />
  );

  return (
    <FormRHF<GqlNewDocumentInput>
      defaultValues={{ caseService: initialService }}
      onSubmit={createNewDocument}
      validationSchema={validationSchema}
      noDefaultStyle
    >
      <PageLayout
        title={<T keyName="form.new_document.title" />}
        actions={submitButton}
        navigation={
          <PageLayoutNavigation to="/documents">
            <T keyName="nav.documents" />
          </PageLayoutNavigation>
        }
        bodyClassName="max-w-xl mx-auto"
        footerClassName="max-w-xl mx-auto"
        footer={submitButton}
      >
        <SelectService name="caseService" autoFocus={initialService === ''} />
        <FormattedInputField
          name="caseNumber"
          label="doc.field.case_number.label"
          help="doc.field.case_number.help"
          autoFocus={initialService !== ''}
        />
        <FormattedInputField
          name="caseExhibitNumber"
          label="doc.field.exhibit_number.label"
          help="doc.field.exhibit_number.help"
        />
        <SelectDocumentType name="docType" />
        <SelectCountry name="docCountry" label="doc.field.country" />
        <SelectFraudType name="docFraudType" />
        <FormattedFormError />
      </PageLayout>
    </FormRHF>
  );
}

export default DocumentNew;
