import { PencilIcon } from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';

import { requiredObject, requiredRoles } from '../../../forms/validation';
import UserRolesField from '../../form/UserRolesField';
import FormattedButton from '../../translation/FormattedButton';
import FormattedFormError from '../../translation/FormattedFormError';
import FormattedSubmitButton from '../../translation/FormattedSubmitButton';
import FormattedUserName from '../../translation/FormattedUserName';

import { GqlUsersQuery, useChangeUserRolesMutation } from '#gql';
import { FormRHFModal, useNotificationCenter } from '#tailwind_ui';
import { getSortedRoles } from '#utils/roles';

function ChangeUserRolesModal({
  user,
  onCancel,
}: {
  user: GqlUsersQuery['users']['nodes'][number];
  onCancel: () => void;
}) {
  const [changeUserRoles] = useChangeUserRolesMutation();

  const validationSchema = requiredObject({
    roles: requiredRoles,
  });

  const sortedRoles = getSortedRoles(user.roles);
  const { addNotification } = useNotificationCenter();

  return (
    <FormRHFModal
      isOpen
      fluid={false}
      onRequestClose={onCancel}
      icon={<PencilIcon />}
      defaultValues={{
        roles: sortedRoles,
      }}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        await changeUserRoles({
          variables: {
            input: {
              id: user.id,
              roles: values.roles,
            },
          },
        });
        addNotification({
          type: 'success',
          title: <T keyName="page.users.update.success" />,
        });
        onCancel();
      }}
    >
      <FormRHFModal.Header>
        <T keyName="page.users.action.change_roles" />
      </FormRHFModal.Header>
      <FormRHFModal.Body>
        <div>
          <T keyName="global.user" />
          {`: `}
          <FormattedUserName values={user} />
        </div>
        <UserRolesField name="roles" />
        <FormattedFormError />
      </FormRHFModal.Body>
      <FormRHFModal.Footer>
        <FormattedButton
          messageId="global.cancel"
          variant="white"
          onClick={onCancel}
        />
        <FormattedSubmitButton messageId="global.change" color="primary" />
      </FormRHFModal.Footer>
    </FormRHFModal>
  );
}

export default ChangeUserRolesModal;
