import { TrashIcon } from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';
import { useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { requiredObject, requiredString } from '../../forms/validation';
import FormattedButton from '../translation/FormattedButton';
import FormattedFormError from '../translation/FormattedFormError';
import FormattedInputField from '../translation/FormattedInputField';
import FormattedSubmitButton from '../translation/FormattedSubmitButton';

import {
  GqlDocumentIdentifierFieldsFragment,
  useDeleteDocumentMutation,
} from '#gql';
import { FormattedAlert } from '#intl';
import { Color, FormRHFModal, useNotificationCenter } from '#tailwind_ui';
import { getDocumentTitle } from '#utils/index';

const validationSchema = requiredObject({
  caseExhibitNumber: requiredString,
  reason: requiredString,
});

interface DocumentDeleteModalProps {
  id: string;
  document: GqlDocumentIdentifierFieldsFragment;
  isOpen: boolean;
  onRequestClose: () => void;
}

const defaultValues = {
  caseExhibitNumber: '',
  reason: '',
};

export default function DocumentDeleteModal(props: DocumentDeleteModalProps) {
  const { id, document, isOpen, onRequestClose } = props;

  const [deleteDocument] = useDeleteDocumentMutation();
  const navigate = useNavigate();

  const { addNotification } = useNotificationCenter();

  const title = getDocumentTitle(document, true);

  async function handleSubmit(values: typeof defaultValues) {
    await deleteDocument({
      variables: { input: { id, reason: values.reason } },
    });

    addNotification({
      type: Color.success,
      title: <T keyName="doc.delete.success" />,
    });

    navigate('/documents');
  }

  return (
    <FormRHFModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      defaultValues={defaultValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      icon={<TrashIcon />}
      iconColor={Color.danger}
    >
      <FormRHFModal.Header>
        <T keyName="doc.delete.title" params={{ title }} />
      </FormRHFModal.Header>
      <FormRHFModal.Body>
        <FormattedAlert type="warning" messageId="doc.delete.alert" />

        <FormattedInputField
          name="caseExhibitNumber"
          label="doc.delete.field.case_exhibit_number.label"
          labelValues={{ caseExhibitNumber: document.caseExhibitNumber }}
        />

        <FormattedInputField
          name="reason"
          label="doc.delete.field.reason.label"
        />

        <FormattedFormError />
      </FormRHFModal.Body>
      <FormRHFModal.Footer>
        <DeleteDocumentFooter closeModal={onRequestClose} document={document} />
      </FormRHFModal.Footer>
    </FormRHFModal>
  );
}

interface DeleteDocumentFooterProps {
  closeModal: () => void;
  document: GqlDocumentIdentifierFieldsFragment;
}

function DeleteDocumentFooter(props: DeleteDocumentFooterProps) {
  const { closeModal, document } = props;
  const caseExhibitNumber = useWatch({ name: 'caseExhibitNumber' });

  return (
    <>
      <FormattedButton
        messageId="global.cancel"
        variant="white"
        onClick={closeModal}
      />
      <FormattedSubmitButton
        disabled={caseExhibitNumber !== document.caseExhibitNumber}
        messageId="global.delete"
        color={Color.danger}
      />
    </>
  );
}
