import { T } from '@tolgee/react';
import { memo } from 'react';

import { useUserEmailsLazyQuery } from '#gql';
import { ClipboardButton, Tooltip, useNotificationCenter } from '#tailwind_ui';

function CopyEmailsToClipboard() {
  const { addNotification } = useNotificationCenter();
  const [getUserEmails] = useUserEmailsLazyQuery({
    fetchPolicy: 'no-cache',
  });

  async function onCopy() {
    const { data } = await getUserEmails();

    return data?.users.nodes.map((u) => u.email).join('; ') ?? '';
  }

  function onSuccess() {
    addNotification({
      type: 'success',
      title: <T keyName="page.users.success.copy_emails" />,
    });
  }

  function onError(error: Error) {
    reportError(error);
    addNotification({
      type: 'danger',
      title: <T keyName="global.error.unknown" />,
      content: error.message,
    });
  }

  return (
    <Tooltip content={<T keyName="page.users.action.copy_emails" />}>
      <label className="group inline-flex cursor-pointer items-center gap-2 self-start">
        <ClipboardButton
          onCopy={onCopy}
          onError={onError}
          onSuccess={onSuccess}
        />
        <span className="group-hover:underline">
          <T keyName="page.users.copy_emails" />
        </span>
      </label>
    </Tooltip>
  );
}

export const CopyEmailsToClipboardMemo = memo(CopyEmailsToClipboard);

export default CopyEmailsToClipboardMemo;
