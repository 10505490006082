import { Link, LinkProps } from 'react-router-dom';

import { MandatoryChildren } from '../../global';

import { PageLayoutBreadcrumbItem } from './page_layout_breadcrumb';

export interface PageLayoutNavigationProps extends MandatoryChildren {
  to: LinkProps['to'];
}
export function PageLayoutNavigation(props: PageLayoutNavigationProps) {
  return (
    <PageLayoutBreadcrumbItem>
      <Link to={props.to}>{props.children}</Link>
    </PageLayoutBreadcrumbItem>
  );
}
