// Experimental features are not translated for now.
// eslint-disable-next-line no-restricted-imports
import { Alert } from '#tailwind_ui';

export function ExperimentalAlert() {
  return (
    <Alert className="my-2" type="warning">
      This feature is experimental and may change at any time.
    </Alert>
  );
}
