import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/20/solid';
import { ShieldExclamationIcon } from '@heroicons/react/24/outline';
import { T, useTranslate } from '@tolgee/react';
import { Fragment, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedNumber } from 'react-intl';

import { BaseLabelBadge } from '../badge/badge';

import { Color, IconButton } from '#tailwind_ui';
import { contrastRationFromHexString } from '#utils/color';

export interface LabelPreviewBaseContext {
  name: string;
  color: {
    background: string;
    text: string;
  };
}

export function LabelPreview() {
  const form = useFormContext<LabelPreviewBaseContext>();
  const [name, color, backgroundColor] = form.watch([
    'name',
    'color.text',
    'color.background',
  ]);

  const contrast = useMemo(
    () => contrastRationFromHexString(color, backgroundColor),
    [color, backgroundColor],
  );

  return (
    <>
      <div className="flex gap-1 text-sm font-semibold text-neutral-700">
        <T keyName="label.field.preview" />
        <ContrastWarning contrast={contrast} />
      </div>
      <div>
        <BaseLabelBadge
          name={name || 'label'}
          color={color}
          backgroundColor={backgroundColor}
        />
      </div>
    </>
  );
}

interface ContrastWarningProps {
  contrast: number | undefined;
}
function ContrastWarning({ contrast: _contrast }: ContrastWarningProps) {
  const { t } = useTranslate();

  if (typeof _contrast !== 'number') return null;

  const contrast = (
    <Fragment key="contrast">
      <FormattedNumber value={_contrast} maximumSignificantDigits={3} />
      :1
    </Fragment>
  );

  if (_contrast < 4.5) {
    return (
      <IconButton
        size="5"
        color={Color.danger}
        tooltip={
          <span className="max-w-sm">
            {t('label.field.color.contrast.danger', { contrast })}
          </span>
        }
        icon={<ShieldExclamationIcon />}
        className="cursor-default"
      />
    );
  }

  if (_contrast < 7) {
    return (
      <IconButton
        size="5"
        color={Color.warning}
        tooltip={
          <div className="max-w-sm">
            {t('label.field.color.contrast.warning', { contrast })}
          </div>
        }
        icon={<ExclamationTriangleIcon />}
        className="cursor-default"
      />
    );
  }

  return (
    <IconButton
      color={Color.success}
      size="5"
      tooltip={
        <div className="max-w-sm">
          {t('label.field.color.contrast.success', { contrast })}
        </div>
      }
      icon={<CheckCircleIcon />}
      className="cursor-default"
    />
  );
}
