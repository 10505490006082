import { TableSearchContextProvider } from '../components/providers/TableSearchContextProvider';
import { documentsContext } from '../contexts/documents';
import { MandatoryChildren } from '../global';
import { UsePaginationTableInitial } from '../hooks/usePaginationTable';
import { SortTable } from '../hooks/useSortTable';
import {
  DocumentFilters,
  DocumentFiltersStore,
} from '../stores/searches/DocumentFiltersStore';

import { DocumentSortField } from '#gql';

const initialSort = {
  field: 'createdAt',
  direction: 'DESC',
} as const satisfies SortTable<DocumentSortField>;

const initialPagination = {
  label: 'page.doc.list.filter_info',
} satisfies Partial<UsePaginationTableInitial>;

export function DocumentTableSearchProvider({ children }: MandatoryChildren) {
  return (
    <TableSearchContextProvider<
      DocumentSortField,
      DocumentFilters,
      typeof DocumentFiltersStore,
      DocumentFiltersStore
    >
      context={documentsContext}
      Store={DocumentFiltersStore}
      initialSort={initialSort}
      initialPagination={initialPagination}
    >
      {children}
    </TableSearchContextProvider>
  );
}
