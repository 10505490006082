export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    AnalyzedScanFields: ['AnalyzedDocument', 'ExtractedDocument'],
    AuditLogData: [
      'AuditLogDataDocumentDelete',
      'AuditLogDataDocumentDeleteImage',
      'AuditLogDataDocumentSetDelete',
      'AuditLogDataDocumentUpdate',
      'AuditLogDataDocumentUpdateSeries',
      'AuditLogDataLabelDelete',
      'AuditLogDataPadifAnalysisDelete',
      'AuditLogDataScanDelete',
      'AuditLogDataSeriesDelete',
      'AuditLogDataSeriesUpdate',
      'AuditLogDataSeriesUpdateAttachments',
      'AuditLogDataUserDelete',
    ],
    AuditLogIdentifiers: [
      'AuditLogIdentifiersWithMongoId',
      'AuditLogIdentifiersWithNumberId',
    ],
    DocumentMRZFields: ['Document', 'ImportDataDocumentFields'],
    ImportDataFields: [
      'ProcessAnalyze',
      'ProcessExtract',
      'ProcessFinalize',
      'ProcessFindRegions',
      'ProcessMRZ',
      'ProcessReview',
      'ProcessRotate',
    ],
    ScanImportData: [
      'ProcessAnalyze',
      'ProcessExtract',
      'ProcessFinalize',
      'ProcessFindRegions',
      'ProcessMRZ',
      'ProcessReview',
      'ProcessRotate',
    ],
  },
};
export default result;
