import { SVGAttributes } from 'react';

interface Pattern {
  space: number;
  strokeWidth: number;
  pathAttributes?: Omit<SVGAttributes<SVGPathElement>, 'strokeWidth'>;
}

interface StripePatternProps {
  id: string;
  pattern: Pattern;
  backgroundRectAttributes?: SVGAttributes<SVGRectElement>;
}

export function StripePattern(props: StripePatternProps) {
  const {
    space,
    strokeWidth,
    pathAttributes = { stroke: 'black' },
  } = props.pattern;

  const patternSize = space * 2;
  const mainLine = `M0,${patternSize} l${patternSize},-${patternSize}`;
  const cornerLine1 = `M-${strokeWidth},${strokeWidth} l${strokeWidth * 2},-${strokeWidth * 2}`;
  const cornerLine2 = `M${patternSize - strokeWidth},${patternSize + strokeWidth} l${strokeWidth * 2},-${strokeWidth * 2}`;

  return (
    <pattern
      id={props.id}
      patternUnits="userSpaceOnUse"
      width={patternSize}
      height={patternSize}
    >
      <rect
        x={0}
        y={0}
        width={patternSize}
        height={patternSize}
        {...props.backgroundRectAttributes}
      />
      <path
        d={`${cornerLine1} ${mainLine} ${cornerLine2}`}
        strokeWidth={strokeWidth}
        {...pathAttributes}
      />
    </pattern>
  );
}
