import { useMemo } from 'react';

import FacetFilterSelectList, {
  FacetFilterSelectListProps,
} from '../../TableSearch/FacetFilterSelectList';

import { useServicesQuery } from '#gql';

export default function ServiceFilterSelectList<
  Filter,
  Prop extends keyof Filter,
>(props: Omit<FacetFilterSelectListProps<Filter, Prop>, 'optionLookup'>) {
  const { data: services } = useServicesQuery();

  const { optionLookup, displayLookup } = useMemo(() => {
    if (!services) {
      return {
        optionLookup: {},
        displayLookup: {},
      };
    }
    // eslint-disable-next-line unicorn/no-array-reduce
    const optionLookup = services.services.nodes.reduce<Record<string, string>>(
      (acc, service) => {
        acc[service.identifier] = `${service.identifier} - ${service.name}`;
        return acc;
      },
      {},
    );

    // eslint-disable-next-line unicorn/no-array-reduce
    const displayLookup = services.services.nodes.reduce<
      Record<string, string>
    >((acc, service) => {
      acc[service.identifier] = service.identifier;
      return acc;
    }, {});

    return { optionLookup, displayLookup };
  }, [services]);
  if (!services) return null;

  return (
    <FacetFilterSelectList
      {...props}
      optionLookup={optionLookup}
      displayLookup={displayLookup}
    />
  );
}
