import { TrashIcon } from '@heroicons/react/24/outline';

import { AsyncFormattedConfirmModal } from '../../AsyncFormattedConfirmModal';

import { useDeleteAlertDraftMutation } from '#gql';

interface DeleteAlertDraftModalProps {
  isOpen: boolean;
  id: string;
  draftId: string;
  onClose: (success?: boolean) => void;
}
export default function DeleteAlertDraftModal(
  props: DeleteAlertDraftModalProps,
) {
  const { isOpen, id, draftId, onClose } = props;
  const [deleteDraft] = useDeleteAlertDraftMutation();

  return (
    <AsyncFormattedConfirmModal
      open={isOpen}
      icon={<TrashIcon />}
      color="warning"
      confirmText="global.discard"
      cancelText="global.cancel"
      title="series.alert.discard_draft.confirm.title"
      body="series.alert.discard_draft.confirm.body"
      onClose={onClose}
      onConfirm={() =>
        deleteDraft({
          variables: {
            id,
            draftId,
          },
        })
      }
    />
  );
}
