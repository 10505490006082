// QUERY is conditional.
// eslint-disable-next-line no-restricted-imports
import { useQuery } from '@apollo/client';
import {
  DocumentPlusIcon,
  PencilIcon,
  QrCodeIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { T, useTranslate } from '@tolgee/react';
import { memo, ReactNode, useCallback, useMemo } from 'react';
import { useKbsGlobal } from 'react-kbs';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { match } from 'ts-pattern';
import { useBoolean } from 'usehooks-ts';

import { useHasPermission } from '../../hooks/permissions';
import { useSeqIdParam } from '../../hooks/useParams';
import { DocumentDocumentSetTableSearchProvider } from '../../providers/DocumentDocumentSetTableSearchProvider';
import LinkGroup, { LinkItem } from '../LinkGroup';
import { MinimalNextButton, MinimalPreviousButton } from '../MinimalButton';
import IfHasPermission from '../permissions/IfHasPermission';
import { documentContext } from '../providers/DocumentProvider.context';
import FormattedErrorAlert from '../translation/FormattedErrorAlert';
import { UserChangesMeta } from '../user/UserChangesMeta';

import { DocumentAddToDocSetModal } from './DocumentAddToDocSetModal';
import DocumentDeleteModal from './DocumentDelete';
import {
  DocumentListLocationState,
  useDocumentListLocationState,
} from './documentListLocationState';

import {
  DocumentBySeqIdDocument,
  GqlDetailedDocumentFieldsFragment,
  GqlDocumentBySeqIdQuery,
  GqlSingleDocumentQuery,
  SingleDocumentDocument,
} from '#gql';
import { LinkLabelBadge } from '#label';
import { ButtonGroup, DropdownActionOption, useOnOff } from '#tailwind_ui';
import { PageLayout, PageLayoutNavigation } from '#ui/page_layout';
import { assert } from '#utils/assert';
import { getDocumentTitle } from '#utils/index';

function DocumentViewBase() {
  const seqId = useSeqIdParam();

  const locationState = useDocumentListLocationState();

  let QUERY, variables;
  let indexInList = 0;
  if (locationState) {
    QUERY = SingleDocumentDocument;
    indexInList =
      locationState.documentsQueryVariables.skip +
      locationState.currentDocumentIndex;
    variables = {
      ...locationState.documentsQueryVariables,
      skip: Math.max(indexInList - 1, 0),
    };
  } else {
    QUERY = DocumentBySeqIdDocument;
    variables = { seqId };
  }

  const queryResult = useQuery(QUERY, { variables });

  if (queryResult.error) {
    return <FormattedErrorAlert error={queryResult.error} />;
  }
  const data = queryResult.data || queryResult.previousData;
  if (!data) return null;

  if (locationState) {
    return (
      <DocumentViewFromList
        data={data}
        indexInList={indexInList}
        seqId={seqId}
        locationState={locationState}
      />
    );
  } else {
    return <DocumentViewSingle data={data} seqId={seqId} />;
  }
}

const DocumentView = memo(DocumentViewBase);
export default DocumentView;

function DocumentViewFromList(props: {
  data: GqlSingleDocumentQuery;
  indexInList: number;
  seqId: string;
  locationState: DocumentListLocationState;
}) {
  const navigate = useNavigate();
  const { data, indexInList, seqId, locationState } = props;
  const { nodes, totalCount } = data.documents;
  const isFirst = indexInList === 0;
  const isLast = indexInList === totalCount - 1;
  const document = isFirst ? nodes[0] : nodes[1];

  const subpage = useLocation().pathname.split('/')[3];

  const goToNextDocument = useCallback(() => {
    if (isLast) return;
    navigate(
      `/documents/${nodes[isFirst ? 1 : 2].seqId}${
        subpage ? `/${subpage}` : ''
      }`,
      {
        state: {
          ...locationState,
          currentDocumentIndex: locationState.currentDocumentIndex + 1,
        },
      },
    );
  }, [navigate, nodes, locationState, isFirst, isLast, subpage]);
  const goToPreviousDocument = useCallback(() => {
    if (isFirst) return;
    navigate(`/documents/${nodes[0].seqId}${subpage ? `/${subpage}` : ''}`, {
      state: {
        ...locationState,
        currentDocumentIndex: locationState.currentDocumentIndex - 1,
      },
    });
  }, [navigate, nodes, locationState, isFirst, subpage]);

  useKbsGlobal([
    {
      shortcut: 'ArrowRight',
      handler: goToNextDocument,
    },
    {
      shortcut: 'ArrowLeft',
      handler: goToPreviousDocument,
    },
  ]);

  const previousNext = (
    <div className="flex flex-col items-end">
      <div className="flex flex-row gap-1">
        <MinimalPreviousButton
          tooltipId="page.doc.view.go_to_previous"
          disabled={isFirst}
          onClick={goToPreviousDocument}
        />
        <MinimalNextButton
          tooltipId="page.doc.view.go_to_next"
          disabled={isLast}
          onClick={goToNextDocument}
        />
      </div>
      <div className="-mt-1 text-right text-sm text-neutral-500">
        {`#${indexInList + 1} `}
        <T keyName="global.of" />
        {` ${totalCount}`}
      </div>
    </div>
  );
  return (
    <DocumentViewImpl
      seqId={seqId}
      document={document}
      previousNext={previousNext}
    />
  );
}

interface DocumentViewSingleProps {
  data: GqlDocumentBySeqIdQuery;
  seqId: string;
}

type DocumentAction = 'PRINT_QR' | 'DELETE_DOC' | 'ADD_TO_DOC_SET';

function DocumentViewSingle(props: DocumentViewSingleProps) {
  const { data, seqId } = props;
  return <DocumentViewImpl seqId={seqId} document={data.documentBySeqId} />;
}

const documentsPages: LinkItem[] = [
  { id: 'FULL', intlId: 'page.doc.view.mode.infos' },
  { id: 'RECTO', intlId: 'doc.document_view.RECTO' },
  { id: 'VERSO', intlId: 'doc.document_view.VERSO' },
  { id: 'SUGGESTIONS', intlId: 'global.suggestions' },
];

interface DocumentViewImplProps {
  seqId: string;
  document: GqlDetailedDocumentFieldsFragment;
  previousNext?: ReactNode;
}

function DocumentViewImpl({
  seqId,
  document,
  previousNext = null,
}: DocumentViewImplProps) {
  const { t } = useTranslate();
  const contextValue = useMemo(() => ({ document, seqId }), [document, seqId]);
  const [isDeleteModalOpen, openDeleteModal, closeDeleteModal] = useOnOff();
  const canDelete = useHasPermission('document_delete');
  const canEditDocSet = useHasPermission('documentSet_update');
  const navigate = useNavigate();
  const docSetModal = useBoolean();

  const documentActions: Array<Array<DropdownActionOption<DocumentAction>>> = [
    [
      {
        type: 'action',
        label: <T keyName="page.doc.view.print_qr" />,
        data: 'PRINT_QR',
        icon: <QrCodeIcon />,
      },
    ],
  ];

  if (canEditDocSet) {
    documentActions.push([
      {
        type: 'action',
        label: <T keyName="document.view.actions.add_to_doc_set" />,
        data: 'ADD_TO_DOC_SET',
        icon: <DocumentPlusIcon />,
      },
    ]);
  }

  if (canDelete) {
    documentActions.push([
      {
        type: 'action',
        data: 'DELETE_DOC',
        label: (
          <div className="text-danger-600">
            <T keyName="global.delete" />
          </div>
        ),
        icon: <TrashIcon className="text-danger-600" />,
      },
    ]);
  }

  const onSelect = useCallback(
    ({ data }: DropdownActionOption<DocumentAction>) => {
      assert(data);

      match(data)
        .with('PRINT_QR', () => navigate('qr'))
        .with('DELETE_DOC', openDeleteModal)
        .with('ADD_TO_DOC_SET', docSetModal.setTrue)
        .exhaustive();
    },
    [docSetModal.setTrue, navigate, openDeleteModal],
  );

  const title = `${document.seqId} - ${getDocumentTitle(document, true)}`;

  return (
    <PageLayout
      title={title}
      navigation={
        <PageLayoutNavigation to="/documents">
          <T keyName="nav.documents.list" />
        </PageLayoutNavigation>
      }
      actions={
        <>
          <IfHasPermission userPermission="document_update">
            <ButtonGroup variant="white">
              <ButtonGroup.Button as={Link} to="edit">
                <div className="flex items-center gap-2">
                  <PencilIcon className="h-5 w-5" />
                  <T keyName="page.doc.view.describe" />
                </div>
              </ButtonGroup.Button>
              <ButtonGroup.Dropdown
                options={documentActions}
                onSelect={onSelect}
              />
            </ButtonGroup>
          </IfHasPermission>
          {previousNext}
        </>
      }
    >
      <div className="flex flex-wrap items-center justify-between gap-2">
        <LinkGroup
          options={documentsPages}
          preserveState={previousNext !== null}
        />

        {document.labels.length > 0 && (
          <div className="inline-flex flex-1 flex-row flex-wrap gap-2">
            {document.labels.map((label) => (
              <LinkLabelBadge key={label.id} label={label} to="/documents" />
            ))}
          </div>
        )}

        <div className="flex items-center gap-4 self-end">
          <UserChangesMeta
            {...document}
            mailSubject={`[${t('document.singular_form', { noWrap: true })}] ${title}`}
            mailBody={window.location.href}
          />
        </div>
      </div>

      <documentContext.Provider value={contextValue}>
        <div className="mt-3">
          <Outlet />
        </div>
      </documentContext.Provider>

      <DocumentDeleteModal
        id={document.id}
        document={document}
        isOpen={isDeleteModalOpen}
        onRequestClose={closeDeleteModal}
      />

      <DocumentDocumentSetTableSearchProvider>
        <DocumentAddToDocSetModal
          id={document.id}
          isOpen={docSetModal.value}
          onRequestClose={docSetModal.setFalse}
        />
      </DocumentDocumentSetTableSearchProvider>
    </PageLayout>
  );
}
