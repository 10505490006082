import clsx from 'clsx';
import { AnchorHTMLAttributes, DetailedHTMLProps } from 'react';
import { Link, LinkProps } from 'react-router-dom';

const baseLinkClassName = 'text-primary-700 hover:underline';

export function HighlightedLink(props: LinkProps) {
  const { className, ...otherProps } = props;
  return (
    <Link {...otherProps} className={clsx(baseLinkClassName, className)} />
  );
}

export function HighlightedAnchor(
  props: DetailedHTMLProps<
    AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  >,
) {
  const { className, ...otherProps } = props;
  return <a {...otherProps} className={clsx(baseLinkClassName, className)} />;
}
