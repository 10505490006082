import { T } from '@tolgee/react';

import { GqlDocumentSet } from '#gql';
import { HighlightedLink } from '#ui/link';

interface DocumentSetTitleProps {
  value: Pick<GqlDocumentSet, 'id' | 'name'>;
  link?: boolean;
}

export default function DocumentSetTitle({
  value,
  link,
}: DocumentSetTitleProps) {
  const title = (
    <>
      <T keyName="global.doc_set" />
      {` "${value.name}"`}
    </>
  );
  return link ? (
    <HighlightedLink to={`/document-sets/${value.id}`}>{title}</HighlightedLink>
  ) : (
    title
  );
}
