import {
  Context,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
} from 'react';
import { useBoolean } from 'usehooks-ts';

import {
  TableSearchGqlPagination,
  TableSearchPagination,
  usePaginationTable,
  UsePaginationTableInitial,
} from '../../hooks/usePaginationTable';
import {
  SortTable,
  useSortTable,
  UseSortTableInitial,
} from '../../hooks/useSortTable';
import {
  ClassSearch,
  useTableSearchStore,
} from '../../hooks/useTableSearchStore';
import { SyncSearchParamsStore } from '../../stores/SyncSearchParamsStore';
import { SyncSearchParamsWithTermStore } from '../../stores/SyncSearchParamsWithTermStore';

import { checkIsFiltered } from './TableSearchContextProvider.utils';

import { TableSortConfig } from '#tailwind_ui';

export interface TableSearchContext<
  SortField extends string,
  Filters extends TableSearchBaseFilters,
  Store extends SyncSearchParamsStore<Filters>,
> {
  /**
   * store is stable, initialized as const in TableSearchContextProvider scope
   *
   * store should expose their api to edit data
   */
  store: Store;

  /**
   * shortcut for store.getSnapshot()
   * returned from useTableSearchStore using useSyncExternalStore
   */
  filters: Filters;
  advancedSearch: boolean;

  /** stable from usehooks-ts useBoolean */
  toggleAdvancedSearch: () => void;
  setAdvancedSearch: Dispatch<SetStateAction<boolean>>;

  /** compute on changes */
  isFiltered: boolean;

  /** is memoized from their properties */
  pagination: TableSearchGqlPagination;

  /** is memoized from their properties */
  sort: SortTable<SortField>;

  /**
   * onPageChange in tablePagination is stable
   * tablePagination is memoized, change only if some of them properties change
   */
  tablePagination: TableSearchPagination;

  /**
   * onChange in tableSort is stable
   * tableSort is memoized, change only if some of them properties change
   */
  tableSort: TableSortConfig;
}

/**
 * It's usefully on generic components
 * Like for a component able to switch advancedSearch boolean, known if isFiltered and so on
 */
export type GenericTableSearchContext = TableSearchContext<
  string,
  TableSearchBaseFilters,
  SyncSearchParamsWithTermStore<TableSearchBaseFilters>
>;

export interface TableSearchBaseFilters {
  searchTerm: string | null;
  [key: string]: unknown;
}

export interface TableSearchContextProviderProps<
  SortField extends string,
  Filters extends TableSearchBaseFilters,
  StoreCls extends ClassSearch<Filters, SyncSearchParamsStore<Filters>>,
  Store extends SyncSearchParamsStore<Filters>,
> {
  /** contexts to provide */
  context: Context<TableSearchContext<SortField, Filters, Store> | null>;

  Store: StoreCls;
  /** call just after Store initialization */
  initStore?: (store: Store) => void;
  /** initial data */
  initialSort: UseSortTableInitial<SortField>;
  initialPagination?: Partial<UsePaginationTableInitial>;

  /** child nodes */
  children: ReactNode;
}
export function TableSearchContextProvider<
  SortField extends string,
  Filters extends TableSearchBaseFilters,
  StoreCls extends ClassSearch<Filters, SyncSearchParamsStore<Filters>>,
  Store extends SyncSearchParamsStore<Filters>,
>(props: TableSearchContextProviderProps<SortField, Filters, StoreCls, Store>) {
  /* plain states */
  const [filters, store] = useTableSearchStore<Filters, StoreCls, Store>(
    props.Store,
    props.initStore,
  );
  const {
    value: advancedSearch,
    toggle: toggleAdvancedSearch,
    setValue: setAdvancedSearch,
  } = useBoolean();

  const [sort, tableSort] = useSortTable(props.initialSort);
  const { pagination, tablePagination, onPageChange } = usePaginationTable(
    props.initialPagination,
  );

  // reset pagination on filter change
  useEffect(
    () => store.subscribe(() => onPageChange(0)),
    [filters, store, onPageChange],
  );

  const state = useMemo<TableSearchContext<SortField, Filters, Store>>(
    () => ({
      store,
      filters,
      advancedSearch,
      toggleAdvancedSearch,
      setAdvancedSearch,
      isFiltered: checkIsFiltered(filters),
      pagination,
      sort,
      tablePagination,
      tableSort,
    }),
    [
      store, // should be stable
      filters, // state - change rerender
      advancedSearch, // state - change rerender
      toggleAdvancedSearch, // should be stable
      setAdvancedSearch, // should be stable
      pagination, // state - change rerender
      sort, // state - change rerender
      tablePagination, // state - change rerender
      tableSort, // state - change rerender
    ],
  );

  const Provider = props.context.Provider;

  return <Provider value={state}>{props.children}</Provider>;
}
