import { useEffect, useId } from 'react';

import { usePreventNavigationContext } from '../contexts/PreventNavigation/PreventNavigationContext';

/**
 * simple hook to interact with PreventNavigationContext
 * @param when - lock / unlock navigation from this boolean
 */
export function usePreventNavigation(when: boolean) {
  const id = useId();
  const dispatch = usePreventNavigationContext();

  useEffect(() => {
    dispatch({
      type: when ? 'ADD' : 'REMOVE',
      payload: id,
    });
    return () => dispatch({ type: 'REMOVE', payload: id });
  }, [dispatch, id, when]);
}
