import { T } from '@tolgee/react';
import { useNavigate } from 'react-router-dom';

import { requiredObject, requiredString } from '../../../forms/validation';
import FormattedFormError from '../../translation/FormattedFormError';
import FormattedInputField from '../../translation/FormattedInputField';
import FormattedSubmitButton from '../../translation/FormattedSubmitButton';

import { useCreateServiceMutation } from '#gql';
import { FormattedAlert } from '#intl';
import { FormRHF } from '#tailwind_ui';
import { PageLayout, PageLayoutNavigation } from '#ui/page_layout';

const createServiceValidation = requiredObject({
  identifier: requiredString.matches(/^[\dA-Za-z]+(?:-[\dA-Za-z]+)*$/),
  name: requiredString,
});

const FORM_SUBMIT = (
  <FormattedSubmitButton color="primary" messageId="global.create" />
);

export default function AdminServicesCreate() {
  const [createService] = useCreateServiceMutation();
  const navigate = useNavigate();

  return (
    <FormRHF
      noDefaultStyle
      defaultValues={{ identifier: '', name: '' }}
      validationSchema={createServiceValidation}
      onSubmit={async (values) => {
        await createService({ variables: { input: { ...values } } });
        navigate('/admin/services');
      }}
    >
      <PageLayout
        title={<T keyName="admin.create_service.title" />}
        navigation={
          <PageLayoutNavigation to="/admin/services">
            <T keyName="nav.admin.services" />
          </PageLayoutNavigation>
        }
        bodyClassName="max-w-xl mx-auto"
        footerClassName="max-w-xl mx-auto"
        actions={FORM_SUBMIT}
        footer={FORM_SUBMIT}
      >
        <FormattedAlert type="warning" messageId="admin.create_service.alert" />
        <FormattedInputField
          name="identifier"
          size={15}
          maxLength={15}
          label="admin.create_service.field.identifier.label"
          placeholder="admin.create_service.field.identifier.placeholder"
          help="admin.create_service.field.identifier.help"
        />
        <FormattedInputField
          name="name"
          label="admin.create_service.field.name.label"
          placeholder="admin.create_service.field.name.placeholder"
          help="admin.create_service.field.name.help"
        />
        <FormattedFormError />
      </PageLayout>
    </FormRHF>
  );
}
