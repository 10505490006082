import { useMemo } from 'react';

import { getUserNameWithService } from '../../helpers/user';
import FormattedMultiSearchSelectField from '../translation/FormattedMultiSearchSelectField';

import { GqlUsersQuery, useUserSelectQuery } from '#gql';
import { useMultiSearchSelect } from '#tailwind_ui';

interface SelectUsersProps {
  name: string;
  label: TranslationKey;
}

interface SelectUsersImplProps {
  data: GqlUsersQuery;
  name: string;
  label: TranslationKey;
}

export default function SelectUsers(props: SelectUsersProps) {
  const { loading, data, error } = useUserSelectQuery();
  if (error) throw error;
  if (loading) return null;

  return <SelectUsersImpl data={data as GqlUsersQuery} {...props} />;
}

function SelectUsersImpl(props: SelectUsersImplProps) {
  const { data, label, name } = props;

  const options = useMemo(() => {
    return data.users.nodes.map((user) => {
      return {
        value: user.id,
        label: getUserNameWithService(user),
      };
    });
  }, [data.users]);

  const hook = useMultiSearchSelect({ options });

  return (
    <FormattedMultiSearchSelectField
      {...hook}
      name={name}
      label={label}
      closeListOnSelect
    />
  );
}
