import { Route } from 'react-router-dom';

import ChangePassword from './pages/change_password';

export function userSettingsRoutes() {
  return [
    <Route
      key="/user/change-password"
      path="/user/change-password"
      element={<ChangePassword />}
    />,
  ];
}
