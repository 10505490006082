import { createContext, useContext } from 'react';

/**
 * Private usage, Provider should be mounted only once.
 * prefer usePreventNavigationShouldBlockContext to know the state.
 */
export const PreventNavigationShouldBlockContext = createContext(false);

/**
 * context true if navigation should be prevented, typical usage with PreventNavigation
 * if shouldBlock the warning banner is visible else invisible
 */
export function usePreventNavigationShouldBlockContext() {
  return useContext(PreventNavigationShouldBlockContext);
}
