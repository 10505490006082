import { createContext, useContext } from 'react';

import { TableSearchContext } from '../components/providers/TableSearchContextProvider';
import {
  DocumentFilters,
  DocumentFiltersStore,
} from '../stores/searches/DocumentFiltersStore';

import { DocumentSortField } from '#gql';

export const documentsContext = createContext<TableSearchContext<
  DocumentSortField,
  DocumentFilters,
  DocumentFiltersStore
> | null>(null);

export function useDocumentsContext() {
  const context = useContext(documentsContext);

  if (context === null) {
    throw new Error(
      'useDocumentsContext must be used within a TableSearchContextProvider',
    );
  }

  return context;
}
