export const AlertStatus = {
  ARCHIVED: 'ARCHIVED',
  INITIATED: 'INITIATED',
  PUBLISHED: 'PUBLISHED',
} as const;

export type AlertStatus = (typeof AlertStatus)[keyof typeof AlertStatus];
export const AuditLogAction = {
  DOCUMENT_DELETE: 'DOCUMENT_DELETE',
  DOCUMENT_DELETE_IMAGE: 'DOCUMENT_DELETE_IMAGE',
  DOCUMENT_SET_DELETE: 'DOCUMENT_SET_DELETE',
  DOCUMENT_UPDATE: 'DOCUMENT_UPDATE',
  DOCUMENT_UPDATE_SERIES: 'DOCUMENT_UPDATE_SERIES',
  LABEL_DELETE: 'LABEL_DELETE',
  PADIF_ANALYSIS_DELETE: 'PADIF_ANALYSIS_DELETE',
  SCAN_DELETE: 'SCAN_DELETE',
  SERIES_DELETE: 'SERIES_DELETE',
  SERIES_UPDATE: 'SERIES_UPDATE',
  SERIES_UPDATE_ATTACHMENTS: 'SERIES_UPDATE_ATTACHMENTS',
  USER_DELETE: 'USER_DELETE',
  USER_LOGIN: 'USER_LOGIN',
} as const;

export type AuditLogAction =
  (typeof AuditLogAction)[keyof typeof AuditLogAction];
export const AuditLogDataUpdateType = {
  ADD: 'add',
  REMOVE: 'remove',
} as const;

export type AuditLogDataUpdateType =
  (typeof AuditLogDataUpdateType)[keyof typeof AuditLogDataUpdateType];
export const AuditLogSortField = {
  ACTION: 'action',
  DATE: 'date',
} as const;

export type AuditLogSortField =
  (typeof AuditLogSortField)[keyof typeof AuditLogSortField];
export const BatchOperation = {
  ADD: 'add',
  REMOVE: 'remove',
} as const;

export type BatchOperation =
  (typeof BatchOperation)[keyof typeof BatchOperation];
export const DocumentExtraImageLight = {
  /** Coaxial light */
  COAXIAL: 'COAXIAL',
  /** Infrared light */
  IR: 'IR',
  /** White light */
  NORMAL: 'NORMAL',
  /** Oblique light */
  OBLIQUE: 'OBLIQUE',
  /** Transmitted light */
  TRANSMITTED: 'TRANSMITTED',
  /** Ultra-violet light */
  UV: 'UV',
} as const;

export type DocumentExtraImageLight =
  (typeof DocumentExtraImageLight)[keyof typeof DocumentExtraImageLight];
export const DocumentFacet = {
  CASEPRESUMEDNATIONALITY: 'casePresumedNationality',
  CASESERVICE: 'caseService',
  DOCCOUNTRY: 'docCountry',
  DOCFRAUDTYPE: 'docFraudType',
  DOCISBACKGROUNDFLUO: 'docIsBackgroundFluo',
  DOCISBITMAPCODE: 'docIsBitmapCode',
  DOCISMRZOK: 'docIsMrzOk',
  DOCMEDIUM: 'docMedium',
  DOCPRINTBACKGROUND: 'docPrintBackground',
  DOCPRINTDATA: 'docPrintData',
  DOCPRINTNUMBER: 'docPrintNumber',
  DOCPRINTPHOTO: 'docPrintPhoto',
  DOCTYPE: 'docType',
  LABELS: 'labels',
  SERIES: 'series',
} as const;

export type DocumentFacet = (typeof DocumentFacet)[keyof typeof DocumentFacet];
export const DocumentMedium = {
  /** Cardboard */
  CARDBOARD: 'CARDBOARD',
  /** Fabric */
  FABRIC: 'FABRIC',
  /** Neobon */
  NEOBON: 'NEOBON',
  /** Paper */
  PAPER: 'PAPER',
  /** Plastic */
  PLASTIC: 'PLASTIC',
  /** PC (polycarbonate) */
  POLYCARBONATE: 'POLYCARBONATE',
} as const;

export type DocumentMedium =
  (typeof DocumentMedium)[keyof typeof DocumentMedium];
export const DocumentSetType = {
  DYNAMIC: 'dynamic',
  MANUAL: 'manual',
} as const;

export type DocumentSetType =
  (typeof DocumentSetType)[keyof typeof DocumentSetType];
export const DocumentSetsSortFields = {
  CREATEDAT: 'createdAt',
  DOCCOUNTRY: 'docCountry',
  DOCTYPE: 'docType',
  NAME: 'name',
  TYPE: 'type',
  UPDATEDAT: 'updatedAt',
} as const;

export type DocumentSetsSortFields =
  (typeof DocumentSetsSortFields)[keyof typeof DocumentSetsSortFields];
export const DocumentSortField = {
  CASESEIZUREDATE: 'caseSeizureDate',
  CREATEDAT: 'createdAt',
  DOCCOUNTRY: 'docCountry',
  DOCFRAUDTYPE: 'docFraudType',
  DOCTYPE: 'docType',
} as const;

export type DocumentSortField =
  (typeof DocumentSortField)[keyof typeof DocumentSortField];
export const DocumentType = {
  /** Driving license */
  DRIVING_LICENSE: 'DRIVING_LICENSE',
  /** Identity card */
  IDENTITY_DOCUMENT: 'IDENTITY_DOCUMENT',
  /** Any other type of document */
  OTHER: 'OTHER',
  /** Passport */
  PASSPORT: 'PASSPORT',
  /** Residence-related document */
  RESIDENCE_DOCUMENT: 'RESIDENCE_DOCUMENT',
  /** Stamp */
  STAMP: 'STAMP',
  /** Travel document issued to non-nationals */
  TRAVEL_DOCUMENT_NON_NATIONALS: 'TRAVEL_DOCUMENT_NON_NATIONALS',
  /** Vehicle licence / log book */
  VEHICLE_REGISTRATION: 'VEHICLE_REGISTRATION',
  /** Visa */
  VISA: 'VISA',
} as const;

export type DocumentType = (typeof DocumentType)[keyof typeof DocumentType];
export const DocumentView = {
  OTHER: 'OTHER',
  RECTO: 'RECTO',
  VERSO: 'VERSO',
} as const;

export type DocumentView = (typeof DocumentView)[keyof typeof DocumentView];
export const FraudType = {
  /** Forgery */
  CONTENT_FORGERY: 'CONTENT_FORGERY',
  /** Counterfeit */
  COUNTERFEIT: 'COUNTERFEIT',
  /** Pseudo document */
  FANTASY: 'FANTASY',
  /** Genuine */
  GENUINE: 'GENUINE',
  /** Stolen blank */
  STOLEN_BLANK: 'STOLEN_BLANK',
  /** Unknown */
  UNKNOWN: 'UNKNOWN',
} as const;

export type FraudType = (typeof FraudType)[keyof typeof FraudType];
export const LabelFacet = {
  DOCCOUNTRY: 'docCountry',
  DOCFRAUDTYPE: 'docFraudType',
  DOCTYPE: 'docType',
} as const;

export type LabelFacet = (typeof LabelFacet)[keyof typeof LabelFacet];
export const LabelSortField = {
  CREATEDAT: 'createdAt',
  NAME: 'name',
} as const;

export type LabelSortField =
  (typeof LabelSortField)[keyof typeof LabelSortField];
export const Language = {
  /** German */
  DE: 'de',
  /** English */
  EN: 'en',
  /** French */
  FR: 'fr',
  /** Italian */
  IT: 'it',
  /** Dutch */
  NL: 'nl',
} as const;

export type Language = (typeof Language)[keyof typeof Language];
export const Locale = {
  /** German */
  DE: 'de',
  /** English */
  EN: 'en',
  /** French */
  FR: 'fr',
  /** Dutch */
  NL: 'nl',
} as const;

export type Locale = (typeof Locale)[keyof typeof Locale];
export const Offense = {
  /** Fraud-counterfeiting of means of payment */
  COUNTERFEITING_MEANS_OF_PAYMENT: 'COUNTERFEITING_MEANS_OF_PAYMENT',
  /** Counterfeit activity */
  COUNTERFEIT_ACTIVITY: 'COUNTERFEIT_ACTIVITY',
  /** Drugs */
  DRUGS: 'DRUGS',
  /** Identity theft */
  IDENTITY_THEFT: 'IDENTITY_THEFT',
  /** Immigration or irregular work */
  IMMIGRATION: 'IMMIGRATION',
  /** Laundering */
  LAUNDERING: 'LAUNDERING',
  /** Money counterfeiting */
  MONEY_COUNTERFEITING: 'MONEY_COUNTERFEITING',
  /** Organized crime */
  ORGANIZED_CRIME: 'ORGANIZED_CRIME',
  /** Other */
  OTHER: 'OTHER',
  /** Prostitution */
  PROSTITUTION: 'PROSTITUTION',
  /** Road traffic */
  ROAD_TRAFFIC: 'ROAD_TRAFFIC',
  /** Skimming */
  SKIMMING: 'SKIMMING',
  /** Smuggling activity */
  SMUGGLING_ACTIVITY: 'SMUGGLING_ACTIVITY',
  /** Terrorism */
  TERRORISM: 'TERRORISM',
  /** Theft and robbery */
  THEFT_AND_ROBBERY: 'THEFT_AND_ROBBERY',
  /** Vehicle trafficking */
  VEHICLE_TRAFFICKING: 'VEHICLE_TRAFFICKING',
  /** Welfare fraud */
  WELFARE_FRAUD: 'WELFARE_FRAUD',
} as const;

export type Offense = (typeof Offense)[keyof typeof Offense];
export const PadifAnalysisSortField = {
  _ID: '_id',
  ACTIVE: 'active',
  UPDATEDAT: 'updatedAt',
} as const;

export type PadifAnalysisSortField =
  (typeof PadifAnalysisSortField)[keyof typeof PadifAnalysisSortField];
export const PersonalDataType = {
  BARCODE: 'barcode',
  FACE: 'face',
  OTHER: 'other',
  SIGNATURE: 'signature',
  TEXT: 'text',
} as const;

export type PersonalDataType =
  (typeof PersonalDataType)[keyof typeof PersonalDataType];
export const PrintProcess = {
  /** Handwritten */
  HANDWRITTEN: 'HANDWRITTEN',
  /** Inkjet printing */
  INKJET_PRINTING: 'INKJET_PRINTING',
  /** Intaglio printing */
  INTAGLIO_PRINTING: 'INTAGLIO_PRINTING',
  /** Laser engraving */
  LASER_ENGRAVING: 'LASER_ENGRAVING',
  /** Laser printing */
  LASER_PRINTING: 'LASER_PRINTING',
  /** Letterpress printing */
  LETTERPRESS_PRINTING: 'LETTERPRESS_PRINTING',
  /** Offset printing */
  OFFSET_PRINTING: 'OFFSET_PRINTING',
  /** Other type of printing process */
  OTHER: 'OTHER',
  /** Photographic process */
  PHOTO_PROCESS: 'PHOTO_PROCESS',
  /** Screen typing */
  SCREEN_TYPING: 'SCREEN_TYPING',
  /** Thermal dye sublimation */
  THERMAL_DYE_SUBLIMATION: 'THERMAL_DYE_SUBLIMATION',
  /** Thermal transfert printing */
  THERMAL_TRANSFER_PRINTING: 'THERMAL_TRANSFER_PRINTING',
  /** Typewriter */
  TYPEWRITER: 'TYPEWRITER',
} as const;

export type PrintProcess = (typeof PrintProcess)[keyof typeof PrintProcess];
export const ScanProcessStatus = {
  ERROR: 'ERROR',
  FINALIZING: 'FINALIZING',
  IMPORTED: 'IMPORTED',
  IMPORTING: 'IMPORTING',
  PENDING: 'PENDING',
  PROCESSING: 'PROCESSING',
  TO_BE_REVIEWED: 'TO_BE_REVIEWED',
} as const;

export type ScanProcessStatus =
  (typeof ScanProcessStatus)[keyof typeof ScanProcessStatus];
export const ScanSortField = {
  CREATEDAT: 'createdAt',
  FILENAME: 'filename',
  UPDATEDAT: 'updatedAt',
} as const;

export type ScanSortField = (typeof ScanSortField)[keyof typeof ScanSortField];
export const SeizureContext = {
  /** Administration */
  ADMINISTRATION: 'ADMINISTRATION',
  /** Airport */
  CONTEXT: 'CONTEXT',
  /** Financial or mailing institution */
  FINANCIAL_OR_MAILING_INST: 'FINANCIAL_OR_MAILING_INST',
  /** Highway */
  HIGHWAY: 'HIGHWAY',
  /** Search or investigation */
  INVESTIGATION: 'INVESTIGATION',
  /** Land border (except train) */
  LAND_BORDER: 'LAND_BORDER',
  /** Mail interception */
  MAIL_INTERCEPTION: 'MAIL_INTERCEPTION',
  /** Other type of seizure context */
  OTHER: 'OTHER',
  /** Port or vessel */
  PORT_OR_VESSEL: 'PORT_OR_VESSEL',
  /** Retail or agency */
  RETAIL_OR_AGENCY: 'RETAIL_OR_AGENCY',
  /** Road traffic control */
  ROAD_TRAFFIC_CONTROL: 'ROAD_TRAFFIC_CONTROL',
  /** Train */
  TRAIN: 'TRAIN',
  /** Construction site or workplace */
  WORKPLACE: 'WORKPLACE',
} as const;

export type SeizureContext =
  (typeof SeizureContext)[keyof typeof SeizureContext];
export const SerieFacet = {
  DOCCOUNTRY: 'docCountry',
  DOCFRAUDTYPE: 'docFraudType',
  DOCTYPE: 'docType',
} as const;

export type SerieFacet = (typeof SerieFacet)[keyof typeof SerieFacet];
export const SerieSortField = {
  CREATEDAT: 'createdAt',
  IMPORTANTFIELDSUPDATEDAT: 'importantFieldsUpdatedAt',
  NAME: 'name',
} as const;

export type SerieSortField =
  (typeof SerieSortField)[keyof typeof SerieSortField];
export const ServiceSortField = {
  IDENTIFIER: 'identifier',
} as const;

export type ServiceSortField =
  (typeof ServiceSortField)[keyof typeof ServiceSortField];
export const SortDirection = {
  ASC: 'ASC',
  DESC: 'DESC',
} as const;

export type SortDirection = (typeof SortDirection)[keyof typeof SortDirection];
/**
 * All user permissions.
 *
 * See [Internal documentation](https://github.com/zakodium/profid/wiki/Roles-and-Permissions).
 */
export const UserPermission = {
  ALERT_CREATE: 'alert_create',
  ALERT_DRAFT_DELETE: 'alert_draft_delete',
  ALERT_UPDATE: 'alert_update',
  ALERT_VIEW: 'alert_view',
  AUDITLOG_VIEW: 'auditLog_view',
  DOCUMENTSET_CREATE: 'documentSet_create',
  DOCUMENTSET_DELETE: 'documentSet_delete',
  DOCUMENTSET_UPDATE: 'documentSet_update',
  DOCUMENTSET_VIEW: 'documentSet_view',
  DOCUMENT_CREATE: 'document_create',
  DOCUMENT_DELETE: 'document_delete',
  DOCUMENT_UPDATE: 'document_update',
  DOCUMENT_VIEW: 'document_view',
  LABEL_CREATE: 'label_create',
  LABEL_DELETE: 'label_delete',
  LABEL_UPDATE: 'label_update',
  LABEL_VIEW: 'label_view',
  PADIFANALYSIS_CREATE: 'padifAnalysis_create',
  PADIFANALYSIS_DELETE: 'padifAnalysis_delete',
  PADIFANALYSIS_UPDATE: 'padifAnalysis_update',
  PADIFANALYSIS_UPDATERESULT: 'padifAnalysis_updateResult',
  PADIFANALYSIS_VIEW: 'padifAnalysis_view',
  SCAN_CREATE: 'scan_create',
  SCAN_DELETE: 'scan_delete',
  SCAN_UPDATE: 'scan_update',
  SCAN_VIEW: 'scan_view',
  SEIZUREAUTHORITY_CREATE: 'seizureAuthority_create',
  SEIZUREAUTHORITY_UPDATE: 'seizureAuthority_update',
  SEIZUREAUTHORITY_VIEW: 'seizureAuthority_view',
  SERIE_CREATE: 'serie_create',
  SERIE_DELETE: 'serie_delete',
  SERIE_MERGE: 'serie_merge',
  SERIE_UPDATE: 'serie_update',
  SERIE_VIEW: 'serie_view',
  SERVICE_CREATE: 'service_create',
  SERVICE_UPDATE: 'service_update',
  SERVICE_VIEW: 'service_view',
  TOKEN_CREATE: 'token_create',
  TOKEN_DELETE: 'token_delete',
  USER_CREATE: 'user_create',
  USER_GETPROTECTEDFIELD: 'user_getProtectedField',
  USER_UPDATE: 'user_update',
  /** ability to view confirmed not disabled user */
  USER_VIEW: 'user_view',
  /**
   * admin permission to query all users including disabled and unconfirmed,
   * and allow to display user admin list page
   */
  USER_VIEW_ADMIN_INACTIVE: 'user_view_admin_inactive',
} as const;

export type UserPermission =
  (typeof UserPermission)[keyof typeof UserPermission];
export const UserRole = {
  ADMIN: 'admin',
  EDITOR: 'editor',
  VIEWER: 'viewer',
} as const;

export type UserRole = (typeof UserRole)[keyof typeof UserRole];
export const UserSortField = {
  EMAIL: 'email',
  LASTLOGIN: 'lastLogin',
  LASTNAME: 'lastName',
  SERVICE: 'service',
  STATUS: 'status',
} as const;

export type UserSortField = (typeof UserSortField)[keyof typeof UserSortField];
