import { useMemo } from 'react';

import { useLocalizedCountryMap } from '../../hooks/localizedCountries';

import FacetFilterSelectList, {
  FacetFilterSelectListProps,
} from './FacetFilterSelectList';

export default function CountryFilterSelectList<
  Filter,
  Prop extends keyof Filter,
>(props: Omit<FacetFilterSelectListProps<Filter, Prop>, 'optionLookup'>) {
  const countryMap = useLocalizedCountryMap();

  const optionLookup = useMemo(() => {
    if (!countryMap) return null;
    // eslint-disable-next-line unicorn/no-array-reduce
    return Object.entries(countryMap).reduce<Record<string, string>>(
      (acc, [key, value]) => {
        acc[key] = `${key} - ${value}`;
        return acc;
      },
      {},
    );
  }, [countryMap]);
  if (!optionLookup) return null;

  return <FacetFilterSelectList {...props} optionLookup={optionLookup} />;
}
