import { match } from 'ts-pattern';

import FinalizeButton from '../../../../components/scans/FinalizeButton';

import { MrzReviewButton } from './MrzReviewButton';
import { SwitchTabContextProvider } from './contexts/switch_tab_context_provider';
import { ReviewFeed } from './review_feed';
import { ScanImportSwitchTabs } from './scan_import_switch_tabs';

import { GqlScanQuery } from '#gql';
import { assert } from '#utils/assert';

type Scan = GqlScanQuery['scan'];

interface ScanInformationProps {
  scan: Scan;
}

export function ScanImportProcess(props: ScanInformationProps) {
  const { scan } = props;

  const { importData } = scan;
  assert(importData);

  const hasMrz = !!importData.parameters.mrz;
  return (
    <SwitchTabContextProvider
      step={importData.__typename}
      hasMrz={hasMrz}
      scanId={scan.id}
    >
      <div className="flex gap-5">
        <div className="flex min-w-[24rem] max-w-sm flex-col gap-5">
          <ReviewFeed step={importData.__typename} status={scan.status} />
          <div className="flex items-center justify-start">
            {match(importData)
              .with(
                { __typename: 'ProcessReview' },
                { __typename: 'ProcessFinalize' },
                (importData) => {
                  assert(scan.document);
                  assert(scan.importData);

                  if (hasMrz && importData.mrz?.mustBeReviewed) {
                    return <MrzReviewButton />;
                  }

                  return (
                    <FinalizeButton
                      disabled={importData.__typename === 'ProcessFinalize'}
                      scanId={scan.id}
                      scanName={scan.filename}
                      documentFields={
                        importData.__typename === 'ProcessFinalize'
                          ? undefined
                          : importData.documentFields
                      }
                      document={scan.document}
                    />
                  );
                },
              )
              .otherwise(() => null)}
          </div>
        </div>

        <div className="w-full min-w-0">
          <ScanImportSwitchTabs scan={scan} />
        </div>
      </div>
    </SwitchTabContextProvider>
  );
}
