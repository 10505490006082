import { useMemo } from 'react';

interface RectoVersoDocument<T> {
  imageRecto?: T;
  imageVerso?: T;
}

export function useRectoVerso<T>(document: RectoVersoDocument<T>) {
  return useMemo(() => {
    const recto = document.imageRecto;
    const verso = document.imageVerso;

    const canBeFlipped = Boolean(recto && verso);
    const initial = recto || verso;

    return {
      canBeFlipped,
      initial,
      recto,
      verso,
    };
  }, [document.imageRecto, document.imageVerso]);
}
