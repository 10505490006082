import { ReactNode } from 'react';
import { IntlProvider } from 'react-intl';

import { useLocale } from './providers/LocaleProvider.context';

import { mapLocaleToIntlLocale } from '#intl';

export default function IntlLocaleProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [locale] = useLocale();

  if (!locale) {
    return null;
  }

  return (
    <IntlProvider locale={mapLocaleToIntlLocale[locale]}>
      {children}
    </IntlProvider>
  );
}
