import { ArchiveBoxIcon, BoltIcon } from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';
import { FormattedDate } from 'react-intl';
import { Link } from 'react-router-dom';
import { match } from 'ts-pattern';

import { useDocumentSetContext } from '../../contexts/documentSet';
import { safeTranslationKeyDocumentSetType } from '../../translationsMapping/documentSets';
import { TRANSLATIONS_KEY_DOCUMENT_TYPE } from '../../translationsMapping/documents';
import CountryName from '../CountryName';
import ImgLink from '../ImgLink';
import { IndexFilterError } from '../TableSearch/IndexFilterError';
import { TableSearchLayout } from '../TableSearch/TableSearchLayout';
import IfHasPermission from '../permissions/IfHasPermission';
import FormattedButton from '../translation/FormattedButton';
import { FormattedTable } from '../translation/FormattedTable';
import IndexedFormattedMessage from '../translation/IndexedFormattedMessage';

import { DocumentSetSearchFilter } from './DocumentSetSearchFilter';

import {
  DocumentSetType,
  GqlDocumentSetsQuery,
  useDocumentSetsQuery,
} from '#gql';
import { Td, Th } from '#tailwind_ui';
import { HighlightedLink } from '#ui/link';
import { PageLayout } from '#ui/page_layout';

export default function DocumentSetsIndexPage() {
  return (
    <PageLayout
      title={<T keyName="page.doc_sets.title" />}
      actions={
        <IfHasPermission userPermission="documentSet_create">
          <FormattedButton
            as={Link}
            to="/document-sets/new"
            messageId="page.doc_sets.create"
          />
        </IfHasPermission>
      }
    >
      <DocumentSetsTableSearch />
    </PageLayout>
  );
}

function DocumentSetsTableSearch() {
  const context = useDocumentSetContext();
  const queryResult = useDocumentSetsQuery({
    variables: {
      ...context.pagination,
      sort: context.sort,
      filters: context.filters,
    },
  });

  const error = queryResult.error;
  const data = queryResult.data || queryResult.previousData;

  if (error) return <IndexFilterError error={error} context={context} />;
  if (!data) return null;

  const { nodes, totalCount, facets } = data.documentSets;

  return (
    <TableSearchLayout
      context={context}
      advancedFilter={
        <DocumentSetSearchFilter
          facets={context.store.getCorrectedFacets(facets)}
        />
      }
    >
      <FormattedTable
        data={nodes}
        renderTr={renderTr}
        renderHeader={renderHeader}
        pagination={{
          ...context.tablePagination,
          totalCount,
        }}
        sort={context.tableSort}
        renderEmpty={renderEmpty}
      />
    </TableSearchLayout>
  );
}

function renderHeader() {
  return (
    <tr>
      <Th className="w-[200px]" />
      <Th sortField="name">
        <T keyName="global.name" />
      </Th>
      <Th sortField="type">
        <T keyName="doc_set.field.type" />
      </Th>
      <Th sortField="docType">
        <T keyName="doc.field.type" />
      </Th>
      <Th sortField="docCountry">
        <T keyName="doc.field.country" />
      </Th>
      <Th className="w-[200px]">
        <T keyName="global.title.nb_doc" />
      </Th>
      <Th sortField="createdAt">
        <T keyName="doc.table.created_at" />
      </Th>
    </tr>
  );
}

type DocumentNode = GqlDocumentSetsQuery['documentSets']['nodes'][number];
function renderTr(data: DocumentNode) {
  const first = data.documents.nodes[0];
  const image = first?.images[0];

  return (
    <tr className="even:bg-neutral-50">
      <Td>
        {image && (
          <ImgLink
            src={image.document?.url || ''}
            thumbSrc={image.documentThumb?.url || ''}
            imgStyle={{
              borderRadius: '0.4rem',
              maxWidth: 200,
              maxHeight: 100,
            }}
          />
        )}
      </Td>
      <Td>
        <HighlightedLink to={`/document-sets/${data.id}`}>
          {data.name}
        </HighlightedLink>
      </Td>
      <Td>
        <span className="flex items-center gap-2">
          {match(data.type)
            .with(DocumentSetType.DYNAMIC, () => <BoltIcon className="h-5" />)
            .with(DocumentSetType.MANUAL, () => (
              <ArchiveBoxIcon className="h-5" />
            ))
            .exhaustive()}
          <T keyName={safeTranslationKeyDocumentSetType(data.type)} />
        </span>
      </Td>
      <Td>
        <IndexedFormattedMessage
          translationMap={TRANSLATIONS_KEY_DOCUMENT_TYPE}
          index={data.docType}
        />
      </Td>
      <Td>
        <span>{data.docCountry}</span>
        {' - '}
        <CountryName country={data.docCountry} />
      </Td>
      <Td>{data.documents.totalCount}</Td>
      <Td>
        <FormattedDate value={data.createdAt} />
      </Td>
    </tr>
  );
}

function renderEmpty() {
  return <T keyName="page.doc_sets.no_sets" />;
}

// eslint-disable-next-line react-refresh/only-export-components
export const docSetsRenderEmpty = renderEmpty;
