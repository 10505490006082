import { useApolloClient } from '@apollo/client';
import { UserIcon } from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  requiredEmail,
  requiredObject,
  requiredString,
} from '../forms/validation';

import NarrowContainer from './NarrowContainer';
import {
  LoginCredentials,
  useCleanupSession,
  useLogin,
} from './providers/LoginProvider.context';
import FormattedButton from './translation/FormattedButton';
import FormattedFormError from './translation/FormattedFormError';
import FormattedInputField from './translation/FormattedInputField';
import FormattedSubmitButton from './translation/FormattedSubmitButton';

import {
  Color,
  FormRHF,
  FormRHFDialogBody,
  FormRHFDialogFooter,
  FormRHFDialogRoot,
  FormRHFDialogTitle,
} from '#tailwind_ui';
import { H2 } from '#ui/heading';

const validationSchema = requiredObject({
  email: requiredEmail,
  password: requiredString,
});

export function LoginPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultEmail = searchParams.get('defaultEmail');
  return (
    <NarrowContainer>
      <LoginForm
        titleId="login.title"
        defaultEmail={defaultEmail}
        onLogin={() => {
          if (defaultEmail) {
            const searchParamsCopy = new URLSearchParams(searchParams);
            searchParamsCopy.delete('defaultEmail');
            setSearchParams(searchParamsCopy, { replace: true });
          }
        }}
      />
    </NarrowContainer>
  );
}

export function LostLoginModal(props: { email: string }) {
  const cleanup = useCleanupSession();
  const apollo = useApolloClient();
  const login = useLogin();

  function onOpenChange(value: boolean) {
    if (value) return;
    cleanup(apollo);
  }

  const defaultValues = useMemo<LoginCredentials>(
    () => ({ email: props.email || '', password: '' }),
    [props.email],
  );

  return (
    <FormRHFDialogRoot<LoginCredentials>
      icon={<UserIcon />}
      open
      preventCloseOnEscape
      preventCloseOnInteractOutside
      size="small"
      onOpenChange={onOpenChange}
      id="login-form"
      defaultValues={defaultValues}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        await login(values);
        apollo.resetStore().catch(reportError);
      }}
    >
      <FormRHFDialogTitle>
        <T keyName="login.lost.title" />
      </FormRHFDialogTitle>
      <FormRHFDialogBody>
        <LoginFormFields autoFocusUsername={!props.email} />
      </FormRHFDialogBody>
      <FormRHFDialogFooter>
        <LoginFormFooter withLogout />
      </FormRHFDialogFooter>
    </FormRHFDialogRoot>
  );
}

interface LoginFormProps {
  titleId: TranslationKey;
  defaultEmail?: string | null;
  onLogin?: () => void;
  withLogout?: boolean;
}

function LoginForm(props: LoginFormProps) {
  const { defaultEmail, onLogin, titleId, withLogout = false } = props;

  const login = useLogin();
  const defaultValues = useMemo<LoginCredentials>(
    () => ({ email: defaultEmail || '', password: '' }),
    [defaultEmail],
  );

  return (
    <FormRHF<LoginCredentials>
      id="login-form"
      defaultValues={defaultValues}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        await login(values);
        onLogin?.();
      }}
    >
      <H2>
        <T keyName={titleId} />
      </H2>
      <LoginFormFields autoFocusUsername={!defaultEmail} />
      <div className="flex flex-row gap-5">
        <LoginFormFooter withLogout={withLogout} />
      </div>
    </FormRHF>
  );
}

interface LoginFormFieldsProps {
  autoFocusUsername: boolean;
}

function LoginFormFields(props: LoginFormFieldsProps) {
  const autoFocusPassword = !props.autoFocusUsername;

  return (
    <>
      <FormattedInputField
        type="email"
        name="email"
        label="global.email"
        autoComplete="username"
        autoFocus={props.autoFocusUsername}
      />
      <FormattedInputField
        type="password"
        name="password"
        label="global.password"
        autoComplete="current-password"
        autoFocus={autoFocusPassword}
      />
      <FormattedFormError />
    </>
  );
}

interface LoginFormFooterProps {
  withLogout: boolean;
}

function LoginFormFooter(props: LoginFormFooterProps) {
  const cleanup = useCleanupSession();
  const client = useApolloClient();

  return (
    <>
      <FormattedSubmitButton color="primary" messageId="login.title" />
      {props.withLogout && (
        <FormattedButton
          color={Color.neutral}
          messageId="nav.logout"
          onClick={() => cleanup(client)}
        />
      )}
    </>
  );
}
