import { T, TranslationKey } from '@tolgee/react';

import { safeMappedTranslationKey } from '../../translationsMapping/common';

import { PropsOf } from '#tailwind_ui';

export interface IndexedFormattedMessageProps<Index extends string>
  extends Omit<PropsOf<typeof T>, 'keyName' | 'children'> {
  translationMap: Record<Index, TranslationKey>;
  index: Index | null | undefined;
}

export default function IndexedFormattedMessage<Index extends string>(
  props: IndexedFormattedMessageProps<Index>,
) {
  const { translationMap, index, ...rest } = props;
  return (
    <T keyName={safeMappedTranslationKey(translationMap, index)} {...rest} />
  );
}
