import clsx from 'clsx';
import { DetailedHTMLProps, HTMLAttributes } from 'react';

export function LinkLike(
  props: DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>,
) {
  const { className, ...otherProps } = props;
  return (
    <span
      {...otherProps}
      className={clsx('cursor-pointer text-primary-500 underline', className)}
    />
  );
}
