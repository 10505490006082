import { T, useTranslate } from '@tolgee/react';

import {
  optionalString,
  requiredEmail,
  requiredObject,
  requiredRoles,
} from '../../../forms/validation';
import SelectService from '../../form/SelectService';
import UserRolesField from '../../form/UserRolesField';
import FormattedButton from '../../translation/FormattedButton';
import FormattedFormError from '../../translation/FormattedFormError';
import FormattedInputField from '../../translation/FormattedInputField';
import FormattedSubmitButton from '../../translation/FormattedSubmitButton';

import ActivateMailtoLink from './ActivateMailtoLink';

import { GqlInitUserInput, useInitUserMutation } from '#gql';
import { FormattedAlert } from '#intl';
import {
  AlertButton,
  AlertButtons,
  ClipboardButton,
  FormRHF,
  useNotificationCenter,
} from '#tailwind_ui';
import { PageLayout, PageLayoutNavigation } from '#ui/page_layout';
import { assertNotNull } from '#utils/assert';
import { getActivateURL } from '#utils/index';

const validationSchema = requiredObject({
  email: requiredEmail,
  service: optionalString,
  roles: requiredRoles,
});

export default function CreateUser() {
  const { t } = useTranslate();
  const { addNotification } = useNotificationCenter();
  const [initUser, { data, reset }] = useInitUserMutation();

  if (data) {
    assertNotNull(data.initUser.activationToken);
    const confirmLink = getActivateURL(
      data.initUser.activationToken,
      data.initUser.email,
    );

    return (
      <PageLayout
        title={<T keyName="admin.create_user.title" />}
        navigation={
          <PageLayoutNavigation to="/admin/users">
            <T keyName="nav.admin.users" />
          </PageLayoutNavigation>
        }
        actions={
          <FormattedButton
            messageId="admin.create_user.recreate"
            onClick={reset}
          />
        }
        bodyClassName="max-w-xl"
        footerClassName="max-w-xl"
      >
        <FormattedAlert type="success" title="admin.create_user.success_title">
          <div>
            {t('admin.create_user.success_message', {
              email: (
                <pre key="email" className="inline">
                  {data.initUser.email}
                </pre>
              ),
            })}
          </div>
          <AlertButtons>
            <label className="inline-flex cursor-pointer items-center gap-2 rounded-md bg-success-50 pr-2 hover:bg-success-100 focus:outline-none focus:ring-2 focus:ring-success-600">
              <ClipboardButton
                onCopy={() => Promise.resolve(confirmLink)}
                onSuccess={() =>
                  addNotification({
                    type: 'success',
                    title: (
                      <T keyName="admin.create_user.copied_to_clipboard" />
                    ),
                  })
                }
              />
              <span className="text-sm font-medium text-success-800">
                <T keyName="admin.create_user.copy_link" />
              </span>
            </label>

            <span className="[&>a_button]:h-full">
              <ActivateMailtoLink
                email={data.initUser.email}
                token={data.initUser.activationToken}
              >
                <AlertButton>
                  <T keyName="admin.create_user.mailto_link" />
                </AlertButton>
              </ActivateMailtoLink>
            </span>
          </AlertButtons>
        </FormattedAlert>
      </PageLayout>
    );
  }

  return (
    <FormRHF<GqlInitUserInput>
      noDefaultStyle
      defaultValues={{ roles: ['viewer'] }}
      validationSchema={validationSchema}
      onSubmit={(values) =>
        initUser({
          variables: {
            input: { ...values },
          },
        })
      }
    >
      <PageLayout
        title={<T keyName="admin.create_user.title" />}
        actions={
          <FormattedSubmitButton color="primary" messageId="global.create" />
        }
        navigation={
          <PageLayoutNavigation to="/admin/users">
            <T keyName="nav.admin.users" />
          </PageLayoutNavigation>
        }
        bodyClassName="max-w-xl mx-auto"
        footerClassName="max-w-xl mx-auto"
        footer={
          <FormattedSubmitButton color="primary" messageId="global.create" />
        }
      >
        <FormattedInputField
          required
          type="email"
          name="email"
          label="global.email"
          autoFocus
        />
        <SelectService name="service" clearable />
        <UserRolesField name="roles" />
        <FormattedFormError />
      </PageLayout>
    </FormRHF>
  );
}
