import {
  getAlertDraftViewUrl,
  getAlertPublicationViewUrl,
} from '../../utils/navigation';
import HeaderWarning, { WarningMessage } from '../HeaderWarning';

import { GqlAlertPublicationQuery } from '#gql';

export default function AlertPublicationWarning(props: {
  alert: GqlAlertPublicationQuery['alert'];
}) {
  const {
    alert: { id, publication, currentPublication, draft, status },
  } = props;
  const language = publication.translation.language;
  let warning: WarningMessage | null = null;

  if (status === 'ARCHIVED') {
    warning = {
      message: 'series.alert.warning.archived',
    };
  } else if (!publication.isCurrent) {
    warning = {
      message: 'series.alert.warning.not_current',
      link: currentPublication
        ? {
            message: 'series.alert.warning.link.view_latest_publication',
            url: getAlertPublicationViewUrl(
              id,
              currentPublication.id,
              language,
            ),
          }
        : null,
    };
  } else if (draft) {
    warning = {
      message: 'series.alert.warning.has_draft',
      link: {
        message: 'series.alert.warning.link.go_to_draft',
        url: getAlertDraftViewUrl(id, language),
      },
    };
  }

  return <HeaderWarning warning={warning} />;
}
