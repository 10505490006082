import { TableSearchContextProvider } from '../components/providers/TableSearchContextProvider';
import { documentSetDynamicDocumentContext } from '../contexts/documentSeDynamicDocument';
import { MandatoryChildren } from '../global';
import { UsePaginationTableInitial } from '../hooks/usePaginationTable';
import { SortTable } from '../hooks/useSortTable';
import {
  DocumentSetDocumentFilters,
  DocumentSetDocumentFiltersStore,
} from '../stores/searches/DocumentSetDocumentFiltersStore';

import { DocumentSortField } from '#gql';

const initialSort = {
  field: 'createdAt',
  direction: 'DESC',
} as const satisfies SortTable<DocumentSortField>;

const initialPagination: UsePaginationTableInitial = {
  page: 0,
  itemsPerPage: 6,
  label: 'page.doc.list.filter_info',
};

interface DocumentSetDynamicDocumentTableSearchProviderProps
  extends MandatoryChildren {
  initStore?: (store: DocumentSetDocumentFiltersStore) => void;
}

export function DocumentSetDynamicDocumentTableSearchProvider(
  props: DocumentSetDynamicDocumentTableSearchProviderProps,
) {
  return (
    <TableSearchContextProvider<
      DocumentSortField,
      DocumentSetDocumentFilters,
      typeof DocumentSetDocumentFiltersStore,
      DocumentSetDocumentFiltersStore
    >
      context={documentSetDynamicDocumentContext}
      Store={DocumentSetDocumentFiltersStore}
      initStore={props.initStore}
      initialSort={initialSort}
      initialPagination={initialPagination}
    >
      {props.children}
    </TableSearchContextProvider>
  );
}
