import { TableSearchBaseFilters } from '../../components/providers/TableSearchContextProvider';
import { SyncSearchParamsStoreSupportedTypes } from '../SyncSearchParamsStore';
import { SyncSearchParamsWithTermStore } from '../SyncSearchParamsWithTermStore';

import {
  AlertStatus,
  DocumentType,
  FraudType,
  GqlSerieFiltersInput,
} from '#gql';

export interface SeriesFilters extends TableSearchBaseFilters {
  docType: DocumentType[];
  docFraudType: FraudType[];
  docCountry: string[];
  alertStatus: Array<AlertStatus | null>;
  alertDraft: boolean[];
}

export class SeriesFiltersStore extends SyncSearchParamsWithTermStore<SeriesFilters> {
  public get shapeDef() {
    return {
      searchTerm: 'string',
      docType: 'string[]',
      docFraudType: 'string[]',
      docCountry: 'string[]',
      alertStatus: 'string[]',
      alertDraft: 'boolean[]',
    } as const satisfies Record<
      keyof SeriesFilters,
      SyncSearchParamsStoreSupportedTypes
    >;
  }

  public get initialData(): SeriesFilters {
    return {
      searchTerm: null,
      docType: [],
      docFraudType: [],
      docCountry: [],
      alertStatus: [],
      alertDraft: [],
    };
  }

  /**
   * some arrays must be set to null because backend do not verify size of array when using it with IN operator
   *
   * TODO adapt backend query to verify empty filter
   */
  public get gqlFilter(): GqlSerieFiltersInput {
    const {
      searchTerm,
      docType,
      docFraudType,
      docCountry,
      alertStatus,
      alertDraft,
    } = this.data;

    return {
      searchTerm,
      docType,
      docFraudType,
      docCountry,
      alertStatus: alertStatus.length > 0 ? alertStatus : null,
      alertDraft: alertDraft.length > 0 ? alertDraft : null,
    };
  }
}
