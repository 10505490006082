import { T } from '@tolgee/react';
import { useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { requiredObject } from '../../forms/validation';
import FormattedFormError from '../translation/FormattedFormError';
import FormattedSubmitButton from '../translation/FormattedSubmitButton';

import DocumentSetSelectField, {
  DocumentSetSelectOption,
} from './DocumentSetSelectField';

import {
  useCreatePadifAnalysisMutation,
  usePadifAnalysesFromSetQuery,
} from '#gql';
import { FormattedAlert } from '#intl';
import { FormRHF } from '#tailwind_ui';
import { PageLayout, PageLayoutNavigation } from '#ui/page_layout';
import { assert, assertNotNull } from '#utils/assert';

interface PadifNewValues {
  documentSet: DocumentSetSelectOption | null;
}

const defaultValues: PadifNewValues = {
  documentSet: null,
};

const validationSchema = requiredObject({
  documentSet: requiredObject(),
});

export default function PadifNew() {
  const [createPadifAnalysis] = useCreatePadifAnalysisMutation();
  const navigate = useNavigate();

  async function onSubmit(values: PadifNewValues) {
    assertNotNull(values.documentSet);
    const result = await createPadifAnalysis({
      variables: {
        input: { documentSet: values.documentSet.value },
      },
    });
    assert(result.data);
    navigate(`/padif/${result.data.createPadifAnalysis.id}`);
  }

  return (
    <FormRHF<PadifNewValues>
      defaultValues={defaultValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      noDefaultStyle
    >
      <PageLayout
        title={<T keyName="page.padif.new.title" />}
        navigation={
          <PageLayoutNavigation to="/padif">
            <T keyName="nav.padif" />
          </PageLayoutNavigation>
        }
        actions={
          <FormattedSubmitButton color="primary" messageId="global.create" />
        }
        bodyClassName="mx-auto max-w-xl"
        footerClassName="mx-auto max-w-xl"
        footer={
          <FormattedSubmitButton color="primary" messageId="global.create" />
        }
      >
        <DocumentSetSelectField name="documentSet" />
        <ExistingAnalysesAlert />
        <FormattedFormError />
      </PageLayout>
    </FormRHF>
  );
}

function ExistingAnalysesAlert() {
  const documentSet: DocumentSetSelectOption | null = useWatch({
    name: 'documentSet',
  });
  const skip = documentSet === null;
  const setId = documentSet?.value || '';

  const { data, error } = usePadifAnalysesFromSetQuery({
    variables: { filterBy: { documentSets: [setId] } },
    skip,
  });
  if (error) throw error;
  if (!skip && !data) return null;
  return (
    <div>
      {!skip && data && data.padifAnalyses.totalCount > 0 && (
        <FormattedAlert
          type="warning"
          messageId="page.padif.new.exists"
          messageValues={{ count: data.padifAnalyses.totalCount }}
        />
      )}
    </div>
  );
}
