import clsx from 'clsx';
import { ReactNode } from 'react';
import { match } from 'ts-pattern';

import { MandatoryChildren } from '../../global';
import { GenericTableSearchContext } from '../providers/TableSearchContextProvider';
import FormattedButton from '../translation/FormattedButton';

import TableSearchButtonToggle from './TableSearchButtonToggle';
import TableSearchFilterContainer from './TableSearchFilterContainer';
import TableSearchInput from './TableSearchInput';

import { Color, Size, Variant } from '#tailwind_ui';

interface TableSearchLayoutProps<Context extends GenericTableSearchContext>
  extends MandatoryChildren {
  context: Context;

  className?: string;
  classNameOverride?: boolean;

  searchBarClassName?: string;
  searchBarClassNameOverride?: boolean;
  searchBarExtra?: ReactNode;

  advancedFilterTitle?: TranslationKey;
  advancedFilter: Exclude<ReactNode, null | undefined>;
}

export function TableSearchLayout<Context extends GenericTableSearchContext>(
  props: TableSearchLayoutProps<Context>,
) {
  const context = props.context;
  const children = props.children;

  return (
    <div
      className={clsx(!props.classNameOverride && 'space-y-2', props.className)}
    >
      {/* Advanced search and search term */}
      <div
        className={clsx(
          !props.searchBarClassNameOverride && 'flex gap-2',
          props.searchBarClassName,
        )}
      >
        <TableSearchButtonToggle context={context} />
        <TableSearchInput context={context} />

        {props.searchBarExtra}
      </div>

      {match(context.advancedSearch)
        .with(true, () => (
          <div className="flex">
            <TableSearchFilterContainer title={props.advancedFilterTitle}>
              <FormattedButton
                messageId="doc.search.filter.clear"
                color={Color.danger}
                variant={Variant.secondary}
                size={Size.xSmall}
                onClick={context.store.reset}
              />
              {props.advancedFilter}
            </TableSearchFilterContainer>

            <div className="grow-[10]">{children}</div>
          </div>
        ))
        .otherwise(() => children)}
    </div>
  );
}
