import { ArrowPathIcon } from '@heroicons/react/20/solid';
import {
  ArchiveBoxXMarkIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';
import { DocumentType, FraudType } from '@zakodium/profid-shared';
import { ReactNode } from 'react';

import SelectCountry, {
  SelectCountryProps,
} from '../../../../components/form/SelectCountry';
import {
  SelectDocumentType,
  SelectFraudType,
} from '../../../../components/form/SelectFields';
import FormattedCheckbox from '../../../../components/translation/FormattedCheckbox';
import FormattedEmptyState from '../../../../components/translation/FormattedEmptyState';
import FormattedFormError from '../../../../components/translation/FormattedFormError';
import FormattedInputField from '../../../../components/translation/FormattedInputField';
import { LabelPreview } from '../preview/preview';

import { shuffleLabelColors } from './shuffle_label_colors';

import { IconButton, Tooltip, useCheckedFormRHFContext } from '#tailwind_ui';
import { chooseBetterContrast } from '#utils/color';

export interface LabelFieldsProps {
  docType?: DocumentType[];
  docFraudType?: FraudType[];
  docCountry?: string[];

  isScopedLabel?: boolean;
  setIsScopedLabel?: (value: boolean) => void;

  scopeWarning?: ReactNode;
}

const jsxDetails = {
  summary: (content: string) => <summary>{content}</summary>,
  ul: (content: string) => <ul className="pl-3.5">{content}</ul>,
  li: (content: string) => <li className="list-disc">{content}</li>,
};

export function LabelFields(props: LabelFieldsProps) {
  const {
    docType,
    docFraudType,
    docCountry,
    isScopedLabel,
    setIsScopedLabel,
    scopeWarning,
  } = props;

  const docTypeProps = docType ? { enumValues: docType } : {};
  const docFraudTypeProps = docFraudType ? { enumValues: docFraudType } : {};
  const docCountryProps: Partial<SelectCountryProps> = docCountry
    ? { enumValues: docCountry }
    : {};

  return (
    <div className="xl:grid xl:grid-cols-2 xl:gap-5">
      <fieldset className="space-y-2">
        <legend className="mb-1 font-semibold text-neutral-700">
          <T keyName="label.field.main_info" />
        </legend>

        <FormattedInputField name="name" label="label.field.name" required />
        <FormattedInputField
          name="description"
          label="label.field.description"
        />

        <ColorField
          name="color.background"
          label="label.field.color"
          required
          allowShuffle
          complementaryShuffleName="color.text"
        />
        <ColorField
          name="color.text"
          label="label.field.color"
          required
          disabled
          hidden
        />

        <div className="flex flex-1 flex-row gap-2">
          <FormattedCheckbox
            name="scopeLabel"
            label="label.field.check.scope"
            checked={isScopedLabel}
            onChange={(event) => {
              setIsScopedLabel?.(event.currentTarget.checked);
            }}
          />
          <Tooltip
            placement="right"
            content={
              <div className="m-5 flex flex-col gap-5">
                <p className="text-sm font-semibold">
                  <T keyName="series.alert.field.help.title" />
                </p>

                <p className="whitespace-pre-line">
                  <T keyName="series.alert.field.help.short" />
                </p>

                <div className="max-w-md">
                  <T
                    keyName="series.alert.field.help.details"
                    params={jsxDetails}
                  />
                </div>
              </div>
            }
          >
            <QuestionMarkCircleIcon className="h-5 w-5" />
          </Tooltip>
        </div>
      </fieldset>

      <fieldset className="space-y-2">
        {!isScopedLabel ? (
          <div className="mx-12 mt-12 rounded-md border border-neutral-200 p-14">
            <FormattedEmptyState
              title="label.field.no.scope"
              icon={<ArchiveBoxXMarkIcon />}
            />
          </div>
        ) : (
          <>
            <legend className="mb-1 font-semibold text-neutral-700">
              <T keyName="label.field.subset" />
            </legend>

            <SelectDocumentType name="docType" clearable {...docTypeProps} />
            <SelectFraudType
              name="docFraudType"
              clearable
              {...docFraudTypeProps}
            />
            <SelectCountry
              name="docCountry"
              label="doc.field.country"
              clearable
              {...docCountryProps}
            />
          </>
        )}

        {scopeWarning}
      </fieldset>

      <div className="col-span-2">
        <FormattedFormError />
      </div>

      <div className="col-span-2 place-self-center">
        <LabelPreview />
      </div>
    </div>
  );
}

interface ColorFieldProps {
  name: string;
  label: TranslationKey;
  required?: boolean;
  allowShuffle?: boolean;
  complementaryShuffleName?: string;
  disabled?: boolean;
  hidden?: boolean;
}
function ColorField(props: ColorFieldProps) {
  const {
    name,
    label,
    required,
    allowShuffle,
    complementaryShuffleName,
    disabled,
    hidden,
  } = props;

  const form = useCheckedFormRHFContext();

  if (hidden) return <input type="hidden" {...form.register(name)} />;

  function shuffle() {
    const { background, text } = shuffleLabelColors();

    form.setValue(name, background, { shouldTouch: true, shouldDirty: true });
    if (!complementaryShuffleName) return;
    form.setValue(complementaryShuffleName, text, {
      shouldTouch: true,
      shouldDirty: true,
    });
  }

  return (
    <FormattedInputField
      name={name}
      label={label}
      type={hidden ? 'hidden' : 'color'}
      required={required}
      disabled={disabled}
      corner={
        !disabled &&
        allowShuffle && (
          <IconButton
            tooltip={<T keyName="label.field.color.shuffle" />}
            icon={<ArrowPathIcon />}
            size="5"
            onClick={shuffle}
          />
        )
      }
      onChange={(event) => {
        if (!complementaryShuffleName) return;

        const color = chooseBetterContrast(event.currentTarget.value, [
          '#000000',
          '#FFFFFF',
        ]);

        form.setValue(complementaryShuffleName, color, {
          shouldTouch: true,
          shouldDirty: true,
        });
      }}
    />
  );
}
