import {
  DocumentExtraImageLight,
  DocumentMedium,
  DocumentSetType,
  DocumentType,
  DocumentView,
  FraudType,
  Offense,
  PrintProcess,
  SeizureContext,
} from '#gql';

export const fraudTypeValues = Object.values(FraudType);
export const seizureContextValues = Object.values(SeizureContext);
export const offenseValues = Object.values(Offense);
export const documentMediumValues = Object.values(DocumentMedium);
export const printProcessValues = Object.values(PrintProcess);
export const documentTypeValues = Object.values(DocumentType);
export const extraDocumentLightValues = Object.values(DocumentExtraImageLight);
export const documentViewValues = Object.values(DocumentView);
export const documentSetTypeValues = Object.values(DocumentSetType);
