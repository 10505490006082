import { createContext, useContext } from 'react';

import { GqlDetailedDocumentFieldsFragment } from '#gql';

export interface DocumentContextValue {
  document: GqlDetailedDocumentFieldsFragment;
}

export const documentContext = createContext<DocumentContextValue | null>(null);

export function useDocumentContext() {
  const context = useContext(documentContext);
  if (!context) {
    throw new Error('useDocumentContext must be used within a DocumentContext');
  }
  return context;
}
