import { createContext, useContext } from 'react';

import { TableSearchContext } from '../components/providers/TableSearchContextProvider';
import {
  LabelFilters,
  LabelFiltersStore,
} from '../stores/searches/LabelFiltersStore';

export const labelSupportedSortFields = ['name', 'createdAt'] as const;
export type LabelSupportedSortField = (typeof labelSupportedSortFields)[number];

export const labelsContext = createContext<TableSearchContext<
  LabelSupportedSortField,
  LabelFilters,
  LabelFiltersStore
> | null>(null);

export function useLabelsContext() {
  const context = useContext(labelsContext);

  if (context === null) {
    throw new Error(
      'useLabelsContext must be used within a TableSearchContextProvider',
    );
  }

  return context;
}
