import {
  BookOpenIcon,
  MagnifyingGlassMinusIcon,
  MagnifyingGlassPlusIcon,
} from '@heroicons/react/24/outline';
import { useTranslate } from '@tolgee/react';
import { useCallback } from 'react';
import { useActions } from 'react-roi';
import { Link } from 'react-router-dom';

import { Button, Roundness } from '#tailwind_ui';

export function RightToolbar() {
  const { t } = useTranslate();
  const { zoom } = useActions();

  const onZoom = useCallback(
    (factor: number) => {
      return () => zoom(factor);
    },
    [zoom],
  );

  return (
    <div className="flex flex-col justify-between">
      <Button
        as={Link}
        tooltip={t('document.anonymization.help')}
        roundness={Roundness.full}
        variant="white"
        to="/docs/documents/anonymization"
        target="_blank"
      >
        <BookOpenIcon className="h-5 w-5" />
      </Button>

      <div className="flex flex-col gap-1">
        <Button
          tooltip={t('document.anonymization.zoom_in')}
          roundness={Roundness.full}
          variant="white"
          onClick={onZoom(1.2)}
        >
          <MagnifyingGlassPlusIcon className="h-5 w-5" />
        </Button>

        <Button
          tooltip={t('document.anonymization.zoom_out')}
          roundness={Roundness.full}
          variant="white"
          onClick={onZoom(0.8)}
        >
          <MagnifyingGlassMinusIcon className="h-5 w-5" />
        </Button>
      </div>
    </div>
  );
}
