import { useLocation } from 'react-router-dom';

import { RequiredNotNull } from '../../global';

import { GqlDocumentsQueryVariables } from '#gql';

export type DocumentListQueryVariables = Omit<
  RequiredNotNull<GqlDocumentsQueryVariables>,
  'locale' | 'sortBy'
> &
  Pick<Required<GqlDocumentsQueryVariables>, 'sortBy'>;

export interface DocumentListLocationState {
  fromDocumentList: true;
  documentsQueryVariables: DocumentListQueryVariables;
  currentDocumentIndex: number;
}

export function useDocumentListLocationState() {
  const location = useLocation();
  const state = location.state;
  if (state?.fromDocumentList) {
    return state as DocumentListLocationState;
  } else {
    return null;
  }
}
