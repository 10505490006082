import { PencilIcon, TrashIcon } from '@heroicons/react/20/solid';
import { T } from '@tolgee/react';
import { useState } from 'react';

import {
  safeTranslationKeyDocumentExtraImageLight,
  safeTranslationKeyDocumentView,
} from '../../translationsMapping/documents';
import ImgLink from '../ImgLink';
import IfHasPermission from '../permissions/IfHasPermission';

import DocumentDeleteExtraImageModal from './DocumentDeleteExtraImageModal';
import DocumentUpdateExtraImageModal from './DocumentUpdateExtraImageModal';

import { GqlDetailedDocumentFieldsFragment } from '#gql';
import { Button, Color, Roundness } from '#tailwind_ui';
import { CompleteOverlay, PartialOverlay } from '#ui/overlay';

export interface DocumentExtraImagesProps {
  docId: string;
  extraImages: GqlDetailedDocumentFieldsFragment['extraImages'];
  docName: string;

  readonly?: boolean;
}

type Image = GqlDetailedDocumentFieldsFragment['extraImages'][number];

export default function DocumentExtraImages(props: DocumentExtraImagesProps) {
  const { docId, extraImages: images, docName, readonly } = props;

  const [deleteImage, setDeleteImage] = useState<Image | null>(null);
  const [updateImage, setUpdateImage] = useState<Image | null>(null);

  if (images.length === 0) {
    return (
      <i>
        <T keyName="global.no_images" />
      </i>
    );
  }

  return (
    <>
      <div className="columns-2 gap-3">
        {images.map((image) => {
          return (
            <DocumentExtraImage
              key={image.id}
              image={image}
              onUpdate={setUpdateImage}
              onDelete={setDeleteImage}
              readonly={readonly}
            />
          );
        })}
      </div>
      {deleteImage && (
        <DocumentDeleteExtraImageModal
          docId={docId}
          imageId={deleteImage.id}
          image={deleteImage.document}
          docName={docName}
          onCancel={() => setDeleteImage(null)}
        />
      )}
      {updateImage && (
        <DocumentUpdateExtraImageModal
          docId={docId}
          image={updateImage}
          onCancel={() => setUpdateImage(null)}
        />
      )}
    </>
  );
}

interface DocumentExtraImageProps {
  image: Image;
  onUpdate?: (image: Image) => void;
  onDelete?: (image: Image) => void;
  readonly?: boolean;
}

function DocumentExtraImage(props: DocumentExtraImageProps) {
  const { image, onUpdate, onDelete, readonly } = props;

  const actions = !readonly && (
    <IfHasPermission userPermission="document_update">
      <div className="flex gap-2 self-end">
        <Button
          className="pointer-events-auto"
          roundness={Roundness.circular}
          color={Color.primary}
          onClick={() => onUpdate?.(image)}
        >
          <PencilIcon className="h-5 w-5" />
        </Button>
        <Button
          className="pointer-events-auto"
          roundness={Roundness.circular}
          color={Color.danger}
          onClick={() => onDelete?.(image)}
        >
          <TrashIcon className="h-5 w-5" />
        </Button>
      </div>
    </IfHasPermission>
  );

  const Overlay = readonly ? PartialOverlay : CompleteOverlay;

  return (
    <div className="group static">
      <div className="relative min-h-[200px] break-inside-avoid">
        <ImgLink src={image.document.url} imgClassName="p-2" />

        <Overlay>
          <div>
            <T keyName="doc.extra_image.light" />
            {' - '}
            <T
              keyName={safeTranslationKeyDocumentExtraImageLight(image.light)}
            />
          </div>
          <div>
            <T keyName="doc.document_view" />
            {' - '}
            <T keyName={safeTranslationKeyDocumentView(image.view)} />
          </div>
          <div className="mt-2 whitespace-pre-line">{image.comment}</div>

          <div className="flex-1" />

          {actions}
        </Overlay>
      </div>
    </div>
  );
}
