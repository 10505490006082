import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { usePreventNavigationShouldBlockContext } from '../contexts/PreventNavigation/PreventNavigationShouldBlockContext';

/**
 * To use in a components rendering a FormPreventNavigation
 * your onSubmit should set saved to true
 * for some reason, useBlocker implementation of react-router is not sync so queueMicrotask is needed to navigate.
 *
 * @param saved
 * @param path
 */
export function useNavigateAfterSave(saved: boolean, path: string) {
  const navigate = useNavigate();
  const shouldBlock = usePreventNavigationShouldBlockContext(); // wait form is fully submitted

  useEffect(() => {
    if (!saved) return;
    if (shouldBlock) return;

    queueMicrotask(() => navigate(path));
  }, [saved, path, navigate, shouldBlock]);
}
