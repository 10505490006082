import { endOfDay, startOfDay } from 'date-fns/fp';

import { TableSearchBaseFilters } from '../../components/providers/TableSearchContextProvider';
import { SyncSearchParamsStoreSupportedTypes } from '../SyncSearchParamsStore';
import { SyncSearchParamsWithTermStore } from '../SyncSearchParamsWithTermStore';

import { DocumentSetType, DocumentType } from '#gql';

export interface DocumentSetFilters extends TableSearchBaseFilters {
  type: DocumentSetType[];
  docType: DocumentType[];
  docCountry: string[];
  startDate: Date | null;
  endDate: Date | null;
}

export class DocumentSetFiltersStore extends SyncSearchParamsWithTermStore<DocumentSetFilters> {
  public get shapeDef() {
    return {
      searchTerm: 'string',
      type: 'string[]',
      docType: 'string[]',
      docCountry: 'string[]',
      startDate: 'date',
      endDate: 'date',
    } as const satisfies Record<
      keyof DocumentSetFilters,
      SyncSearchParamsStoreSupportedTypes
    >;
  }

  public get initialData(): DocumentSetFilters {
    return {
      searchTerm: null,
      type: [],
      docType: [],
      docCountry: [],
      startDate: null,
      endDate: null,
    };
  }

  public setStartDate = (startDate: Date | null) => {
    startDate ??= null;

    if (startDate) {
      startDate = startOfDay(startDate);
    }

    this.mergeData({ startDate });
  };
  public setEndDate = (endDate: Date | null) => {
    endDate ??= null;

    if (endDate) {
      endDate = endOfDay(endDate);
    }

    this.mergeData({ endDate });
  };
}
