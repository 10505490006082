import { useTranslate } from '@tolgee/react';
import { PropsWithChildren } from 'react';
import { FormattedDate } from 'react-intl';

import { MailToUser } from './MailToUser';

import { GqlMailableUserFragment } from '#gql';

export interface UserChangesMetaProps extends PropsWithChildren {
  createdAt: Date;
  updatedAt: Date;

  createdBy: GqlMailableUserFragment;
  updatedBy: GqlMailableUserFragment;

  mailSubject: string;
  mailBody: string;
}

export function UserChangesMeta(props: UserChangesMetaProps) {
  const { t } = useTranslate();

  return (
    <div className="text-right text-sm text-neutral-500">
      <div>
        {t('global.created_by', {
          date: <FormattedDate key="createdAt" value={props.createdAt} />,
          createdBy: (
            <MailToUser
              key="createdBy"
              user={props.createdBy}
              subject={props.mailSubject}
              body={props.mailBody}
            />
          ),
        })}
      </div>
      <div>
        {t('global.last_modified_by', {
          date: <FormattedDate key="updatedAt" value={props.updatedAt} />,
          updatedBy: (
            <MailToUser
              key="updatedBy"
              user={props.updatedBy}
              subject={props.mailSubject}
              body={props.mailBody}
            />
          ),
        })}
      </div>

      {props.children}
    </div>
  );
}
