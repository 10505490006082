import {
  ArrowUturnLeftIcon,
  ArrowUturnRightIcon,
} from '@heroicons/react/20/solid';
import { PencilIcon } from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';
import { useCallback, useState } from 'react';

import FormattedButton from '../translation/FormattedButton';
import FormattedErrorAlert from '../translation/FormattedErrorAlert';

import { GqlDocumentEditableQuery, useRotateDocumentImageMutation } from '#gql';
import {
  Button,
  DialogBody,
  DialogFooter,
  DialogRoot,
  DialogTitle,
  Spinner,
  useOnOff,
} from '#tailwind_ui';

interface ImageRotateProps {
  docId: string;
  image: GqlDocumentEditableQuery['document']['images'][number];
}

export default function ImageRotate(props: ImageRotateProps) {
  const { docId, image } = props;

  const [isOpen, openModal, closeModal] = useOnOff();
  const [angle, setAngle] = useState(0);

  const rotate = useCallback(
    (direction: 'l' | 'r') => () => {
      if (direction === 'r') {
        return setAngle((angle + 90) % 360);
      }

      if (direction === 'l') {
        return setAngle((angle - 90) % 360);
      }
    },
    [setAngle, angle],
  );

  const [rotateDocumentImage, { loading, error }] =
    useRotateDocumentImageMutation();

  const sendRotation = useCallback(() => {
    void rotateDocumentImage({
      variables: {
        input: { id: docId, imageId: image.id, angle },
      },
    }).then(() => {
      setAngle(0);
      closeModal();
    });
  }, [rotateDocumentImage, docId, image.id, angle, closeModal]);

  function onOpenChange(value: boolean) {
    if (value) return;
    closeModal();
  }

  return (
    <>
      <Button onClick={openModal} className="pointer-events-auto">
        <div className="flex items-center gap-2">
          <ArrowUturnRightIcon className="h-5 w-5" />
          <T keyName="global.rotate" />
        </div>
      </Button>

      <DialogRoot
        icon={<PencilIcon />}
        open={isOpen}
        onOpenChange={onOpenChange}
      >
        <DialogTitle>
          <T keyName="doc.image.rotate.title" />
        </DialogTitle>
        <DialogBody>
          <div
            style={{
              width: '300px',
              height: '300px',
              transform: `rotate(${angle}deg)`,
            }}
            className="flex items-center justify-center"
          >
            <img
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                display: 'block',
                maxHeight: '300px',
                maxWidth: '300px',
              }}
              alt="thumbnail"
              src={image.documentThumb.url}
            />
          </div>

          <FormattedErrorAlert error={error} />
        </DialogBody>
        <DialogFooter className="flex justify-between">
          <div className="flex flex-1 gap-1">
            <Button onClick={rotate('l')} roundness="circular">
              <ArrowUturnLeftIcon className="h-5 w-5" />
            </Button>
            <Button onClick={rotate('r')} roundness="circular">
              <ArrowUturnRightIcon className="h-5 w-5" />
            </Button>
          </div>
          <div className="flex justify-center">
            {loading ? (
              <Spinner className="h-10 w-10" />
            ) : (
              <FormattedButton
                messageId="global.validate"
                onClick={sendRotation}
              />
            )}
          </div>
        </DialogFooter>
      </DialogRoot>
    </>
  );
}
