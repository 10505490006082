import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';

import { TableSearchPagination } from '../../hooks/usePaginationTable';
import { UseTableSelect } from '../../hooks/useTableSelection';
import FormattedEmptyState from '../translation/FormattedEmptyState';
import { FormattedPaginationText } from '../translation/FormattedPagination';

import { DocumentSetTableDocumentView } from './DocumentSetTableDocumentView';

import { GqlDocumentSetDocumentsQuery } from '#gql';
import { TableSortConfig } from '#tailwind_ui';

type DocumentSet = GqlDocumentSetDocumentsQuery['documentSet'];

export interface DocumentSetViewProps {
  documentSet: DocumentSet;
  tableSort: TableSortConfig;
  tablePagination: TableSearchPagination;
  selection: UseTableSelect<string>;
  selectedClassname?: string;
}

export default function DocumentSetView(props: DocumentSetViewProps) {
  const {
    documentSet,
    tableSort,
    tablePagination,
    selection,
    selectedClassname,
  } = props;

  const totalCount = documentSet.documents.totalCount;
  const documents = documentSet.documents.nodes;

  return (
    <DocumentSetTableDocumentView
      documents={documents}
      totalCount={totalCount}
      tableSort={tableSort}
      tablePagination={tablePagination}
      renderTextPagination={(actual) => (
        <FormattedPaginationText
          page={actual}
          total={totalCount}
          rowsPerPage={tablePagination.itemsPerPage}
          messageId="page.doc.list.filter_info"
        />
      )}
      renderEmpty={renderEmpty}
      selection={selection}
      selectedClassname={selectedClassname}
    />
  );
}

function renderEmpty() {
  return (
    <FormattedEmptyState
      title="page.padif.empty_set"
      icon={<DocumentDuplicateIcon />}
    />
  );
}
