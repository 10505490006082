import { useParams } from 'react-router-dom';

export function useNamedParamCheck(name: string): string {
  const params = useParams();

  if (!params[name]) {
    throw new Error(`Missing ${name} param`);
  }

  return params[name] as string;
}

export function useIdParam(): string {
  return useNamedParamCheck('id');
}

export function useSeqIdParam(): string {
  return useNamedParamCheck('seqId');
}

export function useImageIdParam(): string {
  return useNamedParamCheck('imageId');
}

export function useScanIdParam(): string {
  return useNamedParamCheck('scanId');
}
