import { T } from '@tolgee/react';
import { Link } from 'react-router-dom';

import {
  optionalString,
  requiredArray,
  requiredObject,
  requiredString,
} from '../../forms/validation';
import {
  documentViewValues,
  extraDocumentLightValues,
} from '../../graphql/enumValues';
import { useSeqIdParam } from '../../hooks/useParams';
import {
  SelectDocumentView,
  SelectExtraImageLight,
} from '../form/SelectFields';
import FormattedButton from '../translation/FormattedButton';
import FormattedDropzoneField from '../translation/FormattedDropzoneField';
import FormattedFormError from '../translation/FormattedFormError';
import FormattedSubmitButton from '../translation/FormattedSubmitButton';
import FormattedTextAreaField from '../translation/FormattedTextAreaField';

import DocumentExtraImages from './DocumentExtraImages';

import {
  DocumentExtraImageLight,
  DocumentView,
  useDocumentExtraImagesQuery,
  useUploadDocumentExtraImageMutation,
} from '#gql';
import { FormRHF, FormRHFProps } from '#tailwind_ui';
import { CardLayout } from '#ui/card_layout';
import { PageLayout, PageLayoutNavigation } from '#ui/page_layout';
import { assertNotNull } from '#utils/assert';
import { getDocumentTitle } from '#utils/index';

/**
 * This should be restricted to image formats supported by ImageJS.
 * Keep in sync with backend.
 */
const mimetypeAllowList = {
  'image/png': ['.png'],
  'image/tiff': ['.tiff', '.tif'],
  'image/jpeg': ['.jpeg', '.jpg'],
};

interface FormValues {
  files: File[];
  light: DocumentExtraImageLight | null;
  view: DocumentView | null;
  comment: string | null;
}

const defaultValues: FormValues = {
  files: [],
  light: null,
  view: null,
  comment: null,
};

const validationSchema = requiredObject({
  files: requiredArray,
  light: requiredString.oneOf(extraDocumentLightValues),
  view: requiredString.oneOf(documentViewValues),
  comment: optionalString,
});

export default function DocumentManageExtraImage() {
  const [uploadExtraImage] = useUploadDocumentExtraImageMutation();
  const seqId = useSeqIdParam();

  const { data, error, refetch } = useDocumentExtraImagesQuery({
    variables: { seqId },
  });

  if (error) throw error;
  if (!data) return null;

  const onUploadFormSubmit: FormRHFProps<FormValues>['onSubmit'] = async (
    values,
    event,
    form,
  ) => {
    const { files, light, view, comment } = values;
    assertNotNull(light);
    assertNotNull(view);
    await uploadExtraImage({
      variables: {
        input: {
          id: data.document.id,
          image: files[0],
          light,
          view,
          comment,
        },
      },
    });
    await refetch();
    form.reset();
  };

  return (
    <PageLayout
      title={<T keyName="doc.extra_images" />}
      navigation={
        <>
          <PageLayoutNavigation to="/documents">
            <T keyName="nav.documents.list" />
          </PageLayoutNavigation>
          <PageLayoutNavigation to={`/documents/${seqId}`}>
            {seqId} - {getDocumentTitle(data.document, true)}
          </PageLayoutNavigation>
        </>
      }
      actions={
        <FormattedButton
          messageId="doc.return"
          variant="white"
          as={Link}
          to={`/documents/${data.document.seqId}`}
        />
      }
      bodyClassName="grid grid-cols-2 gap-x-4 gap-y-2"
      noDefaultBodyClassName
    >
      <CardLayout title="doc.extra_image.upload.title">
        <FormRHF<FormValues>
          defaultValues={defaultValues}
          validationSchema={validationSchema}
          onSubmit={onUploadFormSubmit}
        >
          <FormattedDropzoneField
            multiple={false}
            replace
            accept={mimetypeAllowList}
            name="files"
            maxFiles={1}
            label="global.image"
            showList
            required
            showPreview
          />

          <SelectExtraImageLight name="light" required />
          <SelectDocumentView name="view" required />

          <FormattedTextAreaField name="comment" label="global.comment" />
          <FormattedFormError />
          <FormattedSubmitButton color="primary" messageId="global.send" />
        </FormRHF>
      </CardLayout>

      <CardLayout title="doc.extra_images">
        <DocumentExtraImages
          extraImages={data.document.extraImages}
          docId={data.document.id}
          docName={getDocumentTitle(data.document, true)}
        />
      </CardLayout>
    </PageLayout>
  );
}
