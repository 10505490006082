import { useTranslate } from '@tolgee/react';

import { useFormattedSerializeError } from '../../hooks/useFormattedSerializeError';

// eslint-disable-next-line no-restricted-imports
import { InputFieldRHF, InputFieldRHFProps } from '#tailwind_ui';

interface FormattedInputFieldProps
  extends Omit<
    InputFieldRHFProps,
    'serializeError' | 'label' | 'help' | 'placeholder'
  > {
  label: TranslationKey;
  labelValues?: Record<string, string | number | boolean>;
  help?: TranslationKey;
  helpValues?: Record<string, string | number | boolean>;
  placeholder?: TranslationKey;
}

export default function FormattedInputField(props: FormattedInputFieldProps) {
  const { label, help, placeholder, labelValues, helpValues, ...otherProps } =
    props;
  const { t } = useTranslate();
  const serialize = useFormattedSerializeError();

  return (
    <InputFieldRHF
      label={t(label, labelValues)}
      help={help && t(help, helpValues)}
      placeholder={placeholder && t(placeholder)}
      serializeError={serialize}
      {...otherProps}
    />
  );
}
