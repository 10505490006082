import { Language } from '#gql';

export function getAlertDraftEditUrl(id: string, language?: Language) {
  return `/alerts/${id}/draft/edit?${getSearchParams(language)}`;
}

export function getAlertDraftViewUrl(id: string, language?: Language) {
  return `/alerts/${id}/draft?${getSearchParams(language)}`;
}

export function getAlertPublicationViewUrl(
  id: string,
  publicationId: string,
  language?: Language,
) {
  return `/alerts/${id}/publication/${publicationId}?${getSearchParams(
    language,
  )}`;
}

function getSearchParams(language?: Language) {
  const searchParams = new URLSearchParams();

  if (language) {
    searchParams.set('language', language);
  }
  return searchParams.toString();
}
