import { useDocumentSetCandidateContext } from '../../contexts/documentSetCandidate';
import { UseTableSelect } from '../../hooks/useTableSelection';
import { IndexFilterError } from '../TableSearch/IndexFilterError';
import { TableSearchLayout } from '../TableSearch/TableSearchLayout';
import FormattedEmptyState from '../translation/FormattedEmptyState';
import { FormattedPaginationText } from '../translation/FormattedPagination';

import { DocumentSetDocumentsSearchFilter } from './DocumentSetDocumentsSearchFilter';
import { DocumentSetTableDocumentView } from './DocumentSetTableDocumentView';

import {
  GqlDocumentsCandidatesForSetQuery,
  GqlFacetSearchResult,
  useDocumentsCandidatesForSetQuery,
} from '#gql';

export interface DocumentSetDocumentCandidatesTableProps {
  documentSetId: string;
  selection: UseTableSelect<string>;
}

export default function DocumentSetDocumentCandidatesTable({
  documentSetId,
  selection,
}: DocumentSetDocumentCandidatesTableProps) {
  const context = useDocumentSetCandidateContext();
  const { sort, pagination, filters } = context;

  const { data, error, previousData } = useDocumentsCandidatesForSetQuery({
    variables: {
      documentSetId,
      filterBy: filters,
      sortBy: sort,
      ...pagination,
    },
  });

  const documentsForSet = (data || previousData)?.documentsCandidatesForSet;

  if (error) return <IndexFilterError error={error} context={context} />;
  if (!documentsForSet) return null;

  return (
    <DocumentSetDocumentCandidatesTableView
      facets={documentsForSet.facets}
      documents={documentsForSet.nodes}
      totalCount={documentsForSet.totalCount}
      selection={selection}
    />
  );
}

export interface DocumentSetDocumentCandidatesTableViewProps {
  facets: GqlFacetSearchResult[];
  documents: GqlDocumentsCandidatesForSetQuery['documentsCandidatesForSet']['nodes'];
  totalCount: number;
  selection: UseTableSelect<string>;
}

export function DocumentSetDocumentCandidatesTableView(
  props: DocumentSetDocumentCandidatesTableViewProps,
) {
  const context = useDocumentSetCandidateContext();
  const { tableSort, tablePagination, store, filters } = context;

  return (
    <TableSearchLayout
      className="mt-2"
      context={context}
      advancedFilter={
        <DocumentSetDocumentsSearchFilter
          facets={store.getCorrectedFacets(props.facets)}
          filters={filters}
          store={store}
        />
      }
    >
      <DocumentSetTableDocumentView
        documents={props.documents}
        totalCount={props.totalCount}
        tableSort={tableSort}
        tablePagination={tablePagination}
        renderTextPagination={(actual) => (
          <FormattedPaginationText
            page={actual}
            total={props.totalCount}
            rowsPerPage={tablePagination.itemsPerPage}
            messageId="doc_set.list.candidates.filter_info"
          />
        )}
        selection={props.selection}
        renderEmpty={renderEmptyState}
      />
    </TableSearchLayout>
  );
}

function renderEmptyState() {
  return <FormattedEmptyState title="doc_set.list.candidates.empty" />;
}
