import { ApolloError } from '@apollo/client';
import { T } from '@tolgee/react';
import { omit } from 'lodash';
import { ReactNode, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useDocumentSetDynamicDocumentContext } from '../../contexts/documentSeDynamicDocument';
import { useIdParam } from '../../hooks/useParams';
import { UseTableSelect } from '../../hooks/useTableSelection';
import { DocumentSetDynamicDocumentTableSearchProvider } from '../../providers/DocumentSetDynamicDocumentTableSearchProvider';
import { IndexFilterError } from '../TableSearch/IndexFilterError';
import TableSearchInput from '../TableSearch/TableSearchInput';
import FormattedButton from '../translation/FormattedButton';
import FormattedEmptyState from '../translation/FormattedEmptyState';
import FormattedErrorAlert from '../translation/FormattedErrorAlert';
import { FormattedPaginationText } from '../translation/FormattedPagination';

import { DocumentSetDocumentsSearchFilter } from './DocumentSetDocumentsSearchFilter';
import { DocumentSetTableDocumentView } from './DocumentSetTableDocumentView';
import DocumentSetTitle from './DocumentSetTitle';
import DocumentSetType from './DocumentSetType';

import {
  GqlDocumentSetWithFiltersQuery,
  GqlFacetSearchResult,
  GqlSimulateDocumentsWithFiltersQuery,
  useDocumentSetWithFiltersQuery,
  useEditDynamicFiltersDocumentSetMutation,
  useSimulateDocumentsWithFiltersQuery,
} from '#gql';
import { H4 } from '#ui/heading';
import { PageLayout, PageLayoutNavigation } from '#ui/page_layout';

interface DocumentSetDynamicFiltersProps {
  documentSet: GqlDocumentSetWithFiltersQuery['documentSet'];
}

export function DocumentSetEditDynamicFiltersPage(
  props: DocumentSetDynamicFiltersProps,
) {
  const { documentSet } = props;
  const context = useDocumentSetDynamicDocumentContext();
  const navigate = useNavigate();

  const [error, setError] = useState<ApolloError | null>(null);

  const [editFilters] = useEditDynamicFiltersDocumentSetMutation({
    variables: {
      input: {
        id: documentSet.id,
        dynamicFilters: context.filters,
      },
    },
  });

  function onSave() {
    editFilters({
      variables: {
        input: {
          id: documentSet.id,
          dynamicFilters: context.filters,
        },
      },
    })
      .then(() => {
        navigate(`/document-sets/${documentSet.id}`);
      })
      .catch((error) => setError(error));
  }

  return (
    <PageLayout
      title={<T keyName="doc_set.manage_dynamic_filters" />}
      navigation={
        <>
          <PageLayoutNavigation to="/document-sets">
            <T keyName="nav.doc_sets" />
          </PageLayoutNavigation>
          <PageLayoutNavigation to={`/document-sets/${documentSet.id}`}>
            <DocumentSetTitle value={documentSet} />
          </PageLayoutNavigation>
        </>
      }
      actions={
        <>
          <FormattedButton
            as={Link}
            to="./.."
            messageId="global.cancel"
            variant="white"
          />
          <FormattedButton messageId="global.save" onClick={onSave} />
        </>
      }
      footer={<FormattedButton messageId="global.save" onClick={onSave} />}
    >
      <p>
        <DocumentSetType value={documentSet} />
      </p>

      <FormattedErrorAlert
        error={error}
        onDismiss={() => setError(null)}
        className="my-2"
      />

      <DocumentSetEditDynamicFiltersData documentSet={documentSet} />
    </PageLayout>
  );
}

function DocumentSetEditDynamicFiltersData(
  props: DocumentSetDynamicFiltersProps,
) {
  const { documentSet } = props;
  const context = useDocumentSetDynamicDocumentContext();

  const result = useSimulateDocumentsWithFiltersQuery({
    variables: {
      ...context.pagination,
      sortBy: context.sort,
      filterBy: {
        ...context.filters,
        docType: [documentSet.docType],
        docCountry: [documentSet.docCountry],
      },
    },
  });

  const error = result.error;
  const data = result.data || result.previousData;

  if (error) return <IndexFilterError error={error} context={context} />;
  if (!data?.documents) return null;

  return (
    <DocumentSetEditDynamicFiltersView
      facets={data.documents.facets}
      documents={data.documents.nodes}
      totalCount={data.documents.totalCount}
    />
  );
}

export interface DocumentSetEditDynamicFiltersViewProps {
  facets: GqlFacetSearchResult[];
  documents: GqlSimulateDocumentsWithFiltersQuery['documents']['nodes'];
  totalCount: number;
  selection?: UseTableSelect<string>;
  footerTable?: ReactNode;
}

export function DocumentSetEditDynamicFiltersView(
  props: DocumentSetEditDynamicFiltersViewProps,
) {
  const { facets, documents, totalCount, selection, footerTable } = props;
  const context = useDocumentSetDynamicDocumentContext();

  return (
    <section className="grid grid-cols-4 gap-2">
      <aside>
        <H4>
          <T keyName="doc_set.filters.title" />
        </H4>

        <TableSearchInput context={context} />
        <DocumentSetDocumentsSearchFilter
          facets={context.store.getCorrectedFacets(facets)}
          store={context.store}
          filters={context.filters}
        />
      </aside>

      <main className="col-span-3">
        <DocumentSetTableDocumentView
          documents={documents}
          totalCount={totalCount}
          tableSort={context.tableSort}
          tablePagination={context.tablePagination}
          renderTextPagination={(actual) => (
            <FormattedPaginationText
              page={actual}
              total={totalCount}
              rowsPerPage={context.tablePagination.itemsPerPage}
              messageId="page.doc.list.filter_info"
            />
          )}
          renderEmpty={renderEmpty}
          selection={selection}
          selectedClassname="bg-danger-300"
        />

        {footerTable}
      </main>
    </section>
  );
}

export default function DocumentSetDynamicFiltersPage() {
  const id = useIdParam();
  const result = useDocumentSetWithFiltersQuery({
    variables: { id },
  });

  const data = result.data ?? result.previousData;
  if (result.error) throw result.error;
  if (!data) return null;

  return (
    <DocumentSetDynamicDocumentTableSearchProvider
      initStore={(store) => {
        const { dynamicFilters } = data.documentSet;
        if (!dynamicFilters) return;

        store.setInitialData(omit(dynamicFilters, '__typename'));
        store.reset();
      }}
    >
      <DocumentSetEditDynamicFiltersPage documentSet={data.documentSet} />
    </DocumentSetDynamicDocumentTableSearchProvider>
  );
}

function renderEmpty() {
  return <FormattedEmptyState title="global.noresults" />;
}
