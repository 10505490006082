import { ReactNode } from 'react';

import { useCheckedLoginState } from '../providers/LoginProvider.context';

import { UserPermission } from '#gql';

interface IfHasAnyPermissionsProps {
  userPermissions: UserPermission[];
  children: ReactNode;
}
export default function IfHasAnyPermissions({
  userPermissions,
  children,
}: IfHasAnyPermissionsProps) {
  const { permissions } = useCheckedLoginState();

  const node = userPermissions.some((permission) =>
    permissions.includes(permission),
  )
    ? children
    : null;
  return <>{node}</>;
}
