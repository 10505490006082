import { T } from '@tolgee/react';
import { Link } from 'react-router-dom';

import FormattedButton from '../../translation/FormattedButton';

import AdminSeizureAuthoritiesTable from './AdminSeizureAuthoritiesTable';

import { FormattedAlert } from '#intl';
import { PageLayout } from '#ui/page_layout';

export default function AdminSeizureAuthoritiesIndexPage() {
  return (
    <PageLayout
      title={<T keyName="admin.seizure_authorities.title" />}
      actions={
        <FormattedButton
          as={Link}
          to="/admin/seizure-authorities/create"
          messageId="admin.create_seizure_authority.title"
        />
      }
    >
      <FormattedAlert
        type="info"
        messageId="admin.seizure_authorities.instructions"
      />

      <AdminSeizureAuthoritiesTable />
    </PageLayout>
  );
}
