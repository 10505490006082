import { T } from '@tolgee/react';

import QRCode from '../QRCode';
import FormattedButton from '../translation/FormattedButton';

import { ScannerData } from './Scanner';
import { getQrDataScanner } from './ScannerQrCode.utils';

export interface ScannerQrCodeProps {
  data: ScannerData;
  noPrint?: boolean;
}

export default function ScannerQrCode({ data, noPrint }: ScannerQrCodeProps) {
  return (
    <div className="print:flex print:flex-col print:items-center">
      <QRCode
        level="Q"
        filename="qrcode_scanner"
        value={getQrDataScanner(data)}
      >
        <h6>
          <T
            keyName="scanner.display.service"
            params={{ service: data.service }}
          />
        </h6>
        <h6>
          <T keyName="scanner.display.brand" params={{ brand: data.brand }} />
        </h6>
        <h6>
          <T keyName="scanner.display.model" params={{ model: data.model }} />
        </h6>
        {data.serial ? (
          <div>
            <T
              keyName="scanner.display.serial_number"
              params={{ serial: data.serial }}
            />
          </div>
        ) : null}
        {data.description ? <div>{data.description}</div> : null}
      </QRCode>
      {!noPrint && (
        <div className="m-3 print:hidden">
          <FormattedButton messageId="global.print" onClick={print} />
        </div>
      )}
    </div>
  );
}

function print() {
  window.print();
}
