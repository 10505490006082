import { useEffect, useMemo } from 'react';

export function useBroadcastChannel(name: string) {
  const channel = useMemo(() => new BroadcastChannel(name), [name]);

  useEffect(() => {
    return () => channel.close();
  }, [channel]);

  return channel;
}
