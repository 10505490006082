import { TableSearchBaseFilters } from '../../components/providers/TableSearchContextProvider';
import { SyncSearchParamsStoreSupportedTypes } from '../SyncSearchParamsStore';
import { SyncSearchParamsWithTermStore } from '../SyncSearchParamsWithTermStore';

import { FraudType, DocumentType } from '#gql';

export interface LabelFilters extends TableSearchBaseFilters {
  docType: DocumentType[];
  docFraudType: FraudType[];
  docCountry: string[];
}

export class LabelFiltersStore extends SyncSearchParamsWithTermStore<LabelFilters> {
  public get shapeDef() {
    return {
      searchTerm: 'string',
      docType: 'string[]',
      docFraudType: 'string[]',
      docCountry: 'string[]',
    } as const satisfies Record<
      keyof LabelFilters,
      SyncSearchParamsStoreSupportedTypes
    >;
  }

  public get initialData(): LabelFilters {
    return {
      searchTerm: null,
      docType: [],
      docFraudType: [],
      docCountry: [],
    };
  }
}
