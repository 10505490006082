import {
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
} from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';
import type { ReactNode } from 'react';

import { Tooltip } from '#tailwind_ui';

interface MinimalButtonBaseProps {
  tooltipId: TranslationKey;
  disabled: boolean;
  onClick: () => void;
}

export function MinimalPreviousButton(props: MinimalButtonBaseProps) {
  return (
    <MinimalButton
      icon={<ArrowLeftCircleIcon className="h-6 w-6" />}
      {...props}
    />
  );
}

export function MinimalNextButton(props: MinimalButtonBaseProps) {
  return (
    <MinimalButton
      icon={<ArrowRightCircleIcon className="h-6 w-6" />}
      {...props}
    />
  );
}

interface MinimalButtonProps extends MinimalButtonBaseProps {
  icon: ReactNode;
}

export function MinimalButton(props: MinimalButtonProps) {
  const { icon, disabled, onClick, tooltipId } = props;

  return (
    <Tooltip content={<T keyName={tooltipId} />}>
      <button
        type="button"
        onClick={onClick}
        disabled={disabled}
        className={
          disabled ? 'text-neutral-500' : 'text-neutral-800 hover:text-black'
        }
      >
        {icon}
      </button>
    </Tooltip>
  );
}
