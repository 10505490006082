import { useTranslate } from '@tolgee/react';
import { useMemo } from 'react';

import FacetFilterSelectList, {
  FacetFilterSelectListProps,
} from '../../TableSearch/FacetFilterSelectList';

export default function IsBitmapCodeFilterSelectList<
  Filter,
  Prop extends keyof Filter,
>(
  props: Omit<
    FacetFilterSelectListProps<Filter, Prop>,
    'optionLookup' | 'displayLookup'
  >,
) {
  const { t } = useTranslate();

  const displayLookup = useMemo(
    () =>
      ({
        true: t('global.present'),
        false: t('global.absent'),
      }) as Record<string, string>,
    [t],
  );

  return (
    <FacetFilterSelectList
      {...props}
      optionLookup={displayLookup}
      displayLookup={displayLookup}
    />
  );
}
