import { useCallback } from 'react';
import { useCommittedRois, useRoiState } from 'react-roi';
import { Link } from 'react-router-dom';

import FormattedButton from '../../../../components/translation/FormattedButton';
import FormattedErrorAlert from '../../../../components/translation/FormattedErrorAlert';

import { PersonalDataReactRoiData } from './utils/personal_data';

import { useSetScanImportDataPersonalDataRegionsMutation } from '#gql';
import { useOnOff } from '#tailwind_ui';
import { assert } from '#utils/assert';

interface TopToolbarProps {
  onDelete: () => void;
  onPreview: () => void;
  onSaveSuccess: () => void;
  scanId: string;
}

export function TopToolbar(props: TopToolbarProps) {
  const { onDelete, scanId, onPreview, onSaveSuccess } = props;

  const rois = useCommittedRois<PersonalDataReactRoiData>();
  const { selectedRoi } = useRoiState();

  const [saveIsDisabled, setSaveIsDisabled] = useOnOff(false);

  const [setScanImportDataPersonalDataRegions, { error }] =
    useSetScanImportDataPersonalDataRegionsMutation();

  const onSave = useCallback(() => {
    setSaveIsDisabled();

    void setScanImportDataPersonalDataRegions({
      variables: {
        rois: rois.map((roi) => {
          assert(roi.data);

          const {
            data: { type },
            id,
            x,
            y,
            height,
            width,
          } = roi;

          return {
            id,
            x,
            y,
            height,
            width,
            type,
          };
        }),
        scanId,
      },
    }).then(onSaveSuccess);
  }, [
    onSaveSuccess,
    rois,
    scanId,
    setSaveIsDisabled,
    setScanImportDataPersonalDataRegions,
  ]);

  return (
    <>
      {/* Calc to place the button on the border of the image (max-w-6xl-(button size + gap between image and right toolbar)) */}
      <div className="flex max-w-[calc(72rem-(54px+1.25rem))] flex-row justify-between">
        <FormattedButton
          messageId="global.delete"
          color="danger"
          disabled={selectedRoi === undefined}
          onClick={onDelete}
        />

        <div className="flex items-center gap-5">
          <FormattedButton
            variant="white"
            messageId="global.preview"
            onClick={onPreview}
          />

          <FormattedButton
            as={Link}
            to="./.."
            color="neutral"
            messageId="global.back"
          />

          <FormattedButton
            disabled={saveIsDisabled}
            messageId="global.save"
            onClick={onSave}
          />
        </div>
      </div>
      <FormattedErrorAlert error={error} />
    </>
  );
}
