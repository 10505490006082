import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import {
  ArchiveBoxIcon,
  ArchiveBoxXMarkIcon,
} from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useHasPermission } from '../../../../hooks/permissions';
import { useTolgeeCacheLoad } from '../../../../hooks/useTolgeeCacheLoad';
import HalfFullCircle from '../../../icons/HalfFullCircleIcon';
import ArchiveAlertModal from '../../modals/ArchiveAlertModal';
import CreateAlertDraftModal from '../../modals/CreateAlertDraftModal';
import UnarchiveAlertModal from '../../modals/UnarchiveAlertModal';
import {
  ALERT_DOWNLOAD_PDF_NOTIFICATION_GROUP,
  getDownloadPdfUrl,
} from '../../utils/alertPdf';
import { getAlertDraftEditUrl } from '../../utils/navigation';

import { GqlAlertPublicationQuery } from '#gql';
import {
  assertUnreachable,
  Button,
  ButtonGroup,
  DropdownActionOption,
  useNotificationCenter,
  useOnOff,
} from '#tailwind_ui';
import { assert } from '#utils/assert';

type AlertActions = 'create-draft' | 'archive-alert' | 'unarchive-alert';

export default function AlertPublicationActions(props: {
  alert: GqlAlertPublicationQuery['alert'];
}) {
  const notification = useNotificationCenter();
  const navigate = useNavigate();
  const { alert } = props;

  const showDownloadNotification = useCallback(() => {
    notification.addToastNotification({
      label: <T keyName="alert.download_pdf.will_start" />,
      group: ALERT_DOWNLOAD_PDF_NOTIFICATION_GROUP,
    });
  }, [notification]);
  const canUpdateAlert = useHasPermission('alert_update');

  const [showArchiveAlertModal, openArchiveAlertModal, closeArchiveAlertModal] =
    useOnOff();

  const [
    showCreateAlertDraftModal,
    openCreateAlertDraftModal,
    closeCreateAlertDraftModal,
  ] = useOnOff();

  const [
    showUnarchiveAlertModal,
    openUnarchiveAlertModal,
    closeUnarchiveAlertModal,
  ] = useOnOff();

  const language = alert.publication.translation.language;
  useTolgeeCacheLoad(language, 'alert');

  const options = useMemo(() => {
    const options: Array<Array<DropdownActionOption<AlertActions>>> = [];
    if (alert.status === 'ARCHIVED' && canUpdateAlert) {
      options.push([
        {
          type: 'action',
          label: <T keyName="series.alert.actions.unarchive_alert" />,
          data: 'unarchive-alert',
          icon: <ArchiveBoxXMarkIcon />,
        },
      ]);
    } else if (canUpdateAlert) {
      options.push([
        {
          type: 'action',
          label: <T keyName="series.alert.actions.archive_alert" />,
          data: 'archive-alert',
          icon: <ArchiveBoxIcon />,
        },
      ]);
      if (!alert.draft && alert.publication.isCurrent) {
        options.push([
          {
            type: 'action',
            label: <T keyName="series.alert.actions.create_draft" />,
            data: 'create-draft',
            icon: <HalfFullCircle />,
          },
        ]);
      }
    }

    return options;
  }, [alert.status, alert.publication.isCurrent, alert.draft, canUpdateAlert]);

  return (
    <>
      {options.length > 0 ? (
        <ButtonGroup>
          <ButtonGroup.Button
            as="a"
            href={getDownloadPdfUrl({
              alertId: alert.id,
              draftOrPublicationId: alert.publication.id,
              language,
            })}
            onClick={showDownloadNotification}
          >
            <DownloadButtonContent />
          </ButtonGroup.Button>
          <ButtonGroup.Dropdown
            options={options}
            onSelect={(action) => {
              assert(action.data);
              switch (action.data) {
                case 'archive-alert':
                  openArchiveAlertModal();
                  break;
                case 'unarchive-alert':
                  openUnarchiveAlertModal();
                  break;
                case 'create-draft':
                  openCreateAlertDraftModal();
                  break;
                default:
                  assertUnreachable(action.data);
              }
            }}
          />
        </ButtonGroup>
      ) : (
        <Button
          variant="white"
          as="a"
          href={getDownloadPdfUrl({
            alertId: alert.id,
            draftOrPublicationId: alert.publication.id,
            language,
          })}
          onClick={showDownloadNotification}
        >
          <DownloadButtonContent />
        </Button>
      )}

      <ArchiveAlertModal
        isOpen={showArchiveAlertModal}
        id={alert.id}
        onClose={closeArchiveAlertModal}
      />
      <UnarchiveAlertModal
        isOpen={showUnarchiveAlertModal}
        id={alert.id}
        onClose={() => {
          closeUnarchiveAlertModal();
        }}
      />
      <CreateAlertDraftModal
        id={alert.id}
        isOpen={showCreateAlertDraftModal}
        onClose={(draftId) => {
          closeCreateAlertDraftModal();
          if (draftId) {
            navigate(
              getAlertDraftEditUrl(
                alert.id,
                alert.publication.translation.language,
              ),
            );
          }
        }}
      />
    </>
  );
}

function DownloadButtonContent() {
  return (
    <div className="flex items-center gap-2">
      <ArrowDownTrayIcon className="h-5 w-5" />
      <T keyName="series.alert.download_pdf" />
    </div>
  );
}
