import { T } from '@tolgee/react';
import { useLocation } from 'react-router-dom';

import FilesUpload from '../FilesUpload';

import { useUploadScansMutation } from '#gql';
import { useNotificationCenter } from '#tailwind_ui';
import { PageLayout, PageLayoutNavigation } from '#ui/page_layout';

/**
 * This should be restricted to image formats supported by ImageJS.
 * Keep in sync with backend.
 */
const mimetypeAllowList = {
  'image/png': ['.png'],
  'image/tiff': ['.tiff', '.tif'],
  'image/jpeg': ['.jpeg', '.jpg'],
};

export default function UploadScans() {
  const location = useLocation();
  const [uploadScans] = useUploadScansMutation();
  const { addNotification } = useNotificationCenter();

  const handleSend = async (files: File[]) => {
    addNotification(
      {
        type: 'primary',
        title: <T keyName="upload.series.alert.started" />,
      },
      2000,
    );

    await uploadScans({
      variables: { scans: files },
    });

    addNotification(
      {
        type: 'success',
        title: <T keyName="upload.series.alert.success" />,
      },
      5000,
    );
  };

  return (
    <PageLayout
      title={<T keyName="upload.title" />}
      navigation={
        <PageLayoutNavigation to="/scans">
          <T keyName="nav.scans.list" />
        </PageLayoutNavigation>
      }
      bodyClassName="max-w-xl"
    >
      <FilesUpload
        accept={mimetypeAllowList}
        onSend={handleSend}
        currentUrl={location.pathname}
        successRedirectUrl="/scans"
        displayAlertOnJpeg
      />
    </PageLayout>
  );
}
