import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import html2canvas from 'html2canvas';
import BaseQRCode, { QRCodeSVG } from 'qrcode.react';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { IconButton, PropsOf } from '#tailwind_ui';

type BaseQRCodeProps = PropsOf<typeof QRCodeSVG>;

interface QRCodeProps {
  level?: BaseQRCodeProps['level'];
  value: string;
  children: ReactNode;
  filename: string;
}

export default function QRCode(props: QRCodeProps) {
  const { children, filename, level, value } = props;
  const [showBig, setShowBig] = useState(false);

  const portal = showBig
    ? createPortal(
        <SaveQrCode
          level={level}
          value={value}
          filename={filename}
          close={() => setShowBig(false)}
        >
          {children}
        </SaveQrCode>,
        document.querySelector('#qrcode') as HTMLDivElement,
      )
    : null;

  return (
    <>
      <div className="relative max-w-lg grow truncate border-8 border-solid border-white p-2 outline-dashed outline-2 outline-black print:m-0 print:w-[8cm]">
        <table className="text-sm">
          <tbody>
            <tr>
              <td className="p-2">
                <BaseQRCode
                  renderAs="svg"
                  className="h-[4cm] w-[4cm]"
                  level={level}
                  value={value}
                />
              </td>
              <td>&nbsp;</td>
              <td>{children}</td>
            </tr>
          </tbody>
        </table>
        <div className="absolute right-3 top-2 print:hidden">
          <IconButton
            size="5"
            icon={<ArrowDownTrayIcon />}
            onClick={() => setShowBig(true)}
          />
        </div>
      </div>
      {portal}
    </>
  );
}

interface SaveQrCodeProps {
  level?: BaseQRCodeProps['level'];
  value: string;
  children: ReactNode;
  filename: string;
  close: () => void;
}

function SaveQrCode(props: SaveQrCodeProps) {
  const { children, filename, close, ...otherProps } = props;
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    saveDomElement(ref.current, filename, close);
  }, [close, filename]);

  return (
    <div className="absolute left-[calc(100px_+_100vw)] top-0 z-50 bg-white p-12">
      <div
        ref={ref}
        className="box-content w-[2200px] overflow-visible truncate border-8 border-solid border-white p-5"
      >
        <table className="w-full border-8 border-dashed border-black p-8">
          <tbody>
            <tr>
              <td>
                <div className="w-fit border-8 border-solid border-white p-12">
                  <BaseQRCode size={900} renderAs="canvas" {...otherProps} />
                </div>
              </td>
              <td className="text-[90px]">{children}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

function saveDomElement(
  element: HTMLElement | null,
  filename: string,
  close: () => void,
) {
  if (!element) return;

  html2canvas(element, {
    scrollX: -window.scrollX,
    scrollY: -window.scrollY,
  })
    .then((canvas) => {
      const png = canvas.toDataURL('image/png');
      const element = document.createElement('a');
      element.setAttribute('href', png);
      element.setAttribute('download', `${filename}.png`);
      element.style.display = 'none';
      document.body.append(element);
      element.click();
      element.remove();
      close();
    })
    .catch(reportError);
}
