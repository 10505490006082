import { Reducer, useEffect, useReducer } from 'react';
import { match } from 'ts-pattern';

interface UsePromiseState<R, E = Error> {
  data?: R;
  error?: E;
}

const INIT_STATE = { data: undefined, error: undefined };

export function usePromise<Return, E = Error>(
  promiseFactory: () => Promise<Return>,
): UsePromiseState<Return, E> {
  const [state, dispatch] = useReducer<
    Reducer<UsePromiseState<Return, E>, Actions<Return, E>>
  >(promiseReducer, INIT_STATE);

  useEffect(() => {
    let allowDispatch = true;

    promiseFactory()
      .then((data) => allowDispatch && dispatch({ type: 'resolve', data }))
      .catch((error) => allowDispatch && dispatch({ type: 'reject', error }));

    return () => {
      allowDispatch = false;
    };
  }, [promiseFactory]);

  return state;
}

interface ResolveAction<R> {
  type: 'resolve';
  data: R;
}

interface RejectAction<E> {
  type: 'reject';
  error: E;
}

type Actions<R, E> = ResolveAction<R> | RejectAction<E>;

function promiseReducer<Return, Error>(
  state: UsePromiseState<Return, Error>,
  action: Actions<Return, Error>,
): UsePromiseState<Return, Error> {
  return match(action)
    .with({ type: 'resolve' }, ({ data }) => ({ data, error: undefined }))
    .with({ type: 'reject' }, ({ error }) => ({ data: undefined, error }))
    .exhaustive();
}
