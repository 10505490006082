import { ReactNode, useMemo } from 'react';

import { usePreventUnload } from '../../hooks/usePreventUnload';

import {
  FilesUploadingContext,
  filesUploadingContext,
  useFilesUploadingReducer,
} from './FilesUploadingProvider.context';

export function FilesUploadingProvider(props: { children: ReactNode }) {
  const [uploading, dispatch] = useFilesUploadingReducer();
  const contextValue = useMemo<FilesUploadingContext>(
    () => [uploading, dispatch],
    [uploading, dispatch],
  );

  usePreventUnload(uploading.size > 0);

  return (
    <filesUploadingContext.Provider value={contextValue}>
      {props.children}
    </filesUploadingContext.Provider>
  );
}
