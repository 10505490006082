import { Language } from '#gql';
import { assertUnreachable } from '#tailwind_ui';

export const languages: Record<Language, string> = {
  en: 'English',
  fr: 'Français',
  de: 'Deutsch',
  nl: 'Nederlands',
  it: 'Italiano',
};

export const languageKeys = Object.keys(languages) as Language[];

export function getLanguageKey(lang: Language): TranslationKey {
  switch (lang) {
    case 'de':
      return 'global.language.de';
    case 'fr':
      return 'global.language.fr';
    case 'en':
      return 'global.language.en';
    case 'nl':
      return 'global.language.nl';
    case 'it':
      return 'global.language.it';
    default:
      assertUnreachable(lang);
  }
}

export function getLanguageInSentenceKey(lang: Language): TranslationKey {
  switch (lang) {
    case 'de':
      return 'global.language.in_sentence.de';
    case 'fr':
      return 'global.language.in_sentence.fr';
    case 'en':
      return 'global.language.in_sentence.en';
    case 'nl':
      return 'global.language.in_sentence.nl';
    case 'it':
      return 'global.language.in_sentence.it';
    default:
      assertUnreachable(lang);
  }
}

export function getLanguageAdjectiveKey(lang: Language): TranslationKey {
  switch (lang) {
    case 'de':
      return 'global.language.translation.adjective.de';
    case 'fr':
      return 'global.language.translation.adjective.fr';
    case 'en':
      return 'global.language.translation.adjective.en';
    case 'nl':
      return 'global.language.translation.adjective.nl';
    case 'it':
      return 'global.language.translation.adjective.it';
    default:
      assertUnreachable(lang);
  }
}
