import { Placement } from '@popperjs/core';
import clsx from 'clsx';
import { cloneElement, useRef, ReactNode, Ref, ReactElement } from 'react';
import { Popper as ReactPopper, Manager, Reference } from 'react-popper';

import { useOnClickOutside } from '../../hooks/useOnClickOutside';

interface PopperRenderCallbackArgs {
  scheduleUpdate: () => Promise<unknown>;
  placement: Placement;
}

type TypeReference =
  | ReactElement
  | ((props: { ref: Ref<unknown> }) => ReactElement);

export interface BubbleProps {
  visible: boolean;
  placement?: Placement;
  reference: TypeReference;
  children: ReactNode | ((args: PopperRenderCallbackArgs) => ReactNode);
  popperClassName?: string;
  onClickOutside: (event: Event) => void;
}

export function Bubble(props: BubbleProps) {
  const {
    reference,
    children,
    visible,
    placement,
    onClickOutside,
    popperClassName,
  } = props;

  const popperRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(popperRef, onClickOutside);
  return (
    <Manager>
      <Reference>
        {({ ref }) => {
          let toRender;
          if (typeof reference === 'function') {
            toRender = reference({ ref });
          } else {
            toRender = cloneElement(reference, { ref });
          }
          return (
            <>
              {visible && (
                <div
                  data-testid="bubble-background"
                  className="fixed inset-0 z-30 block bg-transparent"
                />
              )}
              {toRender}
            </>
          );
        }}
      </Reference>
      <ReactPopper placement={placement}>
        {({ ref, style, placement: innerPlacement, arrowProps, update }) => {
          if (!visible) return null;
          let popperElement: ReactNode;
          if (typeof children === 'function') {
            popperElement = children({
              scheduleUpdate: update,
              placement: innerPlacement,
            });
          } else {
            popperElement = children;
          }

          if (popperElement === null) return null;
          return (
            <div
              ref={ref}
              className={clsx(popperClassName, 'z-50')}
              style={style}
            >
              <div ref={popperRef}>
                <div
                  className="rounded-lg border-2 border-neutral-200 bg-white"
                  style={{
                    margin: '0.9em',
                  }}
                >
                  {popperElement}
                </div>
                <div
                  className="bubble-arrow"
                  ref={arrowProps.ref}
                  style={arrowProps.style}
                  data-placement={innerPlacement}
                />
              </div>
            </div>
          );
        }}
      </ReactPopper>
    </Manager>
  );
}
