import { isApolloError } from '@apollo/client';

import { isApiError, isJSError } from '#utils/error/api';

export function getFormattedError(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any,
): {
  title: string | undefined;
  titleId: TranslationKey | undefined;
  details: string | undefined;
  detailsId?: TranslationKey | undefined;
  detailsValues?: Record<string, string>;
} {
  let titleId: TranslationKey | undefined;
  let detailsId: TranslationKey | undefined;
  let details;
  let title;
  let detailsValues: Record<string, string> | undefined;
  if (!titleId) {
    // ======= Handle local errors ========
    if (isJSError(error)) {
      titleId = error.messageId;
    } else if (error.networkError) {
      titleId = 'global.error.network';
      // ======= Handle Apollo errors ========
    } else if (isApolloError(error)) {
      const firstError = error.graphQLErrors[0];
      const firstErrorExtensions = firstError.extensions.profid;

      // Get the message title
      if (firstErrorExtensions.translationKey) {
        titleId = firstErrorExtensions.translationKey;
      } else {
        title = firstError.message;
      }
      const firstMessageError = firstErrorExtensions.messages?.errors?.[0];
      if (firstMessageError) {
        if (firstMessageError.translationKey) {
          detailsId = firstMessageError.translationKey;
          detailsValues = firstMessageError.translationValues;
        } else {
          details = firstMessageError.message;
        }
      }
      // ======= Handle Adonis errors ========
    } else if (isApiError(error)) {
      titleId = error.translationKey;
    } else if (error.message) {
      title = error.message;
    } else {
      titleId = 'global.error.unknown';
    }
  }
  return {
    title,
    titleId,
    details,
    detailsId,
    detailsValues,
  };
}
