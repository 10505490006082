import { T } from '@tolgee/react';

// eslint-disable-next-line no-restricted-imports
import { SubmitButtonRHF, SubmitProps } from '#tailwind_ui';

type FormattedSubmitButtonProps = Omit<SubmitProps, 'children'> & {
  messageId: TranslationKey;
};

export default function FormattedSubmitButton(
  props: FormattedSubmitButtonProps,
) {
  const { messageId, ...otherProps } = props;
  return (
    <SubmitButtonRHF {...otherProps}>
      <T keyName={messageId} />
    </SubmitButtonRHF>
  );
}
