import { DocumentSetType, DocumentType } from '#gql';

export interface FirstStepFormValues {
  docCountry: string | null;
  docType: DocumentType | null;
  name: string;
  type: DocumentSetType;
}

export const firstStepDefaultValues: FirstStepFormValues = {
  docCountry: null,
  docType: null,
  name: '',
  type: 'manual',
};
