import { TrashIcon } from '@heroicons/react/24/outline';

import { AsyncFormattedConfirmModal } from '../../AsyncFormattedConfirmModal';

import { useUpdateAlertRapidDetectionImageMutation } from '#gql';

interface DeleteRapidDetectionImageModalProps {
  isOpen: boolean;
  id: string;
  draftId: string;
  onClose: (success?: boolean) => void;
}

export default function DeleteRapidDetectionImageModal(
  props: DeleteRapidDetectionImageModalProps,
) {
  const { isOpen, id, draftId, onClose } = props;
  const [updateDetectionImage] = useUpdateAlertRapidDetectionImageMutation();

  return (
    <AsyncFormattedConfirmModal
      open={isOpen}
      icon={<TrashIcon />}
      color="danger"
      confirmText="global.discard"
      cancelText="global.cancel"
      title="series.alert.discard_rapid_identification_image.confirm.title"
      body="series.alert.discard_rapid_identification_image.confirm.body"
      onClose={onClose}
      onConfirm={() =>
        updateDetectionImage({
          variables: {
            input: {
              id,
              draftId,
              rapidIdentificationImage: null,
            },
          },
        })
      }
    />
  );
}
