import { useTranslate } from '@tolgee/react';
import { memo } from 'react';

import { safeTranslationKeyScanStatus } from '../../translationsMapping/scans';

import { getScanStatusBadgeColor } from './ScanStatusBadge.utils';

import { ScanProcessStatus } from '#gql';
import { Badge, BadgeVariant } from '#tailwind_ui';

export interface ScanStatusBadgeProps {
  status: ScanProcessStatus;
}

export default memo(function ScanStatusBadge(props: ScanStatusBadgeProps) {
  const { status } = props;
  const { t } = useTranslate();

  return (
    <Badge
      label={t(safeTranslationKeyScanStatus(status))}
      variant={BadgeVariant.COLORED_BACKGROUND}
      color={getScanStatusBadgeColor(status)}
    />
  );
});
