import { TranslateParams, TranslationKey, useTranslate } from '@tolgee/react';

import { TextArea, TextAreaProps } from '#tailwind_ui';

export type FormattedTextAreaProps = Omit<TextAreaProps, 'help'> & {
  help?: TranslationKey;
  helpParams?: TranslateParams;
  label: TranslationKey;
  labelParams?: TranslateParams;
  placeholder?: TranslationKey;
  placeholderParams?: TranslateParams;
};

export default function FormattedTextArea(props: FormattedTextAreaProps) {
  const {
    help,
    helpParams,
    label,
    labelParams,
    placeholder,
    placeholderParams,
    ...otherProps
  } = props;

  const { t } = useTranslate();
  return (
    <TextArea
      help={help && t(help, helpParams)}
      label={label && t(label, labelParams)}
      placeholder={placeholder && t(placeholder, placeholderParams)}
      {...otherProps}
    />
  );
}
