import { TrashIcon } from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';
import { useWatch } from 'react-hook-form';

import { requiredObject, requiredString } from '../../forms/validation';
import FormattedButton from '../translation/FormattedButton';
import FormattedFormError from '../translation/FormattedFormError';
import FormattedInputField from '../translation/FormattedInputField';
import FormattedSubmitButton from '../translation/FormattedSubmitButton';

import { GqlSerieQuery, useDeleteSerieMutation } from '#gql';
import { FormattedAlert } from '#intl';
import { Color, FormRHFModal, useNotificationCenter } from '#tailwind_ui';

const validationSchema = requiredObject({
  name: requiredString,
  reason: requiredString,
});

const defaultValues = { name: '', reason: '' };

export default function DeleteSerieModal(props: {
  serie: GqlSerieQuery['serie'];
  onSuccess: () => void;
  onRequestClose: () => void;
}) {
  const { serie, onSuccess, onRequestClose } = props;
  const [deleteSerie] = useDeleteSerieMutation();
  const { addNotification } = useNotificationCenter();

  async function handleSubmit(values: typeof defaultValues) {
    await deleteSerie({
      variables: { input: { id: serie.id, reason: values.reason } },
    });

    addNotification({
      type: Color.success,
      title: <T keyName="series.delete.success" />,
    });

    onSuccess();
  }

  return (
    <FormRHFModal
      isOpen
      onRequestClose={onRequestClose}
      defaultValues={defaultValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      icon={<TrashIcon />}
      iconColor={Color.danger}
    >
      <FormRHFModal.Header>
        <T keyName="series.delete.title" params={{ name: serie.name }} />
      </FormRHFModal.Header>
      <FormRHFModal.Body>
        <FormattedAlert type="warning" messageId="series.delete.alert" />

        <FormattedInputField
          name="name"
          label="series.delete.field.name.label"
          labelValues={{ name: serie.name }}
        />

        <FormattedInputField
          name="reason"
          label="series.delete.field.reason.label"
        />

        <FormattedFormError />
      </FormRHFModal.Body>
      <FormRHFModal.Footer>
        <DeleteSerieFooter closeModal={onRequestClose} serieName={serie.name} />
      </FormRHFModal.Footer>
    </FormRHFModal>
  );
}

interface DeleteSerieFooterProps {
  closeModal: () => void;
  serieName: string;
}

function DeleteSerieFooter(props: DeleteSerieFooterProps) {
  const { closeModal, serieName } = props;
  const name: string = useWatch({ name: 'name' });
  return (
    <>
      <FormattedButton
        messageId="global.cancel"
        variant="white"
        onClick={closeModal}
      />
      <FormattedSubmitButton
        disabled={name !== serieName}
        messageId="global.delete"
        color={Color.danger}
      />
    </>
  );
}
