import { ChangeEvent, useCallback } from 'react';

import { useScanContext } from '../../contexts/scan';
import { ScanFiltersOwnerShip } from '../../stores/searches/ScanFiltersStore';
import FormattedRadio from '../translation/FormattedRadio';

export default function ScanUserSelector() {
  const { filters, store } = useScanContext();

  const onChangeCallback = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const ownership = event.target.value as ScanFiltersOwnerShip;

      store.setOwnership(ownership);
    },
    [store],
  );

  return (
    <div className="flex gap-5">
      <FormattedRadio
        name="ScanUserSelector"
        value="mine"
        label="scans.filter.show_mine"
        onChange={onChangeCallback}
        checked={filters.ownership === null || filters.ownership === 'mine'}
      />
      <FormattedRadio
        name="ScanUserSelector"
        value="all"
        label="scans.filter.show_all"
        onChange={onChangeCallback}
        checked={filters.ownership === 'all'}
      />
    </div>
  );
}
