import { T } from '@tolgee/react';
import { ReactElement } from 'react';

import { useSeqIdParam } from '../../hooks/useParams';
import FormattedErrorAlert from '../translation/FormattedErrorAlert';

import { GqlSerieEditableQuery, useSerieEditableQuery } from '#gql';

export default function EditableSerieContainer(props: {
  children: (serie: GqlSerieEditableQuery['serie']) => ReactElement;
}) {
  const seqId = useSeqIdParam();
  const { loading, error, data } = useSerieEditableQuery({
    variables: { seqId },
  });

  if (error) {
    return <FormattedErrorAlert error={error} />;
  } else if (loading && !data) {
    return <T keyName="global.loading" />;
  }

  if (!data) {
    return null;
  }

  return props.children(data.serie);
}
