import { ReactNode } from 'react';

import { ExperimentalFeatureId } from './experimentalFeatures';
import { useExperimentalFeatures } from './useExperimentalFeatures';

export default function IfHasExperimentalFeature({
  feature,
  children,
}: {
  feature: ExperimentalFeatureId;
  children: ReactNode;
}) {
  const { featureSet } = useExperimentalFeatures();
  return featureSet.has(feature) ? <>{children}</> : null;
}
