import { ArchiveBoxIcon } from '@heroicons/react/24/outline';

import { AsyncFormattedConfirmModal } from '../../AsyncFormattedConfirmModal';

import { useArchiveAlertMutation } from '#gql';

interface ArchiveAlertModalProps {
  isOpen: boolean;
  id: string;
  onClose: (success?: boolean) => void;
}

export default function ArchiveAlertModal(props: ArchiveAlertModalProps) {
  const { isOpen, id, onClose } = props;
  const [archiveAlert] = useArchiveAlertMutation();

  return (
    <AsyncFormattedConfirmModal
      open={isOpen}
      icon={<ArchiveBoxIcon />}
      confirmText="series.alert.archive.confirm.button"
      cancelText="global.cancel"
      title="series.alert.archive.confirm.title"
      body="series.alert.archive.confirm.body"
      color="primary"
      onClose={onClose}
      onConfirm={() => archiveAlert({ variables: { id } })}
    />
  );
}
