import { TableSearchContextProvider } from '../components/providers/TableSearchContextProvider';
import { documentSetContext } from '../contexts/documentSet';
import { MandatoryChildren } from '../global';
import { UsePaginationTableInitial } from '../hooks/usePaginationTable';
import { SortTable } from '../hooks/useSortTable';
import {
  DocumentSetFilters,
  DocumentSetFiltersStore,
} from '../stores/searches/DocumentSetFiltersStore';

import { DocumentSetsSortFields } from '#gql';

const initialSort = {
  field: 'createdAt',
  direction: 'DESC',
} as const satisfies SortTable<DocumentSetsSortFields>;
const initialPagination = {
  label: 'doc_set.list.document_sets.filter_info',
} as const satisfies Partial<UsePaginationTableInitial>;

export function DocumentSetTableSearchProvider({
  children,
}: MandatoryChildren) {
  return (
    <TableSearchContextProvider<
      DocumentSetsSortFields,
      DocumentSetFilters,
      typeof DocumentSetFiltersStore,
      DocumentSetFiltersStore
    >
      context={documentSetContext}
      Store={DocumentSetFiltersStore}
      initialSort={initialSort}
      initialPagination={initialPagination}
    >
      {children}
    </TableSearchContextProvider>
  );
}
