import { RefObject, useEffect } from 'react';

import { useAppMeta } from '../components/providers/AppMetaProvider.context';

export function useSyncDocumentTitle(ref: RefObject<Element | null>) {
  const meta = useAppMeta();

  useEffect(() => {
    const node = ref.current;
    if (!node) return;

    function setTitle() {
      const pageTitle = node?.textContent ?? '';
      document.title = `${pageTitle} | ${meta.title}`;
    }

    setTitle();

    const observer = new MutationObserver(setTitle);
    observer.observe(node, {
      childList: true,
      characterData: true,
      subtree: true,
    });

    return () => observer.disconnect();
  }, [meta.title, ref]);
}
