import { useTranslate } from '@tolgee/react';
import { FieldValues, useFormState } from 'react-hook-form';

import { PreventNavigation } from '../../contexts/PreventNavigation/PreventNavigation';

import { FormRHF, FormRHFProps } from '#tailwind_ui';

export interface IFormPreventNavigationProps<TValues extends FieldValues>
  extends FormRHFProps<TValues> {
  /**
   * Used with intl.formatMessage
   */
  preventNavigationPrompt?: TranslationKey;
}

/**
 * add <PreventNavigation /> node to FormRHF (top of children) relying on formState.isDirty && !formState.isSubmitSuccessful
 * If your onSubmit method make you navigate, wait for shouldBlock false
 *
 * ```
 * const [saved, setSaved] = useOnOff();
 * const shouldBlock = usePreventNavigationShouldBlockContext(); // wait form is fully submitted
 *
 * useEffect(() => {
 *   if (saved && !shouldBlock) {
 *     navigate(`/series/${seqId}`);
 *   }
 * }, [saved, navigate, seqId, shouldBlock]);
 *
 * async function onSubmit(values) {
 *   await updateQuery(values);
 *
 *   setSaved();
 * }
 * ```
 *
 * @param props
 */
export function FormPreventNavigation<TValues extends FieldValues>(
  props: IFormPreventNavigationProps<TValues>,
) {
  const { preventNavigationPrompt, ...formRHFProps } = props;
  return (
    <FormRHF<TValues> {...formRHFProps}>
      <InnerFormPreventDefault prompt={preventNavigationPrompt} />
      {props.children}
    </FormRHF>
  );
}

function InnerFormPreventDefault({ prompt }: { prompt?: TranslationKey }) {
  const formState = useFormState();
  const { t } = useTranslate();

  return (
    <PreventNavigation
      when={formState.isDirty && !formState.isSubmitSuccessful}
      prompt={prompt && t(prompt)}
    />
  );
}
