import { CSSProperties } from 'react';

import { FormattedBadge } from './translation/FormattedBadge';

interface ImgLinkProps {
  src: string;
  isExtra?: boolean;
  thumbSrc?: string;
  imgClassName?: string;
  imgStyle?: CSSProperties;
  alt?: string;
}

export default function ImgLink(props: ImgLinkProps) {
  const { alt, imgClassName, imgStyle, src, thumbSrc, isExtra } = props;

  return (
    <div className="relative">
      {isExtra && (
        <div
          style={imgStyle}
          className="pointer-events-none absolute flex h-full w-full items-center justify-around"
        >
          <FormattedBadge
            className="opacity-70"
            variant="COLORED_BACKGROUND"
            color="neutral"
            label="doc.extra_image"
            size="small"
          />
        </div>
      )}

      <a href={src} target="_blank" rel="noopener noreferrer">
        <img
          style={imgStyle}
          className={imgClassName}
          src={thumbSrc || src}
          alt={alt || 'thumbnail'}
        />
      </a>
    </div>
  );
}
