import * as d3Color from 'd3-color';

import { chooseBetterContrast, hslToHex } from '#utils/color';

export function shuffleLabelColors() {
  const hue = Math.random();
  const lightness = Math.random() > 0.5 ? 0.2 : 0.9;
  const backgroundColor = hslToHex(hue, 0.97, lightness);

  const [black, white] = [
    d3Color.rgb(0, 0, 0).formatHex(),
    d3Color.rgb(255, 255, 255).formatHex(),
  ];

  const textColor = chooseBetterContrast(backgroundColor, [black, white]);

  return {
    background: backgroundColor,
    text: textColor ?? (lightness === 0.2 ? white : black),
  };
}
