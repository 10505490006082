import { createContext, useContext } from 'react';

import { DocumentView, GqlPadifAnalysisQuery } from '#gql';

export interface ImageSize {
  width: number;
  height: number;
}

export interface NewRoiContext {
  analysis: GqlPadifAnalysisQuery['padifAnalysis'];
  imageSize: ImageSize | null;
  padifId: string;
  view: DocumentView;
}

export interface NewRoiActions {
  setView: (view: DocumentView) => void;
  setImageSize: (size: ImageSize) => void;
}

export const PADIF_COLORS = {
  readonly: 'rgb(107, 114, 128)',
  editable: 'rgb(59, 130, 246)',
};

export const newRoiContext = createContext<
  [NewRoiContext, NewRoiActions] | null
>(null);

export function useNewRoiContext() {
  const ctx = useContext(newRoiContext);
  if (!ctx) throw new Error('context not found');
  return ctx;
}
