import { TrashIcon } from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';

import { AsyncFormattedConfirmModal } from '../AsyncFormattedConfirmModal';

import { useDeleteDocumentImageMutation } from '#gql';
import { FormattedAlert } from '#intl';
import { Button, useOnOff } from '#tailwind_ui';

interface ImageDeleteProps {
  docId: string;
  imageId: string;
}

export default function ImageDelete(props: ImageDeleteProps) {
  const { docId, imageId } = props;

  const [isDeleting, openModal, closeModal] = useOnOff();
  const [deleteDocumentImage] = useDeleteDocumentImageMutation();

  return (
    <>
      <Button
        color="danger"
        onClick={openModal}
        className="pointer-events-auto"
      >
        <div className="flex items-center gap-2">
          <TrashIcon className="h-5 w-5" />
          <T keyName="global.delete" />
        </div>
      </Button>

      <AsyncFormattedConfirmModal
        icon={<TrashIcon />}
        onConfirm={() =>
          deleteDocumentImage({
            variables: { input: { id: docId, imageId } },
          })
        }
        onClose={closeModal}
        title="doc.image.delete.title"
        confirmText="global.yes"
        cancelText="global.no"
        open={isDeleting}
        body={
          <FormattedAlert type="warning" messageId="doc.image.delete.alert" />
        }
      />
    </>
  );
}
