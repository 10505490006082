import { TrashIcon as TrashIconSolid } from '@heroicons/react/20/solid';
import {
  PhotoIcon,
  TrashIcon as TrashIconOutline,
} from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { useCallback, useState } from 'react';

import ImgLink from '../ImgLink';
import IfHasPermission from '../permissions/IfHasPermission';
import FormattedEmptyState from '../translation/FormattedEmptyState';

import DeleteSerieImage from './DeleteSerieImage';

import { GqlFile } from '#gql';
import { Button, Color, DialogRoot, Size } from '#tailwind_ui';
import { CompleteOverlay, PartialOverlay } from '#ui/overlay';

type ImageFile = Pick<GqlFile, 'id' | 'filename' | 'url'>;

interface SerieImagesProps {
  images: ImageFile[];
  serieId: string;
  readonly?: boolean;
  className?: string;
}
export default function SerieImages(props: SerieImagesProps) {
  const { images, serieId, readonly, className } = props;

  const [deleteImage, setDeleteImage] = useState<ImageFile | null>(null);

  const closeModal = useCallback((open?: boolean) => {
    if (open) return;
    setDeleteImage(null);
  }, []);

  if (images.length === 0) {
    return (
      <FormattedEmptyState title="global.no_images" icon={<PhotoIcon />} />
    );
  }

  return (
    <>
      <div className={clsx('columns-1 sm:columns-2 lg:columns-3', className)}>
        {images.map((image) => (
          <SerieImage
            key={image.id}
            image={image}
            onDeleteImage={setDeleteImage}
            readonly={readonly}
          />
        ))}
      </div>

      {!readonly && deleteImage && (
        <DialogRoot
          iconColor="danger"
          icon={<TrashIconOutline />}
          open
          onOpenChange={closeModal}
        >
          <DeleteSerieImage
            serieId={serieId}
            image={deleteImage}
            onCancel={closeModal}
          />
        </DialogRoot>
      )}
    </>
  );
}

interface SerieImageProps {
  image: ImageFile;
  onDeleteImage: (image: ImageFile) => void;
  readonly?: boolean;
}
function SerieImage(props: SerieImageProps) {
  const { image, readonly, onDeleteImage } = props;

  const Overlay = readonly ? PartialOverlay : CompleteOverlay;

  return (
    <div className="group relative min-h-[200px] break-inside-avoid">
      <ImgLink src={image.url} imgClassName="p-2" />

      <Overlay>
        <p>{image.filename}</p>

        {!readonly && (
          <IfHasPermission userPermission="serie_update">
            <Button
              size={Size.xSmall}
              color={Color.danger}
              onClick={() => onDeleteImage(image)}
              roundness="circular"
              className="pointer-events-auto absolute bottom-0 right-0 m-2"
            >
              <TrashIconSolid className="h-5 w-5" />
            </Button>
          </IfHasPermission>
        )}
      </Overlay>
    </div>
  );
}
