import FormattedButton from '../../../../components/translation/FormattedButton';

import { useSwitchTabContext } from './contexts/switch_tab_context';

import { Color } from '#tailwind_ui';

export function MrzReviewButton() {
  const switchTab = useSwitchTabContext();

  return (
    <FormattedButton
      messageId="scan.import.mrz.confirm"
      onClick={() => switchTab.setTab('mrz')}
      color={Color.warning}
    />
  );
}
