import { useCallback, useEffect, useState } from 'react';

import { apiClient } from '../../api';
import { useLocale } from '../providers/LocaleProvider.context';

import { Locale } from '#gql';

interface AnnouncementResponse {
  hasAnnouncement: boolean;
  announcements?: Announcement[];
}

export interface Announcement {
  id: number;
  message: string;
  link: string;
  linkText: string;
}

export function useAnnouncement() {
  const [locale] = useLocale();

  const [announcements, setAnnouncements] = useState<Announcement[]>([]);
  useEffect(() => {
    getAnnouncement(locale).then(setAnnouncements).catch(reportError);
  }, [locale]);

  const dismiss = useCallback(
    (id: number) => {
      apiClient
        .delete(`announcement/${id}`)
        .json()
        .then(() => getAnnouncement(locale))
        .then(setAnnouncements)
        .catch(reportError);
    },
    [locale],
  );

  return [announcements, dismiss] as const;
}

async function getAnnouncement(locale: Locale) {
  const response = await apiClient
    .get('announcements', { headers: { 'x-locale': locale } })
    .json<AnnouncementResponse>();
  if (response.hasAnnouncement) {
    return response.announcements ?? [];
  } else {
    return [];
  }
}
