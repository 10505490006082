import { GqlMailableUserFragment } from '#gql';
import { HighlightedAnchor } from '#ui/link';

export interface MailToUserProps {
  user: GqlMailableUserFragment;
  subject: string;
  body: string;
}

export function MailToUser(props: MailToUserProps) {
  const { user, subject, body } = props;
  const mailto = buildMailTo({ user, subject, body });

  let children = `${user.name}`;
  if (user.service?.name) {
    children += ` (${user.service.name})`;
  }

  return <HighlightedAnchor href={mailto}>{children}</HighlightedAnchor>;
}

function buildMailTo(props: MailToUserProps) {
  const { user } = props;
  const name = encodeURIComponent(user.name ?? '');
  const email = encodeURIComponent(user.email ?? '');
  const subject = encodeURIComponent(props.subject);
  const body = encodeURIComponent(props.body);

  return `mailto:${name}<${email}>?subject=${subject}&body=${body}`;
}
