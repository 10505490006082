import { useEffect, useRef } from 'react';

import { useRectoVerso } from '../../../../hooks/useRectoVerso';
import { useNewRoiContext } from '../utils';

import { PadifRoisDisplayer } from './PadifRoisDisplayer';

import { assertNotNull } from '#utils/assert';

export function PadifImage() {
  const [{ imageSize, analysis, view }, { setImageSize }] = useNewRoiContext();
  const ref = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const current = ref.current;
    if (!current) return;

    function onLoad(event: Event) {
      const image = event.target as HTMLImageElement;

      setImageSize({
        width: image.naturalWidth,
        height: image.naturalHeight,
      });
    }

    current.addEventListener('load', onLoad);
    return () => current.removeEventListener('load', onLoad);
  }, [setImageSize]);

  assertNotNull(analysis.referenceDocument);
  const { recto, verso } = useRectoVerso(analysis.referenceDocument);
  const url = (view === 'RECTO' ? recto : verso)?.document.url;

  if (imageSize) {
    return <PadifRoisDisplayer url={url} />;
  }

  return <img ref={ref} src={url} style={{ maxHeight: '60vh' }} />;
}
