import clsx from 'clsx';

import { MandatoryChildren } from '../../global';

export function PageLayoutBreadcrumb(props: MandatoryChildren) {
  return (
    <nav className="flex h-full items-center gap-2">
      <ol className="contents">{props.children}</ol>
    </nav>
  );
}

export interface PageLayoutBreadcrumbItemProps extends MandatoryChildren {
  noDefaultTextColor?: boolean;
}

export function PageLayoutBreadcrumbItem(props: PageLayoutBreadcrumbItemProps) {
  return (
    <li className={cssItem(props.noDefaultTextColor)}>
      {/* children must be ONE item in PageLayoutBreadcrumb flex flow so wrap around it in a div */}
      <span>{props.children}</span>
      {/* `::after` pseudo-element is inside the li element and contain chevron */}
    </li>
  );
}

export function PageLayoutBreadcrumbIcon(props: MandatoryChildren) {
  return (
    <li className={cssItem()}>
      <span>{props.children}</span>
    </li>
  );
}

function cssItem(noDefaultTextColor?: boolean) {
  return clsx(
    'font-semibold',
    !noDefaultTextColor &&
      'text-neutral-500 after:text-neutral-500 hover:text-neutral-700 hover:after:text-neutral-500',
    !noDefaultTextColor &&
      'transition duration-150 ease-in-out after:transition-none',
    "after:-translate-y-[0.1875rem] after:scale-x-75 after:scale-y-125 after:text-xl after:content-['>'] last-of-type:after:content-none",
    'contents',
  );
}
