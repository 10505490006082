import { array, boolean, ObjectShape } from 'yup';

import { userRoles } from '../constants';

// eslint-disable-next-line no-restricted-imports
import * as components from '#tailwind_ui';

export const requiredMinMessage =
  'form.validation.min' satisfies TranslationKey;
export const requiredMessage =
  'form.validation.required' satisfies TranslationKey;
export const emailMessage = 'form.validation.email' satisfies TranslationKey;
export const caseNumberMessage =
  'form.validation.case_number' satisfies TranslationKey;
export const serieNameMessage =
  'form.validation.serie_name' satisfies TranslationKey;
export const urlMessage = 'form.validation.url' satisfies TranslationKey;

export const requiredNumber = components.requiredNumber(requiredMessage);
export const optionalString = components.optionalString();
export const requiredString = components.requiredString(requiredMessage);
export const requiredEmail = requiredString.email(emailMessage);
export const optionalBoolean = boolean().optional();
export const requiredBoolean = boolean().required();

export function requiredObject<T extends ObjectShape>(
  spec?: T,
  message?: string,
) {
  return components.requiredObject(spec, message || requiredMessage);
}

export const requiredRoles = array()
  .of(requiredString.oneOf(userRoles))
  .min(1, requiredMessage);

export const requiredArray = array()
  .min(1, requiredMinMessage)
  .required(requiredMessage);

export const urlString = optionalString.url(urlMessage);

const caseNumberReg = /^[\w/-]+$/;
export const requiredCaseNumber = requiredString.matches(
  caseNumberReg,
  caseNumberMessage,
);

const serieNameReg = /^[\d A-Z_-]+$/;
export const requiredSerieName = requiredString.matches(
  serieNameReg,
  serieNameMessage,
);
