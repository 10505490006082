import { useOutletContext } from 'react-router-dom';

import FormattedEmptyState from '../translation/FormattedEmptyState';

import PadifReport from './report/PadifReport';

import { GqlPadifAnalysisQuery, usePadifAnalysisResultQuery } from '#gql';

export default function PadifShowMainReportPage() {
  const padif = useOutletContext<GqlPadifAnalysisQuery['padifAnalysis']>();
  const { data, error } = usePadifAnalysisResultQuery({
    variables: { id: padif.id },
  });

  if (error) throw error;
  if (!data) return null;

  const { referenceDocument, result } = data.padifAnalysis;

  if (!referenceDocument) {
    return <FormattedEmptyState title="padif.report.no_reference_document" />;
  }

  if (
    !result ||
    result.documents.length === 0 ||
    result.distanceMatrix.length === 0
  ) {
    return <FormattedEmptyState title="page.padif.report.no_report" />;
  }

  return <PadifReport referenceId={referenceDocument.id} result={result} />;
}
