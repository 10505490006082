import { createContext, useContext } from 'react';

import { TableSearchContext } from '../components/providers/TableSearchContextProvider';
import {
  DocumentSetFilters,
  DocumentSetFiltersStore,
} from '../stores/searches/DocumentSetFiltersStore';

import { DocumentSetsSortFields } from '#gql';

export const documentSetContext = createContext<TableSearchContext<
  DocumentSetsSortFields,
  DocumentSetFilters,
  DocumentSetFiltersStore
> | null>(null);

export function useDocumentSetContext() {
  const context = useContext(documentSetContext);

  if (context === null) {
    throw new Error(
      'useDocumentSetContext must be used within a TableSearchContextProvider',
    );
  }

  return context;
}
