import { useMemo } from 'react';

import { HighlightedAnchor } from '#ui/link';
import { PageLayout } from '#ui/page_layout';

const PROBLEM_SUBJECT = `[problem] _Your issue title_`;
const PROBLEM_BODY = `Dear Zakodium team,

Something doesn't work as expected in ProFID, here is my issue:

Here are my expectations: 

Contextual information:
- URL of the page on which the problem happened:
- Please join screenshot·s: 

Best regards,`;

const FEEDBACK_SUBJECT = `[feedback] _Your feedback topic_`;
const FEEDBACK_BODY = `Dear Zakodium team,

I have feedback about ProFID:

Contextual information: 

Best regards,`;

const QUESTION_SUBJECT = `[question] _Your question in one small sentence_`;
const QUESTION_BODY = `Dear Zakodium team,

I have the following question about ProFID:

Contextual information: 

Best regards,`;

const OTHER_SUBJECT = `[other] _Your object_`;
const OTHER_BODY = `Hello,



Best regards,`;

export function AdminContact() {
  const links = useMemo(() => {
    return [
      {
        id: 'problem',
        label: 'Problem',
        url: mailtoUrl(
          'support+profid@zakodium.com',
          PROBLEM_SUBJECT,
          PROBLEM_BODY,
        ),
      },
      {
        id: 'feedback',
        label: 'Feedback',
        url: mailtoUrl(
          'support+profid@zakodium.com',
          FEEDBACK_SUBJECT,
          FEEDBACK_BODY,
        ),
      },
      {
        id: 'question',
        label: 'Question',
        url: mailtoUrl(
          'support+profid@zakodium.com',
          QUESTION_SUBJECT,
          QUESTION_BODY,
        ),
      },
      {
        id: 'other',
        label: 'Other',
        url: mailtoUrl(
          'support+profid@zakodium.com',
          OTHER_SUBJECT,
          OTHER_BODY,
        ),
      },
    ];
  }, []);

  return (
    <PageLayout title="Contact Support" bodyClassName="max-w-xl">
      <p>About one of theses topics:</p>

      <ul>
        {links.map((l) => (
          <li key={l.id}>
            <HighlightedAnchor href={l.url}>{l.label}</HighlightedAnchor>
          </li>
        ))}
      </ul>

      <p>
        Please fill in the blanks and remove the placeholders in the template.
      </p>
    </PageLayout>
  );
}

function mailtoUrl(email: string, subject: string, body: string) {
  const searchParams = new URLSearchParams({
    subject,
    body,
  });

  // custom to string, because space was replaced by `+` instead `%20`
  // and mail clients (thunderbird for example) do not support parse back `+` to ` `
  const search = Array.from(
    searchParams.entries(),
    ([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`,
  ).join('&');

  return `mailto:${email}?${search}`;
}
