import { TableSearchContextProvider } from '../components/providers/TableSearchContextProvider';
import { padifContext } from '../contexts/padif';
import { MandatoryChildren } from '../global';
import { UsePaginationTableInitial } from '../hooks/usePaginationTable';
import { SortTable } from '../hooks/useSortTable';
import {
  PadifFilters,
  PadifFiltersStore,
} from '../stores/searches/PadifFiltersStore';

import { PadifAnalysisSortField } from '#gql';

const initialSort = {
  field: 'updatedAt',
  direction: 'DESC',
} as const satisfies SortTable<PadifAnalysisSortField>;

const initialPagination = {
  label: 'padif.list.pagination',
} as const satisfies Partial<UsePaginationTableInitial>;

export function PadifTableSearchProvider({ children }: MandatoryChildren) {
  return (
    <TableSearchContextProvider<
      PadifAnalysisSortField,
      PadifFilters,
      typeof PadifFiltersStore,
      PadifFiltersStore
    >
      context={padifContext}
      Store={PadifFiltersStore}
      initialSort={initialSort}
      initialPagination={initialPagination}
    >
      {children}
    </TableSearchContextProvider>
  );
}
