import { T, useTranslate } from '@tolgee/react';
import { useCallback, useMemo, useRef, useState } from 'react';

import { usePadifContext } from '../../contexts/padif';

import {
  GqlDocumentsSearchSelectQuery,
  useDocumentsSearchSelectQuery,
} from '#gql';
import { MultiSearchSelect } from '#tailwind_ui';
import { getDocumentTitle } from '#utils/index';

type Document = GqlDocumentsSearchSelectQuery['documents']['nodes'][number];

export function PadifDocSearchFilter() {
  const { t } = useTranslate();
  const { filters, store } = usePadifContext();
  const [search, setSearch] = useState('');

  const result = useDocumentsSearchSelectQuery({
    variables: {
      search,
    },
  });
  const data = result.data || result.previousData;

  const docs = useRef(new Map<string, Document>());
  const options = useMemo(() => {
    return (
      data?.documents?.nodes?.map((doc) => {
        docs.current.set(doc.id, doc);
        return doc.id;
      }) ?? []
    );
  }, [data]);

  const renderOption = useCallback((option: string) => {
    const doc = docs.current.get(option);

    if (!doc) return <T keyName="global.unspecified" />;

    return (
      <article className="leading-tight">
        <header>{doc.seqId}</header>
        <p className="text-xs text-neutral-500">
          {getDocumentTitle(doc, true)}
        </p>
      </article>
    );
  }, []);

  const renderSelectedOption = useCallback((option: string) => {
    const doc = docs.current.get(option);

    if (!doc) return <T keyName="global.unspecified" />;

    return doc.seqId;
  }, []);
  const selected = useMemo(
    () => filters.referenceDocuments.map((d) => d ?? ''),
    [filters.referenceDocuments],
  );

  if (result.error) throw result.error;

  return (
    <MultiSearchSelect<string>
      name="doc"
      selected={selected}
      onChange={store.setDocuments}
      renderOption={renderOption}
      renderSelectedOption={renderSelectedOption}
      options={options}
      searchValue={search}
      onSearchChange={setSearch}
      label="doc"
      hiddenLabel
      placeholder={t('padif.list.filters.doc')}
      getValue={(option) => option}
      highlightClassName="[&_article>p]:text-neutral-200 [&_article>header]:text-white bg-primary-600"
    />
  );
}
