import { ReactNode, useEffect } from 'react';

import { useBroadcastChannel } from '../../hooks/useBroadCastChannel';

import {
  loginBroadcastChannelContext,
  loginContext,
  loginDispatchContext,
  useLoginBroadcastChannelContext,
  useLoginReducer,
  useSession,
} from './LoginProvider.context';

export function LoginProvider(props: { children: ReactNode }) {
  const [loginState, loginDispatch] = useLoginReducer();
  const channel = useBroadcastChannel('ProFID-Login-Sync');

  return (
    <loginContext.Provider value={loginState}>
      <loginDispatchContext.Provider value={loginDispatch}>
        <loginBroadcastChannelContext.Provider value={channel}>
          {props.children}
        </loginBroadcastChannelContext.Provider>
      </loginDispatchContext.Provider>
    </loginContext.Provider>
  );
}

export function CheckSessionOnOutsideLogin() {
  const channel = useLoginBroadcastChannelContext();
  const session = useSession();

  useEffect(() => {
    function listenOutsideLogin(event: MessageEvent<'login'>) {
      if (event.data !== 'login') return;

      session();
    }

    channel.addEventListener('message', listenOutsideLogin);

    return () => channel.removeEventListener('message', listenOutsideLogin);
  }, [channel, session]);

  return null;
}
