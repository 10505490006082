import { T, useTranslate } from '@tolgee/react';

import SeriesLink from '../serie/SeriesLink';

import { GqlDetailedDocumentFieldsFragment, useSeriesQuery } from '#gql';
import { CardLayout } from '#ui/card_layout';

export default function DocumentSeriesSuggestions(props: {
  document: GqlDetailedDocumentFieldsFragment;
}) {
  const { t } = useTranslate();
  const { document } = props;

  const { error, data } = useSeriesQuery({
    variables: {
      filterBy: {
        docType: [document.docType],
        docCountry: [document.docCountry],
        docFraudType: [document.docFraudType],
      },
    },
  });

  if (error) throw error;
  if (!data) return null;
  const series = data.series.nodes;

  return (
    <CardLayout
      title="global.homologous_series"
      bodyClassName="flex flex-col gap-2"
    >
      {series.length === 0 && (
        <div className="text-neutral-500">
          <T keyName="page.doc.view.suggestions.no_similiar_series" />
        </div>
      )}
      {series.length > 0 && (
        <div>
          {series.map((serie, idx) => (
            <span key={serie.seqId}>
              <SeriesLink series={serie} />
              {idx === series.length - 1 ? '' : ', '}
            </span>
          ))}
        </div>
      )}

      <div>
        {document.series &&
          t('doc.is_in_serie', {
            serie: <SeriesLink key="serie" series={document.series} />,
          })}
        {!document.series && <T keyName="doc.is_not_in_serie" />}
      </div>
    </CardLayout>
  );
}
