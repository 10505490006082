import { safeMappedTranslationKey } from './common';

import { DocumentSetType } from '#gql';

export const TRANSLATIONS_KEY_DOCUMENT_SET_TYPE = {
  manual: 'doc_set.field.type.MANUAL',
  dynamic: 'doc_set.field.type.DYNAMIC',
} as const satisfies Record<DocumentSetType, TranslationKey>;

export function safeTranslationKeyDocumentSetType(
  index: DocumentSetType | null | undefined,
) {
  return safeMappedTranslationKey(TRANSLATIONS_KEY_DOCUMENT_SET_TYPE, index);
}
