import { FolderIcon } from '@heroicons/react/24/outline';
import { useTranslate } from '@tolgee/react';
import { memo } from 'react';

import DocumentLink from '../document/DocumentLink';
import FormattedEmptyState from '../translation/FormattedEmptyState';
import FormattedRelativeDate from '../translation/FormattedRelativeDate';

import { GqlSerieQuery } from '#gql';

interface SerieDocumentsProps {
  documents: GqlSerieQuery['serie']['documents']['nodes'];
}

function SerieDocumentsBase(props: SerieDocumentsProps) {
  const { t } = useTranslate();

  if (props.documents.length === 0) {
    return (
      <FormattedEmptyState title="doc.search.noresults" icon={<FolderIcon />} />
    );
  }

  return (
    <div className="flex flex-col gap-3">
      {props.documents.map((doc) => (
        <div key={doc.id} className="flex flex-1 justify-between gap-2">
          <DocumentLink document={doc} multiline includeService />
          {doc.seriesUpdatedAt && (
            <div className="text-neutral-500">
              {t('doc.series_updated_at', {
                relativeDate: (
                  <FormattedRelativeDate
                    key="relativeDate"
                    value={doc.seriesUpdatedAt}
                  />
                ),
              })}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

const SerieDocuments = memo(SerieDocumentsBase);
export default SerieDocuments;
