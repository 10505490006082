import { useTranslate } from '@tolgee/react';

import { ErrorSerializer } from '#tailwind_ui';

export function useFormattedSerializeError() {
  const { t } = useTranslate();

  const serializer: ErrorSerializer = (error) => {
    if (!error) {
      return error;
    }

    if ('message' in error) {
      return t(error.message);
    }

    return String(error);
  };

  return serializer;
}
