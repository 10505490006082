import { T, useTranslate } from '@tolgee/react';

import { useScanContext } from '../../contexts/scan';
import { safeTranslationKeyScanStatus } from '../../translationsMapping/scans';

import { ScanProcessStatus } from '#gql';
import { MultiSearchSelect, useMultiSearchSelect } from '#tailwind_ui';

const allStatuses = Object.values(ScanProcessStatus);

const selectableStatuses: ScanProcessStatus[] = [
  'TO_BE_REVIEWED',
  'IMPORTED',
  'PENDING',
  'ERROR',
  'IMPORTING',
  'FINALIZING',
  'PROCESSING',
];

const options = selectableStatuses.map((status) => ({
  label: status,
  value: status,
}));

export default function ScanSelectStatuses() {
  const { t } = useTranslate();
  const selectStatusLabel = t('global.status');

  const { filters, store } = useScanContext();

  const selectedOptions = options.filter((option) =>
    filters.statuses.includes(option.value),
  );
  const hook = useMultiSearchSelect({
    options,
  });

  return (
    <MultiSearchSelect
      label={selectStatusLabel}
      hiddenLabel
      placeholder={selectStatusLabel}
      {...hook}
      renderOption={(opt) => {
        return <T keyName={safeTranslationKeyScanStatus(opt.label)} />;
      }}
      selected={selectedOptions}
      onChange={(newSelection) => {
        const statuses =
          newSelection.flatMap((s) => {
            return allStatuses.filter((option) => {
              return option.startsWith(s.value);
            });
          }) ?? [];

        store.setStatuses(statuses);
      }}
    />
  );
}
