import { useTranslate } from '@tolgee/react';

import { Badge, ColoredBadgeProps, VariantBadgeProps } from '#tailwind_ui';

type FormattedBadgeProps = (
  | Omit<VariantBadgeProps, 'label'>
  | Omit<ColoredBadgeProps, 'label'>
) & {
  label: TranslationKey;
};

export function FormattedBadge(props: FormattedBadgeProps) {
  const { t } = useTranslate();
  const label = t(props.label);
  return <Badge {...props} label={label} />;
}
