import { useTranslate } from '@tolgee/react';
import { FormattedDate } from 'react-intl';

import {
  DraftOrPublicationAlert,
  getDate,
  getUser,
  isAlertDraft,
} from './utils/draftOrPublication';

export default function AlertDocumentInfo(props: {
  alert: DraftOrPublicationAlert;
}) {
  const { t } = useTranslate();
  const isDraft = isAlertDraft(props.alert);
  const date = getDate(props.alert);
  const userName = getUser(props.alert);

  return (
    <div className="text-sm italic text-neutral-800 print:hidden">
      {t(
        isDraft ? 'series.alert.last_revised_by' : 'series.alert.published_by',
        {
          userName,
          date: <FormattedDate key={date.toISOString()} value={date} />,
        },
      )}
    </div>
  );
}
