import { useApolloClient } from '@apollo/client';
import { useTolgee } from '@tolgee/react';
import { ReactNode, useCallback, useMemo } from 'react';

import {
  LOCALE_KEY,
  LocaleContext,
  localeContext,
  possibleLocales,
} from './LocaleProvider.context';

import { Locale } from '#gql';
import { LocaleProvider as LocaleComponentsProvider } from '#tailwind_ui';

export function LocaleProvider({ children }: { children: ReactNode }) {
  const graphqlClient = useApolloClient();
  const tolgee = useTolgee(['language']);
  const locale = tolgee.getLanguage() as Locale;

  const proxiedSetLocale = useCallback(
    (newLocale: Locale) => {
      if (!possibleLocales.includes(newLocale)) {
        throw new Error(`invalid locale: ${newLocale}`);
      }
      localStorage.setItem(LOCALE_KEY, newLocale);
      void graphqlClient.resetStore();
      tolgee.changeLanguage(newLocale).catch(reportError);
    },
    [tolgee, graphqlClient],
  );
  const contextValue = useMemo<LocaleContext>(
    () => [locale || '', proxiedSetLocale],
    [locale, proxiedSetLocale],
  );

  return (
    <localeContext.Provider value={contextValue}>
      <LocaleComponentsProvider locale={locale}>
        {children}
      </LocaleComponentsProvider>
    </localeContext.Provider>
  );
}
