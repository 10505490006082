import { T, useTranslate } from '@tolgee/react';
import { useMemo } from 'react';
import { Outlet } from 'react-router-dom';

import DocumentSetTitle from '../DocumentSet/DocumentSetTitle';
import LinkGroup, { LinkItem } from '../LinkGroup';
import { useExperimentalFeatures } from '../experimental-features/useExperimentalFeatures';
import IfHasPermission from '../permissions/IfHasPermission';
import { UserChangesMeta } from '../user/UserChangesMeta';

import PadifSettings from './PadifSettings';

import { GqlPadifAnalysisQuery } from '#gql';
import { PageLayout, PageLayoutNavigation } from '#ui/page_layout';

interface PadifMainProps {
  padif: GqlPadifAnalysisQuery['padifAnalysis'];
}

export default function PadifShowMain(props: PadifMainProps) {
  const { padif } = props;
  const { t } = useTranslate();

  const { featureSet } = useExperimentalFeatures();

  const padifPages = useMemo(() => {
    const pages: LinkItem[] = [
      { id: 'ROI', intlId: 'page.padif.title.rois' },
      { id: 'REPORT', intlId: 'page.padif.title.report' },
    ];

    if (featureSet.has('padifJs')) {
      pages.push({ id: 'NEW-REPORT', intlId: 'padif.title.new_report' });
    }

    return pages;
  }, [featureSet]);

  const title = t({ key: 'page.padif.title', params: { id: padif.id } });

  return (
    <PageLayout
      title={title}
      navigation={
        <>
          <PageLayoutNavigation to="/padif">
            <T keyName="nav.padif" />
          </PageLayoutNavigation>
          <PageLayoutNavigation to={`/document-sets/${padif.documentSet.id}`}>
            <DocumentSetTitle value={padif.documentSet} />
          </PageLayoutNavigation>
        </>
      }
      actions={
        <UserChangesMeta
          {...padif}
          mailSubject={`[${t('nav.padif', { noWrap: true })}] ${title}`}
          mailBody={window.location.href}
        />
      }
      bodyClassName="relative"
    >
      <LinkGroup options={padifPages} />
      <IfHasPermission userPermission="padifAnalysis_update">
        <div className="absolute right-0 top-4 flex flex-row gap-2">
          <T keyName="page.padif.is_active" params={{ active: padif.active }} />
          <div className="inline-block">
            <PadifSettings padif={padif} />
          </div>
        </div>
      </IfHasPermission>

      <Outlet context={padif} />
    </PageLayout>
  );
}
