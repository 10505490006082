import { T } from '@tolgee/react';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';

import { useScanIdContext } from '../../../../scopes/scan_import/pages/scan_import_page/contexts/scan_id_context';
import { useSwitchTabContext } from '../../../../scopes/scan_import/pages/scan_import_page/contexts/switch_tab_context';
import FormattedButton from '../../../translation/FormattedButton';
import FormattedErrorAlert from '../../../translation/FormattedErrorAlert';

import { useConfirmMrzAreaMutation } from '#gql';

export function MRZUserConfirmation() {
  const id = useScanIdContext();
  const { setTab } = useSwitchTabContext();

  const [mutation, result] = useConfirmMrzAreaMutation({
    variables: {
      input: {
        id,
      },
    },
  });

  const onSwitch = useCallback(() => setTab('mrz'), [setTab]);

  function onConfirm() {
    mutation().catch(reportError);
  }

  return (
    <div className="space-y-2">
      {result.error && <FormattedErrorAlert error={result.error} />}
      <div className="flex flex-col items-start gap-2 rounded border-2 border-warning-400 px-6 py-2">
        <p>
          <T keyName="scan.import.mrz.user_confirm" />
        </p>
        <div className="flex items-center gap-2">
          <FormattedButton
            color="warning"
            messageId="global.yes"
            onClick={onConfirm}
            disabled={result.loading}
          />
          <FormattedButton
            as={Link}
            to="./mrz"
            messageId="scan.step.review.feed.mrz.button"
            variant="white"
            onClick={onSwitch}
          />
        </div>
      </div>
    </div>
  );
}
