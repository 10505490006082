import { PlusIcon } from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';
import { DefaultValues } from 'react-hook-form';

import FormattedButton from '../translation/FormattedButton';
import FormattedSubmitButton from '../translation/FormattedSubmitButton';

import NewSeries from './NewSeriesFields';
import { getSeriesValidationSchema } from './newSerieForm';

import { GqlNewSerieInput } from '#gql';
import { FormattedAlert } from '#intl';
import {
  FormRHFDialogBody,
  FormRHFDialogFooter,
  FormRHFDialogRoot,
  FormRHFDialogTitle,
} from '#tailwind_ui';

interface NewSeriesModalProps {
  isOpen: boolean;
  close: () => void;
  onSubmit: (formData: GqlNewSerieInput) => void;
  defaultValues: DefaultValues<GqlNewSerieInput>;
  title: TranslationKey;
  excludedNames: string[];
}

export default function NewSeriesModal(props: NewSeriesModalProps) {
  const { isOpen, close, onSubmit, defaultValues, title, excludedNames } =
    props;

  return (
    <FormRHFDialogRoot<GqlNewSerieInput>
      open={isOpen}
      icon={<PlusIcon />}
      onOpenChange={close}
      size="small"
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      validationSchema={getSeriesValidationSchema(
        'doc.field.new_series.create.exists',
        excludedNames,
      )}
    >
      <FormRHFDialogTitle>
        <T keyName={title} />
      </FormRHFDialogTitle>
      <FormRHFDialogBody>
        <div className="space-y-2">
          <NewSeries />
          <FormattedAlert type="info" messageId="doc.field.new_series.help" />
        </div>
      </FormRHFDialogBody>
      <FormRHFDialogFooter>
        <div className="flex gap-2">
          <FormattedButton
            messageId="global.cancel"
            variant="white"
            onClick={close}
          />
          <FormattedSubmitButton
            color="primary"
            messageId="doc.field.new_series.set.confirm"
          />
        </div>
      </FormRHFDialogFooter>
    </FormRHFDialogRoot>
  );
}
