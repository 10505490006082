import {
  ExperimentalFeatureWithState,
  useExperimentalFeatures,
} from './useExperimentalFeatures';

// Experimental features are not translated for now.
// eslint-disable-next-line no-restricted-imports
import { Alert, Toggle } from '#tailwind_ui';

export default function ExperimentalFeaturesConfig() {
  const { features, toggleFeature } = useExperimentalFeatures();
  return (
    <div className="space-y-4">
      <h1 className="text-center text-3xl">Experimental Features</h1>
      <Alert type="info">
        This page is for configuring experimental features. You can enable or
        disable them here.
        <br />
        Note that these settings are only saved locally.
        <br />
        If you log in on another browser, your previous configuration will not
        be available.
      </Alert>
      <div className="space-y-4">
        {features.map((feature) => (
          <ExperimentalFeatureToggle
            key={feature.id}
            feature={feature}
            toggleFeature={() => toggleFeature(feature.id)}
          />
        ))}
      </div>
    </div>
  );
}

interface ExperimentalFeatureToggleProps {
  feature: ExperimentalFeatureWithState;
  toggleFeature: () => void;
}

function ExperimentalFeatureToggle(props: ExperimentalFeatureToggleProps) {
  const { feature, toggleFeature } = props;
  return (
    <div className="space-y-2">
      <Toggle
        label={feature.name}
        activated={feature.enabled}
        onChange={toggleFeature}
      />
      <p className="text-sm text-neutral-800">{feature.description}</p>
    </div>
  );
}
