import { Route } from 'react-router-dom';

import LabelEditPage from './pages/edit';
import LabelIndexPage from './pages/index';
import LabelNewPage from './pages/new';

export function labelRoutes() {
  return [
    <Route key="/documents/labels" path="/documents/labels">
      <Route index element={<LabelIndexPage />} />
      <Route path="new" element={<LabelNewPage />} />
      <Route path=":labelId" element={<LabelEditPage />} />
    </Route>,
  ];
}
