import { BoltIcon } from '@heroicons/react/20/solid';
import { T } from '@tolgee/react';

import { useDocumentContext } from '../providers/DocumentProvider.context';

import { Tooltip } from '#tailwind_ui';

export default function DocSerialNumber() {
  const { document } = useDocumentContext();

  const { docSerialNumber, similarSerialNumbers } = document;
  if (!docSerialNumber) return null;
  const hasSimilar = similarSerialNumbers.totalCount > 0;

  return (
    <div className="flex">
      <u>
        <T keyName="doc.field.document_number" />
      </u>
      {': '}
      {hasSimilar ? <SimilarSN /> : docSerialNumber}
    </div>
  );
}

function SimilarSN() {
  const { document } = useDocumentContext();

  return (
    <Tooltip
      delay={150}
      content={<T keyName="page.doc.view.found_similar_document_number" />}
    >
      <div className="ml-1 flex items-center gap-1 text-alternative-500">
        {document.docSerialNumber}
        <BoltIcon className="h-5 w-5" />
      </div>
    </Tooltip>
  );
}
