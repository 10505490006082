import { ScannerData } from './Scanner';

export function getQrDataScanner(data: ScannerData) {
  return JSON.stringify({
    _: 'S2',
    o: data.service, // service
    b: data.brand, // brand
    m: data.model, // model
    sn: data.serial || '', // serial number
    d: data.description || '', // description
  });
}
