import { T } from '@tolgee/react';
import { FormattedDate } from 'react-intl';

import { Feature } from '../../constants';
import { useSeriesContext } from '../../contexts/series';
import { useSyncTableSearchParams } from '../../hooks/useSyncTableSearchParams';
import DocumentCell from '../DocumentCell';
import IfHasFeature from '../IfHasFeature';
import { IndexFilterError } from '../TableSearch/IndexFilterError';
import { TableSearchLayout } from '../TableSearch/TableSearchLayout';
import { AlertWidget } from '../alerts/AlertWidget';
import { FormattedTable } from '../translation/FormattedTable';

import SeriesLink from './SeriesLink';
import SeriesSearchFilter from './SeriesSearchFilter';

import { GqlSeriesQuery, useSeriesQuery } from '#gql';
import { Td, Th, Tooltip } from '#tailwind_ui';
import { PageLayout } from '#ui/page_layout';

export default function SeriesIndexPage() {
  return (
    <PageLayout title={<T keyName="nav.series.list" />}>
      <SeriesTableSearch />
    </PageLayout>
  );
}

function SeriesTableSearch() {
  const context = useSeriesContext();

  const queryResult = useSeriesQuery({
    variables: {
      ...context.pagination,
      filterBy: context.store.gqlFilter,
      sortBy: context.sort,
    },
  });

  const data = queryResult.data || queryResult.previousData;
  const error = queryResult.error;

  useSyncTableSearchParams(context);

  if (error) return <IndexFilterError error={error} context={context} />;
  if (!data) return null;

  const { totalCount, facets } = data.series;

  return (
    <TableSearchLayout
      context={context}
      advancedFilter={
        <SeriesSearchFilter facets={context.store.getCorrectedFacets(facets)} />
      }
    >
      <FormattedTable
        data={data?.series?.nodes || []}
        renderHeader={renderHeader}
        sort={context.tableSort}
        renderTr={renderRow}
        pagination={{
          ...context.tablePagination,
          totalCount,
        }}
        renderEmpty={() => <T keyName="page.series.search.noresults" />}
      />
    </TableSearchLayout>
  );
}

function renderHeader() {
  return (
    <tr>
      <Th sortField="name">
        <T keyName="series.field.name" />
      </Th>
      <Th>
        <T keyName="series.field.aliases" />
      </Th>
      <IfHasFeature feature={Feature.documents}>
        <Th>
          <T keyName="global.title.nb_doc" />
        </Th>
      </IfHasFeature>
      <Th className="w-[350px]">
        <T keyName="doc.field.type" />
      </Th>
      <Th>
        <T keyName="global.alert" />
      </Th>
      <Th className="w-[150px]" sortField="createdAt">
        <T keyName="serie.table.created_at" />
      </Th>
      <Th className="w-[150px]" sortField="importantFieldsUpdatedAt">
        <Tooltip
          content={
            <div className="w-96 text-xs">
              <T keyName="serie.table.updated_at.help" />
            </div>
          }
        >
          <div>
            <T keyName="serie.table.updated_at" />
          </div>
        </Tooltip>
      </Th>
    </tr>
  );
}

function renderRow(value: GqlSeriesQuery['series']['nodes'][number]) {
  return (
    <tr className="even:bg-neutral-50">
      <Td className="align-middle">
        <SeriesLink series={value} multiline />
      </Td>
      <Td>
        {value.aliases?.map((alias) => (
          <p className="text-xs text-neutral-500" key={alias}>
            {alias}
          </p>
        ))}
      </Td>
      <IfHasFeature feature={Feature.documents}>
        <Td className="align-middle">{value.documents.totalCount}</Td>
      </IfHasFeature>
      <Td>
        <DocumentCell value={value} />
      </Td>
      <Td>
        <AlertWidget alert={value.currentAlert} />
      </Td>
      <Td className="align-middle">
        <FormattedDate value={value.createdAt} />
      </Td>
      <Td className="align-middle">
        <FormattedDate value={value.importantFieldsUpdatedAt} />
      </Td>
    </tr>
  );
}
