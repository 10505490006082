import { AgglomerationMethod } from 'ml-hclust';
import { FormattedDate } from 'react-intl';

import { PadifAnalysisResult } from '../padifGqlTypes';

import PadifClustering from './PadifClustering';

const clusteringMethod: AgglomerationMethod = 'ward';

export interface PadifReportProps {
  referenceId: string;
  result: PadifAnalysisResult;
}

export default function PadifReport(props: PadifReportProps) {
  const { referenceId, result } = props;

  return (
    <div className="mt-2 flex flex-col justify-center">
      <div className="text-center">
        <FormattedDate
          value={result.finishedAt}
          year="numeric"
          month="numeric"
          day="numeric"
          hour="numeric"
          minute="numeric"
          hour12={false}
        />
      </div>
      <div className="text-center">{`${result.distanceMetric} | ${result.distanceCombinationMethod} | ${clusteringMethod}`}</div>
      <PadifClustering
        referenceId={referenceId}
        documents={result.documents}
        distanceMatrix={result.distanceMatrix}
        clusteringMethod={clusteringMethod}
      />
    </div>
  );
}
