import { T } from '@tolgee/react';
import { useMemo, useState } from 'react';

import { Feature } from '../../constants';
import IfHasFeature from '../IfHasFeature';
import ImgLink from '../ImgLink';
import PadifLink from '../Padif/PadifLink';
import PadifShowMainReference from '../Padif/PadifShowMainReference';
import SeriesLink from '../serie/SeriesLink';
import { FormattedTable } from '../translation/FormattedTable';

import DocumentLink from './DocumentLink';

import {
  GqlDetailedDocumentFieldsFragment,
  GqlDocumentPadifAnalysisRoisQuery,
  useDocumentPadifAnalysisDistancesQuery,
  useDocumentPadifAnalysisRoisQuery,
} from '#gql';
import {
  customOptionsFilter,
  SearchSelect,
  Td,
  Th,
  useSearchSelect,
} from '#tailwind_ui';
import { CardLayout } from '#ui/card_layout';

const ROWS_PER_PAGE = 5;

export default function DocumentPadifSuggestions(props: {
  document: GqlDetailedDocumentFieldsFragment;
}) {
  const { document } = props;
  const { error, data } = useDocumentPadifAnalysisRoisQuery({
    variables: {
      id: document.id,
    },
  });
  if (error) throw error;
  if (!data) return null;
  return (
    <DocumentPadifSuggestionsImpl
      key={document.id}
      document={{ ...document, padifAnalyses: data.document.padifAnalyses }}
    />
  );
}

function DocumentPadifSuggestionsImpl({
  document,
}: {
  document: Omit<GqlDetailedDocumentFieldsFragment, 'padifAnalyses'> & {
    padifAnalyses: GqlDocumentPadifAnalysisRoisQuery['document']['padifAnalyses'];
  };
}) {
  const [currentPage, setCurrentPage] = useState(0);

  const selectPadifOptions = useMemo(() => {
    return document.padifAnalyses.map((padifAnalysis) => ({
      value: padifAnalysis,
      label: `#${padifAnalysis.id}`,
    }));
  }, [document.padifAnalyses]);

  const selectPadif = useSearchSelect({
    options: selectPadifOptions,
    initialSelected: selectPadifOptions[0],
    filterOptions: customOptionsFilter((option) => option.label),
  });
  const selectedPadif = selectPadif.selected || selectPadifOptions[0];

  const { data, previousData } = useDocumentPadifAnalysisDistancesQuery({
    variables: {
      id: document.id,
      padifAnalysis: selectedPadif.value.id,
      limit: ROWS_PER_PAGE,
      skip: currentPage * ROWS_PER_PAGE,
    },
  });

  const tableData = data || previousData;

  const result = useMemo(() => {
    return {
      nodes:
        tableData?.document?.padifAnalysisDocumentDistance?.nodes.map(
          (node) => ({ id: node.document.id, ...node }),
        ) || [],
      totalCount:
        tableData?.document?.padifAnalysisDocumentDistance?.totalCount || 0,
    };
  }, [tableData]);

  if (document.padifAnalyses.length === 0) return null;

  return (
    <CardLayout
      header={
        <>
          <div className="flex items-center justify-between">
            <T keyName="page.doc.view.suggestions.padif_distances" />

            <div className="flex items-center gap-2">
              <PadifLink padifAnalysis={selectedPadif.value} />

              <SearchSelect<(typeof selectPadif)['options'][number]>
                label="Select Padif analysis"
                hiddenLabel
                getValue={(option) => option.value.id}
                renderOption={(option) => option.label}
                {...selectPadif}
                onChange={(option) => {
                  selectPadif.onChange(option);
                  setCurrentPage(0);
                }}
              />
            </div>
          </div>
          <div className="flex justify-center">
            <PadifShowMainReference
              maxHeight={150}
              rois={selectedPadif.value.rois}
              document={selectedPadif.value.referenceDocument}
            />
          </div>
        </>
      }
    >
      <FormattedTable
        renderHeader={renderHeader}
        renderTr={({ document, distance }) => {
          return (
            <tr key={document.id}>
              <Td className="align-middle">
                <ImgLink
                  imgStyle={{
                    borderRadius: '0.4rem',
                    maxWidth: 200,
                    maxHeight: 100,
                  }}
                  src={document.images[0].document.url}
                  thumbSrc={document.images[0].documentThumb.url}
                  alt="document preview"
                />
              </Td>
              <Td className="align-middle">
                <DocumentLink document={document} multiline includeService />
              </Td>
              <IfHasFeature feature={Feature.series}>
                <Td className="align-middle">
                  <SeriesLink series={document.series} multiline />
                </Td>
              </IfHasFeature>
              <Td className="align-middle">{distance.toFixed(2)}</Td>
            </tr>
          );
        }}
        data={result.nodes}
        pagination={{
          itemsPerPage: ROWS_PER_PAGE,
          page: currentPage,
          totalCount: result.totalCount,
          onPageChange: (page) => setCurrentPage(page),
        }}
      />
    </CardLayout>
  );
}

function renderHeader() {
  return (
    <tr>
      <Th className="w-[200px]">
        <T keyName="global.image" />
      </Th>
      <Th>
        <T keyName="global.exhibit" />
      </Th>
      <IfHasFeature feature={Feature.series}>
        <Th>
          <T keyName="global.series" />
        </Th>
      </IfHasFeature>
      <Th className="w-1/3">
        <T keyName="global.distance" />
      </Th>
    </tr>
  );
}
