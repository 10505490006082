import { T, useTranslate } from '@tolgee/react';
import clsx from 'clsx';
import { FormattedDate } from 'react-intl';

import { getUserNameWithService } from '../helpers/user';
import {
  safeTranslationKeyDocumentFluo,
  safeTranslationKeyDocumentMRZ,
  safeTranslationKeyDocumentViewBitmap,
  TRANSLATIONS_KEY_DOCUMENT_FRAUD_TYPE,
  TRANSLATIONS_KEY_DOCUMENT_MEDIUM,
  TRANSLATIONS_KEY_DOCUMENT_PRINT_PROCESS,
  TRANSLATIONS_KEY_DOCUMENT_RELATED_OFFENSE,
  TRANSLATIONS_KEY_DOCUMENT_SEIZURE_CONTEXT,
  TRANSLATIONS_KEY_DOCUMENT_TYPE,
} from '../translationsMapping/documents';

import CountryName from './CountryName';
import { useDocumentContext } from './providers/DocumentProvider.context';
import SeriesLink from './serie/SeriesLink';
import { FormattedTable } from './translation/FormattedTable';
import IndexedFormattedMessage from './translation/IndexedFormattedMessage';

import {
  DocumentType,
  FraudType,
  GqlUserWithServiceFragment,
  Maybe,
  PrintProcess,
} from '#gql';
import { RichTextRenderer, Td, Th } from '#tailwind_ui';
import { H4 } from '#ui/heading';

export function CaseOperation() {
  const { document } = useDocumentContext();
  const operation = document.caseOperation;
  if (!operation) return null;

  return (
    <H4>
      <T keyName="doc.field.operation" />
      {`: ${operation}`}
    </H4>
  );
}

interface CaseSeizureDatePlaceProps {
  className?: string;
}

export function CaseSeizureDatePlace(props: CaseSeizureDatePlaceProps) {
  const { document } = useDocumentContext();
  const { t } = useTranslate();

  const date = document.caseSeizureDate;
  const place = document.caseSeizurePlace;

  if (!date && !place) return null;
  let intlId: TranslationKey = 'page.doc.view.case_seizure_date_place';
  if (!date) {
    intlId = 'page.doc.view.case_seizure_place';
  } else if (!place) {
    intlId = 'page.doc.view.case_seizure_date';
  }

  return (
    <div className={clsx('mb-2', props.className)}>
      {date
        ? t(intlId, {
            date: (
              <b key="date">
                <FormattedDate value={date} />
              </b>
            ),
            place: <b key="place">{place}</b>,
          })
        : t(intlId, {
            place: <b key="place">{place}</b>,
          })}
    </div>
  );
}

export function CaseSeizureAuthority() {
  const { document } = useDocumentContext();
  const authority = document.caseSeizureAuthority;
  if (authority == null) return null;

  return (
    <div>
      <u>
        <T keyName="doc.field.seizure_authority" />
      </u>
      {`: ${authority.name}`}
    </div>
  );
}

export function CaseSeizureContext() {
  const { document } = useDocumentContext();
  const context = document.caseSeizureContext;
  if (context === null) return null;

  return (
    <div>
      <u>
        <T keyName="doc.field.seizure_context" />
      </u>
      {': '}
      <IndexedFormattedMessage
        translationMap={TRANSLATIONS_KEY_DOCUMENT_SEIZURE_CONTEXT}
        index={context}
      />
    </div>
  );
}

export function CaseRelatedOffense() {
  const { document } = useDocumentContext();
  const offense = document.caseRelatedOffense;
  if (offense === null) return null;

  return (
    <div>
      <u>
        <T keyName="doc.field.related_offense" />
      </u>
      {': '}
      <IndexedFormattedMessage
        translationMap={TRANSLATIONS_KEY_DOCUMENT_RELATED_OFFENSE}
        index={offense}
      />
    </div>
  );
}

export function CasePresumedNationality() {
  const { document } = useDocumentContext();
  const nationality = document.casePresumedNationality;
  if (!nationality) return null;

  return (
    <div>
      <u>
        <T keyName="doc.field.presumed_nationality" />
      </u>
      {`: `}
      <CountryName country={nationality} />
    </div>
  );
}

export function CaseInformation() {
  const { document } = useDocumentContext();
  const info = document.caseInformation;
  if (!info) return null;

  return (
    <div className="mb-2">
      <b>
        <T keyName="doc.field.case_information" />
      </b>
      {`: `}
      <div className="whitespace-pre-wrap">{info}</div>
    </div>
  );
}

export function CaseIsDocKept() {
  const { document } = useDocumentContext();
  const isDocKept = document.caseIsDocKept;
  if (!isDocKept) return null;

  return (
    <div>
      <b>
        <T keyName="doc.field.case_is_doc_kept.true" />
      </b>
    </div>
  );
}

export function CaseSeries() {
  const { t } = useTranslate();
  const { document } = useDocumentContext();
  const { series } = document;
  if (!series) return null;

  return (
    <div>
      {t('doc.is_in_serie', {
        serie: <SeriesLink key="series" series={series} />,
      })}
    </div>
  );
}

export function CaseIsProfiling() {
  const { document } = useDocumentContext();
  const isProfiling = document.caseIsProfiling;
  if (!isProfiling) return null;

  return (
    <div>
      <b>
        <T keyName="doc.field.case_is_profiling.true" />
      </b>
    </div>
  );
}

export function DocType() {
  const { document } = useDocumentContext();
  const docType = document.docType;
  if (docType === null) return null;

  return (
    <div>
      <u>
        <T keyName="doc.field.type" />
      </u>
      {': '}
      <IndexedFormattedMessage
        translationMap={TRANSLATIONS_KEY_DOCUMENT_TYPE}
        index={docType}
      />
    </div>
  );
}

export function DocFraudType() {
  const { document } = useDocumentContext();
  const docFraudType = document.docFraudType;
  if (docFraudType === null) return null;

  return (
    <div>
      <u>
        <T keyName="doc.field.fraud_type" />
      </u>
      {': '}
      <IndexedFormattedMessage
        translationMap={TRANSLATIONS_KEY_DOCUMENT_FRAUD_TYPE}
        index={docFraudType}
      />
    </div>
  );
}

export function DocCountry() {
  const { document } = useDocumentContext();
  const docCountry = document.docCountry;
  if (!docCountry) return null;

  return (
    <div>
      <u>
        <T keyName="doc.field.country" />
      </u>
      {': '}
      <CountryName country={docCountry} />
    </div>
  );
}

export function DocEmissionLocation() {
  const { t } = useTranslate();
  const { document } = useDocumentContext();
  const location = document.docEmissionLocation;
  if (!location) return null;
  return (
    <div>
      {t('page.doc.view.emission_location', {
        location: <b key="location">{location}</b>,
      })}
    </div>
  );
}

export function DocIsReported() {
  const { document } = useDocumentContext();
  const reported = document.docIsReported;
  if (!reported) return null;

  return (
    <div>
      <T keyName="doc.field.is_reported.true" />
    </div>
  );
}

export function DocIsMrzOk() {
  const { document } = useDocumentContext();
  const mrzOk = document.docIsMrzOk;
  if (mrzOk == null) return null;

  return (
    <div>
      <b>
        <T keyName={safeTranslationKeyDocumentMRZ(mrzOk)} />
      </b>
    </div>
  );
}

export function DocMedium() {
  const { document } = useDocumentContext();
  const medium = document.docMedium;
  if (medium === null) return null;

  return (
    <div>
      <u>
        <T keyName="doc.field.medium" />
      </u>
      {': '}
      <IndexedFormattedMessage
        translationMap={TRANSLATIONS_KEY_DOCUMENT_MEDIUM}
        index={medium}
      />
    </div>
  );
}

export function DocPrintTable() {
  const { document } = useDocumentContext();
  const printBackground = document.docPrintBackground;
  const printNumber = document.docPrintNumber;
  const printData = document.docPrintData;
  const printPhoto = document.docPrintPhoto;

  if (
    printBackground === null &&
    printNumber === null &&
    printData === null &&
    printPhoto === null
  ) {
    return null;
  }

  const data: Array<{ title: TranslationKey; value: typeof printBackground }> =
    [
      { title: 'page.doc.view.print.background', value: printBackground },
      { title: 'page.doc.view.print.number', value: printNumber },
      { title: 'page.doc.view.print.data', value: printData },
      { title: 'page.doc.view.print.picture', value: printPhoto },
    ];

  return (
    <div className="max-w-lg">
      <FormattedTable<{ title: TranslationKey; value: PrintProcess | null }>
        getId={(element) => element.title}
        data={data}
        renderTr={(element) => <TableRow {...element} />}
        renderHeader={() => (
          <tr>
            <Th compact className="p-2">
              <T keyName="page.doc.view.print" />
            </Th>
            <Th />
          </tr>
        )}
      />
    </div>
  );
}

interface TableRowProps {
  title: TranslationKey;
  value: PrintProcess | null;
}

function TableRow(props: TableRowProps) {
  const { value, title } = props;
  if (!value) return null;

  return (
    <tr>
      <Td compact className="p-2">
        <T keyName={title} />
      </Td>
      <Td compact className="w-fit p-2">
        <IndexedFormattedMessage
          translationMap={TRANSLATIONS_KEY_DOCUMENT_PRINT_PROCESS}
          index={value}
        />
      </Td>
    </tr>
  );
}

export function DocIsBackgroundFluo() {
  const { document } = useDocumentContext();
  const isFluo = document.docIsBackgroundFluo;
  if (isFluo == null) return null;

  const id = safeTranslationKeyDocumentFluo(isFluo);

  return (
    <div>
      <T keyName={id} />
    </div>
  );
}

export function DocInternetPurchaseURL() {
  const { document } = useDocumentContext();
  const { docIsPurchasedOnInternet, docInternetPurchaseURL } = document;
  if (!docIsPurchasedOnInternet) return null;

  if (!docInternetPurchaseURL) {
    return (
      <b>
        <T keyName="doc.field.is_purchased_on_internet.true" />
      </b>
    );
  } else {
    return (
      <b>
        <T
          keyName="page.doc.view.internet_purchase_url"
          params={{ purchaseURL: docInternetPurchaseURL }}
        />
      </b>
    );
  }
}

export function DocBitmapCode() {
  const { document } = useDocumentContext();
  const isBitmap = document.docIsBitmapCode;
  if (isBitmap == null) return null;
  const isAnalyzed = document.docIsBitmapCodeAnalyzed;

  return (
    <div>
      {!isAnalyzed && (
        <T keyName={safeTranslationKeyDocumentViewBitmap(isBitmap)} />
      )}
      {isAnalyzed && isBitmap && (
        <T keyName="page.doc.view.is_bitmap_code_analyzed" />
      )}
    </div>
  );
}

export function DocBitmapAnalysis() {
  const { document } = useDocumentContext();
  const analysis = document.docBitmapCodeAnalysis;
  const isBitmap = document.docIsBitmapCode;
  const isAnalyzed = document.docIsBitmapCodeAnalyzed;
  if (!analysis || !isBitmap || !isAnalyzed) return null;

  return (
    <div>
      <u>
        <T keyName="doc.field.doc_bitmap_code_analysis" />
      </u>
      {': '}
      <div className="whitespace-pre-wrap">{analysis}</div>
    </div>
  );
}

export function DocOther() {
  const { document } = useDocumentContext();
  const other = document.docOther;
  if (!other) return null;

  return (
    <div className="mt-2">
      <u>
        <T keyName="doc.field.other" />
      </u>
      {`: `}
      <div className="whitespace-pre-wrap">{other}</div>
    </div>
  );
}

export function SerieManagers({
  managers,
}: {
  managers: GqlUserWithServiceFragment[];
}) {
  if (managers.length === 0) return null;

  const userNames = managers.map((manager) => getUserNameWithService(manager));
  return (
    <div>
      <u>
        <T keyName="series.field.managers" />
      </u>
      {`: ${userNames.join('; ')}`}
    </div>
  );
}

export function SerieProfile({ profile }: { profile: Maybe<string> }) {
  if (!profile) return null;
  return <RichTextRenderer state={profile} />;
}

export function SerieType({ docType }: { docType: DocumentType }) {
  if (docType === null) return null;

  return (
    <div>
      <u>
        <T keyName="doc.field.type" />
      </u>
      {': '}
      <IndexedFormattedMessage
        translationMap={TRANSLATIONS_KEY_DOCUMENT_TYPE}
        index={docType}
      />
    </div>
  );
}

export function SerieFraudType({ fraudType }: { fraudType: FraudType }) {
  if (fraudType === null) return null;

  return (
    <div>
      <u>
        <T keyName="doc.field.fraud_type" />
      </u>
      {': '}
      <IndexedFormattedMessage
        translationMap={TRANSLATIONS_KEY_DOCUMENT_FRAUD_TYPE}
        index={fraudType}
      />
    </div>
  );
}

export function SerieCountry({ country }: { country: string }) {
  if (!country) return null;

  return (
    <div>
      <u>
        <T keyName="doc.field.country" />
      </u>
      {': '}
      <CountryName country={country} />
    </div>
  );
}
