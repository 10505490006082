import { useMemo, useState } from 'react';

/**
 * all these functions are stable
 */
interface UseTableSelectApi<Item> {
  add: (item: Item) => void;
  addAll: (items: Iterable<Item>) => void;
  delete: (item: Item) => void;
  deleteAll: (items: Iterable<Item>) => void;
  clear: () => void;
  set: (items: Iterable<Item>) => void;
  onSelect: (item: Item, isSelected: boolean) => void;
}

export interface UseTableSelect<Item> {
  value: Set<Item>;
  methods: UseTableSelectApi<Item>;
}

export function useTableSelection<Item>(
  initialState: Set<Item> | (() => Set<Item>) = () => new Set<Item>(),
): UseTableSelect<Item> {
  const [state, setState] = useState<Set<Item>>(initialState);

  const api = useMemo<UseTableSelectApi<Item>>(
    () => ({
      add(item) {
        setState((state) => {
          state = new Set(state);
          return state.add(item);
        });
      },
      addAll(items) {
        setState((state) => {
          state = new Set(state);
          for (const item of items) {
            state.add(item);
          }
          return state;
        });
      },
      delete(item) {
        setState((state) => {
          state = new Set(state);
          state.delete(item);
          return state;
        });
      },
      deleteAll(items) {
        setState((state) => {
          state = new Set(state);
          for (const item of items) {
            state.delete(item);
          }
          return state;
        });
      },
      clear() {
        setState(() => new Set());
      },
      set(items) {
        setState(() => new Set(items));
      },
      onSelect(item, isSelected) {
        setState((selected) => {
          selected = new Set(selected);

          if (isSelected) selected.add(item);
          else selected.delete(item);

          return selected;
        });
      },
    }),
    [],
  );

  return useMemo(() => ({ value: state, methods: api }), [api, state]);
}
