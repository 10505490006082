import {
  ArrowDownTrayIcon,
  PencilIcon,
  UserGroupIcon,
} from '@heroicons/react/20/solid';
import { ShieldExclamationIcon } from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { useHasPermission } from '../../hooks/permissions';
import useLanguageParams from '../../hooks/useLanguageParams';
import HalfFullCircle from '../icons/HalfFullCircleIcon';

import DownloadPdfAction from './DownloadPdfAction';
import {
  ALERT_DOWNLOAD_PDF_NOTIFICATION_GROUP,
  getDownloadPdfUrl,
} from './utils/alertPdf';
import { getLanguageKey } from './utils/languages';
import {
  getAlertDraftEditUrl,
  getAlertDraftViewUrl,
  getAlertPublicationViewUrl,
} from './utils/navigation';

import { AlertStatus, GqlSerieQuery, GqlSeriesQuery, Language } from '#gql';
import { Dropdown, useNotificationCenter } from '#tailwind_ui';
import { assert } from '#utils/assert';

interface AlertWidgetProps {
  alert:
    | GqlSerieQuery['serie']['currentAlert']
    | GqlSeriesQuery['series']['nodes'][number]['currentAlert'];
}

export function AlertWidget(props: AlertWidgetProps) {
  const language = useLanguageParams();
  if (!props.alert) {
    return null;
  }
  const { id, draft, currentPublication, status } = props.alert;

  const draftOrPublicationId = currentPublication?.id ?? draft?.id ?? '';
  const translation = currentPublication
    ? currentPublication.translation
    : draft?.translation;

  const title = translation ? translation.fields.title : '';

  if (!draftOrPublicationId) {
    // Users with no alert update are not able to read drafts from the API
    return null;
  }

  const languages = currentPublication
    ? currentPublication.languages
    : draft
      ? draft.languages
      : [];

  return (
    <div
      className={clsx(
        'max-w-fit rounded-md',
        status === 'INITIATED' ? 'bg-neutral-100' : 'bg-warning-100',
      )}
    >
      <div className="flex items-center">
        <div
          className={clsx(
            'rounded-l-md p-2',
            status === 'INITIATED' ? 'bg-neutral-200' : 'bg-warning-200',
          )}
        >
          <ShieldExclamationIcon
            className={clsx(
              'h-6 w-6',
              status === 'INITIATED' ? 'text-neutral-800' : 'text-warning-800',
            )}
          />
        </div>

        <WidgetContent
          publicationId={currentPublication?.id}
          draftId={draft?.id}
          alertId={id}
          status={status}
          title={title}
        />

        <div className="p-2">
          {status === 'INITIATED' ? (
            <Link to={getAlertDraftEditUrl(id)}>
              <PencilIcon className="h-5 w-5 text-neutral-700 hover:text-neutral-800" />
            </Link>
          ) : (
            <DownloadButton
              alertId={id}
              draftOrPublicationId={draftOrPublicationId}
              defaultLanguage={language}
              languages={languages}
            />
          )}
        </div>
      </div>
      <div className="absolute -top-0 left-[26px]" />
    </div>
  );
}

function WidgetContent(props: {
  status: AlertStatus;
  title: string;
  alertId: string;
  publicationId?: string | null;
  draftId?: string | null;
}) {
  const { status, title, alertId: id, draftId, publicationId } = props;
  const draftOrPublicationId = publicationId ?? draftId;
  assert(draftOrPublicationId);
  const hasUpdatePermission = useHasPermission('alert_update');
  const withDetails = status !== 'INITIATED' && hasUpdatePermission;
  const itemId = draftId ?? publicationId;
  assert(itemId);

  if (withDetails) {
    return (
      <div className="mx-2 my-0.5 flex flex-col text-xs font-light leading-tight text-neutral-600">
        <ViewLink
          to={getAlertPublicationViewUrl(id, draftOrPublicationId)}
          status={status}
          title={title}
        />
        <div className="flex items-center gap-1">
          {draftId ? (
            <>
              <HalfFullCircle className="h-4 w-4 text-neutral-600" />
              <span>
                <T keyName="alert.widget.published" />{' '}
                <Link to={getAlertDraftViewUrl(id)} className="hover:underline">
                  <T keyName="alert.widget.with_draft" />
                </Link>
              </span>
            </>
          ) : (
            <>
              <UserGroupIcon className="h-4 w-4 text-neutral-600" />
              <span>
                <T keyName="alert.widget.published" />
              </span>
            </>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className="m-2">
        <ViewLink
          to={
            status === 'INITIATED'
              ? getAlertDraftViewUrl(id)
              : getAlertPublicationViewUrl(id, itemId)
          }
          status={status}
          title={title}
        />
      </div>
    );
  }
}

function ViewLink(props: { to: string; status: AlertStatus; title: string }) {
  const { to, status, title } = props;
  return (
    <Link to={to}>
      <h3
        className={clsx(
          'text-sm font-medium hover:underline',
          status === 'INITIATED' ? 'text-neutral-800' : 'text-warning-800',
        )}
      >
        {title}
      </h3>
    </Link>
  );
}

function DownloadButton({
  alertId,
  draftOrPublicationId,
  defaultLanguage,
  languages,
}: {
  alertId: string;
  draftOrPublicationId: string;
  defaultLanguage: Language;
  languages: Language[];
}) {
  const notification = useNotificationCenter();
  assert(languages.length > 0);

  if (languages.length === 1) {
    return (
      <a
        href={getDownloadPdfUrl({
          alertId,
          draftOrPublicationId,
          language: defaultLanguage,
        })}
        onClick={() => {
          notification.addToastNotification({
            label: <T keyName="alert.download_pdf.will_start" />,
            group: ALERT_DOWNLOAD_PDF_NOTIFICATION_GROUP,
          });
        }}
      >
        <ArrowDownTrayIcon className="h-5 w-5 cursor-pointer text-warning-700 hover:text-warning-800" />
      </a>
    );
  }
  const otherLanguages = languages.filter((lang) => lang !== defaultLanguage);
  const languageOptions = [defaultLanguage, ...otherLanguages];
  return (
    <Dropdown<string>
      options={[
        languageOptions.map((language) => ({
          type: 'action',
          label: <T keyName={getLanguageKey(language)} />,
          data: getDownloadPdfUrl({
            alertId,
            draftOrPublicationId,
            language,
          }),
        })),
      ]}
      renderAction={(Component, option) => {
        assert(option.data);
        return (
          <DownloadPdfAction href={option.data}>
            <Component />
          </DownloadPdfAction>
        );
      }}
      onSelect={() => {
        // do nothing
      }}
      side="bottom"
    >
      <ArrowDownTrayIcon className="h-5 w-5 cursor-pointer text-warning-700 hover:text-warning-800" />
    </Dropdown>
  );
}
