import { useCallback, useMemo } from 'react';
import { useLocalStorage } from 'usehooks-ts';

import { useCheckedLoginState } from '../providers/LoginProvider.context';

import {
  ExperimentalFeature,
  ExperimentalFeatureId,
  experimentalFeatures,
} from './experimentalFeatures';

export interface ExperimentalFeatureWithState extends ExperimentalFeature {
  enabled: boolean;
}

interface SavedExperimentalFeature {
  id: string;
  enabled: boolean;
}

export function useExperimentalFeatures() {
  const { permissions } = useCheckedLoginState();
  const [savedFeatures, setSavedFeatures] = useLocalStorage<
    SavedExperimentalFeature[]
  >('profid-experimental-features', []);

  const features = useMemo<ExperimentalFeatureWithState[]>(
    () =>
      experimentalFeatures.map((feature) => {
        const savedFeature = savedFeatures.find((f) => f.id === feature.id);
        return {
          ...feature,
          enabled: savedFeature ? savedFeature.enabled : false,
        };
      }),
    [savedFeatures],
  );

  const featureSet = useMemo<Set<ExperimentalFeatureId>>(() => {
    return new Set(
      features
        .filter((feature) =>
          feature.permissions.every((permission) =>
            permissions.includes(permission),
          ),
        )
        .filter((feature) => feature.enabled)
        .map((feature) => feature.id),
    );
  }, [features, permissions]);

  const toggleFeature = useCallback(
    (featureId: string) => {
      setSavedFeatures((savedFeatures) => {
        const savedFeature = savedFeatures.find((f) => f.id === featureId);
        if (savedFeature) {
          return savedFeatures.map((f) =>
            f.id === featureId ? { ...f, enabled: !f.enabled } : f,
          );
        } else {
          return [...savedFeatures, { id: featureId, enabled: true }];
        }
      });
    },
    [setSavedFeatures],
  );

  return {
    features,
    featureSet,
    toggleFeature,
  };
}
