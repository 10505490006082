import { useTranslate } from '@tolgee/react';
import { useMemo } from 'react';

import { safeTranslationKeyDocumentView } from '../../translationsMapping/documents';
import QRCode from '../QRCode';
import FormattedDocumentView from '../translation/FormattedDocumentView';

import { getQrDataExhibit } from './DocumentShowQr.utils';

import { GqlDocumentIdentifierFieldsFragment, DocumentView } from '#gql';

interface DocumentShowQrProps {
  view: DocumentView;
  document: GqlDocumentIdentifierFieldsFragment;
}

export default function DocumentShowQr({
  view,
  document,
}: DocumentShowQrProps) {
  const { t } = useTranslate();

  const filename = useMemo(() => {
    const seqId = document.seqId;
    const translatedView = t(safeTranslationKeyDocumentView(view));

    return `qrcode_${seqId}_${translatedView}`;
  }, [document.seqId, t, view]);

  return (
    <QRCode
      filename={filename}
      level="Q"
      value={getQrDataExhibit(document, view)}
    >
      <div className="text-[1.25em]">{document.seqId}</div>
      <div>
        {document.caseService.identifier} | {document.caseNumber}
      </div>
      <div>{document.caseExhibitNumber}</div>
      <div className="text-[0.8em]">
        <FormattedDocumentView view={view} />
      </div>
    </QRCode>
  );
}
