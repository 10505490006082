import { T } from '@tolgee/react';

import { possibleLocales } from '../../providers/LocaleProvider.context';
import FormattedEmptyState from '../../translation/FormattedEmptyState';
import FormattedErrorAlert from '../../translation/FormattedErrorAlert';
import { FormattedPaginationText } from '../../translation/FormattedPagination';
import { FormattedTable } from '../../translation/FormattedTable';

import AdminSeizureAuthorityRow from './AdminSeizureAuthorityRow';

import { useSeizureAuthoritiesListQuery } from '#gql';
import { Th, useTable } from '#tailwind_ui';

const localeColumnWidth = `${80 / possibleLocales.length}%`;

function renderHeader() {
  return (
    <tr>
      <Th sortField="identifier">
        <T keyName="global.identifier" />
      </Th>
      {possibleLocales.map((locale) => (
        <Th key={locale} style={{ width: localeColumnWidth }}>
          <T keyName="global.name" />
          {` (${locale})`}
        </Th>
      ))}
    </tr>
  );
}

export default function AdminSeizureAuthoritiesTable() {
  const queryResult = useSeizureAuthoritiesListQuery();
  const data = queryResult.data || queryResult.previousData;

  const {
    data: tableData,
    pagination: tablePagination,
    sort: tableSort,
  } = useTable(data?.seizureAuthorities || [], {
    itemsPerPage: 10,
    initialSortDirection: 'ASCENDING',
    initialSortField: 'identifier',
  });

  if (queryResult.error) {
    return <FormattedErrorAlert error={queryResult.error} />;
  }
  if (!data) return null;

  function renderTr(value: (typeof tableData)[0]) {
    return <AdminSeizureAuthorityRow seizureAuthority={value} />;
  }

  return (
    <FormattedTable
      data={tableData}
      renderHeader={renderHeader}
      renderTr={renderTr}
      renderEmpty={renderEmpty}
      sort={tableSort}
      pagination={{
        ...tablePagination,
        totalCount: data?.seizureAuthorities.length,
        withText: true,
        renderText: (actualPage, totalPage, totalCount) => (
          <FormattedPaginationText
            page={actualPage}
            total={totalCount}
            rowsPerPage={tablePagination.itemsPerPage}
            messageId="seizure_authority.list.pagination"
          />
        ),
      }}
    />
  );
}

function renderEmpty() {
  return <FormattedEmptyState title="global.noresults" />;
}
