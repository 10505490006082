import { ReactNode } from 'react';

export function EmptyStateButton(props: {
  icon: ReactNode;
  description: string;
}) {
  return (
    <button
      type="button"
      className="relative block w-full rounded-lg border-2 border-dashed border-neutral-300 p-12 text-center hover:border-neutral-400 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
    >
      <div className="mx-auto h-12 w-12 text-neutral-400">{props.icon}</div>
      <span className="mt-2 block text-sm font-semibold text-neutral-800">
        {props.description}
      </span>
    </button>
  );
}
