import { useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import IfHasPermission from '../permissions/IfHasPermission';
import FormattedErrorAlert from '../translation/FormattedErrorAlert';

import PadifShowMainReference from './PadifShowMainReference';
import PadifShowMainRois from './PadifShowMainRois';

import { GqlPadifAnalysisQuery, usePadifRemoveRoiMutation } from '#gql';

export default function PadifShowMainRoiPage() {
  const padif = useOutletContext<GqlPadifAnalysisQuery['padifAnalysis']>();
  const { id, referenceDocument, rois } = padif;

  const [removePadifRoi] = usePadifRemoveRoiMutation();

  const [addPadifError, setAddPadifError] = useState<Error | null>(null);
  const [removeRoiError, setRemoveRoiError] = useState<Error | null>(null);

  const handleRemoveRoi = (roi: string) => {
    removePadifRoi({
      variables: { input: { id, roi } },
    }).catch(setRemoveRoiError);
  };

  return (
    <>
      <FormattedErrorAlert
        error={addPadifError}
        onDismiss={() => setAddPadifError(null)}
      />

      <FormattedErrorAlert
        error={removeRoiError}
        onDismiss={() => setRemoveRoiError(null)}
      />

      <PadifShowMainReference
        maxHeight="60vh"
        document={referenceDocument}
        rois={rois}
      />

      <IfHasPermission userPermission="padifAnalysis_update">
        <div className="m-auto">
          <PadifShowMainRois rois={rois} onRemoveRoi={handleRemoveRoi} />
        </div>
      </IfHasPermission>
    </>
  );
}
