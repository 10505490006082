import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import { T } from '@tolgee/react';

import { Button } from '#tailwind_ui';

export function DownloadFileButton(props: { href: string }) {
  const url = new URL(props.href);
  url.searchParams.set('download', 'true');
  return (
    <a href={url.href}>
      <Button variant="white">
        <div className="flex items-center gap-2">
          <ArrowDownTrayIcon className="h-5 w-5" />
          <T keyName="global.download" />
        </div>
      </Button>
    </a>
  );
}
