import clsx from 'clsx';
import {
  ButtonHTMLAttributes,
  ElementType,
  ForwardedRef,
  ReactNode,
} from 'react';

import { Color, PropsOf, Roundness, Size, Variant } from '../../types';
import { forwardRefWithAs } from '../../util';
import { Tooltip, WithTooltipProps } from '../popper/Tooltip';

import { getButtonClassName } from './utils';

interface ButtonPropsInternal<T extends ElementType = 'button'>
  extends WithTooltipProps {
  children: ReactNode;
  type?: T extends 'button'
    ? ButtonHTMLAttributes<HTMLButtonElement>['type']
    : 'button' | 'submit' | 'reset';
  color?: Color;
  size?: Size;
  variant?: Variant;
  roundness?: Roundness;
  noBorder?: boolean;
  as?: T;
}

export type ButtonProps<T extends ElementType = 'button'> =
  ButtonPropsInternal<T> & Omit<PropsOf<T>, 'title'>;

export const Button = forwardRefWithAs(function ButtonForwardRef<
  T extends ElementType = 'button',
>(props: ButtonProps<T>, ref: ForwardedRef<HTMLButtonElement>) {
  const {
    color = Color.primary,
    size = Size.medium,
    variant = Variant.primary,
    children,
    roundness = Roundness.light,
    className,
    noBorder = false,

    type = 'button',
    tooltip,
    tooltipDelay,
    tooltipPlacement,

    as: Component = 'button',

    ...otherProps
  } = props;

  return (
    <Tooltip
      delay={tooltipDelay}
      placement={tooltipPlacement}
      content={tooltip}
    >
      <Component
        type={Component === 'button' ? type : undefined}
        {...otherProps}
        className={clsx(
          roundness === Roundness.full || roundness === Roundness.circular
            ? 'rounded-full'
            : 'rounded-md',
          getButtonClassName({
            variant,
            roundness,
            color,
            noBorder,
            size,
            disabled: props.disabled,
            className,
          }),
        )}
        ref={ref}
      >
        {children}
      </Component>
    </Tooltip>
  );
});
