import { Feature } from '../../constants';
import IfHasFeature from '../IfHasFeature';
import { useDocumentContext } from '../providers/DocumentProvider.context';

import DocumentPadifSuggestions from './DocumentPadifSuggestions';
import DocumentSeriesSuggestions from './DocumentSeriesSuggestions';
import DocumentSimilarSerialNumbers from './DocumentSimilarSerialNumbers';

export default function DocumentSuggestions() {
  const { document } = useDocumentContext();
  return (
    <div className="grid grid-cols-2 gap-3">
      <div className="col-span-2 lg:col-span-1">
        <DocumentSimilarSerialNumbers document={document} />
      </div>
      <IfHasFeature feature={Feature.series}>
        <div className="col-span-2 lg:col-span-1">
          <DocumentSeriesSuggestions document={document} />
        </div>
      </IfHasFeature>
      {document.padifAnalyses.length > 0 && (
        <div className="col-span-2">
          <DocumentPadifSuggestions document={document} />
        </div>
      )}
    </div>
  );
}
