import { T } from '@tolgee/react';

import { useNewRoiContext } from '../utils';

import { H1 } from '#ui/heading';

export function PadifInformation() {
  const [{ analysis }] = useNewRoiContext();
  if (!analysis?.referenceDocument) return;

  return (
    <div className="mb-3 flex items-baseline gap-4">
      <div>
        <H1>
          <T keyName="page.padif.title" params={{ id: analysis.id }} />
        </H1>
      </div>
      <T keyName="global.doc_set" />
      {` "${analysis.documentSet.name}"`}
    </div>
  );
}
