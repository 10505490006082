import { TFnType, useTranslate } from '@tolgee/react';
import { useMemo } from 'react';

import { NameValueCallback } from '../../stores/SyncSearchParamsStore';
import SelectList, { SelectItem } from '../SelectList';

import { GqlFacetSearchResult, GqlFacetValue } from '#gql';

export interface FacetFilterSelectListProps<Filter, Prop extends keyof Filter> {
  prop: Prop;
  value?: Filter[Prop] extends Array<infer X> ? X[] : never;
  name: TranslationKey;
  optionLookup?: Record<string, string>;
  displayLookup?: Record<string, string>;
  searchLookup?: Record<string, string>;
  nullOptionLabel?: TranslationKey;
  onAdd: NameValueCallback<Filter>;
  onRemove: NameValueCallback<Filter>;
  facets: GqlFacetSearchResult[];
}

export default function FacetFilterSelectList<
  Filter,
  Prop extends keyof Filter,
>(props: FacetFilterSelectListProps<Filter, Prop>) {
  const {
    prop,
    value = [],
    name,
    optionLookup,
    displayLookup,
    searchLookup,
    facets,
    nullOptionLabel = 'global.unspecified',
    onAdd,
    onRemove,
  } = props;
  const { t } = useTranslate();
  const facet = useMemo(() => {
    return facets.find((f) => f.name === prop);
  }, [facets, prop]);
  if (!facet) {
    throw new Error(`facet ${String(prop)} does not exist`);
  }

  const onAddCallback = onAdd(prop);
  const onRemoveCallback = onRemove(prop);

  const options: Array<SelectItem<string | null>> = facet.values
    .map((facetValue) => {
      const filter = facetValue.filter;
      return {
        value: filter,
        count: facetValue.count,
        ...getLabels(
          facetValue,
          filter,
          t,
          nullOptionLabel,
          optionLookup,
          displayLookup,
          searchLookup,
        ),
      };
    })
    .sort((a, b) => b.count - a.count);

  const selected = options.filter((opt) => value.includes(opt.value as never));

  const translatedName = t(name);

  return (
    <SelectList
      placeholder={translatedName}
      name={name}
      options={options}
      onAdd={(selected) =>
        onAddCallback(selected.value as Filter[keyof Filter])
      }
      onRemove={(option) =>
        onRemoveCallback(option.value as Filter[keyof Filter])
      }
      selected={selected}
    />
  );
}

function getLabels(
  facetValue: GqlFacetValue,
  value: string | null,
  t: TFnType,
  nullOptionLabel: TranslationKey,
  optionLookup?: Record<string, string>,
  displayLookup?: Record<string, string>,
  searchLookup?: Record<string, string>,
) {
  let displayBase;
  const unspecified = t('global.unspecified');
  if (value === null) {
    displayBase = t(nullOptionLabel);
  } else {
    const displayValue = displayLookup?.[value] || unspecified;
    displayBase = displayLookup ? displayValue : value;
  }
  const displayLabel = `${displayBase} (${facetValue.count})`;

  let searchLabel;
  if (value === null) {
    searchLabel = t(nullOptionLabel);
  } else {
    const searchLookupValue = searchLookup?.[value] || unspecified;
    const optionLookupValue = optionLookup?.[value] || unspecified;

    searchLabel = searchLookup
      ? searchLookupValue
      : optionLookup
        ? optionLookupValue
        : value;
  }

  let optionLabel;
  if (value === null) {
    optionLabel = `${t(nullOptionLabel)} (${facetValue.count})`;
  } else {
    optionLabel = `${
      optionLookup ? optionLookup[value] || unspecified : value
    } (${facetValue.count})`;
  }
  return {
    optionLabel,
    displayLabel,
    searchLabel,
  };
}
