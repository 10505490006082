import { endOfDay, startOfDay } from 'date-fns';

import { TableSearchBaseFilters } from '../../components/providers/TableSearchContextProvider';
import { SyncSearchParamsStoreSupportedTypes } from '../SyncSearchParamsStore';
import { SyncSearchParamsWithTermStore } from '../SyncSearchParamsWithTermStore';

import { AuditLogAction } from '#gql';
import { SimpleStringSelectOption } from '#tailwind_ui';

export interface AuditLogFilters extends TableSearchBaseFilters {
  actions: AuditLogAction[];
  userId: string | null;
  userName: string | null;
  startDate: Date | null;
  endDate: Date | null;
}

export class AuditLogFiltersStore extends SyncSearchParamsWithTermStore<AuditLogFilters> {
  public get shapeDef(): Record<
    keyof AuditLogFilters,
    SyncSearchParamsStoreSupportedTypes
  > {
    return {
      searchTerm: 'string',
      actions: 'string[]',
      userId: 'string',
      userName: 'string',
      startDate: 'date',
      endDate: 'date',
    };
  }

  public override get initialData(): AuditLogFilters {
    return {
      searchTerm: '',
      userId: null,
      userName: null,
      actions: [],
      startDate: null,
      endDate: null,
    };
  }

  public setUser = (user: SimpleStringSelectOption | null | undefined) => {
    this.mergeData({
      userId: user?.value ?? null,
      userName: (user?.label as string) ?? null,
    });
  };

  public setStartDate = (startDate: Date | null | undefined) => {
    startDate ??= null;

    if (startDate) {
      startDate = startOfDay(startDate);
    }

    this.mergeData({ startDate });
  };

  public setEndDate = (endDate: Date | null | undefined) => {
    endDate ??= null;

    if (endDate) {
      endDate = endOfDay(endDate);
    }

    this.mergeData({ endDate });
  };

  public setActions = (actions: AuditLogAction[]) => {
    actions ??= [];
    this.mergeData({ actions });
  };
}
