import ky from 'ky';

import { apiUrl } from './constants';

export const apiClient = ky.extend({
  prefixUrl: apiUrl,
  headers: {
    accept: 'application/json',
  },
  credentials: 'include',
  redirect: 'error',
});
