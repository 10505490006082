import { useMemo } from 'react';

import FacetFilterSelectList, {
  FacetFilterSelectListProps,
} from '../../TableSearch/FacetFilterSelectList';

import { useSerieNamesQuery } from '#gql';

type SeriesFacetFilterSelectListProps<Filter, Prop extends keyof Filter> = Omit<
  FacetFilterSelectListProps<Filter, Prop>,
  'optionLookup' | 'displayLookup'
>;

export default function SeriesFacetFilterSelectList<
  Filter,
  Prop extends keyof Filter,
>(props: SeriesFacetFilterSelectListProps<Filter, Prop>) {
  const { error, data } = useSerieNamesQuery();

  const { seriesLookup, seriesDisplay, seriesSearch } = useMemo(() => {
    if (!data?.series) return {};
    const seriesLookup: Record<string, string> = {};
    const seriesDisplay: Record<string, string> = {};
    const seriesSearch: Record<string, string> = {};
    for (const serie of data.series.nodes) {
      seriesSearch[serie.id] = `${serie.seqId} ${serie.name} ${
        serie.aliases?.join(' ') || ''
      }`;
      seriesLookup[serie.id] = `${serie.seqId} | ${serie.name}`;
      seriesDisplay[serie.id] = `${serie.seqId} | ${serie.name}`;
    }
    return {
      seriesLookup,
      seriesDisplay,
      seriesSearch,
    };
  }, [data]);
  if (error) throw error;
  if (!data) return null;

  return (
    <FacetFilterSelectList
      {...props}
      optionLookup={seriesLookup}
      displayLookup={seriesDisplay}
      searchLookup={seriesSearch}
    />
  );
}
