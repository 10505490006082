import { T, useTranslate } from '@tolgee/react';
import clsx from 'clsx';

import ErrorAlert from '../error/ErrorAlert';

import { getFormattedError } from './error.helper';

import { PropsOf, useRootFormError } from '#tailwind_ui';
import { assert } from '#utils/assert';

export default function FormattedFormError(props: {
  className?: string;
  getValues?: <E extends Error>(
    messageId: TranslationKey,
    data: unknown,
    error: E,
  ) => PropsOf<typeof T>['params'];
  onDismiss?: () => void;
}) {
  const { t } = useTranslate();
  const rootError = useRootFormError();

  if (!rootError) {
    return null;
  }
  const { error, data } = rootError;

  const { title, titleId, details, detailsId, detailsValues } =
    getFormattedError(error);

  const alertTitle = titleId
    ? t(titleId, props.getValues?.(titleId, data, error) ?? data)
    : title;

  const alertDetails = detailsId ? t(detailsId, detailsValues) : details;

  assert(alertTitle);

  return (
    <ErrorAlert
      error={error}
      details={alertDetails}
      className={clsx(props.className, 'whitespace-pre-wrap')}
      title={alertTitle}
      onDismiss={props.onDismiss}
    />
  );
}
