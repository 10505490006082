import { useTranslate } from '@tolgee/react';
import { forwardRef, Ref } from 'react';

// eslint-disable-next-line no-restricted-imports
import { Checkbox, CheckboxProps } from '#tailwind_ui';

interface FormattedCheckboxProps extends Omit<CheckboxProps, 'label'> {
  label?: TranslationKey;
  help?: TranslationKey;
}

export default forwardRef<HTMLInputElement, FormattedCheckboxProps>(
  function FormattedCheckbox(
    props: FormattedCheckboxProps,
    ref: Ref<HTMLInputElement>,
  ) {
    const { help, label, ...otherProps } = props;
    const { t } = useTranslate();

    return (
      <Checkbox
        ref={ref}
        label={label && t(label)}
        help={help && t(help)}
        {...otherProps}
      />
    );
  },
);
