import { T } from '@tolgee/react';
import { useCallback } from 'react';
import { useController } from 'react-hook-form';

import FormattedRadio from '../translation/FormattedRadio';

import { useCheckedFormRHFContext } from '#tailwind_ui';

interface TrueFalseUndefinedFieldProps {
  name: string;
  label: TranslationKey;
  trueLabel?: TranslationKey;
  falseLabel?: TranslationKey;
  undefinedLabel?: TranslationKey;
}

export default function TrueFalseUndefinedField({
  trueLabel = 'global.yes',
  falseLabel = 'global.no',
  undefinedLabel = 'global.unspecified',
  name,
  label,
}: TrueFalseUndefinedFieldProps) {
  return (
    <div className="flex flex-row gap-3">
      <p>
        <T keyName={label} />
      </p>
      <InternalRadioFieldRHF label={trueLabel} name={name} value />
      <InternalRadioFieldRHF label={falseLabel} name={name} value={false} />
      <InternalRadioFieldRHF label={undefinedLabel} name={name} value={null} />
    </div>
  );
}

interface InternalRadioFieldRHFProps {
  label: TranslationKey;
  name: string;
  value: null | true | false;
}

function InternalRadioFieldRHF(props: InternalRadioFieldRHFProps) {
  const { label, name, value } = props;

  const { setValue, trigger } = useCheckedFormRHFContext();
  const {
    field,
    formState: { isSubmitted },
  } = useController({
    name,
  });

  const handleChange = useCallback(() => {
    setValue(name, value, {
      shouldTouch: true,
      shouldValidate: isSubmitted,
      shouldDirty: true,
    });

    if (isSubmitted) {
      void trigger();
    }
  }, [setValue, name, value, isSubmitted, trigger]);

  return (
    <FormattedRadio
      ref={field.ref}
      onBlur={field.onBlur}
      label={label}
      name={name}
      id={`${name}-${String(value)}`}
      value=""
      onChange={handleChange}
      checked={field.value === value}
    />
  );
}
