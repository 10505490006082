import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { T, useTranslate } from '@tolgee/react';
import { useCallback, useMemo } from 'react';
import { match } from 'ts-pattern';

import { usePadifContext } from '../../contexts/padif';

import { Select } from '#tailwind_ui';

const ACTIVE_OPTIONS = ['is-active', 'is-disabled'];
export function PadifActiveFilter() {
  const { t } = useTranslate();
  const { filters, store } = usePadifContext();

  const selected = useMemo(
    () =>
      match(filters.active)
        .with(true, () => 'is-active')
        .with(false, () => 'is-disabled')
        .otherwise(() => ''),
    [filters.active],
  );

  const onChange = useCallback(
    (selected: string | undefined) => {
      store.mergeData({
        active: match(selected)
          .with('is-active', () => true)
          .with('is-disabled', () => false)
          .otherwise(() => null),
      });
    },
    [store],
  );

  return (
    <Select<string>
      className="min-w-[16ch]"
      options={ACTIVE_OPTIONS}
      selected={selected}
      renderOption={renderOption}
      renderSelectedOption={renderOption}
      onChange={onChange}
      clearable
      placeholder={t('padif.list.filters.active.label')}
    />
  );
}

function renderOption(option: string) {
  if (!option) return '';

  return (
    <span className="flex items-center gap-2">
      {match(option)
        .with('is-active', () => (
          <>
            <CheckIcon className="h-5 w-5 text-success-600" />
            <T keyName="padif.list.filters.active.true" />
          </>
        ))
        .with('is-disabled', () => (
          <>
            <XMarkIcon className="h-5 w-5 text-danger-600" />
            <T keyName="padif.list.filters.active.false" />
          </>
        ))
        .otherwise(() => null)}
    </span>
  );
}
