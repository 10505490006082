import { PersonalDataType } from '#gql';

export interface PersonalDataReactRoiData {
  fill: string;
  type: PersonalDataType;
}

export interface RGBColor {
  r: number;
  g: number;
  b: number;
}

export interface PersonalDataOption {
  label: Extract<TranslationKey, `document.anonymization.dropdown.${string}`>;
  type: PersonalDataType;
  color: RGBColor;

  /**
   * key code shortcuts
   */
  kbd: string[];
  /**
   * <kbd>{kbdLabel}</kbd>
   */
  kbdLabel: string;
}

export const personalDataTypeOptions: PersonalDataOption[] = [
  {
    label: 'document.anonymization.dropdown.TEXT',
    type: 'text',
    kbd: ['Digit1', 'Numpad1'],
    kbdLabel: '1',
    color: {
      r: 32,
      g: 227,
      b: 70,
    },
  },
  {
    label: 'document.anonymization.dropdown.SIGNATURE',
    type: 'signature',
    kbd: ['Digit2', 'Numpad2'],
    kbdLabel: '2',
    color: {
      r: 40,
      g: 176,
      b: 235,
    },
  },
  {
    label: 'document.anonymization.dropdown.CODE',
    type: 'barcode',
    kbd: ['Digit3', 'Numpad3'],
    kbdLabel: '3',
    color: { r: 209, g: 9, b: 227 },
  },
  {
    label: 'document.anonymization.dropdown.FACE',
    type: 'face',
    kbd: ['Digit4', 'Numpad4'],
    kbdLabel: '4',
    color: {
      r: 97,
      g: 20,
      b: 227,
    },
  },
  {
    label: 'document.anonymization.dropdown.OTHER',
    type: 'other',
    kbd: ['Digit5', 'Numpad5'],
    kbdLabel: '5',
    color: {
      r: 0,
      g: 0,
      b: 0,
    },
  },
];
