import { createContext, Dispatch, useContext, useReducer } from 'react';

import { ActionType, assertUnreachable } from '#tailwind_ui';

type FilesUploadingState = Set<File[]>;

type FilesUploadingAction =
  | ActionType<'ADD', File[]>
  | ActionType<'REMOVE', File[]>;

export type FilesUploadingContext = readonly [
  FilesUploadingState,
  Dispatch<FilesUploadingAction>,
];

export const filesUploadingContext =
  createContext<FilesUploadingContext | null>(null);

export function useFilesUploading() {
  const context = useContext(filesUploadingContext);
  if (context === null) {
    throw new Error(
      'useFilesUploading must be used within a FilesUploadingProvider',
    );
  }
  return context;
}

export function filesUploadingReducer(
  state: FilesUploadingState,
  action: FilesUploadingAction,
) {
  if (action.type === 'ADD') {
    const newState = new Set(state);
    newState.add(action.payload);
    return newState;
  } else if (action.type === 'REMOVE') {
    const newState = new Set(state);
    newState.delete(action.payload);
    return newState;
  } else {
    assertUnreachable(action);
  }
}

export const empty = new Set<File[]>();

export function useFilesUploadingReducer() {
  return useReducer(filesUploadingReducer, empty);
}
