import clsx from 'clsx';
import { KeyboardEvent, useEffect, useState } from 'react';

import { Locale } from '#gql';
import { Td, useOnOff } from '#tailwind_ui';

interface EditableCellProps {
  locale: string;
  hasValue: boolean;
  value: string;
  mutate: (newValue: string, locale: Locale) => void;
}

export default function EditableCell(props: EditableCellProps) {
  const { value, hasValue, mutate, locale } = props;
  const realValue = hasValue ? value : '';

  const [isEditing, setEditing, unsetEditing] = useOnOff(false);
  const [editValue, setEditValue] = useState(realValue);

  useEffect(() => {
    if (!isEditing) {
      setEditValue(realValue);
    }
  }, [realValue, isEditing]);

  function handleKeyDown(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Escape') {
      unsetEditing();
    }
  }

  function handleKeyUp(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      if (editValue !== realValue) {
        mutate(editValue, locale as Locale);
      }
      unsetEditing();
    }
  }

  return isEditing ? (
    <Td>
      <input
        className="w-full rounded p-0.5 outline-none focus:ring focus:ring-neutral-300"
        autoFocus
        value={editValue}
        onBlur={unsetEditing}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        onChange={(event) => setEditValue(event.target.value)}
      />
    </Td>
  ) : (
    <Td>
      <div
        className={clsx('w-full p-0.5', { 'text-neutral-400': !hasValue })}
        onClick={setEditing}
      >
        {value}
      </div>
    </Td>
  );
}
