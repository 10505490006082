import { T, useTranslate } from '@tolgee/react';
import { FormattedNumber } from 'react-intl';

import { DocStatsData, SeriesStatsData } from './stats/get_stats';
import LastSeries from './stats/last_series';
import LastSeriesDocuments from './stats/last_series_documents';
import Pies from './stats/pies';
import ServiceStats from './stats/service_stats';
import TopCountries from './stats/top_countries';

import { H4 } from '#ui/heading';

export interface HomeStatsProps {
  documentStats: DocStatsData;
  seriesStats: SeriesStatsData;
}

export default function HomeStats(props: HomeStatsProps) {
  const { documentStats, seriesStats } = props;

  return (
    <div className="pt-8">
      <DocumentStats stats={documentStats} />

      <SeriesStats documentStats={documentStats} seriesStats={seriesStats} />
    </div>
  );
}

interface DocumentStatsProps {
  stats: DocStatsData;
}

function DocumentStats({ stats }: DocumentStatsProps) {
  if (stats.total === 0) return null;

  return (
    <>
      <ServiceStats stats={stats} />
      <div className="flex flex-row justify-center">
        <div className="mr-24 w-[400px] text-center">
          <Pies stats={stats} />
        </div>
        <div className="flex flex-col justify-center">
          {stats.docCountries.length > 0 && (
            <TopCountries
              title="doc.field.country"
              searchKey="docCountry"
              countries={stats.docCountries}
            />
          )}
          {stats.presumedNationalities.length > 0 && (
            <TopCountries
              title="doc.field.presumed_nationality"
              searchKey="casePresumedNationality"
              countries={stats.presumedNationalities}
            />
          )}
        </div>
      </div>
    </>
  );
}

interface SeriesStatsProps {
  documentStats: DocStatsData;
  seriesStats: SeriesStatsData;
}

function SeriesStats(props: SeriesStatsProps) {
  const { t } = useTranslate();
  const { documentStats, seriesStats } = props;

  if (seriesStats.total === 0) return null;

  return (
    <>
      <div className="mt-5 flex flex-wrap justify-center space-x-1">
        <H4>
          <T
            keyName="home.stats.series.count"
            params={{ total: seriesStats.total }}
          />
        </H4>
        <H4>
          {t('home.stats.docs.has_serie.count', {
            count: documentStats.countInSerie,
            percent: (
              <FormattedNumber
                key="percent"
                value={documentStats.percentInSerie / 100}
                style="percent"
                maximumFractionDigits={2}
              />
            ),
          })}
        </H4>
      </div>
      <div className="mt-3 flex flex-wrap justify-center">
        <div className="mx-4 rounded-md border border-neutral-300 p-2">
          <LastSeries series={seriesStats.lastSeries} />
        </div>
        <div className="mx-4 rounded-md border border-neutral-300 p-2">
          <LastSeriesDocuments series={seriesStats.lastSeriesDocuments} />
        </div>
      </div>
    </>
  );
}
