import { TableSearchContextProvider } from '../components/providers/TableSearchContextProvider';
import { seriesContext, SeriesSupportedSortField } from '../contexts/series';
import { MandatoryChildren } from '../global';
import { UsePaginationTableInitial } from '../hooks/usePaginationTable';
import { UseSortTableInitial } from '../hooks/useSortTable';
import {
  SeriesFilters,
  SeriesFiltersStore,
} from '../stores/searches/SeriesFiltersStore';

const initialSort = {
  field: 'importantFieldsUpdatedAt',
  direction: 'DESC',
} as const satisfies UseSortTableInitial<SeriesSupportedSortField>;
const initialPagination = {
  label: 'page.series.filter_info',
} as const satisfies Partial<UsePaginationTableInitial>;

export function SeriesTableSearchProvider({ children }: MandatoryChildren) {
  return (
    <TableSearchContextProvider<
      SeriesSupportedSortField,
      SeriesFilters,
      typeof SeriesFiltersStore,
      SeriesFiltersStore
    >
      context={seriesContext}
      Store={SeriesFiltersStore}
      initialSort={initialSort}
      initialPagination={initialPagination}
    >
      {children}
    </TableSearchContextProvider>
  );
}
