import { useTranslate } from '@tolgee/react';

import HalfFullCircle from '../../icons/HalfFullCircleIcon';
import FormattedConfirmDialog from '../../translation/FormattedConfirmDialog';

import { useCreateAlertDraftMutation } from '#gql';
import { useNotificationCenter } from '#tailwind_ui';
import { assert } from '#utils/assert';

interface CreateAlertDraftModalProps {
  isOpen: boolean;
  id: string;
  onClose: (draftId?: string) => void;
}

export default function CreateAlertDraftModal(
  props: CreateAlertDraftModalProps,
) {
  const { isOpen, id, onClose } = props;
  const [createDraft] = useCreateAlertDraftMutation();
  const notification = useNotificationCenter();
  const { t } = useTranslate();

  async function onConfirm() {
    try {
      const data = await createDraft({
        variables: {
          id,
        },
      });

      assert(data.data?.createAlertDraft.draft?.id);
      onClose(data.data?.createAlertDraft.draft.id);
      notification.addNotification({
        type: 'success',
        title: t('series.alert.create_draft.notification.success.title'),
        content: t('series.alert.create_draft.notification.success.body'),
      });
    } catch (error) {
      onClose();
      notification.addNotification({
        type: 'danger',
        title: t('series.alert.create_draft.notification.error.title'),
        content: error.message,
      });
    }
  }

  return (
    <FormattedConfirmDialog
      open={isOpen}
      icon={<HalfFullCircle />}
      confirmText="series.alert.create_draft.confirm.button"
      title="series.alert.create_draft.confirm.title"
      onCancel={() => onClose()}
      onConfirm={() => void onConfirm()}
      body="series.alert.create_draft.confirm.body"
    />
  );
}
