import { useAppMeta } from '../../components/providers/AppMetaProvider.context';
import { MandatoryChildren } from '../../global';

import { FormattedAlert } from '#intl';
import { NotificationCenter, ToastNotificationCenter } from '#tailwind_ui';

export function MainWrapper({ children }: MandatoryChildren) {
  return (
    <div className="mx-auto w-full max-w-screen-2xl grow sm:px-3 2xl:px-6 sm:print:px-0 2xl:print:px-0">
      <div className="flex h-full flex-col pt-2 print:pt-0">
        <EnvWarning />
        {children}
        <NotificationCenter position="bottom-right" />
        <ToastNotificationCenter position="top" />
      </div>
    </div>
  );
}

function EnvWarning() {
  const appMeta = useAppMeta();

  if (appMeta.env !== 'test') {
    return null;
  } else {
    return (
      <FormattedAlert
        type="warning"
        className="my-3"
        messageId="global.test_env"
      />
    );
  }
}
