import { FormatDateOptions, FormattedRelativeTime, useIntl } from 'react-intl';

const dateFormatOptions: FormatDateOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

const ONE_MINUTE = 60;
const ONE_HOUR = 60 * ONE_MINUTE;
const ONE_DAY = 24 * ONE_HOUR;
const ONE_WEEK = 7 * ONE_DAY;
const ONE_MONTH = 30 * ONE_DAY;
const ONE_YEAR = 365 * ONE_DAY;

export default function FormattedRelativeDate(props: {
  value: Date | number | string;
}) {
  const intl = useIntl();
  const from = new Date(props.value);
  const to = Date.now();
  let time = (from.getTime() - to) / 1_000;
  let unit: 'month' | 'year' | 'week' | undefined;

  if (time < -ONE_YEAR) {
    time = Math.round(time / ONE_YEAR);
    unit = 'year';
  } else if (time < -ONE_MONTH) {
    time = Math.round(time / ONE_MONTH);
    unit = 'month';
  } else if (time < -ONE_WEEK) {
    time = Math.round(time / ONE_WEEK);
    unit = 'week';
  } else {
    time = Math.min(time, -1);
  }

  return (
    <span title={intl.formatTime(from, dateFormatOptions)}>
      <FormattedRelativeTime
        value={time}
        unit={unit}
        numeric="always"
        // react-intl throws an error if an interval is passed for units > day
        updateIntervalInSeconds={unit ? undefined : 60}
      />
    </span>
  );
}
