import { T, TranslationKey, useTranslate } from '@tolgee/react';
import { ReactNode } from 'react';
import { FormattedDate } from 'react-intl';

import { useScanIdParam } from '../../hooks/useParams';
import { safeTranslationKeyScanStatus } from '../../translationsMapping/scans';
import { DocumentImageLink } from '../document/DocumentLink';
import { MailToUser } from '../user/MailToUser';

import { FullScanImageCard } from './FullScanImageCard';

import { GqlScanQuery, useScanQuery } from '#gql';
import { CardLayout } from '#ui/card_layout';
import { FormattedDefinitionList } from '#ui/definition_list';
import { PageLayout, PageLayoutNavigation } from '#ui/page_layout';

export function ScanView() {
  const scanId = useScanIdParam();
  const { data, error } = useScanQuery({
    pollInterval: 5000,
    variables: { id: scanId },
  });

  if (error) throw error;
  if (!data?.scan) return null;

  const { scan } = data;
  const imageId = scan.document?.images.find(
    (image) => image.originalScan.id === scanId,
  )?.id;

  return (
    <PageLayout
      title={scan.filename}
      navigation={
        <PageLayoutNavigation to="/scans">
          <T keyName="nav.scans.list" />
        </PageLayoutNavigation>
      }
      actions={
        scan.document &&
        imageId && (
          <DocumentImageLink
            document={scan.document}
            multiline
            includeService
            imageId={imageId}
          />
        )
      }
      bodyClassName="flex flex-col gap-5"
      noDefaultBodyClassName
    >
      <div className="grid grid-cols-2 gap-x-4 gap-y-2">
        <GeneralInformationCard scan={scan} />
        <FullScanImageCard image={scan.image} title="scan.full" />
      </div>
    </PageLayout>
  );
}

interface GeneralInformationCardProps {
  scan: GqlScanQuery['scan'];
}

function GeneralInformationCard({ scan }: GeneralInformationCardProps) {
  const { t } = useTranslate();
  const subject = `[${t('scan.title').trim()}] ${scan.filename}`;
  const body = window.location.href;

  const data: Partial<Record<TranslationKey, ReactNode>> = {
    'global.status': <T keyName={safeTranslationKeyScanStatus(scan.status)} />,
    'scans.list.creation_date': (
      <FormattedDate value={scan.createdAt} dateStyle="short" />
    ),
    'scans.list.uploader': (
      <MailToUser user={scan.createdBy} subject={subject} body={body} />
    ),
    'global.updated_by': (
      <MailToUser user={scan.updatedBy} subject={subject} body={body} />
    ),
  };

  if (scan.error) data['global.error'] = scan.error;

  return (
    <CardLayout title="global.view.general_information">
      <FormattedDefinitionList data={data} />
    </CardLayout>
  );
}
