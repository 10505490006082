import { HomeIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { ReactNode, useRef } from 'react';
import { Link } from 'react-router-dom';

import { MandatoryChildren, MandatoryReactNode } from '../../global';
import { useSyncDocumentTitle } from '../../hooks/useSyncDocumentTitle';

import {
  PageLayoutBreadcrumb,
  PageLayoutBreadcrumbIcon,
  PageLayoutBreadcrumbItem,
} from './page_layout_breadcrumb';

import { H1 } from '#ui/heading';

export interface PageLayoutProps extends MandatoryChildren {
  /**
   * bound into an `H2` component
   */
  title: MandatoryReactNode;
  titleClassName?: string;
  headerClassName?: string;

  /**
   * should be PageLayoutNavigation components
   */
  navigation?: ReactNode;
  actions?: ReactNode;
  actionsClassName?: string;

  bodyClassName?: string;
  noDefaultBodyClassName?: boolean;

  footer?: ReactNode;
  footerClassName?: string;
}

export function PageLayout(props: PageLayoutProps) {
  const refDocumentTitle = useRef<HTMLHeadingElement>(null);
  useSyncDocumentTitle(refDocumentTitle);

  return (
    <section className="space-y-2">
      <header
        className={clsx(
          'flex flex-wrap justify-between gap-2 print:hidden',
          props.headerClassName,
        )}
      >
        <PageLayoutBreadcrumb>
          <PageLayoutBreadcrumbIcon>
            <Link to="/">
              <HomeIcon className="h-5 w-5" />
            </Link>
          </PageLayoutBreadcrumbIcon>

          {props.navigation}

          <PageLayoutBreadcrumbItem noDefaultTextColor>
            <H1
              className={clsx(
                '!mb-0 flex items-center gap-2 !text-xl',
                props.titleClassName,
              )}
              ref={refDocumentTitle}
            >
              {props.title}
            </H1>
          </PageLayoutBreadcrumbItem>
        </PageLayoutBreadcrumb>

        <aside
          className={clsx(
            'flex h-full items-center gap-2',
            props.actionsClassName,
          )}
        >
          {props.actions}
        </aside>
      </header>

      <main
        className={clsx(
          !props.noDefaultBodyClassName && 'space-y-2',
          props.bodyClassName,
        )}
      >
        {props.children}
      </main>

      {props.footer && (
        <footer className={props.footerClassName}>{props.footer}</footer>
      )}
    </section>
  );
}
