import { useCallback, useState } from 'react';

import { usePadifDocumentSetQuery, usePadifSetReferenceMutation } from '#gql';

export function useChangePadifReference(padifId: string, rowsPerPage: number) {
  const [page, handlePageChange] = useState(0);
  const [selectedReference, setSelectedReference] = useState<null | string>(
    null,
  );
  const query = usePadifDocumentSetQuery({
    variables: {
      id: padifId,
      limit: rowsPerPage,
      skip: rowsPerPage * page,
    },
  });

  const [setReference, setReferenceResult] = usePadifSetReferenceMutation();

  const setReferenceMutation = useCallback(() => {
    return setReference({
      variables: {
        input: {
          id: padifId,
          document: selectedReference,
        },
      },
    });
  }, [padifId, selectedReference, setReference]);

  const removeReferenceMutation = useCallback(() => {
    return setReference({
      variables: {
        input: {
          id: padifId,
          document: null,
        },
      },
    });
  }, [padifId, setReference]);

  return {
    setReferenceMutation,
    removeReferenceMutation,
    page,
    handlePageChange,
    setSelectedReference,
    selectedReference,
    query,
    rowsPerPage,
    mutationError: setReferenceResult.error,
  };
}
