import { T } from '@tolgee/react';
import { useNavigate } from 'react-router-dom';

import {
  DraftOrPublicationAlert,
  getLanguages,
  getTranslation,
} from '../utils/draftOrPublication';
import { getLanguageKey } from '../utils/languages';

import { Language } from '#gql';
import { Select } from '#tailwind_ui';
import { assert } from '#utils/assert';

export function AlertLanguageSelect(props: { alert: DraftOrPublicationAlert }) {
  const options = getLanguages(props.alert);
  const translation = getTranslation(props.alert);

  return (
    <LanguageSelect
      languages={options}
      selectedLanguage={translation.language}
    />
  );
}

export function LanguageSelect(props: {
  languages: Language[];
  selectedLanguage: Language;
}) {
  const navigate = useNavigate();

  return (
    <Select<string>
      className="w-32"
      label="Language"
      hiddenLabel
      options={props.languages}
      renderOption={(option) => (
        <T keyName={getLanguageKey(option as Language)} />
      )}
      onChange={(language) => {
        assert(language);
        navigate(`./?language=${language}`);
      }}
      selected={props.selectedLanguage}
    />
  );
}
