import { T } from '@tolgee/react';
import { Image, writeCanvas } from 'image-js';
import { RefObject, useEffect, useRef } from 'react';
import { useCommittedRois } from 'react-roi';

import { CropJobData, getImageData, WorkerResponse } from './utils/worker';

import {
  Button,
  useOnOff,
  zoomImageInOverlayStyling,
  ZoomOverlay,
  ZoomOverlayRef,
} from '#tailwind_ui';
import { assert } from '#utils/assert';

interface CroppedImagePreviewButtonProps {
  imageRef: RefObject<HTMLImageElement>;
}

const worker = new Worker(new URL('crop-scan.worker.ts', import.meta.url), {
  type: 'module',
});

export function CroppedImagePreviewButton(
  props: CroppedImagePreviewButtonProps,
) {
  const zoomRef = useRef<ZoomOverlayRef>(null);

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [roi] = useCommittedRois();
  assert(roi);
  const [isCropping, startCropping, endCropping] = useOnOff();
  useEffect(() => {
    function onMessage(event: MessageEvent<WorkerResponse>) {
      endCropping();
      const { data } = event;
      if (data.type === 'error') {
        // eslint-disable-next-line no-console
        console.error(data.error);
      } else {
        const image = new Image(data.data.width, data.data.height, {
          colorModel: data.data.colorModel,
          bitDepth: data.data.bitDepth,
          data: new Uint8ClampedArray(
            data.data.buffer,
            0,
            data.data.byteLength,
          ),
        });
        if (canvasRef.current) {
          writeCanvas(image, canvasRef.current);
          zoomRef.current?.open();
        }
      }
    }
    worker.addEventListener('message', onMessage);
    return () => {
      worker.removeEventListener('message', onMessage);
    };
  }, [endCropping]);

  return (
    <>
      <Button
        variant="white"
        disabled={isCropping}
        onClick={() => {
          startCropping();

          const image = props.imageRef.current;
          if (image) {
            // Loading the image data blocks the main thread for a little bit,
            // but we want to show the loading state immediately
            setTimeout(() => {
              const imageData = getImageData(image);
              const cropData: CropJobData = {
                image: imageData,
                region: roi,
              };

              worker.postMessage(cropData, [imageData.buffer]);
            }, 0);
          }
        }}
      >
        <T
          keyName={
            isCropping
              ? 'cropped.image.loading_preview'
              : 'cropped.image.preview'
          }
        />
      </Button>

      <ZoomOverlay ref={zoomRef}>
        <canvas ref={canvasRef} className={zoomImageInOverlayStyling()} />
      </ZoomOverlay>
    </>
  );
}
