import { T, useTranslate } from '@tolgee/react';
import { useRef, useState } from 'react';
import { FormattedDate, FormattedList } from 'react-intl';

import { useDocumentDocumentSetContext } from '../../contexts/documentDocumentSet';
import {
  UseTableSelect,
  useTableSelection,
} from '../../hooks/useTableSelection';
import DateRangePicker from '../DateRangePicker';
import { docSetsRenderEmpty } from '../DocumentSet/DocumentSetsIndexPage';
import ImgLink from '../ImgLink';
import { TableSearchLayout } from '../TableSearch/TableSearchLayout';
import {
  TableSelection,
  TableSelectionRenderHeaderProps,
  TableSelectionRenderTrProps,
} from '../TableSelection';
import FormattedConfirmDialog from '../translation/FormattedConfirmDialog';
import FormattedEmptyState from '../translation/FormattedEmptyState';
import FormattedErrorAlert from '../translation/FormattedErrorAlert';
import { FormattedPaginationText } from '../translation/FormattedPagination';

import {
  GqlDocumentDocumentSetCandidatesQuery,
  useAddDocumentsToSetMutation,
  useDocumentDocumentSetCandidatesQuery,
} from '#gql';
import {
  ConfirmDialogProps,
  SimpleListContainer,
  Spinner,
  Td,
  Th,
  useNotificationCenter,
} from '#tailwind_ui';
import { HighlightedAnchor } from '#ui/link';

export interface DocumentAddToDocSetModalProps {
  id: string;
  isOpen: boolean;
  onRequestClose: () => void;
}

const confirmDialogBodyProps = {
  className: 'min-h-[80vh] min-w-[80vw]',
} satisfies ConfirmDialogProps['bodyProps'];
export function DocumentAddToDocSetModal(props: DocumentAddToDocSetModalProps) {
  const { id, isOpen, onRequestClose } = props;
  const { t } = useTranslate();
  const context = useDocumentDocumentSetContext();
  const { filters, pagination, sort } = context;
  const { addToastNotification } = useNotificationCenter();
  const selection = useTableSelection<string>();
  const [errors, setErrors] = useState<Error[]>([]);

  const docSetCache = useRef(new Map<string, DocSet>());
  const result = useDocumentDocumentSetCandidatesQuery({
    variables: {
      id,
      ...pagination,
      filters,
      sort,
    },
    onCompleted: (data) => {
      const cache = docSetCache.current;
      for (const docSet of data.document.docSetCandidates.nodes) {
        cache.set(docSet.id, docSet);
      }
    },
  });
  const [addDocumentsToSetMutation] = useAddDocumentsToSetMutation();

  if (result.error) throw result.error;
  const data = result.data || result.previousData;

  function onConfirm() {
    if (selection.value.size === 0) return;

    void Promise.allSettled(
      Array.from(selection.value).map((docSetId) =>
        addDocumentsToSetMutation({
          variables: {
            input: {
              id: docSetId,
              documents: [id],
            },
          },
        }),
      ),
    ).then((values) => {
      const errors = [];
      for (const result of values) {
        if (result.status === 'rejected') {
          errors.push(result.reason);
        }
      }

      if (errors.length < selection.value.size) {
        const cache = docSetCache.current;
        const sets = Array.from(
          selection.value,
          (id) => cache.get(id)?.name ?? id,
        );

        addToastNotification({
          label: t({
            key: 'document.view.modal.add_to_set.notification.success',
            params: {
              count: sets.length,
              sets: <FormattedList value={sets} style="short" />,
            },
          }),
        });
      }

      setErrors(errors);
      void result.refetch();
      if (errors.length > 0) return;

      selection.methods.clear();
      onRequestClose();
    });
  }

  return (
    <FormattedConfirmDialog
      open={isOpen}
      title="document.view.modal.add_to_set.title"
      onCancel={onRequestClose}
      onConfirm={onConfirm}
      confirmText="document.view.modal.add_to_set.button"
      confirmTextValues={{ count: selection.value.size }}
      noCloseConfirm
      size="full"
      bodyProps={confirmDialogBodyProps}
      body={
        <>
          {errors.map((error, i) => (
            <FormattedErrorAlert
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              error={error}
              onDismiss={() =>
                setErrors((errors) => errors.filter((e) => error !== e))
              }
            />
          ))}

          <Body data={data} selection={selection} />
        </>
      }
      confirmProps={{
        disabled: selection.value.size === 0,
      }}
    />
  );
}

interface BodyProps {
  data: GqlDocumentDocumentSetCandidatesQuery | undefined;
  selection: UseTableSelect<string>;
}
function Body(props: BodyProps) {
  const { data, selection } = props;
  const context = useDocumentDocumentSetContext();

  if (!data) {
    return (
      <FormattedEmptyState title="scan.status.PENDING">
        <div className="flex items-center justify-center p-52">
          <Spinner className="h-32 w-32" />
        </div>
      </FormattedEmptyState>
    );
  }

  const { totalCount, nodes: candidates } = data.document.docSetCandidates;

  return (
    <TableSearchLayout context={context} advancedFilter={<AdvancedFilter />}>
      <TableSelection
        data={candidates}
        selection={selection}
        renderHeader={renderHeader}
        renderTr={renderTr}
        renderEmpty={docSetsRenderEmpty}
        pagination={{
          ...context.tablePagination,
          totalCount,
          renderText: (actual) => (
            <FormattedPaginationText
              messageId="doc_set.list.document_sets.filter_info"
              page={actual}
              total={totalCount}
              rowsPerPage={context.tablePagination.itemsPerPage}
            />
          ),
        }}
        sort={context.tableSort}
      />
    </TableSearchLayout>
  );
}

function AdvancedFilter() {
  const context = useDocumentDocumentSetContext();

  return (
    <SimpleListContainer>
      <SimpleListContainer.Item className="space-y-2">
        <DateRangePicker
          startPlaceholderId="doc_set.list.documents.search.added_at_start"
          endPlaceholderId="doc_set.list.documents.search.added_at_end"
          start={context.filters.startDate ?? undefined}
          end={context.filters.endDate ?? undefined}
          onStartChange={context.store.setStartDate}
          onEndChange={context.store.setEndDate}
        />
      </SimpleListContainer.Item>
    </SimpleListContainer>
  );
}

type DocSet =
  GqlDocumentDocumentSetCandidatesQuery['document']['docSetCandidates']['nodes'][number];
function renderHeader(props: TableSelectionRenderHeaderProps<DocSet, string>) {
  return (
    <tr>
      {props.checkboxTh}
      <Th className="w-[200px]">
        <T keyName="global.image" />
      </Th>
      <Th sortField="name">
        <T keyName="global.name" />
      </Th>
      <Th>
        <T keyName="global.title.nb_doc" />
      </Th>
      <Th sortField="createdAt">
        <T keyName="doc.table.created_at" />
      </Th>
    </tr>
  );
}

function renderTr(props: TableSelectionRenderTrProps<DocSet, string>) {
  const documentSet = props.value;
  const first = documentSet.documents.nodes[0];
  const image = first?.images[0];

  return (
    <tr className="even:bg-neutral-50">
      {props.checkboxTd}

      <Td>
        {image && (
          <ImgLink
            src={image.document?.url || ''}
            thumbSrc={image.documentThumb?.url || ''}
            imgStyle={{
              borderRadius: '0.4rem',
              maxWidth: 200,
              maxHeight: 100,
            }}
          />
        )}
      </Td>
      <Td>
        <HighlightedAnchor
          href={`/document-sets/${documentSet.id}`}
          target="_blank"
        >
          {documentSet.name}
        </HighlightedAnchor>
      </Td>
      <Td>{documentSet.documents.totalCount}</Td>
      <Td>
        <FormattedDate value={documentSet.createdAt} />
      </Td>
    </tr>
  );
}
