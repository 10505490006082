import { useWatch } from 'react-hook-form';

import FormattedCheckboxField from '../translation/FormattedCheckboxField';
import FormattedInputField from '../translation/FormattedInputField';

export default function PurchaseURLFields() {
  const isPurchasedOnInternet = useWatch({ name: 'docIsPurchasedOnInternet' });

  return (
    <div>
      <FormattedCheckboxField
        name="docIsPurchasedOnInternet"
        label="doc.field.is_purchased_on_internet"
      />
      {isPurchasedOnInternet && (
        <FormattedInputField
          name="docInternetPurchaseURL"
          label="doc.field.internet_purchase_url"
        />
      )}
    </div>
  );
}
