import { useHasPermission } from '../../../hooks/permissions';
import { useTolgeeCacheLoad } from '../../../hooks/useTolgeeCacheLoad';
import AlertDocument from '../AlertDocument';
import AlertDocumentInfo from '../AlertDocumentInfo';
import AlertBreadcrumbs, {
  AlertBreadcrumbsTitle,
} from '../header/AlertBreadcrumbs';
import { AlertLanguageSelect } from '../header/AlertLanguageSelect';
import AlertDraftActions from '../header/draft/AlertDraftActions';
import AlertDraftWarning from '../header/draft/AlertDraftWarning';
import { useAlertDraftParams } from '../hooks/useAlertParams';

import { useAlertDraftQuery } from '#gql';
import { FormattedAlert } from '#intl';
import { PageLayout } from '#ui/page_layout';

export default function ViewAlertDraft() {
  const { id, language } = useAlertDraftParams();
  const canUpdateAlerts = useHasPermission('alert_update');

  const { data, error } = useAlertDraftQuery({
    variables: {
      id,
      language,
    },
  });

  useTolgeeCacheLoad(
    data?.alert.draft?.translation.language ?? language,
    'alert',
  );

  if (error) {
    throw error;
  }

  if (!data) {
    return null;
  }

  if (!canUpdateAlerts) {
    return (
      <FormattedAlert type="warning" title="series.alert.draft.no_permission" />
    );
  }
  if (!data.alert.draft) {
    return <FormattedAlert type="error" title="series.alert.draft.notfound" />;
  }

  const { alert } = data;

  return (
    <PageLayout
      title={<AlertBreadcrumbsTitle alert={alert} />}
      navigation={<AlertBreadcrumbs alert={alert} />}
      actions={
        <>
          <AlertDraftActions alert={alert} seriesSeqId={alert.serie.seqId} />
          <AlertLanguageSelect alert={alert} />
        </>
      }
    >
      <AlertDraftWarning alert={alert} />
      <AlertDocumentInfo alert={alert} />

      <AlertDocument alert={alert} />
    </PageLayout>
  );
}
