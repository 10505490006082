import { useApolloClient } from '@apollo/client';
import { T } from '@tolgee/react';
import { useLocation, useNavigate } from 'react-router-dom';

import { apiClient } from '../../api';
import { requiredObject, requiredString } from '../../forms/validation';
import NarrowContainer from '../NarrowContainer';
import { useLoginState, useLogout } from '../providers/LoginProvider.context';
import FormattedFormError from '../translation/FormattedFormError';
import FormattedInputField from '../translation/FormattedInputField';
import FormattedSubmitButton from '../translation/FormattedSubmitButton';

import { Color, FormRHF, useNotificationCenter } from '#tailwind_ui';
import { H3 } from '#ui/heading';
import { ApiError, ApiErrorData } from '#utils/error/api';

interface ActivateValues {
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
}

const minLengthPassword = 'error.password.length.min' satisfies TranslationKey;
const maxLengthPassword = 'error.password.length.max' satisfies TranslationKey;

const passwordRule = requiredString
  .min(8, minLengthPassword)
  .max(64, maxLengthPassword);

const validationSchema = requiredObject({
  firstName: requiredString,
  lastName: requiredString,
  password: passwordRule,
  confirmPassword: passwordRule,
});

interface ActivateParams {
  token: string;
  email: string;
}

function useActivateParams(): Readonly<ActivateParams> {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.hash.slice(1));
  const email = searchParams.get('email');
  const token = searchParams.get('token');
  if (!token || !email) {
    throw new Error('Missing activation parameters');
  }
  return { token, email };
}

export default function Activate() {
  const { token, email } = useActivateParams();
  const login = useLoginState();
  const apollo = useApolloClient();
  const logout = useLogout();
  const navigate = useNavigate();
  const { addNotification } = useNotificationCenter();
  return (
    <NarrowContainer>
      <div className="space-y-3">
        <H3>
          <T keyName="user.activate.title" params={{ email }} />
        </H3>
        <FormRHF<ActivateValues>
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            const res = await apiClient.post('activate', {
              throwHttpErrors: false,
              json: {
                ...values,
                token,
              },
            });
            if (res.status !== 200) {
              const body = await res.json<ApiErrorData>();
              throw new ApiError(body);
            }

            if (login.status === 'authenticated') {
              logout(apollo);
            }

            addNotification(
              {
                type: Color.success,
                title: <T keyName="account.notif.validated" />,
              },
              10000,
            );
            navigate(`/?defaultEmail=${email}`);
          }}
        >
          <FormattedInputField
            type="text"
            name="firstName"
            label="global.first_name"
            autoComplete="given-name"
            autoFocus
          />
          <FormattedInputField
            type="text"
            name="lastName"
            label="global.last_name"
            autoComplete="family-name"
          />
          <FormattedInputField
            type="password"
            name="password"
            label="user.activate.field.password"
            autoComplete="new-password"
          />
          <FormattedInputField
            type="password"
            name="confirmPassword"
            label="user.activate.field.repeat_password"
            autoComplete="new-password"
          />
          <FormattedFormError />
          <FormattedSubmitButton color="primary" messageId="global.send" />
        </FormRHF>
      </div>
    </NarrowContainer>
  );
}
