import type { LocalizedCountryNames } from 'i18n-iso-countries';
import i18nCountries from 'i18n-iso-countries';

import { Language } from '#gql';

const { getNames, getAlpha2Codes, registerLocale } = i18nCountries;

type OfficialCountryNames = LocalizedCountryNames<{ select: 'official' }>;
export type CountryCodeAndName = [code: string, name: string];

const countryMap: Record<string, Record<string, string>> = {};
const countryList: { [key in Language]?: CountryCodeAndName[] } = {};

export async function getCountryMap(locale: Language) {
  if (!countryMap[locale]) {
    switch (locale) {
      case 'en': {
        const i18n = await import('i18n-iso-countries/langs/en.json');
        registerLocale(i18n);
        const alpha2Names = getNames('en');
        const alpha3Names = toAlpha3(alpha2Names);
        alpha3Names.XXX = 'Unknown nationality';
        countryMap.en = alpha3Names;
        break;
      }
      case 'fr': {
        const i18n = await import('i18n-iso-countries/langs/fr.json');
        registerLocale(i18n);
        const alpha2Names = getNames('fr');
        const alpha3Names = toAlpha3(alpha2Names);
        alpha3Names.XXX = 'Nationalité inconnue';
        countryMap.fr = alpha3Names;
        break;
      }
      case 'de': {
        const i18n = await import('i18n-iso-countries/langs/de.json');
        registerLocale(i18n);
        const alpha2Names = getNames('de');
        const alpha3Names = toAlpha3(alpha2Names);
        alpha3Names.XXX = 'Unbekannte Nationalität';
        countryMap.de = alpha3Names;
        break;
      }
      case 'nl': {
        const i18n = await import('i18n-iso-countries/langs/nl.json');
        registerLocale(i18n);
        const alpha2Names = getNames('nl');
        const alpha3Names = toAlpha3(alpha2Names);
        alpha3Names.XXX = 'Onbekende nationaliteit';
        countryMap.nl = alpha3Names;
        break;
      }
      case 'it': {
        const i18n = await import('i18n-iso-countries/langs/it.json');
        registerLocale(i18n);
        const alpha2Names = getNames('it');
        const alpha3Names = toAlpha3(alpha2Names);
        alpha3Names.XXX = 'Nazionalità sconosciuta';
        countryMap.it = alpha3Names;
        break;
      }
      default: {
        throw new Error('unknown locale');
      }
    }
  }
  return countryMap[locale];
}

export async function getCountryList(language: Language) {
  const list = countryList[language];
  if (!list) {
    const map = await getCountryMap(language);
    const newList = toList(map);
    countryList[language] = newList;
    return newList;
  }
  return list;
}

function toList(countries: Record<string, string>) {
  return Object.keys(countries).map(
    (code): CountryCodeAndName => [code, countries[code]],
  );
}

function toAlpha3(alpha2Names: OfficialCountryNames) {
  const codes = getAlpha2Codes();
  const map: Record<string, string> = {};
  for (const alpha2 of Object.keys(alpha2Names)) {
    map[codes[alpha2]] = alpha2Names[alpha2];
  }
  return map;
}
