export type NullStr = 'null';
export type BoolStr = 'true' | 'false';

export function safeMappedTranslationKey<
  Map extends Record<string, TranslationKey>,
>(
  translationMap: Map,
  index:
    | keyof Map
    | (keyof Map extends BoolStr ? boolean : never)
    | null
    | undefined,
): TranslationKey {
  if (typeof index === 'boolean') {
    return translationMap[index ? 'true' : 'false'];
  }

  if (index === null || index === undefined) {
    if ('null' in translationMap) return translationMap.null;
    return 'global.unspecified';
  }

  return translationMap[index];
}
