import { hierarchy, HierarchyPointNode } from 'd3-hierarchy';
import { ScaleLinear } from 'd3-scale';
import { AgglomerationMethod, agnes, Cluster } from 'ml-hclust';
import { ReactElement } from 'react';

import { PadifAnalysisResult } from '../padifGqlTypes';

export const labelHeight = 32;
export const labelPadding = 3;
export const labelMargin = 2;
export const labelTextDiff = 1;
export const seriesColorOpacity = 0.6;
export const xAxisHeight = 50;

export function computeHierarchy(
  documents: PadifAnalysisResult['documents'],
  distanceMatrix: PadifAnalysisResult['distanceMatrix'],
  clusteringMethod: AgglomerationMethod,
) {
  const twoDMatrix = Array.from({ length: documents.length }, () =>
    new Array(documents.length).fill(0),
  );
  let index = 0;
  for (let i = 0; i < documents.length; i++) {
    for (let j = i + 1; j < documents.length; j++) {
      const value = distanceMatrix[index++];
      twoDMatrix[i][j] = value;
      twoDMatrix[j][i] = value;
    }
  }

  const clusters = agnes(twoDMatrix, {
    method: clusteringMethod,
    isDistanceMatrix: true,
  });

  return hierarchy(clusters);
}

export function getClusterLines(
  clustering: HierarchyPointNode<Cluster>,
  scaleX: ScaleLinear<number, number>,
) {
  const lines: ReactElement[] = [];
  let key = 0;
  clustering.eachAfter((node) => {
    if (node.parent) {
      lines.push(
        <line
          key={key++}
          x1={scaleX(node.data.height)}
          x2={scaleX(node.parent.data.height)}
          y1={node.x}
          y2={node.x}
          stroke="black"
        />,
      );
    }
    if (node.children) {
      lines.push(
        <line
          key={key++}
          x1={scaleX(node.data.height)}
          x2={scaleX(node.data.height)}
          y1={node.children[0].x}
          y2={(node.children.at(-1) as HierarchyPointNode<Cluster>).x}
          stroke="black"
        />,
      );
    }
  });
  return lines;
}
