import { T } from '@tolgee/react';
import bytesize from 'byte-size';
import { memo, useMemo } from 'react';
import { FormattedDate } from 'react-intl';
import { Link } from 'react-router-dom';

import { useImageIdParam, useSeqIdParam } from '../../hooks/useParams';
import { safeTranslationKeyDocumentView } from '../../translationsMapping/documents';
import ImgLink from '../ImgLink';
import { ScannerData } from '../scans/Scanner';
import ScannerQrCode from '../scans/ScannerQrCode';
import FormattedButton from '../translation/FormattedButton';

import {
  GqlDocumentImageBySeqIdQuery,
  GqlFile,
  useDocumentImageBySeqIdQuery,
} from '#gql';
import { CardLayout } from '#ui/card_layout';
import {
  FormattedDefinitionList,
  FormattedDefinitionListProps,
} from '#ui/definition_list';
import { ObjectLinkFallback } from '#ui/link';
import { PageLayout, PageLayoutNavigation } from '#ui/page_layout';
import { getDocumentTitle } from '#utils/index';

export default function DocumentStandardizedImageView() {
  const seqId = useSeqIdParam();
  const imageId = useImageIdParam();

  const { data, error } = useDocumentImageBySeqIdQuery({
    variables: {
      seqId,
      imageId,
    },
  });

  const formattedData = useMemo(() => {
    if (!data?.document.image) return null;
    const image = data.document.image;

    return {
      'doc.image.scan_upload_date': (
        <FormattedDate value={image.originalScan.createdAt} dateStyle="short" />
      ),
      'doc.image.resolution': (
        <T
          keyName="global.resolution.dpi"
          params={{ value: Math.round(image.resolution) }}
        />
      ),
      'doc.document_view': (
        <T keyName={safeTranslationKeyDocumentView(image.view)} />
      ),
    };
  }, [data?.document.image]);

  if (error) throw error;
  if (!data?.document.image) return null;
  if (!formattedData) return null;

  const { document } = data;
  const { image } = document;
  const colors = image.colorData.colors;

  return (
    <PageLayout
      title={image.full.filename}
      actions={
        <FormattedButton
          messageId="doc.return"
          variant="white"
          as={Link}
          to={`/documents/${document.seqId}`}
        />
      }
      navigation={
        <>
          <PageLayoutNavigation to="/documents">
            <T keyName="nav.documents.list" />
          </PageLayoutNavigation>
          <PageLayoutNavigation to={`/documents/${seqId}`}>
            {seqId} - {getDocumentTitle(document, true)}
          </PageLayoutNavigation>
          <PageLayoutNavigation
            to={`/documents/${document.seqId}/standardized-images/manage`}
          >
            <T keyName="doc.standardized_images" />
          </PageLayoutNavigation>
        </>
      }
      bodyClassName="grid grid-cols-2 gap-y-2 gap-x-4"
      noDefaultBodyClassName
    >
      <CardLayout title="global.view.general_information">
        <FormattedDefinitionList data={formattedData} />
      </CardLayout>

      <ScannerCard scanner={image.scanner} />

      <div className="flex flex-col gap-4">
        <DocumentImageCard
          label="doc.image.header.document"
          {...image}
          {...image.document}
        />
        <ColorsCard label="doc.image.header.colors" colors={colors} />
      </div>

      <FullScanCard
        scanId={image.originalScan.id}
        full={image.full}
        scanImage={image.originalScan.image}
      />
    </PageLayout>
  );
}

interface DocumentImageCardProps {
  label: TranslationKey;
  url: string;
  filename: string;
  size: number;
}
function DocumentImageCard(props: DocumentImageCardProps) {
  const size = bytesize(props.size).toString();

  return (
    <CardLayout title={props.label}>
      <ImgLink src={props.url} alt={`${props.filename} | ${size}`} />
      <FormattedDefinitionList
        data={{ 'global.file.name': props.filename, 'global.file.size': size }}
      />
    </CardLayout>
  );
}

interface ScannerCardProps {
  scanner: GqlDocumentImageBySeqIdQuery['document']['image']['scanner'];
}
function ScannerCard({ scanner }: ScannerCardProps) {
  const data = useMemo<ScannerData>(
    () => ({
      service: scanner.service,
      brand: scanner.brand,
      model: scanner.model,
      serial: scanner.serialNumber || undefined,
      description: scanner.description || undefined,
    }),
    [scanner],
  );

  return (
    <CardLayout title="doc.image.header.scanner">
      <ScannerQrCode data={data} noPrint />
    </CardLayout>
  );
}

interface FullScanCardProps {
  scanId: string;
  scanImage: GqlDocumentImageBySeqIdQuery['document']['image']['originalScan']['image'];
  full: Pick<GqlFile, 'filename' | 'size' | 'url'>;
}
function FullScanCard({ scanId, full, scanImage }: FullScanCardProps) {
  if (!scanImage) return null;
  const { size, filename, url: originalScanUrl } = scanImage;

  const { url } = full;

  const fileSize = bytesize(size).toString();

  const data: FormattedDefinitionListProps['data'] = {
    'global.file.name': filename,
    'global.file.size': fileSize,
  };

  const downloadURL = new URL(originalScanUrl);
  downloadURL.searchParams.set('download', 'true');

  return (
    <CardLayout title="scan.full">
      <div className="flex flex-col gap-6">
        <ObjectLinkFallback
          src={url}
          alt={`${filename} | ${size}`}
          srcLink={originalScanUrl}
        />
        <FormattedDefinitionList data={data} />
        <div className="flex items-center gap-2">
          <Link to={`/scans/${scanId}`}>
            <FormattedButton
              variant="white"
              messageId="doc.image.full_scan.button.details"
            />
          </Link>
          <a href={downloadURL.href}>
            <FormattedButton variant="white" messageId="global.download" />
          </a>
        </div>
      </div>
    </CardLayout>
  );
}

interface ColorsCardProps {
  label: TranslationKey;
  colors: GqlDocumentImageBySeqIdQuery['document']['image']['colorData']['colors'];
}

const ColorsCard = memo(function ColorsCard(props: ColorsCardProps) {
  if (import.meta.env.PROD) return null;

  const { label, colors } = props;

  return (
    <CardLayout title={label}>
      <div className="grid h-56 grid-cols-5 place-content-stretch place-items-stretch gap-2">
        {colors.map(([r, g, b], idx) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={idx}
            className="flex items-center justify-center font-mono"
            style={{
              backgroundColor: `rgb(${r}, ${g}, ${b})`,
              color: `rgb(${255 - r}, ${255 - g}, ${255 - b})`,
            }}
          >
            <span className="mix-blend-hard-light">
              #{r.toString(16).padStart(2, '0').toUpperCase()}
              {g.toString(16).padStart(2, '0').toUpperCase()}
              {b.toString(16).padStart(2, '0').toUpperCase()}
            </span>
          </div>
        ))}
      </div>
    </CardLayout>
  );
});
