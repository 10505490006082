import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { T, useTranslate } from '@tolgee/react';
import { useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import FormattedButton from '../../../components/translation/FormattedButton';
import FormattedSubmitButton from '../../../components/translation/FormattedSubmitButton';
import { UserChangesMeta } from '../../../components/user/UserChangesMeta';
import {
  optionalString,
  requiredObject,
  requiredString,
} from '../../../forms/validation';
import { useNamedParamCheck } from '../../../hooks/useParams';
import { LabelFields } from '../components/form/shared_fields';

import {
  GqlLabelQuery,
  GqlUpdateLabelInput,
  useLabelQuery,
  useUpdateLabelMutation,
} from '#gql';
import { FormRHF, useCheckedFormRHFContext } from '#tailwind_ui';
import { PageLayout, PageLayoutNavigation } from '#ui/page_layout';

const validationSchema = requiredObject({
  name: requiredString,
  color: requiredObject({
    background: requiredString.length(7),
    text: requiredString.length(7),
  }),
  description: optionalString,
  docType: optionalString,
  docFraudType: optionalString,
  docCountry: optionalString,
});

export default function LabelEditPage() {
  const id = useNamedParamCheck('labelId');
  const { data, error } = useLabelQuery({
    variables: { id },
  });

  const label = useMemo(() => {
    if (!data) return;

    const label: GqlLabelQuery['label'] = {
      ...data.label,
      color: { ...data.label.color },
    };
    delete label.__typename;
    delete label.color.__typename;

    return label;
  }, [data]);

  if (error) throw error;
  if (!label) return null;

  return <LabelEditChecked label={label} />;
}

interface LabelEditCheckedProps {
  label: GqlLabelQuery['label'];
}

function LabelEditChecked(props: LabelEditCheckedProps) {
  const { label } = props;
  const { t } = useTranslate();

  const [updateLabel] = useUpdateLabelMutation();
  const navigate = useNavigate();

  const [isScopedLabel, setIsScopedLabel] = useState(() => {
    if (!label) return false;

    return (
      label.docCountry !== null ||
      label.docFraudType !== null ||
      label.docType !== null
    );
  });

  async function onSubmit(data: GqlUpdateLabelInput) {
    data.name = data.name?.trim();
    data.description = data.description?.trim();

    await updateLabel({
      variables: {
        input: {
          ...data,
          ...(isScopedLabel
            ? {}
            : {
                docCountry: null,
                docFraudType: null,
                docType: null,
              }),
        },
      },
    });
    navigate('..');
  }

  return (
    <FormRHF<GqlUpdateLabelInput>
      defaultValues={label}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      noDefaultStyle
    >
      <PageLayout
        title={<T keyName="form.edit_label.title" />}
        navigation={
          <PageLayoutNavigation to="/documents/labels">
            <T keyName="nav.labels.list" />
          </PageLayoutNavigation>
        }
        actions={<FormButtons />}
        footerClassName="mx-auto max-w-5xl flex gap-2"
        footer={<FormButtons />}
      >
        <UserChangesMeta
          {...label}
          mailSubject={`[${t('label.singular_form', { noWrap: true })}] ${label.name}`}
          mailBody={window.location.href}
        />

        <div className="mx-auto max-w-5xl">
          <LabelFields
            isScopedLabel={isScopedLabel}
            setIsScopedLabel={setIsScopedLabel}
            scopeWarning={<WarnScopeChange isScopedLabel={isScopedLabel} />}
          />
        </div>
      </PageLayout>
    </FormRHF>
  );
}

function FormButtons() {
  return (
    <>
      <FormattedButton
        as={Link}
        to=".."
        messageId="global.cancel"
        variant="white"
      />
      <FormattedSubmitButton messageId="global.save" color="primary" />
    </>
  );
}

interface WarnScopeChangeProps {
  isScopedLabel: boolean;
}

function WarnScopeChange({ isScopedLabel }: WarnScopeChangeProps) {
  const isScopedLabelRef = useRef(isScopedLabel);
  const form = useCheckedFormRHFContext<GqlUpdateLabelInput>();
  const { t } = useTranslate();

  const fields: Array<keyof GqlUpdateLabelInput> = [
    'docType',
    'docFraudType',
    'docCountry',
  ];

  form.watch(fields);

  if (
    fields.some((f) => form.getFieldState(f).isDirty) ||
    isScopedLabelRef.current !== isScopedLabel
  ) {
    return (
      <p className="flex items-center gap-2">
        <ExclamationTriangleIcon height="3rem" />
        {t('label.field.scope.change_warning')}
      </p>
    );
  }

  return null;
}
