import { T } from '@tolgee/react';

import { useFilesUploading } from '../providers/FilesUploadingProvider.context';

import { Spinner } from '#tailwind_ui';

export default function UploadingFiles() {
  const [uploading] = useFilesUploading();
  if (uploading.size === 0) return null;
  return (
    <div className="mr-4 flex flex-row items-center gap-2 rounded border border-warning-300 px-2 py-1">
      <Spinner className="h-5 w-5" />
      <T keyName="upload.inprogress" />
    </div>
  );
}
