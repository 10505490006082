import { useCallback, useMemo, useState } from 'react';

import { SortDirection } from '#gql';
import { TableSortConfig } from '#tailwind_ui';
import { sortConvertTable } from '#utils/table';

export interface SortTable<Field extends string> {
  field: Field;
  direction: SortDirection;
}

export type UseSortTableInitial<Field extends string> =
  | SortTable<Field>
  | (() => SortTable<Field>);

export function useSortTable<Field extends string>(
  initialState: UseSortTableInitial<Field>,
): [gplSort: SortTable<Field>, tableSort: TableSortConfig] {
  const [state, setState] = useState(initialState);

  const toggleField = useCallback((field: string) => {
    setState((state) => {
      state = { ...state };

      if (state.field !== field) {
        state.field = field as Field;
        state.direction = 'ASC';
      } else {
        state.direction = state.direction === 'ASC' ? 'DESC' : 'ASC';
      }

      return state;
    });
  }, []);

  const tableSort = useMemo<TableSortConfig>(
    () => ({
      field: state.field,
      direction: sortConvertTable[state.direction],
      onChange: toggleField,
    }),
    [state, toggleField],
  );

  return [state, tableSort];
}
