import { ShieldExclamationIcon } from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';
import { useNavigate } from 'react-router-dom';

import FormattedButton from '../../translation/FormattedButton';
import FormattedFormError from '../../translation/FormattedFormError';
import FormattedSelectField from '../../translation/FormattedSelectField';
import FormattedSubmitButton from '../../translation/FormattedSubmitButton';
import { AlertTitleInput } from '../AlertTitleInput';
import { getLanguageKey, languageKeys } from '../utils/languages';
import { getAlertDraftEditUrl } from '../utils/navigation';

import { FormState } from './shared';
import { createAlertValidationSchema } from './validation';

import { Language, GqlSerieQuery, useCreateAlertMutation } from '#gql';
import { FormRHFModal } from '#tailwind_ui';
import { assert } from '#utils/assert';

interface CreateAlertModalProps {
  serie: GqlSerieQuery['serie'];
  onClose: () => void;
}

export default function CreateAlertModal(props: CreateAlertModalProps) {
  const { onClose: onRequestClose, serie } = props;
  const navigate = useNavigate();
  const [createAlert] = useCreateAlertMutation();

  return (
    <FormRHFModal<FormState>
      isOpen
      defaultValues={{ language: undefined }}
      onRequestClose={onRequestClose}
      validationSchema={createAlertValidationSchema}
      onSubmit={async (values) => {
        if (!values.language) return;

        const result = await createAlert({
          variables: {
            input: {
              seriesId: serie.id,
              language: values.language,
              title: values.title,
            },
          },
        });
        assert(result.data);
        assert(result.data.createAlert.draft?.id);
        onRequestClose();
        navigate(
          getAlertDraftEditUrl(result.data.createAlert.id, values.language),
        );
      }}
      icon={<ShieldExclamationIcon />}
    >
      <FormRHFModal.Header>
        <T keyName="page.series.create_alert.title" />
      </FormRHFModal.Header>
      <FormRHFModal.Body className="w-96">
        <FormattedSelectField<string>
          options={languageKeys}
          getValue={(val) => val}
          label="global.language"
          name="language"
          required
          renderOption={(option) => (
            <T keyName={getLanguageKey(option as Language)} />
          )}
        />
        <AlertTitleInput />

        <FormattedFormError />
      </FormRHFModal.Body>
      <FormRHFModal.Footer>
        <FormattedButton
          variant="white"
          messageId="global.cancel"
          onClick={onRequestClose}
        />
        <FormattedSubmitButton color="primary" messageId="global.create" />
      </FormRHFModal.Footer>
    </FormRHFModal>
  );
}
