import { UserGroupIcon } from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';

import { AsyncFormattedConfirmModal } from '../../AsyncFormattedConfirmModal';

import { usePublishAlertDraftMutation } from '#gql';
import { FormattedAlert } from '#intl';

interface PublishAlertDraftModalProps {
  id: string;
  draftId: string;
  isOpen: boolean;
  hasMultipleLanguages: boolean;
  onClose: (success?: boolean) => void;
}

export default function PublishAlertDraftModal(
  props: PublishAlertDraftModalProps,
) {
  const { id, draftId, isOpen, onClose, hasMultipleLanguages } = props;
  const [publishDraft] = usePublishAlertDraftMutation();

  return (
    <AsyncFormattedConfirmModal
      open={isOpen}
      icon={<UserGroupIcon />}
      color="success"
      confirmText="global.publish"
      cancelText="global.cancel"
      title="series.alert.publish_draft.confirm.title"
      body={<PublishDraftBody hasMultipleLanguages={hasMultipleLanguages} />}
      onClose={onClose}
      onConfirm={() =>
        publishDraft({
          variables: {
            id,
            draftId,
          },
        })
      }
    />
  );
}

export function PublishDraftBody({
  hasMultipleLanguages,
}: {
  hasMultipleLanguages: boolean;
}) {
  return (
    <div className="space-y-3">
      <div>
        <T keyName="series.alert.publish_draft.confirm.body" />
      </div>
      {hasMultipleLanguages && (
        <FormattedAlert
          type="warning"
          title="series.alert.publish_draft.confirm.body.warning.title"
          messageId="series.alert.publish_draft.confirm.body.warning.content"
        />
      )}
    </div>
  );
}
