import { Route } from 'react-router-dom';

import { CorrectMRZPage } from '../../components/scans/importData/CorrectMRZPage';
import ScanCorrectExtractDocumentPage from '../../components/scans/importData/ScanCorrectExtractDocumentPage';
import { ScanRoiRotatePage } from '../../components/scans/importData/ScanRoiRotatePage';

import { ScanImportPage } from './pages/scan_import_page';
import { ScanRoiAnonymizationPage } from './pages/scan_roi_anonymization_page';

export function scanImportRoutes() {
  return [
    <Route
      key="/scans/:scanId/import"
      path="/scans/:scanId/import"
      element={<ScanImportPage />}
    />,
    <Route
      key="/scans/:scanId/import/extract-document"
      path="/scans/:scanId/import/extract-document"
      element={<ScanCorrectExtractDocumentPage />}
    />,
    <Route
      key="/scans/:scanId/import/rotate"
      path="/scans/:scanId/import/rotate"
      element={<ScanRoiRotatePage />}
    />,
    <Route
      key="/scans/:scanId/import/mrz"
      path="/scans/:scanId/import/mrz"
      element={<CorrectMRZPage />}
    />,
    <Route
      key="/scans/:scanId/import/anonymization"
      path="/scans/:scanId/import/anonymization"
      element={<ScanRoiAnonymizationPage />}
    />,
  ];
}
