import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { T } from '@tolgee/react';
import { useRef } from 'react';

import { GqlScansQuery } from '#gql';
import {
  Bubble,
  Color,
  IconButton,
  useOnClickOutside,
  useOnOff,
} from '#tailwind_ui';

type Scan = GqlScansQuery['scans']['nodes'][number];

interface ScanAdminProps {
  scan: Scan;
}

export default function ScanAdmin({ scan }: ScanAdminProps) {
  const [isOpen, setOpen, setClosed] = useOnOff();
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, setClosed);

  return (
    <div className="flex items-center gap-1">
      {scan.error && (
        <Bubble
          visible={isOpen}
          onClickOutside={setClosed}
          popperClassName="max-w-full"
          reference={
            <IconButton
              size="5"
              tooltip={<T keyName="scan.error.show" />}
              icon={<ExclamationTriangleIcon />}
              color={Color.danger}
              onClick={setOpen}
            />
          }
        >
          <pre className="whitespace-pre-wrap p-4 font-normal">
            {scan.error}
          </pre>
        </Bubble>
      )}
    </div>
  );
}
