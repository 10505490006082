import { createContext, useContext } from 'react';
import { useBlocker } from 'react-router';

type Blocker = ReturnType<typeof useBlocker>;

/**
 * Private usage
 * Provider is mount from PreventNavigationProvider
 */
export const PreventNavigationBlockerContext = createContext<Blocker | null>(
  null,
);

/**
 * Get in app the blocker state. blocked and proceeding state already managed with interactive modal
 * from PreventNavigationModal mounted in PreventNavigationProvider
 */
export function usePreventNavigationBlockerContext() {
  const context = useContext(PreventNavigationBlockerContext);
  if (context === null) {
    throw new Error(
      'usePreventNavigationBlockerContext must be used within a PreventNavigationProvider',
    );
  }
  return context;
}
