import { TrashIcon as TrashIconSolid } from '@heroicons/react/20/solid';
import { DocumentIcon, DocumentTextIcon } from '@heroicons/react/24/outline';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import ByteSize from '../ByteSize';
import IfHasPermission from '../permissions/IfHasPermission';
import FormattedEmptyState from '../translation/FormattedEmptyState';

import DeleteSerieAttachment from './DeleteSerieAttachment';

import { GqlFile } from '#gql';
import { Button, Color, Size } from '#tailwind_ui';

export type FileAttachment = Pick<GqlFile, 'id' | 'filename' | 'size' | 'url'>;
interface SerieAttachmentsProps {
  serieId: string;
  attachments: FileAttachment[];
  readonly?: boolean;
}

export default function SerieAttachments(props: SerieAttachmentsProps) {
  const { serieId, attachments, readonly } = props;

  const [deleteAttachment, setDeleteAttachment] =
    useState<null | FileAttachment>(null);
  const closeModal = useCallback(() => setDeleteAttachment(null), []);

  if (attachments.length === 0) {
    return (
      <FormattedEmptyState
        title="global.no_attachments"
        icon={<DocumentTextIcon />}
      />
    );
  }

  return (
    <>
      <div className="flex flex-col gap-1">
        {attachments.map((file) => (
          <Attachment
            key={file.id}
            file={file}
            setDeleteAttachment={setDeleteAttachment}
            readonly={readonly}
          />
        ))}
      </div>
      {deleteAttachment && (
        <DeleteSerieAttachment
          closeModal={closeModal}
          attachment={deleteAttachment}
          serieId={serieId}
        />
      )}
    </>
  );
}

interface AttachmentProps {
  file: FileAttachment;
  setDeleteAttachment: Dispatch<SetStateAction<FileAttachment | null>>;
  readonly?: boolean;
}
function Attachment(props: AttachmentProps) {
  const { file, setDeleteAttachment, readonly } = props;

  return (
    <div className="flex flex-row flex-nowrap items-center gap-2">
      <a
        href={file.url}
        target="_blank"
        rel="noopener noreferrer"
        className="flex flex-row flex-nowrap items-center"
      >
        <DocumentIcon className="h-5 w-5" />
        <span className="truncate">{file.filename}</span>
      </a>

      <span className="flex-1" />

      <ByteSize bytes={file.size} />

      {!readonly && (
        <IfHasPermission userPermission="serie_update">
          <Button
            size={Size.xSmall}
            color={Color.danger}
            onClick={() => setDeleteAttachment(file)}
            roundness="circular"
          >
            <TrashIconSolid className="h-5 w-5" />
          </Button>
        </IfHasPermission>
      )}
    </div>
  );
}
