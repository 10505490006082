import { T } from '@tolgee/react';
import { useNavigate } from 'react-router-dom';

import FormattedSubmitButton from '../translation/FormattedSubmitButton';

import NewSeriesFields from './NewSeriesFields';
import { getSeriesValidationSchema } from './newSerieForm';

import { GqlNewSerieInput, useCreateSerieMutation } from '#gql';
import { FormRHF } from '#tailwind_ui';
import { PageLayout, PageLayoutNavigation } from '#ui/page_layout';
import { assert } from '#utils/assert';

const SUBMIT_BUTTON = (
  <FormattedSubmitButton color="primary" messageId="page.series.new.create" />
);

const validationSchema = getSeriesValidationSchema(
  'doc.field.new_series.create.exists',
);

export default function NewSeriesPage() {
  const [createSerie] = useCreateSerieMutation();
  const navigate = useNavigate();

  const onSubmit = async (formData: GqlNewSerieInput) => {
    const value = await createSerie({
      variables: {
        newSerie: {
          docCountry: formData.docCountry,
          docFraudType: formData.docFraudType,
          docType: formData.docType,
          name: formData.name,
        },
      },
    });
    assert(value.data?.createSerie);
    navigate(`/series/${value.data.createSerie.seqId}`);
  };

  return (
    <FormRHF<GqlNewSerieInput>
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      noDefaultStyle
    >
      <PageLayout
        title={<T keyName="form.new_series.title" />}
        navigation={
          <PageLayoutNavigation to="/series">
            <T keyName="nav.series.list" />
          </PageLayoutNavigation>
        }
        footer={SUBMIT_BUTTON}
        actions={SUBMIT_BUTTON}
        bodyClassName="max-w-xl mx-auto"
        footerClassName="max-w-xl mx-auto"
      >
        <NewSeriesFields />
      </PageLayout>
    </FormRHF>
  );
}
