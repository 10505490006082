import { TableSearchContextProvider } from '../components/providers/TableSearchContextProvider';
import { scanContext } from '../contexts/scan';
import { MandatoryChildren } from '../global';
import { UsePaginationTableInitial } from '../hooks/usePaginationTable';
import { SortTable } from '../hooks/useSortTable';
import {
  ScanFilters,
  ScanFiltersStore,
} from '../stores/searches/ScanFiltersStore';

import { ScanSortField } from '#gql';

const initialSort = {
  field: 'createdAt',
  direction: 'DESC',
} as const satisfies SortTable<ScanSortField>;

const initialPagination = {
  label: 'scans.list.pagination',
} as const satisfies Partial<UsePaginationTableInitial>;

export function ScanTableSearchProvider({ children }: MandatoryChildren) {
  return (
    <TableSearchContextProvider<
      ScanSortField,
      ScanFilters,
      typeof ScanFiltersStore,
      ScanFiltersStore
    >
      context={scanContext}
      Store={ScanFiltersStore}
      initialSort={initialSort}
      initialPagination={initialPagination}
    >
      {children}
    </TableSearchContextProvider>
  );
}
