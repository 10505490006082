import { useTranslate } from '@tolgee/react';

import { Help, HelpPublicProps } from '#tailwind_ui';

interface FormattedHelpProps
  extends Omit<HelpPublicProps, 'help' | 'valid' | 'error'> {
  help?: TranslationKey;
  error?: TranslationKey;
  valid?: TranslationKey;
}

export default function FormattedHelp(props: FormattedHelpProps) {
  const { t } = useTranslate();
  const { help, error, valid, ...otherProps } = props;
  const formattedHelp = help ? t(help) : help;
  const formattedError = error ? t(error) : error;
  const formattedValid = typeof valid === 'string' ? t(valid) : valid;
  return (
    <Help
      {...otherProps}
      help={formattedHelp}
      error={formattedError}
      valid={formattedValid}
    />
  );
}
