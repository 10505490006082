import { T } from '@tolgee/react';

import SeriesLink from '../serie/SeriesLink';
import { FormattedTable } from '../translation/FormattedTable';

import DocumentLink from './DocumentLink';

import {
  GqlDetailedDocumentFieldsFragment,
  GqlDocumentSimilarSerialNumbersQuery,
  useDocumentSimilarSerialNumbersQuery,
} from '#gql';
import { Td, Th } from '#tailwind_ui';
import { CardLayout } from '#ui/card_layout';
import { H4 } from '#ui/heading';

export interface DocumentSimilarSerialNumbersProps {
  document: GqlDetailedDocumentFieldsFragment;
}
export default function DocumentSimilarSerialNumbers(
  props: DocumentSimilarSerialNumbersProps,
) {
  const { document } = props;
  const { error, data } = useDocumentSimilarSerialNumbersQuery({
    variables: { id: document.id },
  });

  if (!data) return null;
  if (error) throw error;

  if (!document.docSerialNumber) {
    return (
      <div className="mt-4">
        <T keyName="page.doc.view.similar_document_numbers.no_number" />
      </div>
    );
  }

  const similarSN = data.document.similarSerialNumbers;
  return (
    <CardLayout title="global.similar_documents">
      <H4 className="mb-3">
        <T keyName="doc.field.document_number" />
        {': '}
        {document.docSerialNumber}
      </H4>

      {similarSN.totalCount > 0 && (
        <FormattedTable
          renderHeader={renderHeader}
          renderTr={(value) => <SNRow value={value} />}
          data={similarSN.nodes}
        />
      )}

      {similarSN.totalCount === 0 && <T keyName="doc.search.noresults" />}
    </CardLayout>
  );
}

function renderHeader() {
  return (
    <tr>
      <Th>
        <T keyName="global.exhibit" />
      </Th>
      <Th className="w-[80px]">
        <T keyName="doc.table.service" />
      </Th>
      <Th>
        <T keyName="doc.field.document_number" />
      </Th>
      <Th>
        <T keyName="global.series" />
      </Th>
    </tr>
  );
}

function SNRow({
  value,
}: {
  value: GqlDocumentSimilarSerialNumbersQuery['document']['similarSerialNumbers']['nodes'][number];
}) {
  return (
    <tr>
      <Td className="align-middle">
        <DocumentLink document={value} multiline includeService />
      </Td>
      <Td className="align-middle" title={value.caseService.name}>
        {value.caseService.identifier}
      </Td>
      <Td>{value.docSerialNumber}</Td>
      <Td className="align-middle">
        <SeriesLink series={value.series} multiline />
      </Td>
    </tr>
  );
}
