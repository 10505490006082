import { useTranslate } from '@tolgee/react';
import { useCallback } from 'react';

import { baseUrl } from '../constants';

import { GqlDocumentIdentifierFieldsFragment } from '#gql';

// TODO move to document scope and export it in `index.ts` once it exists
export function getDocumentTitle(
  doc: GqlDocumentIdentifierFieldsFragment,
  includeService: boolean,
): string {
  if (includeService) {
    return `${doc.caseService.identifier} | ${doc.caseTitle}`;
  } else {
    return doc.caseTitle;
  }
}

// TODO move to user scope and export it in `index.ts` once it exists
export function getActivateURL(token: string, email: string): string {
  token = encodeURIComponent(token);
  email = encodeURIComponent(email);

  return `${baseUrl}user/activate#token=${token}&email=${email}`;
}

// TODO move to user scope
export function useGetActivateMailto() {
  const { t } = useTranslate();

  return useCallback(
    (email: string, token: string, title: string) => {
      const activateLink = getActivateURL(token, email);

      let subject = t('activate.mail.subject', { title });
      let body = t('activate.mail.body', { title, link: activateLink });

      email = encodeURIComponent(email);
      subject = encodeURIComponent(subject);
      body = encodeURIComponent(body);

      return `mailto:${email}?subject=${subject}&body=${body}`;
    },
    [t],
  );
}
