import { GetStyleCallback } from 'react-roi';

import { PersonalDataReactRoiData } from './personal_data';
import { StripePattern } from './stripe_pattern';

export const getScanRoiStyle: GetStyleCallback<PersonalDataReactRoiData> = (
  roi,
  options,
) => {
  const { isSelected } = options;
  const patternId = `stripe-pattern-${roi.id}`;

  return {
    resizeHandlerColor: 'white',
    rectAttributes: {
      fill: `url(#${patternId})`,
      stroke: isSelected ? 'transparent' : 'black',
    },
    renderCustomPattern: () => (
      <StripePattern
        id={patternId}
        backgroundRectAttributes={{
          opacity: 0.2,
          fill: roi.data?.fill || 'rgba(255, 255, 0, 0.2)',
        }}
        pattern={{
          space: 8,
          strokeWidth: 1,
          pathAttributes: {
            stroke: isSelected ? 'white' : 'black',
          },
        }}
      />
    ),
  };
};
