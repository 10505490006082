import { BoolStr, NullStr, safeMappedTranslationKey } from './common';

import { AlertStatus } from '#gql';

export const TRANSLATIONS_KEY_ALERT_STATUS = {
  INITIATED: 'series.alert.status.INITIATED',
  PUBLISHED: 'series.alert.status.PUBLISHED',
  ARCHIVED: 'series.alert.status.ARCHIVED',
  null: 'series.alert.status.null',
} as const satisfies Record<AlertStatus | NullStr, TranslationKey>;

export function safeTranslationKeyAlertStatus(
  index: AlertStatus | null | undefined,
) {
  return safeMappedTranslationKey(TRANSLATIONS_KEY_ALERT_STATUS, index);
}

export const TRANSLATIONS_KEY_ALERT_DRAFT = {
  true: `series.alert.draft.true`,
  false: `series.alert.draft.false`,
} as const satisfies Record<BoolStr, TranslationKey>;

export function safeTranslationKeyAlertDraft(
  index: boolean | null | undefined,
) {
  return safeMappedTranslationKey(TRANSLATIONS_KEY_ALERT_DRAFT, index);
}
