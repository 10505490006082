import { FormattedAlert, FormattedAlertProps } from '#intl';

interface ErrorAlertProps
  extends Omit<
    FormattedAlertProps,
    'type' | 'title' | 'messageId' | 'messageValues'
  > {
  message?: TranslationKey;
}

export default function FormattedAlertMessage(props: ErrorAlertProps) {
  const { message = 'global.error.unknown', ...formattedAlertProps } = props;

  return (
    <FormattedAlert {...formattedAlertProps} type="error" title={message} />
  );
}
