import { useMemo } from 'react';

import {
  optionalString,
  requiredObject,
  requiredString,
} from '../../../../forms/validation';

import { shuffleLabelColors } from './shuffle_label_colors';

import { GqlNewLabelInput } from '#gql';

export const validationSchema = requiredObject({
  name: requiredString,
  color: requiredObject({
    background: requiredString.length(7),
    text: requiredString.length(7),
  }),
  description: optionalString,
  docType: optionalString,
  docFraudType: optionalString,
  docCountry: optionalString,
});

export function useDefaultValue() {
  return useMemo<GqlNewLabelInput>(() => {
    return {
      name: '',
      color: shuffleLabelColors(),
      description: '',
      docType: null,
      docFraudType: null,
      docCountry: null,
    };
  }, []);
}
