import { useTranslate } from '@tolgee/react';
import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import {
  HorizontalNavigationItem,
  HorizontalNavigationRenderOptionCallback,
  UncontrolledHorizontalNavigation,
} from '#tailwind_ui';

export interface LinkItem {
  id: string;
  intlId: TranslationKey;
}

export interface LinkGroupProps {
  options: LinkItem[];
  preserveState?: boolean;
}

export default function LinkGroup(props: LinkGroupProps) {
  const { options, preserveState = false } = props;
  const { pathname, state } = useLocation();

  const { t } = useTranslate();

  const currentValue = useMemo(() => {
    const pathnameParts = pathname.split(`/`);
    const lastPart = pathnameParts.at(-1) as string;
    if (options.some((option) => option.id.toLowerCase() === lastPart)) {
      return lastPart.toUpperCase();
    } else {
      return options[0].id;
    }
  }, [pathname, options]);

  const renderOption: HorizontalNavigationRenderOptionCallback<string> = (
    Component,
    option,
  ) => {
    const to =
      option.value === options[0].id ? '.' : option.value.toLowerCase();

    return (
      <Link to={to} state={preserveState ? state : undefined}>
        <Component>{option.label}</Component>
      </Link>
    );
  };

  const opts = useMemo(() => {
    return options.map((element) => ({
      label: t(element.intlId),
      value: element.id,
    }));
  }, [t, options]);

  return (
    <UncontrolledHorizontalNavigation
      options={opts}
      renderNavigationItem={(element) => (
        <HorizontalNavigationItem
          key={element.value}
          isSelected={currentValue === element.value}
          option={element}
          renderOption={renderOption}
        />
      )}
      renderSelectedOption={() => currentValue.toLowerCase()}
    />
  );
}
