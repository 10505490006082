import { T } from '@tolgee/react';

import DevErrorAlert from './DevErrorAlert';

// Alert title is already formatted.
// eslint-disable-next-line no-restricted-imports
import { Alert } from '#tailwind_ui';
import {
  errorContainsSensitiveDetails,
  getRequestId,
} from '#utils/error/codes';

export default function ErrorAlert(props: {
  error: Error;
  title: string;
  details?: string;
  onDismiss?: () => void;
  className?: string;
}) {
  const hasSensitiveDetails = errorContainsSensitiveDetails(props.error);
  const requestId = getRequestId(props.error);

  if (hasSensitiveDetails) {
    return (
      <DevErrorAlert
        className={props.className}
        error={props.error}
        onDismiss={props.onDismiss}
        title={props.title}
        details={props.details}
      />
    );
  } else {
    return (
      <Alert
        type="error"
        title={props.title}
        className={props.className}
        onDismiss={props.onDismiss}
      >
        {props.details || requestId ? (
          <>
            <div>{props.details}</div>
            {requestId && (
              <T
                keyName="error.send_request_id"
                params={{ requestId, code: renderCode }}
              />
            )}
          </>
        ) : null}
      </Alert>
    );
  }
}

function renderCode(code: string) {
  return <code>{code}</code>;
}
