import { safeMappedTranslationKey } from './common';

export type ScanImportSwitchTab =
  | 'raw'
  | 'extraction'
  | 'rotation'
  | 'mrz'
  | 'anonymization';

export const TRANSLATION_KEY_SCAN_SWITCH_TAB = {
  raw: 'scan.import.switch.raw',
  extraction: 'scan.import.switch.extraction',
  rotation: 'scan.import.switch.rotation',
  mrz: 'scan.import.switch.mrz',
  anonymization: 'scan.import.switch.anonymization',
} as const satisfies Record<ScanImportSwitchTab, TranslationKey>;

export function safeTranslationKeyScanSwitchTab(index: ScanImportSwitchTab) {
  return safeMappedTranslationKey(TRANSLATION_KEY_SCAN_SWITCH_TAB, index);
}
