import { Image } from 'image-js';

import { PersonalDataType } from '../../generated/graphql_enums';

import { fillImage } from './fill_roi_image';
import { pixelateRoiImage } from './pixelate_roi_image';
import { ScanImportRoi } from './types';

/**
 * Apply anonymization in-place
 *
 * @param image
 * @param rois
 */
export function scanImportApplyImageAnonymization(
  image: Image,
  rois: ScanImportRoi[],
): void {
  for (const roi of rois) {
    if (roi.type === PersonalDataType.FACE) {
      pixelateRoiImage(image, roi);
    } else {
      fillImage(image, roi);
    }
  }
}
