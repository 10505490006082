import { ApolloClient, useApolloClient } from '@apollo/client';
import {
  createContext,
  Dispatch,
  useCallback,
  useContext,
  useReducer,
} from 'react';
import { match, P } from 'ts-pattern';

import { apiClient } from '../../api';

import { GqlService, SessionDocument, UserPermission, UserRole } from '#gql';
import { ActionType } from '#tailwind_ui';
import { ApiError, ApiErrorData } from '#utils/error/api';

interface LoginData {
  id: string;
  email: string;
  service: GqlService | null;
  roles: UserRole[];
  permissions: UserPermission[];
  name: string;
}

interface LoginInitial {
  status: 'initial';
}

interface LoginUnauthenticated {
  status: 'unauthenticated';
}

interface LoginLostSession {
  status: 'lostSession';
  data: LoginData;
}

interface LoginError {
  status: 'error';
}
interface LoginErrorLostSession {
  status: 'errorLostSession';
  data: LoginData;
}

interface LoginAuthenticated {
  status: 'authenticated';
  data: LoginData;
}

export type LoginState =
  | LoginInitial
  | LoginUnauthenticated
  | LoginLostSession
  | LoginError
  | LoginErrorLostSession
  | LoginAuthenticated;

const initialLoginState: LoginState = {
  status: 'initial',
};

export const loginContext = createContext<LoginState>(initialLoginState);
export const loginDispatchContext = createContext<Dispatch<LoginAction>>(() => {
  // Empty
});
export const loginBroadcastChannelContext =
  createContext<BroadcastChannel | null>(null);

export function useLoginState() {
  return useContext(loginContext);
}

export function useCheckedLoginState(): LoginData {
  const loginState = useLoginState();
  if (!('data' in loginState)) {
    throw new Error('No session data');
  }
  return loginState.data;
}

export function useLoginDispatch() {
  return useContext(loginDispatchContext);
}

export function useLoginBroadcastChannelContext() {
  const channel = useContext(loginBroadcastChannelContext);

  if (channel === null) {
    throw new Error(
      'useLoginBroadcastChannelContext must be used within a LoginProvider',
    );
  }

  return channel;
}

export function useLogout() {
  const cleanupSession = useCleanupSession();
  return function logout(apollo: ApolloClient<unknown>) {
    apiClient
      .post('logout')
      .json()
      .then(() => {
        cleanupSession(apollo);
      })
      .catch(reportError);
  };
}

export function useCleanupSession() {
  const loginDispatch = useLoginDispatch();
  return useCallback(
    function cleanupSession(apollo: ApolloClient<unknown>) {
      apollo.clearStore().catch(reportError);
      loginDispatch({ type: 'LOGOUT' });
    },
    [loginDispatch],
  );
}

export interface LoginCredentials {
  email: string;
  password: string;
}

export function useLogin() {
  const session = useSession();
  const channel = useLoginBroadcastChannelContext();

  return async function login(credentials: LoginCredentials) {
    let res;
    try {
      res = await apiClient.post('login', {
        throwHttpErrors: false,
        json: { email: credentials.email, password: credentials.password },
      });
    } catch (error) {
      reportError(error);
      throw new Error('global.error.unknown');
    }
    if (res.status !== 200) {
      const result = await res.json<ApiErrorData>();
      throw new ApiError(result);
    }

    session();
    channel.postMessage('login');
  };
}

export function useSession() {
  const sessionInternal = useSessionInternal();
  return useCallback(
    function session() {
      sessionInternal().catch(reportError);
    },
    [sessionInternal],
  );
}

function useSessionInternal() {
  const apolloClient = useApolloClient();
  const loginDispatch = useLoginDispatch();
  const checkSession = useCheckSession();
  return useCallback(
    async function sessionInternal() {
      const isAuthenticated = await checkSession();
      if (!isAuthenticated) {
        return;
      }
      try {
        const { data } = await apolloClient.query({
          query: SessionDocument,
        });
        loginDispatch({ type: 'LOGIN', payload: data.me });
      } catch {
        loginDispatch({ type: 'ERROR' });
      }
    },
    [checkSession, loginDispatch, apolloClient],
  );
}

export function useCheckSession() {
  const loginDispatch = useLoginDispatch();
  return useCallback(
    async function checkSession() {
      try {
        const response = await apiClient
          .get('session')
          .json<{ authenticated: boolean }>();
        if (!response.authenticated) {
          loginDispatch({ type: 'UNAUTHENTICATED' });
          return false;
        }
      } catch (error) {
        // Consider if error is a TypeError, it's a NetworkError from fetch
        if (error instanceof TypeError) {
          reportError(error);
          return false;
        }

        // apiClient error handling, like unparsable JSON
        loginDispatch({ type: 'ERROR' });
        return false;
      }

      return true;
    },
    [loginDispatch],
  );
}

type LoginAction =
  | ActionType<'LOGOUT'>
  | ActionType<'ERROR'>
  | ActionType<'LOGIN', LoginData>
  | ActionType<'UNAUTHENTICATED'>;

function loginReducer(state: LoginState, action: LoginAction): LoginState {
  return match({ action, state })
    .returnType<LoginState>()
    .with({ action: { type: 'LOGOUT' } }, () => ({ status: 'unauthenticated' }))
    .with(
      { action: { type: 'ERROR' }, state: { data: P.not(P.nullish) } },
      ({ state: { data } }) => ({ status: 'errorLostSession', data }),
    )
    .with({ action: { type: 'ERROR' } }, () => ({ status: 'error' }))
    .with({ action: { type: 'LOGIN', payload: P.select() } }, (data) => ({
      status: 'authenticated',
      data,
    }))
    .with(
      {
        action: { type: 'UNAUTHENTICATED' },
        state: { data: P.not(P.nullish) },
      },
      ({ state: { data } }) => ({ status: 'lostSession', data }),
    )
    .with({ action: { type: 'UNAUTHENTICATED' } }, () => ({
      status: 'unauthenticated',
    }))
    .exhaustive();
}

export function useLoginReducer() {
  return useReducer(loginReducer, initialLoginState);
}
