import { createContext, useContext } from 'react';

import { TableSearchContext } from '../components/providers/TableSearchContextProvider';
import {
  DocumentSetDocumentFilters,
  DocumentSetDocumentFiltersStore,
} from '../stores/searches/DocumentSetDocumentFiltersStore';

import { DocumentSortField } from '#gql';

export const documentSetCandidateContext = createContext<TableSearchContext<
  DocumentSortField,
  DocumentSetDocumentFilters,
  DocumentSetDocumentFiltersStore
> | null>(null);

export function useDocumentSetCandidateContext() {
  const context = useContext(documentSetCandidateContext);

  if (context === null) {
    throw new Error(
      'useDocumentsContext must be used within a TableSearchContextProvider',
    );
  }

  return context;
}
