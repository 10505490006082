import { T } from '@tolgee/react';
import { ReactNode } from 'react';

import { ALERT_DOWNLOAD_PDF_NOTIFICATION_GROUP } from './utils/alertPdf';

import { useNotificationCenter } from '#tailwind_ui';

export default function DownloadPdfAction(props: {
  children: ReactNode;
  href: string;
}) {
  const { children, href } = props;
  const notification = useNotificationCenter();
  return (
    <a
      href={href}
      onClick={() => {
        notification.addToastNotification({
          label: <T keyName="alert.download_pdf.will_start" />,
          group: ALERT_DOWNLOAD_PDF_NOTIFICATION_GROUP,
        });
      }}
    >
      {children}
    </a>
  );
}
