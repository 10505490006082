import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { usePaginationTable } from '../../hooks/usePaginationTable';
import { useIdParam } from '../../hooks/useParams';
import { useSortTable } from '../../hooks/useSortTable';
import { useTableSelection } from '../../hooks/useTableSelection';
import FormattedButton from '../translation/FormattedButton';
import FormattedEmptyState from '../translation/FormattedEmptyState';
import FormattedErrorAlert from '../translation/FormattedErrorAlert';
import { FormattedPaginationText } from '../translation/FormattedPagination';

import { DocumentSetTableDocumentView } from './DocumentSetTableDocumentView';
import DocumentSetTitle from './DocumentSetTitle';
import DocumentSetType from './DocumentSetType';

import {
  DocumentSortField,
  useDocumentSetExcludedDocumentsQuery,
  useRemoveExcludedDocumentsFromSetMutation,
} from '#gql';
import { Size, Variant } from '#tailwind_ui';
import { PageLayout, PageLayoutNavigation } from '#ui/page_layout';

export function DocumentSetExcludedDocuments() {
  const id = useIdParam();

  const [sort, tableSort] = useSortTable<DocumentSortField>({
    field: 'caseSeizureDate',
    direction: 'DESC',
  });
  const { pagination, tablePagination } = usePaginationTable();
  const selection = useTableSelection<string>();
  const [error, setError] = useState<Error | null>(null);

  const [removeExcludedDocumentsFromSet] =
    useRemoveExcludedDocumentsFromSetMutation();
  const result = useDocumentSetExcludedDocumentsQuery({
    variables: {
      id,
      sort,
      ...pagination,
    },
  });
  const data = result.data || result.previousData;

  if (result.error) throw result.error;
  if (!data) return null;

  const documentSet = data.documentSet;

  function onClickRemoveExcluded() {
    removeExcludedDocumentsFromSet({
      variables: {
        input: {
          id,
          documents: Array.from(selection.value),
        },
      },
    })
      .then(() => {
        setError(null);
        selection.methods.clear();
        return result.refetch();
      })
      .catch((error) => setError(error));
  }

  return (
    <PageLayout
      title={
        <T
          keyName="doc_set.manage_excluded_documents"
          params={{ count: documentSet.allExcludedDocumentsIds.length }}
        />
      }
      navigation={
        <>
          <PageLayoutNavigation to="/document-sets">
            <T keyName="nav.doc_sets" />
          </PageLayoutNavigation>
          <PageLayoutNavigation to={`/document-sets/${documentSet.id}`}>
            <DocumentSetTitle value={documentSet} />
          </PageLayoutNavigation>
        </>
      }
      actions={
        <>
          <FormattedButton
            as={Link}
            to="./.."
            messageId="global.cancel"
            variant="white"
          />
          <FormattedButton
            messageId="doc_set.list.excluded_documents.actions.remove_excluded"
            messageParams={{ count: selection.value.size }}
            onClick={onClickRemoveExcluded}
          />
        </>
      }
      footerClassName="flex gap-2"
      footer={
        <>
          <FormattedButton
            messageId="global.select_all"
            variant={Variant.secondary}
            size={Size.small}
            onClick={() =>
              selection.methods.set(documentSet.allExcludedDocumentsIds)
            }
          />
          <FormattedButton
            messageId="global.deselect_all"
            variant={Variant.secondary}
            size={Size.small}
            onClick={selection.methods.clear}
          />

          <span className="flex-1" />

          <FormattedButton
            messageId="doc_set.list.excluded_documents.actions.remove_excluded"
            messageParams={{ count: selection.value.size }}
            onClick={onClickRemoveExcluded}
          />
        </>
      }
    >
      <p>
        <DocumentSetType value={documentSet} />
      </p>

      <FormattedErrorAlert error={error} onDismiss={() => setError(null)} />

      <DocumentSetTableDocumentView
        documents={documentSet.excludedDocuments.nodes}
        totalCount={documentSet.excludedDocuments.totalCount}
        tableSort={tableSort}
        tablePagination={tablePagination}
        renderTextPagination={(actual) => (
          <FormattedPaginationText
            page={actual}
            rowsPerPage={tablePagination.itemsPerPage}
            total={documentSet.excludedDocuments.totalCount}
            messageId="doc_set.list.excluded_documents.pagination"
          />
        )}
        renderEmpty={renderEmpty}
        selection={selection}
      />
    </PageLayout>
  );
}

function renderEmpty() {
  return (
    <FormattedEmptyState
      title="doc_set.list.excluded_documents.empty"
      icon={<DocumentDuplicateIcon />}
    />
  );
}
