import HeaderWarning, { WarningMessage } from '../HeaderWarning';

import { GqlAlertDraftQuery } from '#gql';
import { assert } from '#utils/assert';

export default function AlertDraftWarning(props: {
  alert: GqlAlertDraftQuery['alert'];
}) {
  const { alert } = props;
  assert(alert.draft);
  let warning: WarningMessage | null = null;

  if (alert.status === 'ARCHIVED') {
    warning = {
      message: 'series.alert.warning.archived',
    };
  }

  return <HeaderWarning warning={warning} />;
}
