import { match } from 'ts-pattern';

import { FixableImportDataStep } from '../components/scans/importData/utils/types';

import { safeMappedTranslationKey } from './common';

/*
IDLE=Waiting for action
PERFORMING=Script is running
DONE=Script is terminated
 */
type ScanFeedStatus = 'IDLE' | 'PERFORMING' | 'DONE';

export type ScanFeedStep = 'crop' | 'orientation' | 'mrz' | 'find_regions';

export type ScanFeedStepButton = `${FixableImportDataStep}Button`;

export const TRANSLATION_KEY_SCAN_FEED_REVIEW_PERFORMING = {
  ProcessAnalyze: 'scan.step.review.feed.crop.performing',
  ProcessExtract: 'scan.step.review.feed.crop.performing',
  ProcessRotate: 'scan.step.review.feed.orientation.performing',
  ProcessMRZ: 'scan.step.review.feed.mrz.performing',
  ProcessFindRegions: 'scan.step.review.feed.anonymization.performing',
} as const satisfies Record<FixableImportDataStep, TranslationKey>;
export const TRANSLATION_KEY_SCAN_FEED_REVIEW_IDLE = {
  ProcessAnalyze: 'scan.step.review.feed.crop.idle',
  ProcessExtract: 'scan.step.review.feed.crop.idle',
  ProcessRotate: 'scan.step.review.feed.orientation.idle',
  ProcessMRZ: 'scan.step.review.feed.mrz.idle',
  ProcessFindRegions: 'scan.step.review.feed.anonymization.idle',
} as const satisfies Record<FixableImportDataStep, TranslationKey>;

export const TRANSLATION_KEY_SCAN_FEED_REVIEW_DONE = {
  ProcessAnalyze: 'scan.step.review.feed.crop.done',
  ProcessExtract: 'scan.step.review.feed.crop.done',
  ProcessRotate: 'scan.step.review.feed.orientation.done',
  ProcessMRZ: 'scan.step.review.feed.mrz.done',
  ProcessFindRegions: 'scan.step.review.feed.anonymization.done',
} as const satisfies Record<FixableImportDataStep, TranslationKey>;

export const TRANSLATION_KEY_SCAN_FEED_REVIEW_BUTTON = {
  ProcessAnalyzeButton: 'scan.step.review.feed.crop.button',
  ProcessExtractButton: 'scan.step.review.feed.crop.button',
  ProcessRotateButton: 'scan.step.review.feed.orientation.button',
  ProcessMRZButton: 'scan.step.review.feed.mrz.button',
  ProcessFindRegionsButton: 'scan.step.review.feed.anonymization.button',
} as const satisfies Record<ScanFeedStepButton, TranslationKey>;

export function safeTranslationKeyScanFeedStep(
  step: FixableImportDataStep,
  type: ScanFeedStatus,
) {
  return match(type)
    .with('IDLE', () =>
      safeMappedTranslationKey(TRANSLATION_KEY_SCAN_FEED_REVIEW_IDLE, step),
    )
    .with('PERFORMING', () =>
      safeMappedTranslationKey(
        TRANSLATION_KEY_SCAN_FEED_REVIEW_PERFORMING,
        step,
      ),
    )
    .with('DONE', () =>
      safeMappedTranslationKey(TRANSLATION_KEY_SCAN_FEED_REVIEW_DONE, step),
    )
    .exhaustive();
}

export function safeTranslationKeyScanFeedStepButton(
  index: ScanFeedStepButton,
) {
  return safeMappedTranslationKey(
    TRANSLATION_KEY_SCAN_FEED_REVIEW_BUTTON,
    index,
  );
}
