import { useLocale, possibleLocales } from './providers/LocaleProvider.context';

import { Button, Size, Variant } from '#tailwind_ui';

export default function LocalePicker() {
  const [locale, setLocale] = useLocale();

  return (
    <div className="flex flex-row gap-1">
      {possibleLocales.map((possibleLocale) => (
        <Button
          size={Size.xSmall}
          variant={Variant.white}
          key={possibleLocale}
          onClick={() => setLocale(possibleLocale)}
          disabled={locale === possibleLocale}
        >
          {possibleLocale.toUpperCase()}
        </Button>
      ))}
    </div>
  );
}
