import { T } from '@tolgee/react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { Feature } from '../../constants';
import {
  requiredObject,
  requiredString,
  urlString,
} from '../../forms/validation';
import { useNavigateAfterSave } from '../../hooks/useNavigateAfterSave';
import { useSeqIdParam } from '../../hooks/useParams';
import IfHasFeature from '../IfHasFeature';
import BitmapFields from '../form/BitmapFields';
import { FormPreventNavigation } from '../form/FormPreventNavigation';
import PurchaseURLFields from '../form/PurchaseURLFields';
import SelectCountry from '../form/SelectCountry';
import {
  SelectDocMedium,
  SelectDocumentType,
  SelectFraudType,
  SelectPrintProcess,
  SelectRelatedOffense,
  SelectSeizureContext,
} from '../form/SelectFields';
import SelectSeizureAuthority from '../form/SelectSeizureAuthority';
import SeriesFields from '../form/SeriesFields';
import TrueFalseUndefinedField from '../form/TrueFalseUndefinedField';
import FormattedButton from '../translation/FormattedButton';
import FormattedCheckboxField from '../translation/FormattedCheckboxField';
import FormattedDatePickerField from '../translation/FormattedDatePickerField';
import FormattedErrorAlert from '../translation/FormattedErrorAlert';
import FormattedFormError from '../translation/FormattedFormError';
import FormattedInputField from '../translation/FormattedInputField';
import FormattedSubmitButton from '../translation/FormattedSubmitButton';
import FormattedTextAreaField from '../translation/FormattedTextAreaField';

import {
  DocumentEditProps,
  FormUpdateDocumentInput,
} from './DocumentEditInterfaces';

import {
  GqlNewLabelInput,
  useDocumentEditableQuery,
  useUpdateDocumentMutation,
} from '#gql';
import { SelectLabelsNewFields } from '#label';
import { useOnOff } from '#tailwind_ui';
import { CardLayout } from '#ui/card_layout';
import { PageLayout, PageLayoutNavigation } from '#ui/page_layout';
import { getDocumentTitle } from '#utils/index';

const fieldValidation = requiredObject({
  docType: requiredString,
  docFraudType: requiredString,
  docInternetPurchaseURL: urlString,
});

const bodyCardClassName = 'flex flex-col gap-3';

function DocumentEdit(props: DocumentEditProps) {
  const { document } = props;
  const [saved, setSaved] = useOnOff();
  useNavigateAfterSave(saved, `/documents/${document.seqId}`);

  const [updateDocument] = useUpdateDocumentMutation();

  const {
    // Exclude fields that are not editable.
    __typename,
    seqId,
    caseService,
    caseExhibitNumber,
    caseNumber,
    caseTitle,
    images,
    ...documentValues
  } = document;

  const defaultValues: FormUpdateDocumentInput = {
    ...documentValues,
    caseSeizureAuthority: document.caseSeizureAuthority
      ? document.caseSeizureAuthority.identifier
      : null,
    series: document.series ? document.series.id : null,
    labels: document.labels.map((l) => l.id),
  };

  async function onSubmit(formData: FormUpdateDocumentInput) {
    if (!formData.docIsPurchasedOnInternet) {
      formData.docInternetPurchaseURL = null;
    }
    if (!formData.docIsBitmapCode || !formData.docIsBitmapCodeAnalyzed) {
      formData.docIsBitmapCodeAnalyzed = null;
      formData.docBitmapCodeAnalysis = null;
    }

    const labels: string[] = [];
    const newLabels: GqlNewLabelInput[] = [];

    for (const label of formData.labels) {
      if (typeof label === 'string') {
        labels.push(label);
      } else if (typeof label === 'object') {
        // remap because of SelectLabelsNewFields using _guid attribute for internal operating
        newLabels.push({
          name: label.name,
          description: label.description,
          color: label.color,
          docType: label.docType,
          docFraudType: label.docFraudType,
          docCountry: label.docCountry,
        });
      }
    }

    await updateDocument({
      variables: {
        input: {
          ...formData,
          labels,
          newLabels,
        },
      },
    });

    setSaved();
  }

  return (
    <FormPreventNavigation<FormUpdateDocumentInput>
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      validationSchema={fieldValidation}
      noDefaultStyle
    >
      <PageLayout
        title={<T keyName="page.doc.view.describe" />}
        navigation={
          <>
            <PageLayoutNavigation to="/documents">
              <T keyName="nav.documents.list" />
            </PageLayoutNavigation>
            <PageLayoutNavigation to={`/documents/${seqId}`}>
              {seqId} - {getDocumentTitle(document, true)}
            </PageLayoutNavigation>
          </>
        }
        actions={<EditButtons />}
        footer={<EditButtons />}
        bodyClassName="grid grid-cols-1 gap-y-2 gap-x-5 md:grid-cols-2"
        noDefaultBodyClassName
      >
        <CardLayout
          title="page.doc.view.seizure_information"
          bodyClassName={bodyCardClassName}
        >
          <FormattedDatePickerField
            className="flex flex-1 flex-col"
            name="caseSeizureDate"
            label="doc.field.seizure_date"
          />
          <FormattedInputField
            name="caseSeizurePlace"
            label="doc.field.seizure_place"
          />
          <SelectSeizureAuthority name="caseSeizureAuthority" />
          <SelectSeizureContext name="caseSeizureContext" clearable />
          <SelectRelatedOffense name="caseRelatedOffense" clearable />
          <FormattedInputField
            name="caseOperation"
            label="doc.field.operation"
          />
          <SelectCountry
            name="casePresumedNationality"
            label="doc.field.presumed_nationality"
            clearable
          />
        </CardLayout>

        <CardLayout
          title="page.doc.view.case_information"
          bodyClassName={bodyCardClassName}
        >
          <FormattedTextAreaField
            name="caseInformation"
            label="doc.field.information"
            rows={5}
          />
          <FormattedCheckboxField
            name="caseIsDocKept"
            label="doc.field.is_kept"
          />
          <PurchaseURLFields />

          <FormattedCheckboxField
            name="caseIsProfiling"
            label="doc.field.is_profiling"
          />

          <IfHasFeature feature={Feature.series}>
            <SeriesFields />
          </IfHasFeature>

          <SelectLabelsNewFields name="labels" label="doc.field.labels" />
        </CardLayout>

        <CardLayout
          title="page.doc.view.general_information"
          bodyClassName={bodyCardClassName}
        >
          <SelectDocumentType name="docType" required />
          <SelectFraudType name="docFraudType" required />
          <SelectCountry name="docCountry" label="doc.field.country" required />
          <FormattedInputField
            name="docSerialNumber"
            label="doc.field.document_number"
          />
          <FormattedInputField
            name="docEmissionLocation"
            label="doc.field.emission_location"
          />
        </CardLayout>

        <CardLayout
          title="page.doc.view.doc_analysis"
          bodyClassName={bodyCardClassName}
        >
          <TrueFalseUndefinedField
            name="docIsMrzOk"
            label="doc.mrz"
            trueLabel="global.complying"
            falseLabel="global.noncomplying"
          />
          <FormattedCheckboxField
            name="docIsReported"
            label="doc.field.is_reported"
          />
          <SelectDocMedium name="docMedium" clearable />

          <SelectPrintProcess
            name="docPrintBackground"
            label="doc.field.print_background"
            clearable
          />
          <SelectPrintProcess
            name="docPrintNumber"
            label="doc.field.print_number"
            clearable
          />
          <SelectPrintProcess
            name="docPrintData"
            label="doc.field.print_data"
            clearable
          />
          <SelectPrintProcess
            name="docPrintPhoto"
            label="doc.field.print_photo"
            clearable
          />

          <TrueFalseUndefinedField
            name="docIsBackgroundFluo"
            label="global.background"
            trueLabel="global.fluorescent"
            falseLabel="global.nonfluorescent"
          />
          <BitmapFields />
          <FormattedTextAreaField
            name="docOther"
            label="doc.field.other"
            rows={3}
          />
        </CardLayout>

        <FormattedFormError className="col-span-2" />
      </PageLayout>
    </FormPreventNavigation>
  );
}

export default function DocumentEditFormProvider() {
  const seqId = useSeqIdParam();

  const { data, error } = useDocumentEditableQuery({
    variables: { seqId },
  });

  if (error) return <FormattedErrorAlert error={error} />;
  if (!data) return null;

  return <DocumentEdit document={data.document} />;
}

interface EditButtonsProps {
  className?: string;
}

function EditButtons(props: EditButtonsProps) {
  const { className } = props;

  return (
    <div className={clsx('flex items-center gap-2', className)}>
      <FormattedButton
        as={Link}
        to="./.."
        messageId="global.cancel"
        variant="white"
      />
      <FormattedSubmitButton messageId="global.save" color="primary" />
    </div>
  );
}
