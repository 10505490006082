import { T } from '@tolgee/react';
import { Link } from 'react-router-dom';

// Title is complex.
// eslint-disable-next-line no-restricted-imports
import { Alert } from '#tailwind_ui';

export interface WarningMessage {
  message: TranslationKey;
  link?: {
    message: TranslationKey;
    url: string;
  } | null;
}

export default function HeaderWarning(props: {
  warning: WarningMessage | null;
}) {
  const warning = props.warning;
  if (!warning) {
    return null;
  }

  const title = (
    <div className="flex items-center justify-between">
      <div>
        <T keyName={warning.message} />
      </div>
      {warning.link && (
        <Link to={warning.link.url} className="font-bold">
          <T keyName={warning.link.message} /> →
        </Link>
      )}
    </div>
  );
  return (
    <Alert type="info" className="print:hidden">
      {title}
    </Alert>
  );
}
