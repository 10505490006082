import { TrashIcon } from '@heroicons/react/20/solid';
import { T } from '@tolgee/react';
import { Link } from 'react-router-dom';

import FormattedButton from '../translation/FormattedButton';
import FormattedConfirmDialog from '../translation/FormattedConfirmDialog';

import { GqlPadifAnalysisQuery } from '#gql';
import { IconButton, useOnOff } from '#tailwind_ui';

interface PadifShowMainRoisProps {
  rois: GqlPadifAnalysisQuery['padifAnalysis']['rois'];
  onRemoveRoi: (roi: string) => void;
}

export default function PadifShowMainRois(props: PadifShowMainRoisProps) {
  const { rois, onRemoveRoi } = props;

  return (
    <div>
      {rois.length > 0 && (
        <div className="m-2">
          <div className="inline-block">
            <T keyName="page.padif.title.rois" />
            {':'}
          </div>
          <div className="inline-block">
            {rois.map((roi) => (
              <Roi key={roi.id} roi={roi} onRemove={onRemoveRoi} />
            ))}
          </div>
        </div>
      )}
      <Link to="roi/new">
        <FormattedButton messageId="page.padif.title.new_roi" />
      </Link>
    </div>
  );
}

interface RoiProps {
  roi: GqlPadifAnalysisQuery['padifAnalysis']['rois'][number];
  onRemove: (id: string) => void;
}

function Roi(props: RoiProps) {
  const { roi, onRemove } = props;
  const [isOpen, open, close] = useOnOff(false);

  function onConfirm() {
    onRemove(roi.id);
    close();
  }

  return (
    <div className="m-2 inline-block w-[60px] rounded border border-neutral-800 p-1">
      <FormattedConfirmDialog
        open={isOpen}
        title="page.padif.title.delete_roi.question"
        confirmText="global.delete"
        onCancel={close}
        onConfirm={onConfirm}
      />
      <div className="flex items-center justify-around">
        <div
          style={{
            backgroundColor: roi.color,
            borderRadius: '50%',
            width: '1em',
            height: '1em',
          }}
        />
        {` | `}
        <IconButton size="5" icon={<TrashIcon />} onClick={open} />
      </div>
    </div>
  );
}
