import { ReactNode } from 'react';
import { match } from 'ts-pattern';

import { LoginPage, LostLoginModal } from './Login';
import FormattedAlertMessage from './error/FormattedAlertMessage';
import { useLoginState } from './providers/LoginProvider.context';

export default function MustLogin({ children }: { children: ReactNode }) {
  const loginState = useLoginState();

  return match(loginState)
    .with({ status: 'initial' }, () => null) // Do nothing until we get a response from the initial session query.
    .with({ status: 'unauthenticated' }, () => <LoginPage />)
    .with({ status: 'error' }, () => (
      <FormattedAlertMessage message="error.INTERNAL_SERVER_ERROR" />
    ))
    .with({ status: 'lostSession' }, ({ data: { email } }) => (
      <>
        {children}
        <LostLoginModal email={email} />
      </>
    ))
    .with({ status: 'errorLostSession' }, () => (
      <>
        <FormattedAlertMessage message="error.INTERNAL_SERVER_ERROR" />
        {children}
      </>
    ))
    .with({ status: 'authenticated' }, () => children)
    .exhaustive();
}
