import FormattedConfirmDialog from '../translation/FormattedConfirmDialog';

import { FormattedAlert } from '#intl';

interface DeleteSerieWarnHasAlertModalProps {
  onRequestClose: () => void;
  serieName: string;
}

export default function DeleteSerieWarnHasAlertModal(
  props: DeleteSerieWarnHasAlertModalProps,
) {
  return (
    <FormattedConfirmDialog
      title="series.delete.title"
      titleValues={{ name: props.serieName }}
      color="danger"
      confirmProps={{ disabled: true }}
      confirmText="global.delete"
      open
      onCancel={props.onRequestClose}
      body={
        <FormattedAlert
          type="warning"
          title="series.delete.warn_has_alert.title"
          messageId="series.delete.warn_has_alert.body"
        />
      }
    />
  );
}
