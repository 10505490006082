import { useTolgee } from '@tolgee/react';
import { useEffect } from 'react';

export function useTolgeeCacheLoad(
  language: string | undefined,
  namespace?: 'alert' | string,
) {
  const tolgee = useTolgee();

  useEffect(() => {
    if (!language) return;
    tolgee.loadRecord({ language, namespace }).catch(reportError);
  }, [tolgee, language, namespace]);
}
