import { PencilIcon } from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';

import {
  optionalString,
  requiredBoolean,
  requiredObject,
} from '../../../forms/validation';
import SelectService from '../../form/SelectService';
import { useCheckedLoginState } from '../../providers/LoginProvider.context';
import FormattedButton from '../../translation/FormattedButton';
import FormattedCheckboxField from '../../translation/FormattedCheckboxField';
import FormattedFormError from '../../translation/FormattedFormError';
import FormattedSubmitButton from '../../translation/FormattedSubmitButton';
import FormattedUserName from '../../translation/FormattedUserName';

import { GqlUsersQuery, useChangeUserProtectedFieldsMutation } from '#gql';
import { Color, FormRHFModal, useNotificationCenter } from '#tailwind_ui';

interface EditUserValues {
  isDisabled: boolean;
  service: string | null;
}

const validationSchema = requiredObject({
  isDisabled: requiredBoolean,
  service: optionalString,
});

export default function ChangeUserProtectedFieldsModal({
  user,
  onCancel,
}: {
  user: GqlUsersQuery['users']['nodes'][number];
  onCancel: () => void;
}) {
  const login = useCheckedLoginState();
  const [changeUserProtectedFields] = useChangeUserProtectedFieldsMutation();
  const { addNotification } = useNotificationCenter();

  return (
    <FormRHFModal<EditUserValues>
      isOpen
      fluid={false}
      onRequestClose={onCancel}
      icon={<PencilIcon />}
      defaultValues={{
        isDisabled: user.isDisabled,
        service: user.service ? user.service.identifier : null,
      }}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        await changeUserProtectedFields({
          variables: {
            input: {
              id: user.id,
              service: values.service || null,
              isDisabled: values.isDisabled,
            },
          },
        });
        addNotification({
          type: 'success',
          title: <T keyName="page.users.update.success" />,
        });
        onCancel();
      }}
    >
      <FormRHFModal.Header>
        <T keyName="page.users.action.edit_user" />
      </FormRHFModal.Header>
      <FormRHFModal.Body>
        <div>
          <T keyName="global.user" />
          {`: `}
          <FormattedUserName values={user} />
        </div>
        <FormattedCheckboxField
          name="isDisabled"
          label="user.status.DISABLED"
          disabled={login.id === user.id}
        />

        <SelectService clearable name="service" />

        <FormattedFormError />
      </FormRHFModal.Body>
      <FormRHFModal.Footer>
        <FormattedButton
          messageId="global.cancel"
          variant="white"
          onClick={onCancel}
        />
        <FormattedSubmitButton
          color={Color.primary}
          messageId="global.change"
        />
      </FormRHFModal.Footer>
    </FormRHFModal>
  );
}
