import { Image } from 'image-js';

import { ScanImportRoi } from './types';

/**
 * Fill with a black rectangle roi in image in-place
 *
 * @param image
 * @param roi
 */
export function fillImage(image: Image, roi: ScanImportRoi): void {
  const origin = {
    column: roi.x,
    row: roi.y,
  };
  const fill = new Image(roi.width, roi.height, {
    colorModel: image.colorModel,
  });
  fill.copyTo(image, { origin, out: image });
}
