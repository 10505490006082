import { T } from '@tolgee/react';

import { FormattedTable } from '../../../../../components/translation/FormattedTable';
import { useDocumentsContext } from '../../../../../contexts/documents';

import { BaseStat, DocStatsData } from './get_stats';

import { Td } from '#tailwind_ui';
import { HighlightedLink } from '#ui/link';
import { assert } from '#utils/assert';

export default function ServiceStats({ stats }: { stats: DocStatsData }) {
  const { store } = useDocumentsContext();

  return (
    <div className="relative mb-3 flex w-full overflow-x-auto p-2">
      <div className="m-auto">
        <FormattedTable<DocStatsData>
          getId={(data) => data.countInSerie}
          data={[stats]}
          renderTr={renderTr}
          renderHeader={() => renderHeader(stats.services, store.reset)}
        />
      </div>
    </div>
  );
}
function renderHeader(services: BaseStat[], resetFilters: () => void) {
  return (
    <tr className="bg-neutral-50">
      <Td className="sticky -left-2 bg-inherit font-bold">
        <T keyName="global.service" />
      </Td>
      {services.map((service) => {
        assert(service.name);

        return (
          <Td key={service.name} className="text-center">
            <HighlightedLink
              onClick={resetFilters}
              to={`/documents?caseService=${service.name}`}
            >
              {service.name}
            </HighlightedLink>
          </Td>
        );
      })}
      <Td className="sticky -right-2 bg-inherit text-center font-bold">
        <T keyName="global.total" />
      </Td>
    </tr>
  );
}

function renderTr(stats: DocStatsData) {
  return (
    <tr>
      <Td className="sticky -left-2 bg-white font-bold">
        <T keyName="global.cases" />
      </Td>
      {stats.services.map((service) => (
        <Td key={service.name} className="px-5 py-1">
          <div className="flex h-full flex-1 flex-col items-center">
            <span>{service.value}</span>
            <span>{`(${service.percent}%)`}</span>
          </div>
        </Td>
      ))}
      <Td className="sticky -right-2 bg-white px-5 py-1 text-center font-bold">
        {stats.total}
      </Td>
    </tr>
  );
}
