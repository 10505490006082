import { T } from '@tolgee/react';

import { possibleLocales } from '../../providers/LocaleProvider.context';
import EditableCell from '../EditableCell';

import { GqlServicesListQuery, Locale, useUpdateServiceMutation } from '#gql';
import { Color, Td, useNotificationCenter } from '#tailwind_ui';

interface AdminServiceRowProps {
  service: GqlServicesListQuery['services']['nodes'][number];
}

export default function AdminServiceRow(props: AdminServiceRowProps) {
  const { service } = props;
  const [updateService] = useUpdateServiceMutation();
  const { addNotification } = useNotificationCenter();

  function mutate(newValue: string, locale: Locale) {
    updateService({
      variables: { input: { id: service.id, locale, name: newValue } },
    }).catch((error) => {
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        for (const graphQLError of error.graphQLErrors as Error[]) {
          addNotification({
            type: Color.danger,
            title: <T keyName={graphQLError.message as TranslationKey} />,
          });
        }
      } else {
        addNotification({
          type: Color.danger,
          title: <T keyName="global.error.unknown" />,
        });
      }
    });
  }

  return (
    <tr>
      <Td>{service.identifier}</Td>
      {possibleLocales.map((locale) => {
        const localeUp = (locale[0].toUpperCase() +
          locale.slice(1)) as Capitalize<Locale>;
        return (
          <EditableCell
            key={locale}
            value={service[`name${localeUp}`]}
            hasValue={service[`hasTranslation${localeUp}`]}
            mutate={mutate}
            locale={locale}
          />
        );
      })}
    </tr>
  );
}
