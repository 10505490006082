import { createContext, useContext } from 'react';

import { AppEnv, AppVariant, Feature } from '../../constants';

export const appMetaContext = createContext<AppMeta | null>(null);

export interface AppMeta {
  env: AppEnv;
  title: string;
  titleLong: string;
  variant: AppVariant;
  features: Feature[];
  sessionTimeout: number;
}

export function useAppMeta() {
  const appMeta = useContext(appMetaContext);
  if (!appMeta) {
    throw new Error('app meta context not initialized');
  }
  return appMeta;
}
