import { useApolloClient } from '@apollo/client';
import { UserIcon } from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useLogout } from '../providers/LoginProvider.context';

import { Dropdown, DropdownActionOption } from '#tailwind_ui';

export default function UserDropdown() {
  const navigate = useNavigate();
  const logout = useLogout();
  const apollo = useApolloClient();

  const options = useMemo<
    Array<Array<DropdownActionOption<{ url?: string; action?: string }>>>
  >(() => {
    return [
      [
        {
          type: 'action',
          label: <T keyName="nav.change_password" />,
          data: {
            url: '/user/change-password',
          },
        },
        {
          type: 'action',
          label: <T keyName="nav.logout" />,
          data: {
            action: 'logout',
          },
        },
      ],
    ];
  }, []);

  function onSelect(
    option: DropdownActionOption<{ url?: string; action?: string }>,
  ) {
    if (option.data?.url) {
      return navigate(option.data?.url);
    }

    logout(apollo);
  }

  return (
    <Dropdown
      options={options}
      onSelect={onSelect}
      buttonClassName="flex rounded-sm focus:ring-2 ring-primary-600 text-neutral-700"
      noDefaultButtonStyle
    >
      <UserIcon className="h-5 w-5" />
    </Dropdown>
  );
}
