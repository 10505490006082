import { useTranslate } from '@tolgee/react';

import { safeMappedTranslationKey } from '../../translationsMapping/common';

import FacetFilterSelectList, {
  FacetFilterSelectListProps,
} from './FacetFilterSelectList';

import { assertNotNull } from '#utils/assert';

interface FacetGraphqlEnumFilterSelectListPropsBase<T extends string> {
  name: string;
  enumValues: T[];
  translationMap: Record<T, TranslationKey>;
}

type FacetGraphqlEnumFilterSelectListProps<
  T extends string,
  Filter,
  Prop extends keyof Filter,
> = FacetGraphqlEnumFilterSelectListPropsBase<T> &
  Omit<
    FacetFilterSelectListProps<Filter, Prop>,
    'displayLookup' | 'optionLookup'
  >;

export default function FacetGraphqlEnumFilterSelectList<
  T extends string,
  Filter,
  Prop extends keyof Filter,
>(props: FacetGraphqlEnumFilterSelectListProps<T, Filter, Prop>) {
  const { name, enumValues, translationMap, ...rest } = props;
  assertNotNull(props.value);

  const { t } = useTranslate();

  const lookup: Record<string, string> = {};
  for (const enumValue of enumValues) {
    lookup[enumValue] = t(safeMappedTranslationKey(translationMap, enumValue));
  }

  return (
    <FacetFilterSelectList
      name={name}
      {...rest}
      displayLookup={lookup}
      optionLookup={lookup}
    />
  );
}
