import { TrashIcon as TrashIconSolid } from '@heroicons/react/20/solid';
import {
  TrashIcon as TrashIconOutline,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';
import { SCAN_PROCESS_STATUSES_DELETABLE } from '@zakodium/profid-shared';
import { FormattedDate } from 'react-intl';
import { Link } from 'react-router-dom';
import { match } from 'ts-pattern';

import { useScanContext } from '../../contexts/scan';
import { useSyncTableSearchParams } from '../../hooks/useSyncTableSearchParams';
import { IndexFilterError } from '../TableSearch/IndexFilterError';
import { TableSearchLayout } from '../TableSearch/TableSearchLayout';
import DocumentLink from '../document/DocumentLink';
import IfHasRole from '../permissions/IfHasRole';
import { useCheckedLoginState } from '../providers/LoginProvider.context';
import FormattedButton from '../translation/FormattedButton';
import FormattedConfirmDialog from '../translation/FormattedConfirmDialog';
import FormattedEmptyState from '../translation/FormattedEmptyState';
import { FormattedTable } from '../translation/FormattedTable';

import ScanAdmin from './ScanAdmin';
import ScanSelectStatuses from './ScanSelectStatuses';
import ScanStatusBadge from './ScanStatusBadge';
import { getScanStatusBadgeColor } from './ScanStatusBadge.utils';
import ScanUserSelector from './ScanUserSelector';

import { GqlScansQuery, useDeleteScanMutation, useScansQuery } from '#gql';
import {
  IconButton,
  SimpleListContainer,
  SimpleListContainerItem,
  Td,
  Th,
  useNotificationCenter,
} from '#tailwind_ui';
import { HighlightedLink } from '#ui/link';

interface ScanTableTableProps {
  data?: GqlScansQuery;
  deleteScan: (scan: Scan) => void;
}

interface ScanTableRowProps {
  row: Scan;
  deleteScan: (scan: Scan) => void;
  displayActionsColumn: boolean;
}

type Scan = GqlScansQuery['scans']['nodes'][number];

export default function ScanTable() {
  const context = useScanContext();
  const currentUser = useCheckedLoginState();
  const [deleteScan] = useDeleteScanMutation({
    refetchQueries: ['scans'],
  });
  const { addToastNotification, addNotification } = useNotificationCenter();

  const ownership = context.filters.ownership ?? 'mine';
  const queryResult = useScansQuery({
    variables: {
      ...context.pagination,
      filterBy: {
        searchTerm: context.filters.searchTerm,
        statuses: context.filters.statuses,
        startDate: context.filters.startDate,
        endDate: context.filters.endDate,
        createdBy: ownership === 'mine' ? currentUser.id : null,
      },
      sortBy: context.sort,
    },
    pollInterval: 5000,
  });
  const data = queryResult.data || queryResult.previousData;

  useSyncTableSearchParams(context);

  if (queryResult.error) {
    return <IndexFilterError error={queryResult.error} context={context} />;
  }

  function deleteScanAction(scan: Scan) {
    deleteScan({ variables: { input: { id: scan.id } } })
      .then(() => {
        addToastNotification({
          label: (
            <T
              keyName="scan.list.actions.delete.notification.success"
              params={{ id: scan.id }}
            />
          ),
        });
      })
      .catch((error) => {
        reportError(error);
        addNotification({
          type: 'warning',
          icon: <XMarkIcon className="h-5" />,
          title: (
            <T
              keyName="scan.list.actions.delete.notification.error"
              params={{ id: scan.id }}
            />
          ),
          content: error.message,
        });
      });
  }

  return (
    <TableSearchLayout
      context={context}
      advancedFilter={
        <SimpleListContainer>
          <SimpleListContainerItem className="space-y-2">
            <ScanSelectStatuses />
            <ScanUserSelector />
          </SimpleListContainerItem>
        </SimpleListContainer>
      }
    >
      <ScanTableTable data={data} deleteScan={deleteScanAction} />
    </TableSearchLayout>
  );
}

function renderHeader(displayActionsColumn: boolean) {
  return (
    <tr>
      <Th className="w-[200px]">
        <T keyName="scans.list.status" />
      </Th>
      <Th sortField="filename">
        <T keyName="global.image" />
      </Th>
      <Th>
        <T keyName="global.exhibit" />
      </Th>
      <Th>
        <T keyName="scans.list.uploader" />
      </Th>
      <Th className="w-[200px]" sortField="createdAt">
        <T keyName="scans.list.creation_date" />
      </Th>
      {displayActionsColumn && (
        <Th>
          <T keyName="scans.list.actions" />
        </Th>
      )}
      <IfHasRole userRole="admin">
        <Th>
          <T keyName="global.admin" />
        </Th>
      </IfHasRole>
    </tr>
  );
}

function ScanTableTable({ data, deleteScan }: ScanTableTableProps) {
  const context = useScanContext();

  if (!data) return null;
  const { nodes: scans, totalCount } = data.scans;

  const displayActionsColumn = scans.some((scan) =>
    SCAN_PROCESS_STATUSES_DELETABLE.has(scan.status),
  );

  return (
    <FormattedTable<Scan>
      data={scans}
      renderHeader={() => renderHeader(displayActionsColumn)}
      renderTr={(row) => (
        <ScanTableRow
          key={row.id}
          row={row}
          deleteScan={deleteScan}
          displayActionsColumn={displayActionsColumn}
        />
      )}
      renderEmpty={renderEmpty}
      pagination={{
        ...context.tablePagination,
        totalCount,
      }}
      sort={context.tableSort}
    />
  );
}

function ScanTableRow({
  row,
  deleteScan,
  displayActionsColumn,
}: ScanTableRowProps) {
  return (
    <tr>
      <Td>
        {match(row.status)
          .with('TO_BE_REVIEWED', () => (
            <FormattedButton
              size="xSmall"
              className="!py-1"
              as={Link}
              to={`/scans/${row.id}/import`}
              messageId="scans.list.status.cta.import"
              color={getScanStatusBadgeColor(row.status)}
            />
          ))
          .otherwise(() => (
            <ScanStatusBadge status={row.status} />
          ))}
      </Td>
      <Td className="font-bold">
        <HighlightedLink to={`/scans/${row.id}`}>
          {row.filename}
        </HighlightedLink>
      </Td>
      <Td>
        {row.document && (
          <DocumentLink document={row.document} multiline includeService />
        )}
      </Td>
      <Td>{row.createdBy.name}</Td>
      <Td>
        <FormattedDate value={row.createdAt} />
      </Td>
      {displayActionsColumn && (
        <Td>
          {match(row.status)
            .when(
              (status) => SCAN_PROCESS_STATUSES_DELETABLE.has(status),
              () => <DeleteScanModalButton deleteScan={deleteScan} row={row} />,
            )

            .otherwise(() => null)}
        </Td>
      )}

      <IfHasRole userRole="admin">
        <Td>
          <ScanAdmin scan={row} />
        </Td>
      </IfHasRole>
    </tr>
  );
}
function DeleteScanModalButton(
  props: Pick<ScanTableRowProps, 'row' | 'deleteScan'>,
) {
  const { deleteScan, row } = props;

  return (
    <FormattedConfirmDialog
      trigger={
        <IconButton
          tooltip={<T keyName="scan.delete" />}
          size="5"
          icon={<TrashIconSolid />}
        />
      }
      title="scan.delete.dialog.title"
      titleValues={{ filename: row.filename }}
      confirmText="scan.delete"
      body="scan.delete.dialog.body"
      color="danger"
      icon={<TrashIconOutline />}
      onConfirm={() => deleteScan(row)}
    />
  );
}

function renderEmpty() {
  return <FormattedEmptyState title="scans.list.no_items" />;
}
