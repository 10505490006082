import { TrashIcon } from '@heroicons/react/20/solid';
import {
  InformationCircleIcon,
  MagnifyingGlassIcon,
  MegaphoneIcon,
  PencilIcon,
  RectangleGroupIcon,
} from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';
import clsx from 'clsx';
import { ReactNode } from 'react';

import { useHasPermission } from '../../hooks/permissions';
import ImgLink from '../ImgLink';

import AlertDocumentHeader from './AlertDocumentHeader';
import Stamp from './Stamp';
import DeleteRapidDetectionImageModal from './modals/DeleteRapidDetectionImageModal';
import EditRapidDetectionImageModal from './modals/EditRapidDetectionImageModal';
import {
  DraftOrPublicationAlert,
  getRapidDetectionImage,
  getTranslation,
  isAlertDraft,
} from './utils/draftOrPublication';

import { Language } from '#gql';
import { Button, RichTextRenderer, useOnOff } from '#tailwind_ui';
import { EmptyStateButton } from '#ui/button';
import { H2, H4 } from '#ui/heading';
import { CompleteOverlay } from '#ui/overlay';
import { assert } from '#utils/assert';

export default function AlertDocument(props: {
  alert: DraftOrPublicationAlert;
}) {
  const isDraft = isAlertDraft(props.alert);
  const translation = getTranslation(props.alert);

  const { general, measures, rapidIdentificationDescription } =
    translation.fields;

  return (
    <section className="space-y-2">
      <AlertDocumentHeader alert={props.alert} />

      <H2 className="bg-blue-950 p-4 text-center !text-2xl uppercase text-yellow-500">
        {translation?.fields.title}
        <br />
        <T
          keyName="series.alert.display.generated-title.second-line"
          language={translation?.language}
          params={{
            name: props.alert.serie.name,
          }}
        />
      </H2>

      <Article
        icon={<InformationCircleIcon className="h-5" />}
        title={
          <T
            keyName="series.alert.field.general"
            language={translation?.language}
            ns="alert"
          />
        }
        content={general && <RichTextRenderer state={general} />}
        language={translation?.language}
        isDraft={isDraft}
      />

      <Article
        icon={<MagnifyingGlassIcon className="h-5" />}
        title={
          <T
            keyName="series.alert.field.rapid_identification_elements"
            language={translation?.language}
            ns="alert"
          />
        }
        language={translation?.language}
        isDraft={isDraft}
        content={
          rapidIdentificationDescription && (
            <RichTextRenderer state={rapidIdentificationDescription} />
          )
        }
      >
        <RapidDetectionImage alert={props.alert} />
      </Article>

      <Article
        icon={<MegaphoneIcon className="h-5" />}
        title={
          <T
            keyName="series.alert.field.measures"
            language={translation?.language}
            ns="alert"
          />
        }
        language={translation?.language}
        content={measures && <RichTextRenderer state={measures} />}
        isDraft={isDraft}
      />
    </section>
  );
}

interface ArticleProps {
  title: ReactNode;
  icon?: ReactNode;
  content: ReactNode;
  children?: ReactNode;
  isDraft: boolean;
  language: Language | undefined;
}
function Article({
  title,
  icon,
  isDraft,
  content,
  children,
  language,
}: ArticleProps) {
  return (
    <article className="break-inside-avoid border-2 border-solid border-neutral-400">
      <H4 className="flex items-center gap-2 border-b-2 border-neutral-400 px-4 py-1 text-blue-950">
        {icon} {title}
      </H4>
      <div className="space-y-2">
        {children}
        <div className={clsx('relative', isDraft && 'min-h-[140px]')}>
          {content}
          {isDraft && (
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 -rotate-12">
              <div>
                <Stamp size="xl">
                  <T keyName="global.draft" language={language} />
                </Stamp>
              </div>
            </div>
          )}
        </div>
      </div>
    </article>
  );
}

function RapidDetectionImage(props: { alert: DraftOrPublicationAlert }) {
  const isDraft = isAlertDraft(props.alert);
  const draft = props.alert.draft;
  const rapidIdentificationImage = getRapidDetectionImage(props.alert);
  const translation = getTranslation(props.alert);
  const hasAlertUpdatePermission = useHasPermission('alert_update');
  const [
    showDeleteDetectionImageModal,
    openDeleteDetectionImageModal,
    closeDeleteDetectionImageModal,
  ] = useOnOff();

  const [
    showUpdateDetectionImageModal,
    openUpdateDetectionImageModal,
    closeUpdateDetectionImageModal,
  ] = useOnOff();

  const imageNode = (() => {
    if (rapidIdentificationImage) {
      const img = (
        <ImgLink
          src={rapidIdentificationImage.url}
          alt="Rapid Detection"
          imgClassName="block mx-auto max-h-[320px]"
        />
      );
      if (isDraft) {
        assert(draft);
        if (hasAlertUpdatePermission) {
          const actions = (
            <div className="flex gap-2 self-end">
              <Button
                className="pointer-events-auto"
                roundness="circular"
                color="primary"
                onClick={() => openUpdateDetectionImageModal()}
              >
                <PencilIcon className="h-5 w-5" />
              </Button>
              <Button
                className="pointer-events-auto"
                roundness="circular"
                color="danger"
                onClick={() => openDeleteDetectionImageModal()}
              >
                <TrashIcon className="h-5 w-5" />
              </Button>
            </div>
          );
          return (
            <div className="group static">
              <div className="relative mx-3 min-h-[200px] break-inside-avoid">
                {img}
                <CompleteOverlay>
                  <T
                    keyName="series.alert.field.rapid_identification_image"
                    language={translation?.language}
                    ns="alert"
                  />
                  <div className="flex-1" />
                  {actions}
                </CompleteOverlay>
              </div>
            </div>
          );
        } else {
          return img;
        }
      } else {
        return img;
      }
    } else if (isDraft && hasAlertUpdatePermission) {
      return (
        <div
          className="p-3 print:hidden"
          onClick={() => {
            openUpdateDetectionImageModal();
          }}
        >
          <EmptyStateButton
            icon={<RectangleGroupIcon />}
            description="Add a rapid detection image"
          />
        </div>
      );
    }
  })();

  return (
    <>
      {imageNode}
      {draft && (
        <>
          <EditRapidDetectionImageModal
            isOpen={showUpdateDetectionImageModal}
            onClose={closeUpdateDetectionImageModal}
            id={props.alert.id}
            draftId={draft.id}
          />
          <DeleteRapidDetectionImageModal
            isOpen={showDeleteDetectionImageModal}
            onClose={closeDeleteDetectionImageModal}
            id={props.alert.id}
            draftId={draft.id}
          />
        </>
      )}
    </>
  );
}
