import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { ReactNode } from 'react';

import FormattedEmptyState from '../../components/translation/FormattedEmptyState';

export interface ObjectLinkFallbackProps {
  /**
   * The link to the displayed object
   */
  src: string;
  /**
   * The link to the source of the object
   * @default `src`
   */
  srcLink?: string;
  alt: string;

  /**
   * bound as fallback if `<object data=src` cannot load
   * @default <DefaultFallback />
   * */
  children?: ReactNode;
}

export function ObjectLinkFallback(props: ObjectLinkFallbackProps) {
  const { src, alt, children, srcLink } = props;

  return (
    <a href={srcLink || src} target="_blank" rel="noopener noreferrer">
      <object
        data={src}
        aria-description={alt}
        className="pointer-events-none max-w-full"
      >
        {children || <DefaultFallback />}
      </object>
    </a>
  );
}

function DefaultFallback() {
  return (
    <FormattedEmptyState
      icon={<ExclamationCircleIcon />}
      title="global.object_fallback.title"
      subtitle="global.object_fallback.subtitle"
    />
  );
}
