import { createContext, useContext } from 'react';

import { TableSearchContext } from '../components/providers/TableSearchContextProvider';
import {
  DocumentSetDocumentFilters,
  DocumentSetDocumentFiltersStore,
} from '../stores/searches/DocumentSetDocumentFiltersStore';

import { DocumentSortField } from '#gql';

export const documentSetDynamicDocumentContext =
  createContext<TableSearchContext<
    DocumentSortField,
    DocumentSetDocumentFilters,
    DocumentSetDocumentFiltersStore
  > | null>(null);

export function useDocumentSetDynamicDocumentContext() {
  const context = useContext(documentSetDynamicDocumentContext);

  if (context === null) {
    throw new Error(
      'useDocumentSetDynamicDocumentContext must be used within a TableSearchContextProvider',
    );
  }

  return context;
}
