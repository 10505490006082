import { T, useTranslate } from '@tolgee/react';
import React, { useMemo } from 'react';
import { match } from 'ts-pattern';

import { DocumentImageLink } from '../../../components/document/DocumentLink';
import { StateNotCompatibleFormattedAlert } from '../../../components/scans/importData/utils/StateNotCompatibleFormattedAlert';
import { UserChangesMeta } from '../../../components/user/UserChangesMeta';
import { useScanIdParam } from '../../../hooks/useParams';

import { scanIdContext } from './scan_import_page/contexts/scan_id_context';
import { ScanImportProcess } from './scan_import_page/scan_import_process';
import { ScanRoiImportErrorAlert } from './scan_import_page/scan_roi_import_error_alert';

import { useScanQuery } from '#gql';
import { PageLayout, PageLayoutNavigation } from '#ui/page_layout';

export function ScanImportPage() {
  const { t } = useTranslate();
  const scanId = useScanIdParam();

  const { data, error } = useScanQuery({
    pollInterval: 5000,
    variables: { id: scanId },
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const reactKey = useMemo(() => new Date().toISOString(), [data]);

  if (error) throw error;
  if (!data?.scan) return null;

  const { scan } = data;
  const imageId = scan.document?.images.find(
    (image) => image.originalScan.id === scanId,
  )?.id;

  return (
    <scanIdContext.Provider value={scan.id}>
      <PageLayout
        title={scan.filename}
        navigation={
          <PageLayoutNavigation to="/scans">
            <T keyName="nav.scans.list" />
          </PageLayoutNavigation>
        }
        actions={
          <>
            {scan.document && imageId && (
              <DocumentImageLink
                document={scan.document}
                multiline
                includeService
                imageId={imageId}
              />
            )}
            <UserChangesMeta
              {...scan}
              mailSubject={`[${t('scan.title', { noWrap: true })}] ${scan.filename}`}
              mailBody={window.location.href}
            />
          </>
        }
      >
        {match(scan.status)
          .with('ERROR', () => (
            <ScanRoiImportErrorAlert filename={scan.filename} />
          ))
          .with('IMPORTED', () => (
            <StateNotCompatibleFormattedAlert id={scanId} />
          ))
          .otherwise(() => (
            // invalidate component tree each time it receives new data so that the initial state for the selected tab is recomputed and reused (see `useState` for `tab`)
            <ScanImportProcess key={reactKey} scan={scan} />
          ))}
      </PageLayout>
    </scanIdContext.Provider>
  );
}
