import { T, useTranslate } from '@tolgee/react';

import { safeMappedTranslationKey } from '../../translationsMapping/common';
import FormattedSearchSelectField from '../translation/FormattedSearchSelectField';

import { customOptionsFilter, useSearchSelect } from '#tailwind_ui';

export interface GraphqlEnumValuesSelectFieldProps<T extends string> {
  translationKey: TranslationKey;
  translationMap: Record<T, TranslationKey>;
  enumValues: T[];
  name: string;
  clearable?: boolean;
  required?: boolean;
  label?: TranslationKey;
}

export default function GraphqlEnumValuesSelectField<T extends string>({
  translationKey,
  enumValues,
  label,
  translationMap,
  ...rest
}: GraphqlEnumValuesSelectFieldProps<T>) {
  const { t } = useTranslate();

  const options = enumValues.slice();

  const filterOptions = customOptionsFilter<string>((option) => {
    return t(safeMappedTranslationKey(translationMap, option as T));
  });
  const hook = useSearchSelect({
    options,
    filterOptions,
  });

  return (
    <FormattedSearchSelectField
      {...hook}
      name={rest.name}
      label={label || translationKey}
      clearable={rest.clearable}
      required={rest.required}
      renderOption={(option: string) => (
        <T keyName={safeMappedTranslationKey(translationMap, option as T)} />
      )}
    />
  );
}
