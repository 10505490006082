import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';
import { ReactNode, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDocumentsContext } from '../../contexts/documents';
import {
  safeTranslationKeyDocumentFraud,
  safeTranslationKeyDocumentMedium,
  safeTranslationKeyDocumentPrintProcess,
  safeTranslationKeyDocumentType,
} from '../../translationsMapping/documents';
import {
  safeTranslationKeyGlobalComplying,
  safeTranslationKeyGlobalFluo,
  safeTranslationKeyGlobalPresence,
} from '../../translationsMapping/global';
import CountryName from '../CountryName';
import FormattedCheckboxField from '../translation/FormattedCheckboxField';
import FormattedSubmitButton from '../translation/FormattedSubmitButton';

import { GqlDetailedDocumentFieldsFragment } from '#gql';
import {
  Button,
  CardListContainer,
  FormRHFModal,
  useCheckedFormRHFContext,
  useOnOff,
} from '#tailwind_ui';

interface Item {
  name: string;
  label: TranslationKey;
  message: TranslationKey | Exclude<ReactNode, string>;
  disabled?: boolean;
}

interface SearchAnalysisModalForm {
  docIsMrzOkEnabled: boolean;
  docMediumEnabled: boolean;
  docPrintBackgroundEnabled: boolean;
  docPrintNumberEnabled: boolean;
  docPrintDataEnabled: boolean;
  docPrintPhotoEnabled: boolean;
  docIsBackgroundFluoEnabled: boolean;
  docIsBitmapCodeEnabled: boolean;
  docTypeEnabled: boolean;
  docFraudTypeEnabled: boolean;
  docCountryEnabled: boolean;
}

const defaultValues: SearchAnalysisModalForm = {
  docIsMrzOkEnabled: true,
  docMediumEnabled: true,
  docPrintBackgroundEnabled: true,
  docPrintNumberEnabled: true,
  docPrintDataEnabled: true,
  docPrintPhotoEnabled: true,
  docIsBackgroundFluoEnabled: true,
  docIsBitmapCodeEnabled: true,
  docTypeEnabled: true,
  docFraudTypeEnabled: true,
  docCountryEnabled: true,
};

const unselectedValues: SearchAnalysisModalForm = { ...defaultValues };
for (const field of Object.keys(unselectedValues)) {
  unselectedValues[field as keyof SearchAnalysisModalForm] = false;
}

for (const field of [
  'docTypeEnabled',
  'docFraudTypeEnabled',
  'docCountryEnabled',
] as const) {
  defaultValues[field] = true;
  unselectedValues[field] = true;
}

interface SearchAnalysisModalProps {
  document: GqlDetailedDocumentFieldsFragment;
}

// Document take care of the import
export default function SearchAnalysisModal({
  document,
}: SearchAnalysisModalProps) {
  const [isOpen, openModal, closeModal] = useOnOff();

  const ctx = useDocumentsContext();
  const { store } = ctx;
  const navigate = useNavigate();

  const items = useMemo<Item[]>(() => {
    return [
      {
        name: 'docTypeEnabled',
        label: 'doc.field.type',
        message: safeTranslationKeyDocumentType(document.docType),
        disabled: true,
      },
      {
        name: 'docFraudTypeEnabled',
        label: 'doc.field.fraud_type',
        message: safeTranslationKeyDocumentFraud(document.docFraudType),
        disabled: true,
      },
      {
        name: 'docCountryEnabled',
        label: 'doc.field.country',
        message: <CountryName country={document.docCountry} />,
        disabled: true,
      },
      {
        name: 'docIsMrzOkEnabled',
        label: 'doc.mrz',
        message: safeTranslationKeyGlobalComplying(document.docIsMrzOk),
      },
      {
        name: 'docMediumEnabled',
        label: 'doc.field.medium',
        message: safeTranslationKeyDocumentMedium(document.docMedium),
      },
      {
        name: 'docPrintBackgroundEnabled',
        label: 'doc.field.print_background',
        message: safeTranslationKeyDocumentPrintProcess(
          document.docPrintBackground,
        ),
      },
      {
        name: 'docPrintNumberEnabled',
        label: 'doc.field.print_number',
        message: safeTranslationKeyDocumentPrintProcess(
          document.docPrintNumber,
        ),
      },
      {
        name: 'docPrintDataEnabled',
        label: 'doc.field.print_data',
        message: safeTranslationKeyDocumentPrintProcess(document.docPrintData),
      },
      {
        name: 'docPrintPhotoEnabled',
        label: 'doc.field.print_photo',
        message: safeTranslationKeyDocumentPrintProcess(document.docPrintPhoto),
      },
      {
        name: 'docIsBackgroundFluoEnabled',
        label: 'doc.field.is_background_fluo',
        message: safeTranslationKeyGlobalFluo(document.docIsBackgroundFluo),
      },
      {
        name: 'docIsBitmapCodeEnabled',
        label: 'doc.field.doc_is_bitmap_code',
        message: safeTranslationKeyGlobalPresence(document.docIsBitmapCode),
      },
    ];
  }, [document]);

  type KeyWithoutEnabled<Key extends string> =
    Key extends `${infer Subkey}Enabled` ? Subkey : Key;

  function onSubmit(values: typeof defaultValues) {
    const params = new URLSearchParams();

    for (const key of Object.keys(defaultValues).filter(
      (key) => values[key as keyof SearchAnalysisModalForm],
    )) {
      const withoutEnabledKey = key.replace('Enabled', '') as KeyWithoutEnabled<
        keyof SearchAnalysisModalForm
      >;

      const value = document[withoutEnabledKey];
      params.append(withoutEnabledKey, String(value));
    }

    store.reset();
    navigate(`/documents?${params.toString()}`);
  }

  return (
    <>
      <FormRHFModal
        fluid
        requestCloseOnBackdrop
        requestCloseOnEsc
        onRequestClose={closeModal}
        isOpen={isOpen}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        icon={<MagnifyingGlassIcon />}
      >
        <FormRHFModal.Header>
          <T keyName="doc.analysis.title" />
        </FormRHFModal.Header>
        <FormRHFModal.Body>
          <ActionFormRHF items={items} />
          <CardListContainer>
            {items.map((item) => (
              <CardListContainer.Item
                key={item.name}
                className="mx-5 flex flex-row justify-between gap-5"
              >
                <FormattedCheckboxField
                  name={item.name}
                  disabled={item.disabled}
                  label={item.label}
                />
                {typeof item.message === 'string' ? (
                  <T keyName={item.message as TranslationKey} />
                ) : (
                  item.message
                )}
              </CardListContainer.Item>
            ))}
          </CardListContainer>
        </FormRHFModal.Body>
        <FormRHFModal.Footer>
          <FormattedSubmitButton
            color="primary"
            messageId="doc.analysis.title"
          />
        </FormRHFModal.Footer>
      </FormRHFModal>

      <Button
        tooltip={<T keyName="global.search" />}
        onClick={openModal}
        roundness="circular"
      >
        <MagnifyingGlassIcon className="h-5 w-5" />
      </Button>
    </>
  );
}

function ActionFormRHF(props: { items: Item[] }) {
  const form = useCheckedFormRHFContext();

  const selectAll = useCallback(() => {
    for (const item of props.items) {
      if (!item.disabled) {
        form.setValue(item.name, true);
      }
    }
  }, [form, props.items]);

  const deselectAll = useCallback(() => {
    for (const item of props.items) {
      if (!item.disabled) {
        form.setValue(item.name, false);
      }
    }
  }, [form, props.items]);

  return (
    <div className="flex flex-row gap-5">
      <span
        className="cursor-pointer text-primary-500 underline"
        onClick={selectAll}
      >
        <T keyName="global.select_all" />
      </span>
      <span
        className="cursor-pointer text-primary-500 underline"
        onClick={deselectAll}
      >
        <T keyName="global.deselect_all" />
      </span>
    </div>
  );
}
