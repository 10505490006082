import { UserRole } from '#gql';

export function getSortedRoles(roles: UserRole[]) {
  const sortedRoles = roles.slice();
  const viewerRoleIndex = sortedRoles.indexOf('viewer');
  if (viewerRoleIndex !== -1 && sortedRoles[0] !== 'viewer') {
    sortedRoles.splice(viewerRoleIndex, 1);
    sortedRoles.unshift('viewer');
  }
  return sortedRoles;
}
