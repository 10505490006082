import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';

import { requiredObject, requiredString } from '../../../forms/validation';
import { useNavigateAfterSave } from '../../../hooks/useNavigateAfterSave';
import { useTolgeeCacheLoad } from '../../../hooks/useTolgeeCacheLoad';
import { FormPreventNavigation } from '../../form/FormPreventNavigation';
import FormattedButton from '../../translation/FormattedButton';
import FormattedFormError from '../../translation/FormattedFormError';
import FormattedInputField from '../../translation/FormattedInputField';
import FormattedRichTextField from '../../translation/FormattedRichTextField';
import FormattedSubmitButton from '../../translation/FormattedSubmitButton';
import { getAlertDraftViewUrl } from '../utils/navigation';

import { AlertDraftEditMeasuresField } from './AlertDraftEditMeasuresField';

import {
  GqlAlertTranslation,
  GqlAlertTranslationInput,
  useUpdateAlertDraftTranslationMutation,
} from '#gql';
import { FormRHF, RichTextToolbarOptions, useOnOff } from '#tailwind_ui';

interface AlertDraftEditFieldsProps {
  alertId: string;
  draftId: string;
  translation: GqlAlertTranslation | null;
  fieldClassName?: string;
  readOnly?: boolean;
}

const editDraftValidationSchema = requiredObject({
  title: requiredString,
});

type DraftFields = Omit<GqlAlertTranslationInput, 'language'>;

const RICHTEXT_TOOLBAR_OPTIONS: RichTextToolbarOptions = {
  insertTable: false,
  insertImage: false,
};

export default function AlertDraftEditFields(props: AlertDraftEditFieldsProps) {
  const navigate = useNavigate();
  const { alertId, draftId, translation, fieldClassName, readOnly } = props;
  // assert(translation);
  const { title, general, measures, rapidIdentificationDescription } =
    translation?.fields ?? {};

  useTolgeeCacheLoad(translation?.language, 'alert');

  const [saved, setSaved] = useOnOff();

  useNavigateAfterSave(
    saved && !readOnly,
    getAlertDraftViewUrl(alertId, translation?.language),
  );

  const [updateTranslation] = useUpdateAlertDraftTranslationMutation();

  const Form = readOnly
    ? FormRHF<DraftFields>
    : FormPreventNavigation<DraftFields>;

  return (
    <Form
      key={translation?.language}
      noDefaultStyle
      className="contents space-y-3"
      defaultValues={{
        title,
        general,
        measures,
        rapidIdentificationDescription,
      }}
      validationSchema={editDraftValidationSchema}
      onSubmit={async (values) => {
        if (readOnly) return;
        if (!translation) return;

        await updateTranslation({
          variables: {
            input: {
              id: alertId,
              draftId,
              fields: {
                title: values.title,
                general: values.general,
                measures: values.measures,
                rapidIdentificationDescription:
                  values.rapidIdentificationDescription,
              },
              language: translation.language,
              rev: translation.rev,
            },
          },
        });
        setSaved();
      }}
    >
      <FormattedInputField
        name="title"
        label="global.title"
        className={fieldClassName}
        readOnly={readOnly}
      />
      <div className={fieldClassName}>
        <FormattedRichTextField
          name="general"
          label="series.alert.field.general"
          labelOptions={{ ns: 'alert' }}
          toolbarOptions={RICHTEXT_TOOLBAR_OPTIONS}
          readOnly={readOnly}
        />
      </div>

      <div className={fieldClassName}>
        <FormattedRichTextField
          name="rapidIdentificationDescription"
          label="series.alert.field.rapid_identification_description"
          labelOptions={{ ns: 'alert' }}
          toolbarOptions={RICHTEXT_TOOLBAR_OPTIONS}
          readOnly={readOnly}
        />
      </div>

      <div className={fieldClassName}>
        <AlertDraftEditMeasuresField
          toolbarOptions={RICHTEXT_TOOLBAR_OPTIONS}
          readOnly={readOnly}
          language={translation?.language}
        />
      </div>

      <div className={fieldClassName}>
        <FormattedFormError />
      </div>

      {!readOnly && (
        <div className={clsx(fieldClassName, 'flex items-center gap-2')}>
          <FormattedButton
            variant="white"
            messageId="global.cancel"
            onClick={() =>
              navigate(getAlertDraftViewUrl(alertId, translation?.language))
            }
          />
          <FormattedSubmitButton messageId="global.save" color="primary" />
        </div>
      )}
    </Form>
  );
}
