import { ChangeEvent, useCallback } from 'react';

import FormattedTextArea, {
  FormattedTextAreaProps,
} from '../../../translation/FormattedTextArea';

type MRZLinesInputProps = Omit<
  Partial<FormattedTextAreaProps>,
  'defaultValue' | 'onChange' | 'label'
> & {
  initialMrz: string[];
  onChange: (value: string[]) => void;
};

export function MRZInput(props: MRZLinesInputProps) {
  const { initialMrz, onChange, ...textAreaProps } = props;
  const mrzString = initialMrz.join('\n');

  const onChangeCallback = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = event.target as HTMLTextAreaElement;

      const mrz = value
        .split('\n')
        .map((line) => line.trim().toUpperCase())
        .filter(Boolean);
      onChange(mrz);
    },
    [onChange],
  );

  return (
    <FormattedTextArea
      textAreaClassName="font-ocrb uppercase text-nowrap"
      name="mrz"
      hiddenLabel
      label="scans.import_data.mrz.input_title"
      rows={3}
      defaultValue={mrzString}
      onChange={onChangeCallback}
      {...textAreaProps}
    />
  );
}
