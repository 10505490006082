import { SetURLSearchParams } from 'react-router-dom';
import { match, P } from 'ts-pattern';

import { Store } from './Store';

import { GqlFacetSearchResult } from '#gql';

const supportedSingleTypes = ['string', 'number', 'boolean', 'date'] as const;
const supportedArrayTypes = [
  'string[]',
  'number[]',
  'boolean[]',
  'date[]',
] as const;

export type SyncSearchParamsStoreSupportedTypes =
  | (typeof supportedSingleTypes)[number]
  | (typeof supportedArrayTypes)[number];

export abstract class SyncSearchParamsStore<
  Data extends Record<string, unknown>,
> extends Store<Data> {
  /**
   * Must be implemented by children
   * It's the core of generic serialization/deserialization between data shape and searchParams
   */
  public abstract get shapeDef(): Record<
    keyof Data,
    SyncSearchParamsStoreSupportedTypes
  >;

  /**
   * stable searchParam setter
   * @protected
   */
  protected setSearchParams?: SetURLSearchParams;

  /**
   * Single time assignment, DI to simplify constructor
   * ignore if set more than one time
   *
   * @example
   * ```ts
   *   const [, setSearch] = useSearchParams();
   *   const store = useMemo(() => {
   *     const store = new Store();
   *     store.setSetSearchParams(setSearch);
   *
   *     return store;
   *     // eslint-disable-next-line react-hooks/exhaustive-deps
   *   }, []);
   * ```
   *
   * @readonly
   * @protected
   */
  public setSetSearchParams(setSearchParams: SetURLSearchParams) {
    if (this.setSearchParams) return;

    this.setSearchParams = setSearchParams;
  }

  /**
   * hooks on emitChanges to actualize searchParams
   *
   * @protected
   */
  protected override emitChanges() {
    this.snapshotToSearchParams();

    super.emitChanges();
  }

  /**
   * Generic method to transform data to a URLSearchParamsInit
   * iterates through object entries of data, cast value to string or array of cast value to string
   *
   * about Date on searchParam format:
   * (date.getTime() / 1000).toFixed(0)
   * It's chosen for compact and no day shift on format / and parse due to timezoned dates
   *
   * It can be overridden for more precise behavior
   *
   * setSetSearchParams had to be called before using this function
   *
   * @protected
   * @throws TypeError if setSearchParams is not set
   */
  protected snapshotToSearchParams(): void {
    if (!this.setSearchParams) {
      throw new TypeError(
        'setSetSearchParams must had been call before call snapshotToSearchParams',
      );
    }
    if (!this.data) return this.setSearchParams({});

    const aggregator: Record<string, string | string[]> = {};
    for (const [key, value] of Object.entries(this.data)) {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          aggregator[key] = value.map((v) => {
            if (v instanceof Date) {
              return (v.getTime() / 1000).toFixed(0);
            }
            return String(v);
          });
        }
        continue;
      }

      if (value == null) continue;

      aggregator[key] =
        value instanceof Date
          ? (value.getTime() / 1000).toFixed(0)
          : String(value);
    }

    this.setSearchParams(aggregator, { replace: true });
  }

  /**
   * deserialize URLSearchParams to partial data,
   * based upon shapeDef (to know how to deserialize data for each key)
   *
   * @param search
   * @protected
   */
  protected searchParamsToPartialSnapshot(
    search: URLSearchParams,
  ): Partial<Data> {
    const data: Partial<Data> = {};

    for (const [_key, type] of Object.entries(this.shapeDef)) {
      const key = _key as keyof Data & string;
      if (!search.has(key)) continue;

      const rawValue = match(type)
        .with(P.string.endsWith('[]'), () =>
          search
            .getAll(key)
            .map((str) => str.trim())
            .filter(Boolean),
        )
        .otherwise(() => {
          const v = search.get(key)?.trim() ?? null;

          return v === 'null' ? null : search.get(key)?.trim() ?? null;
        });

      // eslint-disable-next-line no-inner-declarations,unicorn/consistent-function-scoping
      function sanitizeStrings(): Array<string | null> {
        const v = rawValue as string[];
        if (!v) return [];

        return v.map((str) => (str === 'null' ? null : str));
      }

      // eslint-disable-next-line no-inner-declarations,unicorn/consistent-function-scoping
      function sanitizeNumber(): number | null {
        const v = rawValue as string | null;
        if (!v) return null;

        const vn = Number(v);
        if (Number.isNaN(vn)) return null;

        return vn;
      }

      // eslint-disable-next-line no-inner-declarations,unicorn/consistent-function-scoping
      function sanitizeNumbers(): number[] {
        const v = rawValue as string[];
        return v
          .map((str) => (str === 'null' ? null : Number(str)))
          .filter(
            (num) => typeof num === 'number' && !Number.isNaN(num),
          ) as number[];
      }

      // eslint-disable-next-line no-inner-declarations,unicorn/consistent-function-scoping
      function sanitizeBooleans(): Array<boolean | null> {
        const v = rawValue as Array<string | null>;
        return v.map((str) =>
          match(str)
            .with('true', () => true)
            .with(null, 'null', () => null)
            .otherwise(() => false),
        );
      }

      // eslint-disable-next-line no-inner-declarations,unicorn/consistent-function-scoping
      function sanitizeDate() {
        const date = new Date(Number(rawValue) * 1000);

        if (Number.isNaN(date.getTime())) return null;

        return date;
      }

      // eslint-disable-next-line no-inner-declarations,unicorn/consistent-function-scoping
      function sanitizeDates(): Date[] {
        const v = rawValue as string[];
        return v
          .map((str) => {
            const date = new Date(Number(str) * 1000);

            if (Number.isNaN(date.getTime())) return null;

            return date;
          })
          .filter(Boolean) as Date[];
      }

      const value = match(type)
        .with('string', () => rawValue as string)
        .with('string[]', sanitizeStrings)
        .with('number', sanitizeNumber)
        .with('number[]', sanitizeNumbers)
        .with('boolean', () => rawValue === 'true')
        .with('boolean[]', sanitizeBooleans)
        .with('date', sanitizeDate)
        .with('date[]', sanitizeDates)
        .exhaustive();

      data[key] = value as Data[typeof key];
    }

    return data;
  }

  /**
   * assign searchParams to data rely on `searchParamsToPartialSnapshot`
   * @param search
   */
  public syncUrlSearchParams = (search: URLSearchParams): void => {
    this.mergeData(this.searchParamsToPartialSnapshot(search));
  };

  /**
   * Helper to prepare an onChange callback,
   * able to add a value into the array of value from data[name]
   * of set the value.
   *
   * depend on shapeDef
   *
   * @param name
   */
  public addFilter = (
    name: keyof Data,
  ): ((value: Data[typeof name]) => void) => {
    return (value: Data[typeof name]) => {
      this.newSnapshot();
      if (this.shapeDef[name].endsWith('[]')) {
        if (!this.data[name]) this.data[name] = [] as Data[typeof name];
        (this.data[name] as Array<Data[typeof name]>).push(value);
      } else {
        this.data[name] = value;
      }
      this.emitChanges();
    };
  };

  /**
   * Helper to prepare an onChange callback,
   * able to remove a value into the array of value from data[name]
   * or set null.
   *
   * depend on shapeDef
   *
   * @param name
   */
  public removeFilter = (
    name: keyof Data,
  ): ((value: Data[typeof name]) => void) => {
    return (value: Data[typeof name]) => {
      this.newSnapshot();
      if (this.shapeDef[name].endsWith('[]')) {
        if (!this.data[name]) this.data[name] = [] as Data[typeof name];
        this.data[name] = (this.data[name] as Array<Data[typeof name]>).filter(
          (v) => v !== value,
        ) as Data[typeof name];
      } else {
        this.data[name] = null as Data[typeof name];
      }
      this.emitChanges();
    };
  };

  /**
   * helper to merge data
   *
   * @param partial
   */
  public mergeData = (partial: NullablePartial<Data>) => {
    this.newSnapshot();

    Object.assign(this.data, partial);

    this.emitChanges();
  };

  public getCorrectedFacets = (facets: GqlFacetSearchResult[]) => {
    return facets.map((facet) => {
      const name = facet.name;

      if (!this.data[name]) return facet;
      const userFilter = Array.from(this.data[name] as Array<string | null>);

      // remove already filtered
      const correctedValues = [...facet.values];
      for (const { filter } of correctedValues) {
        const dupIndex = userFilter.indexOf(filter);
        if (dupIndex === -1) continue;
        userFilter.splice(dupIndex, 1);
      }

      // add bad facets
      for (const filter of userFilter) {
        correctedValues.push({
          __typename: 'FacetValue',
          filter,
          count: 0,
        });
      }

      return { ...facet, values: correctedValues };
    });
  };
}

export type NameValueCallback<Data> = (
  name: keyof Data,
) => (value: Data[keyof Data]) => void;

type NullablePartial<T> = {
  [P in keyof T]?: T[P] | null;
};
