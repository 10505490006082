import { useTranslate } from '@tolgee/react';
import { useMemo } from 'react';

import { documentViewValues } from '../../../graphql/enumValues';
import { safeTranslationKeyDocumentView } from '../../../translationsMapping/documents';
import SelectList, { SelectItem } from '../../SelectList';

import { DocumentView } from '#gql';
import { assert } from '#utils/assert';

type MaybeDocumentView = DocumentView | null;

const scanViewValues: MaybeDocumentView[] = [...documentViewValues, null];

export function DocumentViewSelect(props: {
  name: string;
  label: TranslationKey;
  selected: MaybeDocumentView[];
  setSelected: (selected: MaybeDocumentView[]) => void;
  readOnly?: boolean;
}) {
  const { t } = useTranslate();

  const options = useMemo(() => {
    return scanViewValues.map((value): SelectItem<MaybeDocumentView> => {
      const displayLabel = t(safeTranslationKeyDocumentView(value));
      return {
        value,
        count: 0,
        searchLabel: displayLabel,
        optionLabel: displayLabel,
        displayLabel,
      };
    });
  }, [t]);

  return (
    <SelectList
      name={props.name}
      placeholder={t(props.label)}
      options={options}
      selected={props.selected.map((value) => {
        const option = options.find((opt) => opt.value === value);
        assert(option);
        return option;
      })}
      onAdd={(item) => {
        if (props.readOnly) return;
        props.setSelected([...props.selected, item.value]);
      }}
      onRemove={(item) => {
        if (props.readOnly) return;
        props.setSelected(
          props.selected.filter((value) => value !== item.value),
        );
      }}
    />
  );
}
