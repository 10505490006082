import { FormattedDate } from 'react-intl';

import { Feature } from '../../constants';
import DocumentCell from '../DocumentCell';
import IfHasFeature from '../IfHasFeature';
import ImgLink from '../ImgLink';
import { TableSelectionRenderTrProps } from '../TableSelection';
import SeriesLink from '../serie/SeriesLink';

import DocumentLink from './DocumentLink';
import {
  DocumentListLocationState,
  DocumentListQueryVariables,
} from './documentListLocationState';

import { GqlDocumentsQuery } from '#gql';
import { LinkLabelBadge } from '#label';
import { Td } from '#tailwind_ui';

interface DocumentListRowProps
  extends TableSelectionRenderTrProps<
    GqlDocumentsQuery['documents']['nodes'][number],
    string
  > {
  documentsQueryVariables: DocumentListQueryVariables;
}

export function DocumentListRow({
  value,
  index,
  checkboxTd,
  documentsQueryVariables,
}: DocumentListRowProps) {
  let image = null;
  if (value.imageRecto) {
    image = value.imageRecto;
  } else if (value.imageVerso) {
    image = value.imageVerso;
  }

  const locationState: DocumentListLocationState = {
    fromDocumentList: true,
    documentsQueryVariables,
    currentDocumentIndex: index,
  };

  return (
    <tr className="h-[125px] even:bg-neutral-50">
      {checkboxTd}
      <Td className="align-middle">
        {image ? (
          <ImgLink
            isExtra={image.isExtra}
            imgClassName="max-h-[100px] max-w-[200px] rounded"
            src={image.document.url}
            thumbSrc={image.documentThumb.url}
            alt="document preview"
          />
        ) : (
          '-'
        )}
      </Td>
      <Td className="space-y-3 align-middle">
        <DocumentLink document={value} state={locationState} multiline />
        <div className="flex flex-row flex-wrap items-center gap-2">
          {value.labels.map((label) => (
            <LinkLabelBadge
              key={label.id}
              label={label}
              to="/documents"
              replace
            />
          ))}
        </div>
      </Td>
      <Td className="align-middle" title={value.caseService.name}>
        {value.caseService.identifier}
      </Td>
      <Td className="align-middle">
        <DocumentCell value={value} />
      </Td>
      <IfHasFeature feature={Feature.series}>
        <Td className="align-middle">
          <SeriesLink series={value.series} multiline />
        </Td>
      </IfHasFeature>
      <Td className="align-middle" title={value.caseOperation ?? undefined}>
        {value.caseOperation}
      </Td>
      <Td className="align-middle" title={value.docSerialNumber ?? undefined}>
        {value.docSerialNumber}
      </Td>
      <Td className="align-middle">
        {value.caseSeizureDate ? (
          <FormattedDate value={value.caseSeizureDate} />
        ) : (
          '-'
        )}
      </Td>
      <Td className="align-middle">
        <FormattedDate value={value.createdAt} />
      </Td>
    </tr>
  );
}
