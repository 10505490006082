import { useState } from 'react';

interface ImageDimensions {
  width: number;
  height: number;
  aspectRatio: number;
}

interface StableRotatedImageProps {
  angle: number;
  src: string;
  alt?: string;
}

export default function StableRotatedImage(props: StableRotatedImageProps) {
  const { angle, src, alt } = props;

  const [imageDimensions, setImageDimensions] =
    useState<ImageDimensions | null>(null);

  const isHorizontal = angle % 180 === 0;

  return (
    <div
      className="relative"
      style={
        imageDimensions
          ? isHorizontal
            ? {
                maxHeight: '50vh',
                height: imageDimensions.height,
                aspectRatio: imageDimensions.aspectRatio,
              }
            : {
                maxHeight: '50vh',
                height: imageDimensions.width,
                aspectRatio: 1 / imageDimensions.aspectRatio,
              }
          : undefined
      }
    >
      <img
        onLoad={(event) => {
          const width = event.currentTarget.naturalWidth;
          const height = event.currentTarget.naturalHeight;
          setImageDimensions({
            width,
            height,
            aspectRatio: width / height,
          });
        }}
        alt={alt}
        src={src}
        className="absolute object-contain"
        style={
          imageDimensions
            ? isHorizontal
              ? {
                  maxHeight: '50vh',
                  height: imageDimensions.height,
                  transform: `rotate(${angle}deg)`,
                }
              : {
                  maxWidth: '50vh',
                  width: imageDimensions.width,
                  top: '50%',
                  left: '50%',
                  transform: `translate(-50%, -50%) rotate(${angle}deg)`,
                }
            : undefined
        }
      />
    </div>
  );
}
