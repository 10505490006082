import { useRectoVerso } from '../../hooks/useRectoVerso';

import { PadifShowMainImage } from './react-roi/PadifShowMainImage';

import { GqlDocumentPadifAnalysisRoisQuery, DocumentView } from '#gql';
import { assert, assertNotNull } from '#utils/assert';

export default function PadifShowMainReference({
  maxHeight,
  rois,
  document,
}: {
  maxHeight: number | string;
  rois: GqlDocumentPadifAnalysisRoisQuery['document']['padifAnalyses'][number]['rois'];
  document: GqlDocumentPadifAnalysisRoisQuery['document']['padifAnalyses'][number]['referenceDocument'];
}) {
  // The component should never be mounted without a reference document.
  assertNotNull(document);
  const roisRecto = rois.filter((roi) => roi.view === 'RECTO');
  const roisVerso = rois.filter((roi) => roi.view === 'VERSO');

  const {
    recto: imageRecto,
    verso: imageVerso,
    canBeFlipped,
  } = useRectoVerso(document);

  if (!imageRecto && !imageVerso) {
    return <div>TODO: print message when no image is available</div>;
  }

  if (!canBeFlipped) {
    const existingImage = imageRecto
      ? ([imageRecto, roisRecto] as const)
      : ([imageVerso, roisVerso] as const);

    assert(existingImage[0]);

    return (
      <div className="flex justify-center">
        <DisplayImage
          maxHeight={maxHeight}
          image={existingImage[0]}
          rois={existingImage[1]}
        />
      </div>
    );
  }

  assert(imageRecto && imageVerso);

  return (
    <div className="flex w-full min-w-0 flex-row justify-between">
      <DisplayImage maxHeight={maxHeight} image={imageRecto} rois={roisRecto} />
      <DisplayImage maxHeight={maxHeight} image={imageVerso} rois={roisVerso} />
    </div>
  );
}

interface DisplayImageProps {
  maxHeight: number | string;
  image: {
    id: string;
    view: DocumentView;
    document: { id: string; url: string };
  };
  rois: GqlDocumentPadifAnalysisRoisQuery['document']['padifAnalyses'][number]['rois'];
}

function DisplayImage(props: DisplayImageProps) {
  const { maxHeight, image, rois } = props;

  return (
    <div className="relative m-4 flex min-w-0 flex-1">
      <PadifShowMainImage
        rois={rois}
        url={image.document.url}
        maxHeight={maxHeight}
      />
    </div>
  );
}
