import { CSSProperties, useMemo } from 'react';
import {
  RoiContainer,
  RoiList,
  RoiProvider,
  RoiProviderInitialConfig,
  TargetImage,
} from 'react-roi';

import { GqlDocumentPadifAnalysisRoisQuery } from '#gql';

interface PadifShowMainImageProps {
  url: string;
  rois: GqlDocumentPadifAnalysisRoisQuery['document']['padifAnalyses'][number]['rois'];
  maxHeight: CSSProperties['maxHeight'];
}

interface RoiData {
  backgroundColor: string;
}

export function PadifShowMainImage(props: PadifShowMainImageProps) {
  const { url, rois, maxHeight } = props;

  const initialConfig: RoiProviderInitialConfig<RoiData> = useMemo(() => {
    return {
      mode: 'select',
      commitRoiBoxStrategy: 'round',
      zoom: {
        spaceAroundTarget: 0,
        min: 1,
        max: 5,
      },
      rois: rois.map((roi, index) => ({
        id: roi.id,
        height: roi.height,
        width: roi.width,
        x: roi.x,
        y: roi.y,
        label: `ROI ${index + 1}`,
        angle: 0,
        data: {
          backgroundColor: roi.color,
        },
      })),
    };
  }, [rois]);

  return (
    <RoiProvider<RoiData> initialConfig={initialConfig} key={`${rois.length}`}>
      <div className="relative flex max-w-full" style={{ maxHeight }}>
        <RoiContainer lockPan lockZoom target={<TargetImage src={url} />}>
          <RoiList<RoiData>
            getReadOnly={() => true}
            getStyle={(element, { isSelected }) => {
              return {
                rectAttributes: {
                  fill: element.data?.backgroundColor,
                  opacity: isSelected ? 0.2 : 0.4,
                  stroke: 'black',
                  strokeWidth: 3,
                },
              };
            }}
          />
        </RoiContainer>
      </div>
    </RoiProvider>
  );
}
