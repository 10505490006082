import { userRoles } from '../../constants';
import FormattedMultiSearchSelectField from '../translation/FormattedMultiSearchSelectField';

import { useMultiSearchSelect } from '#tailwind_ui';

export default function UserRolesField(props: { name: string }) {
  // TODO: understand why inference doesn't work here.
  const userRolesSelect = useMultiSearchSelect<string>({
    options: userRoles,
  });
  return (
    <FormattedMultiSearchSelectField<string>
      required
      name={props.name}
      label="global.roles"
      isOptionRemovable={(option) => option !== 'viewer'}
      {...userRolesSelect}
    />
  );
}
