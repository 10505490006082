export class JSError extends Error {
  public messageId: TranslationKey;
  public constructor(message: string, messageId: TranslationKey) {
    super(message);
    this.messageId = messageId;
  }
}

export interface ApiErrorData {
  message: string;
  translationKey?: TranslationKey;
  requestId: string;
}

export class ApiError extends Error {
  public requestId: string;
  public translationKey?: TranslationKey;
  public constructor(errorData: ApiErrorData) {
    super(errorData.message);
    this.name = 'ApiError';
    this.translationKey = errorData.translationKey;
    this.requestId = errorData.requestId;
  }
}

export function isApiError(error: Error): error is ApiError {
  return error instanceof ApiError;
}

export function isJSError(error: Error): error is JSError {
  return error instanceof JSError;
}
