import {
  documentMediumValues,
  documentTypeValues,
  documentViewValues,
  extraDocumentLightValues,
  fraudTypeValues,
  offenseValues,
  printProcessValues,
  seizureContextValues,
} from '../../graphql/enumValues';
import {
  TRANSLATIONS_KEY_DOCUMENT_EXTRA_IMAGE_LIGHT,
  TRANSLATIONS_KEY_DOCUMENT_FRAUD_TYPE,
  TRANSLATIONS_KEY_DOCUMENT_MEDIUM,
  TRANSLATIONS_KEY_DOCUMENT_PRINT_PROCESS,
  TRANSLATIONS_KEY_DOCUMENT_RELATED_OFFENSE,
  TRANSLATIONS_KEY_DOCUMENT_SEIZURE_CONTEXT,
  TRANSLATIONS_KEY_DOCUMENT_TYPE,
  TRANSLATIONS_KEY_DOCUMENT_VIEW_TYPE,
} from '../../translationsMapping/documents';

import GraphqlEnumValuesSelectField, {
  GraphqlEnumValuesSelectFieldProps,
} from './GraphqlEnumValuesSelectField';

function getGraphqlEnumValuesSelectField<R extends string = string>(
  translationKey: TranslationKey,
  enumValues: R[],
  translationMap: GraphqlEnumValuesSelectFieldProps<R>['translationMap'],
) {
  return (
    props: Omit<
      GraphqlEnumValuesSelectFieldProps<R>,
      'translationKey' | 'enumValues' | 'translationMap'
    > &
      Partial<Pick<GraphqlEnumValuesSelectFieldProps<R>, 'enumValues'>>,
  ) => {
    return (
      <GraphqlEnumValuesSelectField<R>
        label={props.label}
        translationKey={translationKey}
        enumValues={enumValues}
        translationMap={translationMap}
        {...props}
      />
    );
  };
}

export const SelectSeizureContext = getGraphqlEnumValuesSelectField(
  'doc.field.seizure_context',
  seizureContextValues,
  TRANSLATIONS_KEY_DOCUMENT_SEIZURE_CONTEXT,
);

export const SelectRelatedOffense = getGraphqlEnumValuesSelectField(
  'doc.field.related_offense',
  offenseValues,
  TRANSLATIONS_KEY_DOCUMENT_RELATED_OFFENSE,
);

export const SelectDocumentType = getGraphqlEnumValuesSelectField(
  'doc.field.type',
  documentTypeValues,
  TRANSLATIONS_KEY_DOCUMENT_TYPE,
);

export const SelectFraudType = getGraphqlEnumValuesSelectField(
  'doc.field.fraud_type',
  fraudTypeValues,
  TRANSLATIONS_KEY_DOCUMENT_FRAUD_TYPE,
);

export const SelectDocMedium = getGraphqlEnumValuesSelectField(
  'doc.field.medium',
  documentMediumValues,
  TRANSLATIONS_KEY_DOCUMENT_MEDIUM,
);

export const SelectPrintProcess = getGraphqlEnumValuesSelectField(
  'doc.print_process',
  printProcessValues,
  TRANSLATIONS_KEY_DOCUMENT_PRINT_PROCESS,
);

export const SelectExtraImageLight = getGraphqlEnumValuesSelectField(
  'doc.extra_image.light',
  extraDocumentLightValues,
  TRANSLATIONS_KEY_DOCUMENT_EXTRA_IMAGE_LIGHT,
);

export const SelectDocumentView = getGraphqlEnumValuesSelectField(
  'doc.document_view',
  documentViewValues,
  TRANSLATIONS_KEY_DOCUMENT_VIEW_TYPE,
);
