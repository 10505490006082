import { FormattedDate } from 'react-intl';

import { useAuditLogContext } from '../../../contexts/auditLog';
import { FormattedPaginationText } from '../../translation/FormattedPagination';
import { FormattedTable } from '../../translation/FormattedTable';

import { GqlAuditLogsQuery } from '#gql';
import { Td, Th } from '#tailwind_ui';
import { DefinitionList } from '#ui/definition_list';

interface AuditLogsListProps {
  auditLogs?: GqlAuditLogsQuery['auditLogs'];
}

export default function AuditLogsList({ auditLogs }: AuditLogsListProps) {
  const context = useAuditLogContext();
  if (!auditLogs) return null;

  return (
    <FormattedTable
      data={auditLogs.nodes}
      renderHeader={renderHeader}
      renderTr={renderTr}
      sort={context.tableSort}
      pagination={{
        ...context.tablePagination,
        totalCount: auditLogs.totalCount,
        renderText: (actual) => (
          <FormattedPaginationText
            messageId="audit_log.list.pagination"
            page={actual}
            total={auditLogs.totalCount}
            rowsPerPage={context.tablePagination.itemsPerPage}
          />
        ),
      }}
    />
  );
}

function renderHeader() {
  return (
    <tr>
      <Th sortField="action">Action</Th>
      <Th>User</Th>
      <Th>Identifiers</Th>
      <Th>Data</Th>
      <Th sortField="date">Date</Th>
    </tr>
  );
}

type AuditLog = GqlAuditLogsQuery['auditLogs']['nodes'][number];
function renderTr(log: AuditLog) {
  const flatData = flatObject(log.data);

  return (
    <tr>
      <Td>{log.action}</Td>
      <Td>
        <p>{log.user.name}</p>
        <p>{log.user.email}</p>
      </Td>
      <Td>{log.identifiers && <DefinitionList data={log.identifiers} />}</Td>
      <Td>
        {flatData && <DefinitionList data={Object.fromEntries(flatData)} />}
      </Td>
      <Td>
        <FormattedDate value={log.date} dateStyle="short" timeStyle="medium" />
      </Td>
    </tr>
  );
}

function flatObject(
  o: object | null,
  prefix = '',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Array<[string, any]> | null {
  if (!o) return null;
  return Object.entries(o).flatMap(([k, v]) =>
    Object(v) === v
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (flatObject(v, `${prefix}${k}.`) as Array<[string, any]>)
      : (v ?? null) === null
        ? []
        : [[`${prefix}${k}`, v]],
  );
}
