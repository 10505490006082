import { T } from '@tolgee/react';
import { Link } from 'react-router-dom';

import { useSeqIdParam } from '../../hooks/useParams';
import FormattedButton from '../translation/FormattedButton';
import FormattedErrorAlert from '../translation/FormattedErrorAlert';

import DocumentShowQr from './DocumentShowQr';

import { DocumentView, useDocumentIdQuery } from '#gql';
import { Variant } from '#tailwind_ui';
import { PageLayout, PageLayoutNavigation } from '#ui/page_layout';
import { getDocumentTitle } from '#utils/index';

const ACTIONS = (
  <>
    <FormattedButton messageId="global.print" onClick={() => window.print()} />
    <FormattedButton
      as={Link}
      to="./../edit"
      messageId="page.doc.view.describe"
    />
    <FormattedButton
      as={Link}
      to="/documents/new"
      messageId="page.doc.qr.add_other"
      variant={Variant.secondary}
    />
  </>
);

function DocumentQrcode() {
  const seqId = useSeqIdParam();

  const { data, error } = useDocumentIdQuery({
    variables: { seqId },
  });

  if (error) return <FormattedErrorAlert error={error} />;
  if (!data) return null;

  const { document } = data;

  return (
    <PageLayout
      title={<T keyName="page.doc.view.print_qr" />}
      headerClassName="print:hidden"
      navigation={
        <>
          <PageLayoutNavigation to="/documents">
            <T keyName="nav.documents.list" />
          </PageLayoutNavigation>
          <PageLayoutNavigation to={`/documents/${document.seqId}`}>
            {document.seqId} - {getDocumentTitle(document, true)}
          </PageLayoutNavigation>
        </>
      }
      actions={ACTIONS}
      footer={ACTIONS}
      footerClassName="flex gap-2 print:hidden"
    >
      {/* gap 4px to compensate outline-2 (2px) */}
      <div className="flex flex-col items-center gap-8 print:gap-[4px]">
        {[DocumentView.RECTO, DocumentView.VERSO, DocumentView.OTHER].map(
          (view) => (
            <DocumentShowQr key={view} view={view} document={document} />
          ),
        )}
      </div>
    </PageLayout>
  );
}

export default DocumentQrcode;
