import { endOfDay, startOfDay } from 'date-fns';

import { TableSearchBaseFilters } from '../../components/providers/TableSearchContextProvider';
import { SyncSearchParamsStoreSupportedTypes } from '../SyncSearchParamsStore';
import { SyncSearchParamsWithTermStore } from '../SyncSearchParamsWithTermStore';

import { ScanProcessStatus } from '#gql';

// null is like mine but without filters set (because we want mine is the default value)
export type ScanFiltersOwnerShip = null | 'mine' | 'all';

export interface ScanFilters extends TableSearchBaseFilters {
  statuses: ScanProcessStatus[];
  ownership: ScanFiltersOwnerShip;
  startDate: Date | null;
  endDate: Date | null;
}

export class ScanFiltersStore extends SyncSearchParamsWithTermStore<ScanFilters> {
  public get shapeDef(): Record<
    keyof ScanFilters,
    SyncSearchParamsStoreSupportedTypes
  > {
    return {
      searchTerm: 'string',
      statuses: 'string[]',
      ownership: 'string',
      startDate: 'date',
      endDate: 'date',
    };
  }

  public override get initialData(): ScanFilters {
    return {
      searchTerm: null,
      ownership: null,
      statuses: [],
      startDate: null,
      endDate: null,
    };
  }

  public setStartDate = (startDate: Date | null | undefined) => {
    startDate ??= null;

    if (startDate) {
      startDate = startOfDay(startDate);
    }

    this.mergeData({ startDate });
  };

  public setEndDate = (endDate: Date | null | undefined) => {
    endDate ??= null;

    if (endDate) {
      endDate = endOfDay(endDate);
    }

    this.mergeData({ endDate });
  };

  public setStatuses = (statuses: ScanProcessStatus[]) => {
    statuses ??= [];
    this.mergeData({ statuses });
  };

  public setOwnership = (ownership: ScanFiltersOwnerShip) => {
    ownership ??= null;
    this.mergeData({ ownership });
  };
}
