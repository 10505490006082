import { ReactNode } from 'react';

import { useCheckedLoginState } from '../providers/LoginProvider.context';

import { UserRole } from '#gql';

interface IfHasRoleProps {
  userRole: UserRole;
  children: ReactNode;
}

export default function IfHasRole({ userRole, children }: IfHasRoleProps) {
  const { roles } = useCheckedLoginState();
  const node = roles.includes(userRole) ? children : null;
  return <>{node}</>;
}
