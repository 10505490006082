import { useEffect } from 'react';

export function usePreventUnload(when: boolean) {
  useEffect(() => {
    function preventUnload(event: BeforeUnloadEvent) {
      if (!when) return;

      const message = 'Your changes may not be saved';
      // eslint-disable-next-line no-alert
      if (window.confirm(message)) return;
      event.preventDefault();
      event.stopPropagation();

      // Kept for now, as Chromium <119 didn't support `event.preventDefault()`.
      // eslint-disable-next-line deprecation/deprecation
      event.returnValue = message;
      return message;
    }

    window.addEventListener('beforeunload', preventUnload);

    return () => window.removeEventListener('beforeunload', preventUnload);
  }, [when]);
}
