import { T } from '@tolgee/react';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useFilesUploading } from './providers/FilesUploadingProvider.context';
import FormattedButton from './translation/FormattedButton';

import { FormattedAlert } from '#intl';
import {
  Dropzone,
  DropzoneList,
  DropzoneProps,
  useDropzone,
  useNotificationCenter,
} from '#tailwind_ui';

interface FilesUploadProps {
  accept: Exclude<DropzoneProps['accept'], undefined>;
  onSend: (files: File[]) => Promise<void>;
  // TODO: remove currentUrl and use a ref to know if the component is still rendered.
  currentUrl?: string;
  successRedirectUrl?: string;

  displayAlertOnJpeg?: boolean;
}

export default function FilesUpload(props: FilesUploadProps) {
  const {
    onSend,
    currentUrl,
    successRedirectUrl,
    accept,
    displayAlertOnJpeg = false,
  } = props;

  const { files, dropzoneProps, dropzoneListProps, clearFiles } = useDropzone({
    accept,
  });

  const hasJpeg = useMemo(() => {
    return files.some((file) => file.type === 'image/jpeg');
  }, [files]);

  const [, dispatchUploading] = useFilesUploading();
  const navigate = useNavigate();
  const { addNotification } = useNotificationCenter();

  return (
    <>
      <Dropzone
        {...dropzoneProps}
        message={<T keyName="serie.upload.drag_drop" />}
      />
      {displayAlertOnJpeg && hasJpeg && (
        <FormattedAlert type="warning" messageId="upload.series.alert.jpeg" />
      )}
      <DropzoneList {...dropzoneListProps} showPreview />
      <div className="flex flex-row gap-2">
        <FormattedButton
          messageId="global.send"
          onClick={() => {
            dispatchUploading({ type: 'ADD', payload: files });
            clearFiles();
            void onSend(files)
              .then(() => {
                if (successRedirectUrl && currentUrl === getCurrentPathname()) {
                  navigate(successRedirectUrl);
                }
              })
              .catch((error) => {
                reportError(error);
                addNotification({
                  type: 'danger',
                  title: <T keyName="global.error" />,
                  content: <T keyName="upload.series.alert.failure" />,
                });
              })
              .finally(() => {
                dispatchUploading({ type: 'REMOVE', payload: files });
              });
          }}
          disabled={files.length === 0}
        />
        {files.length > 0 && (
          <FormattedButton
            messageId="serie.upload.empty_list"
            color="danger"
            onClick={clearFiles}
          />
        )}
      </div>
    </>
  );
}

function getCurrentPathname() {
  return window.location.pathname;
}
