import { ArrowsRightLeftIcon } from '@heroicons/react/20/solid';
import { T } from '@tolgee/react';
import clsx from 'clsx';
import { Dispatch, MouseEvent, SetStateAction, useState } from 'react';
import { match } from 'ts-pattern';

import { useRectoVerso } from '../../hooks/useRectoVerso';
import { safeTranslationKeyDocumentView } from '../../translationsMapping/documents';
import { FormattedBadge } from '../translation/FormattedBadge';

import DocumentLink from './DocumentLink';

import emptyImg from '#assets/img/placeholder_image_300x225.png';
import { DocumentView, GqlPadifDocumentSetQuery } from '#gql';
import { Button } from '#tailwind_ui';
import { CardLayout } from '#ui/card_layout';

interface DocumentFaceProps {
  document: GqlPadifDocumentSetQuery['padifAnalysis']['documentSet']['documents']['nodes'][number];
  selected?: boolean;
  onSelect?: Dispatch<SetStateAction<null | string>>;
  className?: string;
}

function defaultOnSelect() {
  // no-op
}

export default function DocumentFace(props: DocumentFaceProps) {
  const { document, selected, onSelect = defaultOnSelect, className } = props;
  const { canBeFlipped, initial, recto, verso } = useRectoVerso(document);
  const [face, setFace] = useState(initial);
  const url = face?.documentThumb ? face.documentThumb.url : emptyImg;

  return (
    <div
      className={clsx('cursor-pointer rounded border-2', className, {
        'border-transparent': !selected,
        'border-primary-500': selected,
      })}
      onClick={() => onSelect(document.id)}
    >
      <CardLayout
        header={
          <img
            className="rounded-t-lg"
            src={url}
            alt="document face"
            width="100%"
          />
        }
        headerClassName="!p-0 !m-0"
        bodyClassName="flex justify-between gap-5"
      >
        <DocumentLink document={document} multiline />

        <FormattedBadge
          className="opacity-70"
          variant="COLORED_BACKGROUND"
          color="neutral"
          label={safeTranslationKeyDocumentView(
            face === recto ? 'RECTO' : 'VERSO',
          )}
          size="large"
        />

        <Button
          roundness="circular"
          disabled={!canBeFlipped}
          onClick={(e: MouseEvent) => {
            e.stopPropagation();
            setFace((docFace) => {
              if (docFace?.view === 'RECTO' && verso) {
                return verso;
              }
              return recto;
            });
          }}
          tooltip={
            canBeFlipped && (
              <T
                keyName={safeTranslationKeyDocumentView(
                  match(face)
                    .returnType<DocumentView>()
                    .with(recto, () => 'VERSO')
                    .otherwise(() => 'RECTO'),
                )}
              />
            )
          }
        >
          <ArrowsRightLeftIcon className="h-5 w-5" />
        </Button>
      </CardLayout>
    </div>
  );
}
