import { Feature } from '../../constants';
import IfHasFeature from '../IfHasFeature';
import LocalePicker from '../LocalePicker';
import IfHasExperimentalFeature from '../experimental-features/IfHasExperimentalFeature';
import IfHasAnyPermissions from '../permissions/IfHasAnyPermission';
import IfHasPermission from '../permissions/IfHasPermission';
import IfHasRole from '../permissions/IfHasRole';
import { useAppMeta } from '../providers/AppMetaProvider.context';
import { useLocale } from '../providers/LocaleProvider.context';
import { useCheckedLoginState } from '../providers/LoginProvider.context';

import LiNavDropdown from './LiNavDropdown';
import LiNavLink from './LiNavLink';
import UploadingFiles from './UploadingFiles';
import UserDropdown from './UserDropdown';

export default function NavigationBar() {
  const { name } = useCheckedLoginState();
  const appMeta = useAppMeta();
  let [locale] = useLocale();
  if (locale === 'nl') {
    // Videos are not available in Dutch.
    locale = 'en';
  }

  return (
    <nav className="flex items-start justify-between py-3 print:hidden">
      <div className="flex items-center">
        <span className="mr-4 py-1 text-lg">{appMeta.title}</span>
        <ul className="flex items-center gap-4">
          <LiNavLink to="/" text="nav.home" />
          <IfHasFeature feature={Feature.documents}>
            <LiNavDropdown text="nav.documents">
              <LiNavDropdown.Link to="/documents" text="nav.documents.list" />
              <IfHasPermission userPermission="document_create">
                <LiNavDropdown.Link
                  to="/documents/new"
                  text="nav.documents.new"
                />
              </IfHasPermission>
              <LiNavDropdown.Link
                to="/documents/labels"
                text="nav.labels.list"
              />
            </LiNavDropdown>
          </IfHasFeature>
          <IfHasFeature feature={Feature.series}>
            <LiNavDropdown text="nav.series">
              <LiNavDropdown.Link to="/series" text="nav.series.list" />
              <IfHasPermission userPermission="serie_create">
                <LiNavDropdown.Link to="/series/new" text="series.new" />
              </IfHasPermission>
            </LiNavDropdown>
          </IfHasFeature>
          <IfHasFeature feature={Feature.documents}>
            <IfHasAnyPermissions userPermissions={['scan_create', 'scan_view']}>
              <LiNavDropdown text="nav.scans">
                <IfHasPermission userPermission="scan_view">
                  <LiNavDropdown.Link to="/scans" text="nav.scans.list" />
                </IfHasPermission>
                <IfHasPermission userPermission="scan_create">
                  <LiNavDropdown.Link to="/upload" text="nav.scans.send" />
                  <LiNavDropdown.Link to="/scanner" text="nav.scans.scanner" />
                </IfHasPermission>
              </LiNavDropdown>
            </IfHasAnyPermissions>
          </IfHasFeature>
          <IfHasFeature feature={Feature.analysis}>
            <IfHasAnyPermissions
              userPermissions={['padifAnalysis_view', 'documentSet_view']}
            >
              <LiNavDropdown text="nav.analysis">
                <IfHasPermission userPermission="documentSet_view">
                  <LiNavDropdown.Link to="/document-sets" text="nav.doc_sets" />
                </IfHasPermission>
                <IfHasPermission userPermission="padifAnalysis_view">
                  <LiNavDropdown.Link to="/padif" text="nav.padif" />
                </IfHasPermission>
              </LiNavDropdown>
            </IfHasAnyPermissions>
          </IfHasFeature>
          <IfHasRole userRole="admin">
            <LiNavDropdown text="nav.admin">
              <LiNavDropdown.Link to="/admin/users" text="nav.admin.users" />
              <LiNavDropdown.Link
                to="/admin/services"
                text="nav.admin.services"
              />
              <IfHasFeature feature={Feature.documents}>
                <LiNavDropdown.Link
                  to="/admin/seizure-authorities"
                  text="nav.admin.seizure_authorities"
                />
              </IfHasFeature>
              <IfHasExperimentalFeature feature="auditLogs">
                <LiNavDropdown.Link
                  to="/admin/audit-logs"
                  text="nav.admin.audit_logs"
                  experimental
                />
              </IfHasExperimentalFeature>
              <LiNavDropdown.Link
                to="/admin/contact"
                text="nav.admin.contact"
              />
            </LiNavDropdown>
          </IfHasRole>
          <LiNavDropdown text="nav.help">
            <LiNavDropdown.HrefLink
              to="/docs/authentication"
              text="nav.help.documentation"
              target="_blank"
            />
            <LiNavDropdown.HrefLink
              to={`/docs/videos?language=${locale}`}
              text="nav.help.videos"
              target="_blank"
            />
            <LiNavDropdown.HrefLink
              to="/docs/updates"
              text="nav.help.changelog"
              target="_blank"
            />
          </LiNavDropdown>
        </ul>
      </div>

      <div className="flex flex-col items-end gap-1">
        <div className="flex items-end gap-1 text-neutral-500">
          <div className="leading-none">{name}</div>
          <UserDropdown />
        </div>
        <div className="mt-1 flex flex-row items-center gap-1">
          <UploadingFiles />
          <LocalePicker />
        </div>
      </div>
    </nav>
  );
}
