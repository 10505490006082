import { useTranslate } from '@tolgee/react';

import { useFormattedSerializeError } from '../../hooks/useFormattedSerializeError';

import { DropzoneFieldRHF, DropzoneFieldRHFProps } from '#tailwind_ui';

interface FormattedDropzoneFieldProps
  extends Omit<DropzoneFieldRHFProps, 'label' | 'message'> {
  accept: Exclude<DropzoneFieldRHFProps['accept'], undefined>;
  label: TranslationKey;
  ns?: string;
  message?: TranslationKey;
}

export default function FormattedDropzoneField(
  props: FormattedDropzoneFieldProps,
) {
  const {
    label: labelProp,
    message: messageProp = 'form.upload.text',
    ns,
    ...otherProps
  } = props;

  const { t } = useTranslate();

  const label = ns ? t(labelProp, { ns }) : t(labelProp);
  const message = messageProp && t(messageProp);
  const serialize = useFormattedSerializeError();

  return (
    <DropzoneFieldRHF
      {...otherProps}
      message={message}
      label={label}
      serializeError={(err) => serialize(err)}
    />
  );
}
