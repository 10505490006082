import { TrashIcon as TrashIconOutline } from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';

import { AsyncFormattedConfirmModal } from '../AsyncFormattedConfirmModal';

import { FileAttachment } from './SerieAttachments';

import { useDeleteSerieAttachmentMutation } from '#gql';

interface DeleteSerieAttachmentProps {
  serieId: string;
  attachment: FileAttachment;
  closeModal: () => void;
}

export default function DeleteSerieAttachment(
  props: DeleteSerieAttachmentProps,
) {
  const { serieId, attachment, closeModal } = props;
  const [deleteSerieAttachment] = useDeleteSerieAttachmentMutation();

  return (
    <AsyncFormattedConfirmModal
      icon={<TrashIconOutline />}
      color="danger"
      onConfirm={() =>
        deleteSerieAttachment({
          variables: { input: { id: serieId, attachmentId: attachment.id } },
        })
      }
      onClose={closeModal}
      title="page.series.delete_attachment.title"
      confirmText="global.delete"
      cancelText="global.cancel"
      open
      body={
        <T
          keyName="page.series.delete_attachment.question"
          params={attachment}
        />
      }
    />
  );
}
