import {
  TRANSLATIONS_KEY_DOCUMENT_FRAUD_TYPE,
  TRANSLATIONS_KEY_DOCUMENT_TYPE,
} from '../translationsMapping/documents';

import IndexedFormattedMessage from './translation/IndexedFormattedMessage';

import { FraudType, DocumentType } from '#gql';

export default function DocumentCell(props: {
  value: {
    docType: DocumentType;
    docCountry: string;
    docFraudType: FraudType;
  };
}) {
  const { value } = props;
  if (value.docType === undefined) return null;
  return (
    <div>
      <IndexedFormattedMessage
        translationMap={TRANSLATIONS_KEY_DOCUMENT_TYPE}
        index={value.docType}
      />
      <div>
        {value.docCountry}
        {' - '}
        <IndexedFormattedMessage
          translationMap={TRANSLATIONS_KEY_DOCUMENT_FRAUD_TYPE}
          index={value.docFraudType}
        />
      </div>
    </div>
  );
}
