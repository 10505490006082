import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useLoginState } from '../providers/LoginProvider.context';

export default function LoginRedirect() {
  const [params] = useSearchParams();
  const login = useLoginState();
  const navigate = useNavigate();

  const redirectUrl = params.get('redirect') || '/';
  useEffect(() => {
    if (login.status === 'authenticated') {
      window.location.href = redirectUrl;
    }
  }, [login, redirectUrl, navigate]);

  return null;
}
