import { T } from '@tolgee/react';

import { FormattedAlert } from '#intl';
import { HighlightedLink } from '#ui/link';

interface ScanRoiImportErrorAlertProps {
  filename: string;
}

export function ScanRoiImportErrorAlert(props: ScanRoiImportErrorAlertProps) {
  const { filename } = props;

  return (
    <FormattedAlert
      type="error"
      title="scan.step.bad_import_state.title"
      titleValues={{ filename }}
    >
      <T
        keyName="scan.step.bad_import_state.description"
        params={{ link: renderLink }}
      />
    </FormattedAlert>
  );
}

function renderLink(link: string) {
  return (
    <HighlightedLink to="/docs/documents/scan" target="_blank">
      {link}
    </HighlightedLink>
  );
}
