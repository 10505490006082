import { ShieldExclamationIcon } from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';
import { FormattedDate, IntlProvider } from 'react-intl';

import Stamp from './Stamp';
import {
  DraftOrPublicationAlert,
  getDate,
  getTranslation,
  isAlertDraft,
} from './utils/draftOrPublication';

import logoIsf from '#assets/img/logo_isf.png';
import logoProfid from '#assets/img/logo_profid.png';
import { mapLocaleToIntlLocale } from '#intl';

interface AlertDocumentHeaderProps {
  alert: DraftOrPublicationAlert;
}

export default function AlertDocumentHeader(props: AlertDocumentHeaderProps) {
  const translation = getTranslation(props.alert);
  const date = getDate(props.alert);
  const isDraft = isAlertDraft(props.alert);
  return (
    <header className="relative grid h-32 grid-cols-4 border-2 border-neutral-400 px-3 py-2">
      <div className="col-span-1">
        <div
          className="h-full w-full bg-contain bg-center bg-no-repeat"
          style={{ backgroundImage: `url(${logoProfid})` }}
        />
      </div>
      <div className="col-span-2 flex flex-col items-center justify-evenly text-2xl font-bold">
        <div className="flex items-center gap-2 text-warning-700">
          <ShieldExclamationIcon className="h-6 w-6" />
          <span className="uppercase">
            <T
              keyName="series.alert.field.preview.title"
              language={translation?.language}
              ns="alert"
            />
          </span>
          <ShieldExclamationIcon className="h-6 w-6" />
        </div>
        <div className="text-xl">{props.alert.serie.seqId}</div>
        {isDraft ? (
          <div className="mb-1 mt-1 -rotate-6">
            <Stamp size="md">
              <T keyName="global.draft" language={translation?.language} />
            </Stamp>
          </div>
        ) : (
          <div className="text-lg font-normal text-neutral-600">
            <IntlProvider locale={mapLocaleToIntlLocale[translation?.language]}>
              <FormattedDate value={date} />
            </IntlProvider>
          </div>
        )}
      </div>
      <div className="col-span-1">
        <div
          className="h-full w-full bg-contain bg-center bg-no-repeat"
          style={{ backgroundImage: `url(${logoIsf})` }}
        />
      </div>
    </header>
  );
}
