import { T } from '@tolgee/react';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';

interface LiNavLinkProps {
  to: string;
  text: TranslationKey;
}

export default function LiNavLink(props: LiNavLinkProps) {
  return (
    <NavLink
      to={props.to}
      end
      className={({ isActive }) =>
        clsx(
          'rounded px-2 py-1 hover:bg-neutral-100',
          isActive && 'underline underline-offset-4',
        )
      }
    >
      <T keyName={props.text} />
    </NavLink>
  );
}
