import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { T } from '@tolgee/react';
import clsx from 'clsx';
import { ReactNode, useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import { NavLink, useLocation } from 'react-router-dom';

import ExperimentalIcon from '../experimental-features/ExperimentalIcon';

import { Portal, useOnOff } from '#tailwind_ui';

interface DropdownNavItemProps {
  text: TranslationKey;
  to: string;
  experimental?: boolean;
}

interface DropdownNavProps {
  text: TranslationKey;
  children: ReactNode;
}

export default function LiNavDropdown(props: DropdownNavProps) {
  const location = useLocation();
  const [contentRef, setContentRef] = useState<HTMLDivElement | null>(null);
  const [targetRef, setTargetRef] = useState<HTMLButtonElement | null>(null);
  const [isOpen, open, close] = useOnOff();

  // ensure dropdown portal is close after navigation change
  useEffect(close, [location.pathname, close]);

  const { styles } = usePopper(targetRef, contentRef, {
    placement: 'bottom-start',
  });

  return (
    <li onPointerLeave={close} onPointerEnter={open}>
      <button
        className="flex cursor-default items-end"
        type="button"
        ref={setTargetRef}
      >
        <T keyName={props.text} />
        <ChevronDownIcon className="inline-block h-5 w-5" />
      </button>
      {isOpen && (
        <Portal>
          <div className="pt-1" ref={setContentRef} style={styles.popper}>
            <div className="rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              {props.children}
            </div>
          </div>
        </Portal>
      )}
    </li>
  );
}

function DropdownNavLink(props: DropdownNavItemProps) {
  const { to, text, experimental } = props;
  return (
    <NavLink
      className={({ isActive }) =>
        clsx(
          'block whitespace-nowrap px-4 py-2 hover:bg-neutral-100',
          isActive && 'underline underline-offset-4',
        )
      }
      to={to}
      end
    >
      <T keyName={text} />
      {experimental && <ExperimentalIcon />}
    </NavLink>
  );
}

interface HrefNavLinkProps extends DropdownNavItemProps {
  target?: '_blank';
}

function HrefNavLink(props: HrefNavLinkProps) {
  const { text, to, target, experimental } = props;

  return (
    <a
      href={to}
      target={target}
      className="block whitespace-nowrap px-4 py-2 hover:bg-neutral-100"
    >
      <T keyName={text} />
      {experimental && <ExperimentalIcon />}
    </a>
  );
}

LiNavDropdown.HrefLink = HrefNavLink;
LiNavDropdown.Link = DropdownNavLink;
