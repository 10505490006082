import { PencilIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';

import { Feature } from '../../constants';
import IfHasFeature from '../IfHasFeature';
import PadifLink from '../Padif/PadifLink';
import {
  CaseInformation,
  CaseIsDocKept,
  CaseIsProfiling,
  CaseOperation,
  CasePresumedNationality,
  CaseRelatedOffense,
  CaseSeizureAuthority,
  CaseSeizureContext,
  CaseSeizureDatePlace,
  CaseSeries,
  DocBitmapAnalysis,
  DocBitmapCode,
  DocCountry,
  DocEmissionLocation,
  DocFraudType,
  DocInternetPurchaseURL,
  DocIsBackgroundFluo,
  DocIsMrzOk,
  DocIsReported,
  DocMedium,
  DocOther,
  DocPrintTable,
  DocType,
} from '../ViewComponents';
import IfHasPermission from '../permissions/IfHasPermission';
import { useDocumentContext } from '../providers/DocumentProvider.context';

import DocSerialNumber from './DocSerialNumber';
import DocumentExtraImages from './DocumentExtraImages';
import { DocumentStandardizedImagesView } from './DocumentStandardizedImages';
import SearchAnalysisModal from './SearchAnalysisModal';

import { Button, Roundness } from '#tailwind_ui';
import { CardLayout } from '#ui/card_layout';
import { getDocumentTitle } from '#utils/index';

const cardLinkClassName =
  'absolute top-0 right-0 flex h-full flex-col items-center justify-around pr-4 sm:pr-6';

export default function DocumentFull() {
  const { document } = useDocumentContext();

  return (
    <div className="grid grid-cols-2 gap-4">
      <CardLayout title="page.doc.view.seizure_information">
        <CaseOperation />
        <CaseSeizureDatePlace />
        <CaseSeizureAuthority />
        <CaseSeizureContext />
        <CaseRelatedOffense />
        <CasePresumedNationality />
      </CardLayout>

      <CardLayout title="page.doc.view.case_information">
        <CaseInformation />
        <DocInternetPurchaseURL />
        <CaseIsDocKept />
        <CaseIsProfiling />
        <IfHasFeature feature={Feature.series}>
          <CaseSeries />
        </IfHasFeature>
      </CardLayout>

      <CardLayout title="page.doc.view.general_information">
        <div className="mb-2">
          <DocType />
          <DocFraudType />
          <DocCountry />
          <DocSerialNumber />
        </div>
        <DocEmissionLocation />

        {document.padifAnalyses.length > 0 && (
          <CardLayout title="page.doc.view.padif_analysis">
            {document.padifAnalyses.map((padifAnalysis) => (
              <PadifLink key={padifAnalysis.id} padifAnalysis={padifAnalysis} />
            ))}
          </CardLayout>
        )}
      </CardLayout>

      <CardLayout
        title="page.doc.view.doc_analysis"
        extraTitle={
          <div className={cardLinkClassName}>
            <SearchAnalysisModal document={document} />
          </div>
        }
        headerClassName="relative space-x-2"
        bodyClassName="flex flex-col gap-2"
      >
        <DocMedium />

        <DocPrintTable />
        <DocIsBackgroundFluo />
        <DocBitmapCode />
        <DocBitmapAnalysis />

        <DocIsReported />
        <DocIsMrzOk />

        <DocOther />
      </CardLayout>

      <CardLayout
        title="doc.standardized_images"
        extraTitle={
          <IfHasPermission userPermission="document_update">
            <div className={cardLinkClassName}>
              <Button
                roundness={Roundness.circular}
                as={Link}
                to={`/documents/${document.seqId}/standardized-images/manage`}
              >
                <PencilIcon className="h-5 w-5" />
              </Button>
            </div>
          </IfHasPermission>
        }
        headerClassName="relative space-x-2"
      >
        <DocumentStandardizedImagesView
          classNames="grid grid-cols-1 lg:grid-cols-2"
          docId={document.id}
          images={document.images}
          scans={document.unfinishedScans}
          readonly
        />
      </CardLayout>
      <CardLayout
        title="doc.extra_images"
        extraTitle={
          <IfHasPermission userPermission="document_update">
            <div className={cardLinkClassName}>
              <Button
                roundness={Roundness.circular}
                as={Link}
                to={`/documents/${document.seqId}/extra-images/manage`}
              >
                <PencilIcon className="h-5 w-5" />
              </Button>
            </div>
          </IfHasPermission>
        }
        headerClassName="relative space-x-2"
      >
        <DocumentExtraImages
          extraImages={document.extraImages}
          docId={document.id}
          docName={getDocumentTitle(document, true)}
          readonly
        />
      </CardLayout>
    </div>
  );
}
