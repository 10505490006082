import { T } from '@tolgee/react';

import { possibleLocales } from '../../providers/LocaleProvider.context';
import EditableCell from '../EditableCell';

import {
  GqlSeizureAuthoritiesListQuery,
  Locale,
  useUpdateSeizureAuthorityMutation,
} from '#gql';
import { Color, Td, useNotificationCenter } from '#tailwind_ui';

interface AdminSeizureAuthoritiesRowProps {
  seizureAuthority: GqlSeizureAuthoritiesListQuery['seizureAuthorities'][0];
}

export default function AdminSeizureAuthorityRow(
  props: AdminSeizureAuthoritiesRowProps,
) {
  const { seizureAuthority } = props;
  const [updateSeizureAuthority] = useUpdateSeizureAuthorityMutation();
  const { addNotification } = useNotificationCenter();

  function mutate(newValue: string, locale: Locale) {
    updateSeizureAuthority({
      variables: {
        input: {
          id: seizureAuthority.id,
          locale,
          name: newValue,
        },
      },
    }).catch((error) => {
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        for (const graphQLError of error.graphQLErrors as Error[]) {
          addNotification({
            type: Color.danger,
            title: <T keyName={graphQLError.message as TranslationKey} />,
          });
        }
      } else {
        addNotification({
          type: Color.danger,
          title: <T keyName="global.error.unknown" />,
        });
      }
    });
  }

  return (
    <tr>
      <Td>{seizureAuthority.identifier}</Td>
      {possibleLocales.map((locale) => {
        const localeUp = (locale[0].toUpperCase() +
          locale.slice(1)) as Capitalize<Locale>;
        return (
          <EditableCell
            key={locale}
            value={seizureAuthority[`name${localeUp}`]}
            hasValue={seizureAuthority[`hasTranslation${localeUp}`]}
            mutate={mutate}
            locale={locale}
          />
        );
      })}
    </tr>
  );
}
