import { useMemo } from 'react';

import FacetFilterSelectList, {
  FacetFilterSelectListProps,
} from '../../TableSearch/FacetFilterSelectList';

import { useLabelsQuery } from '#gql';

export type LabelFilterSelectListProps<
  Filter,
  Prop extends keyof Filter,
> = Omit<
  FacetFilterSelectListProps<Filter, Prop>,
  'optionLookup' | 'displayLookup'
>;

export default function LabelFilterSelectList<
  Filter,
  Prop extends keyof Filter,
>(props: LabelFilterSelectListProps<Filter, Prop>) {
  const { data } = useLabelsQuery();
  const labels = data?.labels.nodes;

  const lookup = useMemo(() => {
    const lookup: Record<string, string> = {};

    for (const label of labels ?? []) {
      lookup[label.id] = label.name;
    }

    return lookup;
  }, [labels]);

  if (!labels) return null;

  return (
    <FacetFilterSelectList
      nullOptionLabel="global.without_labels"
      {...props}
      optionLookup={lookup}
      displayLookup={lookup}
      searchLookup={lookup}
    />
  );
}
