import { T } from '@tolgee/react';

import { FormattedTable } from '../../../../../components/translation/FormattedTable';
import { useDocumentsContext } from '../../../../../contexts/documents';
import { useLocalizedCountryMap } from '../../../../../hooks/localizedCountries';

import { BaseStat } from './get_stats';

import { Td, Th } from '#tailwind_ui';
import { HighlightedLink } from '#ui/link';
import { assert } from '#utils/assert';

export interface TopCountriesProps {
  title: TranslationKey;
  searchKey: string;
  countries: BaseStat[];
}

export default function TopCountries(props: TopCountriesProps) {
  const { title, searchKey, countries } = props;
  const { store } = useDocumentsContext();

  const allCountries = useLocalizedCountryMap();
  if (!allCountries) return null;

  return (
    <div className="m-1 p-1">
      <FormattedTable<BaseStat>
        data={countries}
        getId={(data) => data.name || data.value}
        renderHeader={() => renderHeader(title)}
        renderTr={(value, index) =>
          renderTr(value, index, searchKey, store.reset, allCountries)
        }
      />
    </div>
  );
}

function renderHeader(title: TranslationKey) {
  return (
    <tr>
      <Th />
      <Th className="w-full">
        <div className="flex-1 text-center">
          <T keyName={title} />
        </div>
      </Th>
      <Th>
        <T keyName="global.cases" />
      </Th>
    </tr>
  );
}

function renderTr(
  value: BaseStat,
  index: number,
  searchKey: string,
  resetFilters: () => void,
  allCountries: Record<string, string>,
) {
  assert(value.name);

  return (
    <tr key={value.name} className="text-center">
      <Td compact className="p-1">
        {index + 1}
      </Td>
      <Td compact className="p-1" title={allCountries[value.name]}>
        <HighlightedLink
          onClick={resetFilters}
          to={`/documents?${searchKey}=${value.name}`}
        >
          {value.name}
        </HighlightedLink>
      </Td>
      <Td
        compact
        className="whitespace-nowrap p-1"
      >{`${value.value} (${value.percent}%)`}</Td>
    </tr>
  );
}
