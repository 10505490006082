import { AuditLogSortField } from '@zakodium/profid-shared';
import { createContext, useContext } from 'react';

import { TableSearchContext } from '../components/providers/TableSearchContextProvider';
import {
  AuditLogFilters,
  AuditLogFiltersStore,
} from '../stores/searches/AuditLogFiltersStore';

export const auditLogContext = createContext<TableSearchContext<
  AuditLogSortField,
  AuditLogFilters,
  AuditLogFiltersStore
> | null>(null);

export function useAuditLogContext() {
  const context = useContext(auditLogContext);

  if (context === null) {
    throw new Error(
      'useAuditLogContext must be used within a TableSearchContextProvider',
    );
  }

  return context;
}
