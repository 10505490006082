import { T } from '@tolgee/react';
import { Link } from 'react-router-dom';

import IfHasPermission from '../permissions/IfHasPermission';
import FormattedButton from '../translation/FormattedButton';

import PadifListTable from './PadifListTable';

import { PageLayout } from '#ui/page_layout';

export default function PadifIndexPage() {
  return (
    <PageLayout
      title={<T keyName="page.padif_list.title" />}
      actions={
        <IfHasPermission userPermission="padifAnalysis_create">
          <FormattedButton
            as={Link}
            to="/padif/new"
            messageId="page.padif.new.title"
          />
        </IfHasPermission>
      }
    >
      <PadifListTable />
    </PageLayout>
  );
}
