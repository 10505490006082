import { T } from '@tolgee/react';

import { Feature } from '../../constants';
import { useDocumentsContext } from '../../contexts/documents';
import { UseTableSelect } from '../../hooks/useTableSelection';
import IfHasFeature from '../IfHasFeature';
import {
  TableSelection,
  TableSelectionRenderHeaderProps,
} from '../TableSelection';
import FormattedEmptyState from '../translation/FormattedEmptyState';

import { DocumentListRow } from './DocumentListRow';
import { DocumentListQueryVariables } from './documentListLocationState';

import { GqlDocumentsQuery } from '#gql';
import { Th } from '#tailwind_ui';
import { HighlightedLink } from '#ui/link';

interface DocumentListTableProps {
  data: GqlDocumentsQuery['documents']['nodes'];
  documentsQueryVariables: DocumentListQueryVariables;
  totalCount: number;
  selection: UseTableSelect<string>;
}

type Document = GqlDocumentsQuery['documents']['nodes'][number];

export default function DocumentListTable({
  data,
  documentsQueryVariables,
  totalCount,
  selection,
}: DocumentListTableProps) {
  const ctx = useDocumentsContext();

  return (
    <TableSelection<Document, Document['id']>
      data={data}
      renderEmpty={renderEmpty}
      renderHeader={renderHeader}
      renderTr={(props) => (
        <DocumentListRow
          {...props}
          documentsQueryVariables={documentsQueryVariables}
        />
      )}
      sort={ctx.tableSort}
      pagination={{
        ...ctx.tablePagination,
        totalCount,
      }}
      selection={selection}
    />
  );
}

function renderHeader(
  props: TableSelectionRenderHeaderProps<Document, Document['id']>,
) {
  return (
    <tr>
      {props.checkboxTh}
      <Th className="w-[200px]">
        <T keyName="global.image" />
      </Th>
      <Th>
        <T keyName="doc.list.table.header.exhibit_and_labels" />
      </Th>
      <Th className="w-[80px]">
        <T keyName="doc.table.service" />
      </Th>
      <Th className="w-[350px]">
        <T keyName="doc.field.type" />
      </Th>
      <IfHasFeature feature={Feature.series}>
        <Th>
          <T keyName="global.series" />
        </Th>
      </IfHasFeature>
      <Th>
        <T keyName="doc.field.operation" />
      </Th>
      <Th>
        <T keyName="doc.field.document_number" />
      </Th>
      <Th sortField="caseSeizureDate" className="w-[150px]">
        <T keyName="doc.field.seizure_date" />
      </Th>
      <Th sortField="createdAt" className="w-[150px]">
        <T keyName="doc.table.created_at" />
      </Th>
    </tr>
  );
}

function renderEmpty() {
  return (
    <FormattedEmptyState title="doc.search.noresults">
      <HighlightedLink to="new">
        <T keyName="nav.documents.new" />
      </HighlightedLink>
    </FormattedEmptyState>
  );
}
