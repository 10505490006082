import clsx from 'clsx';
import { ElementType } from 'react';
import { useFormState } from 'react-hook-form';

import { Button, ButtonProps } from '../../elements/buttons/Button';

export type SubmitProps<T extends ElementType = 'button'> = Omit<
  ButtonProps<T>,
  'type'
>;

export function SubmitButtonRHF<T extends ElementType = 'button'>(
  props: SubmitProps<T>,
) {
  const { disabled, className, as, ...otherProps } = props;
  const { isSubmitting } = useFormState();

  return (
    <Button
      disabled={isSubmitting || disabled}
      type="submit"
      className={clsx('sm:self-start', className)}
      as={as as ElementType}
      {...otherProps}
    />
  );
}
