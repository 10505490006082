import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  from,
  InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
// @ts-expect-error Has no TS types and fails with checkJs.
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { ReactNode, useEffect, useMemo, useRef } from 'react';

import { useAppMeta } from '../components/providers/AppMetaProvider.context';
import { getUserLocale } from '../components/providers/LocaleProvider.context';
import {
  useCheckSession,
  useCleanupSession,
  useLoginState,
} from '../components/providers/LoginProvider.context';
import { apiUrl } from '../constants';

import { fragmentTypes } from '#gql';

const LAST_SESSION_CHECK_KEY = 'profid-last-session-check';
// Time to wait after the theoretical timeout before actually checking the session.
const SESSION_TIMEOUT_CHECK_OFFSET = 30_000;
// Interval used to check whether the session is still theoretically valid or needs to be checked.
const SESSION_TIMEOUT_CHECK_INTERVAL = 5_000;

// Use localStorage to synchronize the most recent check.
function syncLastSessionCheck() {
  localStorage.setItem(LAST_SESSION_CHECK_KEY, Date.now().toString());
}

function getLastSessionCheck() {
  const lastSessionCheck = localStorage.getItem(LAST_SESSION_CHECK_KEY);
  if (!lastSessionCheck) {
    return 0;
  }
  return Number.parseInt(lastSessionCheck, 10);
}

export function InternalApolloProvider(props: { children: ReactNode }) {
  const cleanupSession = useCleanupSession();
  const checkSessionTimeout = useCheckSessionTimeout();

  const apollo = useMemo(() => {
    const localeLink = new ApolloLink((operation, forward) => {
      operation.setContext({
        headers: {
          'x-locale': getUserLocale(),
        },
      });
      return forward(operation);
    });
    const heartBeatLink = new ApolloLink((operation, forward) => {
      checkSessionTimeout.current.reset();
      return forward(operation);
    });
    const errorLink = onError(({ networkError }) => {
      if (
        networkError &&
        'statusCode' in networkError &&
        networkError.statusCode === 401
      ) {
        cleanupSession(client);
      }
    });
    const uploadLink = createUploadLink({
      uri: `${apiUrl}/graphql`,
      credentials: 'include',
      headers: {
        'Apollo-Require-Preflight': 'true',
      },
    });

    const client = new ApolloClient({
      // https://www.apollographql.com/docs/react/caching/cache-configuration#customizing-cache-ids
      cache: new InMemoryCache({
        possibleTypes: fragmentTypes.possibleTypes,
        typePolicies: {
          DocumentImage: {
            keyFields: ['id', 'isCorrected'],
          },
          DocumentViewImage: {
            keyFields: ['id', 'isCorrected'],
          },
          DocumentConnection: {
            keyFields: false,
          },
          Document: {
            fields: {
              similarSerialNumbers: {
                merge: false,
              },
            },
          },
          SerieConnection: {
            keyFields: false,
          },
          Scan: {
            fields: {
              importData: {
                merge: false,
              },
            },
          },
        },
      }),
      link: from([localeLink, heartBeatLink, errorLink, uploadLink]),
      defaultOptions: {
        query: {
          fetchPolicy: 'network-only',
        },
        watchQuery: {
          fetchPolicy: 'network-only',
        },
      },
    });

    return client;
  }, [cleanupSession, checkSessionTimeout]);
  return <ApolloProvider client={apollo}>{props.children}</ApolloProvider>;
}

function useCheckSessionTimeout() {
  const { sessionTimeout } = useAppMeta();
  const checkSession = useCheckSession();
  const loginState = useLoginState();
  const isLoggedIn = loginState.status === 'authenticated';

  const checkSessionTimeout = useRef<{
    lastReset: number;
    reset: () => void;
  }>({
    lastReset: 0,
    reset() {
      syncLastSessionCheck();
      this.lastReset = Date.now();
    },
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!isLoggedIn) {
        return;
      }
      const timeout = sessionTimeout + SESSION_TIMEOUT_CHECK_OFFSET;
      const otherThreadLastReset = getLastSessionCheck();
      const lastReset = Math.max(
        checkSessionTimeout.current.lastReset,
        otherThreadLastReset,
      );

      if (lastReset !== 0 && Date.now() - lastReset > timeout) {
        checkSessionTimeout.current.lastReset = 0;
        checkSession()
          .then((authenticated) => {
            if (authenticated) {
              checkSessionTimeout.current.reset();
            }
          })
          .catch(reportError);
      }
    }, SESSION_TIMEOUT_CHECK_INTERVAL);
    return () => clearInterval(intervalId);
  }, [checkSession, sessionTimeout, isLoggedIn]);

  return checkSessionTimeout;
}
