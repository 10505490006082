import { T } from '@tolgee/react';

import {
  usePaginationTable,
  UsePaginationTableInitial,
} from '../../../hooks/usePaginationTable';
import { useSortTable, UseSortTableInitial } from '../../../hooks/useSortTable';
import { possibleLocales } from '../../providers/LocaleProvider.context';
import FormattedEmptyState from '../../translation/FormattedEmptyState';
import FormattedErrorAlert from '../../translation/FormattedErrorAlert';
import { FormattedTable } from '../../translation/FormattedTable';

import AdminServiceRow from './AdminServiceRow';

import { ServiceSortField, useServicesListQuery } from '#gql';
import { Th } from '#tailwind_ui';

const initialPagination = {
  label: 'service.list.pagination',
} as const satisfies Partial<UsePaginationTableInitial>;
const initialSort = {
  field: 'identifier',
  direction: 'ASC',
} as const satisfies UseSortTableInitial<ServiceSortField>;

export default function AdminServicesTable() {
  const { pagination, tablePagination } = usePaginationTable(initialPagination);
  const [sort, tableSort] = useSortTable(initialSort);

  const queryResult = useServicesListQuery({
    variables: {
      ...pagination,
      sortBy: sort,
    },
  });

  const error = queryResult.error;
  const data = queryResult.data || queryResult.previousData;

  if (error) return <FormattedErrorAlert error={error} />;
  if (!data) return null;

  const tableData = data.services.nodes;

  function renderTr(value: (typeof tableData)[0]) {
    return <AdminServiceRow key={value.id} service={value} />;
  }

  return (
    <FormattedTable
      data={tableData}
      sort={tableSort}
      renderTr={renderTr}
      renderHeader={renderHeader}
      renderEmpty={renderEmpty}
      pagination={{
        ...tablePagination,
        totalCount: data.services.totalCount,
      }}
    />
  );
}

const localeColumnWidth = `${80 / possibleLocales.length}%`;
function renderHeader() {
  return (
    <tr>
      <Th sortField="identifier">
        <T keyName="global.identifier" />
      </Th>
      {possibleLocales.map((locale) => (
        <Th key={locale} style={{ width: localeColumnWidth }}>
          <T keyName="global.name" />
          {` (${locale})`}
        </Th>
      ))}
    </tr>
  );
}

function renderEmpty() {
  return <FormattedEmptyState title="global.noresults" />;
}
