import { T } from '@tolgee/react';
import clsx from 'clsx';
import { ReactNode } from 'react';

// eslint-disable-next-line no-restricted-imports
import { Card, CardProps } from '#tailwind_ui';

export interface CardLayoutProps {
  /* --- slots --- */
  /**
   * rendered by FormattedMessage into `<Card.Header>`
   */
  title?: TranslationKey;
  /**
   * bound as last <Card.Header> children
   */
  extraTitle?: ReactNode;
  /**
   * Fallback if no `title` props, bound as `<Card.Header>` children
   */
  header?: ReactNode;
  /**
   * bound as <Card.Body> children
   */
  children: ReactNode;
  /**
   * bound as <Card.Footer> children
   */
  footer?: ReactNode;

  /* --- props --- */
  /**
   * className for header / body / footer shortcut
   * help for tailwind autocomplete
   * add `".*ClassName"` into key `classAttributes` tailwindcss intellisense config
   *
   * @see https://github.com/tailwindlabs/tailwindcss-intellisense#tailwindcssclassattributes
   */
  cardClassName?: string;
  headerClassName?: string;
  bodyClassName?: string;
  footerClassName?: string;

  cardProps?: Omit<CardProps, 'children'>;
  headerProps?: Omit<Parameters<typeof Card.Header>[0], 'children'>;
  bodyProps?: Omit<Parameters<typeof Card.Body>[0], 'children'>;
  footerProps?: Omit<Parameters<typeof Card.Footer>[0], 'children'>;
}

export function CardLayout(props: CardLayoutProps) {
  return (
    <Card
      {...props.cardProps}
      className={clsx(props.cardProps?.className, props.cardClassName)}
    >
      {(props.title || props.header || props.extraTitle) && (
        <Card.Header
          {...props.headerProps}
          className={clsx(
            'bg-neutral-50 font-bold',
            props.headerProps?.className,
            props.headerClassName,
          )}
        >
          {props.title ? <T keyName={props.title} /> : props.header}
          {props.extraTitle}
        </Card.Header>
      )}

      <Card.Body
        {...props.bodyProps}
        className={clsx(props.bodyProps?.className, props.bodyClassName)}
      >
        {props.children}
      </Card.Body>

      {props.footer && (
        <Card.Footer
          {...props.footerProps}
          className={clsx(props.footerProps?.className, props.footerClassName)}
        >
          {props.footer}
        </Card.Footer>
      )}
    </Card>
  );
}
