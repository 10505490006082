import {
  ArrowPathIcon,
  EnvelopeIcon,
  KeyIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/20/solid';
import { T } from '@tolgee/react';

import FormattedRelativeDate from '../../translation/FormattedRelativeDate';

import ActivateMailtoLink from './ActivateMailtoLink';
import { UserListModalState } from './AdminUsersIndexPage';
import { UserStateBadge } from './UserStatusBadge';

import { GqlUsersQuery } from '#gql';
import { AddNotification, ClipboardButton, IconButton, Td } from '#tailwind_ui';
import { getActivateURL } from '#utils/index';

interface AdminUsersRowProps {
  user: GqlUsersQuery['users']['nodes'][0];
  onModal: (m: UserListModalState) => void;
  loggedIn: string;
  addNotification: AddNotification;
}

export default function AdminUsersRow(props: AdminUsersRowProps) {
  const { user, onModal, loggedIn, addNotification } = props;

  return (
    <tr>
      <Td>{user.name}</Td>
      <Td>{user.email}</Td>
      <Td title={user.service?.name}>{user.service?.identifier || '-'}</Td>
      <Td>
        <UserStateBadge
          isDisabled={user.isDisabled}
          isConfirmed={user.isConfirmed}
        />
      </Td>
      <Td>{user.roles.length > 0 ? user.roles.join(', ') : '-'}</Td>
      <Td>
        {user.lastLogin ? (
          <FormattedRelativeDate value={user.lastLogin} />
        ) : (
          '-'
        )}
      </Td>
      <Td className="flex flex-row items-center gap-1 align-middle">
        {user.id !== loggedIn && (
          <IconButton
            size="5"
            icon={<KeyIcon />}
            onClick={() => onModal({ type: 'CHANGE_ROLES', user })}
            tooltip={<T keyName="page.users.action.change_roles" />}
          />
        )}

        <IconButton
          size="5"
          icon={<PencilIcon />}
          onClick={() => onModal({ type: 'CHANGE_USER', user })}
          tooltip={<T keyName="page.users.action.edit_user" />}
        />
        {user.id !== loggedIn && !user.isDisabled && user.isConfirmed && (
          <IconButton
            size="5"
            icon={<ArrowPathIcon />}
            onClick={() => onModal({ type: 'RESET_PASSWORD', user })}
            tooltip={<T keyName="page.users.action.reset_password" />}
          />
        )}
        {!user.isConfirmed && !user.isDisabled && (
          <ActivateMailtoLink email={user.email} token={user.activationToken}>
            <IconButton
              size="5"
              icon={<EnvelopeIcon />}
              tooltip={<T keyName="page.users.action.send_email" />}
            />
          </ActivateMailtoLink>
        )}
        {!user.isConfirmed && !user.isDisabled && (
          <ClipboardButton
            onCopy={() =>
              Promise.resolve(
                getActivateURL(user.activationToken as string, user.email),
              )
            }
            onSuccess={() =>
              addNotification({
                type: 'success',
                title: <T keyName="admin.create_user.copied_to_clipboard" />,
              })
            }
            tooltip={<T keyName="page.users.action.copy_link" />}
          />
        )}
        {!user.isConfirmed && !user.name && (
          <IconButton
            size="5"
            icon={<TrashIcon />}
            onClick={() => onModal({ type: 'DELETE_USER', user })}
            tooltip={<T keyName="page.users.action.delete_user" />}
          />
        )}
      </Td>
    </tr>
  );
}
