import { T } from '@tolgee/react';
import { Link } from 'react-router-dom';

import { useSeqIdParam } from '../../hooks/useParams';
import FormattedButton from '../translation/FormattedButton';

import { DocumentStandardizedImagesEdition } from './DocumentStandardizedImages';

import { useDocumentStandardizedImagesQuery } from '#gql';
import { PageLayout, PageLayoutNavigation } from '#ui/page_layout';
import { getDocumentTitle } from '#utils/index';

export default function DocumentManageStandardizedImage() {
  const seqId = useSeqIdParam();

  const { data, error } = useDocumentStandardizedImagesQuery({
    variables: { seqId },
  });

  if (error) throw error;
  if (!data) return null;

  return (
    <PageLayout
      title={<T keyName="doc.standardized_images" />}
      navigation={
        <>
          <PageLayoutNavigation to="/documents">
            <T keyName="nav.documents.list" />
          </PageLayoutNavigation>
          <PageLayoutNavigation to={`/documents/${seqId}`}>
            {seqId} - {getDocumentTitle(data.document, true)}
          </PageLayoutNavigation>
        </>
      }
      actions={
        <FormattedButton
          messageId="doc.return"
          variant="white"
          as={Link}
          to={`/documents/${data.document.seqId}`}
        />
      }
    >
      <DocumentStandardizedImagesEdition
        classNames="columns-3"
        docId={data.document.id}
        images={data.document.images}
      />
    </PageLayout>
  );
}
