import { useTranslate } from '@tolgee/react';

import { useDateFormat } from './providers/LocaleProvider.context';

import { DatePicker } from '#tailwind_ui';

interface DateRangePickerProps {
  start?: Date;
  end?: Date;
  onStartChange: (date: Date | null) => void;
  onEndChange: (date: Date | null) => void;
  startPlaceholderId?: TranslationKey;
  endPlaceholderId?: TranslationKey;
  readOnly?: boolean;
}

export default function DateRangePicker({
  start,
  end,
  onStartChange,
  onEndChange,
  startPlaceholderId,
  endPlaceholderId,
  readOnly,
}: DateRangePickerProps) {
  const { t } = useTranslate();
  const dateFormat = useDateFormat();

  return (
    <div>
      <DatePicker
        name="startDate"
        label={t('global.daterangepicker.start.label')}
        hiddenLabel
        placeholderText={startPlaceholderId ? t(startPlaceholderId) : undefined}
        isClearable
        showYearDropdown
        dropdownMode="select"
        dateFormat={dateFormat}
        openToDate={start || end}
        value={start || null}
        filterDate={(date) => {
          if (end) return date <= end;
          return true;
        }}
        onChange={onStartChange}
        readOnly={readOnly}
      />
      <DatePicker
        name="endDate"
        label={t('global.daterangepicker.end.label')}
        className="mt-2"
        hiddenLabel
        placeholderText={endPlaceholderId ? t(endPlaceholderId) : undefined}
        isClearable
        showYearDropdown
        dropdownMode="select"
        dateFormat={dateFormat}
        openToDate={end || start}
        value={end || null}
        filterDate={(date) => {
          if (start) return date >= start;
          return true;
        }}
        onChange={onEndChange}
        readOnly={readOnly}
      />
    </div>
  );
}
