import { T } from '@tolgee/react';
import { PropsWithChildren } from 'react';

import { useUserContext } from '../../../contexts/user';
import { useSyncTableSearchParams } from '../../../hooks/useSyncTableSearchParams';
import { IndexFilterError } from '../../TableSearch/IndexFilterError';
import { TableSearchLayout } from '../../TableSearch/TableSearchLayout';
import { useCheckedLoginState } from '../../providers/LoginProvider.context';
import FormattedEmptyState from '../../translation/FormattedEmptyState';
import { FormattedTable } from '../../translation/FormattedTable';

import { UserListModalState } from './AdminUsersIndexPage';
import AdminUsersRow from './AdminUsersRow';
import { AdminUsersTableFilters } from './AdminUsersTableFilters';
import CopyEmailsToClipboard from './CopyEmailsToClipboard';

import { GqlUsersQuery, useUsersQuery } from '#gql';
import { Th, useNotificationCenter } from '#tailwind_ui';

export interface AdminUsersTableProps extends PropsWithChildren {
  openModal: (state: UserListModalState) => void;
}

export default function AdminUsersTable(props: AdminUsersTableProps) {
  const { openModal, children } = props;

  const { addNotification } = useNotificationCenter();
  const context = useUserContext();
  const login = useCheckedLoginState();

  const usersQueryResult = useUsersQuery({
    variables: {
      ...context.pagination,
      sortBy: context.sort,
      filterBy: context.filters,
    },
  });

  const error = usersQueryResult.error;
  const data = usersQueryResult.data || usersQueryResult.previousData;

  useSyncTableSearchParams(context);

  if (error) return <IndexFilterError error={error} context={context} />;
  if (!data) return null;

  function renderTr(user: GqlUsersQuery['users']['nodes'][number]) {
    return (
      <AdminUsersRow
        key={user.id}
        user={user}
        onModal={openModal}
        loggedIn={login.id}
        addNotification={addNotification}
      />
    );
  }

  return (
    <TableSearchLayout
      context={context}
      advancedFilter={<AdminUsersTableFilters />}
      searchBarExtra={
        <>
          <span className="flex-1" />
          <CopyEmailsToClipboard />
        </>
      }
    >
      {children}

      <FormattedTable
        data={data.users.nodes}
        renderTr={renderTr}
        renderHeader={renderHeader}
        renderEmpty={renderEmpty}
        sort={context.tableSort}
        pagination={{
          ...context.tablePagination,
          totalCount: data.users.totalCount,
        }}
      />
    </TableSearchLayout>
  );
}

function renderHeader() {
  return (
    <tr>
      <Th className="text-start" sortField="lastName">
        <T keyName="global.user" />
      </Th>
      <Th className="text-start" sortField="email">
        <T keyName="global.email" />
      </Th>
      <Th sortField="service">
        <T keyName="global.service" />
      </Th>
      <Th sortField="status">
        <T keyName="global.status" />
      </Th>
      <Th className="text-start">
        <T keyName="global.roles" />
      </Th>
      <Th sortField="lastLogin">
        <T keyName="user.last_login" />
      </Th>
      <Th className="text-start">
        <T keyName="global.actions" />
      </Th>
    </tr>
  );
}

function renderEmpty() {
  return <FormattedEmptyState title="global.noresults" />;
}
