import clsx from 'clsx';
import { ReactNode } from 'react';

export default function Stamp({
  children,
  size: variant,
}: {
  children: ReactNode;
  size: 'md' | 'xl';
}) {
  return (
    <div
      className={clsx('stamp', {
        'stamp-md': variant === 'md',
        'stamp-xl': variant === 'xl',
      })}
    >
      {children}
    </div>
  );
}
