import { TranslationKey } from '@tolgee/react';

import { safeMappedTranslationKey } from './common';

export type MeasuresSentencesIndex =
  | 'PROFID'
  | 'EXTEND'
  | 'SIENA'
  | 'TERRORISM'
  | 'TRAFFICKING';

export const TRANSLATION_KEY_PREDEFINED_MEASURES_SENTENCES = {
  PROFID: 'series.alert.field.measures.predefined.report_to_profid',
  TERRORISM: 'series.alert.field.measures.predefined.consider_terrorism',
  TRAFFICKING:
    'series.alert.field.measures.predefined.consider_human_trafficking',
  EXTEND: 'series.alert.field.measures.predefined.extend_collection',
  SIENA: 'series.alert.field.measures.predefined.report_to_siena',
} as const satisfies Record<MeasuresSentencesIndex, TranslationKey>;

export const TRANSLATION_KEY_PREDEFINED_MEASURES_SENTENCES_PARTIAL = {
  PROFID: 'series.alert.field.measures.predefined.report_to_profid.partial',
  TERRORISM:
    'series.alert.field.measures.predefined.consider_terrorism.partial',
  TRAFFICKING:
    'series.alert.field.measures.predefined.consider_human_trafficking.partial',
  EXTEND: 'series.alert.field.measures.predefined.extend_collection.partial',
  SIENA: 'series.alert.field.measures.predefined.report_to_siena.partial',
} as const satisfies Record<MeasuresSentencesIndex, TranslationKey>;

export function safeTranslationKeyMeasuresSentences(
  index: MeasuresSentencesIndex,
) {
  return safeMappedTranslationKey(
    TRANSLATION_KEY_PREDEFINED_MEASURES_SENTENCES,
    index,
  );
}

export function safeTranslationKeyMeasuresPartialSentences(
  index: MeasuresSentencesIndex,
) {
  return safeMappedTranslationKey(
    TRANSLATION_KEY_PREDEFINED_MEASURES_SENTENCES_PARTIAL,
    index,
  );
}
