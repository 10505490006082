import { createContext, useContext } from 'react';
import { match } from 'ts-pattern';

import { FixableImportDataStep } from '../../../../../components/scans/importData/utils/types';
import { ScanImportSwitchTab } from '../../../../../translationsMapping/scanSwitchTab';

import { ScanImportDataStep } from './switch_tab_context_provider';

export interface SwitchTabContext {
  tab: ScanImportSwitchTab;
  setTab: (newTab: ScanImportSwitchTab) => void;
  hasMrz: boolean;
}

export const switchTabContext = createContext<SwitchTabContext | null>(null);

export function useSwitchTabContext() {
  const ctx = useContext(switchTabContext);
  if (!ctx) throw new Error('SwitchTabContext not found');
  return ctx;
}

export function getSwitchStep(
  step: FixableImportDataStep,
  hasMrz: boolean,
): ScanImportSwitchTab {
  return match<ScanImportDataStep, ScanImportSwitchTab>(step)
    .with('ProcessFindRegions', () => 'anonymization')
    .with('ProcessReview', 'ProcessFinalize', () => 'anonymization')
    .with('ProcessAnalyze', () => 'raw')
    .with('ProcessExtract', () => 'extraction')
    .with('ProcessRotate', () => 'rotation')
    .with('ProcessMRZ', () => (hasMrz ? 'mrz' : 'rotation'))
    .exhaustive();
}
