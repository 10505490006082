import { createContext, useContext } from 'react';

import { Locale } from '#gql';

export const LOCALE_KEY = 'profid-locale';

const sortedLocales: Record<Locale, Locale> = {
  en: 'en',
  fr: 'fr',
  de: 'de',
  nl: 'nl',
};
export const possibleLocales = Object.values(sortedLocales);

const dateFormats: Record<Locale, string> = {
  en: 'dd/MM/yyyy',
  fr: 'dd/MM/yyyy',
  de: 'd.M.yyyy',
  nl: 'd-M-yyyy',
};

function getPreferredLocale(): Locale {
  const locales = navigator.languages;
  let preferredLocale: Locale = 'en';
  if (locales) {
    for (const locale of locales) {
      if (possibleLocales.includes(locale as Locale)) {
        preferredLocale = locale as Locale;
        break;
      }
    }
  }
  return preferredLocale;
}

export function getUserLocale(): Locale {
  const storedLocale = localStorage.getItem(LOCALE_KEY);
  if (!storedLocale) {
    return getPreferredLocale();
  }
  if (!possibleLocales.includes(storedLocale as Locale)) {
    return getPreferredLocale();
  }
  return storedLocale as Locale;
}

export type LocaleContext = [
  locale: Locale,
  setLocale: (newLocale: Locale) => void,
];

export const localeContext = createContext<LocaleContext>([
  'en',
  () => {
    throw new Error('Locale context not initialized');
  },
]);

export function useLocale(): LocaleContext {
  return useContext(localeContext);
}

export function useDateFormat(): string {
  const [locale] = useLocale();
  return dateFormats[locale];
}
