import { ReactNode } from 'react';
import { KbsProvider } from 'react-kbs';

import ErrorBoundary from '../components/ErrorBoundary';
import IntlLocaleProvider from '../components/IntlLocaleProvider';
import TolgeeLocaleProvider from '../components/TolgeeLocaleProvider';
import { AppMetaProvider } from '../components/providers/AppMetaProvider';
import { FilesUploadingProvider } from '../components/providers/FilesUploadingProvider';
import { LocaleProvider } from '../components/providers/LocaleProvider';
import {
  CheckSessionOnOutsideLogin,
  LoginProvider,
} from '../components/providers/LoginProvider';
import { PreventNavigationProvider } from '../contexts/PreventNavigation/PreventNavigation';
import { InternalApolloProvider } from '../contexts/apollo';
import { MandatoryChildren } from '../global';
import { AuditLogTableSearchProvider } from '../providers/AuditLogTableSearchProvider';
import { DocumentSetCandidateTableSearchProvider } from '../providers/DocumentSetCandidateTableSearchProvider';
import { DocumentSetTableSearchProvider } from '../providers/DocumentSetTableSearchProvider';
import { DocumentTableSearchProvider } from '../providers/DocumentTableSearchProvider';
import { LabelTableSearchProvider } from '../providers/LabelTableSearchProvider';
import { PadifTableSearchProvider } from '../providers/PadifTableSearchProvider';
import { ScanTableSearchProvider } from '../providers/ScanTableSearchProvider';
import { SeriesTableSearchProvider } from '../providers/SeriesTableSearchProvider';
import { UserTableSearchProvider } from '../providers/UserTableSearchProvider';

import Router from './router';

import { NotificationProvider, TooltipProvider } from '#tailwind_ui';

function StrictMode(props: { children: ReactNode }) {
  // Blocked on https://github.com/zakodium/profid/issues/264
  return <>{props.children}</>;
}

export default function App() {
  return (
    <StrictMode>
      <UIProviders>
        <NetworkProviders>
          <LocalizationProviders>
            <NavigationProviders>
              <Router />
            </NavigationProviders>
          </LocalizationProviders>
        </NetworkProviders>
      </UIProviders>
    </StrictMode>
  );
}

function UIProviders({ children }: MandatoryChildren) {
  return (
    <KbsProvider>
      <TooltipProvider>
        <NotificationProvider>{children}</NotificationProvider>
      </TooltipProvider>
    </KbsProvider>
  );
}

function NetworkProviders({ children }: MandatoryChildren) {
  return (
    <AppMetaProvider>
      <LoginProvider>
        <InternalApolloProvider>
          <CheckSessionOnOutsideLogin />
          {children}
        </InternalApolloProvider>
      </LoginProvider>
    </AppMetaProvider>
  );
}

function LocalizationProviders({ children }: MandatoryChildren) {
  return (
    <TolgeeLocaleProvider>
      <LocaleProvider>
        <IntlLocaleProvider>{children}</IntlLocaleProvider>
      </LocaleProvider>
    </TolgeeLocaleProvider>
  );
}

function NavigationProviders({ children }: MandatoryChildren) {
  return (
    <ErrorBoundary>
      <PreventNavigationProvider>
        <FilesUploadingProvider>
          <SearchesProviders>{children}</SearchesProviders>
        </FilesUploadingProvider>
      </PreventNavigationProvider>
    </ErrorBoundary>
  );
}

function SearchesProviders({ children }: MandatoryChildren) {
  return (
    <DocumentTableSearchProvider>
      <SeriesTableSearchProvider>
        <LabelTableSearchProvider>
          <DocumentSetTableSearchProvider>
            <DocumentSetCandidateTableSearchProvider>
              <AuditLogTableSearchProvider>
                <PadifTableSearchProvider>
                  <ScanTableSearchProvider>
                    <UserTableSearchProvider>
                      {children}
                    </UserTableSearchProvider>
                  </ScanTableSearchProvider>
                </PadifTableSearchProvider>
              </AuditLogTableSearchProvider>
            </DocumentSetCandidateTableSearchProvider>
          </DocumentSetTableSearchProvider>
        </LabelTableSearchProvider>
      </SeriesTableSearchProvider>
    </DocumentTableSearchProvider>
  );
}
