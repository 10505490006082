import { MrzLineImage } from './importData/types';

export interface MRZLinesCompareProps {
  mrzLines?: MrzLineImage[];
  mrz: string[];
}

export type MRZLinesCompareLines = Array<{
  image: {
    id: string;
    url: string;
  } | null;
  mrz: string;
}>;

export default function MRZLinesCompare(props: MRZLinesCompareProps) {
  const { mrzLines = [], mrz } = props;

  const compareLines: MRZLinesCompareLines = [];
  if (mrzLines.length > mrz.length) {
    for (const [idx, mrzLine] of mrzLines.entries()) {
      compareLines.push({
        mrz: mrz[idx] || '',
        image: mrzLine,
      });
    }
  } else {
    for (const [idx, line] of mrz.entries()) {
      compareLines.push({
        mrz: line,
        image: mrzLines[idx] || null,
      });
    }
  }

  // Get the maximum width between every mrz line (number of chars)
  const maxCharLength = Math.max(
    ...compareLines.map((line) => line.mrz.length),
  );

  const maxWidth = maxCharLength * 17;
  return (
    <div
      className="flex flex-col gap-1 font-ocrb text-[21px]"
      style={{ maxWidth }}
    >
      {compareLines.map((mrzLine, idx) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div key={idx} className="flex flex-col">
            <div
              className="inline-flex flex-col gap-1"
              style={{
                width: `${(mrzLine.mrz.length / maxCharLength) * 100}%`,
              }}
            >
              {mrzLine.image ? (
                <img
                  alt={`Line ${idx}`}
                  className="w-full object-contain px-[2px]"
                  src={mrzLine.image.url}
                />
              ) : (
                <div className="h-6 w-full bg-neutral-200" />
              )}
              <div className="flex justify-between leading-tight">
                {mrzLine.mrz.split('').map((char, idx) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={idx}>{char}</div>
                ))}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
