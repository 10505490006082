import { useTranslate } from '@tolgee/react';
import { useMemo } from 'react';

import FacetFilterSelectList, {
  FacetFilterSelectListProps,
} from '../../TableSearch/FacetFilterSelectList';

export default function IsBackgroundFluoSelectList<
  Filter,
  Prop extends keyof Filter,
>(
  props: Omit<
    FacetFilterSelectListProps<Filter, Prop>,
    'optionLookup' | 'displayLookup'
  >,
) {
  const { t } = useTranslate();

  const displayLookup = useMemo(
    () => ({
      true: t('global.fluorescent'),
      false: t('global.nonfluorescent'),
    }),
    [t],
  );

  return (
    <FacetFilterSelectList
      {...props}
      optionLookup={displayLookup}
      displayLookup={displayLookup}
    />
  );
}
