import { T } from '@tolgee/react';
import clsx from 'clsx';
import { Dispatch, SetStateAction } from 'react';
import { match } from 'ts-pattern';

import DocumentSetTitle from '../DocumentSet/DocumentSetTitle';
import DocumentFace from '../document/DocumentFace';
import IfHasPermission from '../permissions/IfHasPermission';
import FormattedButton from '../translation/FormattedButton';
import FormattedErrorAlert from '../translation/FormattedErrorAlert';
import FormattedPagination from '../translation/FormattedPagination';
import useFormattedErrorNotificationEffect from '../translation/hooks/useFormattedErrorNotificationEffect';

import { useChangePadifReference } from './hooks/usePadifReference';

import { GqlPadifAnalysisQuery, GqlPadifDocumentSetQuery } from '#gql';
import { Color } from '#tailwind_ui';
import { H3 } from '#ui/heading';
import { PageLayout, PageLayoutNavigation } from '#ui/page_layout';

interface PadifShowInitProps {
  padif: GqlPadifAnalysisQuery['padifAnalysis'];
  onCancel?: () => void;
  onSuccess?: () => void;
}

export default function PadifShowInit({
  padif,
  onCancel,
  onSuccess = () => {
    // no-op
  },
}: PadifShowInitProps) {
  return (
    <PageLayout
      title={<T keyName="page.padif.title" params={{ id: padif.id }} />}
      navigation={
        <>
          <PageLayoutNavigation to="/padif">
            <T keyName="nav.padif" />
          </PageLayoutNavigation>
          <PageLayoutNavigation to={`/document-sets/${padif.documentSet.id}`}>
            <DocumentSetTitle value={padif.documentSet} />
          </PageLayoutNavigation>
        </>
      }
    >
      <IfHasPermission
        userPermission="padifAnalysis_update"
        otherwise={<ShowNoReference />}
      >
        <SelectReferenceDocument
          padif={padif}
          onSuccess={onSuccess}
          onCancel={onCancel}
        />
      </IfHasPermission>
    </PageLayout>
  );
}

interface SelectReferenceDocumentProps {
  padif: GqlPadifAnalysisQuery['padifAnalysis'];
  onSuccess: () => void;
  onCancel?: () => void;
}

function SelectReferenceDocument({
  padif,
  onSuccess,
  onCancel,
}: SelectReferenceDocumentProps) {
  const {
    selectedReference,
    setSelectedReference,
    setReferenceMutation,
    removeReferenceMutation,
    page,
    handlePageChange,
    rowsPerPage,
    query,
    mutationError,
  } = useChangePadifReference(padif.id, 4);

  useFormattedErrorNotificationEffect(mutationError);

  if (query.error) {
    return <FormattedErrorAlert error={query.error} />;
  }
  if (!query.data) {
    return null;
  }

  return (
    <>
      <div className="my-2 flex items-center justify-between gap-5">
        <H3>
          <T keyName="page.padif.select_ref" />
        </H3>
        <ReferenceActionButtons
          onSuccess={onSuccess}
          onCancel={onCancel}
          padif={padif}
          removeReferenceMutation={removeReferenceMutation}
          setReferenceMutation={setReferenceMutation}
          selectedReference={selectedReference}
        />
      </div>

      <PadifSelectReference
        data={query.data}
        handlePageChange={handlePageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        selectedReference={selectedReference}
        setSelectedReference={setSelectedReference}
      />
    </>
  );
}

interface PadifSelectReferenceProps {
  data: GqlPadifDocumentSetQuery;
  selectedReference: string | null;
  setSelectedReference: Dispatch<SetStateAction<string | null>>;
  handlePageChange: Dispatch<SetStateAction<number>>;
  page: number;
  rowsPerPage: number;
}

export function PadifSelectReference({
  data,
  selectedReference,
  setSelectedReference,
  page,
  handlePageChange,
  rowsPerPage,
}: PadifSelectReferenceProps) {
  const documents = data.padifAnalysis.documentSet.documents.nodes;
  const totalCount = data.padifAnalysis.documentSet.documents.totalCount;

  // dynamic columns clamp between 2 and 4
  const gridCols = match(documents.length)
    .with(1, 2, () => 'grid-cols-2')
    .with(3, () => 'grid-cols-3')
    .otherwise(() => 'grid-cols-4');

  return (
    <>
      {totalCount === 0 && (
        <div>
          <T keyName="page.padif.empty_set" />
        </div>
      )}
      <div className={clsx('grid gap-2', gridCols)}>
        {documents.map((document) => (
          <DocumentFace
            key={document.id}
            document={document}
            selected={selectedReference === document.id}
            onSelect={setSelectedReference}
          />
        ))}
      </div>
      <div className="mt-2">
        <FormattedPagination
          onChange={handlePageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          totalCount={totalCount}
        />
      </div>
    </>
  );
}

function ShowNoReference() {
  return (
    <div>
      <T keyName="page.padif.no_reference" />
    </div>
  );
}

interface ReferenceActionButtonsProps {
  selectedReference: string | null;
  removeReferenceMutation: ReturnType<
    typeof useChangePadifReference
  >['removeReferenceMutation'];
  setReferenceMutation: ReturnType<
    typeof useChangePadifReference
  >['setReferenceMutation'];
  onCancel?: () => void;
  onSuccess: () => void;
  padif: GqlPadifAnalysisQuery['padifAnalysis'];
}

export function ReferenceActionButtons({
  selectedReference,
  setReferenceMutation,
  removeReferenceMutation,
  onCancel,
  onSuccess = () => {
    // no-op
  },
  padif,
}: ReferenceActionButtonsProps) {
  return (
    <div className="flex flex-row gap-2">
      {onCancel && (
        <FormattedButton
          messageId="global.cancel"
          onClick={onCancel}
          variant="white"
        />
      )}

      {padif.referenceDocument && (
        <FormattedButton
          messageId="page.padif.del_reference"
          onClick={() => {
            void removeReferenceMutation();
          }}
          color={Color.danger}
        />
      )}
      <FormattedButton
        messageId="page.padif.set_reference"
        onClick={() => {
          void setReferenceMutation().then(onSuccess);
        }}
        color={Color.primary}
        disabled={!selectedReference}
      />
    </div>
  );
}
