import { ApolloError } from '@apollo/client';
import { T } from '@tolgee/react';
import { useSearchParams } from 'react-router-dom';

import { GenericTableSearchContext } from '../providers/TableSearchContextProvider';
import FormattedButton from '../translation/FormattedButton';
import FormattedErrorAlert from '../translation/FormattedErrorAlert';

export interface IndexFilterErrorProps<
  Context extends GenericTableSearchContext,
> {
  error: ApolloError;
  context: Context;
  onDismiss?: () => void;
  className?: string;
}
export function IndexFilterError<Context extends GenericTableSearchContext>(
  props: IndexFilterErrorProps<Context>,
) {
  const { error, context, onDismiss, className } = props;

  const [searchParams] = useSearchParams();

  return (
    <>
      <FormattedErrorAlert
        error={error}
        onDismiss={onDismiss}
        className={className}
      />

      {searchParams.size > 0 && (
        <>
          <p>
            <T keyName="global.possibly_invalid_filters" />
          </p>
          <FormattedButton
            messageId="global.reset"
            onClick={context.store.reset}
          />
        </>
      )}
    </>
  );
}
