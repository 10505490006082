import { useMemo } from 'react';

import {
  Badge,
  BadgeVariant,
  Color,
  customOptionsFilter,
  OptionsFilter,
  SearchSelect,
  useSearchSelect,
} from '#tailwind_ui';
import { assert } from '#utils/assert';

export interface SelectItem<V> {
  value: V;
  count?: number;
  searchLabel: string;
  optionLabel: string;
  displayLabel: string;
}

interface SelectListProps<V> {
  name: string;
  placeholder: string;
  options: Array<SelectItem<V>>;
  selected: Array<SelectItem<V>>;
  onAdd: (value: SelectItem<V>) => void;
  onRemove: (value: SelectItem<V>) => void;
}

const optionsFilter = customOptionsFilter<
  SelectItem<string | boolean | number>
>((option) => {
  return option.searchLabel;
});

export default function SelectList<V>(props: SelectListProps<V>) {
  const { onAdd, onRemove, selected, options, ...selectProps } = props;

  const filteredOptions = useMemo(() => {
    return options.filter(
      (option) =>
        !selected.some((selOption) => selOption.value === option.value),
    );
  }, [options, selected]);

  const searchSelectProps = useSearchSelect<SelectItem<V>>({
    options: filteredOptions,
    filterOptions: optionsFilter as unknown as OptionsFilter<SelectItem<V>>,
  });
  return (
    <div>
      <SearchSelect<SelectItem<V>>
        {...selectProps}
        {...searchSelectProps}
        selected={undefined}
        label="Select filters"
        hiddenLabel
        placeholder={selectProps.placeholder}
        inputClassName="!placeholder-neutral-500"
        getValue={(option) => String(option.value)}
        onChange={(option) => {
          if (option) {
            onAdd(option);
          }
        }}
        renderOption={(option) => option.optionLabel}
      />
      <div className="mt-2 flex flex-wrap gap-1">
        {selected.map((sel) => {
          return (
            <Badge
              variant={BadgeVariant.COLORED_BACKGROUND}
              color={Color.primary}
              key={String(sel.value)}
              label={sel.displayLabel || sel.searchLabel}
              onDismiss={() => {
                const item = selected.find((el) => el.value === sel.value);
                assert(item);
                if (onRemove) {
                  onRemove(item);
                }
              }}
            />
          );
        })}
      </div>
    </div>
  );
}
