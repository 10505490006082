import { endOfDay, startOfDay } from 'date-fns';

import { TableSearchBaseFilters } from '../../components/providers/TableSearchContextProvider';
import { SyncSearchParamsStoreSupportedTypes } from '../SyncSearchParamsStore';
import { SyncSearchParamsWithTermStore } from '../SyncSearchParamsWithTermStore';

import {
  DocumentMedium,
  DocumentType,
  DocumentView,
  FraudType,
  PrintProcess,
} from '#gql';

export interface DocumentFilters extends TableSearchBaseFilters {
  labels: Array<string | null>;

  caseService: string[];
  docType: DocumentType[];
  docFraudType: FraudType[];
  docCountry: string[];

  series: Array<string | null>;
  casePresumedNationality: Array<string | null>;

  seizureStart: Date | null;
  seizureEnd: Date | null;

  scanView: Array<DocumentView | null>;
  extraImageView: Array<DocumentView | null>;

  docIsMrzOk: Array<boolean | null>;
  docMedium: Array<DocumentMedium | null>;
  docPrintBackground: Array<PrintProcess | null>;
  docPrintNumber: Array<PrintProcess | null>;
  docPrintData: Array<PrintProcess | null>;
  docPrintPhoto: Array<PrintProcess | null>;
  docIsBackgroundFluo: Array<boolean | null>;
  docIsBitmapCode: Array<boolean | null>;
}

export class DocumentFiltersStore extends SyncSearchParamsWithTermStore<DocumentFilters> {
  public get shapeDef() {
    return {
      searchTerm: 'string',
      labels: 'string[]',

      caseService: 'string[]',
      docType: 'string[]',
      docFraudType: 'string[]',
      docCountry: 'string[]',

      series: 'string[]',
      casePresumedNationality: 'string[]',

      seizureStart: 'date',
      seizureEnd: 'date',

      scanView: 'string[]',
      extraImageView: 'string[]',

      docIsMrzOk: 'boolean[]',
      docMedium: 'string[]',
      docPrintBackground: 'string[]',
      docPrintNumber: 'string[]',
      docPrintData: 'string[]',
      docPrintPhoto: 'string[]',
      docIsBackgroundFluo: 'boolean[]',
      docIsBitmapCode: 'boolean[]',
    } as const satisfies Record<
      keyof DocumentFilters,
      SyncSearchParamsStoreSupportedTypes
    >;
  }

  public get initialData(): DocumentFilters {
    return {
      searchTerm: null,

      labels: [],

      caseService: [],
      docType: [],
      docFraudType: [],
      docCountry: [],

      series: [],
      casePresumedNationality: [],

      seizureStart: null,
      seizureEnd: null,

      scanView: [],
      extraImageView: [],

      docIsMrzOk: [],
      docMedium: [],
      docPrintBackground: [],
      docPrintNumber: [],
      docPrintData: [],
      docPrintPhoto: [],
      docIsBackgroundFluo: [],
      docIsBitmapCode: [],
    };
  }

  public setSeizureStart = (seizureStart: Date | null) => {
    seizureStart ??= null;

    if (seizureStart) {
      seizureStart = startOfDay(seizureStart);
    }

    this.mergeData({ seizureStart });
  };

  public setSeizureEnd = (seizureEnd: Date | null) => {
    seizureEnd ??= null;

    if (seizureEnd) {
      seizureEnd = endOfDay(seizureEnd);
    }

    this.mergeData({ seizureEnd });
  };
}
