import { GqlSerieIdentifierFieldsFragment } from '#gql';

interface SerieHeadingProps {
  serie: GqlSerieIdentifierFieldsFragment;
}

export function SerieHeadingBreadcrumb(props: SerieHeadingProps) {
  const { serie } = props;
  return (
    <>
      {serie.seqId}
      {/* force space for title page */}
      <span className="ml-1 text-neutral-500">{` ${serie.name}`}</span>
    </>
  );
}
