import { T } from '@tolgee/react';
import { useWatch } from 'react-hook-form';

import FormattedButton from '../translation/FormattedButton';
import FormattedFormError from '../translation/FormattedFormError';
import FormattedInputField from '../translation/FormattedInputField';
import FormattedSubmitButton from '../translation/FormattedSubmitButton';

import { getUpdateSeriesNameValidationSchema } from './newSerieForm';

import {
  GqlSerieQuery,
  useSerieNamesQuery,
  useUpdateSerieNameMutation,
} from '#gql';
import { FormattedAlert } from '#intl';
import { FormRHFModal } from '#tailwind_ui';

interface UpdateSerieNameModalProps {
  serie: GqlSerieQuery['serie'];
  onRequestClose: () => void;
}

export default function UpdateSerieNameModal(props: UpdateSerieNameModalProps) {
  const { onRequestClose, serie } = props;
  const [editName] = useUpdateSerieNameMutation();
  const { data } = useSerieNamesQuery();

  if (!data) {
    return null;
  }
  const validate = getUpdateSeriesNameValidationSchema(
    'page.series.rename_series.exists',
    data.series.nodes
      .flatMap((node) => [node.name, ...(node.aliases || [])])
      // It's allowed to rename a series to an existing alias within the same series
      .filter((name) => !serie.aliases?.includes(name)),
  );

  return (
    <FormRHFModal<{ name: string }>
      isOpen
      defaultValues={{ name: serie.name }}
      onRequestClose={onRequestClose}
      validationSchema={validate}
      onSubmit={async (values) => {
        await editName({
          variables: {
            input: {
              id: serie.id,
              name: values.name,
            },
          },
        });
        onRequestClose();
      }}
    >
      <FormRHFModal.Header>
        <T keyName="page.series.edit_name.title" />
      </FormRHFModal.Header>
      <FormRHFModal.Body className="w-96">
        <FormattedInputField name="name" label="global.name" />
        <FormattedAlert
          type="info"
          messageId="page.series.edit_name.alias_info"
        />
        <FormattedFormError />
      </FormRHFModal.Body>
      <FormRHFModal.Footer>
        <FormattedButton
          messageId="global.cancel"
          variant="white"
          onClick={onRequestClose}
        />
        <FormSubmitButton serie={serie} />
      </FormRHFModal.Footer>
    </FormRHFModal>
  );
}

function FormSubmitButton(props: { serie: GqlSerieQuery['serie'] }) {
  const name = useWatch({ name: 'name' });
  const disabled = name === props.serie.name;
  return (
    <FormattedSubmitButton
      color="primary"
      messageId="global.change"
      disabled={disabled}
    />
  );
}
