import { ScanImportSwitchTab } from '../../../translationsMapping/scanSwitchTab';

function key(scanId: string): string {
  return `import_scan_tab_${scanId}`;
}

export function scanImportGetTab(scanId: string): ScanImportSwitchTab | null {
  const item = window.sessionStorage.getItem(key(scanId));
  if (!item) return null;

  return item as ScanImportSwitchTab;
}

export function scanImportSetTab(scanId: string, value: ScanImportSwitchTab) {
  window.sessionStorage.setItem(key(scanId), value);
}

export function scanImportRemoveTab(scanId: string) {
  window.sessionStorage.removeItem(key(scanId));
}
