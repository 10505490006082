/**
 * instances of this class are interoperable with React useSyncExternalStore
 * usable children must implement `get initialData`
 *
 * - provide minimal API subscribe and getSnapshot for useSyncExternalStore
 * - with internal API to manage listeners
 *   - child helpers like `newSnapshot` based upon `structuredClone`
 *     to prepare new data before edit (React works best with immutable values)
 *   - `emitChanges` child must call after a data change
 * - and a public reset method
 *
 * @see https://react.dev/reference/react/useSyncExternalStore
 */
export abstract class Store<Data> {
  /**
   * Must be implemented by child class
   * Must return new ref each call
   * Must return data generated without dependencies from this
   */
  public get initialData(): Data {
    throw new Error('not implemented');
  }

  /**
   * use only if `get initialData()` cannot be implemented in class body
   * @param data
   */
  public setInitialData(data: Data) {
    // break possible reference so data cannot change in scope
    data = structuredClone(data);

    Object.defineProperty(this, 'initialData', {
      get() {
        // ensure each call returns a new reference
        return structuredClone(data);
      },
    });
  }

  protected data: Data;
  private listeners = new Set<() => void>();

  public constructor() {
    this.data = this.initialData;
  }

  /**
   * subscribe function to give directly to useSyncExternalStore as first argument
   * @param listener
   */
  public readonly subscribe = (listener: () => void) => {
    this.listeners.add(listener);

    return () => void this.listeners.delete(listener);
  };

  /**
   * getSnapshot function to give directly to useSyncExternalStore as second argument
   */
  public readonly getSnapshot = (): Data => {
    return this.data;
  };

  /**
   * helper to prepare new data before make some mutation
   *
   * @example of typical store mutator
   * ```ts
   * public setValue(value: string) {
   *  this.newSnapshot();
   *
   *  this.data.foo = value;
   *
   *  this.emitChanges();
   * }
   * ```
   *
   * @protected
   */
  protected readonly newSnapshot = (): void => {
    this.data = structuredClone(this.data);
  };

  /**
   * helper to call listeners of store. must be called after a data mutation
   *
   * @protected
   */
  protected emitChanges() {
    for (const listener of this.listeners) {
      try {
        listener();
      } catch (error) {
        reportError(error);
      }
    }
  }

  /**
   * Reset data to this.initialData
   */
  public reset = () => {
    this.data = this.initialData;

    this.emitChanges();
  };
}
