import { createContext, useContext } from 'react';

import { DispatchPreventNavigationContext } from './reducer';

/**
 * private context provider, only one in App top level mounted from PreventNavigationProvider
 */
export const PreventNavigationContext =
  createContext<DispatchPreventNavigationContext | null>(null);

/**
 * expose dispatch method of PreventNavigationProvider reducer
 * prefer usePreventNavigation hook
 */
export function usePreventNavigationContext() {
  const context = useContext(PreventNavigationContext);
  if (context === null) {
    throw new Error(
      'usePreventNavigationContext must be used within a PreventNavigationProvider',
    );
  }
  return context;
}
