import {
  ArrowUturnLeftIcon,
  ArrowUturnRightIcon,
} from '@heroicons/react/20/solid';
import { T } from '@tolgee/react';
import React, { useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useScanIdParam } from '../../../hooks/useParams';
import FormattedButton from '../../translation/FormattedButton';
import FormattedErrorAlert from '../../translation/FormattedErrorAlert';

import StableRotatedImage from './StableRotatedImage';

import { useRotateScanImportMutation, useScanQuery } from '#gql';
import { FormattedAlert } from '#intl';
import { scanImportRemoveTab } from '#scan_import';
import { AlertButton, Button } from '#tailwind_ui';
import { CardLayout } from '#ui/card_layout';
import { PageLayout, PageLayoutNavigation } from '#ui/page_layout';

export function ScanRoiRotatePage() {
  const scanId = useScanIdParam();

  const { data, error } = useScanQuery({
    variables: { id: scanId },
  });

  if (error) {
    return <FormattedErrorAlert error={error} />;
  }

  if (!data?.scan) return null;

  const importData = data.scan.importData;

  if (
    !(
      importData?.__typename === 'ProcessFindRegions' ||
      importData?.__typename === 'ProcessMRZ' ||
      importData?.__typename === 'ProcessReview'
    )
  ) {
    return (
      <FormattedAlert
        type="warning"
        title="scan.step.correct.anonymization.wrong_step_alert.title"
      >
        <div>
          <T keyName="scan.step.correct.wrong_step_alert.generic_description" />
        </div>
        <Link to={`/scans/${data.scan.id}`}>
          <AlertButton>
            <T keyName="scan.step.correct.wrong_step_alert.back_button" />
          </AlertButton>
        </Link>
      </FormattedAlert>
    );
  }

  return (
    <PageLayout
      title={<T keyName="nav.scans.list.import.orientation" />}
      navigation={
        <>
          <PageLayoutNavigation to="/scans">
            <T keyName="nav.scans.list" />
          </PageLayoutNavigation>
          <PageLayoutNavigation to="./../">
            {data.scan.filename}
          </PageLayoutNavigation>
        </>
      }
    >
      <RotateScanImage
        initialAngle={importData.parameters.rotate}
        scanId={data.scan.id}
        image={importData.extractedDocument.image}
      />
    </PageLayout>
  );
}

interface RotateScanImageProps {
  initialAngle: number;
  scanId: string;
  image: {
    id: string;
    url: string;
  };
}

function RotateScanImage(props: RotateScanImageProps) {
  const { image, scanId, initialAngle } = props;

  const [angle, setAngle] = useState(initialAngle);

  const navigate = useNavigate();
  const [rotateScanImportMutation] = useRotateScanImportMutation({
    variables: { id: scanId, rotate: angle },
  });

  const rotate = useCallback(
    (direction: 'l' | 'r') => () => {
      return setAngle(
        (oldAngle) =>
          ((direction === 'r' ? oldAngle + 90 : oldAngle - 90) + 360) % 360,
      );
    },
    [setAngle],
  );

  const save = useCallback(async () => {
    await rotateScanImportMutation();
    navigate('./../');
    scanImportRemoveTab(scanId);
  }, [navigate, rotateScanImportMutation, scanId]);

  return (
    <CardLayout
      bodyClassName="flex flex-1 items-center justify-center"
      footerProps={{
        className: 'flex flex-1 items-center justify-between gap-2',
      }}
      header={<T keyName="scan.step.correct.rotate.title" />}
      footer={
        <>
          <div className="flex flex-1 items-center gap-2">
            <Button onClick={rotate('l')} roundness="circular">
              <ArrowUturnLeftIcon className="h-5 w-5" />
            </Button>
            <Button onClick={rotate('r')} roundness="circular">
              <ArrowUturnRightIcon className="h-5 w-5" />
            </Button>
          </div>
          <FormattedButton
            messageId="global.back"
            as={Link}
            to="./../"
            color="neutral"
          />
          <FormattedButton
            messageId="global.save"
            onClick={() => void save()}
          />
        </>
      }
    >
      <StableRotatedImage angle={angle} src={image.url} alt="Extracted image" />
    </CardLayout>
  );
}
