import { Image, pixelate } from 'image-js';

import { ScanImportRoi } from './types';

/**
 * Pixelate roi image in-place
 *
 * @param image
 * @param roi
 */
export function pixelateRoiImage(image: Image, roi: ScanImportRoi): void {
  const origin = {
    column: roi.x,
    row: roi.y,
  };
  const crop = image.crop({
    width: roi.width,
    height: roi.height,
    origin,
  });
  pixelate(crop, {
    algorithm: 'center',
    cellSize: Math.round(crop.width * 0.1),
    out: crop,
  });
  crop.copyTo(image, { origin, out: image });
}
