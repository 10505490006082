// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getRequestId(error?: any): string | undefined {
  if (!error) return;
  return error.graphQLErrors?.[0]?.extensions?.profid?.requestId;
}

export function errorContainsSensitiveDetails(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any,
): boolean {
  return Boolean(
    error.graphQLErrors?.[0]?.extensions?.profid?.hasSensitiveDetails,
  );
}
