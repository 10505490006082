import { MinimalPreviousButton, MinimalNextButton } from './MinimalButton';

export default function MinimalPagination({
  page,
  rowsPerPage,
  totalCount,
  onChange,
}: {
  page: number;
  rowsPerPage: number;
  totalCount: number;
  onChange: (page: number) => void;
}) {
  const totalPages = Math.ceil(totalCount / rowsPerPage);

  let currentPage = page;
  if (currentPage > totalPages) {
    currentPage = totalPages - 1;
  }

  const nextDisabled = currentPage === totalPages - 1;
  const previousDisabled = currentPage === 0;

  if (totalPages <= 1) return null;

  return (
    <div className="flex">
      <MinimalPreviousButton
        tooltipId="global.previous_page"
        disabled={previousDisabled}
        onClick={() => onChange(page - 1)}
      />
      &nbsp;
      <MinimalNextButton
        tooltipId="global.next_page"
        disabled={nextDisabled}
        onClick={() => onChange(page + 1)}
      />
    </div>
  );
}
