import { T } from '@tolgee/react';

import { GqlUser } from '#gql';
import { Badge, BadgeSize, BadgeVariant, Color } from '#tailwind_ui';

function getBadgeProps({
  isDisabled,
  isConfirmed,
}: Pick<GqlUser, 'isDisabled' | 'isConfirmed'>): {
  labelId: TranslationKey;
  color: Color;
} {
  if (isDisabled) {
    return { labelId: 'user.status.DISABLED', color: Color.danger };
  }
  if (!isConfirmed) {
    return { labelId: 'user.status.UNCONFIRMED', color: Color.warning };
  }

  return { labelId: 'user.status.ACTIVE', color: Color.success };
}
export function UserStateBadge(
  props: Pick<GqlUser, 'isDisabled' | 'isConfirmed'>,
) {
  const { labelId, color } = getBadgeProps(props);

  return (
    <Badge
      label={<T keyName={labelId} />}
      color={color}
      variant={BadgeVariant.COLORED_BACKGROUND}
      size={BadgeSize.LARGE}
    />
  );
}

export function UserActiveStateBadge({
  isDisabled,
}: Pick<GqlUser, 'isDisabled'>) {
  const Component = isDisabled ? UserIsDisabledBadge : UserIsActiveBadge;

  return <Component />;
}

function UserIsActiveBadge() {
  return (
    <Badge
      label={<T keyName="user.status.ACTIVE" />}
      color={Color.success}
      variant={BadgeVariant.COLORED_BACKGROUND}
      size={BadgeSize.SMALL}
      className="me-4 !py-0"
    />
  );
}

function UserIsDisabledBadge() {
  return (
    <Badge
      label={<T keyName="user.status.DISABLED" />}
      color={Color.danger}
      variant={BadgeVariant.COLORED_BACKGROUND}
      size={BadgeSize.SMALL}
      className="me-4 !py-0"
    />
  );
}

export function UserConfirmStateBadge({
  isConfirmed,
}: Pick<GqlUser, 'isConfirmed'>) {
  const Component = isConfirmed ? UserIsConfirmedBadge : UserUnconfirmedBadge;

  return <Component />;
}

function UserIsConfirmedBadge() {
  return (
    <Badge
      label={<T keyName="user.status.CONFIRMED" />}
      color={Color.success}
      variant={BadgeVariant.COLORED_BACKGROUND}
      size={BadgeSize.SMALL}
      className="me-4 !py-0"
    />
  );
}

function UserUnconfirmedBadge() {
  return (
    <Badge
      label={<T keyName="user.status.UNCONFIRMED" />}
      color={Color.warning}
      variant={BadgeVariant.COLORED_BACKGROUND}
      size={BadgeSize.SMALL}
      className="me-4 !py-0"
    />
  );
}
