import { ReactNode, useEffect, useRef } from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { useLocation } from 'react-router-dom';

import FormattedErrorAlert from './translation/FormattedErrorAlert';

export default function ErrorBoundary(props: { children: ReactNode }) {
  return (
    <ReactErrorBoundary FallbackComponent={FallbackComponent}>
      {props.children}
    </ReactErrorBoundary>
  );
}

/**
 * reset ReactErrorBoundary on location change
 *
 * @param props
 */
function FallbackComponent(props: {
  error: Error;
  resetErrorBoundary: () => void;
}) {
  const location = useLocation();
  const refLocation = useRef(location.key);

  const refReset = useRef(props.resetErrorBoundary);
  refReset.current = props.resetErrorBoundary;

  useEffect(() => {
    if (location.key === refLocation.current) return;

    refLocation.current = location.key;
    refReset.current();
  }, [location.key]);

  return (
    <FormattedErrorAlert
      error={props.error}
      onDismiss={props.resetErrorBoundary}
    />
  );
}
