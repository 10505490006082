import { useTranslate } from '@tolgee/react';
import { useCallback, useMemo, useRef, useState } from 'react';

import { usePadifContext } from '../../contexts/padif';
import DocumentSetType from '../DocumentSet/DocumentSetType';

import {
  GqlDocumentSetSearchSelectQuery,
  useDocumentSetSearchSelectQuery,
} from '#gql';
import { MultiSearchSelect } from '#tailwind_ui';

type DocumentSet =
  GqlDocumentSetSearchSelectQuery['documentSets']['nodes'][number];

export function PadifDocSetSearchFilter() {
  const { t } = useTranslate();
  const { filters, store } = usePadifContext();
  const [search, setSearch] = useState('');

  const result = useDocumentSetSearchSelectQuery({
    variables: {
      search,
    },
  });
  const data = result.data || result.previousData;

  const docSets = useRef(new Map<string, DocumentSet>());
  const options = useMemo(() => {
    return (
      data?.documentSets?.nodes?.map((docSet) => {
        docSets.current.set(docSet.id, docSet);
        return docSet.id;
      }) ?? []
    );
  }, [data]);

  const renderOption = useCallback((option: string) => {
    const docSet = docSets.current.get(option);

    if (!docSet) return null;

    return (
      <article>
        <header>{docSet.name}</header>
        <p className="text-xs text-neutral-500">
          <DocumentSetType value={docSet} />
        </p>
      </article>
    );
  }, []);

  const renderSelectedOption = useCallback((option: string) => {
    const docSet = docSets.current.get(option);

    if (!docSet) return null;

    return docSet.name;
  }, []);

  if (result.error) throw result.error;

  return (
    <MultiSearchSelect<string>
      name="doc_set"
      selected={filters.documentSets}
      onChange={store.setDocumentSets}
      renderOption={renderOption}
      renderSelectedOption={renderSelectedOption}
      options={options}
      searchValue={search}
      onSearchChange={setSearch}
      label="doc_set"
      hiddenLabel
      placeholder={t('padif.list.filters.doc_set')}
      getValue={(option) => option}
      highlightClassName="[&_article>p]:text-neutral-200 [&_article>header]:text-white bg-primary-600"
    />
  );
}
