import { T } from '@tolgee/react';

import { useDocumentSetCandidateContext } from '../../../contexts/documentSetCandidate';
import { UseTableSelect } from '../../../hooks/useTableSelection';
import { IndexFilterError } from '../../TableSearch/IndexFilterError';
import FormattedButton from '../../translation/FormattedButton';
import { DocumentSetDocumentCandidatesTableView } from '../DocumentSetDocumentCandidatesTable';

import { FirstStepFormValues } from './common';

import { useSimulateDocumentsWithFiltersQuery } from '#gql';
import { Size, Variant } from '#tailwind_ui';
import { H2 } from '#ui/heading';

export interface DocumentSetNewStep2ManualProps {
  selection: UseTableSelect<string>;
  identifiers: FirstStepFormValues;
  onSubmit: () => void;
}

export default function DocumentSetNewStep2Manual(
  props: DocumentSetNewStep2ManualProps,
) {
  const { selection, identifiers, onSubmit } = props;
  const { docType, docCountry } = identifiers;
  const context = useDocumentSetCandidateContext();

  const result = useSimulateDocumentsWithFiltersQuery({
    variables: {
      ...context.pagination,
      sortBy: context.sort,
      filterBy: {
        ...context.filters,
        docType: docType ? [docType] : [],
        docCountry: docCountry ? [docCountry] : [],
      },
    },
  });

  const data = result.data ?? result.previousData;

  const actionBar = (
    <div className="flex gap-2">
      <FormattedButton
        messageId="global.deselect_all"
        variant={Variant.secondary}
        size={Size.small}
        onClick={selection.methods.clear}
      />

      <span className="flex-1" />

      <FormattedButton
        messageId="doc_set.form.new.save_manual"
        messageParams={{ count: selection.value.size }}
        onClick={onSubmit}
      />
    </div>
  );

  return (
    <>
      <H2>
        <T keyName="doc_set.form.new.step_select_documents.manual_title" />
      </H2>

      <p>
        <T keyName="doc_set.form.new.step_select_documents.manual_description" />
      </p>

      {actionBar}

      {result.error && (
        <IndexFilterError error={result.error} context={context} />
      )}

      {data?.documents && (
        <DocumentSetDocumentCandidatesTableView
          documents={data.documents.nodes}
          totalCount={data.documents.totalCount}
          facets={data.documents.facets}
          selection={selection}
        />
      )}

      {actionBar}
    </>
  );
}
