import { cluster } from 'd3-hierarchy';
import { scaleLinear, ScaleOrdinal } from 'd3-scale';
import { AgglomerationMethod, Cluster } from 'ml-hclust';
import { useLayoutEffect, useRef, useState } from 'react';

import { PadifAnalysisResult } from '../padifGqlTypes';

import PadifClusteringDocument from './PadifClusteringDocument';
import PadifClusteringXAxis from './PadifClusteringXAxis';
import {
  labelHeight,
  labelMargin,
  labelPadding,
  xAxisHeight,
  computeHierarchy,
  getClusterLines,
} from './reportUtils';

export default function PadifClusteringDendrogram({
  documents,
  distanceMatrix,
  clusteringMethod,
  referenceId,
  width,
  seriesColorScale,
  seriesLabelScale,
}: {
  documents: PadifAnalysisResult['documents'];
  distanceMatrix: PadifAnalysisResult['distanceMatrix'];
  clusteringMethod: AgglomerationMethod;
  referenceId: string;
  width: number;
  seriesColorScale: ScaleOrdinal<string, string>;
  seriesLabelScale: ScaleOrdinal<string, string>;
}) {
  const hierarchy = computeHierarchy(
    documents,
    distanceMatrix,
    clusteringMethod,
  );

  const dendrogramHeight = labelHeight * documents.length;
  const height = dendrogramHeight + xAxisHeight;

  const labelsRef = useRef<SVGGElement>(null);
  const [labelWidth, setLabelWidth] = useState(0);
  useLayoutEffect(() => {
    if (labelsRef.current) {
      setLabelWidth(labelsRef.current.getBBox().width);
    }
  }, [documents, referenceId]);

  const dendrogramWidth = width - labelWidth - labelPadding;

  const clustering = cluster<Cluster>()
    .size([dendrogramHeight, dendrogramWidth])
    .separation(() => 1)(hierarchy);
  const scaleX = scaleLinear()
    .domain([clustering.data.height, 0])
    .range([0, dendrogramWidth]);
  const lines = getClusterLines(clustering, scaleX);

  const documentLabels = clustering
    .leaves()
    .map((node, index) => (
      <PadifClusteringDocument
        seriesColorScale={seriesColorScale}
        seriesLabelScale={seriesLabelScale}
        referenceId={referenceId}
        key={node.data.index}
        document={documents[node.data.index]}
        index={index}
      />
    ));

  return (
    <svg height={height} width={width} className="overflow-visible">
      <g>{lines}</g>
      <g
        ref={labelsRef}
        transform={`translate(${
          dendrogramWidth + labelPadding + labelMargin
        },0)`}
      >
        {documentLabels}
      </g>
      <g transform={`translate(0,${dendrogramHeight + 10})`}>
        <PadifClusteringXAxis scale={scaleX} width={dendrogramWidth} />
      </g>
    </svg>
  );
}
