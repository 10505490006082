import { TrashIcon } from '@heroicons/react/24/outline';
import { T } from '@tolgee/react';

import { AsyncFormattedConfirmModal } from '../AsyncFormattedConfirmModal';

import {
  GqlDetailedExtraImageFieldsFragment,
  useDeleteDocumentExtraImageMutation,
} from '#gql';
import { Color } from '#tailwind_ui';

interface DocumentDeleteExtraImageProps {
  docId: string;
  docName: string;
  imageId: string;
  image: GqlDetailedExtraImageFieldsFragment['document'];
  onCancel: () => void;
}

export default function DocumentDeleteExtraImageModal(
  props: DocumentDeleteExtraImageProps,
) {
  const { docId, docName, onCancel, imageId, image } = props;
  const [deleteExtraImage] = useDeleteDocumentExtraImageMutation();

  return (
    <AsyncFormattedConfirmModal
      open
      icon={<TrashIcon />}
      color={Color.danger}
      title="page.series.delete_image.title"
      body={
        <>
          <T
            keyName="doc.extra_image.delete.question"
            params={{ name: docName }}
          />
          <img src={image.url} alt="Serie" />
        </>
      }
      cancelText="global.cancel"
      confirmText="global.delete"
      onClose={onCancel}
      onConfirm={() =>
        deleteExtraImage({
          variables: { input: { id: docId, imageId } },
        })
      }
    />
  );
}
