import { T } from '@tolgee/react';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import FormattedButton from '../../../components/translation/FormattedButton';
import FormattedSubmitButton from '../../../components/translation/FormattedSubmitButton';
import {
  useDefaultValue,
  validationSchema,
} from '../components/form/new_shared';
import { LabelFields } from '../components/form/shared_fields';

import { GqlNewLabelInput, useCreateLabelMutation } from '#gql';
import { FormRHF } from '#tailwind_ui';
import { PageLayout, PageLayoutNavigation } from '#ui/page_layout';

export default function LabelNewPage() {
  const defaultValue = useDefaultValue();
  const [createLabel] = useCreateLabelMutation();
  const navigate = useNavigate();

  const [isScopedLabel, setIsScopedLabel] = useState(false);

  async function createNewLabel(data: GqlNewLabelInput) {
    data.name = data.name.trim();
    data.description = data.description?.trim();

    await createLabel({
      variables: {
        newLabel: data,
      },
    });

    navigate('..');
  }

  return (
    <FormRHF<GqlNewLabelInput>
      defaultValues={defaultValue}
      onSubmit={createNewLabel}
      validationSchema={validationSchema}
      noDefaultStyle
    >
      <PageLayout
        title={<T keyName="form.new_label.title" />}
        navigation={
          <PageLayoutNavigation to="/documents/labels">
            <T keyName="nav.labels.list" />
          </PageLayoutNavigation>
        }
        actions={<FormButtons />}
        bodyClassName="mx-auto max-w-5xl"
        footerClassName="mx-auto max-w-5xl flex gap-2"
        footer={<FormButtons />}
      >
        <LabelFields
          isScopedLabel={isScopedLabel}
          setIsScopedLabel={setIsScopedLabel}
        />
      </PageLayout>
    </FormRHF>
  );
}

function FormButtons() {
  return (
    <>
      <FormattedButton
        as={Link}
        to=".."
        messageId="global.cancel"
        variant="white"
      />
      <FormattedSubmitButton messageId="global.add" color="primary" />
    </>
  );
}
